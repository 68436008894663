import * as React from "react"
import { useHistory } from "react-router-dom"
import { type ViewIdentifier } from "@digits-graphql/frontend/graphql-bearer"
import { type DigitsLocation } from "@digits-shared/components/Router/DigitsLocation"
import { LoggedRedirect } from "@digits-shared/components/Router/LoggedRedirect"

/*
  INTERFACE
*/

interface RequiresViewProps {
  view: ViewIdentifier
  redirectTo?: string
  redirectName?: string
  children?: React.ReactNode
}

/*
  COMPONENTS
*/

export const RequiresView: React.FC<RequiresViewProps> = ({
  view,
  redirectTo,
  redirectName,
  children,
}) => {
  const history = useHistory()

  if (!view?.viewVersion) {
    if (!redirectTo || redirectTo === (history?.location as DigitsLocation)?.fullPathname) {
      return null
    }
    return <LoggedRedirect name={redirectName ?? "RequiresView"} to={redirectTo} />
  }

  return <>{children}</>
}
