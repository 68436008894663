import * as React from "react"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgCheck } from "@digits-shared/components/SVGIcons/line/Check.svg"
import { FOCUS_VISIBLE_OUTLINE } from "@digits-shared/components/UI/Elements/Form/InputStyles"
import colors from "@digits-shared/themes/colors"
import styled, { css } from "styled-components"

/*
 STYLES
*/

const CheckmarkIcon = styled(SvgCheck)`
  position: absolute;
  height: 12px;
  width: 12px;
  pointer-events: none;
  ${svgPathStyles(colors.accentBlue, 3.5)};

  opacity: 0;
  transition: opacity 100ms;
`

const CheckboxInput = styled.input`
  display: inline-block;
  background-color: ${colors.translucentWhite40};
  padding: 9px;
  appearance: none;
  overflow: hidden;
  cursor: pointer;
  margin: 0;
  transition:
    opacity 200ms ease,
    border 200ms ease;
  border-radius: 3px;
  border: 1.5px solid ${colors.secondary30};

  &:checked {
    border-color: ${colors.accentBlue};
    background-color: ${colors.translucentWhite70};

    & + ${CheckmarkIcon} {
      opacity: 1;
    }
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:focus-visible {
    ${FOCUS_VISIBLE_OUTLINE};
  }
`

const CheckboxContainer = styled.span<{ $disabled?: boolean }>`
  width: 36px;
  min-width: 36px;
  height: 36px;
  margin: -6px;
  border-radius: 50%;
  background: ${colors.transparent};

  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  transition: background 200ms ease-in-out;

  ${({ $disabled }) =>
    !$disabled &&
    css`
      cursor: pointer;

      &:hover {
        background: ${colors.secondary05};

        ${CheckboxInput} {
          border-color: ${colors.accentBlue};
          background-color: ${colors.translucentWhite40};
        }
      }
    `};
`

export type CheckboxProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  className?: string
}

// TODO: Eventually replace the Checkbox file in this directory with this one
export const Checkbox: React.FC<CheckboxProps> = ({ className, ...props }) => {
  const checkboxRef = React.useRef<HTMLInputElement>(null)
  const stopPropagation = React.useCallback((event: React.MouseEvent) => {
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
  }, [])

  const onClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      stopPropagation(e)
      e.preventDefault()
      checkboxRef.current?.click()
    },
    [stopPropagation]
  )

  return (
    <CheckboxContainer onClick={onClick} $disabled={props.disabled}>
      <CheckboxInput
        {...props}
        onClick={stopPropagation}
        ref={checkboxRef}
        className={className}
        type="checkbox"
      />
      <CheckmarkIcon />
    </CheckboxContainer>
  )
}
