import * as React from "react"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgArrowNarrowDownRight } from "@digits-shared/components/SVGIcons/line/ArrowNarrowDownRight.svg"
import { SvgArrowNarrowUpRight } from "@digits-shared/components/SVGIcons/line/ArrowNarrowUpRight.svg"
import styled from "styled-components"
import {
  DeltaSign,
  useParseDeltaMonths,
} from "src/frontend/components/Shared/Layout/Components/Runway/shared"

/*
  STYLES
*/

const RunwayDelta = styled.div<{ color: string }>`
  color: ${({ color }) => color};

  svg {
    ${({ color }) => svgPathStyles(color, 2)}

    position: relative;
    top: 2px;
    width: 11px;
    height: 11px;
    margin-right: 2px;
  }
`

/*
  INTERFACES
*/

interface DeltaProps {
  deltaMonths?: number
}

/*
  COMPONENTS
*/

export const RunwayDeltaSubHeader = React.memo<DeltaProps>(({ deltaMonths }) => {
  const parsed = useParseDeltaMonths(deltaMonths)

  if (!parsed) return null

  const { color, sign, delta, interval } = parsed

  return (
    <RunwayDelta color={color}>
      {sign === DeltaSign.Positive ? <SvgArrowNarrowUpRight /> : <SvgArrowNarrowDownRight />}
      {delta} {interval}
    </RunwayDelta>
  )
})
