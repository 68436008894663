import * as React from "react"
import { type TextComponentConfig } from "@digits-graphql/frontend/graphql-bearer"
import styled from "styled-components"
import { ComponentHeaderTitle } from "src/frontend/components/Shared/Layout/Components/Headers/ComponentSummary"
import { ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"

/*
  STYLES
*/

const TextContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`

/*
  COMPONENTS
*/

export const TextConfig: React.FC<{ config: TextComponentConfig }> = () => (
  <>
    <ComponentHeaderTitle title="Text" componentSize={ComponentSize.Small} periodName={undefined} />
    <TextContainer>
      <img alt="" src={require("static/images/reports/empty-summary.png")} height={90} />
    </TextContainer>
  </>
)
