import * as React from "react"
import { type Party } from "@digits-graphql/frontend/graphql-bearer"
import { svgIconStyles, svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgDomainNames } from "@digits-shared/components/SVGIcons/category/DomainNames.svg"
import { SvgFacebook } from "@digits-shared/components/SVGIcons/custom/Facebook.svg"
import { SvgInstagram } from "@digits-shared/components/SVGIcons/custom/Instagram.svg"
import { SvgTwitter } from "@digits-shared/components/SVGIcons/custom/Twitter.svg"
import { SvgMail01 } from "@digits-shared/components/SVGIcons/line/Mail01.svg"
import {
  INSTAGRAM_HANDLE_PATTERN_REGEX,
  TWITTER_HANDLE_PATTERN_REGEX,
} from "@digits-shared/components/UI/Elements/Inputs/SocialMediaInputs"
import { DigitsLinkButton } from "@digits-shared/DesignSystem/LinkButton"
import ElementArrayHelper from "@digits-shared/helpers/ElementArrayHelper"
import stringHelper from "@digits-shared/helpers/stringHelper"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"
import { type VibrantBackgroundValues } from "src/shared/components/Elements/VibrantBackground"
import { PartyDetail, PartyLink } from "src/shared/components/PartyHover/Styles"

const PartyPhoneNumber = React.lazy(
  () => import("src/shared/components/PartyHover/PartyPhoneNumber")
)

/*
 STYLES
*/

const HeaderLink = styled.div<{
  vibrantBackgroundValues?: VibrantBackgroundValues
}>`
  font-size: 11px;
  padding-right: 5px;
  ${({ vibrantBackgroundValues }) => css`
    color: ${vibrantBackgroundValues?.textColor};
  `};
`

export const PartyDetailsName = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-weight: ${fonts.weight.heavy};
  font-size: 22px;
  margin-bottom: 3px;
  line-height: 120%;
`

export const PartyDetailsDescription = styled.div`
  opacity: 0.92;
  font-size: 13px;
  font-weight: ${fonts.weight.normal};
  line-height: 115%;
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  white-space: normal;
`

const PartyPreviewOnlyDetails = styled.div`
  opacity: 0.92;
  display: flex;
  align-items: center;
`

const PartyLinkButton = styled(DigitsLinkButton)<{
  color?: string
}>`
  border: 1px solid ${({ color }) => color || colors.black};
  color: ${({ color }) => color || colors.black};
`

const PartyDetailsStyled = styled.div`
  flex: 1;
  padding-left: 20px;
`

const PartyContactContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 2px;
  line-height: 18px;
`

export const PartyDetailsBullet = styled.div`
  opacity: 0.5;
  font-weight: ${fonts.weight.medium};
  font-size: 11px;
  display: inline-block;
  line-height: 0;
  margin: 1px 5px 0 5px;
`

const SVG_STYLES = css<{
  fill?: string
}>`
  width: 15px;
  height: 15px;
  margin-right: 5px;
  ${({ fill }) => svgIconStyles(fill || colors.white)}
`

const PATH_SVG_STYLES = css<{
  fill?: string
}>`
  width: 15px;
  height: 15px;
  margin-right: 5px;
  ${({ fill }) => svgPathStyles(fill || colors.white, 1.5)};
`

const WebsiteIcon = styled(SvgDomainNames)`
  ${SVG_STYLES};
`

const EmailIcon = styled(SvgMail01)<{
  fill?: string
}>`
  ${PATH_SVG_STYLES};
`

const SOCIAL_NETWORK_ICON_STYLES = css`
  ${SVG_STYLES};

  margin-right: 1px;
  margin-bottom: -4px;

  a:not(:first-child) > & {
    margin-left: 8px;
  }
`

const FBIcon = styled(SvgFacebook)`
  ${SOCIAL_NETWORK_ICON_STYLES}
`

const TwitterIcon = styled(SvgTwitter)`
  ${SOCIAL_NETWORK_ICON_STYLES}
`

const InstagramIcon = styled(SvgInstagram)`
  ${SOCIAL_NETWORK_ICON_STYLES}
`

interface PartyDetailsInfoProps {
  party: Party
  partyDetailsPath?: string
  vibrantBackgroundValues?: VibrantBackgroundValues
  isPreview?: boolean
  className?: string
}

export const PartyAdditionalDetails: React.FC<PartyDetailsInfoProps> = ({
  party,
  partyDetailsPath,
  vibrantBackgroundValues,
  isPreview,
  className,
}) => {
  const detailsButton = partyDetailsPath && vibrantBackgroundValues && (
    <PartyAdditionalDetailsButton
      partyDetailsPath={partyDetailsPath}
      vibrantBackgroundValues={vibrantBackgroundValues}
    />
  )

  const children = [
    <PartyDetailsName key="party-name">
      {party.name}
      {detailsButton}
    </PartyDetailsName>,
  ]

  if (party.shortDescription) {
    children.push(
      <PartyDetailsDescription key="party-description">
        <span>{party.shortDescription}</span>
      </PartyDetailsDescription>
    )
  }

  return (
    <PartyDetailsStyled className={className}>
      <HeaderLink vibrantBackgroundValues={vibrantBackgroundValues}>{children}</HeaderLink>
      <PartyContactContainer>{<PartyContactDetails party={party} />}</PartyContactContainer>
      {isPreview && <PartyInfoPreviewOnlyLinks party={party} />}
    </PartyDetailsStyled>
  )
}

export const PartyAdditionalDetailsButton: React.FC<{
  partyDetailsPath: string
  vibrantBackgroundValues: VibrantBackgroundValues
  className?: string
}> = ({ partyDetailsPath, vibrantBackgroundValues, className }) => (
  <PartyLinkButton
    className={className}
    $variant="ghost-light"
    to={partyDetailsPath}
    size="small"
    color={vibrantBackgroundValues.textColor}
  >
    Details
  </PartyLinkButton>
)

export const PartyContactDetails: React.FC<{
  party: Party
  iconColor?: string
}> = ({ party, iconColor }) => {
  const contactChildren = new ElementArrayHelper()

  if (party.url) {
    contactChildren.push(
      <PartyDetail key="party-url">
        <PartyLink href={party.url} rel="noopener noreferrer" target="_blank">
          <WebsiteIcon fill={iconColor} />
          {stringHelper.displayUrl(party.url)}
        </PartyLink>
      </PartyDetail>
    )
  }

  const supportUrl = party.supportUrl ? stringHelper.displayUrl(party.supportUrl) : undefined
  if (supportUrl) {
    contactChildren.push(
      <PartyDetail key="party-support-url">
        <PartyLink href={party.supportUrl ?? undefined} target="_blank">
          <WebsiteIcon fill={iconColor} />
          {supportUrl && supportUrl.length > 40 ? "support" : supportUrl}
        </PartyLink>
      </PartyDetail>
    )
  }

  if (party.supportEmail) {
    contactChildren.push(
      <PartyLink
        key="party-email"
        href={`mailto:${party.supportEmail}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <EmailIcon fill={iconColor} />
        {party.supportEmail}
      </PartyLink>
    )
  }

  const supportPhone = (
    <React.Suspense key="party-phone">
      <PartyPhoneNumber party={party} />
    </React.Suspense>
  )
  if (party.supportPhone) contactChildren.push(supportPhone)

  return contactChildren.joinElements(<PartyDetailsBullet>•</PartyDetailsBullet>)
}

const PartyInfoPreviewOnlyLinks: React.FC<{
  party: Party
}> = ({ party }) => {
  const socialNetworks = []
  if (party.twitterUrl) {
    const isHandle = party.twitterUrl.match(TWITTER_HANDLE_PATTERN_REGEX)
    const twitterUrl = isHandle ? `https://twitter.com/${party.twitterUrl}` : party.twitterUrl

    socialNetworks.push(
      <a key="twitter" href={twitterUrl} rel="noopener noreferrer" target="_blank">
        <TwitterIcon />
      </a>
    )
  }

  if (party.instagramUrl) {
    const isHandle = party.instagramUrl.match(INSTAGRAM_HANDLE_PATTERN_REGEX)
    const instagramUrl = isHandle
      ? `https://instagram.com/${party.instagramUrl}`
      : party.instagramUrl

    socialNetworks.push(
      <a key="instagram" href={instagramUrl} rel="noopener noreferrer" target="_blank">
        <InstagramIcon />
      </a>
    )
  }

  if (party.facebookUrl) {
    socialNetworks.push(
      <a key="facebook" href={party.facebookUrl} rel="noopener noreferrer" target="_blank">
        <FBIcon />
      </a>
    )
  }

  return (
    <PartyPreviewOnlyDetails>{!!socialNetworks.length && socialNetworks}</PartyPreviewOnlyDetails>
  )
}
