import * as React from "react"
import { type EntityParty, type IntervalOrigin } from "@digits-graphql/frontend/graphql-bearer"
import urlHelper from "@digits-shared/helpers/urlHelper"
import useRouter from "@digits-shared/hooks/useRouter"
import { useHighlight } from "src/frontend/components/OS/Details/Shared/hooks/useHighlight"
import { BarChartComponent } from "src/frontend/components/Shared/Layout/Components/Charts/BarChartComponent"
import { LineChartComponent } from "src/frontend/components/Shared/Layout/Components/Charts/LineChartComponent"
import {
  type Summaries,
  type TimeseriesValue,
  toSortedTimeseriesValues,
} from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"
import {
  PartyComponentIcon,
  PartyComponentTitle,
} from "src/frontend/components/Shared/Layout/Components/Headers/ComponentEntityIcons"
import { type ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { usePartyLinkPath } from "src/frontend/components/Shared/Layout/hooks/useEntityDetailsViewPaths"
import {
  ChartType,
  type MatchedComponent,
  type SizingProps,
} from "src/frontend/components/Shared/Layout/types"

/*
  INTERFACES
*/

interface PartyChartComponentProps extends SizingProps {
  party?: EntityParty
  summaries?: Summaries
  origin: IntervalOrigin
  component: MatchedComponent<"partyChart">
  componentSize: ComponentSize
  chartType: ChartType
  skipAnimations: boolean
}

/*
  COMPONENTS
*/

export const PartyChartComponent: React.FC<PartyChartComponentProps> = ({
  party,
  summaries,
  origin,
  component,
  componentSize,
  chartType,
  height,
  skipAnimations,
}) => {
  const {
    config: {
      partyChart: { partyRole },
    },
  } = component

  const { history } = useRouter()
  const partyPath = usePartyLinkPath(party, partyRole, origin)
  const timeseries = React.useMemo(() => toSortedTimeseriesValues(summaries), [summaries])

  const icon = (
    <PartyComponentIcon
      party={party}
      role={partyRole}
      componentSize={componentSize}
      intervalOrigin={origin}
    />
  )
  const name = (
    <PartyComponentTitle
      party={party}
      role={partyRole}
      componentSize={componentSize}
      intervalOrigin={origin}
    >
      {component.title}
    </PartyComponentTitle>
  )

  const highlight = useHighlight()
  const [selectedIndex, setSelectedIndex] = React.useState(highlight ?? undefined)

  const onMouseOver = React.useCallback((value: TimeseriesValue, index: number) => {
    setSelectedIndex(index)
  }, [])

  const onMouseOut = React.useCallback(
    (value?: TimeseriesValue) => {
      setSelectedIndex(highlight ?? undefined)
    },
    [highlight]
  )

  const onChartClick = React.useCallback(
    (_: TimeseriesValue, index: number) => {
      if (!partyPath) return
      const path = urlHelper.addSearchParams(partyPath, { highlight: index })
      if (!path) return

      setSelectedIndex(index)
      history.push(path)
    },
    [partyPath, history]
  )

  switch (chartType) {
    case ChartType.BalanceSummary:
      return (
        <LineChartComponent
          componentSize={componentSize}
          title={name}
          icon={icon}
          timeseries={timeseries}
          height={height}
          skipAnimations={skipAnimations}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          onClick={onChartClick}
          selectedIndex={selectedIndex}
        />
      )
    case ChartType.TransactionSummary:
      return (
        <BarChartComponent
          componentSize={componentSize}
          title={name}
          icon={icon}
          timeseries={timeseries}
          height={height}
          skipAnimations={skipAnimations}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          onClick={onChartClick}
          selectedIndex={selectedIndex}
        />
      )
  }
}
