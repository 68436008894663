export enum AddClientModalState {
  ClientDetails = "ClientDetails",
  SendInvite = "SendInvite",
  Done = "Done",
}

export interface ClientDetails {
  name: string
  websiteUrl?: string | null
  emailAddress: string
  givenName: string
  familyName: string
}
export interface AddClientState {
  modalState: AddClientModalState
  addClientDetailsFormValid: boolean
  clientDetails?: ClientDetails
  icon?: string
  iconBase64?: string
  message?: string
  sending: boolean
  errorMessage?: React.ReactNode
}
