import React from "react"
import * as ReactDOM from "react-dom"
import { type ObjectIdentifier } from "@digits-graphql/frontend/graphql-bearer"
import { Keys } from "@digits-shared/components/UI/Elements/Keys"
import { DigitsButton } from "@digits-shared/DesignSystem/Button"
import caretHelper from "@digits-shared/helpers/caretHelper"
import { isMobile } from "@digits-shared/helpers/devicesHelper"
import { storage } from "@digits-shared/helpers/storage/storage"
import useForwardedRef from "@digits-shared/hooks/useForwardedRef"
import { useOnBodyClick } from "@digits-shared/hooks/useOnBodyClick"
import { usePortalElement } from "@digits-shared/hooks/usePortalElement"
import useStateBoolean from "@digits-shared/hooks/useStateBoolean"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import { AnimatePresence } from "framer-motion"
import styled, { css } from "styled-components"
import AutoComplete, { MenuAlignment } from "src/shared/components/EditableContent/AutoComplete"
import { ContentDecorator } from "src/shared/components/EditableContent/ContentDecorator"
import {
  type Decorator,
  DECORATOR_CLASS,
  mapDecorator,
  TagNames,
} from "src/shared/components/EditableContent/editableContentConstants"
import {
  type LinkPreset,
  PortalPositionedRichTextToolbar,
  RichTextToolbar,
} from "src/shared/components/EditableContent/RichTextToolbar"
import DOMSanitize from "src/shared/components/ObjectEntities/DOMSanitize"
import { convertToDigitsEntityTags } from "src/shared/components/ObjectEntities/entitiesParserShared"
import { useStopClickPropagation } from "src/shared/hooks/useStopClickPropagation"

/*
 STYLES
*/

export const EditableInputBox = styled.div<{ disabled?: boolean }>`
  flex: 1;
  height: 100%;
  width: 100%;
  outline: none;
  resize: none;
  text-align: left;
  word-break: break-word;
  font-weight: ${fonts.weight.book};
  font-size: 14px;
  line-height: 23px;
  opacity: 1;
  padding: 15px;
  transition:
    opacity 500ms,
    color 500ms,
    padding 200ms;

  a {
    color: inherit;
    cursor: pointer;
    text-decoration: underline;
  }

  ul,
  ol {
    padding: revert;
  }

  digits-entity {
    color: ${colors.secondary};
    font-weight: ${fonts.weight.heavy};
  }

  &[contenteditable="true"] {
    &:empty::after {
      content: "";
      color: ${colors.translucentSecondary40};
    }

    ${({ disabled }) =>
      disabled &&
      css`
        opacity: 0.6;
      `}
  }

  & *::selection,
  span.${DECORATOR_CLASS} {
    background: #93ceee;
    color: ${colors.secondary};
    border-radius: 3px;
    padding: 2px 3px;
    font-weight: ${fonts.weight.heavy};
    line-height: 21px;
  }
`

const PortalRichTextToolbar = styled(PortalPositionedRichTextToolbar)`
  top: -23px;
  left: 50%;
  transform: translateX(-50%);
`

const Actions = styled.div`
  display: flex;
  gap: 12px;
  margin-left: 32px;
  align-items: center;
`

/*
 INTERFACES
*/

interface AutocompleteProps {
  autocomplete: Decorator[]
  identifier: ObjectIdentifier
  autoCompleteMenuAlignment?: MenuAlignment
}

interface InputProps {
  draftId?: string
  readonly?: boolean
  disabled?: boolean
  autoFocus?: boolean
  className?: string
  toolbarPortalElId?: string
  disableToolbar?: boolean
  children?: React.ReactNode | undefined

  onInput?: (event: React.ChangeEvent<HTMLDivElement>) => void
  onKeyDown?: (event: React.KeyboardEvent<HTMLElement>) => void
  onKeyUp?: (event: React.KeyboardEvent<HTMLElement>) => void
  onChange?: () => void
  onFocus?: () => void
  onBlur?: () => void
  onCancel?: () => void
  onSave?: (e?: Event) => void
  onHyperlinkModal?: (show: boolean) => void
}

type EditableProps = InputProps &
  Partial<AutocompleteProps> &
  Pick<ToolbarProps, "showGenerateSummary" | "showSelectColors" | "linkPresets">

interface ToolbarProps {
  children?: React.ReactNode
  contentEditable: boolean
  toolbarPortalElId?: string
  editableContentRef: React.RefObject<HTMLDivElement>
  onRTToolbarOpen: () => void
  onRTToolbarClose: () => void
  onRTToolbarFormat: () => void
  showsHyperlinkModal: React.RefObject<boolean>
  showGenerateSummary?: boolean
  showSelectColors?: boolean
  linkPresets?: LinkPreset[]
  onHyperlinkModal: (show: boolean) => void
}

/*
 COMPONENTS
*/

export const EditableContent = React.forwardRef<HTMLDivElement, EditableProps>((props, ref) => {
  const {
    draftId,
    readonly,
    disabled,
    autoFocus,
    className,
    autocomplete,
    identifier,
    toolbarPortalElId,
    disableToolbar,
    autoCompleteMenuAlignment = MenuAlignment.Below,
    children,
    onInput,
    onCancel,
    onSave,
    onHyperlinkModal,
    showGenerateSummary,
    showSelectColors,
    linkPresets,
  } = props

  const draft = draftId && storage.session.getItem(draftId)
  const draftHTML = React.useMemo(
    () => (draftId && draft ? { __html: `<span>${draft}</span>` } : undefined),
    // We only want to seed the input element with a prior draft on mount.
    // Otherwise, when we remove the draft from session storage it overwrites
    // whatever the user had entered since the content was pre-populated.
    [draftId, draft]
  )

  const forwardedRef = useForwardedRef(ref)

  const [focused, setFocused] = React.useState(false)

  const showsHyperlinkModal = React.useRef(false)
  const onModal = React.useCallback(
    (showModal: boolean) => {
      onHyperlinkModal?.(showModal)
      showsHyperlinkModal.current = showModal
    },
    [onHyperlinkModal]
  )

  const onFocus = React.useCallback(() => {
    setFocused(true)
    props.onFocus?.()
  }, [props])

  const onBlur = React.useCallback(() => {
    if (showsHyperlinkModal.current) return
    setFocused(false)
    props.onBlur?.()
  }, [props])

  const {
    onBoxFocus,
    onBoxBlur,
    onAutoCompleted,
    onRTToolbarOpen,
    onRTToolbarClose,
    onRTToolbarFormat,
    focusedRef,
  } = useFocusEvents({ ...props, onFocus, onBlur }, forwardedRef)
  const { onMouseDown, onClick } = useMouseEvents(forwardedRef, focusedRef, props)
  const { onKeyUp, onKeyDown } = useKeyboardEvents(forwardedRef, props)
  const { onCopy, onCut, onPaste } = useClipboardEvents(forwardedRef, props)

  React.useEffect(() => {
    const element = forwardedRef.current
    if (element && autoFocus) {
      setTimeout(() => {
        element.focus()
        caretHelper.placeAtEnd(element)
      }, 500)
    }
  }, [disabled, autoFocus, draftId, forwardedRef])

  const stopPropagation = useStopClickPropagation()
  const cancel = React.useCallback(
    (e: React.MouseEvent) => {
      stopPropagation(e)
      onCancel?.()
      setFocused(false)
    },
    [onCancel, stopPropagation]
  )
  const save = React.useCallback(
    (e: React.MouseEvent) => {
      stopPropagation(e)
      onSave?.()
      setFocused(false)
    },
    [onSave, stopPropagation]
  )

  const contentEditable = !readonly && !disabled

  const { innerHTML, reactChildren } = draftHTML
    ? { innerHTML: draftHTML, reactChildren: undefined }
    : typeof children === "string"
      ? { innerHTML: { __html: children }, reactChildren: undefined }
      : { innerHTML: undefined, reactChildren: children }

  return (
    <div css="position: relative; flex: 1; height: 100%;">
      <EditableInputBox
        ref={forwardedRef}
        className={className}
        contentEditable={contentEditable}
        disabled={disabled}
        onClick={onClick}
        onInput={onInput}
        onMouseDown={onMouseDown}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onCopy={onCopy}
        onCut={onCut}
        onPaste={onPaste}
        onFocus={onBoxFocus}
        onBlur={onBoxBlur}
        suppressContentEditableWarning={!focused}
        dangerouslySetInnerHTML={innerHTML}
        tabIndex={showsHyperlinkModal.current ? -1 : undefined}
      >
        {reactChildren}
      </EditableInputBox>

      {identifier && autocomplete && contentEditable && (
        <AutoComplete
          identifier={identifier}
          types={autocomplete}
          editableContentRef={forwardedRef}
          onAutoCompleted={onAutoCompleted}
          menuAlignment={autoCompleteMenuAlignment}
        />
      )}
      {contentEditable && !disableToolbar && !toolbarPortalElId && (
        <SelectionToolbar
          contentEditable={contentEditable}
          editableContentRef={forwardedRef}
          onRTToolbarOpen={onRTToolbarOpen}
          onRTToolbarClose={onRTToolbarClose}
          onRTToolbarFormat={onRTToolbarFormat}
          showsHyperlinkModal={showsHyperlinkModal}
          onHyperlinkModal={onModal}
          showGenerateSummary={showGenerateSummary}
          showSelectColors={showSelectColors}
          linkPresets={linkPresets}
        />
      )}
      {contentEditable && toolbarPortalElId && (
        <PortalToolbar
          show={focused}
          showGenerateSummary={showGenerateSummary}
          showSelectColors={showSelectColors}
          linkPresets={linkPresets}
          contentEditable={contentEditable}
          editableContentRef={forwardedRef}
          toolbarPortalElId={toolbarPortalElId}
          onRTToolbarOpen={onRTToolbarOpen}
          onRTToolbarClose={onRTToolbarClose}
          onRTToolbarFormat={onRTToolbarFormat}
          showsHyperlinkModal={showsHyperlinkModal}
          onHyperlinkModal={onModal}
        >
          <Actions>
            <DigitsButton
              $variant="secondary-dark"
              size="small"
              onClickCapture={cancel}
              onMouseEnter={onHyperlinkModal?.bind(undefined, true)}
              onMouseLeave={onHyperlinkModal?.bind(undefined, false)}
            >
              Cancel
            </DigitsButton>
            <DigitsButton size="small" onClick={save}>
              Save
            </DigitsButton>
          </Actions>
        </PortalToolbar>
      )}
    </div>
  )
})

export const SelectionToolbar: React.FC<ToolbarProps> = ({
  contentEditable,
  editableContentRef,
  onRTToolbarOpen,
  onRTToolbarClose,
  onRTToolbarFormat,
  showsHyperlinkModal,
  onHyperlinkModal,
  showGenerateSummary,
  showSelectColors,
  linkPresets,
}) => {
  // TODO: mobile support
  if (isMobile) return null

  if (!contentEditable) {
    return null
  }

  return (
    <RichTextToolbar
      editableContentRef={editableContentRef}
      onOpen={onRTToolbarOpen}
      onClose={onRTToolbarClose}
      onFormat={onRTToolbarFormat}
      onHyperlinkModal={onHyperlinkModal}
      showGenerateSummary={showGenerateSummary}
      showSelectColors={showSelectColors}
      linkPresets={linkPresets}
    />
  )
}

export const PortalToolbar: React.FC<
  ToolbarProps & { show: boolean; toolbarPortalElId: string }
> = ({
  contentEditable,
  toolbarPortalElId,
  editableContentRef,
  show,
  onRTToolbarOpen,
  onRTToolbarClose,
  onRTToolbarFormat,
  showsHyperlinkModal,
  showGenerateSummary,
  showSelectColors,
  linkPresets,
  onHyperlinkModal,
  children,
}) => {
  const portalEl = usePortalElement(toolbarPortalElId)
  const toolbarRef = React.useRef<HTMLDivElement>(null)

  // TODO: mobile support
  if (isMobile) return null

  if (!contentEditable) {
    return null
  }

  return ReactDOM.createPortal(
    <AnimatePresence>
      {(showsHyperlinkModal.current || show) && (
        <PortalRichTextToolbar
          showGenerateSummary={showGenerateSummary}
          showSelectColors={showSelectColors}
          linkPresets={linkPresets}
          toolbarRef={toolbarRef}
          editableContentRef={editableContentRef}
          onOpen={onRTToolbarOpen}
          onClose={onRTToolbarClose}
          onFormat={onRTToolbarFormat}
          onHyperlinkModal={onHyperlinkModal}
          hideChevron
        >
          {children}
        </PortalRichTextToolbar>
      )}
    </AnimatePresence>,
    portalEl
  )
}

function useMouseEvents(
  forwardedRef: React.RefObject<HTMLDivElement>,
  focusedRef: React.RefObject<boolean>,
  { readonly }: EditableProps
) {
  const stopPropagation = useStopClickPropagation({ preventDefault: true })

  // Normally the editor gains focus and starts editing on mouse down. This prevents the editor from gaining focus
  // until a complete click is performed. This is useful for allowing drag-and-drop to have precedence over editing.
  const onMouseDown = React.useCallback(
    (e: React.MouseEvent) => {
      if (!focusedRef.current && !readonly) {
        stopPropagation(e)
      }
    },
    [focusedRef, readonly, stopPropagation]
  )

  const onClick = React.useCallback(
    (e: React.MouseEvent) => {
      if (!readonly) {
        if (!focusedRef.current) {
          forwardedRef.current?.focus()
        }

        // select the entire anchor tag
        const selection = window.getSelection()
        let element: HTMLElement = selection?.focusNode as HTMLElement
        while (
          element &&
          element.nodeType !== Node.ELEMENT_NODE &&
          element !== forwardedRef.current
        ) {
          element = element.parentElement as HTMLElement
        }
        if (element?.tagName === TagNames.Anchor) {
          caretHelper.selectElement(element)
        }
      } else {
        e.stopPropagation()
      }
    },
    [focusedRef, forwardedRef, readonly]
  )

  return React.useMemo(
    () => ({
      onClick,
      onMouseDown,
    }),
    [onClick, onMouseDown]
  )
}

function useKeyboardEvents(
  inputRef: React.RefObject<HTMLElement | null>,
  { onKeyDown, onKeyUp: onKeyUpProp, onChange, autocomplete }: EditableProps
) {
  const emitChange = React.useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      // Do not decorate pasted (ctrl + v) text
      if (event.key === "v" && event.nativeEvent.composed) {
        return
      }

      // check if user is typing a composed character, like é or ñ, in this case just ignore
      // the first Alt + e, etc. event because the `getWordAtCaretPosition` will break the composition
      if (event.nativeEvent.isComposing) return

      const element = inputRef.current
      if (!element) return

      const { key } = event
      if (key === Keys.Escape) {
        onKeyDown?.(event)
        setTimeout(() => element.blur(), 100)
        return
      }

      if (key.length > 1) {
        return
      }
      // TODO: mobile support
      if (isMobile) return

      const prev = element.innerHTML
      const currentElement = caretHelper.getChildElementAtCaretPosition(element)
      const decorator = mapDecorator(currentElement?.getAttribute("autocomplete"))
      const type = decorator || autocomplete?.[0]

      ContentDecorator.decorate(element, type)
      if (prev !== element.innerHTML) {
        onChange?.()
      }
    },
    [inputRef, autocomplete, onKeyDown, onChange]
  )

  const onKeyUp = React.useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      onKeyUpProp?.(event)
      emitChange(event)
    },
    [emitChange, onKeyUpProp]
  )

  return React.useMemo(
    () => ({
      onKeyUp,
      onKeyDown,
      onChange,
    }),
    [onChange, onKeyDown, onKeyUp]
  )
}

function useFocusEvents(
  { disabled, readonly, onFocus, onBlur, onChange, onSave, children }: EditableProps,
  forwardedRef: React.MutableRefObject<HTMLDivElement>
) {
  const { value: acOpen, setTrue: setACOpen, setFalse: setACClose } = useStateBoolean()
  const disabledClicks = React.useRef(false)
  const focusedRef = React.useRef(false)

  const onBoxFocus = React.useCallback(() => {
    if (readonly || disabled) return

    focusedRef.current = true
    onFocus?.()

    // set a small timeout to ensure any text processing is done before focusing
    setTimeout(() => caretHelper.placeAtEnd(forwardedRef.current), 100)

    // disable and re-enable clicks after focus to avoid bleed-through
    disabledClicks.current = true
    setTimeout(() => (disabledClicks.current = false), 500)
  }, [disabled, forwardedRef, onFocus, readonly])

  const onBoxBlur = React.useCallback(() => {
    if (acOpen) return
    focusedRef.current = false
    onBlur?.()
  }, [acOpen, onBlur])

  const onAutoCompleted = React.useCallback(() => {
    setACOpen()
    setTimeout(setACClose, 500)
  }, [setACClose, setACOpen])

  const onRTToolbarOpen = React.useCallback(() => {
    disabledClicks.current = true
  }, [])

  const onRTToolbarClose = React.useCallback(() => {
    disabledClicks.current = false
  }, [])

  const onRTToolbarFormat = React.useCallback(() => {
    onChange?.()
  }, [onChange])

  // When focusing the input box if there is an animation the click can bleed through
  const preventClickBleedThrough = React.useCallback((event: MouseEvent) => {
    if (disabledClicks.current) {
      event.preventDefault()
      event.stopImmediatePropagation()
    }
  }, [])
  const disableBodyClicks = disabled || disabledClicks.current || readonly
  useOnBodyClick(React.useRef(document.body), preventClickBleedThrough, disableBodyClicks)

  return React.useMemo(
    () => ({
      onBoxFocus,
      onBoxBlur,
      onAutoCompleted,
      onRTToolbarOpen,
      onRTToolbarClose,
      onRTToolbarFormat,
      focusedRef,
    }),
    [onAutoCompleted, onBoxBlur, onBoxFocus, onRTToolbarClose, onRTToolbarOpen, onRTToolbarFormat]
  )
}

function useClipboardEvents(
  inputRef: React.RefObject<HTMLElement | null>,
  { onChange, disabled, readonly }: EditableProps
) {
  const onPaste = React.useCallback(
    (event: React.ClipboardEvent<HTMLElement>) => {
      const element = inputRef.current
      // Extract data
      const pastedData = event.clipboardData.getData("text/html")

      if (!element || !pastedData) return
      if (disabled || readonly) return

      event.preventDefault()

      const sanitizedFragment = DOMSanitize.sanitize(convertToDigitsEntityTags(pastedData), {
        removeAnchors: true,
      })

      caretHelper.insertAtCaret(element, sanitizedFragment)
      onChange?.()
    },
    [disabled, inputRef, onChange, readonly]
  )

  return React.useMemo(() => ({ onCopy: undefined, onCut: undefined, onPaste }), [onPaste])
}
