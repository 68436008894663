import * as React from "react"
import { Route, Switch } from "react-router-dom"
import { type DigitsRoute } from "@digits-shared/components/Router/DigitsRoute"
import { LoggedRedirect } from "@digits-shared/components/Router/LoggedRedirect"
import { ProtectedRoute } from "@digits-shared/components/Router/ProtectedRoute"
import { useCurrentRoute } from "@digits-shared/hooks/useCurrentRoute"
import useRouter from "@digits-shared/hooks/useRouter"
import useSession from "@digits-shared/hooks/useSession"
import ApplicationsDirectory from "src/frontend/components/OS/Applications/ApplicationsDirectory"
import { COMMON_APPLICATION_PARAMETERIZE_PATHS } from "src/frontend/components/OS/Applications/hooks/useLegalEntityRedirects"
import { useSearchKeyLauncher } from "src/frontend/components/OS/Applications/Search/useSearchKeyLauncher"
import { Home } from "src/frontend/components/OS/Home/Home"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import routes from "src/frontend/routes"
import type FrontendSession from "src/frontend/session"
import NotFound from "src/shared/components/Elements/PageNotFound"

export const OSContent: React.FC = () => {
  const session = useSession<FrontendSession>()
  const currentOrgSlug = session.currentOrganization?.slug
  const generatePath = useFrontendPathGenerator()
  const { location } = useRouter()

  return (
    <Switch location={location}>
      {/* App Directory Routing */}
      <Route
        path={[routes.organization.parameterizedPath, routes.legalEntity.parameterizedPath]}
        render={() => (
          <ProtectedRoute
            redirectTo={generatePath(routes.login)}
            component={LegalEntityApplications}
          />
        )}
      />

      {/* Connector & Link Routing */}
      <Route
        path={[routes.connectorOauth.parameterizedPath, routes.link.parameterizedPath]}
        render={() => (
          <ProtectedRoute
            redirectTo={generatePath(routes.login)}
            component={ApplicationsDirectory}
          />
        )}
      />

      {/* Shared Applications (support, internal features, etc) */}
      <Route
        path={COMMON_APPLICATION_PARAMETERIZE_PATHS}
        render={() => (
          <ProtectedRoute
            redirectTo={generatePath(routes.login)}
            component={LegalEntityApplications}
          />
        )}
      />

      {/* Root /billing */}
      <Route
        path="/billing"
        render={() => (
          <ProtectedRoute redirectTo={generatePath(routes.login)}>
            <LoggedRedirect
              name="OSContent-rootBilling"
              to={
                currentOrgSlug
                  ? routes.organizationBilling.generate({ orgSlug: currentOrgSlug })
                  : routes.root.generateFromCurrentPath()
              }
            />
          </ProtectedRoute>
        )}
      />

      {/* App Directory Routing (exact matching) */}
      <Route
        exact
        path={[routes.dashboard.parameterizedPath, routes.root.parameterizedPath]}
        render={() => (
          <ProtectedRoute
            redirectTo={generatePath(routes.login)}
            component={LegalEntityApplications}
          />
        )}
      />

      <Route path="*" component={NotFound} />
    </Switch>
  )
}

/**
 * Renders the home view that is at the root of our application hierarchy.
 * These applications and routes require of an LE or org slug
 */
const LegalEntityApplications: React.FC = () => {
  const { location } = useRouter()

  const generatePath = useFrontendPathGenerator()
  const { isDeprecatedAccountingRoute, to } = useRequiresDeprecatedAccountingRouteRedirect()
  useSearchKeyLauncher()

  if (location.name === routes.legalEntity.name) {
    return (
      <LoggedRedirect
        name="OSContent-legalEntityHome-homeExperience"
        to={generatePath(routes.legalEntityHome)}
      />
    )
  }

  if (isDeprecatedAccountingRoute && to) {
    return <LoggedRedirect name="replaceLegacyAccountingRoute" to={to} />
  }

  return (
    <Home>
      <ApplicationsDirectory />
    </Home>
  )
}

function useRequiresDeprecatedAccountingRouteRedirect() {
  const { location } = useRouter()
  const currentRoute = useCurrentRoute(routes)
  const parameters = currentRoute.getParametersFromPath(location.pathname)
  const checkRoute = (route: DigitsRoute) => route.isRouteOrChildOfRoute(currentRoute)
  const generateTo = (route: DigitsRoute) => route.generate(parameters)

  switch (true) {
    case checkRoute(routes.accountingTransactionDashboardDeprecated):
      return {
        isDeprecatedAccountingRoute: true,
        to: generateTo(routes.accounting),
      }
    case checkRoute(routes.accountingDeprecated):
      return {
        isDeprecatedAccountingRoute: true,
        to: generateTo(routes.accounting),
      }
    default:
      return {
        isDeprecatedAccountingRoute: false,
        to: undefined,
      }
  }
}
