import * as React from "react"

/**
 * If you want to obtain an element to host a modal dialog, prefer: useModalRoot().
 *
 * Intended for use with ReactDOM.createPortal(). Maintains two elements in the DOM:
 *
 * First is the root element. The root is a container for portal hosts.
 * It is given the provided ID and appended at the end of the body. It
 * is removed when empty.
 *
 * Second is the host element. It is what the React Portal mounts your component into.
 * It is appended as a child of the root element, and removed on unmount.
 *
 * E.g.:
 *   const Modal: React.FC = ({ children }) => {
 *     const portalEl = usePortalElement("id-of-host-el")
 *     return ReactDOM.createPortal(children, portalEl)
 *   }
 */
export function usePortalElement(
  id: string,
  removeRootOnUnmount = true,
  parentRef: HTMLElement | string = document.body,
  insertPosition: InsertPosition = "beforeend"
) {
  const hostElRef = React.useRef<HTMLElement>()

  React.useEffect(() => {
    let rootEl = document.querySelector<HTMLElement>(`#${id}`)
    const parentElement =
      rootEl?.parentElement ||
      (typeof parentRef === "string" ? document.querySelector<HTMLElement>(parentRef) : parentRef)

    if (!parentElement) {
      TrackJS?.console.error("Creating Portal element with incorrect parent ref: ", parentRef)
      return
    }

    // If there is no existing DOM element, create a new one.
    if (!rootEl) {
      rootEl = document.createElement("div")
      rootEl.setAttribute("id", id)
      parentElement.insertAdjacentElement(insertPosition, rootEl)
    }

    if (hostElRef.current) {
      rootEl.appendChild(hostElRef.current)
    }

    return () => {
      hostElRef.current?.remove()
      if (rootEl && rootEl.childNodes.length < 1 && removeRootOnUnmount) {
        rootEl.remove()
      }
    }
  }, [id, parentRef, insertPosition, removeRootOnUnmount])

  // Need to make sure we don't re-create the Ref initial value on every render
  // https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily
  function getHostEl() {
    if (!hostElRef.current) {
      hostElRef.current = document.createElement("div")
    }
    return hostElRef.current
  }

  return getHostEl()
}
