import * as React from "react"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import {
  SPRINGBOARD_DETAILS_CONTENT_ASIDE_INTERIOR_MARGIN_TOP,
  SPRINGBOARD_DETAILS_CONTENT_ASIDE_WIDTH,
  SPRINGBOARD_DETAILS_CONTENT_BORDER_RADIUS,
  SPRINGBOARD_DETAILS_CONTENT_PADDING,
  SPRINGBOARD_DETAILS_CONTENT_SPACING_LEFT,
  SPRINGBOARD_DETAILS_CONTENT_SPACING_RIGHT,
  SPRINGBOARD_DETAILS_HIDE_WHEN_SCROLL_Z_INDEX,
  SPRINGBOARD_DETAILS_NAV_FULL_MARGIN_BOTTOM,
  SPRINGBOARD_DETAILS_NAV_HEIGHT,
} from "src/frontend/components/OS/Details/Shared/Styles/shared"

export const ContentInfoAsideContainer = styled.aside`
  width: ${SPRINGBOARD_DETAILS_CONTENT_ASIDE_WIDTH}px;
  flex-shrink: 0;
  z-index: ${SPRINGBOARD_DETAILS_HIDE_WHEN_SCROLL_Z_INDEX + 2};
  border-radius: ${SPRINGBOARD_DETAILS_CONTENT_BORDER_RADIUS}px;
  padding-bottom: 20px;
`

export const SpringboardDetailsContentAside = styled.div`
  display: flex;
  gap: ${SPRINGBOARD_DETAILS_CONTENT_PADDING}px;
  flex-direction: column;
  max-height: 100%;

  // height: calc(
  //   100% - ${SPRINGBOARD_DETAILS_NAV_HEIGHT + SPRINGBOARD_DETAILS_NAV_FULL_MARGIN_BOTTOM}px
  // );

  // Do not set overflow.. it will break floating containers
  //overflow: hidden;

  position: relative;
  justify-content: flex-start;
  transition: background-color 200ms ease-in;
  text-transform: none;
  padding: ${SPRINGBOARD_DETAILS_CONTENT_PADDING}px 0 0;
  border-radius: ${SPRINGBOARD_DETAILS_CONTENT_BORDER_RADIUS}px;

  background: #e9eff4;
  color: ${colors.secondary};
`

export const SpringboardDetailsContentScrollArea = styled.section`
  display: flex;
  flex-direction: column;
  gap: 17px;
  width: 0;
  flex-grow: 1;
  overflow-y: auto;
  color: ${colors.secondary};
  border-radius: ${SPRINGBOARD_DETAILS_CONTENT_BORDER_RADIUS}px
    ${SPRINGBOARD_DETAILS_CONTENT_BORDER_RADIUS}px 0 0;
`

export const DeeperDiveContainer = styled.div`
  background: #e9eff4;
  background-blend-mode: luminosity;
  border: 1px solid ${colors.white};
  border-radius: ${SPRINGBOARD_DETAILS_CONTENT_BORDER_RADIUS}px;
  margin-bottom: 20px;
`

export const SpringboardDetailsContentHeaderContainer = styled.div`
  z-index: ${SPRINGBOARD_DETAILS_HIDE_WHEN_SCROLL_Z_INDEX + 2};
  background: ${colors.white};
  border-radius: ${SPRINGBOARD_DETAILS_CONTENT_BORDER_RADIUS}px;
`

export const DimensionCardContainer = styled.div`
  background: ${colors.white};
  border-radius: 8px;
  padding: ${SPRINGBOARD_DETAILS_CONTENT_PADDING}px;
  margin: 0 ${SPRINGBOARD_DETAILS_CONTENT_PADDING}px;
  z-index: 1;

  &:last-child {
    margin-bottom: ${SPRINGBOARD_DETAILS_CONTENT_PADDING}px;
  }
`

export const DimensionNameAndIcon = styled(DimensionCardContainer)`
  margin-top: ${SPRINGBOARD_DETAILS_CONTENT_ASIDE_INTERIOR_MARGIN_TOP}px;
  display: flex;
  align-items: center;
  gap: 12px;
`

const DeeperDiveSpacing = styled.div`
  padding-bottom: 15px;
`

export interface ContentElementProps {
  contentElementRef: React.MutableRefObject<HTMLDivElement | null>
  children?: React.ReactNode
}

export const SpringboardDetailsContentHeader: React.FC<ContentElementProps> = ({ children }) => (
  <SpringboardDetailsContentHeaderContainer>{children}</SpringboardDetailsContentHeaderContainer>
)

const DeeperDiveContentContainerStyled = styled.div`
  padding: 8px ${SPRINGBOARD_DETAILS_CONTENT_SPACING_RIGHT}px 0
    ${SPRINGBOARD_DETAILS_CONTENT_SPACING_LEFT}px;
`

export const DeeperDiveContentContainer: React.FC<ContentElementProps> = ({
  contentElementRef,
  children,
}) => (
  <DeeperDiveContentContainerStyled>
    <DeeperDiveSpacing>{children}</DeeperDiveSpacing>
  </DeeperDiveContentContainerStyled>
)
