import * as React from "react"
import { Interval } from "@digits-graphql/frontend/graphql-bearer"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import { AxisBottom, type TickFormatter, type TickLabelProps } from "@visx/axis"
import { type ScaleBand } from "d3-scale"
import dayjs from "@digits-shared/initializers/dayjs/dayjs"
import { useStackableBarChartContext } from "src/frontend/components/OS/Shared/Charts/StackableBarChart/StackableBarChartContext"

const DATE_CUTOFF_WIDTH = 380

/*
  COMPONENTS
*/

interface Props {
  xScale: ScaleBand<number>
  top: number
  width: number
  height: number
}

const axisStyles: TickLabelProps<number> = (startedAt: number) => ({
  fill: colors.secondary,
  fontSize: 10,
  textAnchor: "middle" as const,
  fontFamily: fonts.family.avenir,
  fontWeight: fonts.weight.heavy,
  letterSpacing: 0,
  dx: 0,
  dy: "0.5em",
})

export const StackableBarChartAxis: React.FC<Props> = ({ xScale, top, width, height }) => {
  const periodsLength = xScale.domain().length
  const axisFormat = useXAxisFormat(periodsLength, width, height)

  return (
    <AxisBottom
      hideTicks
      hideAxisLine
      top={top + 2}
      left={0}
      numTicks={periodsLength}
      scale={xScale}
      tickLabelProps={axisStyles}
      tickLength={0}
      tickClassName="month-axis"
      tickFormat={axisFormat}
    />
  )
}

function useXAxisFormat(
  periodsLength: number,
  width: number,
  height: number
): TickFormatter<number> {
  const { tooltipData, currentPeriodBarData, intervalOrigin, alwaysShowAxisTicks } =
    useStackableBarChartContext()

  return React.useCallback(
    (startedAt: number, index: number) => {
      if (
        !alwaysShowAxisTicks &&
        tooltipData?.period.startedAt !== startedAt &&
        !currentPeriodBarData?.[startedAt]
      )
        return

      // if space is too small... skip every other tick
      const tooSmall = width <= DATE_CUTOFF_WIDTH
      if (periodsLength > 5 && tooSmall && index % 2 === 0) return ""

      const date = dayjs.unix(startedAt).utc()

      switch (intervalOrigin.interval) {
        case Interval.Year:
          return date.format("YYYY")

        case Interval.Day:
          return date.date() === 1 || index === 0 ? date.format("MMM D") : date.format("D")

        default:
          return !tooSmall && (date.month() === 0 || index === 0)
            ? date.format("MMM 'YY")
            : date.format("MMM")
      }
    },
    [
      alwaysShowAxisTicks,
      tooltipData?.period.startedAt,
      currentPeriodBarData,
      periodsLength,
      width,
      intervalOrigin.interval,
    ]
  )
}
