import * as React from "react"
import { LoadingBlock } from "@digits-shared/components/Loaders"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"

/*
  STYLES
*/

const ListItem = styled.li`
  padding: 0 16px;
`

/*
  COMPONENTS
*/

export const LoadingRows: React.FC = () => (
  <>
    <StyledLoadingBlock />
    <StyledLoadingBlock />
    <StyledLoadingBlock />
  </>
)

const StyledLoadingBlock: React.FC = () => (
  <ListItem>
    <LoadingBlock
      width="100%"
      height={60}
      backgroundColor={colors.translucentWhite50}
      $shineColor={colors.white}
      borderRadius="8px"
      display="block"
    />
  </ListItem>
)
