import * as React from "react"
import { Link } from "react-router-dom"
import { type ActionItemFieldsFragment } from "@digits-graphql/frontend/graphql-bearer"
import colors from "@digits-shared/themes/colors"
import styled, { css } from "styled-components"
import dayjs from "@digits-shared/initializers/dayjs/dayjs"
import { BillRow } from "src/frontend/components/Shared/NavSidebar/ActionItems/Row/BillRow"
import { ReportRow } from "src/frontend/components/Shared/NavSidebar/ActionItems/Row/ReportRow"
import { ThreadRow } from "src/frontend/components/Shared/NavSidebar/ActionItems/Row/ThreadRow"
import { useGenerateActionItemPath } from "src/frontend/components/Shared/NavSidebar/ActionItems/Row/useGenerateActionItemPath"

/*
  STYLES
*/

const RowListItem = styled.li`
  display: contents;
`

const StyledLink = styled(Link)<{ disabled: boolean }>`
  margin: 0 16px 0 16px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  user-select: none;
  cursor: pointer;
  transition: all 100ms ease-in;
  padding: 12px;

  background-color: ${colors.translucentWhite60};

  &:hover {
    background-color: ${colors.white};
    box-shadow: 0 4px 20px ${colors.translucentBlack06};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      filter: grayscale(1);
      pointer-events: none;
      background: ${colors.translucentSecondary05};
    `}
`

/*
  COMPONENTS
*/

export const Row: React.FC<{ item: ActionItemFieldsFragment; hideSidebar: () => void }> = ({
  item,
  hideSidebar,
}) => {
  const to = useGenerateActionItemPath(item)
  const isDisabled = !to
  return (
    <RowListItem>
      <StyledLink
        to={to}
        disabled={isDisabled}
        onClick={isWorkItemThread(item) ? hideSidebar : undefined}
      >
        <RowContents item={item} hideSidebar={hideSidebar} />
      </StyledLink>
    </RowListItem>
  )
}

const RowContents: React.FC<{ item: ActionItemFieldsFragment; hideSidebar: () => void }> = ({
  item,
}) => {
  const { value, title, author, updatedAt } = item
  const date = dayjs.unix(updatedAt).utc().fromNow()

  switch (value.__typename) {
    case "ActionItemBillApproval":
      return (
        <BillRow
          author={author}
          title={title}
          amount={value.bill.totalAmount}
          dueDate={value.bill.dueDate}
          date={date}
        />
      )
    case "ActionItemThread": {
      return <ThreadRow author={author} date={date} targetEntity={value.targetEntity} />
    }
    case "ActionItemPublishedReport":
      return <ReportRow title={title} author={author} date={date} />

    default:
      return null
  }
}

function isWorkItemThread(actionItem: ActionItemFieldsFragment) {
  return (
    actionItem.value.__typename === "ActionItemThread" &&
    actionItem.value.targetEntity.__typename === "EntityWorkItem"
  )
}
