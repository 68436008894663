import type * as React from "react"

export interface DimensionSort {
  title: string
  TitleIconElement: React.ElementType
  selected?: boolean
  onSelect: () => void
}

export enum DimensionSortBy {
  Date = "Date",
  Amount = "Amount",
  Name = "Name",
  Delta = "Delta",
  TransactionCount = "TransactionCount",
}

export enum DimensionActivity {
  All = "All",
  Active = "Active",
  Growing = "Growing",
  Shrinking = "Shrinking",
}

export const DEFAULT_DIMENSION_ACTIVITY = DimensionActivity.Active
