import * as React from "react"

export interface DetailsViewContextProps {
  closeView: () => void
  hideCloseButton: () => void
  showCloseButton: () => void
  overrideBackLabel: (label: string | undefined) => void
  backLabel?: string
}

/**
 * Context Consumer and Provider for Details View Context
 */
export const DetailsViewContext = React.createContext<DetailsViewContextProps>({
  closeView: () => {},
  hideCloseButton: () => {},
  showCloseButton: () => {},
  overrideBackLabel: () => {},
})

export const DetailsViewContextProvider = DetailsViewContext.Provider
