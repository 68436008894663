import * as React from "react"
import { DetailsViewContext } from "@digits-shared/components/Contexts/DetailsViewContext"

/**
 * Returns a `DetailsViewContext` to access helpers for Details Views
 */
export default function useDetailsViewContext() {
  const context = React.useContext(DetailsViewContext)

  // Memoize so that a new object is only returned if something changes
  return React.useMemo(() => context, [context])
}
