import { type StaticRoutes } from "@digits-shared/components/Router/DigitsRoute"
import { HistoryType } from "@digits-shared/components/Router/History"
import {
  type SwitchableHistoryDelegate,
  SwitchableRouter,
  type SwitchableRouterProps,
} from "src/shared/components/Router"

export class ContentViewRouter<SRC extends StaticRoutes> extends SwitchableRouter<SRC> {
  constructor(props: SwitchableRouterProps<SRC>) {
    super(props)
    this.configureHistory()
  }

  historyDelegate: SwitchableHistoryDelegate = {
    shouldNotifyMemoryListener: (activeHistory, memoryHistory, browserHistory, location) =>
      activeHistory() === memoryHistory,

    shouldNotifyBrowserListener: (activeHistory, memoryHistory, browserHistory, location) =>
      !this.inNonContentView(location.name),
  }

  historyType(locationName: string) {
    return this.inNonContentView(locationName) ? HistoryType.Memory : HistoryType.Browser
  }
}
