import * as React from "react"
import { Theme } from "@digits-shared/themes/theme"
import { useThemeMode } from "@digits-shared/themes/ThemeContext"

export type ThemeComponents<P = object> = {
  [Theme.Light]: React.ComponentType<P>
  [Theme.Dark]: React.ComponentType<P>
  [Theme.Print]?: React.ComponentType<P>
}

/**
 * Accepts an object that provides React.FCs (incl. styled-components) for keys matching
 * each theme defined in the Theme enum. Both Components must contain the same props interface.
 *
 * When used inside another React Component, it will determine the currently active theme from the
 * context, and return the Component for that key, which will render a JSX Element
 *
 * Example:
 *
 * const ThemedDelta = themedComponent({
 *  light: DeltaPill,
 *  dark: Delta,
 * })
 *
 * const Total: React.FC = () => {
 *   return (
 *     <span>your total was $0.00</span>
 *     <ThemedDelta amount={0} delta={0} ...>
 *   )
 * }
 *
 */
export function themedComponent<
  P extends React.JSX.IntrinsicAttributes & { children?: React.ReactNode },
>(components: ThemeComponents<P>) {
  return function useComponentLookup(props: P) {
    const mode = useThemeMode()
    let ThemedComponent: React.ComponentType<P>
    if (mode === Theme.Print) {
      ThemedComponent = components[Theme.Print] ?? components[Theme.Light]
    } else {
      ThemedComponent = components[mode]
    }
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <ThemedComponent {...props} />
  }
}
