import * as React from "react"
import { DepartmentChartConfig } from "src/frontend/components/Shared/Layout/Components/Departments/DepartmentChartConfig"
import { type DepartmentSummaries } from "src/frontend/components/Shared/Layout/Components/Departments/filterDepartmentAncestors"
import { type MatchedConfig, type SizingProps } from "src/frontend/components/Shared/Layout/types"
import { useRemoveConfig } from "src/frontend/components/Shared/Portals/ConfigsSidebar/ComponentConfigContext"
import { usePortalStore } from "src/frontend/components/Shared/Portals/State/portalStore"

/*
  INTERFACES
*/

interface ChartConfigProps extends SizingProps {
  config: MatchedConfig<"departmentChart">
  skipAnimations: boolean
}

/*
  COMPONENTS
*/
export const DepartmentPortalChartConfig: React.FC<ChartConfigProps> = ({
  config,
  height,
  skipAnimations,
}) => {
  const removeConfig = useRemoveConfig()
  const layoutId = usePortalStore((state) => state.layout.layoutId)

  const onSummaries = React.useCallback(
    (summaries: DepartmentSummaries[]) => {
      if (!summaries.length) {
        removeConfig()
      }
    },
    [removeConfig]
  )

  return (
    <DepartmentChartConfig
      config={config}
      height={height}
      skipAnimations={skipAnimations}
      layoutId={layoutId}
      onSummaries={onSummaries}
    />
  )
}
