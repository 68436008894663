import * as React from "react"
import { Link } from "react-router-dom"
import {
  DirectionFromOrigin,
  Interval,
  useListActionItemsBadgeCountsQuery,
  useNotificationCreatedSubscription,
} from "@digits-graphql/frontend/graphql-bearer"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgMail01 } from "@digits-shared/components/SVGIcons/line/Mail01.svg"
import { SvgMessageCheckSquare } from "@digits-shared/components/SVGIcons/line/MessageCheckSquare.svg"
import { type ButtonProps, DigitsButton } from "@digits-shared/DesignSystem/Button"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import useSession from "@digits-shared/hooks/useSession"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import { DebuggingUserPicker } from "src/frontend/components/Shared/NavSidebar/ActionItems/ActionItemsDebugging"
import { useActionItemFilter } from "src/frontend/components/Shared/NavSidebar/ActionItems/useActionItemFilter"
import { type GroupView } from "src/frontend/components/Shared/NavSidebar/ActionItems/useGroupView"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import { useInternalUserSettings } from "src/frontend/hooks/useInternalUserSettings"
import routes from "src/frontend/routes"
import type FrontendSession from "src/frontend/session"

/*
  STYLES
*/

const HeaderContainer = styled.div`
  padding: 0 16px;
`

const ActionItemsIcon = styled(SvgMessageCheckSquare)`
  width: 20px;
  height: 20px;
  ${svgPathStyles(colors.secondary, 2.5)};
`

const InboxIcon = styled(SvgMail01)`
  width: 20px;
  height: 20px;
  ${svgPathStyles(colors.secondary, 2)};
`

const HeaderText = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  margin-top: 21px;
  flex-shrink: 0;
  font-weight: ${fonts.weight.heavy};
  font-size: 16px;
  user-select: none;
  gap: 8px;
`

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
`

const ButtonGroup = styled.div`
  display: flex;
`

const GroupViewButton = styled(DigitsButton)<ButtonProps & { $viewActive: boolean }>`
  color: ${({ $viewActive }) => ($viewActive ? colors.secondary : colors.lightDark)};
  transition: color 100ms ease-in-out;
  padding: 0px 8px;
  &:hover:not([disabled]) {
    background: transparent;
    color: ${colors.secondary};
  }
  &:focus:not([disabled]) {
    background: transparent;
  }
`

const Badge = styled.div<{ $isSelected: boolean }>`
  display: inline-block;
  background: ${({ $isSelected }) => ($isSelected ? "#00ffbe" : colors.secondary10)};
  font-weight: ${fonts.weight.heavy};
  color: ${colors.secondary};
  padding: 2px 8px;
  border-radius: 8px;
  line-height: 12px;
  font-size: 10px;
`

/*
  COMPONENTS
*/

interface GroupViewProps {
  groupView: GroupView
  setGroupView: (view: GroupView) => void
}

export const ActionItemsHeader: React.FC<GroupViewProps> = ({ groupView, setGroupView }) => {
  const showAssignmentUI = useInternalUserSettings().actionItemAssignments
  const tabCounts = useTabBadgeCounts()
  const { currentLegalEntity } = useSession<FrontendSession>()
  const pathGenerator = useFrontendPathGenerator()
  return (
    <HeaderContainer>
      <HeaderText>
        {showAssignmentUI ? <InboxIcon /> : <ActionItemsIcon />}
        <Link
          css="flex: 1"
          to={
            currentLegalEntity
              ? pathGenerator(routes.clientTransactionReview, {
                  leSlug: currentLegalEntity.slug,
                  tab: groupView.toLocaleLowerCase(),
                })
              : ""
          }
        >
          {showAssignmentUI ? "Inbox" : "Action Items"}
        </Link>
        <DebuggingUserPicker />
      </HeaderText>
      <HeaderContent>
        <ButtonGroup>
          {showAssignmentUI && (
            <>
              <GroupTab
                autoFocus
                selectedGroupView={groupView}
                tabCount={tabCounts?.assigned}
                groupView="Assigned"
                setGroupView={setGroupView}
              >
                Assigned
              </GroupTab>
              <GroupTab
                selectedGroupView={groupView}
                tabCount={tabCounts?.watched}
                groupView="Watched"
                setGroupView={setGroupView}
              >
                Watching
              </GroupTab>
            </>
          )}
          <GroupTab
            autoFocus={!showAssignmentUI}
            selectedGroupView={groupView}
            tabCount={tabCounts?.open}
            groupView="Open"
            setGroupView={setGroupView}
          >
            Open
          </GroupTab>
          <GroupTab selectedGroupView={groupView} groupView="Resolved" setGroupView={setGroupView}>
            {showAssignmentUI ? "Closed" : "Resolved"}
          </GroupTab>
        </ButtonGroup>
      </HeaderContent>
    </HeaderContainer>
  )
}

const GroupTab: React.FC<
  {
    autoFocus?: boolean
    selectedGroupView: GroupView
    tabCount?: number
    groupView: GroupView
    setGroupView: (view: GroupView) => void
  } & React.PropsWithChildren
> = ({ autoFocus, selectedGroupView, tabCount, groupView, setGroupView, children }) => {
  const onClick = React.useCallback(() => {
    setGroupView(groupView)
  }, [groupView, setGroupView])

  const active = selectedGroupView === groupView
  return (
    <GroupViewButton
      autoFocus={autoFocus}
      $variant="ghost-dark"
      size="medium"
      $viewActive={active}
      onClick={onClick}
    >
      {children} {!!tabCount && <Badge $isSelected={active}>{tabCount}</Badge>}
    </GroupViewButton>
  )
}

function useTabBadgeCounts() {
  const { currentLegalEntityId } = useSession<FrontendSession>()
  const showAssignmentUI = useInternalUserSettings().actionItemAssignments
  const baseVariables = {
    origin: dateTimeHelper.todayIntervalOrigin(Interval.Month, 48),
    direction: DirectionFromOrigin.Past,
    legalEntityIds: [currentLegalEntityId],
  }

  const { data: assigned, refetch: refetchAssigned } = useListActionItemsBadgeCountsQuery({
    variables: {
      ...baseVariables,
      filter: useActionItemFilter("Assigned"),
    },
    skip: !showAssignmentUI,
  })

  const { data: watched, refetch: refetchWatched } = useListActionItemsBadgeCountsQuery({
    variables: {
      ...baseVariables,
      filter: useActionItemFilter("Watched"),
    },
    skip: !showAssignmentUI,
  })

  const { data: open, refetch: refetchOpen } = useListActionItemsBadgeCountsQuery({
    variables: {
      ...baseVariables,
      filter: useActionItemFilter("Open"),
    },
    skip: !showAssignmentUI,
  })

  useNotificationCreatedSubscription({
    onData: (subscriptionData) => {
      if (
        currentLegalEntityId &&
        subscriptionData.data.data?.notificationCreated.id &&
        subscriptionData.data.data.notificationCreated.legalEntityId === currentLegalEntityId
      ) {
        refetchAssigned()
        refetchWatched()
        refetchOpen()
      }
    },
  })

  return {
    assigned: assigned?.listActionItemsBadgeCounts?.[0]?.count ?? 0,
    watched: watched?.listActionItemsBadgeCounts?.[0]?.count ?? 0,
    open: open?.listActionItemsBadgeCounts?.[0]?.count ?? 0,
  }
}
