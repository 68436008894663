import { usePortalStore } from "src/frontend/components/Shared/Portals/State/portalStore"
import { useIsEditLayoutActive } from "src/frontend/components/Shared/Portals/State/useIsEditLayoutActive"

export function useDragAndDropDisabled(): boolean {
  const textInFocus = usePortalStore((state) => state.textInFocus)
  // const mode = useReportPackageMode()
  // const activelyCommenting = useIsActivelyCommenting()
  const isEditLayoutActive = useIsEditLayoutActive()

  return !isEditLayoutActive || textInFocus
}
