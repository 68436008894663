import * as React from "react"
import { Interval, type MonetaryValue, type Period } from "@digits-graphql/frontend/graphql-bearer"
import { LoadingBlock } from "@digits-shared/components/Loaders"
import { Delta } from "@digits-shared/components/UI/Elements/Delta"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"
import dayjs from "@digits-shared/initializers/dayjs/dayjs"

export const SummaryHeader = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 20px 15px 20px;
`

const StyledSummaryDate = styled.div`
  position: relative;
  align-items: center;
  display: flex;
`

export const SummaryTitle = styled.span`
  font-weight: ${fonts.weight.medium};
  font-size: 16px;
  padding-right: 10px;
  text-transform: uppercase;
`

export const SummarySubtitle = styled.span`
  font-weight: ${fonts.weight.normal};
  font-size: 13px;
  color: ${colors.theme.dark.link};
`

export const SummaryDetails = styled.div<{
  alignment?: "left" | "space-between" | "center"
  isStacked?: boolean
}>`
  align-items: center;
  display: flex;

  ${(props) => {
    switch (props.alignment) {
      case "left":
        return css`
          ${SummaryDetail} {
            &:first-child {
              padding-left: 0;
            }

            &:last-child {
              border-right: none;
            }
          }
        `

      case "space-between":
        return css`
          justify-content: space-between;

          ${SummaryDetail} {
            padding-right: 0;
            border-right: none;
          }
        `

      case "center":
      default:
        return css`
          justify-content: center;

          ${SummaryDetail} {
            &:last-child {
              padding-right: 0;
              border-right: none;
            }
          }
        `
    }
  }}

  ${(props) =>
    props.isStacked &&
    css`
      ${SummaryDetail} {
        flex-direction: column;
      }

      ${SummaryDetailValue} {
        padding-right: 0;
      }
    `};
`

export const SummaryDetailValue = styled.span`
  display: inline-flex;
  align-items: center;
  font-family: ${fonts.family.avenir};
  padding-right: 10px;
  font-size: 16px;
`

type SummaryAmountProps = { monetaryValue?: MonetaryValue }

// Note: This does not use `colors.money.outbound` because headers should be
// true black unless there is a strong reason to reflect a specific color.
// Mid-dark grays do not look good in header scenarios.
export const SummaryAmount = styled(SummaryDetailValue)<SummaryAmountProps>`
  color: ${({ monetaryValue }) =>
    monetaryValue && monetaryValue.amount > 0 ? colors.money.inbound : colors.white};
`

export const SummaryDelta = styled(Delta)`
  font-family: ${fonts.family.avenir};
  justify-content: flex-end;
`

export const SummaryDetailLabel = styled.span`
  display: flex;
  align-items: center;
  font-family: ${fonts.family.avenir};
  color: ${colors.regentGray};
  font-size: 10px;
  text-transform: uppercase;
`

export const SummaryDetail = styled.div<{
  alignment?: "left" | "right" | "center"
  size?: "16px" | "22px"
}>`
  align-items: center;
  display: inline-flex;
  padding: 0 ${({ size }) => (size === "22px" ? "30px" : "20px")};
  border-right: 1px solid ${colors.transparent};

  ${(props) =>
    props.alignment &&
    css`
      text-align: ${props.alignment};
    `}
  ${SummaryDetailValue} {
    font-size: ${({ size }) => size || "16px"};
  }

  ${SummaryDetailLabel} {
    font-size: ${({ size }) => (size === "22px" ? "11px" : "10px")};
  }
`

export const SummaryDate: React.FC<{ period?: Period; error?: Error; loading?: boolean }> = ({
  period,
  loading,
}) => {
  if (loading) {
    return <LoadingBlock display="inline-block" width="150px" height="20px" />
  }

  if (!period) {
    return (
      <StyledSummaryDate>
        <SummaryTitle>--</SummaryTitle>
      </StyledSummaryDate>
    )
  }

  // TODO: Remove UTC here once timestamps come back already in legal entity timezone offset
  const date = dayjs.unix(period.startedAt).utc()
  switch (period.interval) {
    case Interval.Year:
      return (
        <StyledSummaryDate>
          <SummaryTitle>{date.format("YYYY")}</SummaryTitle>
        </StyledSummaryDate>
      )
    case Interval.Month:
      return (
        <StyledSummaryDate>
          <SummaryTitle>{date.format("MMMM")}</SummaryTitle>
          <SummarySubtitle>{date.format("YYYY")}</SummarySubtitle>
        </StyledSummaryDate>
      )
    default:
      return null
  }
}

export const SummaryLoading: React.FC = () => (
  <SummaryHeader>
    <SummaryDate loading />
    <SummaryDetails>
      <SummaryDetail>
        <LoadingBlock display="inline-block" width="100px" height="20px" />
      </SummaryDetail>
    </SummaryDetails>
  </SummaryHeader>
)
