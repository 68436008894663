const SPACE_BETWEEN_THREADS = 45

/*
 INTERFACES
*/

export interface ThreadPopUpCoordinates {
  top: number
  left: number
  bottom: number
  right: number
  orientation: "landscape" | "portrait"
  anchor: {
    top: number
    left: number
  }
}

/*
 FUNCTIONS
*/
export function calculatePopUpCoordinates(
  context: string,
  popUp: HTMLElement | null
): ThreadPopUpCoordinates | undefined {
  const rect = findThreadTargetDOMRect(context)
  if (!rect) return

  const coordinates = {
    ...rect,
    anchor: {
      ...rect,
    },
  }

  const parentElement = popUp?.parentElement
  if (!popUp || !parentElement) {
    return coordinates
  }

  const aboveSibling = popUp.previousElementSibling as HTMLElement
  if (aboveSibling) {
    const siblingBottom = aboveSibling.offsetTop + aboveSibling.offsetHeight + SPACE_BETWEEN_THREADS
    if (siblingBottom > coordinates.top) {
      coordinates.top = siblingBottom
    }
  }

  return coordinates
}

export function findThreadTarget(context: string): Element | undefined {
  const contextSelector = context.split("/").map((contextId) => `[data-context-id="${contextId}"]`)
  const selectorParts = contextSelector.flat()

  // look for the target and keep trying its ancestors until found or no more
  while (selectorParts.length) {
    const selector = selectorParts.join(" ")

    try {
      const target = document.querySelector(selector)
      if (target) return target
      selectorParts.pop()
    } catch {
      TrackJS?.console.error(`Thread selector context (${context}) threw a DOM exception`)
    }
  }

  return undefined
}

export function findThreadTargetDOMRect(context: string) {
  const target = findThreadTarget(context)
  if (!target) return target

  const orientation = findThreadTargetPageOrientation(target)
  const { right, bottom } = target.getBoundingClientRect()
  const { top, left } = getOffsets(target)
  return {
    top,
    left,
    right,
    bottom,
    orientation,
  }
}

const getOffsets = (element: Element) => {
  let offsetTop = 0
  let offsetLeft = 0
  let parent = element as HTMLElement
  while (parent) {
    offsetTop += parent.offsetTop
    offsetLeft += parent.offsetLeft
    parent = parent.offsetParent as HTMLElement
  }

  return { top: Math.max(0, offsetTop - 80), left: offsetLeft }
}

function findThreadTargetPageOrientation(element: Element): "landscape" | "portrait" {
  const page = element.closest('[data-report-page="true"]')
  const orientationAttr = page?.getAttribute("orientation")
  return orientationAttr === "landscape" ? orientationAttr : "portrait"
}
