import * as React from "react"
import { Route, Switch } from "react-router-dom"
import { Delay, RingLogoLoader } from "@digits-shared/components/Loaders/RingLogo"
import { OnboardingStateProvider } from "@digits-shared/components/Onboarding/OnboardingContext"
import useConstant from "@digits-shared/hooks/useConstant"
import routes from "src/frontend/routes"

export const LoadingContent: React.FC = () => <RingLogoLoader delay={Delay.PageLoad} />

const Preview = React.lazy(() => import("src/frontend/components/Public/Preview"))

export const PublicContent: React.FC = () => {
  const objectSharingPaths = useConstant(() => routes.objectSharingPaths())

  return (
    <OnboardingStateProvider>
      <React.Suspense fallback={<LoadingContent />}>
        <Switch>
          <Route path={objectSharingPaths} component={Preview} />
        </Switch>
      </React.Suspense>
    </OnboardingStateProvider>
  )
}
