import * as React from "react"
import { StatementSize } from "src/frontend/components/Shared/Reports/Report/Components/Statements/shared"
import { useStatementSize } from "src/frontend/components/Shared/Reports/Report/Components/Statements/StatementContext"
import {
  isCategoryDetails,
  type RowDetails,
} from "src/frontend/components/Shared/Reports/Report/Components/Statements/toDetailsData"
import { useReportEntityLink } from "src/frontend/components/Shared/Reports/Report/hooks/useReportEntityLink"
import { useIsActivelyCommenting } from "src/frontend/components/Shared/Reports/ReportComments/hooks"

export function useRowDetailsLinks(details?: RowDetails) {
  const periodDetails = details?.periodDetails
  const isActivelyCommenting = useIsActivelyCommenting()
  const statementSize = useStatementSize()
  const category = isCategoryDetails(periodDetails) ? periodDetails.hover.entity : undefined
  const path = useReportEntityLink(category) || ""
  const disabled = isActivelyCommenting || statementSize === StatementSize.Condensed || !path

  // enable linking row items to detail pages if we are viewing this on the client portal
  return React.useMemo(
    () => ({
      disabled,
      path,
    }),
    [disabled, path]
  )
}
