import * as React from "react"
import { SortDirection } from "@digits-graphql/frontend/graphql-bearer"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import colors from "@digits-shared/themes/colors"
import styled, { css } from "styled-components"
import { type DisplayOptionAccessor } from "src/frontend/components/OS/Shared/DisplayOptions/DisplayOption"
import {
  DisplayOptionsColumn,
  DisplayOptionsColumnTitle,
} from "src/frontend/components/OS/Shared/DisplayOptions/Shared"
import { type DimensionSort } from "src/frontend/types/DimensionFilter"

/*
  STYLES
*/

export const SortDimensionCell = styled.div<{ selected?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 6px;

  padding-top: 10px;
  padding-bottom: 10px;
  width: 80px;

  font-size: 10px;

  opacity: ${({ selected }) => (selected ? 1 : 0.75)};

  &:hover {
    opacity: 1;
  }

  transition:
    background-color 150ms ease-out,
    border 150ms ease-out,
    box-shadow 150ms ease-out,
    opacity 150ms ease-out;

  ${({ selected }) =>
    selected
      ? css`
          border: 1px solid ${colors.secondary05};
          background: ${colors.secondary05};
        `
      : css`
          border: 1px solid ${colors.translucentSecondary20};
          background-color: ${colors.translucentWhite04};

          &:hover {
            border: 1px solid ${colors.secondary};
          }
        `};
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  svg {
    height: 32px;
    ${svgPathStyles(colors.secondary)};
  }
`

/*
  COMPONENTS
*/

interface Props {
  className?: string
  dimensions: DimensionSort[]
  sortDirection: DisplayOptionAccessor<SortDirection>
}

export const SortColumn: React.FC<Props> = ({ className, dimensions, sortDirection }) => {
  const onToggleDirection = React.useCallback(() => {
    sortDirection.set(
      sortDirection.get() === SortDirection.Ascending
        ? SortDirection.Descending
        : SortDirection.Ascending
    )
  }, [sortDirection])

  return (
    <DisplayOptionsColumn className={className}>
      <DisplayOptionsColumnTitle>Sort By</DisplayOptionsColumnTitle>
      {dimensions.map(({ title, TitleIconElement, selected, onSelect }) => (
        <SortDimensionCell
          key={title}
          selected={selected}
          onClick={selected ? onToggleDirection : onSelect}
        >
          <TitleIconElement />
          {title}
        </SortDimensionCell>
      ))}
    </DisplayOptionsColumn>
  )
}
