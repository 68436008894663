import * as React from "react"
import useLazyTimeout from "@digits-shared/hooks/useLazyTimeout"
import styled from "styled-components"

/*
  STYLES
*/

const ExplosionVideoContainer = styled.div`
  position: fixed;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  mix-blend-mode: screen;
  z-index: 10;
`

const BackdropBlur = styled.div<{ blurPx: number }>`
  z-index: 10;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  backdrop-filter: blur(${({ blurPx }) => blurPx}px);
`

/*
  COMPONENTS
*/

export const ExplosionVideo: React.FC<{ blurPx?: number; onEnded?: () => void }> = ({
  blurPx = 20,
  onEnded,
}) => {
  const explosionVideoRef = React.useRef<HTMLVideoElement | null>(null)

  const [playVideo] = useLazyTimeout(() => explosionVideoRef.current?.play(), 300)

  React.useEffect(() => {
    playVideo()
  }, [playVideo])

  return (
    <>
      {blurPx > 0 && <BackdropBlur blurPx={blurPx} />}
      <ExplosionVideoContainer>
        <video
          id="explosion-video"
          ref={explosionVideoRef}
          src={require("static/videos/explosion.mp4")}
          crossOrigin="anonymous"
          muted
          preload="auto"
          playsInline
          onEnded={onEnded}
        />
      </ExplosionVideoContainer>
    </>
  )
}
