import * as React from "react"

export type StateBoolean<T> = {
  value: T
  setTrue: () => void
  setFalse: () => void
  toggle: () => void
  setValue: React.SetState<boolean>
}

export default function useStateBoolean(initialValue?: boolean | (() => boolean)) {
  const [value, setValue] = React.useState(initialValue || false)

  return useSharedStateBoolean<boolean>(value, setValue)
}

export function useStateOptionalBoolean(initialValue?: boolean) {
  const [value, setValue] = React.useState(initialValue)

  return useSharedStateBoolean<boolean | undefined>(value, setValue)
}

function useSharedStateBoolean<T>(value: T, setValue: React.SetState<boolean>): StateBoolean<T> {
  const setTrue = React.useCallback(() => {
    setValue(true)
  }, [setValue])

  const setFalse = React.useCallback(() => {
    setValue(false)
  }, [setValue])

  const toggle = React.useCallback(() => {
    setValue((previousValue) => !previousValue)
  }, [setValue])

  return React.useMemo(
    () => ({
      value,
      setTrue,
      setFalse,
      toggle,
      setValue,
    }),
    [value, setTrue, setFalse, toggle, setValue]
  )
}
