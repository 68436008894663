import * as React from "react"
import { type SVGAttributes } from "react"
import envHelper from "@digits-shared/helpers/envHelper"

// In Production let the build optimizer eagerly load all the SVGs so they can
// be chunked together instead of doing 1kb requests
const EagerSVGIconComponent = React.lazy(() =>
  import("src/shared/components/Icons/EagerSvgIcons").then((module) => ({
    default: module.EagerSVGIconComponent,
  }))
)

// In Development load the SVGs lazily to avoid fetching 800+ svgs on first load
const LazySVGIconComponent = React.lazy(() =>
  import("src/shared/components/Icons/LazySVGIcons").then((module) => ({
    default: module.LazySVGIconComponent,
  }))
)

const SVGComponent = envHelper.isDevelopment() ? LazySVGIconComponent : EagerSVGIconComponent

interface SVGProps {
  subjectDisplayKey: string
  preserveAspectRatio?: string
  className?: string
  additionalSVGAttributes?: SVGAttributes<SVGSVGElement>
}

export const SVGIconComponent: React.FC<SVGProps> = ({
  subjectDisplayKey,
  className,
  preserveAspectRatio,
  additionalSVGAttributes,
}) => (
  <React.Suspense fallback={<div className={className} />}>
    <SVGComponent
      svgName={subjectDisplayKey}
      className={className}
      preserveAspectRatio={preserveAspectRatio}
      additionalSVGAttributes={additionalSVGAttributes}
    />
  </React.Suspense>
)
