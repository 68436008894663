import * as React from "react"
import { Route, Switch } from "react-router-dom"
import { ModuleLoading } from "@digits-shared/components/Loaders"
import { LoggedRedirect } from "@digits-shared/components/Router/LoggedRedirect"
import { ProtectedRoute } from "@digits-shared/components/Router/ProtectedRoute"
import { isMobile } from "@digits-shared/helpers/devicesHelper"
import useConstant from "@digits-shared/hooks/useConstant"
import useRouter from "@digits-shared/hooks/useRouter"
import useSession from "@digits-shared/hooks/useSession"
import { AspectCode } from "@digits-shared/session/SessionTypes"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/useViewVersion"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import routes from "src/frontend/routes"
import type FrontendSession from "src/frontend/session"

const ReportsApplication = React.lazy(
  () => import("src/frontend/components/OS/Applications/Reports/ReportsApplication")
)

const AnalysisApplication = React.lazy(
  () => import("src/frontend/components/OS/Applications/Analysis/AnalysisApplication")
)

const BoostApplication = React.lazy(
  () => import("src/frontend/components/OS/Applications/Boost/BoostApplication")
)

const AccountantSettings = React.lazy(
  () => import("src/frontend/components/OS/Applications/AccountantSettings/SettingsApplication")
)

const BillPayApplication = React.lazy(
  () => import("src/frontend/components/OS/Applications/BillPay/BillPayApplication")
)

const InvoicingApplication = React.lazy(
  () => import("src/frontend/components/OS/Applications/Invoicing/InvoicingApplication")
)

const GetPaidApplication = React.lazy(
  () => import("src/frontend/components/OS/Applications/GetPaid/GetPaidApplication")
)

const IdentityVerification = React.lazy(
  () => import("src/frontend/components/OS/Applications/IdentityVerification/IdentityVerification")
)

const AssistantCenter = React.lazy(() =>
  import("src/frontend/components/Shared/Assistant/Assistant").then((m) => ({
    default: m.AssistantCenter,
  }))
)

const AccountingApplication = React.lazy(
  () => import("src/frontend/components/OS/Applications/Accounting/AccountingApplication")
)

const VendorDirectoryApplication = React.lazy(
  () => import("src/frontend/components/OS/Applications/VendorDirectory/VendorDirectoryApplication")
)

const ReconciliationApplication = React.lazy(
  () => import("src/frontend/components/OS/Applications/Reconciliations/ReconciliationApplication")
)

const LegacyActionItemsRedirect = React.lazy(
  () =>
    import(
      "src/frontend/components/OS/Applications/ClientTransactionReview/Shared/LegacyActionItemsRedirect"
    )
)

const ClientTransactionReviewMobile = React.lazy(
  () =>
    import(
      "src/frontend/components/OS/Applications/ClientTransactionReview/ContentView/ClientTransactionReview.mobile"
    )
)

const VaultApplication = React.lazy(
  () => import("src/frontend/components/OS/Applications/Vault/VaultApplication")
)

const LinkApplication = React.lazy(
  () => import("src/frontend/components/OS/Applications/Link/LinkApplication")
)

const ApplicationsDirectory: React.FC = () => {
  const generatePath = useFrontendPathGenerator()
  const session = useSession<FrontendSession>()

  const objectSharingPaths = useConstant(() => routes.objectSharingPaths())
  const viewVersion = useViewVersion()
  const leParams = {
    leSlug: session.currentLegalEntity?.slug,
  }

  return (
    <React.Suspense fallback={<ModuleLoading />}>
      <Switch>
        {/* Analysis */}
        <Route
          path={routes.analysis.parameterizedPath}
          render={() => (
            <ProtectedRoute
              redirectTo={generatePath(routes.dashboard)}
              component={AnalysisApplication}
              requiresView={viewVersion}
            />
          )}
        />

        {/* Reports */}
        <Route
          path={[routes.reports.parameterizedPath, routes.reportPackage.parameterizedPath]}
          render={() => (
            <ProtectedRoute
              redirectTo={generatePath(routes.dashboard)}
              component={ReportsApplication}
              requiresView={viewVersion}
            />
          )}
        />

        {/* Boost */}
        <Route
          path={[routes.qualityCheck.parameterizedPath, routes.boost.parameterizedPath]}
          render={() => (
            <ProtectedRoute
              aspectCode={AspectCode.Audit}
              redirectTo={generatePath(routes.dashboard)}
              component={BoostApplication}
              requiresView={viewVersion}
            />
          )}
        />

        {/* Client Transaction Review */}
        {/* only mobile/tablet renders a Content view */}
        {isMobile && (
          <Route
            path={routes.clientTransactionReview.parameterizedPath}
            component={ClientTransactionReviewMobile}
          />
        )}

        {/* redirect from /action-items-list and /action-items to /action-items-list/transaction-review */}
        {/* redirect from /action-items/:workItemId to /action-items-list/transaction-review/:workItemId */}
        <Route
          path={[
            routes.actionItemsList.parameterizedPath,
            routes.actionItems.parameterizedPath,
            routes.actionItem.parameterizedPath,
          ]}
          component={LegacyActionItemsRedirect}
          exact
        />

        {/* Transaction Review */}
        <Route
          path={routes.transactionReview.parameterizedPath}
          render={() => (
            <ProtectedRoute
              aspectCode={AspectCode.Audit}
              redirectTo={generatePath(routes.dashboard)}
              component={BoostApplication}
              requiresView={viewVersion}
            />
          )}
        />

        {/* Settings */}
        <Route path={routes.organization.parameterizedPath} component={AccountantSettings} />

        {/* Bill Pay */}
        <Route
          path={routes.oldPayBills.parameterizedPath}
          render={() => (
            <LoggedRedirect
              name="ApplicationsDirectory-RedirectOldPayBills"
              to={generatePath(routes.billPay, { leSlug: session.currentLegalEntity?.slug })}
            />
          )}
        />
        <Route
          path={[
            routes.billPay.parameterizedPath,
            routes.billDetails.parameterizedPath,
            routes.billPaymentDetails.parameterizedPath,
          ]}
          render={() => (
            <ProtectedRoute
              aspectCode={AspectCode.PayBills}
              redirectTo={generatePath(routes.dashboard)}
              component={BillPayApplication}
            />
          )}
        />
        <Route
          path={routes.legalEntityIdentityVerification.parameterizedPath}
          render={() => (
            <ProtectedRoute
              aspectCode={AspectCode.PayBills}
              redirectTo={generatePath(routes.dashboard)}
              component={IdentityVerification}
            />
          )}
        />

        {/* Invoicing */}
        <Route
          path={[
            routes.invoicing.parameterizedPath,
            routes.draftInvoice.parameterizedPath,
            routes.sentInvoiceDetails.parameterizedPath,
          ]}
          render={() => (
            <ProtectedRoute
              aspectCode={AspectCode.Invoicing}
              redirectTo={generatePath(routes.dashboard)}
              component={InvoicingApplication}
            />
          )}
        />

        {/* Get Paid */}
        <Route
          path={routes.getPaid.parameterizedPath}
          render={() => (
            <ProtectedRoute
              aspectCode={AspectCode.GetPaid}
              redirectTo={generatePath(routes.dashboard)}
              component={GetPaidApplication}
            />
          )}
        />

        {/* Assistant */}
        <Route
          path={routes.assistant.parameterizedPath}
          render={() => (
            <ProtectedRoute
              digitsEmployeeOnly
              redirectTo={generatePath(routes.dashboard)}
              component={AssistantCenter}
              requiresView={viewVersion}
            />
          )}
        />

        {/* Accounting Application */}
        <Route
          path={[routes.accounting.parameterizedPath, routes.categoryReview.parameterizedPath]}
          render={() => (
            <ProtectedRoute
              redirectTo={generatePath(routes.dashboard)}
              component={AccountingApplication}
              requiresView={viewVersion}
              aspectCode={AspectCode.AIBookkeeper}
            />
          )}
        />

        {/* Vendor Directory */}
        <Route
          path={routes.vendorDirectory.parameterizedPath}
          render={() => (
            <ProtectedRoute
              redirectTo={generatePath(routes.dashboard)}
              component={VendorDirectoryApplication}
              requiresView={viewVersion}
              aspectCode={AspectCode.AIBookkeeper}
            />
          )}
        />

        {/* Reconciliation Application */}
        <Route
          path={[
            routes.reconciliations.parameterizedPath,
            routes.reconciliation.parameterizedPath,
            routes.viewOnlyReconciliation.parameterizedPath,
          ]}
          render={() => (
            <ProtectedRoute
              redirectTo={generatePath(routes.dashboard)}
              component={ReconciliationApplication}
              requiresView={viewVersion}
              aspectCode={AspectCode.AIBookkeeper}
            />
          )}
        />

        {/* Vault */}
        <Route
          path={routes.vault.parameterizedPath}
          render={() => (
            <ProtectedRoute
              redirectTo={generatePath(routes.dashboard)}
              requiresView={viewVersion}
              aspectCode={AspectCode.Vault}
            >
              <VaultApplication />
            </ProtectedRoute>
          )}
        />

        {/* Link Routing */}
        <Route path={routes.link.parameterizedPath} component={LinkApplication} />

        {/* Sharing Details Route Handler */}
        <Route path={objectSharingPaths} component={SharingDetails} />

        {/*
          In order to create the transition effect for springboard, the legal entity home
          page will hit this switch statement. We need a noop route matcher to prevent the final catch
          all redirect from hitting. The final redirect should hit when its any other invalid route
        */}
        <Route exact path={generatePath(routes.legalEntityHome, leParams)} />

        {/* Fallback redirects for legacy routes */}
        <Route
          path={routes.clientPortal.parameterizedPath}
          component={LegacyClientPortalRedirect}
        />
        <Route
          path={routes.burn.parameterizedPath}
          render={() => (
            <LoggedRedirect
              name="ApplicationsDirectory-LegacyBurnRedirect"
              to={generatePath(routes.expenses, leParams)}
            />
          )}
        />
        <Route
          path={routes.sales.parameterizedPath}
          render={() => (
            <LoggedRedirect
              name="ApplicationsDirectory-LegacySalesRedirect"
              to={generatePath(routes.revenue, leParams)}
            />
          )}
        />

        {/* Fall through */}
        <Route
          path="*"
          render={() => (
            <LoggedRedirect
              name="ApplicationsDirectory-fallThrough"
              to={generatePath(routes.legalEntityHome, {
                leSlug: session.currentLegalEntity?.slug,
              })}
            />
          )}
        />
      </Switch>
    </React.Suspense>
  )
}

export default ApplicationsDirectory

const SharingDetails: React.FC = () => {
  const generatePath = useFrontendPathGenerator()
  const { currentLegalEntity } = useSession<FrontendSession>()
  const { isSharingContextActive } = useSession<FrontendSession>()
  const viewVersion = useViewVersion()

  if (isSharingContextActive) {
    return (
      <LoggedRedirect
        name="SharingDetails-sharingContextActive"
        to={generatePath(routes.sharedWithMe)}
      />
    )
  }

  return (
    <Switch>
      <Route
        path={[
          routes.accounting.parameterizedPath,
          routes.accountingDetailEdit.parameterizedPath,
          routes.categoryReview.parameterizedPath,
        ]}
        render={() => (
          <ProtectedRoute
            redirectTo={generatePath(routes.dashboard)}
            component={AccountingApplication}
            requiresView={viewVersion}
            aspectCode={AspectCode.AIBookkeeper}
          />
        )}
      />

      <Route
        path="*"
        render={() => (
          <LoggedRedirect
            name="SharingDetails-fallThrough"
            to={generatePath(routes.legalEntityHome, {
              leSlug: currentLegalEntity?.slug,
            })}
          />
        )}
      />
    </Switch>
  )
}

const LegacyClientPortalRedirect: React.FC = () => {
  const { location } = useRouter()
  const newLocation = location.fullPathname.replace("/portal", "")
  return <LoggedRedirect name="ApplicationsDirectory-LegacyClientportal" to={newLocation} />
}
