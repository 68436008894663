import * as React from "react"
import { type ReactNode } from "react"
import {
  PaddingCell,
  SideExpandedCell,
  SideExpandedCellBackground,
  TableCell,
} from "@digits-shared/components/UI/Table/Column"
import {
  type DataIdentifier,
  EXPANDABLE_CLASS_NAME,
  EXPANDED_CLASS_NAME,
  getRowIdentifier,
  HOVERABLE_CLASS_NAME,
  type IdentifiableData,
  LEFT_SIDE_EXPANDABLE_CLASS_NAME,
  RIGHT_SIDE_EXPANDABLE_CLASS_NAME,
  type RowBooleanValue,
  SELECTED_CLASS_NAME,
  TABLE_BORDER_COLOR,
} from "@digits-shared/components/UI/Table/tableConstants"
import borders from "@digits-shared/themes/borders"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"

/*
  STYLES 
*/

export const TableRow = styled.tr`
  /* Add top and bottom borders via box-shadows so that highlighting a row does not cause height to jump. */

  &:not(:last-child):not(:first-child) {
    box-shadow:
      inset 0 0.5px 0 0 ${TABLE_BORDER_COLOR},
      inset 0 -0.5px 0 0 ${TABLE_BORDER_COLOR};
  }

  &:last-child {
    box-shadow:
      inset 0 0.5px 0 0 ${TABLE_BORDER_COLOR},
      inset 0 -1px 0 0 ${TABLE_BORDER_COLOR};
  }

  &:only-child {
    box-shadow:
      inset 0 1px 0 0 ${TABLE_BORDER_COLOR},
      inset 0 -1px 0 0 ${TABLE_BORDER_COLOR};
  }

  &:first-child > {
    ${TableCell} {
      border-top-color: ${colors.transparent};
    }
  }

  & > ${TableCell}:first-child, & > ${PaddingCell} + ${TableCell} {
    padding-left: 0;
  }

  & > ${TableCell}:last-child {
    padding-right: 0;
    text-align: right;
  }

  &.${LEFT_SIDE_EXPANDABLE_CLASS_NAME} {
    &:hover ${SideExpandedCell} {
      opacity: 1;
    }
  }

  &.${RIGHT_SIDE_EXPANDABLE_CLASS_NAME} {
    &:hover ${SideExpandedCell} {
      opacity: 1;
    }

    & > ${PaddingCell} ~ ${TableCell}:nth-last-child(3):not(:nth-child(3)) {
      padding-right: 0;
      text-align: right;
    }
  }

  &:not(.${RIGHT_SIDE_EXPANDABLE_CLASS_NAME}) {
    & > ${PaddingCell} ~ ${TableCell}:nth-last-child(2):not(:nth-child(2)) {
      padding-right: 0;
      text-align: right;
    }
  }

  &.${EXPANDABLE_CLASS_NAME} {
    cursor: pointer;
  }

  &.${EXPANDED_CLASS_NAME} {
    background: ${colors.translucentWhite04};
  }

  &.${SELECTED_CLASS_NAME} {
    /* Two box shadows: first for the subtle glow inside the row, the second for the hard border around the row.*/
    box-shadow:
      inset 0 0 30px ${colors.theme.dark.interactedContainerGlow},
      0 0 0 -1px ${colors.theme.dark.interactedContainerBorder},
      0 0 0 1px ${colors.theme.dark.interactedContainerBorder};
    border-radius: ${borders.radius.default}px;
    color: ${colors.white};

    &.${LEFT_SIDE_EXPANDABLE_CLASS_NAME}, &.${LEFT_SIDE_EXPANDABLE_CLASS_NAME} {
      ${SideExpandedCell} {
        opacity: 1;

        ${SideExpandedCellBackground} {
          background-color: ${colors.rowHoverBackground};
        }
      }
    }
  }

  &.${HOVERABLE_CLASS_NAME} {
    &:not(.${EXPANDED_CLASS_NAME}):hover {
      cursor: pointer;
      /* Two box shadows: first for the subtle glow inside the row, the second for the hard border around the row.*/
      box-shadow:
        inset 0 0 30px ${colors.translucentSecondary10},
        inset 0 -1px 0 0 ${colors.translucentSecondary10},
        inset 0 1px 0 0 ${colors.translucentSecondary10};
      border-radius: ${borders.radius.default}px;
      color: ${colors.secondary};

      ${SideExpandedCellBackground} {
        background-color: ${colors.rowHoverBackground};
      }
    }
  }
`

/*
  INTERFACES
*/

interface RowProps<T> {
  index: number
  data: T
  list: T[]
  isLoading?: boolean
  isExpandable?: boolean
  isExpanded?: boolean
  getDataIdentifier?: DataIdentifier<T>
  getRowClassName?: (data: T, index: number, list: T[]) => string
  children?: React.ReactNode

  // If `onRowClick` returns a boolean, will determine if the row should be selected. This is done
  // in this manner to allow for only the selected row to re-render to make selected, rather than
  // the entire table.
  onRowClick?: (data: T, index: number, event: React.MouseEvent) => boolean | void
  shouldRowBeSelected?: RowBooleanValue<T>

  isLeftSideExpandable?: boolean
  renderLeftSideExpanded?: (data: T, index: number) => React.ReactNode
  isRightSideExpandable?: boolean
  renderRightSideExpanded?: (data: T, index: number) => React.ReactNode

  isRowHoverable?: RowBooleanValue<T>
  // These 2 functions should be used scarcely as they will likely trigger a full table re-render.
  // For most cases you want to use CSS `:hover` to quickly update the UI.
  // The intention of these methods is notify a parent component about the hover states,
  // for example to render another sibling component of this table.
  onRowMouseOver?: (data: T, index: number, event: React.MouseEvent) => void
  onRowMouseOut?: (data: T, index: number, event: React.MouseEvent) => void
}

interface RowState {
  isSelected: boolean
  selectionChange: boolean
}

interface FastRowProps<T> extends RowProps<T> {
  children?: ReactNode
  isSelected: boolean
}

/*
  COMPONENTS
*/

export default class Row<T> extends React.Component<RowProps<T>, RowState> {
  state: RowState = {
    isSelected: false,
    selectionChange: false,
  }

  static getDerivedStateFromProps(props: RowProps<{}>, state: RowState) {
    if (props.shouldRowBeSelected !== undefined) {
      const { data, index, shouldRowBeSelected } = props
      const newSelectionState = getRowBooleanValue(data, index, shouldRowBeSelected)
      state.selectionChange = newSelectionState !== state.isSelected
      state.isSelected = newSelectionState
    }
    return state
  }

  shouldComponentUpdate(nextProps: Readonly<RowProps<T>>, nextState: RowState) {
    const { isSelected, selectionChange } = this.state
    if (selectionChange) return true

    const { data: nextData, isExpanded: nextIsExpanded } = nextProps
    const { isSelected: nextIsSelected } = nextState

    const nextIdentifier = (nextData as unknown as IdentifiableData)?.id
    if (nextIdentifier === undefined) {
      return true
    }

    const { data, isExpanded } = this.props
    const identifier = (data as unknown as IdentifiableData)?.id
    if (identifier === undefined) {
      return true
    }

    return (
      identifier !== nextIdentifier ||
      nextIsExpanded !== isExpanded ||
      nextIsSelected !== isSelected
    )
  }

  render() {
    // in this manner to allow for only the selected row to re-render to make selected, rather than
    // the entire table.
    const { isSelected } = this.state
    return <FastRow<T> {...this.props} isSelected={isSelected} onRowClick={this.onClick} />
  }

  onClick = (data: T, index: number, event: React.MouseEvent) => {
    const { onRowClick } = this.props
    const isSelected = onRowClick?.(data, index, event)
    if (isSelected !== undefined) {
      this.setState({ isSelected })
    }
  }
}

function FastRow<T>(props: FastRowProps<T>) {
  const {
    children,
    isLoading,
    index,
    data,
    list,
    getRowClassName,
    isRowHoverable,
    isExpandable,
    isExpanded,
    isSelected,
    isLeftSideExpandable,
    isRightSideExpandable,
    shouldRowBeSelected,
    onRowClick,
    onRowMouseOver,
    onRowMouseOut,
  } = props

  const onClick = React.useCallback(
    (event: React.MouseEvent) => {
      onRowClick?.(data, index, event)
    },
    [data, index, onRowClick]
  )

  const onMouseEnter = React.useCallback(
    (event: React.MouseEvent<HTMLTableRowElement>) => {
      onRowMouseOver?.(data, index, event)
    },
    [data, index, onRowMouseOver]
  )

  const onMouseLeave = React.useCallback(
    (event: React.MouseEvent<HTMLTableRowElement>) => {
      onRowMouseOut?.(data, index, event)
    },
    [data, index, onRowMouseOut]
  )

  // At the data id to the <tr> so it can be queryable in the DOM or to compare a
  // particular data item with the rows in the table.
  const identifier = getRowIdentifier<RowProps<T>, T>(props, data, index)

  const expandable = !!isExpandable
  const leftSideExpandable = !!isLeftSideExpandable
  const rightSideExpandable = !!isRightSideExpandable

  const expanded = !!isExpanded
  const selected = getRowBooleanValue(data, index, shouldRowBeSelected) || isSelected

  const hoverable =
    (!expanded && !selected && !isLoading && getRowBooleanValue(data, index, isRowHoverable)) ||
    expandable

  const className = [
    (getRowClassName && getRowClassName(data, index, list)) || "",
    expandable ? EXPANDABLE_CLASS_NAME : "",
    leftSideExpandable ? LEFT_SIDE_EXPANDABLE_CLASS_NAME : "",
    rightSideExpandable ? RIGHT_SIDE_EXPANDABLE_CLASS_NAME : "",
    expanded ? EXPANDED_CLASS_NAME : "",
    selected ? SELECTED_CLASS_NAME : "",
    hoverable ? HOVERABLE_CLASS_NAME : "",
  ].join(" ")

  return (
    <TableRow
      key={`row_${identifier}`}
      id={identifier.toString()}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={className}
    >
      {children}
    </TableRow>
  )
}

function getRowBooleanValue<T>(data: T, index: number, value?: RowBooleanValue<T>) {
  return !!value && (value === true || value(data, index))
}
