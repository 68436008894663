import * as React from "react"
import {
  ActionItemKind,
  type ActionItemThreadFieldsFragment,
  type EntityUser,
} from "@digits-graphql/frontend/graphql-bearer"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import stringHelper from "@digits-shared/helpers/stringHelper"
import userHelper from "@digits-shared/helpers/userHelper"
import { ActionItemRow } from "src/frontend/components/Shared/NavSidebar/ActionItems/Row/ActionItemRow"
import { NameDateDetail } from "src/frontend/components/Shared/NavSidebar/ActionItems/Row/NameAndDate"
import { useCurrentAffiliation } from "src/shared/hooks/useCurrentAffiliation"

/*
  COMPONENTS
*/

export const ThreadRow: React.FC<{
  date: string
  author?: EntityUser | null
  targetEntity: ActionItemThreadFieldsFragment["targetEntity"]
}> = ({ date, author, targetEntity }) => {
  const currentAffiliation = useCurrentAffiliation()

  const isAuthorAffiliated = React.useMemo(() => {
    if (!currentAffiliation || !author) return true // Assume the common case.
    return currentAffiliation?.affiliatedUsers.some((affUser) => affUser.user.id === author.id)
  }, [author, currentAffiliation])

  const { title, iconUrl, categoryName } = processThreadTarget(targetEntity, isAuthorAffiliated)
  return (
    <ActionItemRow
      title={title}
      actionItemKind={ActionItemKind.Comment}
      categoryName={categoryName}
      party={{
        name: author?.givenName,
        iconUrl: iconUrl || author?.avatarUrl,
      }}
    >
      {author && (
        <NameDateDetail
          date={date}
          secondary={`${userHelper.displayName(author, "abbreviateLast")}`}
        />
      )}
    </ActionItemRow>
  )
}

function processThreadTarget(
  targetEntity: ActionItemThreadFieldsFragment["targetEntity"],
  isAuthorAffiliated: boolean
): {
  title: string
  categoryName?: string | null
  iconUrl?: string | null
} {
  switch (targetEntity.__typename) {
    case "EntityCategory":
      return {
        title: targetEntity.name ? `Commented on ${targetEntity.name}` : "Commented on a category",
        categoryName: targetEntity.name,
      }
    case "EntityParty":
      return {
        title: targetEntity.name ? `Commented on ${targetEntity.name}` : "Commented on a party",
        iconUrl: targetEntity.iconUrl,
      }
    case "EntityReportPackage":
      return {
        title: targetEntity.title ? `Commented on ${targetEntity.title}` : "Commented on a report",
      }
    case "EntityLedgerTransaction": {
      const transactionMoment = dateTimeHelper.dayjsFromTimestamp(targetEntity.timestamp)
      return {
        title: transactionMoment
          ? `Commented on a transaction from ${transactionMoment.format("MMMM D")}`
          : "Commented on a transaction",
      }
    }
    case "EntityWorkItem": {
      const verb = isAuthorAffiliated ? "Asked" : "Replied to"
      return {
        title: targetEntity.objectTransaction.party
          ? `${verb} a question on ${stringHelper.getIndefiniteArticle(targetEntity.objectTransaction.party?.name)} ${targetEntity.objectTransaction.party?.name} transaction`
          : `${verb} a question on a transaction`,
        iconUrl: targetEntity.objectTransaction.party?.iconUrl,
      }
    }
  }
  return { title: "New Comment" }
}
