import * as React from "react"
import { AffiliateType, useListAffiliatesQuery } from "@digits-graphql/frontend/graphql-bearer"
import { ColorIcon } from "@digits-shared/components/UI/Elements/ColorIcon"
import useSession from "@digits-shared/hooks/useSession"
import styled from "styled-components"
import { useShouldBlockBranding } from "src/frontend/components/OS/Applications/AccountantSettings/Shared"
import { CurrentLegalEntityLogo } from "src/frontend/components/OS/Applications/ClientPortal/Shared/LegalEntityLogo"
import type FrontendSession from "src/frontend/session"

/*
 STYLES
*/

const Row = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

/*
 COMPONENTS
*/

export const LogoRow: React.FC<{ size: number }> = ({ size }) => {
  const {
    currentOrganization: sessionOrganization,
    currentLegalEntityId: legalEntityId,
    isAffiliatedSession,
  } = useSession<FrontendSession>()
  const shouldBlockBranding = useShouldBlockBranding()

  const { data: affiliateList } = useListAffiliatesQuery({
    variables: { legalEntityId, affiliateType: AffiliateType.Accounting },
    skip: isAffiliatedSession,
  })

  const accountingOrg = isAffiliatedSession
    ? sessionOrganization
    : affiliateList?.listAffiliates?.organizations?.[0]?.organization
  const orgLogo = shouldBlockBranding ? undefined : accountingOrg?.iconUrl

  return (
    <Row>
      <ColorIcon imageUrl={orgLogo} fallbackText={accountingOrg?.name} size={size} />
      <CurrentLegalEntityLogo size={size} />
    </Row>
  )
}
