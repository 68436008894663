import { Link } from "react-router-dom"
import { H2, H3 } from "@digits-shared/components/UI/Elements/Header"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"

export const WidgetTitle = styled(H3)`
  text-transform: none;
  margin: 0;
  font-size: 15px;
  font-weight: ${fonts.weight.heavy};
  color: ${colors.secondary};
  line-height: 20px;
`

export const WidgetHeader = styled.div`
  display: flex;
  align-items: center;

  ${WidgetTitle} {
    flex: 1;
  }
`

export const WidgetSubtitle = styled.div`
  margin: 2px 0 0 0;
  font-size: 11px;
  font-weight: ${fonts.weight.normal};
  color: ${colors.translucentSecondary80};
  line-height: 14px;
`

export const WideWidgetTitleTagLine = styled.span`
  font-size: 18px;
  font-weight: ${fonts.weight.book};
  color: ${colors.translucentSecondary90};
  line-height: 22px;
`

export const WideWidgetTitle = styled(H2)`
  text-transform: none;
  margin: 2px 0;
  font-size: 20px;
  font-weight: ${fonts.weight.black};

  ${WideWidgetTitleTagLine} {
    &::before {
      content: " ";
    }
  }
`

export const WideWidgetSubtitle = styled.div`
  margin: 3px 0;
  font-size: 13px;
  font-weight: ${fonts.weight.normal};
  color: ${colors.translucentSecondary80};
  line-height: 14px;
`

export const WidgetHeaderLink = styled(Link)`
  text-transform: capitalize;
  font-size: 11px;
  font-weight: ${fonts.weight.normal};
  color: ${colors.translucentSecondary80};
  margin-left: 3px;

  &:hover {
    color: ${colors.secondary};
  }
`
