import * as React from "react"
import { type DigitsRoute, type StaticRoutes } from "@digits-shared/components/Router/DigitsRoute"
import useRouter from "@digits-shared/hooks/useRouter"

export function useCurrentRoute(routes: StaticRoutes): DigitsRoute {
  const { location } = useRouter()

  return React.useMemo(() => {
    const routeConfigs = Object.values(routes)
    return routeConfigs.find((config) => config.name === location.name) || routeConfigs[0]
  }, [location, routes])
}
