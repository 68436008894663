import * as React from "react"
import { type StatementDetails } from "@digits-graphql/frontend/graphql-bearer"
import { usePopOverState } from "@digits-shared/hooks/usePopOverState"
import { useIsPrintTheme } from "@digits-shared/themes"
import styled from "styled-components"
import { CategoryDetailsPopOver } from "src/frontend/components/Shared/Reports/Report/Components/Shared/DetailsPopOver/CategoryDetailsPopOver"
import { SummaryLineItemPopOver } from "src/frontend/components/Shared/Reports/Report/Components/Shared/DetailsPopOver/SummaryLineItemPopOver"
import {
  type PopOverPositioning,
  usePopOverPositioning,
} from "src/frontend/components/Shared/Reports/Report/Components/Shared/DetailsPopOver/usePopOverPositioning"
import useRowSelectionContext from "src/frontend/components/Shared/Reports/Report/Components/Statements/RowSelectionContext"
import {
  isCategoryRowDetails,
  isSummaryLineRowDetails,
  type RowDetails,
  useToTimeSeries,
} from "src/frontend/components/Shared/Reports/Report/Components/Statements/toDetailsData"
import { useIsActivelyCommenting } from "src/frontend/components/Shared/Reports/ReportComments/hooks"

/*
  STYLES
*/

const PopOverContainer = styled.div`
  position: relative;
  min-width: fit-content;
`

const ChildWrapper = styled.div`
  display: contents;
  white-space: normal;
  cursor: pointer;
`

/*
  INTERFACES
*/

interface PopOverProps {
  rowId: string
  depth: number
  details?: RowDetails
  className?: string
  children?: React.ReactNode
}

type DetailsProps = PopOverProps & PopOverPositioning

/*
  COMPONENTS
*/

export const DetailsPopOver: React.FC<PopOverProps> = ({
  rowId,
  depth,
  details,
  className,
  children,
}) => {
  const { popOverPosition, containerRef, popOverMeasureRef, popOverMeasureRect } =
    usePopOverPositioning()
  const { isPopOverOpen, onMouseEnter, onMouseLeave } = usePopOverEvents(rowId, details)

  const wrapperClassName = isPopOverOpen ? "popover-active" : ""
  return (
    <PopOverContainer ref={containerRef} className={className} onMouseLeave={onMouseLeave}>
      {isPopOverOpen && (
        <DetailsByType
          rowId={rowId}
          depth={depth}
          details={details}
          positionRef={containerRef}
          measureRef={popOverMeasureRef}
          measureRect={popOverMeasureRect}
          position={popOverPosition}
        />
      )}
      <ChildWrapper
        className={["details-popover", wrapperClassName].join(" ")}
        onMouseEnter={onMouseEnter}
      >
        {children}
      </ChildWrapper>
    </PopOverContainer>
  )
}

function usePopOverEvents(rowId: string, details?: RowDetails) {
  const { pinnedCell, setPinnedCell } = useRowSelectionContext()
  const isPrintTheme = useIsPrintTheme()
  const isActivelyCommenting = useIsActivelyCommenting()
  const allZeros = useAllZeros(details?.periodDetails)
  // disable pop-overs if we're actively commenting
  const disabled = isActivelyCommenting || allZeros || isPrintTheme

  const {
    isPopOverOpen,
    onMouseEnter,
    onMouseLeave,
    showPopOver,
    hidePopOver,
    pinPopOver,
    unpinPopOver,
  } = usePopOverState({
    // longer wait so accountants can scroll/scan the rows without the popover getting in the way
    waitToOpen: 1500,
    disabled,
  })

  React.useEffect(() => {
    if (disabled) {
      hidePopOver()
    }
  }, [disabled, hidePopOver, isActivelyCommenting])

  React.useEffect(() => {
    if (pinnedCell?.rowId === rowId) {
      showPopOver()
      pinPopOver(pinnedCell.target)
      return
    }
    if (isPopOverOpen) {
      unpinPopOver()
    }
  }, [isPopOverOpen, pinPopOver, pinnedCell, rowId, setPinnedCell, showPopOver, unpinPopOver])

  return { isPopOverOpen, onMouseEnter, onMouseLeave }
}

function useAllZeros(periodDetails?: StatementDetails | null) {
  const timeseries = useToTimeSeries(periodDetails)
  return React.useMemo(
    () =>
      !timeseries?.values.length ||
      timeseries.values.every((val) => val.moneyFlow.value.amount === 0),
    [timeseries]
  )
}

const DetailsByType: React.FC<DetailsProps> = ({
  rowId,
  details,
  positionRef,
  measureRef,
  measureRect,
  position,
}) => {
  const isPrintTheme = useIsPrintTheme()
  if (isPrintTheme) return null

  switch (true) {
    case isSummaryLineRowDetails(details):
      return (
        <SummaryLineItemPopOver
          positionRef={positionRef}
          measureRef={measureRef}
          measureRect={measureRect}
          details={details}
          position={position}
          rowId={rowId}
        />
      )
    case isCategoryRowDetails(details):
      return (
        <CategoryDetailsPopOver
          positionRef={positionRef}
          measureRef={measureRef}
          measureRect={measureRect}
          details={details}
          position={position}
          rowId={rowId}
        />
      )

    default:
      return null
  }
}
