import * as React from "react"
import {
  type MonetaryValue,
  type TransactionSummary,
} from "@digits-graphql/frontend/graphql-bearer"
import {
  useInvertValues,
  useIsInboundAmount,
} from "@digits-shared/components/Contexts/InvertValuesContext"
import { LoadingBlock } from "@digits-shared/components/Loaders"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { chevronStyles, PointingDirection } from "@digits-shared/components/UI/Elements/Chevron"
import { Delta } from "@digits-shared/components/UI/Elements/Delta"
import { DeltaWithChevron } from "@digits-shared/components/UI/Elements/DeltaWithChevron"
import { Amount } from "@digits-shared/components/UI/Table/Content"
import deltaHelper from "@digits-shared/helpers/deltaHelper"
import numberHelper, { CurrencyStyle } from "@digits-shared/helpers/numberHelper"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import { ChevronDisclosureForRow } from "src/frontend/components/OS/Shared/ChevronDisclosure"

const DELTA_SIZE = "11px"

export const DimensionRowInfoColumn = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`

export const DimensionRowSparkChartSizing = styled.div`
  display: flex;
  align-items: flex-end;
  height: 30px;
  width: 145px;
`

const DimensionRowDeltaContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`

const DimensionRowTotalsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: right;
`

const DimensionRowTotalsAmount = styled(Amount)`
  font-size: 14px;
  font-weight: ${fonts.weight.heavy};
`

const DimensionRowTotalsCount = styled.div`
  color: ${colors.translucentSecondary80};
  font-weight: ${fonts.weight.book};
  font-size: 10px;
  text-transform: uppercase;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const DimensionRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  color: ${colors.secondary};

  ${DimensionRowDeltaContainer} {
    width: 55px;
  }

  ${DimensionRowTotalsContainer} {
    width: 105px;
  }

  ${DimensionRowInfoColumn}, ${DimensionRowSparkChartSizing}, ${DimensionRowDeltaContainer} {
    margin-right: 20px;
  }

  &:hover {
    ${ChevronDisclosureForRow} {
      ${svgPathStyles(colors.secondary, 1.5)};
    }
  }
`

const DeltaChevron = styled.div`
  ${chevronStyles(PointingDirection.Right, "5px", colors.neonGreen)};
  position: absolute;
  top: 50%;
  right: -5px;
  margin-top: -5px;

  &:before {
    width: 8px;
    height: 8px;
    transform: rotate(45deg);
    content: "";
    box-shadow: 1px -1px 2px 0px rgba(0, 0, 0, 0.15);
    background-color: ${colors.transparent};
    position: absolute;
    bottom: -4px;
    right: 2px;
  }
`

const DimensionRowDeltaAmount = styled.div<{ color: string }>`
  color: ${({ color }) => color};
  font-weight: ${fonts.weight.medium};
  font-size: 13px;
`

const DimensionRowDeltaWithChevron = styled(DeltaWithChevron)`
  opacity: 0.8;
`

export const DimensionRowDelta: React.FC<{
  isLoading?: boolean
  summary?: TransactionSummary
}> = ({ isLoading, summary }) => {
  const invertValues = useInvertValues()

  if (isLoading || !summary) return <DimensionRowDeltaLoading />

  const { deltaPrevious, isFirstOccurrencePeriod, value } = summary?.total || {}
  const delta = deltaHelper.deltaPercentageForMonetaryValue(value, deltaPrevious, invertValues)

  const deltaValue: MonetaryValue = {
    ...value,
    amount: deltaPrevious || 0,
  }

  const currency =
    deltaValue.amount !== 0
      ? numberHelper.currency(deltaValue, {
          style: CurrencyStyle.Summary,
          invertValues,
        })
      : "--"

  const deltaProps = {
    isNew: isFirstOccurrencePeriod,
    delta,
    amount: value.amount,
    invertValues,
  }
  const pointingDirection = deltaHelper.deltaDirectionForValue(deltaProps)
  const directionSign = pointingDirection === PointingDirection.Up && value.amount !== 0 ? "+" : ""
  const deltaColor = deltaHelper.deltaValueColor(deltaProps)

  return !summary.total.deltaPrevious && !summary.total.transactionsCount ? (
    <DimensionRowDeltaContainer>--</DimensionRowDeltaContainer>
  ) : (
    <DimensionRowDeltaContainer>
      <DimensionRowDeltaAmount
        color={deltaColor}
      >{`${directionSign}${currency.toString()}`}</DimensionRowDeltaAmount>
      <DimensionRowDeltaWithChevron
        css="display: block;"
        size={DELTA_SIZE}
        isNew={!!isFirstOccurrencePeriod}
        delta={delta}
        amount={value.amount}
        invertValues={invertValues}
        noBackground
      />
    </DimensionRowDeltaContainer>
  )
}

export const DimensionTotalAmountWithDelta: React.FC<{
  isLoading?: boolean
  summary?: TransactionSummary
  displayChevron?: boolean
  allowDeltaToggle?: boolean
  size?: string
  className?: string
}> = ({ isLoading, summary, displayChevron, allowDeltaToggle, size, className }) => {
  const invertValues = useInvertValues()
  const isInbound = useIsInboundAmount(summary?.total.value.amount ?? 0)

  if (isLoading || !summary) return <DimensionRowDeltaLoading />

  const { total } = summary
  const { deltaPrevious, isFirstOccurrencePeriod, value } = total
  const deltaValue: MonetaryValue = {
    ...value,
    amount: deltaPrevious ?? 0,
  }

  const currency = numberHelper.currency(value, {
    style: CurrencyStyle.Aggregation,
    invertValues,
  })

  const delta = deltaHelper.deltaPercentageForMonetaryValue(value, deltaPrevious, invertValues)

  return (
    <DimensionRowDeltaContainer className={className}>
      <Amount inbound={isInbound}>{currency.toString()}</Amount>
      <Delta
        size={size ?? DELTA_SIZE}
        isNew={!!isFirstOccurrencePeriod}
        delta={delta}
        amount={value.amount}
        allowDeltaToggle={allowDeltaToggle}
        deltaValue={allowDeltaToggle ? deltaValue : undefined}
      />
      {displayChevron && <DeltaChevron />}
    </DimensionRowDeltaContainer>
  )
}

const DimensionRowDeltaLoading: React.FC = () => (
  <DimensionRowDeltaContainer>
    <LoadingBlock width="65px" height="18px" />
    <LoadingBlock width="35px" height="16px" margin="4px 0 0 0" />
  </DimensionRowDeltaContainer>
)

export const DimensionRowTotals: React.FC<{
  isLoading?: boolean
  summary?: TransactionSummary
  noCount?: boolean
}> = ({ isLoading, summary, noCount }) => {
  const invertValues = useInvertValues()
  const isInbound = useIsInboundAmount(summary?.total.value.amount ?? 0)

  if (!summary || isLoading) return <DimensionRowTotalsLoading />

  const count = summary.total.transactionsCount
  const currency = count
    ? numberHelper.currency(summary.total.value, {
        style: CurrencyStyle.Aggregation,
        invertValues,
      })
    : "--"

  return (
    <DimensionRowTotalsContainer>
      <DimensionRowTotalsAmount inbound={isInbound}>{currency.toString()}</DimensionRowTotalsAmount>
      {!noCount && !!count ? (
        <DimensionRowTotalsCount>
          {count} {count === 1 ? "transaction" : "transactions"}
        </DimensionRowTotalsCount>
      ) : null}
    </DimensionRowTotalsContainer>
  )
}

const DimensionRowTotalsLoading: React.FC = () => (
  <DimensionRowTotalsContainer>
    <DimensionRowTotalsAmount inbound={false}>
      <LoadingBlock width="60px" height="18px" />
    </DimensionRowTotalsAmount>
    <DimensionRowTotalsCount>
      <LoadingBlock width="80px" height="16px" />
    </DimensionRowTotalsCount>
  </DimensionRowTotalsContainer>
)
