import {
  type EntityCategory,
  type EntityDepartment,
  type EntityParty,
  type IntervalOrigin,
  type LayoutComponentType,
  type MetricSummaryItem,
  type ReportKind,
  type SummaryLineItem,
} from "@digits-graphql/frontend/graphql-bearer"
import { type PartyRole } from "@digits-graphql/frontend/graphql-public"
import { type DigitsRoute } from "@digits-shared/components/Router/DigitsRoute"
import useSession from "@digits-shared/hooks/useSession"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import routes from "src/frontend/routes"
import type FrontendSession from "src/frontend/session"
import { FrontendPartyRole } from "src/frontend/types/FrontendPartyRole"

export function useCategoryLinkPath(
  category: EntityCategory | undefined,
  intervalOrigin: IntervalOrigin
) {
  const { currentLegalEntity, isSharingContextActive } = useSession<FrontendSession>()
  const generatePath = useFrontendPathGenerator()
  const { year, index, interval, intervalCount } = intervalOrigin

  return currentLegalEntity && !isSharingContextActive && category
    ? generatePath(routes.categoryDetails, {
        leSlug: currentLegalEntity.slug,
        categoryId: category.id,
        year,
        index,
        interval,
        intervalCount,
      })
    : undefined
}

export function usePartyLinkPath(
  party: EntityParty | undefined,
  partyRole: PartyRole | undefined | null,
  intervalOrigin: IntervalOrigin | undefined
) {
  const { currentLegalEntity, isSharingContextActive } = useSession<FrontendSession>()
  const generatePath = useFrontendPathGenerator()
  const { year, index, interval, intervalCount } = intervalOrigin || {}

  return currentLegalEntity && !isSharingContextActive && party
    ? generatePath(routes.partyDetails, {
        leSlug: currentLegalEntity.slug,
        partyId: party.id,
        partyRole: FrontendPartyRole.roleURLKey(partyRole),
        year,
        index,
        interval,
        intervalCount,
      })
    : undefined
}

export function useComponentDetailsLinkPath(
  configType: LayoutComponentType | MetricSummaryItem | SummaryLineItem | ReportKind | undefined,
  origin: IntervalOrigin | undefined
) {
  const { currentLegalEntity, isSharingContextActive } = useSession<FrontendSession>()
  const generatePath = useFrontendPathGenerator()
  const { year, index, interval } = origin || {}

  return currentLegalEntity && !isSharingContextActive && configType
    ? generatePath(routes.layoutComponentDetails, {
        leSlug: currentLegalEntity.slug,
        configType,
        year,
        index,
        interval,
        intervalCount: undefined,
      })
    : undefined
}

export function useDepartmentLinkPath(
  department: EntityDepartment | undefined,
  intervalOrigin: IntervalOrigin,
  relativeRoute?: DigitsRoute
) {
  const { currentLegalEntity, isSharingContextActive } = useSession<FrontendSession>()
  const generatePath = useFrontendPathGenerator()
  const { year, index, interval, intervalCount } = intervalOrigin

  return currentLegalEntity && !isSharingContextActive && department
    ? generatePath(relativeRoute ?? routes.departmentDetails, {
        leSlug: currentLegalEntity.slug,
        departmentId: department.id,
        year,
        index,
        interval,
        intervalCount,
      })
    : undefined
}

export function useLocationLinkPath(
  location: EntityDepartment | undefined,
  intervalOrigin: IntervalOrigin,
  relativeRoute?: DigitsRoute
) {
  const { currentLegalEntity, isSharingContextActive } = useSession<FrontendSession>()
  const generatePath = useFrontendPathGenerator()
  const { year, index, interval, intervalCount } = intervalOrigin

  return currentLegalEntity && location && !isSharingContextActive
    ? generatePath(relativeRoute ?? routes.locationDetails, {
        leSlug: currentLegalEntity.slug,
        locationId: location.id,
        year,
        index,
        interval,
        intervalCount,
      })
    : undefined
}
