import * as React from "react"

export const ComponentConfigContext = React.createContext<ComponentConfigContextVal>({
  removeConfig: () => {},
})

export interface ComponentConfigContextVal {
  removeConfig: () => void
}

export function useRemoveConfig() {
  return React.useContext(ComponentConfigContext).removeConfig
}
