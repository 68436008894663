import * as React from "react"
import { useEvent } from "react-use"
import { usePopoverMutexContext } from "@digits-shared/components/UI/Elements/PopUp/PopOverMutexContext"
import useConstant from "@digits-shared/hooks/useConstant"
import useForceUpdate from "@digits-shared/hooks/useForceUpdate"
import useStateBoolean, { type StateBoolean } from "@digits-shared/hooks/useStateBoolean"
import { v4 as UUID } from "uuid"

const DEBOUNCE_TIME = 200

type PopOverStateChange = (visible: boolean) => void

interface PopOverStateProps {
  disabled?: boolean
  onStateChange?: PopOverStateChange
  waitToOpen?: number
  debounceTime?: number
}

export function usePopOverState(props: PopOverStateProps = {}) {
  const uuid = useConstant<string>(UUID)
  const state = useStateBoolean()

  const { value: isPopOverOpen } = state
  const eventHandlers = useOnMouseEvents(uuid, state, props)

  return React.useMemo(
    () => ({
      isPopOverOpen,
      ...eventHandlers,
    }),
    [eventHandlers, isPopOverOpen]
  )
}

function useOnMouseEvents(
  uuid: string,
  state: StateBoolean<boolean>,
  props: PopOverStateProps = {}
) {
  const { disabled, onStateChange, waitToOpen = 0, debounceTime = DEBOUNCE_TIME } = props
  const { setTrue: showPopOver, setFalse: hidePopOver } = state

  const {
    activePopOver,
    setActivePopOver,
    clearActivePopOver,
    pinActivePopOver,
    unpinActivePopOver,
  } = usePopoverMutexContext()
  const isPopOverBlocked = useMouseWheelBlocking(debounceTime)

  const sticky = React.useRef<HTMLElement | null>(null)
  const pinned = React.useRef<HTMLElement | null>(null)

  const isDisabled = React.useRef(disabled ?? false)
  React.useEffect(() => {
    isDisabled.current = !!disabled
  }, [disabled])

  const debouncedClosing = React.useRef<NodeJS.Timeout>()
  const cancelDebouncedClosing = React.useCallback(() => {
    if (debouncedClosing.current) {
      clearTimeout(debouncedClosing.current)
    }
    debouncedClosing.current = undefined
  }, [])

  const closePopOver = React.useCallback(() => {
    hidePopOver()
    onStateChange?.(false)
    pinned.current?.classList.remove("pinned")
    pinned.current = null
    sticky.current = null
  }, [hidePopOver, onStateChange])

  const onMouseLeave = React.useCallback(
    (e: React.MouseEvent) => {
      // cancel delayed opening
      if (delayedOpening.current) {
        clearTimeout(delayedOpening.current)
      }

      if (sticky.current || pinned.current) {
        return
      }

      debouncedClosing.current = setTimeout(() => {
        if (activePopOver.current?.id !== uuid) return
        clearActivePopOver()
        closePopOver()
      }, debounceTime)
    },
    [activePopOver, clearActivePopOver, closePopOver, debounceTime, uuid]
  )

  const openPopOver = React.useCallback(() => {
    cancelDebouncedClosing()
    setActivePopOver({ id: uuid, closePopOver, pinned: false })
    showPopOver()
    onStateChange?.(true)
  }, [cancelDebouncedClosing, closePopOver, onStateChange, setActivePopOver, showPopOver, uuid])

  const delayedOpening = React.useRef<NodeJS.Timeout>()
  const onMouseEnter = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (
        isPopOverBlocked.current ||
        isDisabled.current ||
        sticky.current ||
        activePopOver.current?.pinned
      )
        return

      delayedOpening.current = setTimeout(openPopOver, waitToOpen)
      sticky.current = e.metaKey || e.altKey ? e.currentTarget : null
    },
    [activePopOver, isPopOverBlocked, openPopOver, waitToOpen]
  )

  const [, forceUpdate] = useForceUpdate()
  const pinPopOver = React.useCallback(
    (target: HTMLElement) => {
      pinned.current = target
      target.classList.add("pinned")
      pinActivePopOver()
      forceUpdate()
    },
    [forceUpdate, pinActivePopOver]
  )
  const unpinPopOver = React.useCallback(() => {
    pinned.current?.classList.remove("pinned")
    pinned.current = null
    unpinActivePopOver()
    forceUpdate()
  }, [forceUpdate, unpinActivePopOver])

  // clear timeouts if unmounted
  React.useEffect(
    () => () => {
      if (delayedOpening.current) {
        clearTimeout(delayedOpening.current)
      }
      if (debouncedClosing.current) {
        clearTimeout(debouncedClosing.current)
      }
    },
    []
  )

  return {
    onMouseLeave,
    onMouseEnter,
    hidePopOver: closePopOver,
    showPopOver: openPopOver,
    pinPopOver,
    unpinPopOver,
  }
}

function useMouseWheelBlocking(debounceTime: number) {
  const isPopOverBlocked = React.useRef<NodeJS.Timeout>()

  const unblockPopOver = React.useCallback(() => {
    if (isPopOverBlocked.current) {
      clearTimeout(isPopOverBlocked.current)
    }
    isPopOverBlocked.current = undefined
  }, [])

  const blockPopOver = React.useCallback(() => {
    unblockPopOver()
    isPopOverBlocked.current = setTimeout(() => {
      unblockPopOver()
    }, debounceTime)
  }, [debounceTime, unblockPopOver])

  useEvent("mousewheel", blockPopOver, document.body, { passive: true, capture: true })

  return isPopOverBlocked
}
