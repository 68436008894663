import * as React from "react"
import styled from "styled-components"
import { type VaultFile } from "src/frontend/components/OS/Applications/Vault/types"
import { useCanListVault } from "src/frontend/components/OS/Applications/Vault/useCanListVault"
import { useVaultFilteredList } from "src/frontend/components/OS/Applications/Vault/VaultBrowser/useVaultFilteredList"
import { VaultFileRow } from "src/frontend/components/OS/Applications/Vault/VaultBrowser/VaultFileRow"
import { ComponentHeaderTitle } from "src/frontend/components/Shared/Layout/Components/Headers/ComponentSummary"
import { useComponentTitle } from "src/frontend/components/Shared/Layout/Components/useComponentTitle"
import { DocumentDragAndDrop } from "src/frontend/components/Shared/Layout/Components/Vault/DocumentDragAndDrop"
import { DocumentGridItem } from "src/frontend/components/Shared/Layout/Components/Vault/DocumentGridItem"
import { ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import {
  type MatchedComponent,
  type SizingProps,
} from "src/frontend/components/Shared/Layout/types"

/*
  STYLES
*/

const FilesContainer = styled.div`
  margin-bottom: 4px;
`

const DragAndDropContainer = styled.div<{ $height: number }>`
  position: relative;
  height: ${({ $height }) => $height}px;
`

const FilesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  margin-bottom: 18px;
`

const FilesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 110px;
  overflow: auto;
`

/*
  INTERFACES
*/

interface ComponentProps extends SizingProps {
  component: MatchedComponent<"documentCollection">
  componentSize: ComponentSize
}

/*
  COMPONENTS
*/

export const DocumentsComponent: React.FC<ComponentProps> = ({ component, componentSize }) => {
  const {
    config: {
      documentCollection: { collectionId },
    },
  } = component
  const { title, periodName } = useComponentTitle(component)

  const { files, loading } = useVaultFilteredList(collectionId)
  const vaultDisabled = !useCanListVault()

  if (vaultDisabled) {
    return (
      <>
        <ComponentHeaderTitle title={title} periodName={periodName} componentSize={componentSize} />
        <DisabledVault componentSize={componentSize} />
      </>
    )
  }

  return (
    <>
      <ComponentHeaderTitle title={title} periodName={periodName} componentSize={componentSize} />
      <FilesContainer>
        <DocumentFiles componentSize={componentSize} files={files} loading={loading} />
      </FilesContainer>
      <DragAndDrop
        componentSize={componentSize}
        collectionId={collectionId}
        files={files}
        loading={loading}
      />
    </>
  )
}

function useComponentHeight(componentSize: ComponentSize, files?: VaultFile[]) {
  switch (componentSize) {
    case ComponentSize.Large:
      return 200

    case ComponentSize.Medium:
    case ComponentSize.Small:
      return files?.length ? 108 : 223

    case ComponentSize.PageMedium:
    case ComponentSize.PageSmall:
      return files?.length ? 80 : 195
  }
  return 100
}

const DisabledVault: React.FC<{
  componentSize: ComponentSize
}> = ({ componentSize }) => {
  const height = useComponentHeight(componentSize, undefined)
  return (
    <DragAndDropContainer $height={height}>
      File sharing not allowed on shared reports.
    </DragAndDropContainer>
  )
}

const DragAndDrop: React.FC<{
  componentSize: ComponentSize
  collectionId: string
  files?: VaultFile[]
  loading: boolean
}> = ({ componentSize, collectionId, files, loading }) => {
  const height = useComponentHeight(componentSize, files)

  return (
    <DragAndDropContainer $height={height}>
      <DocumentDragAndDrop collectionId={collectionId} />
    </DragAndDropContainer>
  )
}

const DocumentFiles: React.FC<{
  files?: VaultFile[]
  loading: boolean
  componentSize: ComponentSize
}> = ({ files, loading, componentSize }) => {
  if (!files?.length) return null

  if (componentSize === ComponentSize.Large) {
    return <DocumentsGridView files={files} />
  }

  return <DocumentsListView files={files} />
}

const DocumentsGridView: React.FC<{
  files?: VaultFile[]
}> = ({ files }) => (
  <FilesGrid>{files?.map((file) => <DocumentGridItem key={`${file.id}`} file={file} />)}</FilesGrid>
)

const DocumentsListView: React.FC<{
  files?: VaultFile[]
}> = ({ files }) => (
  <FilesList>{files?.map((file) => <VaultFileRow key={`${file.id}`} file={file} />)}</FilesList>
)
