/**
 * get the scroll of the body
 * @return {number}
 */
export const getBodyScrollTop = (): number => {
  let docScrollTop = 0
  if (document.body && document.body !== null) {
    docScrollTop = document.body.scrollTop
  }

  if (!docScrollTop && document.documentElement && document.documentElement !== null) {
    docScrollTop = document.documentElement.scrollTop
  }
  return window.pageYOffset || docScrollTop
}

/**
 * get the scroll of element
 * @param {HTMLElement | null} element
 * @return {number}
 */
export const getElementScrollTop = (element: Element | null): number => {
  let elemScrollTop = 0
  if (element !== null) {
    elemScrollTop = element.scrollTop
  }
  return elemScrollTop
}

/**
 * get the scroll of element by it's ID
 * @return {number}
 */
export const getElementByIdScrollTop = (elementId: string): number => {
  let elemScrollTop = 0
  const element = document.getElementById(elementId)
  if (element !== null) {
    elemScrollTop = element.scrollTop
  }
  return elemScrollTop
}

/**
 * scroll to y number on the body
 * @param {number} scrollNumber
 * @return {void}
 */
export const bodyScrollTop = (scrollNumber = 0): void => {
  elementScrollTop(scrollNumber, document.body)
}

/**
 * scroll to y number on an element
 * @param {number} top
 * @param {HTMLElement | null} element
 * @param {ScrollBehavior} behavior
 * @return {void}
 */
export const elementScrollTop = (
  top: number,
  element: Element | null,
  behavior?: ScrollBehavior
): void => {
  if (element) {
    window.requestAnimationFrame(() => {
      ;(element as HTMLElement).style.scrollBehavior = behavior ?? "auto"
      element.scrollTo({ top, behavior })
      ;(element as HTMLElement)?.style?.removeProperty("scroll-behavior")
    })
  }
}

/**
 * scroll to y number inside an element by ID
 * @param {number} top
 * @param {string} elementId
 * @param {ScrollBehavior} behavior
 * @return {void}
 */
export const elementByIdScrollTop = (
  top: number,
  elementId: string,
  behavior?: ScrollBehavior
): void => {
  const element = document.getElementById(elementId)
  if (element) {
    elementScrollTop(top, element, behavior)
  }
}

/**
 * find the element's relative top position and scroll the container to that position
 * @param {HTMLElement | null} element to scroll to
 * @param {HTMLElement | undefined} scrollingElement to set the scrollTop on (uses the element's parent if undefined)
 * @param {ScrollBehavior} behavior
 * @return {void}
 */
export const scrollToElement = (
  element: HTMLElement | null,
  scrollingElement?: Element,
  behavior?: ScrollBehavior
): void => {
  const parent = scrollingElement || (!!element && element.parentElement)
  if (element && parent) {
    const top = element.offsetTop || element.clientTop || element.scrollTop
    if (top) {
      elementScrollTop(top, parent, behavior)
    }
  }
}
