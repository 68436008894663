import * as React from "react"
import { useEffectOnce, usePrevious } from "react-use"
import { type SpringConfig, type UseSpringProps } from "@react-spring/web"
import { useInternalUserSettings } from "src/frontend/hooks/useInternalUserSettings"
import { AnimationConfigDevTool } from "src/shared/components/AnimationControls/AnimationControlsDevTool"

/*
  CONTEXT
*/

/** These values come from the react-spring documentation */
export const CONFIG_DEFAULT_VALUES: SpringConfig = {
  bounce: 1,
  damping: 1,
  friction: 26,
  mass: 1,
  precision: 0.01,
  tension: 170,
  velocity: 0,
}

export interface ControlsRecord {
  config: SpringConfig
  updatedAt: number
  loop: boolean
}

export type ControlsRegistry = {
  [name: string]: ControlsRecord
}

export interface SpringControlsContextValue {
  registry: ControlsRegistry
  getConfig: (name: string) => SpringConfig
  updateConfig: (name: string, config: SpringConfig) => void
  updateLoop: (name: string, loop: boolean) => void
  forceUpdate: (name: string) => void
  addConfig: (name: string, config: SpringConfig) => void
  removeConfig: (name: string) => void
}

const DEFAULT_VALUE: SpringControlsContextValue = {
  registry: {},
  getConfig: () => ({}),
  updateConfig: () => {},
  updateLoop: () => {},
  forceUpdate: () => {},
  addConfig: () => {},
  removeConfig: () => {},
}

export const SpringControlsContext = React.createContext<SpringControlsContextValue>(DEFAULT_VALUE)

/*
  COMPONENTS
*/

export const SpringControlsContextProvider: React.FC<{
  enabled: boolean
  children?: React.ReactNode
}> = ({ enabled, children }) =>
  enabled ? (
    <EnabledSpringControlsContext>{children}</EnabledSpringControlsContext>
  ) : (
    <>{children}</>
  )

/** Provides the spring config context behavior only when enabled */
const EnabledSpringControlsContext: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [registry, setRegistry] = React.useState<ControlsRegistry>({})

  const getConfig = React.useCallback((name: string) => registry[name]?.config || {}, [registry])

  const forceUpdate = React.useCallback(
    (name: string) => {
      setRegistry({
        ...registry,
        [name]: {
          ...registry[name],
          updatedAt: new Date().getTime(),
        },
      } as ControlsRegistry)
    },
    [registry]
  )

  const updateLoop = React.useCallback(
    (name: string, loop: boolean) => {
      setRegistry({
        ...registry,
        [name]: {
          ...registry[name],
          loop,
          updatedAt: new Date().getTime(),
        },
      } as ControlsRegistry)
    },
    [registry]
  )

  const updateConfig = React.useCallback(
    (name: string, config: SpringConfig) => {
      // If loop is on, don't change the updated at value. The looping will pick up the change
      const updatedAt = registry[name]?.loop ? registry[name]?.updatedAt : new Date().getTime()

      setRegistry({
        ...registry,
        [name]: {
          ...registry[name],
          config,
          updatedAt,
        },
      } as ControlsRegistry)
    },
    [registry]
  )

  const addConfig = React.useCallback(
    (name: string, config: SpringConfig) => {
      setRegistry({
        ...registry,
        [name]: {
          config,
          updatedAt: new Date().getTime(),
          loop: false,
        },
      })
    },
    [registry]
  )

  const removeConfig = React.useCallback(
    (name: string) => {
      const newRegistry = {
        ...registry,
      }
      delete newRegistry[name]
      setRegistry(newRegistry)
    },
    [registry]
  )

  const value = React.useMemo(
    () => ({
      registry,
      getConfig,
      updateConfig,
      updateLoop,
      forceUpdate,
      addConfig,
      removeConfig,
    }),
    [registry, getConfig, updateConfig, updateLoop, forceUpdate, addConfig, removeConfig]
  )

  return (
    <SpringControlsContext.Provider value={value}>
      <AnimationConfigDevTool />
      {children}
    </SpringControlsContext.Provider>
  )
}

/*
  HOOKS
*/

/** Hook providing the SpringControlsContext */
export function useSpringControlsContext() {
  return React.useContext(SpringControlsContext)
}

/** Hook used to register a spring animation config, and receive updates to it through dev tools */
export function useSpringControls(
  name: string,
  config: SpringConfig,
  delay?: number
): Pick<UseSpringProps, "config" | "loop" | "delay"> {
  const { animationConfigDevToolsEnabled } = useInternalUserSettings()
  const { registry, addConfig } = useSpringControlsContext()

  useEffectOnce(() => {
    if (animationConfigDevToolsEnabled && !registry[name]) {
      addConfig(name, config)
    }
  })

  // TODO: Should remove the config when the last component using it is unmounted. Ref count?
  //       Not enabled right now because doing this causes a rerender loop, and I haven't found
  //       the solution.
  // React.useEffect(() => () => removeConfig(name), [name, removeConfig])

  const lookupConfig = React.useMemo(
    () => registry[name]?.config ?? config,
    [config, name, registry]
  )

  if (!animationConfigDevToolsEnabled) return { config }

  return { config: lookupConfig, loop: registry[name]?.loop, delay }
}

/**
 * Hook providing a React component key value which changes when the spring config is altered in
 * the dev tools. Use the return value as a component key at the appropriate level to retrigger
 * your animation.
 */
export function useSpringControlsKey(name: string) {
  const { registry } = useSpringControlsContext()
  const record = registry[name]
  const key = React.useMemo(() => record?.updatedAt?.toString(), [record])
  const prevKey = usePrevious(key)

  // Keep the key stable on the last good value when the config record is removed
  return key ?? prevKey
}

/** Hook providing the names of the currently registered spring animations */
export function useSpringControlsNames() {
  const { registry } = useSpringControlsContext()

  return React.useMemo(() => Object.keys(registry), [registry])
}
