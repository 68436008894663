import * as React from "react"
import { type Category, type TransactionParty } from "@digits-graphql/frontend/graphql-bearer"
import { IconSize } from "@digits-shared/components/UI/Icons/Icon"
import {
  EXPANDED_CLASS_NAME,
  HOVERABLE_CLASS_NAME,
  SELECTED_CLASS_NAME,
} from "@digits-shared/components/UI/Table"
import {
  Amount,
  RowContentDescription,
  RowContentTitle,
} from "@digits-shared/components/UI/Table/Content"
import moneyFlowHelper from "@digits-shared/helpers/moneyFlowHelper"
import { CurrencyStyle } from "@digits-shared/helpers/numberHelper"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"
import { TransactionIcon } from "src/shared/components/Transactions/TransactionRow/TransactionPartyIcon"
import { TransactionRecurrence } from "src/shared/components/Transactions/TransactionRow/TransactionRecurrence"
import { type TransactionProps } from "src/shared/components/Transactions/TransactionRow/types"

export const TRANSFER_OPACITY = 0.7
const TRANSFER_TEXT_COLOR = colors.subtitleGray

/*
  STYLES
*/

export const TRANSFER_TEXT_STYLES = css`
  color: ${TRANSFER_TEXT_COLOR};

  .${SELECTED_CLASS_NAME} &,
  ${"." + HOVERABLE_CLASS_NAME}:not(.${EXPANDED_CLASS_NAME}):hover & {
    color: ${colors.black};
  }
`

const RecurrenceWrapper = styled.div``

export const SpacingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const TransferContainer = styled(RowContentTitle)`
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: ${fonts.weight.medium};
  overflow: visible;
  opacity: ${TRANSFER_OPACITY};
`

const BankIconContainer = styled.div`
  margin-right: 5px;
`

const TransferAmount = styled(Amount)`
  font-size: inherit;
  ${TRANSFER_TEXT_STYLES};
`

const BankName = styled(RowContentDescription)`
  font-size: inherit;
  ${TRANSFER_TEXT_STYLES};
`

export const TransferText = styled.div`
  ${TRANSFER_TEXT_STYLES};
  margin: 0 5px;
  flex-shrink: 0;
`

const TransferRecurrence = styled(TransactionRecurrence)`
  margin-left: 10px;
`

/*
  INTERFACE
*/

interface TransferSideProps {
  institution?: TransactionParty | null
  category?: Category | null
}

/*
  COMPONENTS
*/

export const TransferTransaction: React.FC<TransactionProps> = ({ transaction }) => {
  const { moneyFlow } = transaction

  const amount = moneyFlowHelper.currency(moneyFlow, {
    style: CurrencyStyle.Detail,
    absolute: true,
  })

  return (
    <SpacingContainer>
      <TransferContainer>
        <TransferAmount flow={moneyFlow.businessFlow} normal={moneyFlow.isNormal}>
          {amount}
        </TransferAmount>
        <TransferText>transfer from</TransferText>
        <TransferSide
          transaction={transaction}
          institution={transaction.displayInstitution}
          category={transaction.displayCategory}
        />
        <TransferText>to</TransferText>
        <TransferSide
          transaction={transaction}
          institution={transaction.splitInstitution}
          category={transaction.splitCategory}
        />
      </TransferContainer>
      <RecurrenceWrapper>
        <TransferRecurrence transaction={transaction} />
      </RecurrenceWrapper>
    </SpacingContainer>
  )
}

const TransferSide: React.FC<TransactionProps & TransferSideProps> = ({
  transaction,
  institution,
  category,
}) => (
  <TransferContainer>
    <BankIconContainer>
      <TransactionIcon
        transaction={transaction}
        party={institution}
        category={category}
        iconSize={IconSize.Small}
      />
    </BankIconContainer>
    <BankName>{category?.name}</BankName>
  </TransferContainer>
)
