import * as React from "react"
import { Route, Switch } from "react-router-dom"
import { type EntityLegalEntity, ObjectKind } from "@digits-graphql/frontend/graphql-bearer"
import { LoggedRedirect } from "@digits-shared/components/Router/LoggedRedirect"
import { PageHeader, PageHeaderTitle } from "@digits-shared/components/UI/Elements/Container"
import { CopyrightFooter } from "@digits-shared/components/UI/Elements/CopyrightFooter"
import useRouter from "@digits-shared/hooks/useRouter"
import useStateBoolean from "@digits-shared/hooks/useStateBoolean"
import styled from "styled-components"
import { SharedWithMeCallToAction } from "src/frontend/components/OS/Applications/SharedWithMe/CallToAction"
import {
  SharedWithMeContentHeader,
  SharedWithMeContentHeaderLoading,
} from "src/frontend/components/OS/Applications/SharedWithMe/ContentHeader"
import { ObjectKindSelector } from "src/frontend/components/OS/Applications/SharedWithMe/ObjectKindSelector"
import {
  ALL_BUSINESSES,
  type LegalEntitySharedEntities,
  type ShareableEntity,
  type SharedEntities,
} from "src/frontend/components/OS/Applications/SharedWithMe/Shared"
import {
  SharedEntityItems,
  SharedEntityItemsLoading,
} from "src/frontend/components/OS/Applications/SharedWithMe/SharedEntityItems"
import { useSharedEntities } from "src/frontend/components/OS/Applications/SharedWithMe/useSharedEntities"
import { WideWidgetContainer } from "src/frontend/components/OS/Shared/Widgets/WidgetContainer"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import routes from "src/frontend/routes"
import { FrontendPartyRole } from "src/frontend/types/FrontendPartyRole"

/*
  STYLES
*/

const ApplicationContainer = styled.div`
  height: 100vh;
  position: relative;
`

const StyledWideWidgetContainer = styled(WideWidgetContainer)`
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding: 0;
`

const SharedWithMeCopyrightFooter = styled(CopyrightFooter)`
  padding-top: 10px;
  height: 53px;
`

/*
  COMPONENTS
*/

export const SharedWithMeApplication: React.FC = () => {
  const generatePath = useFrontendPathGenerator()

  return (
    <>
      <Switch>
        <Route
          path={[
            // use parties route to match the :partyRole
            routes.sharedWithMeParties.parameterizedPath,
            routes.sharedWithMe.parameterizedPath,
          ]}
          component={Content}
        />
        <LoggedRedirect
          name="SharedWithMeApplication-noMatch"
          to={generatePath(routes.sharedWithMe)}
        />
      </Switch>
      <SharedWithMeCopyrightFooter />
    </>
  )
}

const Content: React.FC = () => {
  const { location } = useRouter()
  const { entities, loading } = useSharedEntities()

  const selectedObjectKind = useSelectedObjectKind()
  const selectedPartyRole = useSelectedPartyRole()
  const selectedEntities = useSelectedEntities(selectedObjectKind, entities)
  const availableLegalEntities = useAvailableLegalEntities(selectedEntities)

  const empty =
    !entities.reportPackages?.length &&
    !entities.departments?.length &&
    !entities.locations?.length &&
    !entities.categories?.length &&
    !entities.transactions?.length &&
    !entities.parties?.size

  const { value: isPickerActive, toggle: togglePicker, setFalse: hidePicker } = useStateBoolean()
  const [selectedLE, setSelectedLE] = React.useState<EntityLegalEntity>(ALL_BUSINESSES)

  const onPickerClose = React.useCallback(() => {
    hidePicker()
  }, [hidePicker])

  const onPick = React.useCallback(
    (legalEntity: EntityLegalEntity) => {
      hidePicker()
      setSelectedLE(legalEntity)
    },
    [hidePicker, setSelectedLE]
  )

  const redirectPath = useFirstAvailableEntitiesPath(entities)

  if (!loading && !selectedEntities?.length && location.pathname !== redirectPath) {
    return <LoggedRedirect name="SharedWithMeApplication-noEntities" to={redirectPath} />
  }

  return (
    <ApplicationContainer>
      <PageHeader>{!empty && <PageHeaderTitle>Shared With Me</PageHeaderTitle>}</PageHeader>
      {loading ? (
        <StyledWideWidgetContainer>
          <SharedWithMeContentHeaderLoading />
          <SharedEntityItemsLoading />
        </StyledWideWidgetContainer>
      ) : (
        <>
          {empty ? (
            <SharedWithMeCallToAction />
          ) : (
            <StyledWideWidgetContainer>
              {!!selectedEntities?.length && selectedEntities?.length > 1 && (
                <SharedWithMeContentHeader
                  legalEntities={availableLegalEntities}
                  pickedLegalEntity={selectedLE}
                  togglePicker={togglePicker}
                  isPickerActive={isPickerActive}
                  onPickerClose={onPickerClose}
                  onPick={onPick}
                />
              )}
              <ObjectKindSelector entities={entities} />
              <SharedEntityItems
                selectedObjectKind={selectedObjectKind}
                selectedPartyRole={selectedPartyRole}
                selectedLegalEntity={selectedLE}
                entities={entities}
              />
            </StyledWideWidgetContainer>
          )}
        </>
      )}
    </ApplicationContainer>
  )
}

export default SharedWithMeApplication

function useSelectedObjectKind() {
  const { location } = useRouter()

  return React.useMemo(() => {
    switch (location.name) {
      case routes.sharedWithMeDepartments.name:
        return ObjectKind.Department

      case routes.sharedWithMeLocations.name:
        return ObjectKind.Location

      case routes.sharedWithMeCategories.name:
        return ObjectKind.Category

      case routes.sharedWithMeParties.name:
        return ObjectKind.Party

      case routes.sharedWithMeTransactions.name:
        return ObjectKind.Transaction

      case routes.sharedWithMe.name:
      case routes.sharedWithMeReportPackages.name:
      default:
        return ObjectKind.ReportPackage
    }
  }, [location])
}

function useSelectedPartyRole() {
  const {
    params: { partyRole },
  } = useRouter()

  return React.useMemo(() => FrontendPartyRole.findByURLKey(partyRole), [partyRole])
}

function useSelectedEntities(
  selectedObjectKind: ObjectKind,
  entities: SharedEntities
): LegalEntitySharedEntities<ShareableEntity>[] | undefined {
  const partyRole = useSelectedPartyRole()

  return React.useMemo(() => {
    switch (selectedObjectKind) {
      case ObjectKind.Department:
        return entities.departments
      case ObjectKind.Category:
        return entities.categories
      case ObjectKind.Transaction:
        return entities.transactions
      case ObjectKind.Party:
        return entities.parties.get(partyRole.partyRole)
      case ObjectKind.ReportPackage:
      default:
        return entities.reportPackages
    }
  }, [
    selectedObjectKind,
    entities.departments,
    entities.categories,
    entities.transactions,
    entities.parties,
    entities.reportPackages,
    partyRole.partyRole,
  ])
}

function useAvailableLegalEntities(
  selectedEntities?: LegalEntitySharedEntities<ShareableEntity>[]
) {
  return React.useMemo(
    () =>
      selectedEntities
        ?.filter((s) => s?.entities?.length)
        .map((s) => s.legalEntity)
        .concat([ALL_BUSINESSES]) || [],
    [selectedEntities]
  )
}

function useFirstAvailableEntitiesPath(entities: SharedEntities): string {
  const generatePath = useFrontendPathGenerator()
  const partyRole = useSelectedPartyRole()

  return React.useMemo(() => {
    switch (true) {
      case !!entities.reportPackages.length:
        return generatePath(routes.sharedWithMeReportPackages)
      case !!entities.categories.length:
        return generatePath(routes.sharedWithMeCategories)
      case !!entities.departments.length:
        return generatePath(routes.sharedWithMeDepartments)
      case !!entities.locations.length:
        return generatePath(routes.sharedWithMeLocations)
      case !!entities.transactions.length:
        return generatePath(routes.sharedWithMeTransactions)
      case !!entities.parties.size: {
        const urlPartyRole =
          partyRole !== FrontendPartyRole.Unknown
            ? partyRole.urlKey
            : FrontendPartyRole.findByRole(Array.from(entities.parties.keys())[0]).urlKey

        return generatePath(routes.sharedWithMeParties, { partyRole: urlPartyRole })
      }
      default:
        // No shared objects, going to main shared with me page should load zero state
        return generatePath(routes.sharedWithMe)
    }
  }, [
    entities.categories.length,
    entities.departments.length,
    entities.locations.length,
    entities.parties,
    entities.reportPackages.length,
    entities.transactions.length,
    generatePath,
    partyRole,
  ])
}
