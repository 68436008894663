import * as React from "react"
import { type EntityLegalEntity, type EntityParty } from "@digits-graphql/frontend/graphql-bearer"
import { InvertValuesContext } from "@digits-shared/components/Contexts/InvertValuesContext"
import { SharedPartyRow } from "src/frontend/components/OS/Applications/SharedWithMe/Entities/EntityRows/SharedPartyRow"
import {
  LegalEntitiesContainer,
  LegalEntityRows,
  useFilterByLegalEntity,
} from "src/frontend/components/OS/Applications/SharedWithMe/Entities/Shared"
import {
  type LegalEntitySharedEntities,
  type SharedEntities,
} from "src/frontend/components/OS/Applications/SharedWithMe/Shared"
import { ProductAreaContext } from "src/frontend/components/Shared/Contexts/ProductAreaContext"
import { type FrontendPartyRole } from "src/frontend/types/FrontendPartyRole"

/*
  COMPONENTS
*/
export const SharedPartiesByRole: React.FC<{
  partyEntities: SharedEntities["parties"]
  selectedPartyRole: FrontendPartyRole
  selectedLegalEntity?: EntityLegalEntity
}> = ({ partyEntities, selectedPartyRole, selectedLegalEntity }) => (
  <ProductAreaContext.Provider value={selectedPartyRole.productArea}>
    <InvertValuesContext.Provider value={selectedPartyRole.invertValues}>
      <SharedParties
        selectedPartyRole={selectedPartyRole}
        partyEntities={partyEntities.get(selectedPartyRole.partyRole) || []}
        selectedLegalEntity={selectedLegalEntity}
      />
    </InvertValuesContext.Provider>
  </ProductAreaContext.Provider>
)

const SharedParties: React.FC<{
  selectedPartyRole: FrontendPartyRole
  partyEntities: LegalEntitySharedEntities<EntityParty>[]
  selectedLegalEntity?: EntityLegalEntity
}> = ({ partyEntities, selectedPartyRole, selectedLegalEntity }) => {
  const filterParties = useFilterByLegalEntity(partyEntities, selectedLegalEntity)

  return (
    <LegalEntitiesContainer>
      {filterParties.map(({ legalEntity, entities }) => (
        <LegalEntityRows key={legalEntity.id} legalEntity={legalEntity}>
          {entities.map((sharedParty, idx) => (
            <SharedPartyRow
              key={idx}
              party={sharedParty.entity}
              partyRole={selectedPartyRole}
              legalEntity={legalEntity}
              viewType={sharedParty.viewType}
            />
          ))}
        </LegalEntityRows>
      ))}
    </LegalEntitiesContainer>
  )
}
