import * as React from "react"
import { defined } from "@digits-shared/helpers/filters"
import numberHelper from "@digits-shared/helpers/numberHelper"
import { useReportCommentsState } from "src/frontend/components/Shared/Reports/ReportComments/ReportCommentsContext"
import { findThreadTargetDOMRect } from "src/frontend/components/Shared/Reports/ReportComments/threadCoordinates"
import {
  type RegistryEntry,
  REPORT_TOP_LEVEL_THREAD,
  RESOLVED_REPORT_TOP_LEVEL_THREAD,
} from "src/frontend/components/Shared/Reports/ReportComments/types"

export function useCommentsRegistry() {
  const { registry } = useReportCommentsState()
  return registry
}

export function useCommentsRegistryEntries() {
  const { registry } = useReportCommentsState()
  const registryKeys = Object.keys(registry)

  return React.useMemo(
    () =>
      registryKeys
        .filter((context) => !context.includes(RESOLVED_REPORT_TOP_LEVEL_THREAD))
        .map<RegistryEntry | undefined>((context) => {
          const entry = registry[context]
          const targetCoordinates = findThreadTargetDOMRect(context)
          if (!entry || !targetCoordinates) return undefined

          return {
            ...entry,
            targetCoordinates,
          }
        })
        .filter(defined),
    [registry, registryKeys]
  )
}

export function useResolvedTopLevelThreads() {
  const { registry } = useReportCommentsState()
  const registryKeys = Object.keys(registry)

  return React.useMemo(
    () =>
      registryKeys
        .filter((context) => context.includes(RESOLVED_REPORT_TOP_LEVEL_THREAD))
        .map<RegistryEntry["thread"] | undefined>((context) => registry[context]?.thread)
        .filter(defined),
    [registry, registryKeys]
  )
}

export function useSortedThreads() {
  const entries = useCommentsRegistryEntries()
  return React.useMemo(
    () =>
      entries
        .filter((entry) => !entry.resolved && entry.context !== REPORT_TOP_LEVEL_THREAD)
        .sort(
          (a, b) =>
            (numberHelper.isNumber(a.targetCoordinates?.top) ? a.targetCoordinates?.top || 0 : 0) -
            (numberHelper.isNumber(b.targetCoordinates?.top) ? b.targetCoordinates?.top || 0 : 0)
        ),
    [entries]
  )
}

export function useActiveCommentEntry() {
  const { registry, activeContextId } = useReportCommentsState()
  if (!activeContextId) return undefined
  return registry[activeContextId]
}

export function useFindCommentEntryByContextPath(contextPath: string) {
  const entries = useCommentsRegistryEntries()
  return React.useMemo(() => {
    if (!contextPath) return []
    return entries
      .filter((entry) => !entry.resolved)
      .filter((entry) => entry.context.substring(0, contextPath.length) === contextPath)
  }, [contextPath, entries])
}

export function useReportEntities() {
  const { entities } = useReportCommentsState()
  return entities
}
