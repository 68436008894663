import React from "react"
import ReactDOM from "react-dom"
import { useEvent } from "react-use"
import { svgIconStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgHourglass01Solid } from "@digits-shared/components/SVGIcons/solid/Hourglass01Solid.svg"
import { POP_UP_LIGHT_BACKGROUND_STYLES } from "@digits-shared/components/UI/Elements/PopUp/PopUp"
import { useMeasure } from "@digits-shared/hooks/useMeasure"
import { useModalRoot } from "@digits-shared/hooks/useModalRoot"
import { themedStyles } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import styled, { css } from "styled-components"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/useViewVersion"
import { type TypeaheadKinds } from "src/shared/components/Typeahead/sharedTypeahead"
import { TypeaheadInput } from "src/shared/components/Typeahead/TypeaheadInput"
import { TypeaheadSearch } from "src/shared/components/Typeahead/TypeaheadSearch"
import zIndexes from "src/shared/config/zIndexes"

/*
 STYLES
*/

const menuStyles = themedStyles({
  light: css`
    ${POP_UP_LIGHT_BACKGROUND_STYLES};
    background: ${colors.secondary05};
    box-shadow: 0px 0px 8px 0px rgba(52, 52, 116, 0.3);
  `,
  dark: css`
    background: radial-gradient(53.24% 100% at 50% 0%, #3f3f3f 0%, #212121 100%);
    box-shadow: 0 13px 27px ${colors.translucentBlack10};
  `,
})

const Menu = styled.div<{ shouldPositionBottom: boolean; $width?: number }>`
  ${menuStyles};
  position: absolute;
  ${({ shouldPositionBottom }) =>
    !shouldPositionBottom
      ? css`
          top: calc(100% + 5px);
        `
      : css`
          bottom: 10px;
        `}
  left: 0;
  min-width: ${({ $width }) => ($width ? `${$width}px` : "290px")};
  max-width: ${({ $width }) => ($width ? `${$width}px` : "350px")};
  padding: 0;
  overflow: hidden;
  z-index: ${zIndexes.modalOverlay + 1};
  transform: translate3d(0px, 0px, 0px);
  border-radius: 8px;
`

const Mask = styled.div`
  z-index: ${zIndexes.modalOverlay + 1};
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`

const ParentPlaceholder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`

const FixedPosition = styled.div<FixedPositionProps>`
  position: fixed;
  z-index: ${zIndexes.modalOverlay + 1};

  // Position the typeahead using top, which is the bottom of the positionRef
  // or position it relative to the bottom of the viewport if the typeahead would intersect with the viewport
  ${({ $position }) =>
    $position && !$position.shouldPositionBottom
      ? css`
          top: ${$position.top}px;
        `
      : $position &&
        css`
          bottom: 0px;
        `}

  ${({ $position }) =>
    $position &&
    css`
      left: ${$position.left}px;
    `}

  ${({ $position }) =>
    !$position &&
    css`
      display: none;
    `}
`

const HourglassIcon = styled(SvgHourglass01Solid)`
  ${svgIconStyles(colors.secondary)};
  width: 24px;
  height: 24px;
`

const NoViewMessage = styled.div`
  min-width: 300px;
  display: flex;
  gap: 32px;
  margin: 24px 8px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

/*
 INTERFACES
*/

interface FixedPositionProps {
  $position?: TypeaheadPosition
}

type TypeaheadProps = TypeaheadKinds & {
  className?: string
  onClear?: () => void
  currentValue?: string
  positionRef?: React.RefObject<HTMLElement>
  width?: number
  placeholder?: string
  close: () => void
}

interface TypeaheadPortalProps {
  typeaheadPosition?: TypeaheadPosition
  positionRef?: React.RefObject<HTMLElement>
  placeholderComponentRef: React.RefObject<HTMLDivElement>
}

interface TypeaheadPosition extends MenuPosition {
  top: number
  left: number
}

interface MenuPosition {
  shouldPositionBottom: boolean
}

/*
 COMPONENTS
*/

export const Typeahead = React.forwardRef<HTMLInputElement, TypeaheadProps>((props, ref) => {
  const { className, kind, positionRef, width, placeholder } = props
  const { typeaheadPosition, menuRef, placeholderComponentRef } =
    useTypeaheadPortalState(positionRef)

  const [searchTerm, setSearchTerm] = React.useState("")
  const { viewVersion } = useViewVersion()

  const onChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.currentTarget.value)
  }, [])

  return (
    <TypeaheadPortal
      positionRef={positionRef}
      typeaheadPosition={typeaheadPosition}
      placeholderComponentRef={placeholderComponentRef}
    >
      <Menu
        shouldPositionBottom={Boolean(typeaheadPosition?.shouldPositionBottom)}
        className={className}
        ref={menuRef}
        $width={width ?? 300}
      >
        {viewVersion ? (
          <>
            <TypeaheadInput
              ref={ref}
              onChange={onChange}
              placeholder={placeholder ?? "Find " + kind.toLowerCase() + "…"}
              width={width}
              autoFocus
            />
            <TypeaheadSearch searchTerm={searchTerm} {...props} />
          </>
        ) : (
          <NoViewMessage>
            We're still computing your Digits. <HourglassIcon /> Please return in a few minutes.
          </NoViewMessage>
        )}
      </Menu>
    </TypeaheadPortal>
  )
})

// TypeaheadPortal is a wrapper around Typeahead that fixed positions the typeahead in a portal
// if a positionRef is provided, it will use that to position the typeahead
// otherwise it will use the position of the first relatively positioned parent component via the ParentPlaceholder component.
// Positioning via the ParentPlaceholder component mimics the current Typeahead behavior
// but still allows the portal benefits of being able to overflow the parent container;
const TypeaheadPortal: React.FC<React.PropsWithChildren<TypeaheadPortalProps>> = ({
  positionRef: optionalPositionRef,
  typeaheadPosition,
  placeholderComponentRef,
  children,
}) => {
  const modalRoot = useModalRoot()

  return (
    <>
      {/*
          Placeholder component to get the position of the component that wraps the typeahead
        */}
      {!optionalPositionRef && <ParentPlaceholder ref={placeholderComponentRef} />}
      {ReactDOM.createPortal(
        <Mask>
          <FixedPosition $position={typeaheadPosition}>{children}</FixedPosition>
        </Mask>,
        modalRoot
      )}
    </>
  )
}

function useTypeaheadPortalState(optionalPositionRef?: React.RefObject<HTMLElement>) {
  const [menuRef, { height: menuHeight }] = useMeasure<HTMLDivElement>()
  const placeholderComponentRef = React.useRef<HTMLDivElement>(null)
  const positionRef = optionalPositionRef ?? placeholderComponentRef
  const [typeaheadPosition, setTypeaheadPosition] = React.useState<TypeaheadPosition | undefined>(
    undefined
  )

  const positionTypeahead = React.useCallback(() => {
    if (positionRef.current) {
      const { bottom, left, top } = positionRef.current.getBoundingClientRect()
      // Fix the typeahead position to the bottom of the viewport if it would overflow the bottom of the viewport
      const shouldPositionBottom = bottom + menuHeight > window.innerHeight || top < 0
      // Fix the typeahead position to the left of the viewport if it would overflow the left of the viewport
      const shouldPositionUsingViewPortLeft = left < 10

      setTypeaheadPosition({
        top: bottom,
        left: shouldPositionUsingViewPortLeft ? 10 : left,
        shouldPositionBottom,
      })

      document
        .querySelector(`${Menu} .current`)
        ?.scrollIntoView({ behavior: "instant", block: "center" })
    }
  }, [menuHeight, positionRef])

  useEvent("resize", positionTypeahead)

  React.useLayoutEffect(() => {
    positionTypeahead()
  }, [positionTypeahead])

  return React.useMemo(
    () => ({
      typeaheadPosition,
      menuRef,
      placeholderComponentRef,
    }),
    [typeaheadPosition, menuRef]
  )
}
