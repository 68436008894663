import React from "react"
import {
  DirectionFromOrigin,
  Interval,
  useListReportsBadgeCountsQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"

export function useReportsBadgeCountsQuery(legalEntityIds: string[], skip?: boolean) {
  const origin = dateTimeHelper.todayIntervalOrigin(Interval.Month, 2)

  const result = useListReportsBadgeCountsQuery({
    variables: {
      origin,
      direction: DirectionFromOrigin.Past,
      legalEntityIds,
    },
    skip,
  })

  return React.useMemo(() => {
    const counts = result?.data?.listReportsBadgeCounts

    return { counts, loading: result.loading }
  }, [result])
}
