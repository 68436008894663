import React from "react"
import { Interval } from "@digits-graphql/frontend/graphql-bearer"
import { TimeContext } from "src/shared/components/Contexts/TimeContext"

/**
 * Returns the Interval Origin in the current context
 */
export default function useIntervalOrigin() {
  const intervalOrigin = React.useContext(TimeContext)

  // Memoize so that a new object is only returned if something changes
  return React.useMemo(() => intervalOrigin, [intervalOrigin])
}

export const DEFAULT_INTERVAL_COUNT_MONTH = 12
export const DEFAULT_INTERVAL_COUNT_QUARTER = 10
export const DEFAULT_INTERVAL_COUNT_YEAR = 10

export function useIntervalOriginWithDefaultCount() {
  const intervalOrigin = useIntervalOrigin()

  return React.useMemo(() => {
    switch (intervalOrigin.interval) {
      case Interval.Quarter:
        return {
          ...intervalOrigin,
          intervalCount: intervalOrigin.intervalCount || DEFAULT_INTERVAL_COUNT_QUARTER,
        }
      case Interval.Year:
        return {
          ...intervalOrigin,
          intervalCount: intervalOrigin.intervalCount || DEFAULT_INTERVAL_COUNT_YEAR,
        }
      case Interval.Month:
      default:
        return {
          ...intervalOrigin,
          intervalCount: intervalOrigin.intervalCount || DEFAULT_INTERVAL_COUNT_MONTH,
        }
    }
  }, [intervalOrigin])
}
