import * as React from "react"
import useSession from "@digits-shared/hooks/useSession"
import type Permissions from "@digits-shared/session/Permissions"
import type FrontendSession from "src/frontend/session"
import {
  type FrontendPermissionModule,
  FrontendPermissionSource,
} from "src/frontend/session/permissionModule"

/**
 * Get permissions stored on JWT for the specified source (e.g. Organization or Legal Entity).
 *
 *    - Source of LegalEntity should be used to check for access to the Legal Entity in context.
 *    - Source of Organization should be used to check for access regardless of the Legal Entity
 *      set in context.
 *
 *      If you are unsure which source should be used, a good question to ask is:
 *      Is this an action that an affiliate is taking on their Organization (not the affiliated
 *      Legal Entity's Organization)?
 *
 *      If yes, then you'll likely want to use FrontendPermissionSource.Organization.
 *      Otherwise, if it's about an action a user is taking on a Legal Entity (or the Legal Entity's
 *      owning Organization), then you'll likely want FrontendPermissionSource.LegalEntity.
 *
 *      Scenario 1:
 *      While an accountant is viewing one of their clients, we want to check if they have access
 *      to add a new client to their Organization. In this example, the current context is set with
 *      the accounting firm's Organization and the Legal Entity of their client (via an affiliation).
 *      We will want to pass FrontendPermissionSource.Organization to check against the permissions
 *      set on the accounting firm rather than the affiliation permission to the Legal Entity.
 *
 *      Scenario 2:
 *      For any action that is being done directly to a Legal Entity, we'll want to always specify
 *      FrontendPermissionSource.LegalEntity. For the operator case, there is effectively no
 *      difference between passing source of Organization or Legal Entity. However, in order
 *      for checks to work correctly for either an affiliate or operator of a Legal Entity, we will
 *      need to pass FrontendPermissionSource.LegalEntity. This ensures we correctly use the
 *      affiliation (i.e. accountants client) permissions rather the Organization (i.e. accounting
 *      firm) when an affiliate is viewing an associated Legal Entity.
 *
 * @param source {FrontendPermissionSource} The source from context whose permissions to check
 */
export function useJwtPermissions(
  source: FrontendPermissionSource
): Permissions<FrontendPermissionModule> | undefined {
  const { currentOrganization, currentAffiliation } = useSession<FrontendSession>()

  return React.useMemo(() => {
    switch (source) {
      // If source is organization, we can simply return the organization permissions
      case FrontendPermissionSource.Organization:
        return currentOrganization?.permissions
      // If source is legal entity and the affiliation permissions are present, then
      // we can assume the current user is viewing an affiliated legal entity and access
      // is controlled by the permissions defined on the affiliation.
      //
      // If access to the legal entity is not through an affiliation, we can assume this
      // is an operator use case, and the access to the legal entity is controlled
      // by the permissions that are defined on the organization (via their employment).
      case FrontendPermissionSource.LegalEntity:
        return currentAffiliation
          ? currentAffiliation.permissions
          : currentOrganization?.permissions
    }
  }, [currentAffiliation, currentOrganization?.permissions, source])
}
