import {
  type EntityCategory,
  type EntityDepartment,
  type EntityLocation,
  type EntityParty,
  ObjectKind,
  PartyRole,
  type TextComponentConfigTag,
  type TextTagOptions,
} from "@digits-graphql/frontend/graphql-bearer"
import { defined, uniqueBy } from "@digits-shared/helpers/filters"
import { SUPPORTED_PARTY_ROLES } from "src/frontend/types/FrontendPartyRole"
import {
  DATA_DECORATOR,
  DATA_ID,
} from "src/shared/components/EditableContent/editableContentConstants"

const ENTITY_REGEX = /([^|>]+)\|([^|>]+)(?:\|([^>]+))?/
const ENTITIES_TAGS_REGEX = /(<[^|>]+\|[^>]+>)/i
const ENTITY_TAG_REGEX = new RegExp(`<${ENTITY_REGEX.source}>`) // includes `<` `>`

export function extractEntitiesAsTags(
  text: string,
  legalEntityId: string
): TextComponentConfigTag[] {
  return text
    .split(ENTITIES_TAGS_REGEX)
    .flatMap<TextComponentConfigTag | undefined>((part) => {
      const entity = part.match(ENTITY_TAG_REGEX)
      if (!entity) return undefined

      const [_, kindStr, id, title] = entity
      const { kind, options } = getObjectKindFromString(kindStr || "")
      if (!kind) return undefined

      return {
        displayText: title || "",
        objectId: {
          legalEntityId,
          id: id || "",
          kind,
        },
        options,
      }
    })
    .filter(defined)
    .filter(uniqueBy((tag) => `${tag.objectId.id}${tag.options?.partyRole || ""}`))
}

export function convertToDigitsEntityTags(text: string) {
  // reformat entities from `<EntityKind|ID|Title>` to well-formed `<digits-entity data-id="ID" data-decorator="EntityKind">Title</digits-entity>`
  // so that `DOMPurify` correctly parses out the custom elements
  return text
    .split(ENTITIES_TAGS_REGEX)
    .flatMap((part) => {
      const entity = part.match(ENTITY_TAG_REGEX)
      if (!entity) return part

      const [_, entityName, entityId, title] = entity
      return `<digits-entity ${DATA_DECORATOR}="${entityName}" title="${title}" ${DATA_ID}="${entityId}">${title}</digits-entity>`
    })
    .join("")
    .replaceAll("\n", "<BR />") // support legacy comments with \n line breaks
}

export function getObjectKindFromString(kindStr: string): {
  kind?: ObjectKind
  options?: TextTagOptions
} {
  const dynamicEntityRole = `Entity${kindStr}Role` as PartyRole
  const dynamicRole = `${kindStr}Role` as PartyRole
  const partyRole = PartyRole[dynamicEntityRole] || PartyRole[dynamicRole]
  if (SUPPORTED_PARTY_ROLES.includes(partyRole)) {
    return { kind: ObjectKind.Party, options: { partyRole } }
  }

  // TODO: remove fallback when insights include roles
  const dynamicKind = kindStr as ObjectKind
  if (ObjectKind[dynamicKind] === ObjectKind.Party) {
    return { kind: ObjectKind.Party, options: { partyRole: PartyRole.EntityVendorRole } }
  }

  return { kind: ObjectKind[dynamicKind] }
}

export type HoverableEntity = EntityParty | EntityCategory | EntityDepartment | EntityLocation
