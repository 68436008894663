import * as React from "react"
import { ObjectKind, useReadShareAdjacencyQuery } from "@digits-graphql/frontend/graphql-bearer"
import { useSharedObjectInfo } from "src/frontend/hooks/useSharedObjectInfo"

/** Determines if the user has permission to the shared object via adjacency */
export function useSharedObjectAdjacencyPermission({ skip }: { skip: boolean }) {
  const sharedObjectInfo = useSharedObjectInfo()

  const { data, loading, error } = useReadShareAdjacencyQuery({
    variables: {
      // The fallback values here won't be used, but are required to keep the compiler happy.
      // We know that sharedObjectInfo will be defined if we make a call here, (based on
      // needsCheck) but we can't introduce a conditional before this hook to prove it
      // without upsetting the hooks linter.
      kind: sharedObjectInfo?.kind || ObjectKind.User,
      id: sharedObjectInfo?.id || "",
      legalEntitySlug: sharedObjectInfo?.legalEntitySlug || "",
    },
    skip: skip || !sharedObjectInfo,
    fetchPolicy: "no-cache",
  })

  return React.useMemo(
    () => ({
      data: data?.readShareAdjacency,
      loading,
      error,
    }),
    [data, loading, error]
  )
}
