import * as React from "react"
import { type AccessConstraint, PartyRole } from "@digits-graphql/frontend/graphql-bearer"
import useRouter from "@digits-shared/hooks/useRouter"
import { FrontendPartyRole } from "src/frontend/types/FrontendPartyRole"

export function useAccessConstraint() {
  const { params } = useRouter()
  const { partyRole } = params

  return React.useMemo((): AccessConstraint | undefined => {
    const frontendPartyRole = FrontendPartyRole.findByURLKey(partyRole)

    if (frontendPartyRole.partyRole !== PartyRole.UnknownRole) {
      return {
        partyRole: frontendPartyRole.partyRole,
      }
    }

    return undefined
  }, [partyRole])
}
