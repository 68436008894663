import * as React from "react"
import { type IntervalOrigin } from "@digits-graphql/frontend/graphql-bearer"
import stringHelper from "@digits-shared/helpers/stringHelper"
import urlHelper from "@digits-shared/helpers/urlHelper"
import useRouter from "@digits-shared/hooks/useRouter"
import { useHighlight } from "src/frontend/components/OS/Details/Shared/hooks/useHighlight"
import { CASH_METRIC_TYPES, CashBadge } from "src/frontend/components/Shared/Layout/CashBadge"
import { BarChartComponent } from "src/frontend/components/Shared/Layout/Components/Charts/BarChartComponent"
import {
  type Summaries,
  type TimeseriesValue,
  toSortedTimeseriesValues,
} from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"
import { ComponentExpandLink } from "src/frontend/components/Shared/Layout/Components/Headers/ComponentExpandIcon"
import { type ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { useComponentDetailsLinkPath } from "src/frontend/components/Shared/Layout/hooks/useEntityDetailsViewPaths"
import {
  type MatchedComponent,
  type SizingProps,
} from "src/frontend/components/Shared/Layout/types"

/*
  INTERFACES
*/

interface MetricChartComponentProps extends SizingProps {
  summaries?: Summaries
  origin: IntervalOrigin
  component: MatchedComponent<"metricChart">
  componentSize: ComponentSize
  skipAnimations: boolean
}

/*
  COMPONENTS
*/

export const MetricChartComponent: React.FC<MetricChartComponentProps> = ({
  summaries,
  origin,
  component,
  componentSize,
  height,
  skipAnimations,
}) => {
  const { item: configType } = component.config.metricChart
  const { history } = useRouter()
  const metricPath = useComponentDetailsLinkPath(configType, origin)

  const timeseries = React.useMemo(() => toSortedTimeseriesValues(summaries), [summaries])
  const highlight = useHighlight()
  const [selectedIndex, setSelectedIndex] = React.useState(highlight ?? undefined)

  const onMouseOver = React.useCallback((_: TimeseriesValue, index: number) => {
    setSelectedIndex(index)
  }, [])

  const onMouseOut = React.useCallback(() => {
    setSelectedIndex(highlight ?? undefined)
  }, [highlight])

  const onBarClick = React.useCallback(
    (_: TimeseriesValue, index: number) => {
      setSelectedIndex(index)
      if (!metricPath) return
      const path = urlHelper.addSearchParams(metricPath, { highlight: index })
      if (!path) return
      history.push(path)
    },
    [history, metricPath]
  )

  const title = React.useMemo(() => {
    const badge = CASH_METRIC_TYPES.includes(configType) ? <CashBadge /> : undefined

    return (
      <ComponentExpandLink to={metricPath} linkBadge={badge} componentSize={componentSize}>
        {stringHelper.camelCaseToSpaces(configType)}
      </ComponentExpandLink>
    )
  }, [componentSize, configType, metricPath])

  return (
    <BarChartComponent
      componentSize={componentSize}
      title={title}
      icon={undefined}
      timeseries={timeseries}
      height={height}
      skipAnimations={skipAnimations}
      onClick={onBarClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      selectedIndex={selectedIndex}
    />
  )
}
