import * as React from "react"
import { LoadingBlock } from "@digits-shared/components/Loaders"
import borders from "@digits-shared/themes/borders"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import { useLegendGroupByDataId } from "src/frontend/components/OS/Shared/Charts/StackableBarChart/Legend/useLegendGroupByDataId"
import { type StackableBarData } from "src/frontend/components/OS/Shared/Charts/StackableBarChart/Shared"
import { useStackableBarChartContext } from "src/frontend/components/OS/Shared/Charts/StackableBarChart/StackableBarChartContext"

/*
 STYLES
*/

const LegendsCount = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
  margin: 0 auto;
`

const Legend = styled.div<{ isDimmed: boolean }>`
  display: flex;
  align-items: flex-end;
  padding: 0 10px;
  opacity: ${({ isDimmed }) => (isDimmed ? 0.5 : 1)};
`

const LegendCount = styled.div`
  font-size: 18px;
  color: ${colors.neonGreen};
  text-shadow: ${borders.theme.dark.textShadow};
  margin-bottom: -1px;
`

const LegendText = styled.span`
  padding-left: 5px;
  font-family: ${fonts.family.avenir};
  font-size: 13px;
  font-weight: ${fonts.weight.medium};
  color: ${colors.secondary};
  text-transform: uppercase;
`

const LegendLoading = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`

/*
 COMPONENTS
*/

export const StackableBarChartLegendCount: React.FC = () => {
  const { loading, tooltipData } = useStackableBarChartContext()

  const legendsByDataId = useLegendGroupByDataId()

  if (loading) {
    return (
      <LegendLoading>
        <LoadingBlock width="80px" height="25px" margin="0 10px" />
        <LoadingBlock width="95px" height="25px" margin="0 10px" />
      </LegendLoading>
    )
  }

  const items = Object.values(legendsByDataId).map((stack) => {
    // Can use first bar data in stack since we only need to check for data id and legend
    // which should be the same for each one
    const barData = stack[0] as StackableBarData
    return (
      <Legend
        key={barData.dataId}
        isDimmed={!!tooltipData && tooltipData.dataId !== barData.dataId}
      >
        <LegendCount>{barData.total.transactionsCount}</LegendCount>
        <LegendText>{barData.legend}</LegendText>
      </Legend>
    )
  })

  return <LegendsCount>{items}</LegendsCount>
}
