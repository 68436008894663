import { LogOutDocument, LogOutSupportDocument } from "@digits-graphql/frontend/graphql-public"
import useSessionLogOut from "@digits-shared/hooks/useSessionLogOut"
import routes from "src/frontend/routes"

export function useLogOut() {
  return useSessionLogOut({
    rootPath: routes.root.parameterizedPath,
    LogOut: LogOutDocument,
    LogOutDoppelganger: LogOutSupportDocument,
  })
}
