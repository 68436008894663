import * as React from "react"
import { InvertValuesContext } from "@digits-shared/components/Contexts/InvertValuesContext"
import { DeltaChevron, DeltaContainer } from "@digits-shared/components/UI/Elements/Delta"
import { Amount } from "@digits-shared/components/UI/Table/Content"
import stringHelper from "@digits-shared/helpers/stringHelper"
import styled from "styled-components"
import { ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { useRemoveConfig } from "src/frontend/components/Shared/Portals/ConfigsSidebar/ComponentConfigContext"
import {
  Title,
  type TopEntitiesProps,
} from "src/frontend/components/Shared/Portals/ConfigsSidebar/Configs/Lists/shared"
import { useTopPartiesLiveData } from "src/frontend/components/Shared/Portals/hooks/useTopPartiesLiveData"
import {
  Categories,
  TopPartiesList,
} from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/Shared/TopPartiesList"
import { type PartyTransactionSummary } from "src/frontend/types"
import { FrontendPartyRole } from "src/frontend/types/FrontendPartyRole"

const StyledTopPartiesList = styled(TopPartiesList)`
  ${Categories} {
    color: #0c404b99;
  }

  ${Amount} {
    color: #0c404b;
  }

  ${DeltaContainer} {
    color: #0c404b99;

    ${DeltaChevron} {
      border-bottom-color: #0c404b99;
      border-top-color: #0c404b99;
    }
  }
`

/*
  COMPONENTS
*/

export const TopPartiesConfig: React.FC<TopEntitiesProps> = ({ config, componentType }) => {
  const removeConfig = useRemoveConfig()

  const role = React.useMemo(
    () => (config.partyRole ? FrontendPartyRole.findByRole(config.partyRole) : undefined),
    [config.partyRole]
  )

  const invertValues = role?.invertValues ?? false

  const countDisplay = role
    ? stringHelper.pluralize(config.limit, role.displayName, role.pluralDisplayName)
    : ""

  const { data, loading } = useTopPartiesLiveData(config, componentType)

  const partySummaries: PartyTransactionSummary[] | undefined = React.useMemo(() => {
    if (!data) return undefined

    return data.entities.party.map((p) => ({
      party: p.partyObject,
      summary: p.summary,
      categoryNames: p.by.category.map((c) => c.categoryObject.name),
    }))
  }, [data])

  // remove this component if the results are empty
  React.useEffect(() => {
    if (partySummaries && !partySummaries?.length) {
      removeConfig()
    }
  }, [partySummaries, removeConfig])

  if (!countDisplay) return null

  return (
    <InvertValuesContext.Provider value={invertValues}>
      <Title componentSize={ComponentSize.Small}>Top {countDisplay} </Title>
      <StyledTopPartiesList padding={10} partySummaries={partySummaries} loading={loading} />
    </InvertValuesContext.Provider>
  )
}
