import * as React from "react"
import { defined } from "@digits-shared/helpers/filters"
import { toSortedTimeseriesValues } from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"
import { type LocationChartData } from "src/frontend/types"

export function useLocationChartTimeseries(data: LocationChartData | undefined) {
  return React.useMemo(() => {
    const breakdownTimeseries = [
      { label: "Income", values: toSortedTimeseriesValues(data?.incomeChange) },
      { label: "Other Income", values: toSortedTimeseriesValues(data?.otherIncomeChange) },
      { label: "COGS", values: toSortedTimeseriesValues(data?.cogsChange) },
      { label: "Expenses", values: toSortedTimeseriesValues(data?.expensesChange) },
      { label: "Other Expenses", values: toSortedTimeseriesValues(data?.otherExpensesChange) },
    ].filter(defined)

    const totalTimeseries = { label: "Total", values: toSortedTimeseriesValues(data?.total) }

    return { breakdownTimeseries, totalTimeseries }
  }, [
    data?.cogsChange,
    data?.expensesChange,
    data?.incomeChange,
    data?.otherExpensesChange,
    data?.otherIncomeChange,
    data?.total,
  ])
}
