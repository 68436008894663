import React from "react"
import { isNetworkRequestInFlight } from "@apollo/client/core/networkStatus"
import {
  type ObjectIdentifier,
  ObjectKind,
  type User,
} from "@digits-graphql/frontend/graphql-bearer"
import { defined, uniqueBy } from "@digits-shared/helpers/filters"
import userHelper from "@digits-shared/helpers/userHelper"
import useSession from "@digits-shared/hooks/useSession"
import { useListSuggestedUsers } from "src/frontend/hooks/useListSuggestedUsers"
import type FrontendSession from "src/frontend/session"
import { type UserTypeaheadResult } from "src/shared/components/Typeahead/sharedTypeahead"

export function useTypeaheadUsers(text: string, objectIdentifier: ObjectIdentifier) {
  const { currentOrganizationId } = useSession<FrontendSession>()
  const { users, networkStatus } = useListSuggestedUsers(objectIdentifier, currentOrganizationId)

  return React.useMemo(
    () => ({
      loading: isNetworkRequestInFlight(networkStatus),
      results: buildUserResults(text, users),
    }),
    [networkStatus, text, users]
  )
}

function userToTypeaheadResult(user: User): UserTypeaheadResult {
  return {
    id: user.id,
    title: userHelper.displayName(user),
    subtitle: user.primaryEmailAddress,
    searchValues: [user.givenName, user.familyName, user.primaryEmailAddress].filter(defined),
    kind: ObjectKind.User,
    entity: userHelper.userToEntity(user),
  }
}

function buildUserResults(query: string, users: User[] | undefined) {
  const searchTerm = query.trim()
  if (!users) return []

  return users
    .map(userToTypeaheadResult)
    .filter(uniqueBy((u) => u.id))
    .filter((data) => data.searchValues.some((v) => v.toLowerCase().startsWith(searchTerm)))
    .sort((a, b) => a.title.localeCompare(b.title))
}
