import * as React from "react"
import { useEscapeKeyCapture } from "@digits-shared/hooks/useEscapeKeyCapture"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import { ActionItemsHeader } from "src/frontend/components/Shared/NavSidebar/ActionItems/ActionItemsHeader"
import { GroupedActionItemsList } from "src/frontend/components/Shared/NavSidebar/ActionItems/List"
import { useGroupView } from "src/frontend/components/Shared/NavSidebar/ActionItems/useGroupView"
import {
  ASSIGNMENTS_SIDEBAR_EXPANSION_WIDTH,
  SIDEBAR_EXPANSION_WIDTH,
} from "src/frontend/components/Shared/NavSidebar/constants"
import { useInternalUserSettings } from "src/frontend/hooks/useInternalUserSettings"

/*
  STYLES
*/

const ActionItemsContainer = styled.div<{ showAssignments?: boolean }>`
  width: ${({ showAssignments }) =>
    showAssignments ? ASSIGNMENTS_SIDEBAR_EXPANSION_WIDTH : SIDEBAR_EXPANSION_WIDTH}px;
  height: 100%;
  border-radius: 0 16px 16px 0;
  box-shadow: inset 34px 0px 40px -21px ${colors.translucentSecondary10};
  display: flex;
  flex-direction: column;
`

/*
  COMPONENTS
*/

export const ActionItemsSidebarContent: React.FC<{ hideSidebar: () => void }> = ({
  hideSidebar,
}) => {
  const showAssignments = useInternalUserSettings().actionItemAssignments
  const { groupView, setGroupView } = useGroupView()
  useEscapeKeyCapture(
    hideSidebar,
    // needs to be nested within document to capture the escape event before DetailsView
    { target: document?.body }
  )

  return (
    <ActionItemsContainer showAssignments={showAssignments}>
      <ActionItemsHeader groupView={groupView} setGroupView={setGroupView} />
      <GroupedActionItemsList groupView={groupView} hideSidebar={hideSidebar} />
    </ActionItemsContainer>
  )
}
