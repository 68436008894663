import * as React from "react"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import { Tooltip as VXTooltip } from "@visx/tooltip"
import styled, { css } from "styled-components"

export const TooltipLabel = styled.div`
  font-size: 12px;
  font-weight: ${fonts.weight.normal};
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
`

export const TOOLTIP_STYLES = css`
  display: flex;
  z-index: 6;
  align-items: center;
  position: absolute;
  padding: 4px 8px;
  border-radius: 4px !important;
  background-color: ${colors.black};
  border-color: ${colors.black} !important;
`

const TooltipStyled = styled(VXTooltip)<{ $backgroundColor?: string }>`
  ${TOOLTIP_STYLES};
  ${({ $backgroundColor }) => css`
    background-color: ${$backgroundColor};
  `}
`

interface TooltipProps {
  children: React.ReactNode
  top?: number | string
  left?: number | string
  right?: number | string
  fontColor?: string
  className?: string
}

export const Tooltip: React.FC<TooltipProps> = ({
  left,
  right,
  top,
  fontColor = colors.white,
  children,
  className,
}) => {
  const styles: React.CSSProperties = {
    top: typeof top === "number" ? `${top}px` : top,
    left: right ? "auto" : typeof left === "number" ? `${left}px` : left,
    right: typeof right === "number" ? `${right}px` : right,
    color: fontColor,
  }

  return (
    <TooltipStyled className={className} style={styles}>
      <TooltipLabel>{children}</TooltipLabel>
    </TooltipStyled>
  )
}
