import * as React from "react"
import useRowSelectionContext from "src/frontend/components/Shared/Reports/Report/Components/Statements/RowSelectionContext"
import {
  STATEMENT_HOVERED_COLUMN_CLASSNAME,
  STATEMENT_ROWS_CLASSNAME,
  TotalCell,
} from "src/frontend/components/Shared/Reports/Report/Components/Statements/shared"
import { HighlightColumn } from "src/frontend/components/Shared/Reports/Report/Components/Statements/StatementColumnHighlights"
import {
  useReportExtraColumnsCount,
  useStatementBreakdownColumnsCount,
} from "src/frontend/components/Shared/Reports/Report/hooks/useReportDocumentOptions"

export function useRowSelectionHandlers() {
  const { pinnedCell } = useRowSelectionContext()
  const columnsCount = useStatementBreakdownColumnsCount() + useReportExtraColumnsCount()

  const selectors = React.useMemo(
    () => ({
      rows: `.${STATEMENT_ROWS_CLASSNAME}`,
      header: `.${HighlightColumn.styledComponentId}`,
      cell: `.${TotalCell.styledComponentId}`,
    }),
    []
  )

  const headerIndex = React.useRef<number>()

  const updateColumnHighlight = React.useCallback(
    (row: HTMLElement, target: EventTarget | null, shouldHighlight = true) => {
      if (!row || !target) return

      const column = (target as HTMLElement)?.closest?.(selectors.cell)
      if (!column) return

      const columns = row.querySelectorAll(selectors.cell)
      const index = Array.from(columns).indexOf(column) + 2

      if (shouldHighlight && index === headerIndex.current) return
      headerIndex.current = shouldHighlight ? index : undefined

      const rows = row?.closest(selectors.rows)
      if (!rows) return

      const headers = rows.querySelectorAll(selectors.header)
      headers.forEach((h) => h.classList.remove(STATEMENT_HOVERED_COLUMN_CLASSNAME))

      if (shouldHighlight) {
        headers[index]?.classList.add(STATEMENT_HOVERED_COLUMN_CLASSNAME)
      }
    },
    [selectors]
  )

  // Handle pinned cell state
  React.useEffect(() => {
    if (!columnsCount) return

    const rows = pinnedCell?.target?.closest(selectors.rows)
    if (!rows) return

    rows.classList.add("pinned-cell")
  }, [columnsCount, pinnedCell, selectors.rows])

  const onMouseMove = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (!columnsCount) return
      updateColumnHighlight(e.currentTarget, e.target)
    },
    [columnsCount, updateColumnHighlight]
  )

  const onMouseLeave = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      updateColumnHighlight(e.currentTarget, e.target, false)
    },
    [updateColumnHighlight]
  )

  return { onMouseMove, onMouseLeave }
}
