import * as React from "react"
import { shallow } from "zustand/shallow"
import {
  usePortalDispatch,
  usePortalStore,
} from "src/frontend/components/Shared/Portals/State/portalStore"

/** Ensures that certain invariants remain true for builder state */
export function usePortalStateInvariants() {
  const { activeComponentId, layout } = usePortalStore(
    (state) => ({
      activeComponentId: state.activeComponentId,
      layout: state.layout,
    }),
    shallow
  )
  const portalDispatch = usePortalDispatch()

  // Ensure there is always a section in the layout
  React.useEffect(() => {
    // Avoid enforcing these invariants while a drag is active
    if (activeComponentId) return

    const { sections } = layout

    if (!sections || !sections.length) {
      portalDispatch({ type: "addSection" })
    }
  }, [activeComponentId, layout, portalDispatch])
}
