import * as React from "react"
import { DigitsLinkButton } from "@digits-shared/DesignSystem/LinkButton"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import routes from "src/frontend/routes"

/*
  STYLES
*/

const CTAImage = styled.img`
  width: 935px;
  margin: 0px auto;
  top: 202px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 502px;
  width: 748px;
  flex-wrap: wrap;
  margin-top: 10px;
`

const SharedWithMeCallToActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const AddFirmText = styled.div`
  color: ${colors.translucentSecondary80};
  font-size: 23px;
  line-height: 145%;
  margin-bottom: 8px;
`

const WelcomeText = styled.div`
  font-weight: ${fonts.weight.medium};
  font-size: 32px;
  line-height: 44px;
  color: ${colors.secondary};
  margin-bottom: 10px;
`

const AIText = styled.div`
  display: flex;
  font-weight: ${fonts.weight.book};
  font-size: 24px;
  line-height: 33px;
`

const GreenAIText = styled.div`
  color: ${colors.primary};
`

/*
  COMPONENTS
*/

export const SharedWithMeCallToAction: React.FC = () => {
  const pathGenerator = useFrontendPathGenerator()

  return (
    <SharedWithMeCallToActionContainer>
      <WelcomeText>Welcome To Digits</WelcomeText>
      <AIText>
        <div>AI-powered accounting tools that</div>
        &nbsp;
        <GreenAIText>delight your clients</GreenAIText>
      </AIText>
      <AIText>
        <div>and</div>
        &nbsp;
        <GreenAIText>save you time.</GreenAIText>
      </AIText>
      <CTAImage src={require("static/images/sharing/welcome.png")} />
      <Container>
        <AddFirmText>Add your accounting firm to use the power of Digits.</AddFirmText>
        <DigitsLinkButton css="display: flex;" to={pathGenerator(routes.welcome)}>
          Get Started
        </DigitsLinkButton>
      </Container>
    </SharedWithMeCallToActionContainer>
  )
}
