import { AnimatablePopUp } from "@digits-shared/components/UI/Elements/PopUp/AnimatablePopUp"
import { PickerPosition } from "@digits-shared/components/UI/Picker/constants"
import borders from "@digits-shared/themes/borders"
import styled, { css } from "styled-components"

export const DEFAULT_PICKER_CHEVRON_HEIGHT = 7

export const PICKER_MAX_HEIGHT = 270

export type PickerPopUpAcceptedPromiseArg = PickerPopUpAcceptedOptions | unknown

export interface PickerPopUpAcceptedOptions {
  noCloseAnimation?: boolean
  noLockAnimation?: boolean
}

export function isPickerPopUpAcceptedOptions(
  options: PickerPopUpAcceptedPromiseArg
): options is PickerPopUpAcceptedOptions {
  return (options as PickerPopUpAcceptedOptions)?.noCloseAnimation !== undefined
}

export const PickerPopUp = styled(AnimatablePopUp)<{
  isDisabled: boolean
  pickerPosition: PickerPosition
}>`
  ${({ pickerPosition }) =>
    pickerPosition === PickerPosition.AboveTarget
      ? css`
          transform: translateY(calc(-100% - 55px));
        `
      : css`
          margin-top: 13px;
        `};

  border-radius: ${borders.theme.dark.radius.modal}px;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      pointer-events: none;
      filter: grayscale(0.7);
    `}
`
