import { FeatureKind } from "@digits-graphql/frontend/graphql-bearer"
import numberHelper from "@digits-shared/helpers/numberHelper"
import colors from "@digits-shared/themes/colors"
import {
  type BoundingBox,
  type Point,
  type PointBounds,
  type Rect,
} from "src/frontend/components/OS/Shared/DocumentViewer/types"

const PAGE_GAP = 40

export function documentDownloadUrl(
  collectionId: string,
  fileId: string,
  token: string,
  fileName?: string // makes the file-transfer include Content-Disposition header (download file to disk)
): string {
  const endpoint = process.env.DOCUMENT_DOWNLOAD_ENDPOINT
  if (!endpoint) return ""

  const url = new URL(endpoint)
  url.searchParams.append("document-collection-id", collectionId)
  url.searchParams.append("file-id", fileId)
  url.searchParams.append("token", token)
  if (fileName) {
    url.searchParams.append("file-name", fileName)
  }

  return url.href
}

export function strokeWidth(pageWidth: number, emphasized?: boolean): number {
  return (
    numberHelper.clamp(3 * (pageWidth / 2500), 1, 5) + emphasizedStrokeWidth(pageWidth, emphasized)
  )
}

export function boxOffset(pageWidth: number, emphasized: boolean | undefined): number {
  return (
    numberHelper.clamp(3 * (pageWidth / 2500), 1, 3) + emphasizedStrokeWidth(pageWidth, emphasized)
  )
}

export function emphasizedStrokeWidth(pageWidth: number, emphasized: boolean | undefined) {
  if (!emphasized) return 0
  return numberHelper.clamp(2 * (pageWidth / 2500), 1, 2)
}

export function yOffsetForPage(pageIndex: number, imageEls: SVGImageElement[]) {
  return imageEls.reduce((offset, imgEl, i) => {
    if (i >= pageIndex) return offset

    return offset + (imgEl.getBBox().height ?? 0) + PAGE_GAP
  }, 0)
}

export function getRectOverlapArea(a: Rect, b: Rect) {
  const aPoints = toPointBounds(a)
  const bPoints = toPointBounds(b)
  const xOverlap = Math.max(
    0,
    Math.min(aPoints.maxPoint.x, bPoints.maxPoint.x) -
      Math.max(aPoints.minPoint.x, bPoints.minPoint.x)
  )
  const yOverlap = Math.max(
    0,
    Math.min(aPoints.maxPoint.y, bPoints.maxPoint.y) -
      Math.max(aPoints.minPoint.y, bPoints.minPoint.y)
  )
  return xOverlap * yOverlap
}

export function getSurroundingRectForBoxes<T>(boxes: BoundingBox<T>[]): Rect {
  return getSurroundingRectForRects(boxes.map((b) => b.rect))
}

export function getSurroundingRectForRects(rects: Rect[]): Rect {
  const pointBounds = rects.map(toPointBounds)
  const initialBounds: PointBounds = { minPoint: { x: 0, y: 0 }, maxPoint: { x: 0, y: 0 } }

  const bounds = pointBounds.reduce((acc, b) => {
    if (acc === initialBounds) return b

    return {
      minPoint: minPoint(acc.minPoint, b.minPoint),
      maxPoint: maxPoint(acc.maxPoint, b.maxPoint),
    }
  }, initialBounds)

  return toRect(bounds)
}

export function minPoint(p1: Point, p2: Point): Point {
  return {
    x: Math.min(p1.x, p2.x),
    y: Math.min(p1.y, p2.y),
  }
}

export function maxPoint(p1: Point, p2: Point): Point {
  return {
    x: Math.max(p1.x, p2.x),
    y: Math.max(p1.y, p2.y),
  }
}

export function toPointBounds(rect: Rect): PointBounds {
  return {
    minPoint: {
      x: rect.x,
      y: rect.y,
    },
    maxPoint: {
      x: rect.x + rect.width,
      y: rect.y + rect.height,
    },
  }
}

export function toRect(bounds: PointBounds): Rect {
  return {
    x: bounds.minPoint.x,
    y: bounds.minPoint.y,
    width: bounds.maxPoint.x - bounds.minPoint.x,
    height: bounds.maxPoint.y - bounds.minPoint.y,
  }
}

export function newPoint(x: number, y: number): Point {
  return { x, y }
}

export function newRect(x: number, y: number, width: number, height: number): Rect {
  return { x, y, width, height }
}

export function colorForFeatureKind(
  kind: FeatureKind,
  emphasized: boolean | undefined,
  showDebugColors: boolean | undefined
): string {
  if (showDebugColors) {
    switch (kind) {
      case FeatureKind.VendorName:
        return "#6929c4"
      case FeatureKind.VendorPhoneNumber:
        return "#9f1853"
      case FeatureKind.VendorAddress:
        return "#198038"
      case FeatureKind.VendorEmail:
        return "cyan"
      case FeatureKind.VendorWebsite:
        return "yellow"
      case FeatureKind.InvoiceId:
        return "#b28600"
      case FeatureKind.InvoiceDate:
        return "#8a3800"
      case FeatureKind.DueDate:
        return "#570408"
      case FeatureKind.Terms:
        return "#005d5d"
      case FeatureKind.PayerName:
        return "#1192e8"
      case FeatureKind.PayerAddress:
        return "#fa4d56"
      case FeatureKind.PayerEmail:
        return "#002d9c"
      case FeatureKind.PayerCompanyName:
        return "#002d9c"
      case FeatureKind.TotalAmount:
        return "#ee538b"
      case FeatureKind.WireInfo:
        return "aquamarine"
      case FeatureKind.PaymentInstructions:
        return "coral"
      case FeatureKind.RemittanceAddress:
        return "darkgreen"
      case FeatureKind.OtherPaymentInfo:
        return "darkslateblue"
      case FeatureKind.AccountNumber:
        return "darkseagreen"
      case FeatureKind.CheckInfo:
        return "firebrick"
      default:
        return colors.transparent
    }
  }

  if (kind === FeatureKind.Other) {
    return colors.transparent
  }

  return emphasized ? colors.primary : colors.translucentPrimary60
}

// Sort for bounding boxes that pushes "Other" kind to the end. This helps them
// be drawn on top of other boxes for the purposes of selecting with click-to-copy.
export function byFeatureKind(a: BoundingBox<unknown>, b: BoundingBox<unknown>) {
  if (a.kind === FeatureKind.Other && b.kind === FeatureKind.Other) {
    return 0
  }
  if (a.kind === FeatureKind.Other) {
    return 1
  }
  if (b.kind === FeatureKind.Other) {
    return -1
  }
  return a.kind.localeCompare(b.kind)
}

export function isDocumentViewerRelatedEvent(event: React.FocusEvent<HTMLInputElement>): boolean {
  return !!event.relatedTarget?.getAttribute?.("data-document-viewer")
}
