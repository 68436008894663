import * as React from "react"
import { chevronStyles, PointingDirection } from "@digits-shared/components/UI/Elements/Chevron"
import {
  type ColumnStructure,
  defaultColumnRenderer,
  PaddingCell,
  renderDefaultPaddingCell,
  renderEmptySideColumnExpandedCell,
  TableCell,
} from "@digits-shared/components/UI/Table/Column"
import { TableRow } from "@digits-shared/components/UI/Table/Row"
import { SortOrder, TableBody } from "@digits-shared/components/UI/Table/TableBody"
import {
  EXPANDED_CLASS_NAME,
  HOVERABLE_CLASS_NAME,
  LEFT_SIDE_EXPANDABLE_CLASS_NAME,
  RIGHT_SIDE_EXPANDABLE_CLASS_NAME,
  SELECTED_CLASS_NAME,
} from "@digits-shared/components/UI/Table/tableConstants"
import numberHelper from "@digits-shared/helpers/numberHelper"
import { themedValue } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"

/*
  STYLES
*/

const headerColor = themedValue({
  light: colors.secondary,
  dark: colors.translucentWhite50,
})
export const TableHeader = styled.thead<TableHeaderProps>`
  font-size: 10px;
  color: ${headerColor};
  text-transform: uppercase;

  ${(props) =>
    props.isLoading &&
    css`
      pointer-events: none;
    `}

  ${TableCell} {
    padding: 5px 0;
    cursor: initial;
    vertical-align: bottom;
  }

  /* Table header borders  */
  & + ${TableBody} > ${TableRow}:first-child {
    & > ${TableCell} {
      border-top: none;
    }

    ${(props) =>
      !props.isLoading &&
      css`
        &.${HOVERABLE_CLASS_NAME}, &.${SELECTED_CLASS_NAME} {
          &:hover,
          &.${EXPANDED_CLASS_NAME}, &.${SELECTED_CLASS_NAME} {
            &
              > ${PaddingCell}:first-child,
              &
              > ${PaddingCell}:last-child,
              &.${LEFT_SIDE_EXPANDABLE_CLASS_NAME}
              > ${PaddingCell}:nth-child(2),
            &.${LEFT_SIDE_EXPANDABLE_CLASS_NAME}
              > ${TableCell}:first-child,
              &.${RIGHT_SIDE_EXPANDABLE_CLASS_NAME}
              > ${PaddingCell}:nth-last-child(2),
            &.${RIGHT_SIDE_EXPANDABLE_CLASS_NAME} > ${TableCell}:last-child {
              border-top: none;
            }
          }
        }
      `}
  }
`

export const SortingHeaderTitle = styled.div`
  display: inline-block;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

export const SortingHeaderChevron = styled.div`
  margin-left: 5px;
  margin-right: 10px;
  width: 10px;
  height: 0;
`

export const SortingHeaderColumn = styled.div<SortingHeaderColumnProps>`
  position: relative;
  display: flex;
  ${(props) =>
    props.colSize?.textAlign === "right"
      ? css`
          flex-direction: row-reverse;
        `
      : css`
          justify-content: flex-start;
        `};

  ${TableCell}:last-child &,
  ${PaddingCell} ~ ${TableCell}:nth-last-child(2) & {
    flex-direction: row-reverse;
  }

  ${(props) =>
    props.disableSorting &&
    css`
      pointer-events: none;
    `}

  ${(props) => css`
    ${SortingHeaderChevron}::after {
      ${chevronStyles(props.direction, "3px", colors.translucentWhite50)};
      position: absolute;
      top: 45%;
      visibility: ${props.isSortedByColumn ? "visible" : "hidden"};
    }

    ${props.isSortedByColumn &&
    css`
      font-weight: ${fonts.weight.black};
      color: #4490b0;

      ${SortingHeaderChevron}::after {
        ${chevronStyles(props.direction, "3px", "#4490B0")}
      }
    `}
  `}
`

/*
  TYPES
*/

export interface HeaderProps {
  columnName: string
  key?: string | number
  children?: React.ReactNode
}

export type HeaderRender = (props: HeaderProps) => React.ReactNode

export interface HeaderComponent<V> {
  component: React.FC<HeaderProps & V>
  sortingHeaderTitleComponent?: React.FC<HeaderProps>
  props?: V
}

export type HeaderRenderer<V> = HeaderRender | HeaderComponent<V>

export type SortingColumns = { [key: string]: SortingHeaderProps }

export interface TableSorting {
  sortingColumns: SortingColumns
  activeSort: SortAction
}

export interface SortAction {
  sortingColumn: string
  order: SortOrder
}

export interface TableHeaderProps {
  isLoading?: boolean
  addPaddingCells?: boolean | number
  isLeftSideExpandable?: boolean
  isRightSideExpandable?: boolean
  children?: React.ReactNode
}

export interface SortingHeaderProps {
  sortOrder: SortOrder
  isSortedByColumn?: boolean
}

interface SortingHeaderColumnProps extends SortingHeaderProps {
  colSize?: ColumnStructure
  disableSorting?: boolean
  onHeaderClick?: (columnName: string) => void
  isSortedByColumn?: boolean
  direction: PointingDirection
}

export function updateSortingColumns(
  setSortingColumns: React.Dispatch<SortAction>,
  sortingColumn: string,
  order: SortOrder
) {
  setSortingColumns({ sortingColumn, order })
}

function renderHeader<V>(
  columnName: string,
  headerRenderer: HeaderRenderer<V>,
  key?: string | number
) {
  const usingComponent = headerRenderer as HeaderComponent<V>
  if (usingComponent.component) {
    const props = usingComponent.props || ({} as V)
    const Header = usingComponent.component as React.FC<HeaderProps & V>
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Header columnName={columnName} key={key} {...props} />
  }

  const usingRender = headerRenderer as HeaderRender
  return usingRender({ columnName, key })
}

export function defaultHeaderRenderer<V>(
  columnName: string,
  key?: string | number,
  headerRenderer?: HeaderRenderer<V>,
  colSize?: ColumnStructure
) {
  if (!headerRenderer) {
    return defaultColumnRenderer(columnName, key, colSize)
  }

  const content = renderHeader(columnName, headerRenderer, key)
  return defaultColumnRenderer(content, key, colSize)
}

export function sortingHeaderColumn<V>(
  { onHeaderClick, ...props }: Omit<SortingHeaderColumnProps, "direction">,
  columnName: string,
  key?: string | number,
  headerRenderer?: HeaderRenderer<V>
) {
  const { sortOrder, colSize, isSortedByColumn } = props
  const pointingDirection =
    sortOrder === SortOrder.Ascending ? PointingDirection.Up : PointingDirection.Down

  let customHeader
  if (headerRenderer) {
    const usingComponent = headerRenderer as HeaderComponent<V>
    const renderedCustomHeader = renderHeader(columnName, headerRenderer, key)
    if (usingComponent.sortingHeaderTitleComponent) {
      const TitleComponent = usingComponent.sortingHeaderTitleComponent
      customHeader = (
        <TitleComponent columnName={columnName} key={key} {...props}>
          {renderedCustomHeader}
        </TitleComponent>
      )
    } else {
      customHeader = <SortingHeaderTitle>{renderedCustomHeader}</SortingHeaderTitle>
    }
  } else {
    customHeader = columnName
  }

  const handleClick = onHeaderClick?.bind(undefined, columnName)

  return (
    <TableCell size={colSize} key={key}>
      <SortingHeaderColumn
        onClick={handleClick}
        {...props}
        direction={pointingDirection}
        isSortedByColumn={isSortedByColumn}
      >
        {customHeader}
        <SortingHeaderChevron />
      </SortingHeaderColumn>
    </TableCell>
  )
}

export const Header: React.FC<TableHeaderProps> = (props) => {
  const { isLoading, isLeftSideExpandable, isRightSideExpandable, addPaddingCells, children } =
    props

  const paddingCellWidth = numberHelper.isNumber(addPaddingCells) ? addPaddingCells : undefined
  const classNames = [
    isLoading ? HOVERABLE_CLASS_NAME : "",
    isLeftSideExpandable ? LEFT_SIDE_EXPANDABLE_CLASS_NAME : "",
    isRightSideExpandable ? RIGHT_SIDE_EXPANDABLE_CLASS_NAME : "",
  ].join(" ")
  return (
    <TableHeader {...props}>
      <TableRow className={classNames}>
        {isLeftSideExpandable && renderEmptySideColumnExpandedCell()}
        {addPaddingCells && renderDefaultPaddingCell("padding-header1", paddingCellWidth)}
        {children}
        {addPaddingCells && renderDefaultPaddingCell("padding-header2", paddingCellWidth)}
        {isRightSideExpandable && renderEmptySideColumnExpandedCell()}
      </TableRow>
    </TableHeader>
  )
}
