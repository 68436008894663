import * as React from "react"
import { useDebounce } from "react-use"
import { type LayoutComponentConfig } from "@digits-graphql/frontend/graphql-bearer"
import { type DayPickerLocation } from "@digits-shared/components/UI/Elements/Inputs/DateInput"
import dayjs from "@digits-shared/initializers/dayjs/dayjs"
import { type MatchedComponent } from "src/frontend/components/Shared/Layout/types"
import { type PortalAction } from "src/frontend/components/Shared/Portals/State/actions"
import {
  OptionDate,
  OptionInput,
} from "src/frontend/components/Shared/Reports/Report/Options/ReportOptionsUIs"
import { type LayoutBuilderAction } from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/actions"

/*
  INTERFACES
*/

interface OptionsProps<T> {
  component: MatchedComponent<"staticMetric">
  dispatch: (action: T) => T
  datePickerLocation?: DayPickerLocation
}

/*
  COMPONENTS
*/

export const KPIOptions: React.FC<OptionsProps<PortalAction | LayoutBuilderAction>> = ({
  component,
  dispatch,
  datePickerLocation,
}) => {
  const {
    config: {
      staticMetric: { name, value, updatedAt },
    },
  } = component
  const [debouncedName, setDebouncedName] = React.useState(name)
  const [debouncedValue, setDebouncedValue] = React.useState(value)
  const [debouncedDate, setDebouncedDate] = React.useState(updatedAt)

  React.useEffect(() => {
    setDebouncedName(name)
    setDebouncedValue(value)
    setDebouncedDate(updatedAt)
  }, [name, value, updatedAt])

  useDebounce(
    React.useCallback(() => {
      if (
        (!debouncedName && !debouncedValue && !debouncedDate) ||
        (debouncedName === name && debouncedValue === value && debouncedDate === updatedAt)
      ) {
        return
      }

      const updatedConfig: LayoutComponentConfig = {
        ...component.config,
        staticMetric: {
          ...component.config.staticMetric,
          name: debouncedName,
          value: debouncedValue,
          updatedAt: debouncedDate,
        },
      }

      dispatch({
        type: "setConfig",
        config: updatedConfig,
        componentId: component.componentId,
        save: true,
      })
    }, [
      debouncedName,
      debouncedValue,
      name,
      value,
      component.config,
      component.componentId,
      debouncedDate,
      updatedAt,
      dispatch,
    ]),
    800,
    [debouncedName, debouncedValue, debouncedDate]
  )

  const onNameChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { currentTarget } = e
    setDebouncedName(currentTarget.value)
  }, [])

  const onValueChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { currentTarget } = e
    setDebouncedValue(currentTarget.value)
  }, [])

  const onDateChange = React.useCallback((newDate: Date) => {
    setDebouncedDate(dayjs(newDate).unix())
    return Promise.resolve()
  }, [])

  const updatedAtDate = dayjs.unix(updatedAt).utc().toDate()
  return (
    <div>
      <OptionInput
        title="Header"
        maxLength={38}
        defaultValue={name}
        onChange={onNameChange}
        placeholder="Add Header"
      />
      <OptionInput
        title="Value & Unit"
        maxLength={140}
        defaultValue={value}
        onChange={onValueChange}
        placeholder="Add Value and Unit"
      />

      <OptionDate
        title="Date"
        defaultDate={updatedAtDate}
        onDateChange={onDateChange}
        location={datePickerLocation}
      />
    </div>
  )
}
