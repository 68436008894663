import { uniqueBy } from "@digits-shared/helpers/filters"
import type Permissions from "@digits-shared/session/Permissions"
import type SessionAffiliation from "src/frontend/session/Affiliation"
import type SessionLegalEntity from "src/frontend/session/LegalEntity"
import {
  type SessionOrganization,
  type SessionOrganizationAttributes,
} from "src/frontend/session/Organization"
import { type FrontendPermissionModule } from "src/frontend/session/permissionModule"
import { type Experience } from "src/frontend/session/personas"

export interface SessionBusinessOrganizationAttributes extends SessionOrganizationAttributes {
  legalEntities: SessionLegalEntity[]
  affiliations?: SessionAffiliation[]
  permissions: Permissions<FrontendPermissionModule>
  primaryExperience?: Experience
  employmentId?: string
  employmentActivated?: boolean
}

/**
 * Simple wrapper for organizations that a user belongs to stored on JWT.
 *
 * Provides convenience methods, better naming than what is found on the
 * intentionally abbreviated JWT, and clean types.
 */
export default class SessionBusinessOrganization implements SessionOrganization {
  private readonly attributes: SessionBusinessOrganizationAttributes

  constructor(rawOrganization: SessionBusinessOrganizationAttributes) {
    this.attributes = {
      ...rawOrganization,
    }
  }

  addLegalEntities(entities: SessionLegalEntity[]) {
    this.attributes.legalEntities = [...this.legalEntities, ...entities].filter(
      uniqueBy((e) => e.id)
    )
  }

  addAffiliations(affiliations: SessionAffiliation[]) {
    this.attributes.affiliations = [...(this.affiliations ?? []), ...(affiliations ?? [])].filter(
      uniqueBy((a) => a.id)
    )
  }

  /*
    ACCESSORS
  */

  get id() {
    return this.attributes.id
  }

  get slug() {
    return this.attributes.slug
  }

  get name() {
    return this.attributes.name
  }

  get iconUrl() {
    return this.attributes.iconUrl
  }

  get legalEntities() {
    return this.attributes.legalEntities
  }

  get affiliations() {
    return this.attributes.affiliations
  }

  get permissions() {
    return this.attributes.permissions
  }

  get employmentId() {
    return this.attributes.employmentId
  }

  /*
    CONVENIENCE METHODS
  */

  get employmentActivated() {
    return this.attributes.employmentActivated
  }

  get hasApprovedLegalEntity() {
    return !!this.approvedLegalEntities.length
  }

  get hasActiveLegalEntity() {
    return !!this.activeLegalEntities.length
  }

  get hasDashboardLegalEntity() {
    return !!this.dashboardLegalEntities.length
  }

  get hasPendingLegalEntity() {
    return !!this.pendingLegalEntities.length
  }

  get hasNewLegalEntity() {
    return !!this.newLegalEntities.length
  }

  get approvedLegalEntities() {
    return this.attributes.legalEntities.filter((legalEntity) => legalEntity.isApproved)
  }

  get activeLegalEntities() {
    return this.attributes.legalEntities.filter((legalEntity) => legalEntity.isActive)
  }

  get dashboardLegalEntities() {
    return this.attributes.legalEntities.filter(
      (legalEntity) => legalEntity.isActive || legalEntity.isApproved
    )
  }

  get approvedAffiliations() {
    return this.attributes.affiliations?.filter((legalEntity) => legalEntity.entity.isApproved)
  }

  get activeAffiliations() {
    return this.attributes.affiliations?.filter((legalEntity) => legalEntity.entity.isActive)
  }

  get dashboardAffiliations() {
    return this.attributes.affiliations?.filter(
      (legalEntity) => legalEntity.entity.isApproved || legalEntity.entity.isActive
    )
  }

  get pendingLegalEntities() {
    return this.attributes.legalEntities.filter((legalEntity) => legalEntity.isPending)
  }

  get pendingAffiliations() {
    return this.attributes.affiliations?.filter((legalEntity) => legalEntity.entity.isPending)
  }

  get newLegalEntities() {
    return this.attributes.legalEntities.filter((legalEntity) => legalEntity.isNew)
  }

  get newAffiliations() {
    return this.attributes.affiliations?.filter((legalEntity) => legalEntity.entity.isNew)
  }

  get hasAffiliations() {
    return !!this.affiliations?.length
  }

  get hasApprovedAffiliations() {
    return !!this.approvedAffiliations?.length
  }

  get hasActiveAffiliations() {
    return !!this.activeAffiliations?.length
  }

  get hasDashboardAffiliations() {
    return !!this.dashboardAffiliations?.length
  }

  get hasPendingAffiliations() {
    return !!this.pendingAffiliations?.length
  }

  get hasNewAffiliations() {
    return !!this.newAffiliations?.length
  }

  get affiliatedLegalEntities() {
    return this.affiliations?.map((af) => af.entity) || []
  }

  get affiliatedOrganizations() {
    return this.affiliations?.map((af) => af.organization) || []
  }

  get primaryExperience() {
    return this.attributes.primaryExperience
  }
}
