import useSession from "@digits-shared/hooks/useSession"
import { createScope } from "bunshi"
import { ScopeProvider } from "bunshi/react"
import type FrontendSession from "src/frontend/session/FrontendSession"

export const LegalEntityIdScope = createScope<string | undefined>(undefined, {
  debugLabel: "LegalEntityId",
})

export const LegalEntityIdScopeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const session = useSession<FrontendSession>()

  return (
    <ScopeProvider scope={LegalEntityIdScope} value={session.currentLegalEntityId}>
      {children}
    </ScopeProvider>
  )
}
