import * as React from "react"

export const GlowFilter: React.FC<{ id: string; standardDeviation: number }> = ({
  id,
  standardDeviation,
}) => (
  <filter id={id} x="-50%" y="-50%" width="200%" height="200%">
    <feGaussianBlur stdDeviation={standardDeviation} result="coloredBlur" />
    <feMerge>
      <feMergeNode in="coloredBlur" />
      <feMergeNode in="SourceGraphic" />
    </feMerge>
  </filter>
)
