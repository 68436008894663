import { SummaryDetail } from "@digits-shared/components/UI/Summaries/Summary"
import borders from "@digits-shared/themes/borders"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import { ActivePeriodContainer } from "src/shared/components/PeriodSelector/ActivePeriod"

export const SummaryChartGraph = styled.div`
  position: relative;
  padding: 0 25px;
  height: 270px;
`

export const SummaryHeaderRow = styled.div`
  margin: 20px 20px 0 30px;
  display: flex;
  flex-direction: row;
  flex-grow: 1;

  ${ActivePeriodContainer} {
    border: 1px solid transparent;
    border-radius: ${borders.radius.default}px;
    font-size: 13px;
    color: ${colors.secondary};
    text-transform: none;
  }
`

export const SummaryContainer = styled.div`
  display: flex;
  flex: 1;
`

export const SummaryDetailStyled = styled(SummaryDetail)`
  display: flex;
  flex-direction: column;
  gap: 6px;
  border: unset;
  padding: 0;
  margin-right: 40px;
`

export const SummaryLabel = styled.span`
  display: flex;
  color: ${colors.translucentSecondary80};
  font-size: 12px;
  font-weight: ${fonts.weight.normal};
  align-self: start;
`

export const SummaryAmount = styled.div`
  color: ${colors.secondary};
  display: inline-flex;
  align-self: start;
  align-items: center;
  font-family: ${fonts.family.avenir};
  font-size: 18px;
  font-weight: ${fonts.weight.heavy};
`
