import * as React from "react"
import useForwardedRef from "@digits-shared/hooks/useForwardedRef"
import styled, { keyframes } from "styled-components"

/*
  STYLES
*/

const ANIMATION_KEYFRAMES = keyframes`
  0% {
    background-position: 100% top;
  }
  100% {
    background-position: 0 top;
  }
`

const propsToStyle = ({ spriteURL }: AnimatedIconProps) => ({
  style: {
    backgroundImage: `url(${spriteURL})`,
  },
})

const BaseAnimatedIcon = styled.div.attrs<AnimatedIconProps>(propsToStyle)`
  background-size: auto 100%;
  background-position: 0 top;
  background-repeat: no-repeat;
  animation: ${ANIMATION_KEYFRAMES} 1s linear infinite;
`

/*
  INTERFACES
*/

interface AnimatedIconProps {
  spriteURL: string
}

interface AnimatedIconComponentProps {
  spriteURL: string
  previewSpriteURL?: string
  className?: string
  onAnimationEnd?: (e?: React.AnimationEvent) => void
}

/*
  COMPONENT
*/

export const AnimatedIcon = React.forwardRef<HTMLDivElement, AnimatedIconComponentProps>(
  ({ className, spriteURL, previewSpriteURL, onAnimationEnd }, ref) => {
    const forwardedRef = useForwardedRef(ref)

    React.useEffect(() => {
      if (!previewSpriteURL) return

      const previewImg = new Image()
      previewImg.onload = () => {
        const spriteImg = new Image()
        spriteImg.onload = () => {
          const sprite = forwardedRef.current
          if (sprite) {
            sprite.style.backgroundImage = `url(${spriteURL})`
          }
        }
        spriteImg.src = spriteURL
      }
      previewImg.src = previewSpriteURL
    }, [forwardedRef, previewSpriteURL, spriteURL])

    const url = previewSpriteURL || spriteURL
    return (
      <BaseAnimatedIcon
        ref={forwardedRef}
        className={className}
        spriteURL={url}
        onAnimationEnd={onAnimationEnd}
      />
    )
  }
)
