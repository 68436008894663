import { type ReactNode } from "react"
import {
  type ObjectEntities,
  ObjectKind,
  type TextTagOptions,
} from "@digits-graphql/frontend/graphql-bearer"
import urlHelper, { URL_REGEX } from "@digits-shared/helpers/urlHelper"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import {
  DATA_DECORATOR,
  DATA_ENTITY_KIND,
  DATA_ID,
  decoratedEntityToEntityKind,
  Decorator,
  DECORATOR_CLASS,
  EntityTagToken,
} from "src/shared/components/EditableContent/editableContentConstants"
import DOMSanitize from "src/shared/components/ObjectEntities/DOMSanitize"
import {
  convertToDigitsEntityTags,
  getObjectKindFromString,
} from "src/shared/components/ObjectEntities/entitiesParserShared"

/*
 INTERFACES
*/

interface TagsProps {
  text: string
  entities?: ObjectEntities | null
  formatChildNode?: (el: HTMLElement) => ReactNode | undefined
}

/*
 COMPONENTS
*/

/**
 * NodesToDOMParser converts a DocumentFragment into raw HTML string.
 * Key behaviors:
 * - Creates real DOM nodes first
 * - Returns HTML string directly without React element wrapper
 * - Bypasses React's virtual DOM completely
 */
export const NodesToDOMParser = ({ text, entities, formatChildNode }: TagsProps): string => {
  const entitiesText = convertToDigitsEntityTags(text)
  const fragment = DOMSanitize.sanitize(entitiesText)
  const container = document.createElement("div")

  Array.from(fragment.childNodes).forEach((node) => {
    const parsedNode = parseHtml(node, entities, formatChildNode)
    if (parsedNode) {
      container.appendChild(parsedNode)
    }
  })

  return container.innerHTML // Return string instead of React element
}

function parseHtml(
  node: ChildNode,
  entities?: ObjectEntities | null,
  formatChildNode?: (el: HTMLElement) => ReactNode | undefined
): Node | null {
  const element = node.cloneNode(true) as HTMLElement

  if (!element.tagName) {
    return createUrlTags(element.nodeValue || "")
  }

  if (element.tagName === "DIGITS-ENTITY") {
    const entityId = element.getAttribute(DATA_ID) || ""
    const entityName = element.getAttribute(DATA_DECORATOR) || ""
    const title = element.innerText || element.getAttribute("title") || ""

    return createEntityElement(entityId, entityName, title, entities)
  }

  // Handle child nodes
  if (element.childNodes?.length) {
    // Create array from childNodes before modifying
    const childNodes = Array.from(element.childNodes)
    // Clear existing children
    element.textContent = ""
    // Process and append each child
    childNodes.forEach((child) => {
      const parsedChild = parseHtml(child, entities, formatChildNode)
      if (parsedChild) {
        element.appendChild(parsedChild)
      }
    })
  }

  return element
}

function createUrlTags(text: string): Node {
  const container = document.createDocumentFragment()

  text.split(URL_REGEX).forEach((str) => {
    if (!str || str.search(URL_REGEX) === -1) {
      container.appendChild(document.createTextNode(str))
      return
    }

    if (!urlHelper.isValidHttpUrl(str)) {
      container.appendChild(document.createTextNode(str))
      return
    }

    const link = document.createElement("a")
    link.href = str
    link.target = "_blank"
    link.rel = "noreferrer"
    link.textContent = str
    container.appendChild(link)
  })

  return container
}

function createEntityElement(
  entityId: string,
  entityName: string,
  title: string,
  entities?: ObjectEntities | null
): Node {
  if (!entities) {
    const span = document.createElement("span")
    span.textContent = title || entityName
    return span
  }

  const { kind, options } = getObjectKindFromString(entityName)

  switch (kind) {
    case ObjectKind.User:
      return createUserEntity(entityId, options, title, entities)
    case ObjectKind.Category:
      return createCategoryEntity(entityId, options, title, entities)
    case ObjectKind.Party:
      return createPartyEntity(entityId, options, title, entities)
    case ObjectKind.Department:
      return createDepartmentEntity(entityId, options, title, entities)
    case ObjectKind.Location:
      return createLocationEntity(entityId, options, title, entities)
    default: {
      console.warn("Unsupported entity", entityName, entityId, title)
      const span = document.createElement("span")
      span.textContent = title || entityName
      return span
    }
  }
}

function createUserEntity(
  entityId: string,
  options: TextTagOptions | undefined,
  title: string,
  entities: ObjectEntities
): Node {
  const user = entities.users?.find((u) => u.id === entityId)
  if (!user) {
    return createFallbackTag(title, "User")
  }

  return createEditableTag(
    Decorator.User,
    entityId,
    options,
    user.givenName || user.familyName || user.emailAddress
  )
}

function createCategoryEntity(
  entityId: string,
  options: TextTagOptions | undefined,
  title: string,
  entities: ObjectEntities
): Node {
  const category = entities.categories?.find((c) => c.id === entityId)
  if (!category) {
    return createFallbackTag(title, "Category")
  }

  return createEditableTag(Decorator.Category, entityId, options, category.name)
}

function createPartyEntity(
  entityId: string,
  options: TextTagOptions | undefined,
  title: string,
  entities: ObjectEntities
): Node {
  const party = entities.parties?.find((p) => p.id === entityId)
  if (!party) {
    return createFallbackTag(title, "Party")
  }
  return createEditableTag(Decorator.Party, entityId, options, party.name)
}

function createDepartmentEntity(
  entityId: string,
  options: TextTagOptions | undefined,
  title: string,
  entities: ObjectEntities
): Node {
  const department = entities.departments?.find((d) => d.id === entityId)
  if (!department) {
    return createFallbackTag(title, "Department")
  }
  return createEditableTag(Decorator.Department, entityId, options, department.name)
}

function createLocationEntity(
  entityId: string,
  options: TextTagOptions | undefined,
  title: string,
  entities: ObjectEntities
): Node {
  const location = entities.locations?.find((l) => l.id === entityId)
  if (!location) {
    return createFallbackTag(title, "Location")
  }
  return createEditableTag(Decorator.Location, entityId, options, location.name)
}

function createEditableTag(
  decorator: Decorator,
  entityId: string,
  options: TextTagOptions | undefined,
  content: string
): HTMLElement {
  const span = document.createElement("span")
  const entityKind = decoratedEntityToEntityKind(options?.partyRole || decorator)

  span.className = `${DECORATOR_CLASS} ${decorator}`
  span.setAttribute(DATA_ID, entityId)
  span.setAttribute(DATA_DECORATOR, decorator)
  span.setAttribute(DATA_ENTITY_KIND, entityKind ?? "")
  span.textContent = `${EntityTagToken.Mention}${content}`

  return span
}

function createFallbackTag(title: string, fallbackText: string): Node {
  const span = document.createElement("b")
  span.style.color = colors.secondary
  span.style.fontWeight = fonts.weight.heavy.toString()
  span.style.marginLeft = "19px"
  span.textContent = title || fallbackText
  return span
}
