import {
  ObjectKind,
  SearchEntitiesField,
  type SearchEntitiesSort,
  useSearchEntitiesQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/useViewVersion"

export function useTypeaheadSearchQuery(query: string, kind: ObjectKind, skip = false) {
  const viewKey = useViewVersion()
  const text = query.trim()
  const hasSearchText = text.length > 0
  const sort = getSortForObjectKind(kind, hasSearchText)

  const response = useSearchEntitiesQuery({
    variables: {
      ...viewKey,
      text,
      kind,
      offset: 0,
      limit: 100,
      sort,
      sortHitsLeftmost: hasSearchText,
    },
    notifyOnNetworkStatusChange: true,
    skip,
  })

  const { networkStatus } = response
  const result = response.loading ? response.previousData : response.data

  return {
    result,
    networkStatus,
  }
}

const getSortForObjectKind = (kind: ObjectKind, hasSearchText: boolean): SearchEntitiesSort[] => {
  switch (kind) {
    case ObjectKind.Category:
      if (hasSearchText) {
        return [{ field: SearchEntitiesField.Score, ascending: false }]
      }

      return [
        { field: SearchEntitiesField.DisplayNumber, ascending: true },
        { field: SearchEntitiesField.Type, ascending: true },
        { field: SearchEntitiesField.Name, ascending: true },
      ]
    case ObjectKind.Party:
      return [{ field: SearchEntitiesField.Date, ascending: false }]
    default:
      return [{ field: SearchEntitiesField.Name, ascending: true }]
  }
}
