import * as React from "react"
import { PageScrollContext } from "@digits-shared/components/UI/Elements/PageScrollContext"

/**
 * Returns the Page's Scroll Context
 */
export default function useScrollContext() {
  const scrollContext = React.useContext(PageScrollContext)

  // Memoize so that a new object is only returned if something changes
  return React.useMemo(() => scrollContext, [scrollContext])
}
