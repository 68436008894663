import * as React from "react"
import { useOnboardingContext } from "@digits-shared/components/Onboarding/OnboardingContext"
import { type DigitsRoute } from "@digits-shared/components/Router/DigitsRoute"
import useRouter from "@digits-shared/hooks/useRouter"
import { jwtDecode } from "jwt-decode"
import routes from "src/frontend/routes"

export const EMAIL_VALIDATION_TOKEN_PARAM = "tok"

interface jwtEmail {
  email: string
}

export function useEmailValidationToken() {
  const { location } = useRouter()
  const { state } = useOnboardingContext()
  const { token } = state

  return React.useMemo(() => {
    const route: DigitsRoute | undefined = routes[location.name]

    const confirmToken =
      route?.currentQueryParams().get(EMAIL_VALIDATION_TOKEN_PARAM) || token || undefined

    let emailAddress: string | undefined
    if (confirmToken) {
      const parsed = jwtDecode<jwtEmail>(confirmToken)
      emailAddress = parsed?.email
    }
    return {
      confirmToken,
      emailAddress,
    }
  }, [location.name, token])
}
