import * as React from "react"
import { type DigitsRoute } from "@digits-shared/components/Router/DigitsRoute"
import useRouter from "@digits-shared/hooks/useRouter"
import useSession from "@digits-shared/hooks/useSession"
import { AspectCode } from "@digits-shared/session/SessionTypes"
import routes from "src/frontend/routes"
import type FrontendSession from "src/frontend/session"

type MobileRoutes = Map<string, DigitsRoute>

// Routes that have been made "responsive". Routes not in this list will block mobile devices
const MOBILE_ROUTES = [
  routes.dashboard,
  routes.root,
  routes.clientPortal,
  routes.actionItems,
  routes.actionItemsList,
  routes.clientTransactionReview,
  routes.internalFeatures,
  routes.assistant,
  routes.welcome,
  routes.onboardApplicationStatus,
  routes.getPaid,
  routes.collectFunds,
]

const mobileRoutesMap = MOBILE_ROUTES.reduce<MobileRoutes>((rmap, route) => {
  rmap.set(route.name, route)
  return rmap
}, new Map<string, DigitsRoute>())

export const useMobileRoutes = () =>
  React.useMemo(
    () => ({
      findMobileRoute(route: DigitsRoute) {
        return Array.from(mobileRoutesMap.values()).find((mobileRoute) =>
          mobileRoute.isRouteOrChildOfRoute(route)
        )
      },
      home: routes.clientTransactionReview,
    }),
    []
  )

export function useIsMobileLocation() {
  const { location } = useRouter()
  const hasTransactionReview = useSession<FrontendSession>().hasAccessToAspect(AspectCode.Audit)
  const mobileRoutes = useMobileRoutes()
  return React.useMemo(() => {
    // if (route === routes.assistant || route === routes.assistantRedirect) return true

    const route = routes[location.name]
    if (!route) return false

    if (!hasTransactionReview && mobileRoutes.home.isRouteOrChildOfRoute(route)) {
      return false
    }

    return !!mobileRoutes.findMobileRoute(route)
  }, [hasTransactionReview, location.name, mobileRoutes])
}
