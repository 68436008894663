import * as React from "react"
import ReactDOM from "react-dom"
import { useCreateAffiliationMutation } from "@digits-graphql/frontend/graphql-bearer"
import { Modal, ModalAnchor } from "@digits-shared/components/UI/Elements/Modals/Modal"
import { DigitsButton } from "@digits-shared/DesignSystem/Button"
import { useFormTracking } from "@digits-shared/hooks/useFormTracking"
import { useModalRoot } from "@digits-shared/hooks/useModalRoot"
import useSession from "@digits-shared/hooks/useSession"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import { AnimatePresence, m } from "framer-motion"
import styled, { css } from "styled-components"
import { FailureBanner } from "src/frontend/components/OS/Applications/ClientPortal/InviteFlow/FailureBanner"
import { type AddClientAction } from "src/frontend/components/OS/Header/AddClient/actions"
import {
  ClientDetailsField,
  ClientDetailsForm,
} from "src/frontend/components/OS/Header/AddClient/ClientDetailsForm"
import { Done } from "src/frontend/components/OS/Header/AddClient/Done"
import { DEFAULT_STATE, reducer } from "src/frontend/components/OS/Header/AddClient/reducer"
import {
  SendInviteField,
  SendInviteForm,
} from "src/frontend/components/OS/Header/AddClient/SendInviteForm"
import {
  MODAL_HEIGHT,
  MODAL_RADIUS,
  MODAL_WIDTH,
  transitionVariants,
} from "src/frontend/components/OS/Header/AddClient/shared"
import {
  AddClientModalState,
  type AddClientState,
} from "src/frontend/components/OS/Header/AddClient/types"
import type FrontendSession from "src/frontend/session"

const DEFAULT_MESSAGE =
  "We are thrilled to welcome you to the future of AI Accounting with Digits!\n\nThe full capabilities of Digits are ready to unlock. Get started by onboarding your financial systems on to Digits."

/*
  STYLES
*/

const OverlayStyles = css`
  backdrop-filter: unset;
`

const StyledModal = styled(Modal)`
  box-shadow: 0 0 30px ${colors.translucentBlack20};
  background: none;
  border: none;
  width: unset;
  height: unset;

  border-radius: ${MODAL_RADIUS}px;
  padding: 0;

  ${ModalAnchor} {
    height: 100%;
  }

  &::before {
    border: none;
  }
`

const ModalContent = styled.div`
  position: relative;
  width: ${MODAL_WIDTH}px;
  height: ${MODAL_HEIGHT}px;
`

const AboveModal = styled.div`
  position: absolute;
  bottom: 100%;
`

const ContentSwitcher = styled(m.div)`
  width: 100%;
  height: 100%;
`

const StableBackground = styled.div`
  position: absolute;
  left: 0;
  border-radius: ${MODAL_RADIUS}px;
  width: ${MODAL_WIDTH}px;
  height: ${MODAL_HEIGHT}px;
  border: none;
  background-color: ${colors.translucentWhite50};
`

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
`

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 32px 40px;

  /* Teal Purple Gradient */
  background: linear-gradient(
    84.38deg,
    rgba(44, 146, 190, 0.8) -4.07%,
    rgba(61, 58, 194, 0.54) 95.1%
  );
  border-bottom: 1px solid rgba(50, 73, 127, 0.2);
  border-radius: 16px 16px 0 0;
`

const Footer = styled.div`
  background: ${colors.white};
  display: flex;
  justify-content: end;
  align-items: flex-end;
  padding: 16px 16px;
  border-top: 1px solid rgba(50, 73, 127, 0.2);
  border-radius: 0 0 16px 16px;
`

const HeaderTitle = styled.div`
  font-size: 18px;
  font-weight: ${fonts.weight.heavy};
  color: ${colors.white};
`

const Body = styled.div`
  height: 450px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
`

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`

/*
  COMPONENTS
*/

export const AddClientModal: React.FC<{
  onClose: () => void
  onDone: () => void
}> = ({ onClose, onDone }) => {
  const { isAffiliatedSession } = useSession<FrontendSession>()
  const modalRoot = useModalRoot()
  const [state, dispatch] = React.useReducer(reducer, {
    ...DEFAULT_STATE,
  })

  if (!isAffiliatedSession) {
    return null
  }

  return ReactDOM.createPortal(
    <StyledModal
      overlayStyles={state.modalState === AddClientModalState.Done ? OverlayStyles : undefined}
    >
      <ModalContent>
        <AboveModal>
          <FailureBanner message={state.errorMessage} />
        </AboveModal>
        <Content dispatch={dispatch} state={state} onDone={onDone} onClose={onClose} />
      </ModalContent>
    </StyledModal>,
    modalRoot
  )
}

const Content: React.FC<{
  dispatch: React.Dispatch<AddClientAction>
  state: AddClientState
  onClose: () => void
  onDone: () => void
}> = ({ state, dispatch, onClose, onDone }) => (
  <StableBackground>
    <AnimatePresence mode="wait">
      <ContentSwitcher
        key={state.modalState}
        variants={transitionVariants}
        initial="exiting"
        animate="entering"
        exit="exiting"
      >
        <TransitionedContent state={state} dispatch={dispatch} onDone={onDone} onClose={onClose} />
      </ContentSwitcher>
    </AnimatePresence>
  </StableBackground>
)

const TransitionedContent: React.FC<{
  className?: string
  state: AddClientState
  dispatch: React.Dispatch<AddClientAction>
  onClose: () => void
  onDone: () => void
}> = ({ className, state, dispatch, onClose, onDone }) => {
  switch (state.modalState) {
    case AddClientModalState.ClientDetails:
      return (
        <AddClientDetails
          className={className}
          dispatch={dispatch}
          state={state}
          onClose={onClose}
        />
      )
    case AddClientModalState.SendInvite:
      return <SendInvite className={className} dispatch={dispatch} state={state} />

    case AddClientModalState.Done:
      return <Done dispatch={dispatch} state={state} onDone={onDone} />
  }
}

const AddClientDetails: React.FC<{
  className?: string
  dispatch: React.Dispatch<AddClientAction>
  state: AddClientState
  onClose: () => void
}> = ({ className, dispatch, state, onClose }) => {
  const disabled = !state.addClientDetailsFormValid

  const { clientDetails } = state

  const formTracking = useFormTracking<ClientDetailsField>({
    initialValues: {
      [ClientDetailsField.companyName]: clientDetails?.name ?? "",
      [ClientDetailsField.websiteUrl]: clientDetails?.websiteUrl ?? "",
      [ClientDetailsField.givenName]: clientDetails?.givenName ?? "",
      [ClientDetailsField.familyName]: clientDetails?.familyName ?? "",
      [ClientDetailsField.emailAddress]: clientDetails?.emailAddress ?? "",
    },
    optionalFields: [ClientDetailsField.websiteUrl],
  })

  const onNext = React.useCallback(() => {
    dispatch({
      type: "setClientDetails",
      details: {
        name: formTracking.getFieldValue(ClientDetailsField.companyName) ?? "",
        websiteUrl: formTracking.getFieldValue(ClientDetailsField.websiteUrl),
        givenName: formTracking.getFieldValue(ClientDetailsField.givenName) ?? "",
        familyName: formTracking.getFieldValue(ClientDetailsField.familyName) ?? "",
        emailAddress: formTracking.getFieldValue(ClientDetailsField.emailAddress) ?? "",
      },
    })
    dispatch({ type: "setModalState", modalState: AddClientModalState.SendInvite })
  }, [dispatch, formTracking])

  return (
    <Wrapper className={className}>
      <Header>
        <HeaderTitle>Add Client Details</HeaderTitle>
      </Header>
      <Body>
        <ClientDetailsForm state={state} dispatch={dispatch} formTracking={formTracking} />
      </Body>
      <Footer>
        <ButtonRow>
          <DigitsButton $variant="secondary-dark" onClick={onClose}>
            Cancel
          </DigitsButton>
          <DigitsButton $variant="primary" disabled={disabled} onClick={onNext}>
            Next
          </DigitsButton>
        </ButtonRow>
      </Footer>
    </Wrapper>
  )
}

const SendInvite: React.FC<{
  className?: string
  dispatch: React.Dispatch<AddClientAction>
  state: AddClientState
}> = ({ className, dispatch, state }) => {
  const { iconBase64, clientDetails } = state
  const { currentOrganizationId } = useSession<FrontendSession>()
  const [createAffiliation] = useCreateAffiliationMutation({
    refetchQueries: ["ReadOrganizationBillingPlan"],
    awaitRefetchQueries: false,
  })

  const formTracking = useFormTracking<SendInviteField>({
    initialValues: {
      [SendInviteField.message]: DEFAULT_MESSAGE,
    },
  })

  const onBack = React.useCallback(() => {
    dispatch({ type: "setModalState", modalState: AddClientModalState.ClientDetails })
  }, [dispatch])

  const onSend = React.useCallback(() => {
    dispatch({ type: "setSending", value: true })
    if (!currentOrganizationId || !clientDetails) {
      return
    }

    createAffiliation({
      variables: {
        organizationId: currentOrganizationId,
        message: formTracking.getFieldValue(SendInviteField.message),
        iconBase64,
        ...clientDetails,
      },
    })
      .then(() => {
        dispatch({ type: "setModalState", modalState: AddClientModalState.Done })
      })
      .catch((error) => {
        dispatch({ type: "setErrorMessage", message: error.message })
      })
      .finally(() => {
        dispatch({ type: "setSending", value: false })
      })
  }, [clientDetails, createAffiliation, currentOrganizationId, dispatch, formTracking, iconBase64])

  const disabled = !state.addClientDetailsFormValid || state.sending
  const buttonText = state.sending ? "Sending..." : "Send"

  return (
    <Wrapper className={className}>
      <Header>
        <HeaderTitle>Send Invite</HeaderTitle>
      </Header>
      <Body>
        <SendInviteForm state={state} dispatch={dispatch} formTracking={formTracking} />
      </Body>
      <Footer>
        <ButtonRow>
          <DigitsButton $variant="secondary-dark" onClick={onBack}>
            Back
          </DigitsButton>
          <DigitsButton $variant="primary" disabled={disabled} onClick={onSend}>
            {buttonText}
          </DigitsButton>
        </ButtonRow>
      </Footer>
    </Wrapper>
  )
}
