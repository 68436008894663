import * as React from "react"
import { createRoot } from "react-dom/client"
import {
  GraphQLTracerContext,
  useBuildGraphQLTracer,
} from "@digits-shared/components/Contexts/GraphQLTracerContext"
import ErrorBoundary from "@digits-shared/components/Error/ErrorBoundary"
import { enableMapSet } from "immer"
import useSetupApolloClient from "src/shared/initializers/apollo/useSetupApolloClient"
import { Main, session } from "src/frontend/Main"
import { GraphQLTraceDebugger } from "src/shared/components/Support/GraphQLTraceDebugger"
import "digits-shared/static/css/fonts.css"

enableMapSet()

const Entry: React.FC = () => {
  const graphQLTracer = useBuildGraphQLTracer(session)

  const client = useSetupApolloClient(session, {
    withWS: true,
    onTrace: graphQLTracer.addTrace,
  })

  return (
    <GraphQLTracerContext.Provider value={graphQLTracer}>
      {/* Place error boundary around all route to prevent */}
      {/* entire tree from breaking when child throw */}
      {/* https://reactjs.org/docs/error-boundaries.html */}
      <ErrorBoundary session={session}>
        <Main apolloClient={client} />
        <GraphQLTraceDebugger session={session} />
      </ErrorBoundary>
    </GraphQLTracerContext.Provider>
  )
}

const container = document.getElementById("main")
const root = createRoot(container || document.createDocumentFragment())

root.render(<Entry />)
