import * as React from "react"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"

const IconWrapper = styled.div<{ iconSize?: string }>`
  display: contents;

  & > svg {
    ${svgPathStyles(colors.white, 1.5)};
    flex-shrink: 0;

    width: ${({ iconSize }) => iconSize || "24px"};
    height: ${({ iconSize }) => iconSize || "24px"};
  }
`

export const DrawerItemIcon: React.FC<{
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element
  iconSize?: string
}> = ({ icon, iconSize }) => <IconWrapper iconSize={iconSize}>{icon({})}</IconWrapper>
