import * as React from "react"
import borders from "@digits-shared/themes/borders"
import colors from "@digits-shared/themes/colors"
import { useDroppable } from "@dnd-kit/core"
import styled, { css } from "styled-components"
import { DropType, TargetKind } from "src/frontend/components/Shared/DragAndDrop/dragAndDropShared"
import {
  COMPONENT_GUTTER_ID_PREFIX,
  findComponentsIn,
  findPositionIn,
  ROW_GUTTER_ID_PREFIX,
} from "src/frontend/components/Shared/Layout/Shared"
import { useDropInfo } from "src/frontend/components/Shared/Portals/DragAndDrop/useDropInfo"
import { useUpdateComponentDragOverlay } from "src/frontend/components/Shared/Portals/DragAndDrop/useUpdateComponentDragOverlay"
import { usePortalStore } from "src/frontend/components/Shared/Portals/State/portalStore"
import { activeComponentSelector } from "src/frontend/components/Shared/Portals/State/selectors"
import { useDragAndDropDisabled } from "src/frontend/components/Shared/Portals/State/useDragAndDropDisabled"

export enum GutterOrientation {
  Horizontal = "Horizontal",
  Vertical = "Vertical",
}

/*
 STYLES
*/

const GutterIndicator = styled.div<{ show: boolean; $orientation: GutterOrientation }>`
  ${({ $orientation }) =>
    $orientation === GutterOrientation.Horizontal
      ? css`
          height: 4px;
        `
      : css`
          width: 2px;
          height: 100%;
        `}

  border-radius: ${borders.radius.default}px;
  ${({ show }) =>
    show &&
    css`
      background: ${colors.translucentPrimary50};
    `}
`

export const Gutter = styled.div<{ $orientation: GutterOrientation }>`
  ${({ $orientation }) =>
    $orientation === GutterOrientation.Horizontal
      ? css`
          padding: 10px 22px;
        `
      : css`
          padding: 0 10px;

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
          }
        `}
`

/*
 COMPONENTS
*/

export const RowGutter: React.FC<{ sectionId: string; index: number }> = ({ sectionId, index }) => {
  const disabled = useDragAndDropDisabled()
  const activeComponent = usePortalStore(activeComponentSelector)
  const layout = usePortalStore((state) => state.layout)
  const { rowIndex, rowId } = findPositionIn(layout, activeComponent?.componentId)
  const componentsInRow = findComponentsIn(layout, sectionId, rowId ?? "")
  const sameSpot = componentsInRow.length === 1 && (rowIndex === index || rowIndex + 1 === index)

  const { setNodeRef: droppableRef, isOver } = useDroppable({
    id: `${ROW_GUTTER_ID_PREFIX}[s${sectionId}][i${index}]`,
    data: {
      index,
      sectionId,
      targetKind: TargetKind.RowGutter,
      type: DropType.Component,
      dropAllowed: true,
    },
    disabled,
  })

  const orientation = GutterOrientation.Horizontal
  const show = !sameSpot && isOver

  return (
    <Gutter ref={droppableRef} data-gutter-orientation={orientation} $orientation={orientation}>
      <GutterIndicator show={show} $orientation={orientation} />
    </Gutter>
  )
}

export const ComponentGutter: React.FC<{ sectionId: string; rowId: string; index: number }> = ({
  sectionId,
  rowId,
  index,
}) => {
  const disabled = useDragAndDropDisabled()

  const { activeComponentIndex, changingRows, dropAllowed } = useDropInfo({ sectionId, rowId })
  const sameSpot =
    !changingRows && (activeComponentIndex === index || activeComponentIndex + 1 === index)

  const { setNodeRef: droppableRef, isOver } = useDroppable({
    id: `${COMPONENT_GUTTER_ID_PREFIX}[s${sectionId}][r${rowId}][i${index}]`,
    data: {
      index,
      sectionId,
      rowId,
      targetKind: TargetKind.ComponentGutter,
      type: DropType.Component,
      dropAllowed,
    },
    disabled,
  })

  useUpdateComponentDragOverlay(sectionId, rowId, isOver)

  const orientation = GutterOrientation.Vertical
  const show = !sameSpot && dropAllowed && isOver

  return (
    <Gutter ref={droppableRef} data-gutter-orientation={orientation} $orientation={orientation}>
      <GutterIndicator show={show} $orientation={orientation} />
    </Gutter>
  )
}
