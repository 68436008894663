import React from "react"
import { isNetworkRequestInFlight } from "@apollo/client/core/networkStatus"
import {
  ObjectKind,
  SearchEntitiesField,
  type SearchEntitiesQuery,
  useSearchGlobalPartiesQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import { defined, uniqueBy } from "@digits-shared/helpers/filters"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/useViewVersion"
import {
  type NewPartyEntity,
  type PartyTypeaheadResult,
  type PartyWithNewEntitiesTypeaheadResult,
} from "src/shared/components/Typeahead/sharedTypeahead"
import { useTypeaheadSearchQuery } from "src/shared/components/Typeahead/useTypeaheadSearchQuery"

export function useTypeaheadParties(text: string, kind: ObjectKind, newParties: NewPartyEntity[]) {
  const { result: partyQueryResult, networkStatus: partyNetworkStatus } = useTypeaheadSearchQuery(
    text,
    ObjectKind.Party
  )

  const { result: globalPartyQueryResult, networkStatus: globalPartyNetworkStatus } =
    useTypeaheadGlobalPartySearchQuery(text)

  return React.useMemo(() => {
    const loading =
      isNetworkRequestInFlight(partyNetworkStatus) ||
      isNetworkRequestInFlight(globalPartyNetworkStatus)

    return {
      loading,
      results: buildPartyResults(partyQueryResult, globalPartyQueryResult, newParties),
    }
  }, [
    partyNetworkStatus,
    globalPartyNetworkStatus,
    partyQueryResult,
    globalPartyQueryResult,
    newParties,
  ])
}

function useTypeaheadGlobalPartySearchQuery(query: string) {
  const { viewType } = useViewVersion()
  const text = query.trim()
  const hasSearchText = text.length > 0

  const response = useSearchGlobalPartiesQuery({
    variables: {
      viewType,
      text,
      offset: 0,
      limit: 5,
      sort: [{ field: SearchEntitiesField.Name, ascending: true }],
      sortHitsLeftmost: true,
    },
    notifyOnNetworkStatusChange: true,
    skip: !hasSearchText,
  })

  const { networkStatus } = response
  const result = response.loading ? response.previousData : response.data

  return {
    result,
    networkStatus,
  }
}

type Party = NonNullable<SearchEntitiesQuery["search"]["entities"]["parties"]>[number]

function extractPartiesFromQuery(queryResult: SearchEntitiesQuery | undefined): Party[] {
  if (!queryResult?.search) return []

  const { results, entities } = queryResult.search
  return results
    .map((result) => entities.parties?.find((party) => party.id === result.objectId.id))
    .filter(defined)
}

function partyToTypeaheadResult(party: Party, isGlobal = false): PartyTypeaheadResult {
  return {
    id: party.id,
    title: party.name,
    subtitle: party.shortDescription,
    searchValues: [party.name, party.shortDescription].filter(defined),
    entity: party,
    kind: ObjectKind.Party,
    ...(isGlobal && { isNew: true }),
  }
}

function newPartyToTypeaheadResult(entity: NewPartyEntity): PartyWithNewEntitiesTypeaheadResult {
  return {
    id: entity.id || "",
    title: entity.name,
    subtitle: undefined,
    searchValues: [entity.name],
    kind: ObjectKind.Party,
    entity,
  }
}

function buildPartyResults(
  partyQueryResult: SearchEntitiesQuery | undefined,
  globalPartyQueryResult: SearchEntitiesQuery | undefined,
  newParties: NewPartyEntity[]
) {
  const localParties = extractPartiesFromQuery(partyQueryResult)
  const globalParties = extractPartiesFromQuery(globalPartyQueryResult)

  const results = [
    ...localParties.map((party) => partyToTypeaheadResult(party)),
    ...globalParties.map((party) => partyToTypeaheadResult(party, true)),
    ...newParties.map(newPartyToTypeaheadResult),
  ]

  return results
    .filter(uniqueBy((p) => p.id))
    .filter((p) => p.title !== "")
    .sort((a, b) => a.title.localeCompare(b.title))
}
