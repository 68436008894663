import * as React from "react"
import { FileType } from "@digits-shared/helpers/fileHelper"
import styled from "styled-components"
import { SvgFileCsv } from "src/shared/components/SVG/FileCsv.svg"
import { SvgFileDoc } from "src/shared/components/SVG/FileDoc.svg"
import { SvgFileDocx } from "src/shared/components/SVG/FileDocx.svg"
import { SvgFileGeneric } from "src/shared/components/SVG/FileGeneric.svg"
import { SvgFileHeic } from "src/shared/components/SVG/FileHeic.svg"
import { SvgFileJpg } from "src/shared/components/SVG/FileJpg.svg"
import { SvgFilePdf } from "src/shared/components/SVG/FilePdf.svg"
import { SvgFilePng } from "src/shared/components/SVG/FilePng.svg"
import { SvgFileTxt } from "src/shared/components/SVG/FileTxt.svg"
import { SvgFileXls } from "src/shared/components/SVG/FileXls.svg"
import { SvgFileZip } from "src/shared/components/SVG/FileZip.svg"

export const FileIconStyled = styled.img`
  width: 44px;
  height: 48px;
`

export const FileIcon: React.FC<{ fileType: FileType | string; className?: string }> = ({
  fileType,
  className,
}) => {
  switch (fileType) {
    case FileType.PDF:
      return (
        <FileIconStyled
          className={className}
          src={require("static/images/shared/file-icons/pdf-light.png")}
        />
      )
    case FileType.XLS:
      return (
        <FileIconStyled
          className={className}
          src={require("static/images/shared/file-icons/xls-light.png")}
        />
      )
    case FileType.XLSX:
      return (
        <FileIconStyled
          className={className}
          src={require("static/images/shared/file-icons/xlsx-light.png")}
        />
      )
    case FileType.CSV:
      return (
        <FileIconStyled
          className={className}
          src={require("static/images/shared/file-icons/csv-light.png")}
        />
      )
    default:
      return (
        <FileIconStyled
          className={className}
          src={require("static/images/shared/file-icons/attachment-light.png")}
        />
      )
  }
}

export const FileSvgIcon: React.FC<{ fileType: FileType | string; className?: string }> = ({
  fileType,
  className,
}) => {
  switch (fileType) {
    case FileType.PDF:
      return <SvgFilePdf className={className} />

    case FileType.CSV:
      return <SvgFileCsv className={className} />

    case FileType.TEXT:
      return <SvgFileTxt className={className} />

    case FileType.DOC:
      return <SvgFileDoc className={className} />

    case FileType.DOCX:
      return <SvgFileDocx className={className} />

    case FileType.XLS:
    case FileType.XLSX:
      return <SvgFileXls className={className} />

    case FileType.PNG:
      return <SvgFilePng className={className} />

    case FileType.JPEG:
      return <SvgFileJpg className={className} />

    case FileType.HEIC:
      return <SvgFileHeic className={className} />

    case FileType.ZIP:
      return <SvgFileZip className={className} />

    default:
      return <SvgFileGeneric className={className} />
  }
}
