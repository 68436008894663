import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import { DimensionDisplayOptionGroup } from "src/frontend/components/OS/Shared/DisplayOptions/DimensionDisplayOptionGroup"
import { PeriodHeaderAnnotation } from "src/frontend/components/OS/Shared/PeriodGrouping/PeriodHeader"
import { WideWidgetContainer } from "src/frontend/components/OS/Shared/Widgets/WidgetContainer"
import { WideWidgetTitle } from "src/frontend/components/OS/Shared/Widgets/WidgetTitle"

export const DimensionWidgetContainer = styled(WideWidgetContainer)`
  padding: 0 0 32px 0;
`

export const DimensionWidgetHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px 10px 25px;
  border-bottom: 0.5px solid ${colors.white};
`

export const DimensionWideWidgetTitle = styled(WideWidgetTitle)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  font-size: 15px;
`

export const DimensionWidgetDisplayOptionGroup = styled(DimensionDisplayOptionGroup)``

export const DimensionWidgetCollection = styled.div``

export const DimensionWidgetEmptyView = styled(PeriodHeaderAnnotation)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  font-size: 14px;
  text-transform: none;
`
