import { type DigitsLocation } from "@digits-shared/components/Router/DigitsLocation"
import type History from "history"

export interface DigitsHistory extends History.History {
  location: DigitsLocation
  previousLocation?: DigitsLocation
}

export enum HistoryAction {
  Push = "Push",
  Replace = "Replace",
  None = "None",
}

export enum HistoryType {
  Memory = "Memory",
  Browser = "Browser",
}
