import { themedValue } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"

export const STATEMENT_ROWS_CLASSNAME = "statement-rows"

export const STATEMENT_HOVERED_COLUMN_CLASSNAME = "column-hovered"

export const STATEMENT_COLUMN_WIDTH = 70

export const STATEMENT_WIDE_CONTAINER_SIZE = 600

export const INDENT_SIZE = 10

export enum StatementSize {
  Condensed = "condensed",
  Full = "full",
}

export interface RowProps {
  depth: number
}

const rowColor = themedValue({
  print: colors.black,
  light: colors.secondary,
  dark: colors.translucentWhite70,
})

const hoverColor = themedValue({
  print: colors.black,
  light: colors.primary,
  dark: colors.primary,
})

export const StickyCell = styled.div`
  @container (min-width: 700px) {
    & {
      position: sticky;
      left: 0;
      z-index: 2;
    }
  }
`

export const Title = styled(StickyCell)<RowProps & { disabled?: boolean }>`
  padding-left: ${({ depth }) => 20 + (depth || 0) * INDENT_SIZE}px;
  padding-right: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;

  &[disabled] {
    pointer-events: none;
  }
  &:not([disabled]) a:hover {
    color: ${hoverColor};
  }
`

export const RowStyled = styled.div<RowProps>`
  display: grid;
  grid-template-columns: var(--statement-grid-template);

  border-radius: 3px;
  color: ${rowColor};
  padding: 2px 3px 4px 0;

  .details-popover:hover &,
  .popover-active & {
    background: ${colors.secondary10};
  }
`

export const TotalCell = styled.div`
  text-align: right;
  white-space: nowrap;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  font-size: 12px;
  line-height: 14px;
  padding: 0 7px;

  border: 1px solid transparent;
  border-radius: 4px;

  &:not(.pinned):hover {
    border-color: ${colors.secondary50};
    background: ${colors.translucentSecondary05};
  }

  &.pinned {
    border-color: ${colors.secondary};
    background: ${colors.translucentSecondary10};
  }
`

export const StatementTrendLineCell = styled.div`
  justify-self: flex-end;
`

export const SectionSummaryTitle = styled(StickyCell)`
  padding-left: 20px;
  flex: 1;
  text-transform: uppercase;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
