import * as React from "react"
import useConstant from "@digits-shared/hooks/useConstant"

interface ContextPathProps {
  contextPath: string
}

const ContextPathContext = React.createContext<ContextPathProps>({ contextPath: "" })

export const ContextPath: React.FC<React.PropsWithChildren<{ contextId: string }>> = ({
  contextId,
  children,
}) => {
  const contextPath = useContextPath(contextId)
  const context = useConstant(() => ({
    contextPath,
  }))

  return <ContextPathContext.Provider value={context}>{children}</ContextPathContext.Provider>
}

export function useContextPath(contextId?: string) {
  const { contextPath } = React.useContext(ContextPathContext)

  return React.useMemo(() => {
    const fullPath = contextPath ? contextPath.split("/") : []
    if (contextId && fullPath[fullPath.length - 1] !== contextId) {
      fullPath.push(contextId)
    }
    return fullPath.join("/")
  }, [contextPath, contextId])
}
