import * as React from "react"
import { useEvent } from "react-use"
import { Keys } from "@digits-shared/components/UI/Elements/Keys"
import { PickerPosition } from "@digits-shared/components/UI/Picker/constants"
import { ZERO_STATE_PICKER_KEYBOARD_INDEX } from "@digits-shared/components/UI/Picker/Picker"

enum KeyboardNavigation {
  Increment = "Increment",
  Decrement = "Decrement",
  Reset = "Reset",
}

interface PickerKeyboardNavigationProps<E> {
  pickerPosition: PickerPosition
  elements: E[]
  isLoading: boolean
  onEnter: (elements: E) => void
  maxElements?: number
  autoSelect?: boolean
}

export function usePickerKeyboardNavigations<E>({
  pickerPosition,
  elements,
  isLoading,
  onEnter,
  maxElements,
  autoSelect,
}: PickerKeyboardNavigationProps<E>) {
  const [keyboardNavIndex, setKeyboardNavIndex] = React.useReducer<
    React.Reducer<number, KeyboardNavigation>
  >(
    (currentIndex, navigation) => {
      let newIndex = currentIndex
      switch (navigation) {
        case KeyboardNavigation.Increment:
          newIndex += 1
          break
        case KeyboardNavigation.Decrement:
          newIndex -= 1
          break
        case KeyboardNavigation.Reset:
          return ZERO_STATE_PICKER_KEYBOARD_INDEX
      }

      const maxAvailableIndex = Math.min(newIndex, (maxElements || elements.length) - 1)
      return Math.max(maxAvailableIndex, 0)
    },
    autoSelect ? 0 : ZERO_STATE_PICKER_KEYBOARD_INDEX
  )

  const clearIndex = React.useCallback(() => {
    setKeyboardNavIndex(KeyboardNavigation.Reset)
  }, [])

  const handleKeyDown = React.useCallback(
    (event: KeyboardEvent) => {
      if (isLoading) return

      switch (event.key) {
        case Keys.ArrowDown:
          return setKeyboardNavIndex(
            pickerPosition === PickerPosition.BelowTarget
              ? KeyboardNavigation.Increment
              : KeyboardNavigation.Decrement
          )
        case Keys.ArrowUp:
          return setKeyboardNavIndex(
            pickerPosition === PickerPosition.BelowTarget
              ? KeyboardNavigation.Decrement
              : KeyboardNavigation.Increment
          )
        case Keys.Enter: {
          const element = elements[keyboardNavIndex]

          if (element) {
            event.stopPropagation()
            onEnter(element)
          }
        }
      }
    },
    [pickerPosition, keyboardNavIndex, onEnter, elements, isLoading]
  )

  useEvent("keydown", handleKeyDown, document.body, { capture: true })
  useEvent("mousemove", clearIndex, document.body, { capture: true })

  return keyboardNavIndex
}
