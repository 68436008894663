import * as React from "react"
import { type PropsWithChildren } from "react"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import * as TooltipPrimitive from "@radix-ui/react-tooltip"
import styled, { css, keyframes } from "styled-components"

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

export const TOOLTIP_STYLES = css`
  border-radius: 4px;
  ${fonts.scale.detail};
  color: ${colors.white};
  background-color: ${colors.black};
  filter: drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.1));
  user-select: none;
  will-change: transform, opacity;
  z-index: 30;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 4px 8px;
  max-width: 160px;
`

const TooltipContent = styled(TooltipPrimitive.Content)<{ $maxWidth: number }>`
  ${TOOLTIP_STYLES};
  max-width: ${({ $maxWidth }) => $maxWidth}px;
  animation-duration: 500ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);

  &[data-state="delayed-open"] {
    animation-name: ${fadeIn};
  }
`

const TriggerContainer = styled.div`
  width: fit-content;
`

interface DigitsTooltipProps {
  content: React.ReactNode
  open?: boolean
  defaultOpen?: boolean
  onOpenChange?: (open: boolean) => void
  side?: "top" | "bottom" | "left" | "right"
  align?: "start" | "center" | "end"
  delayDuration?: number
  className?: string
  disabled?: boolean
  maxWidth?: number
  openOnFocus?: boolean
}

export const DigitsTooltip: React.FC<PropsWithChildren<DigitsTooltipProps>> = ({
  children,
  className,
  content,
  onOpenChange,
  open,
  defaultOpen,
  align = "center",
  side = "bottom",
  delayDuration = 500,
  disabled = false,
  maxWidth = 160,
  openOnFocus,
}) => {
  // By default, prevent tooltips from showing when an element is focused (i.e. only show on hover)
  // Note: we acknowledge that this has accessibility implications
  const onFocus = React.useCallback(
    (e: React.FocusEvent) => {
      if (!openOnFocus) {
        e.preventDefault()
      }
    },
    [openOnFocus]
  )

  if (disabled) return children

  return (
    <TooltipPrimitive.Provider>
      <TooltipPrimitive.Root
        open={open}
        defaultOpen={defaultOpen}
        onOpenChange={onOpenChange}
        delayDuration={delayDuration}
      >
        <TooltipPrimitive.Trigger asChild onFocus={onFocus}>
          <TriggerContainer className={className} onFocus={onFocus}>
            {children}
          </TriggerContainer>
        </TooltipPrimitive.Trigger>
        <TooltipPrimitive.Portal>
          <TooltipContent side={side} sideOffset={4} align={align} $maxWidth={maxWidth}>
            {content}
          </TooltipContent>
        </TooltipPrimitive.Portal>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  )
}
