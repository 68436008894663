import * as React from "react"
import { Link } from "react-router-dom"
import {
  type EntityCategory,
  type EntityParty,
  PartyRole,
  type TransactionSummary,
} from "@digits-graphql/frontend/graphql-bearer"
import { useInvertValues } from "@digits-shared/components/Contexts/InvertValuesContext"
import { SvgFile02 } from "@digits-shared/components/SVGIcons/line/File02.svg"
import { IconSize } from "@digits-shared/components/UI/Icons/Icon"
import { RowContentDescription, RowContentTitle } from "@digits-shared/components/UI/Table/Content"
import numberHelper, { CurrencyStyle } from "@digits-shared/helpers/numberHelper"
import useSession from "@digits-shared/hooks/useSession"
import { themedValue } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import { themedSVGPathStyles } from "@digits-shared/themes/svgIconStyles"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"
import { useProductArea } from "src/frontend/components/Shared/Contexts/ProductAreaContext"
import { PartyItemIcon } from "src/frontend/components/Shared/Reports/Report/Components/Parties/PartyItemIcon"
import {
  Count,
  NeonAmount,
} from "src/frontend/components/Shared/Reports/Report/Elements/ReportElements"
import { useReportComponentIntervalOrigin } from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/hooks/useReportComponentIntervalOrigin"
import WithComments from "src/frontend/components/Shared/Reports/ReportComments/WithComments"
import routes from "src/frontend/routes"
import type FrontendSession from "src/frontend/session"
import { FrontendPartyRole } from "src/frontend/types/FrontendPartyRole"

/*
  STYLE
*/

const DEFAULT_COLUMNS = "minmax(0, 1fr) minmax(50px, 90px)"
const COUNT_EMPHASIZED_COLUMNS = "minmax(0, 1fr) minmax(20px, 45px) minmax(50px, 90px)"

const gridCSS = (columns: string) => css`
  display: grid;
  grid-template-columns: ${columns};
  grid-column-gap: 5px;
  align-items: center;
  padding: 7px 0;

  width: 100%;
  font-weight: ${fonts.weight.normal};
  font-size: 13px;
`

const Row = styled(Link)<{ columns: string; disabled: boolean }>`
  ${(props) => gridCSS(props.columns)};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "")};
  text-decoration: none !important;

  border-style: solid;
  border-color: transparent transparent ${colors.translucentPrimary10};
  border-width: 1px 0 1px 0;
  &:last-of-type {
    border-bottom-color: transparent;
  }

  &:hover {
    &:first-of-type {
      border-top-color: ${colors.translucentPrimary10};
    }

    &:last-of-type {
      border-bottom-color: ${colors.translucentPrimary10};
    }
    background: ${colors.translucentSecondary05};
  }
`

const Details = styled.div`
  display: flex;
`

const partyNameColor = themedValue({
  light: colors.black,
  dark: colors.white,
})

const DetailsContainer = styled.div`
  overflow: hidden;
`

const PartyName = styled(RowContentTitle)`
  color: ${partyNameColor};
`

const CategoryName = styled(RowContentDescription)`
  color: ${colors.gray};
`

const journalEntryStyles = themedSVGPathStyles(
  {
    light: colors.regentGray,
    dark: colors.translucentWhite50,
  },
  1.5
)

const JournalEntryIcon = styled(SvgFile02)<{ size?: number }>`
  ${journalEntryStyles};
  min-width: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  margin-right: 13px;
  margin-left: 2px;
`

/*
 COMPONENT
*/

interface PartySummary {
  party: EntityParty
  summary: TransactionSummary
}

export const TopPartiesList: React.FC<{
  partySummaries: PartySummary[]
  category: EntityCategory
  className?: string
}> = ({ partySummaries, category, className }) => (
  <div className={className}>
    <PartySummariesList partySummaries={partySummaries} category={category} />
  </div>
)

export const PartySummariesList: React.FC<{
  partySummaries: PartySummary[]
  category: EntityCategory
  className?: string
  emphasizeCount?: boolean
}> = ({ partySummaries, category, className, emphasizeCount }) => (
  <div className={className}>
    {partySummaries.map((s, index) => {
      const { party } = s
      if (party?.id) {
        return (
          <PartyListItem
            key={party.id}
            partySummary={s}
            category={category}
            emphasizeCount={emphasizeCount}
          />
        )
      }

      return (
        <JournalEntryItem
          key={`journal_${index}`}
          partySummary={s}
          category={category}
          emphasizeCount={emphasizeCount}
        />
      )
    })}
  </div>
)

const PartyListItem: React.FC<{
  partySummary: PartySummary
  category: EntityCategory
  emphasizeCount?: boolean
}> = ({ partySummary, category, emphasizeCount }) => {
  const { isSharingContextActive } = useSession<FrontendSession>()
  const { interval, year, index } = useReportComponentIntervalOrigin()
  const { summary, party } = partySummary
  const productArea = useProductArea()

  const partyRole =
    party.roles?.find((r) => r !== PartyRole.UnknownRole) ||
    FrontendPartyRole.rolesForProductArea(productArea)[0]

  const partyPath = partyRole
    ? routes.partyDetails.generateFromCurrentPath({
        partyId: party.id,
        partyRole: FrontendPartyRole.roleURLKey(partyRole),
        interval,
        year,
        index,
      })
    : undefined

  const columns = emphasizeCount ? COUNT_EMPHASIZED_COLUMNS : DEFAULT_COLUMNS

  return (
    <WithComments contextId={party.id} disabled>
      <Row
        to={partyPath || ""}
        disabled={isSharingContextActive || !partyPath}
        columns={columns}
        data-context-id={party.id}
      >
        <Details>
          <PartyItemIcon css="margin-right: 15px;" party={party} />
          <DetailsContainer>
            <PartyName>{party.name}</PartyName>
            <CategoryName>{category.name}</CategoryName>
          </DetailsContainer>
        </Details>
        {emphasizeCount && <div>{summary.total.transactionsCount}x</div>}
        <SummaryAmount summary={summary} emphasizeCount={emphasizeCount} />
      </Row>
    </WithComments>
  )
}

const JournalEntryItem: React.FC<{
  partySummary: PartySummary
  category: EntityCategory
  emphasizeCount?: boolean
}> = ({ partySummary, category, emphasizeCount }) => {
  const { summary } = partySummary

  const columns = emphasizeCount ? COUNT_EMPHASIZED_COLUMNS : DEFAULT_COLUMNS
  const { transactionsCount } = summary.total
  const name = transactionsCount === 1 ? "Journal Entry" : "Journal Entries"

  return (
    <Row to="" columns={columns} disabled>
      <Details>
        <JournalEntryIcon size={IconSize.Medium.pixels} />
        <DetailsContainer>
          <PartyName>{name}</PartyName>
          <CategoryName>{category.name}</CategoryName>
        </DetailsContainer>
      </Details>
      {emphasizeCount && <div>{transactionsCount}x</div>}
      <SummaryAmount summary={summary} emphasizeCount={emphasizeCount} />
    </Row>
  )
}

const SummaryAmount: React.FC<{ summary: TransactionSummary; emphasizeCount?: boolean }> = ({
  summary,
  emphasizeCount,
}) => {
  const { value, transactionsCount } = summary.total
  const invertValues = useInvertValues()
  const amountWithCurrency = numberHelper.currency(value, {
    style: CurrencyStyle.Detail,
    invertValues,
  })

  return (
    <div css="text-align: right">
      <NeonAmount>{amountWithCurrency}</NeonAmount>
      {!emphasizeCount && (
        <Count>
          {transactionsCount} Transaction{transactionsCount === 1 ? "" : "s"}
        </Count>
      )}
    </div>
  )
}
