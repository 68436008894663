import * as React from "react"
import { SharedBarChartStyles } from "src/frontend/components/OS/Shared/Charts/styles"
import { ChartContainer } from "src/frontend/components/Shared/Layout/Components/Charts/shared"
import { ParentSizedTimeseriesBarChart } from "src/frontend/components/Shared/Layout/Components/Charts/TimeseriesBarChart"
import { type TimeseriesValue } from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"
import {
  ComponentDateSummary,
  ComponentSummary,
} from "src/frontend/components/Shared/Layout/Components/Headers/ComponentSummary"
import { TimeseriesBarChartTile } from "src/frontend/components/Shared/Layout/Components/Tiles/TimeseriesBarChartTile"
import { ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { type SizingProps } from "src/frontend/components/Shared/Layout/types"

/*
  INTERFACES
*/

interface BarChartComponentProps extends SizingProps {
  componentSize: ComponentSize
  title: React.ReactNode | undefined
  icon: React.ReactNode | undefined
  timeseries: TimeseriesValue[]
  skipAnimations: boolean
  onMouseOver: (value: TimeseriesValue, index: number) => void
  onMouseOut: (value?: TimeseriesValue) => void
  onClick?: (value: TimeseriesValue, index: number) => void
  selectedIndex?: number
}

/*
  COMPONENTS
*/

export const BarChartComponent = React.memo<BarChartComponentProps>(
  ({
    componentSize,
    title,
    icon,
    timeseries,
    height,
    skipAnimations,
    onMouseOver,
    onMouseOut,
    onClick,
    selectedIndex,
  }) => {
    const preselected = selectedIndex ? timeseries[selectedIndex] : undefined
    if (componentSize === ComponentSize.Small || componentSize === ComponentSize.PageSmall) {
      return (
        <>
          <ComponentDateSummary
            componentSize={componentSize}
            title={title}
            icon={icon}
            timeseries={timeseries}
            selectedIndex={selectedIndex}
          />
          <TimeseriesBarChartTile
            timeseries={timeseries}
            skipAnimations={skipAnimations}
            selectedIndex={selectedIndex}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            onClick={onClick}
            height={height}
            chartStyle={SharedBarChartStyles}
          />
        </>
      )
    }

    return (
      <>
        <ComponentSummary
          componentSize={componentSize}
          title={title}
          icon={icon}
          timeseries={timeseries}
          selectedIndex={selectedIndex}
        />
        <ChartContainer height={height} width="auto">
          <ParentSizedTimeseriesBarChart
            timeseries={timeseries}
            skipAnimations={skipAnimations}
            preselectedValue={preselected}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            onClick={onClick}
            chartStyle={SharedBarChartStyles}
          />
        </ChartContainer>
      </>
    )
  }
)
