import * as React from "react"
import { Interval } from "@digits-graphql/frontend/graphql-bearer"
import useConstant from "@digits-shared/hooks/useConstant"
import colors from "@digits-shared/themes/colors"
import styled, { css } from "styled-components"
import { v4 as generateUUID } from "uuid"
import dayjs from "@digits-shared/initializers/dayjs/dayjs"
import {
  RUNWAY_GRADIENT,
  runwayColors,
  useCashOutTimeLeft,
} from "src/frontend/components/Shared/Layout/Components/Runway/shared"
import {
  TileCard,
  TileCardHeading,
} from "src/frontend/components/Shared/Layout/Components/Tiles/TileCard"
import { TileDeltaTime } from "src/frontend/components/Shared/Layout/Components/Tiles/TileDelta"
import { type Runway } from "src/frontend/types"

const GAUGE_MAX_MONTHS = 36
const GAUGE_MAX_YEARS = GAUGE_MAX_MONTHS / 12

/*
 STYLES
*/

const GaugePosition = styled.div`
  position: relative;
  width: 100%;
`

const UnderGaugeText = styled.div<{ largeText?: boolean }>`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10%;

  text-align: center;
  font-size: ${({ largeText }) => (largeText ? 16 : 12)}px;
  color: ${colors.secondary60};

  display: flex;
  flex-direction: column;
`

const GaugeFill = styled.div<{ profitable?: boolean }>`
  ${({ profitable }) =>
    profitable
      ? css`
          background-color: ${runwayColors.green};
        `
      : css`
          background: conic-gradient(from 270deg, ${RUNWAY_GRADIENT.join(", ")} 35%);
        `}
`

/*
 COMPONENTS 
*/

export const RunwayTile: React.FC<{
  className?: string
  height?: number
  loading?: boolean
  runway?: Runway
  skipAnimations?: boolean
}> = ({ className, loading, height, runway, skipAnimations }) => {
  const { title, count, interval, profitable, deltaMonths } = useCashOutTimeLeft(runway)
  const cashOutMoment = React.useMemo(
    () => runway?.cashOutDate && dayjs.unix(runway.cashOutDate),
    [runway?.cashOutDate]
  )
  const cashOutText = profitable
    ? "🎉"
    : cashOutMoment
      ? [cashOutMoment.format("MMMM"), cashOutMoment.format("YYYY")].map((s) => (
          <span key={s}>{s}</span>
        ))
      : ""

  return (
    <TileCard className={className} height={height} skipAnimations={skipAnimations}>
      <TileCardHeading>
        {loading ? "" : title}
        <TileDeltaTime intervalCount={deltaMonths} interval={Interval.Month} />
      </TileCardHeading>
      <GaugePosition>
        <GradientGauge
          max={interval === Interval.Month ? GAUGE_MAX_MONTHS : GAUGE_MAX_YEARS}
          current={count}
          profitable={profitable}
          skipAnimations={skipAnimations}
        />
        <UnderGaugeText largeText={profitable}>{loading ? "" : cashOutText}</UnderGaugeText>
      </GaugePosition>
    </TileCard>
  )
}

const GradientGauge: React.FC<{
  max: number
  current?: number
  profitable?: boolean
  skipAnimations?: boolean
}> = ({ max, current, profitable, skipAnimations }) => {
  const uuid = useConstant(() => generateUUID())
  const arcStrokeWidth = 18
  const radius = 106 // > 90,
  const viewBoxWidth = 200

  // Compute the viewBoxHeight based on the available parameters.
  // Since we know the circle radius and length of a chord across the circle,
  // we can solve a right triangle (1 leg: chordLength/2, hypotenuse: radius)
  // to find the height of the arc.
  const viewBoxHeight = React.useMemo(() => {
    const chordLength = viewBoxWidth - arcStrokeWidth
    const arcHeight = radius - Math.sqrt(radius ** 2 - (chordLength / 2) ** 2)
    return arcHeight + arcStrokeWidth
  }, [arcStrokeWidth, radius, viewBoxWidth])

  const gaugePath = `M${arcStrokeWidth / 2},${
    viewBoxHeight - arcStrokeWidth / 2
  }a${radius},${radius} 0 0,1 ${viewBoxWidth - arcStrokeWidth},0`

  const progress = profitable
    ? 1
    : current === undefined
      ? 0.01
      : Math.max(Math.min(current / max, 1), 0.01)

  const loading = current === undefined && profitable === undefined

  return (
    <svg viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}>
      <mask id={`gauge-clip-${uuid}`}>
        <path
          css={!skipAnimations && "transition: stroke-dashoffset 1000ms 450ms ease-in-out;"}
          d={gaugePath}
          stroke={loading ? "black " : "white"}
          strokeWidth={arcStrokeWidth}
          strokeDasharray={viewBoxWidth + arcStrokeWidth}
          /* 0 is full */
          strokeDashoffset={(1 - progress) * (viewBoxWidth + arcStrokeWidth)}
          fill="none"
          strokeLinecap="round"
        />
      </mask>
      {/* gauge track */}
      <path
        d={gaugePath}
        stroke={colors.translucentBlack10}
        strokeWidth={arcStrokeWidth}
        fill="none"
        strokeLinecap="round"
      />
      {/* SVG doesn't support conic/angular gradients */}
      <foreignObject
        x="0"
        y="0"
        width={viewBoxWidth}
        height={viewBoxWidth}
        mask={`url(#gauge-clip-${uuid})`}
      >
        <GaugeFill
          css={`
            width: ${viewBoxWidth}px;
            height: ${viewBoxWidth}px;
          `}
          profitable={profitable}
        />
      </foreignObject>
    </svg>
  )
}
