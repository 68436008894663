import {
  type LayoutComponentType,
  type StatementConfig,
} from "@digits-graphql/frontend/graphql-bearer"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/useViewVersion"
import { useStatementLiveData } from "src/frontend/components/Shared/Layout/Components/Statements/useStatementLiveData"

/**
 * Must be used within a LayoutContext.
 */
export function usePortalStatementLiveData(
  config: StatementConfig,
  type: LayoutComponentType,
  skip?: boolean
) {
  const viewKey = useViewVersion()
  return useStatementLiveData(config, type, viewKey, skip)
}
