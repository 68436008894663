import * as React from "react"
import { LogoFooter } from "src/frontend/components/Shared/NavSidebar/Footer/LogoFooter"
import { useTrialPeriod } from "src/frontend/components/Shared/NavSidebar/WelcomeModal/useTrialPeriod"

const LazyModalFooter = React.lazy(
  () => import("src/frontend/components/Shared/NavSidebar/WelcomeModal/ModalFooter")
)

export const ModalFooter: React.FC = () => {
  const trialPeriod = useTrialPeriod()
  return trialPeriod.isActive ? (
    <React.Suspense fallback={<LogoFooter />}>
      <LazyModalFooter trialPeriod={trialPeriod} />
    </React.Suspense>
  ) : (
    <LogoFooter />
  )
}
