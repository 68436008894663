import { TABLE_BORDER_COLOR } from "@digits-shared/components/UI/Table"
import { DigitsButton } from "@digits-shared/DesignSystem/Button"
import { themedStyles } from "@digits-shared/themes"
import borders from "@digits-shared/themes/borders"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"

/*
  STYLES
*/

export const PeriodHeader = styled.div<{ empty?: boolean }>`
  ${themedStyles({
    light: css`
      border: 1px solid ${colors.translucentWhite60};
      background-color: ${colors.translucentWhite20};
    `,
    dark: css`
      border: 1px solid ${TABLE_BORDER_COLOR};
      background-color: ${colors.osRowBackground};
    `,
  })}
  border-top-left-radius: ${borders.radius.highlight}px;
  border-top-right-radius: ${borders.radius.highlight}px;
  border-bottom-width: 0;
  ${({ empty }) =>
    empty &&
    css`
      border-bottom-left-radius: ${borders.radius.highlight}px;
      border-bottom-right-radius: ${borders.radius.highlight}px;
      border-bottom-width: 1px;
    `}

  ${themedStyles({
    light: css`
      color: ${colors.secondary};
    `,
    dark: css`
      color: ${colors.white};
    `,
  })}
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 42px;
  width: 100%;
  padding: 10px;
`

export const PeriodHeaderTitleContainer = styled.div`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: ${fonts.weight.book};
`

export const PeriodHeaderPrimaryTitle = styled.div`
  font-weight: ${fonts.weight.medium};
  display: flex;
  align-items: center;
`

export const PeriodHeaderSecondaryTitle = styled.div`
  ${themedStyles({
    light: css`
      color: ${colors.translucentSecondary80};
    `,
    dark: css`
      color: ${colors.translucentWhite50};
    `,
  })}
  font-weight: ${fonts.weight.normal};
  margin-left: 5px;
  display: flex;
  align-items: center;
`

export const PeriodHeaderAnnotation = styled.div`
  font-size: 10px;
  ${themedStyles({
    light: css`
      color: ${colors.translucentSecondary80};
    `,
    dark: css`
      color: ${colors.translucentWhite50};
    `,
  })}
  text-transform: uppercase;
  display: flex;
`

export const ClearFilterButton = styled(DigitsButton)`
  margin-left: 30px;
`

export const PeriodHeaderRightContent = styled.div`
  display: flex;
  align-items: center;
  text-align: right;
`
