import * as React from "react"
import { css } from "styled-components"
import { DeleteComponentStep } from "src/frontend/components/Shared/Layout/types"
import {
  POOF_ANIMATION_KEYFRAMES,
  TRASH_CAN_RATTLE_KEYFRAMES,
} from "src/frontend/components/Shared/Portals/Components/DeleteAnimation"
import { usePortalDispatch } from "src/frontend/components/Shared/Portals/State/portalStore"
import { useDeleteComponentAnimation } from "src/frontend/components/Shared/Portals/State/useDeleteComponentAnimation"
import { DELETE_COMPONENT_SHRINK_KEYFRAMES } from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/LayoutViewer/DeleteAnimation"

export function useComponentDeleteAnimation(componentId?: string) {
  const builderDispatch = usePortalDispatch()
  const deleteComponentAnimation = useDeleteComponentAnimation(componentId)

  const onShrinkEnd = React.useCallback(
    (e: React.AnimationEvent) => {
      if (e.animationName === DELETE_COMPONENT_SHRINK_KEYFRAMES.getName()) {
        builderDispatch({ type: "deleteComponentShrinkEnded" })
      }
    },
    [builderDispatch]
  )

  const onDeleteAnimationEnd = React.useCallback(
    (e: React.AnimationEvent) => {
      const { animationName } = e
      if (
        deleteComponentAnimation &&
        (animationName === POOF_ANIMATION_KEYFRAMES.getName() ||
          animationName === TRASH_CAN_RATTLE_KEYFRAMES.getName())
      ) {
        builderDispatch({ type: "deleteComponentAnimationEnded" })
        builderDispatch({
          type: "deleteComponent",
          componentId: deleteComponentAnimation.componentId,
        })
      }
    },
    [builderDispatch, deleteComponentAnimation]
  )

  // After the delete transition animation plays, we need to remove those styles so that
  // the scale and opacity can be reset. Otherwise the poof image won't be visible.
  // However we still need to keep the contents of the component hidden for the poof
  // to work.
  const componentCss = React.useMemo(
    () =>
      deleteComponentAnimation?.step === DeleteComponentStep.Poof
        ? css`
            opacity: 0;
          `
        : undefined,
    [deleteComponentAnimation?.step]
  )

  return React.useMemo(
    () => ({
      ...deleteComponentAnimation,
      componentCss,
      onShrinkEnd,
      onDeleteAnimationEnd,
    }),
    [componentCss, deleteComponentAnimation, onDeleteAnimationEnd, onShrinkEnd]
  )
}
