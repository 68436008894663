import * as React from "react"
import {
  type LayoutComponentType,
  SummaryLineItem,
  type SummaryLineItemConfig as Config,
} from "@digits-graphql/frontend/graphql-bearer"
import stringHelper from "@digits-shared/helpers/stringHelper"
import { toSortedTimeseriesValues } from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"
import { type SizingProps } from "src/frontend/components/Shared/Layout/types"
import { BarChartConfig } from "src/frontend/components/Shared/Portals/ConfigsSidebar/Configs/Charts/BarChartConfig"
import { LineChartConfig } from "src/frontend/components/Shared/Portals/ConfigsSidebar/Configs/Charts/LineChartConfig"
import { useSummaryLineItemByTimeLiveData } from "src/frontend/components/Shared/Portals/hooks/useSummaryLineItemByTimeLiveData"
import { useRemoveConfig } from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/Drawer/ComponentConfigContext"

/*
  INTERFACES
*/

interface SummaryLineItemChartConfigProps extends SizingProps {
  config: Config
  componentType: LayoutComponentType
  skipAnimations: boolean
}

/*
  COMPONENTS
*/
export const SummaryLineItemChartConfig: React.FC<SummaryLineItemChartConfigProps> = ({
  config,
  componentType,
  height,
  skipAnimations,
}) => {
  const removeConfig = useRemoveConfig()

  const { dimensionalSummary, loading } = useSummaryLineItemByTimeLiveData(config, componentType)

  const timeseries = React.useMemo(
    () => toSortedTimeseriesValues(dimensionalSummary?.time),
    [dimensionalSummary?.time]
  )

  // remove this component if the results are empty
  React.useEffect(() => {
    if (!loading && !dimensionalSummary?.time?.length) {
      removeConfig()
    }
  }, [dimensionalSummary, loading, removeConfig])

  const name = React.useMemo(() => stringHelper.camelCaseToSpaces(config.item), [config.item])

  if (config.item === SummaryLineItem.TotalCash) {
    return (
      <LineChartConfig
        name={name}
        timeseries={timeseries}
        skipAnimations={skipAnimations}
        height={height}
      />
    )
  }

  return (
    <BarChartConfig
      name={name}
      timeseries={timeseries}
      skipAnimations={skipAnimations}
      height={height}
    />
  )
}
