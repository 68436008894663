import * as React from "react"
import { shallow } from "zustand/shallow"
import {
  usePortalDispatch,
  usePortalStore,
} from "src/frontend/components/Shared/Portals/State/portalStore"
import { type InviteClientsModalState } from "src/frontend/components/Shared/Portals/State/types"

export function useInviteClientsModalControls(initialModalState?: InviteClientsModalState) {
  const portalDispatch = usePortalDispatch()
  const { showInviteClientsModal, initialInviteClientsModalState } = usePortalStore(
    (state) => ({
      showInviteClientsModal: state.showInviteClientsModal,
      initialInviteClientsModalState: state.initialInviteClientsModalState,
    }),
    shallow
  )

  const showModal = React.useCallback(() => {
    portalDispatch({ type: "setShowInviteClientsModal", show: true, initialModalState })
  }, [initialModalState, portalDispatch])

  const hideModal = React.useCallback(() => {
    portalDispatch({ type: "setShowInviteClientsModal", show: false })
  }, [portalDispatch])

  return React.useMemo(
    () => ({
      shouldShow: showInviteClientsModal,
      initialModalState: initialInviteClientsModalState,
      showModal,
      hideModal,
    }),
    [hideModal, initialInviteClientsModalState, showInviteClientsModal, showModal]
  )
}
