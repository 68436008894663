import * as React from "react"
import {
  EmployeeStatus,
  EmployeeVisibility,
  useListEmployeesQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import useSession from "@digits-shared/hooks/useSession"
import { JWTPermissionFlag } from "@digits-shared/session/jwt/jwtPermissions"
import { useHasPermission } from "src/frontend/components/Shared/Permissions/Requires"
import type FrontendSession from "src/frontend/session"
import {
  FrontendPermissionModule,
  FrontendPermissionSource,
} from "src/frontend/session/permissionModule"

interface ViewersOptions {
  limit?: number
  includeInvited?: boolean
  skip?: boolean
}

export function usePortalViewers(options?: ViewersOptions) {
  const { limit = 100, includeInvited = true } = options ?? {}
  const {
    currentLegalEntity,
    currentLegalEntityId: legalEntityId,
    doppelganger,
  } = useSession<FrontendSession>()

  const statuses = React.useMemo(
    () =>
      includeInvited
        ? [EmployeeStatus.Activated, EmployeeStatus.Initialized]
        : [EmployeeStatus.Activated],
    [includeInvited]
  )

  const hasEmployeesPermission = useHasPermission({
    source: FrontendPermissionSource.LegalEntity,
    module: FrontendPermissionModule.Employees,
    flag: JWTPermissionFlag.Read,
  })

  const { data, loading, error } = useListEmployeesQuery({
    variables: {
      filter: {
        legalEntityId,
        statuses,
        visibility: EmployeeVisibility.Active,
      },
      pagination: {
        limit,
        offset: 0,
      },
    },
    skip:
      !legalEntityId ||
      !currentLegalEntity?.hasDashboardAccess(doppelganger) ||
      !hasEmployeesPermission ||
      options?.skip,
    // This query is fetched from multiple screens (Transaction Review, Reports, Springboard, Client Portal)
    // and if any are inactive when a new client is added in the portal, they don't get refetched.
    fetchPolicy: "cache-and-network",
  })

  return React.useMemo(
    () => ({
      loading,
      error,
      portalViewers: data?.listEmployees,
    }),
    [loading, error, data?.listEmployees]
  )
}
