import * as React from "react"
import {
  SharedBarChartStyles,
  SharedLineChartStyles,
} from "src/frontend/components/OS/Shared/Charts/styles"
import { ChartContainer } from "src/frontend/components/Shared/Layout/Components/Charts/shared"
import { ParentSizedTimeseriesBreakdownChart } from "src/frontend/components/Shared/Layout/Components/Charts/TimeseriesBreakdownChart"
import { LocationComponentIcon } from "src/frontend/components/Shared/Layout/Components/Headers/ComponentEntityIcons"
import { ComponentSummary } from "src/frontend/components/Shared/Layout/Components/Headers/ComponentSummary"
import { useLocationChartLiveData } from "src/frontend/components/Shared/Layout/Components/Locations/useLocationChartLiveData"
import { useLocationChartTimeseries } from "src/frontend/components/Shared/Layout/Components/Locations/useLocationChartTimeseries"
import { ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { type MatchedConfig, type SizingProps } from "src/frontend/components/Shared/Layout/types"
import { type LocationChartData } from "src/frontend/types"

/*
  INTERFACES
*/

interface ChartConfigProps extends SizingProps {
  config: MatchedConfig<"locationChart">
  skipAnimations: boolean
  layoutId: string
  onSummaries?: (data: LocationChartData | undefined) => void
}

/*
  COMPONENTS
*/
export const LocationChartConfig: React.FC<ChartConfigProps> = ({
  config,
  height,
  skipAnimations,
  layoutId,
  onSummaries,
}) => {
  const { origin } = config.locationChart
  const { data, loading } = useLocationChartLiveData(config.locationChart)

  const { breakdownTimeseries, totalTimeseries } = useLocationChartTimeseries(data)

  // remove this component if the results are empty
  React.useEffect(() => {
    if (!loading) {
      onSummaries?.(data)
    }
  }, [data, loading, onSummaries])

  return (
    <>
      <ComponentSummary
        componentSize={ComponentSize.Small}
        icon={
          <LocationComponentIcon
            location={undefined}
            intervalOrigin={origin}
            componentSize={ComponentSize.Small}
          />
        }
        title={data?.location.name ?? "Location"}
        timeseries={totalTimeseries.values}
      />
      <ChartContainer height={height} width="auto">
        <ParentSizedTimeseriesBreakdownChart
          breakdownTimeseries={breakdownTimeseries}
          totalTimeseries={totalTimeseries}
          barChartStyle={SharedBarChartStyles}
          lineChartStyle={SharedLineChartStyles}
          hideGrid
          noTooltip
          hideAxis
          skipAnimations={skipAnimations}
        />
      </ChartContainer>
    </>
  )
}
