import React from "react"
import {
  PointingDirection,
  themedChevronStyles,
} from "@digits-shared/components/UI/Elements/Chevron"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"

const DEFAULT_PICKER_HEIGHT = 43

/*
  STYLES
*/

const chevronStyles = themedChevronStyles<ContainerProps>(PointingDirection.Down, "3px", {
  light: colors.white,
  dark: ({ isDisabled, noChevron }) =>
    isDisabled || noChevron ? colors.transparent : colors.theme.dark.text,
})

export const PickerBubbleContainer = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${({ height }) => height || DEFAULT_PICKER_HEIGHT}px;
  cursor: pointer;
  pointer-events: ${({ isDisabled }) => (isDisabled ? "none" : "unset")};

  border-radius: 50vh;
  padding: 5px 25px 5px 10px;

  &::after {
    position: absolute;
    right: 12px;
    bottom: 18px;
    ${chevronStyles};
  }
`

/*
  INTERFACES
*/

export interface PickerBubbleIconProps<E, P = {}> {
  element?: E
  isLoading?: boolean
  isSelected?: boolean
  additionalProps?: P
}

export interface PickerBubbleLabelProps<E, P = {}> {
  element?: E
  isLoading?: boolean
  isSelected?: boolean
  additionalProps?: P
}

interface ContainerProps {
  isDimmed?: boolean
  isDisabled?: boolean
  noChevron?: boolean
  height?: number
}

interface Props<E, L, I> {
  className?: string
  height?: number
  // Component to render for the icon placement in bubble
  IconComponent?: React.ComponentType<PickerBubbleIconProps<E, I>>
  iconProps?: I
  // Component to render for the label placement in bubble
  LabelComponent: React.ComponentType<PickerBubbleLabelProps<E, L>>
  // Props to be passed to the label to help with rendering
  labelProps?: L
  element?: E
  onClick?: (event: React.MouseEvent) => void
  isDimmed?: boolean
  isDisabled?: boolean
  isLoading?: boolean
  // Don't show chevron in corner indicating the bubble can be clicked
  noChevron?: boolean
}

/*
  COMPONENTS
*/

export function PickerBubble<E, L, I>({
  className,
  height,
  IconComponent,
  iconProps,
  LabelComponent,
  labelProps,
  element,
  onClick,
  isDimmed,
  isDisabled,
  isLoading,
  noChevron,
  children,
}: React.PropsWithChildren<Props<E, L, I>>) {
  const preventBleedingOnClick = React.useCallback(
    (event: React.MouseEvent) => {
      if (!onClick) return

      event.stopPropagation()
      event.preventDefault()
      onClick(event)
    },
    [onClick]
  )

  return (
    <PickerBubbleContainer
      className={className}
      onClick={preventBleedingOnClick}
      isDimmed={isDimmed}
      isDisabled={isDisabled}
      noChevron={noChevron}
      height={height}
    >
      {IconComponent && (
        <IconComponent
          element={element}
          isLoading={isLoading}
          isSelected={!isDimmed}
          additionalProps={iconProps}
        />
      )}
      <LabelComponent
        element={element}
        isLoading={isLoading}
        isSelected={!isDimmed}
        additionalProps={labelProps}
      />
      {children}
    </PickerBubbleContainer>
  )
}
