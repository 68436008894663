import { themedStyles } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import { css } from "styled-components"

export const OS_BUTTON_HOVER_BACKGROUND_COLOR = colors.translucentWhite70

export const OS_BUTTON_HOVER_STYLES = css`
  border-radius: 4px;
  background-color: ${colors.transparent};
  transition: background-color 150ms ease-out;

  &:hover {
    ${themedStyles({
      light: css`
        background-color: ${colors.translucentWhite70};
      `,
      dark: css`
        background-color: rgb(6, 242, 253, 30%);
      `,
    })}
  }
`
