import { Link } from "react-router-dom"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"

export const Banner = styled.div`
  width: 100%;
  padding: 10px 30px;
  background-color: ${colors.orange};
  text-align: center;
`

export const PlainMessage = styled.div`
  font-size: 14px;
  color: ${colors.white};
`

export const Message = styled(Link)`
  font-size: 14px;
  color: ${colors.white};

  &:hover {
    text-decoration: underline;
  }
`
