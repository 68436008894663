import * as React from "react"
import { useDocumentDownloadUrlLazyQuery } from "@digits-graphql/frontend/graphql-bearer"
import useSession from "@digits-shared/hooks/useSession"
import { type VaultFile } from "src/frontend/components/OS/Applications/Vault/types"
import type FrontendSession from "src/frontend/session"

export function useDownloadVaultFile() {
  const { currentLegalEntityId: legalEntityId } = useSession<FrontendSession>()
  const [downloadUrl] = useDocumentDownloadUrlLazyQuery()

  return React.useCallback(
    (file: VaultFile) =>
      downloadUrl({
        variables: {
          legalEntityId,
          id: file.id,
        },
        fetchPolicy: "no-cache",
      }).then(({ data }) => {
        if (!data?.documentDownloadUrl) return
        window.location.href = data.documentDownloadUrl.url
      }),
    [downloadUrl, legalEntityId]
  )
}
