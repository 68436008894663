import * as React from "react"
import { DocumentType } from "@digits-graphql/frontend/graphql-bearer"
import {
  EXCEL_FILETYPES,
  type FileType,
  IMAGE_FILETYPES,
  PDF_FILETYPES,
  WORD_FILETYPES,
} from "@digits-shared/helpers/fileHelper"
import useRouter from "@digits-shared/hooks/useRouter"
import { VaultQueryParam } from "src/frontend/components/OS/Applications/Vault/useVaultQueryParams"
import { useFrontendSearchPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import routes from "src/frontend/routes"

export enum VaultFilterCategory {
  fileType = "fileType",
  creator = "creator",
  documentType = "documentType",
}

export enum VaultFilterType {
  pdf = "pdf",
  docs = "docs",
  sheets = "sheets",
  imgs = "imgs",
  creator = VaultFilterCategory.creator,
  documentType = VaultFilterCategory.documentType,
}

type HasOneFilterType = VaultFilterType.creator | VaultFilterType.documentType
type HasManyFilterType =
  | VaultFilterType.pdf
  | VaultFilterType.docs
  | VaultFilterType.sheets
  | VaultFilterType.imgs

export const FILTERABLE_FILE_TYPES = [
  VaultFilterType.pdf,
  VaultFilterType.sheets,
  VaultFilterType.docs,
  VaultFilterType.imgs,
] as const

export const FILTERABLE_DOCUMENT_TYPES = [
  DocumentType.Bill,
  DocumentType.CheckingAccount,
  DocumentType.Compliance,
  DocumentType.Contract,
  DocumentType.CreditCard,
  DocumentType.Equity,
  DocumentType.Form1099,
  DocumentType.FormW9,
  DocumentType.Invoice,
  DocumentType.K1,
  DocumentType.LoanAgreement,
  DocumentType.Payroll,
  DocumentType.Receipt,
  DocumentType.SavingsAccount,
  DocumentType.Schedule,
  DocumentType.TaxID,
  DocumentType.TaxReturn,
  DocumentType.Valuation,
  DocumentType.UnknownType, // intentionally placing Unknown last as it will be our "other" category
] as const satisfies DocumentType[]

export function useVaultFilters() {
  const [hasPDF, onPDF] = useFilterMany(VaultQueryParam.mime, PDF_FILETYPES)
  const [hasDocs, onDocs] = useFilterMany(VaultQueryParam.mime, WORD_FILETYPES)
  const [hasSheets, onSheets] = useFilterMany(VaultQueryParam.mime, EXCEL_FILETYPES)
  const [hasImgs, onImgs] = useFilterMany(VaultQueryParam.mime, IMAGE_FILETYPES)
  const [hasCreator, hasThisCreator, filterByCreator] = useFilterOne(VaultQueryParam.creatorId)
  const [hasDocumentType, hasThisDocumentType, filterByDocumentType] = useFilterOne(
    VaultQueryParam.documentType
  )

  const onHasManyFilterChange = React.useCallback(
    (filterType: HasManyFilterType) => {
      switch (filterType) {
        case VaultFilterType.pdf:
          return onPDF
        case VaultFilterType.docs:
          return onDocs
        case VaultFilterType.sheets:
          return onSheets
        case VaultFilterType.imgs:
          return onImgs
        default:
          return undefined
      }
    },
    [onPDF, onDocs, onSheets, onImgs]
  )

  const onHasOneFilterChange = React.useCallback(
    (filterType: HasOneFilterType, filter: string) => {
      switch (filterType) {
        case VaultFilterType.creator:
          return filterByCreator(filter)
        case VaultFilterType.documentType:
          return filterByDocumentType(filter)
        default:
          return undefined
      }
    },
    [filterByCreator, filterByDocumentType]
  )

  const isFilterActive = React.useCallback(
    (filterType: VaultFilterType) => {
      switch (filterType) {
        case VaultFilterType.pdf:
          return hasPDF
        case VaultFilterType.docs:
          return hasDocs
        case VaultFilterType.sheets:
          return hasSheets
        case VaultFilterType.creator:
          return hasCreator
        case VaultFilterType.documentType:
          return hasDocumentType
        case VaultFilterType.imgs:
          return hasImgs
        default:
          return false
      }
    },
    [hasPDF, hasDocs, hasSheets, hasCreator, hasDocumentType, hasImgs]
  )

  const isHasOneFilterActive = React.useCallback(
    (filterType: HasOneFilterType, filter: string) => {
      switch (filterType) {
        case VaultFilterType.creator:
          return hasThisCreator(filter)
        case VaultFilterType.documentType:
          return hasThisDocumentType(filter)
        default:
          return false
      }
    },
    [hasThisCreator, hasThisDocumentType]
  )

  const activeFilters = React.useMemo(() => {
    const hasFileType = hasPDF || hasDocs || hasSheets || hasImgs
    const activeFilterTypes: VaultFilterCategory[] = []
    if (hasCreator) activeFilterTypes.push(VaultFilterCategory.creator)
    if (hasFileType) activeFilterTypes.push(VaultFilterCategory.fileType)
    if (hasDocumentType) activeFilterTypes.push(VaultFilterCategory.documentType)

    return activeFilterTypes
  }, [hasPDF, hasDocs, hasSheets, hasImgs, hasCreator, hasDocumentType])

  return React.useMemo(
    () => ({
      activeFilters,
      isFilterActive,
      isHasOneFilterActive,
      onHasOneFilterChange,
      onHasManyFilterChange,
    }),
    [
      activeFilters,
      isFilterActive,
      onHasManyFilterChange,
      onHasOneFilterChange,
      isHasOneFilterActive,
    ]
  )
}

function useFilterMany<T extends FileType>(
  queryParam: VaultQueryParam,
  filterValues: T[] | readonly T[]
): [boolean, () => void] {
  const { search, history } = useRouter()
  const generatePath = useFrontendSearchPathGenerator()

  const setFilterValues = React.useCallback(
    (isActive: boolean) => {
      const newParams = new URLSearchParams()
      if (isActive) {
        filterValues.forEach(() => newParams.set(queryParam, ""))
      } else {
        filterValues.forEach((fv) => newParams.append(queryParam, fv))
      }
      history.replace(generatePath(routes.vault, newParams))
    },
    [queryParam, filterValues, generatePath, history]
  )

  return React.useMemo(() => {
    const hasFilter = Boolean(filterValues.some((fv) => search.has(queryParam, fv)))
    return [hasFilter, () => setFilterValues(hasFilter)]
  }, [search, filterValues, queryParam, setFilterValues])
}

function useFilterOne(
  queryParam: VaultQueryParam
): [boolean, (value: string) => boolean, (value: string) => () => void] {
  const { search, history } = useRouter()
  const generatePath = useFrontendSearchPathGenerator()

  return React.useMemo(() => {
    const has = (value: string) => search.has(queryParam, value)
    return [
      search.has(queryParam),
      has,
      (value: string) => () => {
        const newParams = new URLSearchParams()
        if (has(value)) {
          newParams.set(queryParam, "")
        } else {
          newParams.set(queryParam, value)
        }
        history.replace(generatePath(routes.vault, newParams))
      },
    ]
  }, [queryParam, generatePath, history, search])
}

export function isVaultFilter(value: string): value is VaultFilterCategory {
  const vaultFilters: string[] = [
    VaultFilterCategory.documentType,
    VaultFilterCategory.creator,
    VaultFilterCategory.fileType,
  ]

  return vaultFilters.includes(value)
}
