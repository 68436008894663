import * as React from "react"
import {
  type EntityLegalEntity,
  type EntityReportPackage,
  type ReportPackage,
  type ReportPackageMetadata,
  ReportPackageStatus,
} from "@digits-graphql/frontend/graphql-bearer"
import { LoadingBlock } from "@digits-shared/components/Loaders"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import { FileType } from "@digits-shared/helpers/fileHelper"
import useSession from "@digits-shared/hooks/useSession"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"
import { COVER_IMAGES, DEFAULT_COVER_URL } from "src/frontend/components/Shared/Covers/coverImages"
import { DigitsPackageIconSize } from "src/frontend/components/Shared/Reports/Packages/Icons/DigitsPackageIconSize"
import {
  isEntityReportPackage,
  isReportPackage,
  isReportPackageStatus,
} from "src/frontend/components/Shared/Reports/Packages/shared"
import type FrontendSession from "src/frontend/session"
import { FileIcon } from "src/shared/components/Icons/FileIcon"

/*
  STYLES
*/
const ICON_HEIGHT = 130
const ICON_WIDTH = 125

const REPORT_COVER_BG = require("static/images/reports/report-cover-bg.png")

const NoPackagesIconOuterContainer = styled.div<{ size: DigitsPackageIconSize }>`
  display: flex;
  justify-content: center;
  width: ${({ size }) => size.containerWidth}px;
`

const FileIconContainer = styled.div<{
  size: DigitsPackageIconSize
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => size.containerWidth}px;
  height: ${({ size }) => size.containerHeight}px;
`

const FileIconStyled = styled(FileIcon)`
  width: 98px;
  height: auto;
`

export const PackageCoverBg = styled.div`
  background-image: url("${REPORT_COVER_BG}");
  background-size: contain;
  background-repeat: no-repeat;
  width: 263px;
  height: 174px;
`

export const PackageCoverMask = styled.div`
  width: 94px;
  height: 112px;
  overflow: hidden;
  border-radius: 8px;
  margin-top: 32px;
  margin-left: 75px;
`

export const PackageCoverImage = styled.div<{
  coverSrc: string | undefined
  hasClickHandler?: boolean
}>`
  ${({ coverSrc }) =>
    coverSrc &&
    css`
      background-image: url("${coverSrc}");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    `}
  width: 94px;
  height: 112px;

  ${({ hasClickHandler }) =>
    hasClickHandler &&
    css`
      cursor: pointer;
    `}
`

export const InfoContainer = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 10px;
  border-radius: 8px;
  background: ${colors.translucentBlack10};
`

export const LegalEntityName = styled.div`
  font-size: 6px;
  font-weight: ${fonts.weight.book};
  color: ${colors.white};
  margin-top: 32px;
  text-overflow: ellipsis;
  overflow: hidden;
`

export const Title = styled.div`
  color: ${colors.white};
  font-size: 8px;
  font-weight: ${fonts.weight.heavy};
  z-index: 0;
`

export const Subtitle = styled.div`
  color: ${colors.translucentWhite70};
  font-weight: ${fonts.weight.medium};
  font-size: 5px;
`

const DraftWatermarkText = styled.div`
  position: absolute;
  left: 0;
  bottom: -2px;
  pointer-events: none;
  font-weight: ${fonts.weight.black};
  mix-blend-mode: plus-lighter;
  color: transparent;
  background: rgba(111, 112, 118, 0.8);
  background-clip: text;
  font-size: 29px;
  text-align: center;
  margin-left: -2px;
`

interface PackageIconProps {
  reportPackage: EntityReportPackage | ReportPackageMetadata | ReportPackage
  legalEntity?: EntityLegalEntity
  onClick?: () => void
  className?: string
}

/*
  COMPONENTS
*/

export const ReportPackageIcon: React.FC<
  PackageIconProps & {
    size: DigitsPackageIconSize
  }
> = ({ className, size, reportPackage, legalEntity, onClick }) => {
  const displayFileType = React.useMemo(() => {
    let firstFileType: FileType | string | undefined
    let hasOnlyUploads = true

    const documents = isReportPackage(reportPackage)
      ? reportPackage.documents.map((d) => d.metadata)
      : reportPackage.documents

    documents.forEach((d, i) => {
      const fileType = "mimeType" in d ? d.mimeType : d.reportFile?.mimeType
      // Store the first file type so we can use its icon if need be
      if (!firstFileType) firstFileType = fileType

      // If there is no file type, we can assume its a generated report. If there is at least 1
      // Digits generated report in the package, we know its not only uploads
      if (!fileType || fileType === FileType.DIGITS) hasOnlyUploads = false
    })

    // display file type will be either the first file's type if there are no Digits generated
    // reports or the Digits Report icon if any documents are Digits generated.
    return hasOnlyUploads ? firstFileType : FileType.DIGITS
  }, [reportPackage])

  if (displayFileType && displayFileType !== FileType.DIGITS) {
    return (
      <FileIconContainer size={size} onClick={onClick}>
        <FileIconStyled fileType={displayFileType} />
      </FileIconContainer>
    )
  }

  return (
    <CoverIcon
      reportPackage={reportPackage}
      className={className}
      legalEntity={legalEntity}
      onClick={onClick}
    />
  )
}

const CoverIcon: React.FC<PackageIconProps> = ({
  className,
  reportPackage,
  legalEntity,
  onClick,
}) => (
  <PackageCoverBg className={className}>
    <ReportCover reportPackage={reportPackage} legalEntity={legalEntity} onClick={onClick} />
  </PackageCoverBg>
)

const ReportCover: React.FC<PackageIconProps> = ({ reportPackage, legalEntity, onClick }) => (
  <PackageCoverMask>
    <PackageCover reportPackage={reportPackage} legalEntity={legalEntity} onClick={onClick} />
  </PackageCoverMask>
)

export const PackageCover: React.FC<PackageIconProps & { className?: string }> = ({
  className,
  reportPackage,
  legalEntity,
  onClick,
}) => {
  const coverSrc = React.useMemo(() => {
    const coverUrl = isReportPackage(reportPackage)
      ? reportPackage?.packageOptions?.coverUrl
      : reportPackage.coverUrl
    if (!coverUrl) return COVER_IMAGES.get(DEFAULT_COVER_URL)?.thumb

    return COVER_IMAGES.get(coverUrl)?.thumb || COVER_IMAGES.get(DEFAULT_COVER_URL)?.thumb
  }, [reportPackage])
  return (
    <PackageCoverImage
      className={className}
      coverSrc={coverSrc}
      onClick={onClick}
      hasClickHandler={!!onClick}
    >
      <PackageCoverInfo reportPackage={reportPackage} legalEntity={legalEntity} />
    </PackageCoverImage>
  )
}

const PackageCoverInfo: React.FC<PackageIconProps> = ({ reportPackage, legalEntity }) => {
  const { currentLegalEntity } = useSession<FrontendSession>()

  const periodLabel = React.useMemo(() => {
    if (isEntityReportPackage(reportPackage)) {
      return dateTimeHelper.displayNameFromUnixTimestamp(
        reportPackage.periodEndedAt,
        reportPackage.interval
      )
    }
    return dateTimeHelper.displayNameFromRange(reportPackage)
  }, [reportPackage])

  const isDraft = isReportPackageStatus(reportPackage, ReportPackageStatus.Draft)

  return (
    <InfoContainer>
      {isDraft && <DraftWatermarkText>DRAFT</DraftWatermarkText>}
      <LegalEntityName>{legalEntity?.name ?? currentLegalEntity?.name}</LegalEntityName>
      <Title>{periodLabel}</Title>
      <Subtitle>{reportPackage.title || "Report"}</Subtitle>
    </InfoContainer>
  )
}

export const LoadingReportPackageIcon: React.FC<{
  size?: DigitsPackageIconSize
  className?: string
}> = ({ size = DigitsPackageIconSize.Large, className }) => (
  <NoPackagesIconOuterContainer className={className} size={size}>
    <LoadingBlock height={`${ICON_HEIGHT}px`} width={`${ICON_WIDTH}px`} />
  </NoPackagesIconOuterContainer>
)
