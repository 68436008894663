import * as React from "react"
import useLazyTimeout from "@digits-shared/hooks/useLazyTimeout"
import { DeleteComponentStep } from "src/frontend/components/Shared/Layout/types"
import {
  usePortalDispatch,
  usePortalStore,
} from "src/frontend/components/Shared/Portals/State/portalStore"

/** Waits for a component overlay slide to finish before advancing a component delete animation */
export function useAdvanceComponentDeleteAnimation() {
  const deleteComponentAnimation = usePortalStore((state) => state.deleteComponentAnimation)
  const portalDispatch = usePortalDispatch()

  const onSlideEnded = React.useCallback(() => {
    portalDispatch({ type: "componentSlideEnded" })
  }, [portalDispatch])

  // 200ms matches the default transition time of DndKit
  const [awaitSlideEnd] = useLazyTimeout(onSlideEnded, 200)

  React.useEffect(() => {
    if (deleteComponentAnimation?.step === DeleteComponentStep.Slide) {
      awaitSlideEnd()
    }
  }, [awaitSlideEnd, deleteComponentAnimation?.step])
}
