import React from "react"
import {
  BillingProductName,
  useReadBillingPlansQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import { JWTPermissionFlag } from "@digits-shared/session/jwt/jwtPermissions"
import { useHasPermission } from "src/frontend/components/Shared/Permissions/Requires"
import {
  FrontendPermissionModule,
  FrontendPermissionSource,
} from "src/frontend/session/permissionModule"

export const useBillingPlans = (organizationId?: string) => {
  const hasReadBillingPermission = useHasPermission({
    source: FrontendPermissionSource.Organization,
    module: FrontendPermissionModule.Billing,
    flag: JWTPermissionFlag.Read,
  })

  const result = useReadBillingPlansQuery({
    variables: {
      organizationId: organizationId || "",
      productNames: [BillingProductName.Growth, BillingProductName.Premium],
    },
    skip: !hasReadBillingPermission || !organizationId,
    context: { noBatch: true },
  })

  return React.useMemo(() => {
    const plans = result?.data?.readBillingPlans
    return { plans, loading: result.loading }
  }, [result])
}
