import * as React from "react"
import {
  type CashSummaryChartConfig,
  LayoutComponentType,
  useReadCashSummaryChartLiveDataQuery,
  type ViewIdentifier,
} from "@digits-graphql/frontend/graphql-bearer"
import {
  type CashSummaryChartResponse,
  type EntityCategoryTransactionSummaryHistory,
} from "src/frontend/types"

export function useCashSummaryChartLiveData(
  config: CashSummaryChartConfig | undefined | null,
  viewId: ViewIdentifier,
  skip?: boolean
): CashSummaryChartResponse {
  const { data, loading } = useReadCashSummaryChartLiveDataQuery({
    variables: {
      viewId,
      config: {
        type: LayoutComponentType.CashSummaryChart,
        cashSummaryChart: config,
      },
    },
    skip: skip || !viewId?.viewVersion,
  })

  return React.useMemo(() => {
    const liveData = data?.liveComponentData

    // Resolves the component data union types to the portions we care about
    if (liveData?.__typename === "CashSummary") {
      if (!liveData.cashTotal?.time && !liveData.cashChange?.category) {
        return { data: undefined, loading }
      }

      return {
        data: {
          breakdownSummaries: liveData.cashChange?.category?.map(
            ({
              categoryObject: category,
              by: { time: summaries },
            }): EntityCategoryTransactionSummaryHistory => ({
              category,
              history: summaries?.map((t) => t.summary),
            })
          ),
          totalCash: liveData.cashTotal?.time.map((t) => t.summary),
        },
        loading,
      }
    }

    return { data: undefined, loading }
  }, [data?.liveComponentData, loading])
}
