import React from "react"
import { Theme } from "@digits-shared/themes/theme"
import { ThemeContext as BaseThemeContext } from "styled-components"

export const DEFAULT_THEME = Theme.Dark

export const ThemeContext = BaseThemeContext

export function useThemeContext() {
  return React.useContext(ThemeContext)
}

export function useThemeMode() {
  return useThemeContext()?.mode || DEFAULT_THEME
}

export function useIsPrintTheme() {
  return useThemeMode() === Theme.Print
}
