import { type Category, type CategoryType } from "@digits-graphql/frontend/graphql-bearer"
import {
  type ActiveHierarchicalDimensionNode,
  convertToHierarchicalDimensionTree,
  type HierarchicalDimensionNode,
  sortHierarchicalDimensionByName,
} from "src/shared/helpers/hierarchicalDimensionHelper"

export type CategoriesHierarchyNodesByType = {
  [key in CategoryType]: HierarchicalDimensionNode<Category>[]
}

export interface ActiveCategory {
  category: Category
  count: number
}

export const getCategoriesHierarchyNodesByType = (nodes: Category[]) => {
  const categoriesTree = convertToHierarchicalDimensionTree(nodes, 0)
  return categoriesTree.reduce<CategoriesHierarchyNodesByType>(
    (types, categoryNode: HierarchicalDimensionNode<Category>) => {
      const typeNodes = types[categoryNode.dimension.type] || []
      typeNodes.push(categoryNode)
      types[categoryNode.dimension.type] = typeNodes
      return types
    },
    {} as CategoriesHierarchyNodesByType
  )
}

export const convertToActiveCategoriesTree = (
  nodes: ActiveCategory[],
  depth: number,
  parent?: ActiveCategory
) => {
  const directChildren = nodes
    .filter((node) => node.category.parentCategoryId === (parent ? parent.category.id : null))
    .sort(sortWithCategoriesByDisplayNumber)

  return directChildren.map((activeCategory) => {
    const categoryAssociation: ActiveHierarchicalDimensionNode<Category> = {
      depth,
      dimension: activeCategory.category,
      count: activeCategory.count,
      children: convertToActiveCategoriesTree(nodes, depth + 1, activeCategory),
    }
    categoryAssociation.children.forEach((c) => (c.parent = categoryAssociation))
    return categoryAssociation
  })
}

type WithCategory = { category: Category }

export const sortWithCategoriesByDisplayNumber = (
  withCategoryA: WithCategory,
  withCategoryB: WithCategory
) => sortCategoriesByDisplayNumber(withCategoryA.category, withCategoryB.category)

export const sortCategoriesByDisplayNumber = (categoryA: Category, categoryB: Category) => {
  if (categoryA.displayNumber && !categoryB.displayNumber) return -1
  if (categoryB.displayNumber && !categoryA.displayNumber) return 1
  if (categoryA.displayNumber && categoryB.displayNumber) {
    return (
      parseInt(categoryA.displayNumber.replace("-", ""), 10) -
      parseInt(categoryB.displayNumber.replace("-", ""), 10)
    )
  }

  return sortHierarchicalDimensionByName(categoryA, categoryB)
}

export const categoryIdWithOther = (category: Category) => {
  const isUglyOther = category.id.startsWith("other_")
  return { isUglyOther, categoryId: isUglyOther ? category.parentCategoryId : category.id }
}
