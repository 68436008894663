import * as React from "react"
import {
  type LayoutComponentType,
  type TopEntitiesConfig,
  useReadTopPartiesLiveDataQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/useViewVersion"

/**
 * Queries for layout live data fitting the dimensional reducer shape: by time by category.
 */
export function useTopPartiesLiveData(topEntities: TopEntitiesConfig, type: LayoutComponentType) {
  const viewId = useViewVersion()

  const { data, loading } = useReadTopPartiesLiveDataQuery({
    variables: {
      viewId,
      config: {
        type,
        topEntities,
      },
      limit: topEntities.limit,
      sort: topEntities.sort,
    },
    skip: !viewId?.viewVersion,
  })

  return React.useMemo(() => {
    const liveData = data?.liveComponentData

    // Resolves the component data union type to the portion we care about
    if (liveData?.__typename === "TopEntities") {
      return { data: liveData, loading }
    }

    return { data: undefined, loading }
  }, [data?.liveComponentData, loading])
}
