import * as React from "react"
import { Route, Switch } from "react-router-dom"
import { responsiveStyles } from "@digits-shared/components/Responsive/responsiveStyles"
import { LoggedRedirect } from "@digits-shared/components/Router/LoggedRedirect"
import { ProtectedRoute } from "@digits-shared/components/Router/ProtectedRoute"
import { TOP_MARGIN } from "@digits-shared/components/UI/Elements/Container"
import useSession from "@digits-shared/hooks/useSession"
import { AspectCode } from "@digits-shared/session/SessionTypes"
import styled, { css } from "styled-components"
import { ApplicationPositioning } from "src/frontend/components/OS/Applications/ApplicationPane"
import { usePerformActivateLegalEntityAndEmployment } from "src/frontend/components/OS/Applications/hooks/useActivateLegalEntity"
import { useLegalEntityRedirects } from "src/frontend/components/OS/Applications/hooks/useLegalEntityRedirects"
import SharedWithMeApplication from "src/frontend/components/OS/Applications/SharedWithMe/SharedWithMeApplication"
import SupportApplication from "src/frontend/components/OS/Applications/Support/SupportApplication"
import { ApplicationGrid } from "src/frontend/components/OS/Home/ApplicationGrid"
import { HomeContent } from "src/frontend/components/OS/Home/HomeContent"
import { useErrorStates } from "src/frontend/components/OS/Home/LauncherErrors"
import {
  AssistantDisplay,
  useAssistantDisplay,
} from "src/frontend/components/OS/Home/useAssistantDisplay"
import { DragAndDropConnectionContext } from "src/frontend/components/Shared/Assistant/DragAndDropConnectionContext"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/useViewVersion"
import { NavSidebar } from "src/frontend/components/Shared/NavSidebar/NavSidebar"
import { DragAndDropContext } from "src/frontend/components/Shared/Portals/DragAndDrop/DragAndDropContext"
import {
  useActivateEditLayout,
  useCanActivateEditLayout,
  useIsEditLayoutActive,
} from "src/frontend/components/Shared/Portals/State/useIsEditLayoutActive"
import { Desktop } from "src/frontend/components/Shared/Responsive/Desktop"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import { useInternalUserSettings } from "src/frontend/hooks/useInternalUserSettings"
import routes from "src/frontend/routes"
import type FrontendSession from "src/frontend/session"

/*
  STYLES
*/

const wrapperResponsiveStyles = responsiveStyles({
  desktop: css``,
  mobile: css`
    width: 100%;
    // keep min width 0 to prevent the misalignment of mobile/tablet view
    min-width: 0;
  `,
})

export const ApplicationWrapper = styled.div<{ assistantDisplay?: AssistantDisplay }>`
  height: 100%;
  margin: 0 auto;

  ${({ assistantDisplay }) =>
    assistantDisplay === AssistantDisplay.Inline
      ? css`
          width: calc(var(--application-pane-width) + 360px + 24px);
          display: grid;
          grid-gap: 24px;
          grid-template-columns: 744px 360px;
        `
      : assistantDisplay !== AssistantDisplay.FullWidth
        ? css`
            width: calc(100vw - var(--sidebar-content-offset) - 48px);
            min-width: var(--application-pane-full-width);
            max-width: 1920px;
          `
        : null}

  ${wrapperResponsiveStyles};
`

const applicationStyles = responsiveStyles({
  desktop: css`
    height: 100%;
  `,
  mobile: css`
    min-height: var(--viewport-height);
  `,
})

export const Application = styled.div`
  ${applicationStyles};
  width: 100%;
  display: flex;
  flex-direction: column;
`

const InlineAssistantContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  width: 360px;
  margin-top: ${TOP_MARGIN}px;
  height: calc(100vh - ${TOP_MARGIN}px);
  position: relative;
`

/*
 COMPONENTS
*/

export const Home: React.FC<React.PropsWithChildren> = ({ children }) => {
  const assistantDisplay = useAssistantDisplay()

  const { currentLegalEntity, doppelganger } = useSession<FrontendSession>()
  const { gridOverlayEnabled } = useInternalUserSettings()

  // If the LE is not in a ready state, we will eventually see it via the error
  // state below, but we need to prevent the application content from rendering
  // while that call is loading. This is important because permissioned queries
  // against a pending LE fail.
  const leReady = currentLegalEntity?.hasDashboardAccess(doppelganger)

  const errorState = useErrorStates()

  const redirect = useLegalEntityRedirects()
  usePerformActivateLegalEntityAndEmployment(!!redirect)
  if (redirect) return redirect

  return (
    <>
      {!errorState && (
        <ApplicationPositioning assistantDisplay={assistantDisplay} id="app-positioning">
          {gridOverlayEnabled && (
            <Desktop>
              <ApplicationGrid />
            </Desktop>
          )}
          <Desktop>
            <NavSidebar />
          </Desktop>
          <ApplicationWrapper assistantDisplay={assistantDisplay}>
            <DragAndDropContext>
              <AppSwitcher>{leReady && children}</AppSwitcher>
              <Desktop>
                <InlineAssistant />
              </Desktop>
            </DragAndDropContext>
          </ApplicationWrapper>
        </ApplicationPositioning>
      )}
      {errorState}
    </>
  )
}

/* Display the assistant inline, or in the slide-out overlay style, depending on the route */
const InlineAssistant: React.FC = () => (
  <Switch>
    <Route
      path={[routes.expenses.parameterizedPath, routes.revenue.parameterizedPath]}
      component={AssistantColumn}
    />
  </Switch>
)

const AssistantChatWindow = React.lazy(() =>
  import("src/frontend/components/Shared/Assistant/ChatWindow").then((module) => ({
    default: module.AssistantChatWindow,
  }))
)
const AssistantColumn: React.FC = () => (
  <InlineAssistantContainer>
    <AssistantDragAndDropConnector>
      <React.Suspense fallback={null}>
        <AssistantChatWindow />
      </React.Suspense>
    </AssistantDragAndDropConnector>
  </InlineAssistantContainer>
)

const AssistantDragAndDropConnector: React.FC<React.PropsWithChildren> = ({ children }) => {
  const isEditLayoutActive = useIsEditLayoutActive()
  const disabled = !useCanActivateEditLayout() && !isEditLayoutActive
  const activateEditLayout = useActivateEditLayout()

  const value = React.useMemo(
    () => ({
      isEditLayoutActive,
      disabled,
      activateEditLayout,
    }),
    [activateEditLayout, disabled, isEditLayoutActive]
  )

  return (
    <DragAndDropConnectionContext.Provider value={value}>
      {children}
    </DragAndDropConnectionContext.Provider>
  )
}

const AppSwitcher: React.FC<React.PropsWithChildren> = ({ children }) => {
  const session = useSession<FrontendSession>()
  const { currentLegalEntity } = session
  const generatePath = useFrontendPathGenerator()
  const viewVersion = useViewVersion()

  const redirectTo = React.useMemo(() => {
    const leSlug = currentLegalEntity?.slug
    if (leSlug && session.hasAccessToAspect(AspectCode.PayBills)) {
      return generatePath(routes.billPay, { leSlug })
    }
    if (leSlug && session.hasAccessToAspect(AspectCode.GetPaid)) {
      return generatePath(routes.getPaid, { leSlug })
    }
    return generatePath(routes.sharedWithMe)
  }, [currentLegalEntity?.slug, generatePath, session])

  return (
    <Switch>
      <Route
        path={[routes.legalEntityHome.parameterizedPath]}
        exact
        render={() => (
          <ProtectedRoute
            component={HomeContent}
            redirectTo={redirectTo}
            requiresView={viewVersion}
          />
        )}
      />

      <Applications>{children}</Applications>
    </Switch>
  )
}

const Applications: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { currentLegalEntity } = useSession<FrontendSession>()

  return (
    <Application>
      <Switch>
        <Route
          path={[
            routes.legalEntity.parameterizedPath,
            routes.organization.parameterizedPath,
            routes.collectFunds.parameterizedPath,
          ]}
        >
          {children}
        </Route>

        <Route
          path={routes.sharedWithMe.parameterizedPath}
          render={() => (
            <ProtectedRoute
              redirectTo={routes.login.generate()}
              component={SharedWithMeApplication}
            />
          )}
        />

        {/* Check for Digits employee access are inside SupportApplication */}
        <Route
          path={[
            routes.internalDGRedirect.parameterizedPath,
            routes.internalFeatures.parameterizedPath,
          ]}
          render={() => (
            <ProtectedRoute
              redirectTo={routes.dashboard.generate()}
              component={SupportApplication}
            />
          )}
        />

        {/*
          Redirect if we load any right drawer page that doesn't match an LE route,
          this redirect will make sure the content area is on springboard launcher
        */}
        <Route
          path="*"
          render={() => (
            <LoggedRedirect
              name="LauncherAndApplications-fallThrough"
              to={
                currentLegalEntity
                  ? routes.legalEntityHome.generate({ leSlug: currentLegalEntity?.slug })
                  : routes.sharedWithMe.generate()
              }
            />
          )}
        />
      </Switch>
    </Application>
  )
}
