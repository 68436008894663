import * as React from "react"
import { StackableBarLegendColor } from "src/frontend/components/OS/Shared/Charts/StackableBarChart/Legend/StackableBarChartLegendColor"
import { StackableBarChartLegendCount } from "src/frontend/components/OS/Shared/Charts/StackableBarChart/Legend/StackableBarChartLegendCount"
import { StackableBarMiniLegend } from "src/frontend/components/OS/Shared/Charts/StackableBarChart/Legend/StackableBarChartMiniLegend"
import {
  StackableBarChartLegendType,
  useStackableBarChartContext,
} from "src/frontend/components/OS/Shared/Charts/StackableBarChart/StackableBarChartContext"

/*
 COMPONENTS
*/

export const StackableBarChartLegend: React.FC = () => {
  const { legend } = useStackableBarChartContext()

  switch (legend) {
    case StackableBarChartLegendType.Mini:
      return <StackableBarMiniLegend />
    case StackableBarChartLegendType.Count:
      return <StackableBarChartLegendCount />
    case StackableBarChartLegendType.Color:
      return <StackableBarLegendColor />
    case StackableBarChartLegendType.None:
      return null
  }
}
