import * as React from "react"
import * as ReactDOM from "react-dom"
import { useDndRoot } from "@digits-shared/hooks/useDndRoot"
import { DragOverlay, useDndContext } from "@dnd-kit/core"
import { TRASH_CAN_DROP_TARGET_ID } from "src/frontend/components/OS/Applications/ClientPortal/consts"
import { DropType } from "src/frontend/components/Shared/DragAndDrop/dragAndDropShared"
import zIndexes from "src/shared/config/zIndexes"

export const DragAndDropOverlay: React.FC<React.PropsWithChildren> = ({ children }) => {
  const dndRoot = useDndRoot()
  const { over, active } = useDndContext()
  const dropAllowed = over?.data.current?.dropAllowed
  const opacity = over?.id === TRASH_CAN_DROP_TARGET_ID ? "0.5" : undefined
  const cursor = active && dropAllowed ? "grabbing" : "not-allowed"

  const style = React.useMemo(() => ({ cursor, opacity }), [cursor, opacity])

  const [overlayKey, setKey] = React.useState<string>("")
  const dropAnimation = overlayKey === DropType.Config ? null : undefined

  React.useEffect(() => {
    if (!overlayKey && active?.data.current?.type) {
      setKey(active?.data.current?.type)
    }
  }, [active?.data, overlayKey])
  // DragOverlay must remain mounted in order for the animations to complete.
  //
  // Using a portal to place this outside the various scrolling areas is necessary
  // for it to correctly visually align and for it not to get clipped.
  return ReactDOM.createPortal(
    <DragOverlay
      key={overlayKey}
      zIndex={zIndexes.dndOverlay}
      style={style}
      dropAnimation={dropAnimation}
    >
      {children}
    </DragOverlay>,
    dndRoot
  )
}
