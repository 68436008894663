import * as React from "react"
import { useRef } from "react"
import { svgIconStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgAccounting01 } from "@digits-shared/components/SVGIcons/line/Accounting01.svg"
import { SvgBookOpen01 } from "@digits-shared/components/SVGIcons/line/BookOpen01.svg"
import { SvgBuilding02 } from "@digits-shared/components/SVGIcons/line/Building02.svg"
import { SvgMessageTextSquare02 } from "@digits-shared/components/SVGIcons/line/MessageTextSquare02.svg"
import { SvgScales02 } from "@digits-shared/components/SVGIcons/line/Scales02.svg"
import { SvgShieldTick } from "@digits-shared/components/SVGIcons/line/ShieldTick.svg"
import { SvgChevronRightSolid } from "@digits-shared/components/SVGIcons/solid/ChevronRightSolid.svg"
import { useCurrentRoute } from "@digits-shared/hooks/useCurrentRoute"
import { usePopOverState } from "@digits-shared/hooks/usePopOverState"
import useSession from "@digits-shared/hooks/useSession"
import { AspectCode } from "@digits-shared/session/SessionTypes"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import { AnimatePresence, m, type Variants } from "framer-motion"
import styled from "styled-components"
import { Application } from "src/frontend/components/OS/Applications/Application"
import { useBadgeContext } from "src/frontend/components/Shared/Contexts/BadgeContext"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/useViewVersion"
import {
  SIDEBAR_PATH_SVG_STYLES,
  SidebarDivider,
} from "src/frontend/components/Shared/NavSidebar/sidebarConstants"
import {
  Badge,
  RightColumn,
  SidebarRow,
} from "src/frontend/components/Shared/NavSidebar/SidebarExpando"
import { RequiresAspect } from "src/frontend/components/Shared/Permissions/RequiresAspect"
import { RequiresView } from "src/frontend/components/Shared/View/RequiresView"
import routes from "src/frontend/routes"
import type FrontendSession from "src/frontend/session"
import { SvgDetectedReview } from "src/shared/components/SVG/DetectedReview.svg"
import zIndexes from "src/shared/config/zIndexes"

const ACCOUNTING_ROUTES = [
  routes.accounting,
  routes.reconciliations,
  routes.transactionReview,
  routes.qualityCheck,
  routes.categoryReview,
  routes.vendorDirectory,
]

/*
  STYLES
*/

const AccountingIcon = styled(SvgAccounting01)`
  ${SIDEBAR_PATH_SVG_STYLES};
`

const LedgerIcon = styled(SvgBookOpen01)`
  ${SIDEBAR_PATH_SVG_STYLES};
`

const ReconciliationsIcon = styled(SvgScales02)`
  ${SIDEBAR_PATH_SVG_STYLES};
`

const VendorsIcon = styled(SvgBuilding02)`
  ${SIDEBAR_PATH_SVG_STYLES};
`

const TransactionReviewIcon = styled(SvgMessageTextSquare02)`
  ${SIDEBAR_PATH_SVG_STYLES};
`

const CategoryReviewIcon = styled(SvgDetectedReview)`
  ${SIDEBAR_PATH_SVG_STYLES};
`

const QualityCheckIcon = styled(SvgShieldTick)`
  ${SIDEBAR_PATH_SVG_STYLES};
`

const AccountingTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`

const MenuChevron = styled(SvgChevronRightSolid)`
  width: 16px;
  height: 16px;
  ${svgIconStyles(colors.secondary)};
`

const MenuContainer = styled(m.div)<{ top: number; left: number }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;

  position: fixed;
  left: ${({ left }) => left}px;
  top: ${({ top }) => top}px;

  border-radius: 0 16px 16px 0;
  padding: 16px 8px 16px 0;
  z-index: ${zIndexes.leftSidebarMax};
  background-color: ${colors.secondary05};
  box-shadow: 10px 0px 17px -3px ${colors.translucentBlack10};
`

const MenuHoverContainer = styled.div`
  position: relative;
  width: 100%;
`

const MenuSidebarRow = styled(SidebarRow)`
  padding: 10px 12px 10px 28px;
  width: 100%;
  margin: 0;
  border-radius: 0 8px 8px 0;

  ${Badge} {
    left: 42px;
  }

  ${RightColumn} {
    ${fonts.scale.detail};
    font-weight: ${fonts.weight.heavy};
    opacity: 1;
  }
`

const menuVariants = {
  closed: { opacity: 0, x: -100 },
  showing: { opacity: 1, transition: { delay: 0.1, duration: 0.3, ease: "easeOut" } },
  hiding: { opacity: 0, transition: { duration: 0.3, ease: "easeIn" } },
  opening: { x: 0, transition: { duration: 0.3, ease: "easeOut" } },
  closing: { x: -50, transition: { delay: 0.1, duration: 0.3, ease: "easeIn" } },
} satisfies Variants

/*
  COMPONENT
*/

export const AccountingSidebar: React.FC = () => {
  const session = useSession<FrontendSession>()
  const { currentLegalEntity } = session
  const params = { leSlug: currentLegalEntity?.slug }

  const viewVersion = useViewVersion()
  const currentRoute = useCurrentRoute(routes)
  const { badgeCount } = useBadgeContext()
  const menuContainerRef = useRef<HTMLDivElement>(null)

  const { isPopOverOpen: isMenuOpen, onMouseEnter, onMouseLeave, hidePopOver } = usePopOverState()

  const isAccountingApplicationActive = React.useMemo(
    () => ACCOUNTING_ROUTES.some((r) => r.isRouteOrChildOfRoute(currentRoute)),
    [currentRoute]
  )

  const qcCount = badgeCount(currentLegalEntity.id, Application.QualityCheck.name) || 0
  const trCount = badgeCount(currentLegalEntity.id, Application.TransactionReview.name) || 0

  const showAudit = session.hasAccessToAspect(AspectCode.Audit)
  const showAccounting = session.hasAccessToAspect(AspectCode.AIBookkeeper)
  const showVendors = session.hasAccessToAspect(AspectCode.AIBookkeeper)

  const { top, right } = menuContainerRef.current?.getBoundingClientRect() || {
    top: 0,
    right: 0,
  }

  if (!showAudit && !showAccounting) return null

  // If an accounting application is open, show all menu items in the sidebar for easy navigation
  // For users that don't have AI bookkeeper access, always show the options expanded
  if (!isAccountingApplicationActive && showAccounting && window.innerWidth >= 1550) {
    return (
      <RequiresView view={viewVersion}>
        <SidebarDivider />
        <MenuHoverContainer
          ref={menuContainerRef}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <SidebarRow
            Icon={AccountingIcon}
            route={routes.accounting}
            params={params}
            badge={Boolean(trCount + qcCount)}
            onClick={hidePopOver}
          >
            <AccountingTitleContainer>
              Accounting
              <MenuChevron />
            </AccountingTitleContainer>
          </SidebarRow>

          {/* The right of the Accounting row is the left for the menu */}
          <AccountingMenu showMenu={isMenuOpen} top={top} left={right} hideMenu={hidePopOver} />
        </MenuHoverContainer>
      </RequiresView>
    )
  }

  return (
    <RequiresView view={viewVersion}>
      {(showAudit || showAccounting) && <SidebarDivider />}

      <RequiresAspect aspect={AspectCode.AIBookkeeper}>
        <>
          <SidebarRow Icon={LedgerIcon} route={routes.accounting} params={params}>
            Ledger
          </SidebarRow>

          <SidebarRow Icon={ReconciliationsIcon} route={routes.reconciliations} params={params}>
            Reconciliations
          </SidebarRow>

          {showVendors && (
            <SidebarRow Icon={VendorsIcon} route={routes.vendorDirectory} params={params}>
              Vendors
            </SidebarRow>
          )}
        </>
      </RequiresAspect>

      <RequiresAspect aspect={AspectCode.Audit}>
        <>
          <SidebarRow
            Icon={TransactionReviewIcon}
            route={routes.transactionReview}
            params={params}
            badge={Boolean(trCount)}
          >
            Transaction Review
          </SidebarRow>

          <SidebarRow
            Icon={QualityCheckIcon}
            route={routes.qualityCheck}
            params={params}
            badge={Boolean(qcCount)}
          >
            Quality Check
          </SidebarRow>

          {session.isDigitsEmployee && (
            <SidebarRow Icon={CategoryReviewIcon} route={routes.categoryReview} params={params}>
              Review
            </SidebarRow>
          )}
        </>
      </RequiresAspect>
    </RequiresView>
  )
}

const AccountingMenu: React.FC<{
  className?: string
  hideMenu: () => void
  top: number
  left: number
  showMenu: boolean
}> = ({ className, top, left, hideMenu, showMenu }) => {
  const { isDigitsEmployee, currentLegalEntity } = useSession<FrontendSession>()

  const params = { leSlug: currentLegalEntity?.slug }
  const { badgeCount } = useBadgeContext()

  const qcCount = badgeCount(currentLegalEntity.id, Application.QualityCheck.name) || 0
  const trCount = badgeCount(currentLegalEntity.id, Application.TransactionReview.name) || 0

  return (
    <AnimatePresence>
      {showMenu && (
        <MenuContainer
          className={className}
          top={top}
          left={left}
          onClick={hideMenu}
          variants={menuVariants}
          initial="closed"
          animate={["opening", "showing"]}
          exit={["closing", "hiding"]}
        >
          <RequiresAspect aspect={AspectCode.AIBookkeeper}>
            <>
              <MenuSidebarRow Icon={LedgerIcon} route={routes.accounting} params={params}>
                Ledger
              </MenuSidebarRow>

              <MenuSidebarRow
                Icon={ReconciliationsIcon}
                route={routes.reconciliations}
                params={params}
              >
                Reconciliations
              </MenuSidebarRow>

              <MenuSidebarRow Icon={VendorsIcon} route={routes.vendorDirectory} params={params}>
                Vendors
              </MenuSidebarRow>
            </>
          </RequiresAspect>

          <RequiresAspect aspect={AspectCode.Audit}>
            <>
              <MenuSidebarRow
                Icon={TransactionReviewIcon}
                route={routes.transactionReview}
                params={params}
                badge={Boolean(trCount)}
              >
                Transaction Review
              </MenuSidebarRow>

              <MenuSidebarRow
                Icon={QualityCheckIcon}
                route={routes.qualityCheck}
                params={params}
                badge={Boolean(qcCount)}
              >
                Quality Check
              </MenuSidebarRow>

              {isDigitsEmployee && (
                <MenuSidebarRow
                  Icon={CategoryReviewIcon}
                  route={routes.categoryReview}
                  params={params}
                >
                  Review
                </MenuSidebarRow>
              )}
            </>
          </RequiresAspect>
        </MenuContainer>
      )}
    </AnimatePresence>
  )
}
