import * as React from "react"
import { AffiliateType, useListAffiliatesQuery } from "@digits-graphql/frontend/graphql-bearer"
import useSession from "@digits-shared/hooks/useSession"
import { JWTPermissionFlag } from "@digits-shared/session/jwt/jwtPermissions"
import { useHasPermission } from "src/frontend/components/Shared/Permissions/Requires"
import type FrontendSession from "src/frontend/session"
import {
  FrontendPermissionModule,
  FrontendPermissionSource,
} from "src/frontend/session/permissionModule"

export function useCurrentAffiliation() {
  const { currentLegalEntity, currentAffiliation, currentOrganization } =
    useSession<FrontendSession>()
  const legalEntity = currentAffiliation?.entity || currentLegalEntity

  const hasAffiliatePermission = useHasPermission({
    source: FrontendPermissionSource.LegalEntity,
    module: FrontendPermissionModule.LegalEntities,
    flag: JWTPermissionFlag.Read,
  })

  const { data } = useListAffiliatesQuery({
    variables: {
      legalEntityId: legalEntity?.id || "",
      affiliateType: AffiliateType.Accounting,
    },
    skip: !legalEntity || !hasAffiliatePermission,
  })

  const accountingFirms = data?.listAffiliates.organizations

  return React.useMemo(
    () =>
      accountingFirms?.length
        ? accountingFirms.find((af) => af.organization.id === currentOrganization?.id) ||
          accountingFirms[0]
        : undefined,
    [accountingFirms, currentOrganization]
  )
}
