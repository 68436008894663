/*
  INTERFACES
 */

export const INITIAL_STATE: TextComponentState = {
  posting: false,
  editing: false,
  unsavedChanges: false,
}

/*
  ACTIONS
*/

export interface TextComponentState {
  posting: boolean
  editing: boolean
  unsavedChanges: boolean
}

export type TextComponentAction =
  | {
      type: "SET_POSTING" | "SET_EDITING" | "SET_UNSAVED_CHANGES"
      value: boolean
    }
  | { type: "RESET" }

/*
  REDUCER
*/
export function textComponentReducer(
  curState: TextComponentState,
  action: TextComponentAction
): TextComponentState {
  switch (action.type) {
    case "SET_POSTING":
      return {
        ...curState,
        posting: action.value,
      }
    case "SET_EDITING":
      return {
        ...curState,
        editing: action.value,
      }
    case "SET_UNSAVED_CHANGES":
      return {
        ...curState,
        unsavedChanges: action.value,
      }
    case "RESET":
      return { ...INITIAL_STATE }
  }
}
