import * as React from "react"
import { type LayoutComponentConfig } from "@digits-graphql/frontend/graphql-bearer"
import { themedStyles } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import { useDndContext } from "@dnd-kit/core"
import styled, { css } from "styled-components"
import {
  ComponentConfigWrapper,
  ConfigComponentContainer,
} from "src/frontend/components/Shared/Layout/Shared"
import { MatchConfig } from "src/frontend/components/Shared/Portals/ConfigsSidebar/MatchConfig"
import {
  usePortalDispatch,
  usePortalStore,
} from "src/frontend/components/Shared/Portals/State/portalStore"
import { activeComponentSelector } from "src/frontend/components/Shared/Portals/State/selectors"
import { type PortalState } from "src/frontend/components/Shared/Portals/State/types"

/**
 * Hook that updates the config drag overlay.
 */
export function useUpdateConfigDragOverlay(isOver: boolean) {
  const builderDispatch = usePortalDispatch()
  const activeComponent = usePortalStore(activeComponentSelector)
  const activeConfig = usePortalStore((state: PortalState) => state.activeConfig)
  const setConfigDragOverlay = useSetConfigDragOverlay()

  React.useEffect(() => {
    // Avoid updating the drag overlay to be a config when what is being dragged is a component.
    // (In that case, activeConfig is null)
    if (isOver && activeComponent && activeConfig) {
      setConfigDragOverlay(activeComponent.config)
    }
  }, [activeComponent, activeConfig, builderDispatch, isOver, setConfigDragOverlay])
}

const ComponentConfigOverlayWrapper = styled(ConfigComponentContainer)`
  ${themedStyles({
    light: css`
      background: ${colors.white};
    `,
    dark: undefined,
  })}
`

/**
 * Helper hook which returns a function that can be used to set the drag overlay for a config.
 */
export function useSetConfigDragOverlay() {
  const portalDispatch = usePortalDispatch()
  const dndContext = useDndContext()

  return React.useCallback(
    (config: LayoutComponentConfig) => {
      portalDispatch({
        type: "setDragOverlayContent",
        content: (
          <ComponentConfigWrapper
            ref={dndContext.dragOverlay.setRef}
            isOverlay
            css="pointer-events: none;"
          >
            <ComponentConfigOverlayWrapper>
              <MatchConfig config={config} skipAnimations />
            </ComponentConfigOverlayWrapper>
          </ComponentConfigWrapper>
        ),
      })
    },
    [portalDispatch, dndContext.dragOverlay.setRef]
  )
}
