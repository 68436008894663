import * as React from "react"

export interface DragAndDropConnectionValue {
  isEditLayoutActive: boolean
  disabled: boolean
  activateEditLayout: () => void
}

export const DragAndDropConnectionContext = React.createContext<DragAndDropConnectionValue>({
  isEditLayoutActive: false,
  disabled: false,
  activateEditLayout: () => {},
})

export function useDragAndDropConnectionContext() {
  return React.useContext(DragAndDropConnectionContext)
}
