export enum FileType {
  PDF = "application/pdf",
  DOC = "application/msword",
  DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  XLS = "application/vnd.ms-excel",
  XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  CSV = "text/csv",
  TEXT = "text/plain",
  DIGITS = "application/x-digits-report",
  HEIC = "image/heic",
  JPEG = "image/jpeg",
  JSON = "application/json",
  PNG = "image/png",
  TIFF = "image/tiff",
  ZIP = "application/zip",
  Unknown = "application/octet-stream",
}

export const PDF_FILETYPES = [FileType.PDF] as const
export const WORD_FILETYPES = [FileType.DOC, FileType.DOCX] as const
export const EXCEL_FILETYPES = [FileType.XLS, FileType.XLSX] as const
export const IMAGE_FILETYPES = [FileType.PNG, FileType.JPEG, FileType.TIFF, FileType.HEIC] as const

export type FileSizeUnit = "B" | "KB" | "MB" | "GB" | "TB"

interface FileSize {
  size: number
  unit: FileSizeUnit
}

const SIZE_UNITS: FileSizeUnit[] = ["B", "KB", "MB", "GB", "TB"]

const FileHelper = {
  size(bytes: number): FileSize {
    const exp = bytes === 0 ? 0 : Math.floor(Math.log(bytes) / Math.log(1024))
    return {
      size: parseFloat((bytes / 1024 ** exp).toFixed(1)),
      unit: SIZE_UNITS[exp] as FileSize["unit"],
    }
  },

  formatBytes(bytes: number, space?: boolean) {
    const fileSize = FileHelper.size(bytes)
    return `${fileSize.size}${space ? " " : ""}${fileSize.unit}`
  },

  fileTypeDisplayName(mimeType: string) {
    const entry = Object.entries(FileType).find(([k, v]) => mimeType === v)
    return entry?.[0] || "Unknown"
  },

  fileTypeForMimeType(mimeType: string) {
    const entry = Object.entries(FileType).find(([k, v]) => mimeType === v)
    return entry?.[1] || undefined
  },
}

export default FileHelper
