import * as React from "react"

/*
  CONTEXT
*/

interface ActivePopOver {
  id: string
  closePopOver: () => void
  pinned: boolean
}

interface PopOverMutexContextVal {
  activePopOver: React.RefObject<ActivePopOver | undefined>
  setActivePopOver: (instance: ActivePopOver | undefined) => void
  clearActivePopOver: () => void
  pinActivePopOver: () => void
  unpinActivePopOver: () => void
}

export const PopOverMutexContext = React.createContext<PopOverMutexContextVal>({
  activePopOver: React.createRef(),
  setActivePopOver: () => {},
  clearActivePopOver: () => {},
  pinActivePopOver: () => {},
  unpinActivePopOver: () => {},
})

/*
  HOOKS
*/

export function usePopoverMutexContext() {
  return React.useContext(PopOverMutexContext)
}

/*
  COMPONENTS
*/

export const PopoverMutexContextProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  // use Refs instead of State to avoid re-renders
  const activePopOver = React.useRef<ActivePopOver>()

  const setActivePopOver = React.useCallback((instance: ActivePopOver | undefined) => {
    // Close existing one
    activePopOver.current?.closePopOver()

    activePopOver.current = instance
  }, [])

  const clearActivePopOver = React.useCallback(() => {
    setActivePopOver(undefined)
  }, [setActivePopOver])

  const pinActivePopOver = React.useCallback(() => {
    if (!activePopOver.current) return
    activePopOver.current = { ...activePopOver.current, pinned: true }
  }, [])

  const unpinActivePopOver = React.useCallback(() => {
    if (!activePopOver.current) return
    activePopOver.current = { ...activePopOver.current, pinned: false }
  }, [])

  const value = React.useMemo(
    () => ({
      activePopOver,
      setActivePopOver,
      clearActivePopOver,
      pinActivePopOver,
      unpinActivePopOver,
    }),
    [setActivePopOver, clearActivePopOver, pinActivePopOver, unpinActivePopOver]
  )

  return <PopOverMutexContext.Provider value={value}>{children}</PopOverMutexContext.Provider>
}
