import * as React from "react"
import { DigitsButton } from "@digits-shared/DesignSystem/Button"
import stringHelper from "@digits-shared/helpers/stringHelper"
import styled from "styled-components"
import {
  DisplayOptionsColumn,
  DisplayOptionsColumnTitle,
} from "src/frontend/components/OS/Shared/DisplayOptions/Shared"

/*
  STYLES
*/

const DisplayOptionsColumnStretched = styled(DisplayOptionsColumn)`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 30px;
  gap: 8px;
`

const FilterButton = styled(DigitsButton)`
  margin: 5px 0 0 3px;
`

/*
  COMPONENTS
*/

interface Props {
  onResetClick: () => void
  currentFiltersCount: number
  children?: React.ReactNode
}

export const FilterColumn: React.FC<Props> = ({ onResetClick, currentFiltersCount, children }) => (
  <DisplayOptionsColumnStretched>
    <DisplayOptionsColumnTitle>Filter By</DisplayOptionsColumnTitle>
    {children}
    {!!currentFiltersCount && (
      <FilterButton size="small" onClick={onResetClick} $variant="ghost-dark">
        Clear {currentFiltersCount}{" "}
        {stringHelper.pluralize(currentFiltersCount, "Filter", "Filters")}
      </FilterButton>
    )}
  </DisplayOptionsColumnStretched>
)
