import * as React from "react"
import { useEvent } from "react-use"
import { Interval, type IntervalOrigin, type Period } from "@digits-graphql/frontend/graphql-bearer"
import { Keys } from "@digits-shared/components/UI/Elements/Keys"
import { DigitsButton } from "@digits-shared/DesignSystem/Button"
import { type TimeRange } from "@digits-shared/helpers/dateTimeHelper"
import useForwardedRef from "@digits-shared/hooks/useForwardedRef"
import { useOnBodyClick } from "@digits-shared/hooks/useOnBodyClick"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"
import {
  DateRangeContextProvider,
  useDateRangeContext,
} from "src/shared/components/DateRangeSelector/DateRangeContext"
import {
  EndDateOption,
  FromSelector,
  IntervalSelector,
} from "src/shared/components/DateRangeSelector/DateRangeOptions"
import {
  DateRangePortal,
  useDateRangePortalState,
} from "src/shared/components/DateRangeSelector/DateRangePortal"
import { DateRangePresets } from "src/shared/components/DateRangeSelector/DateRangePresets"
import { DayRangePicker } from "src/shared/components/DateRangeSelector/DayRangePicker"
import customKeyframes from "src/shared/config/customKeyframes"
import zIndexes from "src/shared/config/zIndexes"

const DateRangeSelectorContainer = styled.div`
  border-radius: 24px;
  background: #f4f6f9;
  border: 1px solid ${colors.white};
  box-shadow: 0 0 24.337px rgba(139, 139, 139, 0.25);

  position: absolute;
  z-index: ${zIndexes.pageHeaderDropdown};
  display: flex;
  max-height: 450px;

  font-size: 14px;
  font-weight: ${fonts.weight.medium};
  line-height: normal;
  text-transform: capitalize;
  text-align: left;

  opacity: 0;
  animation: ${customKeyframes.fadeIn} 250ms 200ms forwards;
`

const RangeSelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 23px;
  border-radius: 0 24px 24px 0;
`

const RangeContainer = styled.div<{ $showIntervals: boolean }>`
  display: flex;
  flex: 1;
  gap: 38px;
  overflow: hidden;
  margin: 22px 0;
  ${({ $showIntervals }) =>
    !$showIntervals &&
    css`
      margin-top: 0;
    `}
`

const Buttons = styled.div`
  display: flex;
  gap: 12px;
  justify-content: flex-end;
`

interface DateRangeProps {
  positionRef: React.RefObject<HTMLElement>
  className?: string
  defaultValue?: Period | IntervalOrigin
  onChange?: (timeRange: TimeRange) => void
  onTimeRange: (timeRange: TimeRange) => void
  onCancel: () => void
  includeDays?: boolean
  includeMonths?: boolean
  includeQuarters?: boolean
  includeYears?: boolean
  singleInterval?: boolean
  showPresets?: boolean
}

export const DateRangeSelector: React.FC<DateRangeProps> = ({
  positionRef,
  defaultValue,
  onChange,
  onTimeRange,
  onCancel,
  className,
  includeDays,
  includeMonths,
  includeQuarters,
  includeYears,
  singleInterval,
  showPresets,
}) => {
  const { selectorRef, position } = useDateRangePortalState(positionRef)
  return (
    <DateRangePortal position={position}>
      <DateRangeContextProvider
        defaultValue={defaultValue}
        onTimeRange={onTimeRange}
        onCancel={onCancel}
        includeDays={includeDays}
        includeMonths={includeMonths}
        includeQuarters={includeQuarters}
        includeYears={includeYears}
        singleInterval={singleInterval}
      >
        <DateRange
          ref={selectorRef}
          className={className}
          onChange={onChange}
          showPresets={showPresets}
        />
      </DateRangeContextProvider>
    </DateRangePortal>
  )
}

const DateRange = React.forwardRef<
  HTMLDivElement,
  { className?: string; onChange?: (timeRange: TimeRange) => void; showPresets?: boolean }
>(({ className, onChange, showPresets = true }, ref) => {
  const forwardRef = useForwardedRef(ref)
  const {
    dateRangeState: { timeRange },
    onCancelClick,
  } = useDateRangeContext()

  // notify caller of each time change
  React.useEffect(() => {
    onChange?.(timeRange)
  }, [timeRange, timeRange.startedAt, timeRange.endedAt, timeRange.interval, onChange])

  const handleKeyDown = React.useCallback(
    (event: KeyboardEvent) => {
      if (event.key === Keys.Escape) onCancelClick()
    },
    [onCancelClick]
  )
  useEvent("keydown", handleKeyDown, document, { capture: false })
  useOnBodyClick(forwardRef, onCancelClick)

  return (
    <DateRangeSelectorContainer ref={forwardRef} className={className}>
      {showPresets && <DateRangePresets />}
      <RangeSelector />
    </DateRangeSelectorContainer>
  )
})

const RangeSelector: React.FC = () => {
  const { onSetClick, onCancelClick } = useDateRangeContext()
  return (
    <RangeSelectorContainer>
      <IntervalSelector />
      <RangeOptions />
      <Buttons>
        <DigitsButton $variant="secondary-dark" onClick={onCancelClick}>
          Cancel
        </DigitsButton>
        <DigitsButton onClick={onSetClick}>Set</DigitsButton>
      </Buttons>
    </RangeSelectorContainer>
  )
}

const RangeOptions: React.FC = () => {
  const {
    dateRangeState: {
      timeRange: { interval },
    },
    singleInterval,
    showIntervals,
  } = useDateRangeContext()
  switch (interval) {
    case Interval.Day:
      return (
        <RangeContainer $showIntervals={showIntervals}>
          <DayRangePicker />
        </RangeContainer>
      )

    default:
      return (
        <RangeContainer $showIntervals={showIntervals}>
          <FromSelector />
          {!singleInterval && <EndDateOption />}
        </RangeContainer>
      )
  }
}
