import * as React from "react"
import { type Contact } from "@digits-graphql/frontend/graphql-public"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgCheckCircleBroken } from "@digits-shared/components/SVGIcons/line/CheckCircleBroken.svg"
import { SvgTrash01 } from "@digits-shared/components/SVGIcons/line/Trash01.svg"
import { ColorIcon } from "@digits-shared/components/UI/Elements/ColorIcon"
import userHelper from "@digits-shared/helpers/userHelper"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import { AnimatePresence, m, type Variants } from "framer-motion"
import styled, { css } from "styled-components"
import { type ContactWithStatus } from "src/frontend/components/OS/Applications/ClientPortal/InviteFlow/State/types"

/*
  STYLES
*/

const ClientInfo = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
`

const ClientName = styled.div`
  font-size: 14px;
  font-weight: ${fonts.weight.medium};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const ClientEmail = styled.div`
  font-size: 12px;
  color: ${colors.secondary60};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const DeleteIcon = styled.div`
  margin-left: auto;
  display: none;

  opacity: 0.6;
  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`

const Wrapper = styled.div<{ readOnly: boolean }>`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding: 0 14px;
  border: 0.5px solid transparent;
  background: ${colors.translucentWhite40};
  border-radius: 10px;
  gap: 8px;
  margin: 4px 0;
  height: 50px;

  &:hover {
    ${DeleteIcon} {
      display: revert;
    }
  }

  transition: border 200ms ease-in-out;

  ${({ readOnly }) =>
    readOnly &&
    css`
      ${ClientName} {
        color: ${colors.secondary70};
      }
      &:hover {
        cursor: default;
      }
    `};
`

const TrashIcon = styled(SvgTrash01)`
  ${svgPathStyles(colors.secondary, 1.5)};
  height: 15px;
  width: 15px;
`

const Checkmark = styled(SvgCheckCircleBroken)`
  height: 20px;
  width: 20px;
  ${svgPathStyles("#71f5bb", 1.5)};
`

const SentStatus = styled(m.div)`
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 6px;
  text-transform: uppercase;
  font-size: 13px;
  color: ${colors.secondary};
  font-weight: ${fonts.weight.heavy};
`

const variants = {
  exiting: { opacity: 0, scale: 0, transition: { type: "spring", damping: 15, stiffness: 200 } },
  entering: { opacity: 1, scale: 1, transition: { type: "spring", damping: 15, stiffness: 200 } },
} satisfies Variants

/*
  COMPONENTS
*/

export const ClientRow: React.FC<{
  client: ContactWithStatus
  readOnly: boolean
  showSentStatus: boolean
  className?: string
  deleteClient?: (client: Contact) => void
}> = ({ client, deleteClient, className, readOnly, showSentStatus }) => {
  const deleteCurrentClient = React.useCallback(() => {
    deleteClient?.(client)
  }, [deleteClient, client])

  const displayName = userHelper.displayName(client)

  return (
    <Wrapper className={className} readOnly={readOnly}>
      <ColorIcon imageUrl={client.avatarUrl} fallbackText={displayName} />
      <ClientInfo>
        <ClientName>{displayName}</ClientName>
        <ClientEmail>{client.emailAddress}</ClientEmail>
      </ClientInfo>

      {!readOnly && (
        <DeleteIcon onClick={deleteCurrentClient}>
          <TrashIcon />
        </DeleteIcon>
      )}

      <AnimatePresence>
        {showSentStatus && (
          <SentStatus variants={variants} initial="exiting" animate="entering" exit="exiting">
            Sent!
            <Checkmark />
          </SentStatus>
        )}
      </AnimatePresence>
    </Wrapper>
  )
}
