import * as React from "react"
import useSession from "@digits-shared/hooks/useSession"
import type { AspectCode } from "@digits-shared/session/SessionTypes"
import type FrontendSession from "src/frontend/session"

type RequireAspect = { aspect: AspectCode }
type RequireAspects = { aspects: AspectCode[] }
type RequiresProps = React.PropsWithChildren<RequireAspect | RequireAspects>

function isSingleAspect(props: RequireAspect | RequireAspects): props is RequireAspect {
  return (props as RequireAspect).aspect !== undefined
}

export const RequiresAspect: React.FC<RequiresProps> = (props) => {
  const { children } = props
  const session = useSession<FrontendSession>()
  const { currentLegalEntity } = session

  const hasAspects = React.useMemo(
    () => {
      const aspects = isSingleAspect(props) ? [props.aspect] : props.aspects
      return aspects.every((a) => session.hasAccessToAspect(a))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props, currentLegalEntity?.id]
  )

  if (!hasAspects) return null

  return <>{children}</>
}
