import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"

export const DISPLAY_OPTIONS_FILTER_LOADING_TEXT = "LOADING…"

export const DisplayOptionsColumn = styled.div`
  display: flex;
  flex-direction: column;
`

export const DisplayOptionsColumnTitle = styled.div`
  font-size: 11px;
  font-weight: ${fonts.weight.medium};
  text-transform: uppercase;
  justify-content: flex-start;
  margin-bottom: 4px;
  margin-left: 2px;
`
