import React from "react"
import {
  type EntityCategory,
  type EntityParty,
  Interval,
  type IntervalOrigin,
} from "@digits-graphql/frontend/graphql-bearer"
import { useReportComponentIntervalOrigin } from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/hooks/useReportComponentIntervalOrigin"
import { useEntityLink } from "src/shared/components/ObjectEntities/useEntityLink"

export function useReportEntityLink(entity: EntityParty | EntityCategory | undefined) {
  const origin = useLinkOrigin()
  const link = useEntityLink(entity, origin)
  if ((entity as EntityCategory)?.displayKey === "RetainedEarnings") {
    return undefined
  }
  return link
}

function useLinkOrigin(): IntervalOrigin {
  const origin = useReportComponentIntervalOrigin()
  const { intervalCount, interval, year, index } = origin

  return React.useMemo(() => {
    if (intervalCount !== null && intervalCount !== undefined && intervalCount > 1) {
      return { intervalCount, interval, year, index }
    }

    switch (interval) {
      case Interval.Month:
        return { interval, year, index, intervalCount: 12 }
      case Interval.Year:
        return { interval, year, index, intervalCount: 10 }
      default:
        return { intervalCount, interval, year, index }
    }
  }, [index, interval, intervalCount, year])
}
