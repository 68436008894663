// Needs to be in its own file due to an import problem with webpack
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"

export const PARTY_HOVER_WIDTH = 425

export const PartyDetail = styled.div`
  opacity: 0.7;
  font-weight: ${fonts.weight.medium};
  font-size: 11px;
  display: inline-block;
  word-break: break-word;

  &:hover {
    text-decoration: underline;
  }
`

export const PartyLink = styled.a`
  display: flex;
  align-items: center;
  color: inherit;

  &:hover {
    text-decoration: underline;
  }
`
