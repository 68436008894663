import * as React from "react"
import { useListOrganizationAffiliationsQuery } from "@digits-graphql/frontend/graphql-bearer"
import useSession from "@digits-shared/hooks/useSession"
import { type AffiliationEntity } from "src/frontend/components/OS/Applications/AccountantSettings/Affiliations/Shared"
import type FrontendSession from "src/frontend/session"
import { FrontendPermissionModule } from "src/frontend/session/permissionModule"

export function useAvailableAffiliationEntities() {
  const { currentOrganization, isAffiliatedSession } = useSession<FrontendSession>()
  const hasAffiliationsPermission = currentOrganization?.permissions.hasReadPermission(
    FrontendPermissionModule.Affiliations
  )

  const { data, loading, error } = useListOrganizationAffiliationsQuery({
    variables: {
      id: currentOrganization?.id || "",
    },
    skip: !isAffiliatedSession || !hasAffiliationsPermission,
  })

  const availableAffiliationEntities: AffiliationEntity[] = React.useMemo(
    () =>
      (hasAffiliationsPermission
        ? data?.listOrganizationAffiliations
        : currentOrganization?.affiliations?.map((sa) => ({
            legalEntity: sa.entity,
            organization: sa.organization,
          }))) || [],
    [
      currentOrganization?.affiliations,
      hasAffiliationsPermission,
      data?.listOrganizationAffiliations,
    ]
  )

  return React.useMemo(
    () => ({
      loading,
      error,
      data: availableAffiliationEntities,
    }),
    [availableAffiliationEntities, loading, error]
  )
}
