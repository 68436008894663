import * as React from "react"

// A modified useEffect hook that only runs once.
//
// TLDR; Use this instead of react-use useEffectOnce
//
// react-use will not check if the effect callback has been called,
// and it relied on old React v17 behavior where if there are no dependents
// it won't be call again. In React v18 (strict mode) an effect can be called
// multiple times irrespective of dependents.
// https://github.com/streamich/react-use/blob/master/src/useEffectOnce.ts
// https://github.com/streamich/react-use/pull/2476
export const useEffectOnce = (effect: React.EffectCallback) => {
  const runOnce = React.useRef(false)
  React.useEffect(() => {
    if (runOnce.current) return
    runOnce.current = true
    return effect()
  })
}
