import { type ObjectEntities, type Thread } from "@digits-graphql/frontend/graphql-bearer"
import { type PopUpCoordinates } from "@digits-shared/components/UI/Elements/PopUp/PopUp"

export const REPORT_TOP_LEVEL_THREAD = "com.digits.report.thread"
export const RESOLVED_REPORT_TOP_LEVEL_THREAD = `${REPORT_TOP_LEVEL_THREAD}.resolved`
export const SECTION_ID = "com.digits.section.id"

export interface ReportCommentsState {
  initialized?: string
  registry: CommentsRegistry
  activeContextId?: string
  entities?: ObjectEntities
}

export interface ThreadDeeplink {
  threadId?: string
  commentId?: string
  newComment?: boolean
}

export interface RegistryEntry {
  context: string
  thread?: Thread
  targetCoordinates?: PopUpCoordinates
  resolved: boolean
}

export type RegistryEntryWithThread = Omit<RegistryEntry, "thread"> &
  Pick<Required<RegistryEntry>, "thread">

export interface CommentsRegistry {
  // Key - context path
  // Value - RegistryEntry
  [key: string]: RegistryEntry
}
