/*
  INTERFACES
 */
export enum OnboardingStep {
  None = "None",
  SignIn = "SignIn",
  EmailSignIn = "EmailSignIn",
  MagicLinkSent = "MagicLinkSent",
  MagicLinkExpired = "MagicLinkExpired",
  PasswordlessInviteAccepted = "PasswordlessInviteAccepted",
  MagicLinkLoading = "MagicLinkLoading",
  SignUp = "SignUp",
  EmailSignUp = "EmailSignUp",
  ForgotPassword = "ForgotPassword",
  PasswordResetSent = "PasswordResetSent",
  ResetPassword = "ResetPassword",
  VerifyEmail = "VerifyEmail",
  CreatePassword = "CreatePassword",
  AcceptTerms = "AcceptTerms",
  SingleSignOnExpired = "SingleSignOnExpired",

  UserAlreadyExists = "UserAlreadyExists",
}

export enum EmailSignInField {
  EmailAddress = "emailAddress",
  Password = "password",
}

export enum EmailSignUpField {
  GivenName = "givenName",
  FamilyName = "familyName",
  EmailAddress = "emailAddress",
}

export enum ForgotPasswordField {
  EmailAddress = "emailAddress",
}

// Shared between CreatePassword and ResetPassword
export enum SetPasswordField {
  Password = "password",
  ConfirmPassword = "confirmPassword",
}

export enum MagicLinkSignInField {
  EmailAddress = "emailAddress",
}

export type OnboardingField =
  | EmailSignInField
  | EmailSignUpField
  | ForgotPasswordField
  | SetPasswordField
  | MagicLinkSignInField

export interface OnboardingState {
  step: OnboardingStep
  back: boolean
  loading: boolean
  blockLoginRedirect: boolean
  errorText?: string
  token?: string
  emailAddress?: string
  redirectPath?: string
  invitationToken?: string
}

export const INITIAL_STATE: OnboardingState = {
  step: OnboardingStep.None,
  back: false,
  loading: false,
  blockLoginRedirect: false,
}

/*
  ACTIONS
*/

export type OnboardingAction =
  | {
      type:
        | "SHOW_SIGN_IN"
        | "SHOW_SIGN_UP"
        | "SHOW_EMAIL_SIGN_UP"
        | "SHOW_FORGOT_PASSWORD"
        | "SHOW_PASSWORD_RESET_SENT"
        | "SHOW_RESET_PASSWORD"
        | "SHOW_VERIFY_EMAIL"
        | "SHOW_CREATE_PASSWORD"
        | "SHOW_MAGIC_LINK_LOADING"
        | "BLOCK_LOGIN_REDIRECT"
        | "BACK"
    }
  | {
      type: "SET_LOADING"
      loading: boolean
    }
  | {
      type: "SET_ERROR_TEXT"
      text: string | undefined
    }
  | {
      type: "SET_TOKEN"
      token: string | undefined
    }
  | {
      type: "SHOW_EMAIL_SIGN_IN"
      emailAddress?: string
      redirectPath?: string
    }
  | {
      type: "SHOW_MAGIC_LINK_EXPIRED"
      emailAddress: string
      redirectPath: string
    }
  | {
      type: "SHOW_PASSWORDLESS_INVITE_ACCEPTED"
      invitationToken: string
      redirectPath?: string
    }
  | {
      type: "SHOW_MAGIC_LINK_SENT"
      emailAddress?: string
      redirectPath?: string
    }
  | {
      type: "USER_ALREADY_EXISTS"
      emailAddress?: string
      redirectPath?: string
    }
  | {
      type: "SHOW_SINGLE_SIGN_ON_EXPIRED"
      emailAddress: string
    }

// Actions that should trigger form fields to be reset
export const RESET_FORM_ACTION_TYPES = new Set<OnboardingAction["type"]>([
  "SHOW_SIGN_IN",
  "SHOW_EMAIL_SIGN_IN",
  "SHOW_SIGN_UP",
  "SHOW_EMAIL_SIGN_UP",
  "SHOW_FORGOT_PASSWORD",
  "SHOW_PASSWORD_RESET_SENT",
  "SHOW_RESET_PASSWORD",
  "SHOW_CREATE_PASSWORD",
])

/*
  REDUCER
*/
export function reducer(curState: OnboardingState, action: OnboardingAction): OnboardingState {
  switch (action.type) {
    case "SHOW_SIGN_IN":
      return goToStep(curState, OnboardingStep.SignIn)
    case "SHOW_EMAIL_SIGN_IN":
      return goToStep(
        {
          ...curState,
          emailAddress: action.emailAddress,
          redirectPath: action.redirectPath,
        },
        OnboardingStep.EmailSignIn
      )
    case "SHOW_SIGN_UP":
      return goToStep(curState, OnboardingStep.SignUp)
    case "SHOW_EMAIL_SIGN_UP":
      return goToStep(curState, OnboardingStep.EmailSignUp)
    case "SHOW_FORGOT_PASSWORD":
      return goToStep(curState, OnboardingStep.ForgotPassword)
    case "SHOW_PASSWORD_RESET_SENT":
      return goToStep(curState, OnboardingStep.PasswordResetSent)
    case "SHOW_RESET_PASSWORD":
      return goToStep(curState, OnboardingStep.ResetPassword)
    case "SHOW_MAGIC_LINK_EXPIRED":
      return goToStep(
        {
          ...curState,
          emailAddress: action.emailAddress,
          redirectPath: action.redirectPath,
        },
        OnboardingStep.MagicLinkExpired
      )
    case "SHOW_PASSWORDLESS_INVITE_ACCEPTED":
      return goToStep(
        { ...curState, invitationToken: action.invitationToken, redirectPath: action.redirectPath },
        OnboardingStep.PasswordlessInviteAccepted
      )
    case "SHOW_MAGIC_LINK_LOADING":
      return goToStep(curState, OnboardingStep.MagicLinkLoading)
    case "SHOW_VERIFY_EMAIL":
      // Does not use goToStep helper in order to preserve the field values
      // for resending the verification email.
      return {
        ...curState,
        step: OnboardingStep.VerifyEmail,
        errorText: undefined,
        loading: false,
      }
    case "SHOW_CREATE_PASSWORD":
      return goToStep(curState, OnboardingStep.CreatePassword)
    case "SET_LOADING":
      return {
        ...curState,
        loading: action.loading,
      }
    case "SET_ERROR_TEXT":
      return {
        ...curState,
        errorText: action.text,
      }
    case "SET_TOKEN":
      return {
        ...curState,
        token: action.token,
      }
    case "SHOW_MAGIC_LINK_SENT":
      return goToStep(
        {
          ...curState,
          emailAddress: action.emailAddress,
          redirectPath: action.redirectPath,
        },
        OnboardingStep.MagicLinkSent
      )
    case "SHOW_SINGLE_SIGN_ON_EXPIRED":
      return goToStep(
        {
          ...curState,
          emailAddress: action.emailAddress,
        },
        OnboardingStep.SingleSignOnExpired
      )
    case "USER_ALREADY_EXISTS":
      return goToStep(
        {
          ...curState,
          emailAddress: action.emailAddress,
          redirectPath: action.redirectPath,
        },
        OnboardingStep.UserAlreadyExists
      )
    case "BLOCK_LOGIN_REDIRECT":
      return {
        ...curState,
        blockLoginRedirect: true,
      }
    case "BACK":
      return {
        ...curState,
        back: true,
      }
  }
}

// Go to the specified step and reset step specific state
function goToStep(curState: OnboardingState, step: OnboardingStep): OnboardingState {
  return {
    ...curState,
    back: false,
    step,
    errorText: undefined,
    loading: false,
  }
}
