import { LegalEntityStatus, ViewType } from "@digits-graphql/frontend/graphql-bearer"
import { type SessionEmployment } from "@digits-graphql/frontend/graphql-public"
import { defined } from "@digits-shared/helpers/filters"
import objectHelper from "@digits-shared/helpers/objectHelper"
import { type StorageFacade } from "@digits-shared/helpers/storage/storage"
import { type JWTAffiliation, type JWTLegalEntity } from "@digits-shared/session/jwt/jwtLegalEntity"
import { type JWTSession } from "@digits-shared/session/jwt/jwtSession"
import { type JWTEmployment } from "@digits-shared/session/jwt/jwtUser"
import { SessionPermissions } from "@digits-shared/session/Permissions"
import Session from "@digits-shared/session/Session"
import { type AspectCode } from "@digits-shared/session/SessionTypes"
import type SessionUser from "@digits-shared/session/User"
import memoizeOne from "memoize-one"
import TOS_VERSION from "static/documents/terms-of-service-version.txt?raw"
import dayjs from "@digits-shared/initializers/dayjs/dayjs"
import SessionAffiliation from "src/frontend/session/Affiliation"
import SessionAffiliationOrganization from "src/frontend/session/AffiliationOrganization"
import SessionBusinessOrganization, {
  type SessionBusinessOrganizationAttributes,
} from "src/frontend/session/BusinessOrganization"
import SessionLegalEntity from "src/frontend/session/LegalEntity"
import { type FrontendPermissionModule } from "src/frontend/session/permissionModule"
import { Experience } from "src/frontend/session/personas"
import AssetsHelper, { CDNAsset } from "src/shared/helpers/assetsHelper"

export * from "./BusinessOrganization"

/**
 * Session specifically for frontend webapp
 */
export default class FrontendSession extends Session<JWTSession> {
  static CURRENT_ORG_ID_STORAGE_NAMESPACE = "organization.current"
  static CURRENT_LE_ID_STORAGE_NAMESPACE = "legalEntity.current"

  // includes the JWT orgs + the sharing org. Prevents from shifting the organizations() array on every call
  sharingOrganizations: SessionBusinessOrganization[] | undefined
  sharingOrganization: SessionBusinessOrganization | undefined
  sharingLegalEntity: SessionLegalEntity | undefined
  sharingViewType: ViewType | undefined

  // Holds the orgs which come from the JWT
  private sessionOrganizations: SessionBusinessOrganization[]
  private sessionOrganization: SessionBusinessOrganization | undefined
  private sessionLegalEntity: SessionLegalEntity | undefined

  constructor(storage?: StorageFacade) {
    super(storage)

    // Must call FrontendSession::decodeSession to correctly set instance fields which are not
    // set by super call.
    this.decodeSession()
  }

  /**
   * Returns true if a sharing context is active.
   */
  get isSharingContextActive() {
    return !!this.sharingLegalEntity
  }

  /**
   * Applies the specified organization and legal entity as the current sharing context,
   * which overrides other known organizations and legal entities in the session.
   */
  setSharingContext(
    sharingOrganization: SessionBusinessOrganization,
    sharingLegalEntity: SessionLegalEntity,
    sharingViewType = ViewType.Ledger
  ) {
    this.sharingOrganization = sharingOrganization
    this.sharingLegalEntity = sharingLegalEntity
    this.sharingViewType = sharingViewType
    this.sharingOrganizations = [...this.sessionOrganizations, sharingOrganization]
    this.currentOrganization = sharingOrganization
    this.currentLegalEntity = sharingLegalEntity
  }

  /**
   * Clears the sharing context, removing overrides for organization and legal entity.
   */
  clearSharingContext() {
    if (this.isSharingContextActive) {
      this.currentOrganization = undefined
      this.currentLegalEntity = undefined
    }

    this.sharingOrganizations = undefined
    this.sharingOrganization = undefined
    this.sharingLegalEntity = undefined
    this.sharingViewType = undefined
  }
  /**
   * Provides the list of currently known organizations, inclusive of the current sharing organization,
   * if present.
   */
  get organizations() {
    // when sharing is active, return the jwt + sharing org
    return this.sharingOrganizations ?? this.sessionOrganizations
  }

  /**
   * Provides the list of currently known legal entities across all organizations in the JWT
   */
  get legalEntities() {
    return this.organizations.flatMap((org) => org.legalEntities).filter(defined)
  }

  /**
   * Provides the list of currently known affiliations across all organizations in the JWT
   */
  get affiliations() {
    return this.organizations.flatMap((org) => org.affiliations).filter(defined)
  }

  /**
   * Provides the list of currently known organizations on the users JWT. Explicitly not including
   * sharing organizations.
   */
  get jwtOrganizations() {
    const sharingOrg = this.sharingOrganization
    if (!sharingOrg) {
      return this.sessionOrganizations
    }
    return this.sessionOrganizations.filter((o) => o.id !== sharingOrg.id)
  }

  /**
   * Get the current organization. Used either the organization referenced in memory
   * or uses a fallback.
   *
   * @return The current organization
   */
  get currentOrganization(): SessionBusinessOrganization | undefined {
    // make sure the cached organization object matches the active Org id we are using in this session
    if (this.sessionOrganization && this.sessionOrganization.id === this.lastKnownOrganizationId) {
      return this.sessionOrganization
    }

    this.sessionOrganization = this.findCurrentOrganization()

    return this.sessionOrganization
  }

  /**
   * Set the current organization in session. If not a sharing organization, persist it to
   * local and session storage.
   *
   * Persisting will keep the current organization in local and session storage and key
   * off of the user. We won't clear this on logout so the user can return to their last
   * org after logging back in.
   * @param {SessionBusinessOrganization} organization The current organization to set
   */
  set currentOrganization(organization: SessionBusinessOrganization | undefined) {
    if (!organization) {
      this.sessionOrganization = undefined
      return
    }

    if (!this.findOrganizationById(organization.id)) return

    this.sessionOrganization = organization
    if (organization.id !== this.sharingOrganization?.id) {
      this.persistCurrentOrganizationId(organization.id)
    }
  }

  /**
   * Attempts to find the current organization, using the last known organization ID
   * if the session organization is not set or does not match.
   */
  private findCurrentOrganization(): SessionBusinessOrganization | undefined {
    if (this.sessionOrganization) return this.sessionOrganization

    const storedOrganization = this.findOrganizationByLegalEntityId(this.currentLegalEntity?.id)
    if (storedOrganization) return storedOrganization

    return undefined
  }

  /**
   * Get the current affiliated organization.
   */
  get currentAffiliatedOrganization(): SessionAffiliationOrganization | undefined {
    return this.currentAffiliation?.organization
  }

  /*
   * Get the parent organization id for the current legal entity.
   * For affiliated users, this would be the current affiliated organization;
   * for org members this would be the current organization which has a fallback
   * value of the first organization the user is an employee of.
   */
  get currentLegalEntityParentOrganizationId() {
    return this.currentAffiliatedOrganization?.id || this.currentOrganizationId
  }

  /**
   * Get the current organization id.
   *
   * @return The current organization id
   */
  get currentOrganizationId(): string | undefined {
    return this.currentOrganization?.id
  }

  // Get the last known Legal Entity Id from the local storage
  get lastKnownOrganizationId() {
    // Look up the current org id stored in user preferences.
    return (
      this.storage.session.getItem(FrontendSession.CURRENT_ORG_ID_STORAGE_NAMESPACE) ??
      this.getGlobalPreference<string>(FrontendSession.CURRENT_ORG_ID_STORAGE_NAMESPACE)
    )
  }

  /**
   * Find a stored organization in the session by the id
   *
   * @param {string} id The id of the organization
   * @return The found organization or undefined if not found
   */
  findOrganizationById(id: string): SessionBusinessOrganization | undefined {
    return this.organizations.find((organization) => organization.id === id)
  }

  /**
   * Find a stored organization in the session by the slug
   *
   * This is useful for when you only have a partial reference to an org.
   * For example, checking organization slug in the url to make sure it is valid.
   * @param {string} slug The slug of the organization to check if valid
   * @return The found organization or undefined if not found
   */
  findOrganizationBySlug(slug: string): SessionBusinessOrganization | undefined {
    return this.organizations.find((organization) => organization.slug === slug)
  }

  /**
   * Find a stored organization in the session by a legal entity ID, it could be part of the org or
   * via affiliations
   *
   * @param {string} legalEntityId The id of the legal entity to search by
   * @return The found organization or undefined if not found
   */
  findOrganizationByLegalEntityId(legalEntityId: string): SessionBusinessOrganization | undefined {
    return this.organizations.find(
      (org) =>
        org.legalEntities.some((le) => le.id === legalEntityId) ||
        org.affiliatedLegalEntities.some((le) => le.id === legalEntityId)
    )
  }

  /**
   * Create a new organization and add it to the stored array of organizations in the session.
   *
   * NOTE: This does not persist reloads so should only be done after a success creation
   * of an organization to the server.
   *
   * @param {SessionBusinessOrganizationAttributes} organizationAttributes Attributes used to
   * create a new organization
   */
  addOrganization(organizationAttributes: SessionBusinessOrganizationAttributes) {
    const newOrganization = new SessionBusinessOrganization(organizationAttributes)
    this.sessionOrganizations = this.mergeOrganizations(this.sessionOrganizations, newOrganization)
    return newOrganization
  }

  /**
   * Get the current legal entity. Used either the legal entity referenced in memory
   * or uses a fallback.
   *
   * @return The current legal entity
   */
  get currentLegalEntity(): SessionLegalEntity {
    // make sure the cached entity object matches the active LE id we are using in this session
    if (this.sessionLegalEntity) {
      return this.sessionLegalEntity
    }

    this.sessionLegalEntity = this.findCurrentLegalEntity()
    if (!this.isSharingContextActive && !this.lastKnownLegalEntityId && this.sessionLegalEntity) {
      this.persistCurrentLegalEntityId(this.sessionLegalEntity.id)
    }

    return this.sessionLegalEntity
  }

  /**
   * Set the current legal entity. If not a sharing legal entity, persist it to
   * local and session storage.
   *
   * Persisting keeps the current legal entity in local and session storage and
   * key off of the user.We won't clear this on logout so the user can return to
   * their last viewed legal entity after logging back in.
   * @param {SessionLegalEntity} legalEntity The current legal entity to set
   */
  set currentLegalEntity(legalEntity: SessionLegalEntity | undefined) {
    this.sessionLegalEntity = legalEntity

    if (!legalEntity || !this.findLegalEntityById(legalEntity.id)) return

    if (legalEntity.id !== this.sharingLegalEntity?.id) {
      this.persistCurrentLegalEntityId(legalEntity.id)
    }
  }

  /**
   * Get all active or approved legal entities in the user's JWT (across all of their orgs)
   *
   * @return Tuple of { legalEntity: SessionLegalEntity, organization: SessionOrganization }
   */
  get allActiveLegalEntities() {
    const legalEntities = this.organizations
      ?.flatMap((organization) =>
        organization.legalEntities.map((legalEntity) => ({ legalEntity, organization }))
      )
      .filter(defined)

    if (this.doppelganger?.hasDashboardAccess) return legalEntities

    return legalEntities.filter(({ legalEntity }) => legalEntity.isActive || legalEntity.isApproved)
  }

  /**
   * Determine the currently legal entity with fallbacks to the first dashboard accessible
   * legal entity.
   *
   * @return The current legal entity
   */
  private findCurrentLegalEntity(): SessionLegalEntity {
    // Look up by current legal entity
    const lastLegalEntityId = this.lastKnownLegalEntityId

    if (lastLegalEntityId && lastLegalEntityId !== this.sharingLegalEntity?.id) {
      const lastKnownLE = this.findLegalEntityById(lastLegalEntityId)
      if (lastKnownLE) return lastKnownLE
    }

    return this.findFirstDashboardAccessLegalEntity() as SessionLegalEntity
  }

  /**
   * Get the current affiliation.
   * @return The current affiliation.
   */
  get currentAffiliation(): SessionAffiliation | undefined {
    const { currentLegalEntity } = this

    return this.organizations
      .flatMap((org) => org.affiliations)
      .find((af) => af?.entity.id === currentLegalEntity?.id)
  }

  /**
   * Get the current legal entity id.
   *
   * NOTE: Until we support switching between Legal Entities, this is
   * always the first Legal Entity associated to the current organization
   * @return The current legal entity id
   */
  get currentLegalEntityId() {
    const le = this.currentLegalEntity
    return le?.id
  }

  // Get the last known Legal Entity Id from the local storage
  get lastKnownLegalEntityId() {
    return (
      this.storage.session.getItem(FrontendSession.CURRENT_LE_ID_STORAGE_NAMESPACE) ??
      this.getGlobalPreference<string>(FrontendSession.CURRENT_LE_ID_STORAGE_NAMESPACE)
    )
  }

  hasUsableBearer(): boolean {
    if (!super.hasUsableBearer()) return false

    const memberships = this.decodedSignedJWT?.user?.memberships
    if (!memberships) {
      this.clearStoredOrganizationAndLegalEntity()
      return true
    }

    // Check if lastKnownLegalEntityId exists in JWT as either JWTLegalEntity.id or JWTAffiliation.entity.id
    const { lastKnownLegalEntityId, currentLegalEntityId } = this

    const findEmploymentByLE = (leID: string) => (employment: JWTEmployment) =>
      employment.org.entities?.some((entity) => entity.id === leID) ||
      employment.org.affiliations?.some((affiliation) => affiliation.entity.id === leID)

    return (
      (!!lastKnownLegalEntityId && memberships.some(findEmploymentByLE(lastKnownLegalEntityId))) ||
      (!!currentLegalEntityId && memberships.some(findEmploymentByLE(currentLegalEntityId)))
    )
  }

  /**
   * Find a legal entity in the session by the id
   *
   * @param {string} id The id of the legal entity to check if valid
   * @return The found legal entity or undefined if not found
   */
  findLegalEntityById(id: string): SessionLegalEntity | undefined {
    const legalEntities = this.organizations
      .flatMap((organization) => organization.legalEntities.find((le) => le.id === id))
      .filter(defined)

    if (legalEntities.length) {
      return legalEntities[0]
    }

    const affiliatedEntities = this.organizations
      .flatMap((organization) => organization.affiliatedLegalEntities.find((le) => le.id === id))
      .filter(defined)

    return affiliatedEntities.length ? affiliatedEntities[0] : undefined
  }

  /**
   * Finds an active or affiliated legal entity in the session _token_ data,
   * by the provided slug.
   *
   * This call exists to be able to answer whether the LE represented by the
   * provided slug is a natural part of this session, ignoring any current
   * sharing context.
   */
  findLegalEntityBySlugInToken(slug: string) {
    // sessionOrganizations are just those from the JWT token, kept separate
    // from the sharing context
    return this.findLegalEntityBySlugIn(this.sessionOrganizations, slug)
  }

  /**
   * Find a LE or affiliated LE in the session by the slug and optional status
   *
   * This is useful for when you only have a partial reference to a LE.
   * @param {string} slug The slug of the legal entity to check if valid
   * @param {LegalEntityStatus} status Optional status to search by
   * @return The found legal entity or undefined if not found
   */
  findLegalEntityBySlug(slug: string, status?: LegalEntityStatus): SessionLegalEntity | undefined {
    return this.findLegalEntityBySlugIn(this.organizations, slug, status)
  }

  /**
   * Find an affiliated LE in the session by the slug and optional status
   *
   * This is useful for when you only have a partial reference to a LE.
   * @param {string} slug The slug of the legal entity to check if valid
   * @param {LegalEntityStatus} status Optional status to search by
   * @return The found legal entity or undefined if not found
   */
  findAffiliateLegalEntityBySlug(
    slug: string,
    status?: LegalEntityStatus
  ): SessionLegalEntity | undefined {
    return this.findAffiliateLegalEntityBySlugIn(this.organizations, slug, status)
  }

  findLegalEntityBySlugIn(
    organizations: SessionBusinessOrganization[],
    slug: string,
    status?: LegalEntityStatus
  ) {
    const legalEntities = organizations
      .flatMap((organization) =>
        organization.legalEntities.find(
          (le) => le.slug === slug && (!status || le.status === status)
        )
      )
      .filter<SessionLegalEntity>((le): le is SessionLegalEntity => le !== undefined)

    return legalEntities?.[0] || this.findAffiliateLegalEntityBySlugIn(organizations, slug, status)
  }

  findAffiliateLegalEntityBySlugIn(
    organizations: SessionBusinessOrganization[],
    slug: string,
    status?: LegalEntityStatus
  ) {
    const affiliatedEntities = organizations
      .filter((org) => !!org.affiliations)
      .flatMap((organization) =>
        organization.affiliatedLegalEntities.find(
          (le) => le.slug === slug && (!status || le.status === status)
        )
      )
      .filter<SessionLegalEntity>((le): le is SessionLegalEntity => le !== undefined)

    return affiliatedEntities[0]
  }

  /**
   * Find an **active** or **approved** legal entity (or affiliate LE) in the session by the slug
   *
   * This is useful for when you only have a partial reference to a LE.
   * @param {string} slug The slug of the legal entity to check if valid
   * @return The found legal entity or undefined if not found
   */
  findDashboardLegalEntityBySlug(slug: string): SessionLegalEntity | undefined {
    return (
      this.findLegalEntityBySlug(slug, LegalEntityStatus.Active) ||
      this.findLegalEntityBySlug(slug, LegalEntityStatus.Approved)
    )
  }

  /**
   * Find a **pending** affiliate legal entity in the session by the slug
   * For now, Pending & PendingHold should be considered the same status.
   *
   * This is useful for when you only have a partial reference to a LE.
   * @param {string} slug The slug of the legal entity to check if valid
   * @return The found legal entity or undefined if not found
   */
  findOnboardingAffiliateLegalEntityBySlug(slug: string): SessionLegalEntity | undefined {
    let pending = this.findAffiliateLegalEntityBySlug(slug, LegalEntityStatus.Pending)
    if (pending) return pending

    pending = this.findAffiliateLegalEntityBySlug(slug, LegalEntityStatus.PendingHold)
    if (pending) return pending

    return this.findAffiliateLegalEntityBySlug(slug, LegalEntityStatus.New)
  }

  findFirstOnboardingLegalEntity() {
    const org =
      this.organizations.find((o) => o.hasPendingLegalEntity) ||
      this.organizations.find((o) => o.hasPendingAffiliations)
    return org?.pendingLegalEntities?.[0] || org?.pendingAffiliations?.[0]?.entity
  }

  /**
   * Find first legal entity with dashboard access. This intentionally excludes sharing legal
   * entities. Due to their ephemeral nature (not present in session JWT), we don't want them as
   * a fallback.
   *
   * @return The found legal entity or undefined if not found
   */
  findFirstDashboardAccessLegalEntity(): SessionLegalEntity | undefined {
    // Look up by current legal entity
    const lastLegalEntityId = this.lastKnownLegalEntityId
    if (lastLegalEntityId && lastLegalEntityId !== this.sharingLegalEntity?.id) {
      const lastKnownLE = this.findLegalEntityById(lastLegalEntityId)
      if (lastKnownLE?.hasDashboardAccess(this.doppelganger)) return lastKnownLE
    }

    const firstActiveAffiliation =
      this.findFirstDashboardAccessAffiliationOrganization()?.affiliatedLegalEntities.find(
        (le) =>
          le.hasDashboardAccess(this.doppelganger) &&
          le.isActive &&
          le.id !== this.sharingLegalEntity?.id
      )

    const firstActiveLE = this.findFirstDashboardAccessOrganization()?.legalEntities.find(
      (le) =>
        le.hasDashboardAccess(this.doppelganger) &&
        le.isActive &&
        le.id !== this.sharingLegalEntity?.id
    )

    const firstAffiliation =
      this.findFirstDashboardAccessAffiliationOrganization()?.affiliatedLegalEntities.find(
        (le) => le.hasDashboardAccess(this.doppelganger) && le.id !== this.sharingLegalEntity?.id
      )

    const firstLE = this.findFirstDashboardAccessOrganization()?.legalEntities.find(
      (le) => le.hasDashboardAccess(this.doppelganger) && le.id !== this.sharingLegalEntity?.id
    )

    // Prefer active LEs over pending LEs
    return firstActiveAffiliation || firstActiveLE || firstAffiliation || firstLE
  }

  /**
   * Find first organization with dashboard access. Prefer organizations with dashboard access
   * over organizations with pending legal entities but user has a DG token.
   *
   * @return The found legal entity or undefined if not found
   */
  findFirstDashboardAccessOrganization(): SessionBusinessOrganization | undefined {
    const { doppelganger } = this
    // Look up the current org id stored in user preferences. Performing this here since
    // we'll only want to use this as a fallback if we don't know where else to send the user.
    const currentOrgId = this.lastKnownOrganizationId

    // If we find a stored current organization, look for in the available organizations
    if (currentOrgId) {
      const currentOrg = this.findOrganizationById(currentOrgId)

      if (
        currentOrg?.hasDashboardLegalEntity ||
        (currentOrg?.legalEntities.length && doppelganger?.hasDashboardAccess)
      ) {
        return currentOrg
      }
    }

    // Loop over session organizations and identify organizations with dashboard access
    // or any with at least 1 legal entity if the user is a DGer with dashboard access. Prefer
    // the former if found.
    //
    // NOTE: Using session organization intentionally as we don't want to consider shared organizations
    // for this purpose (fallback logic should only consider access present on the JWT)
    let dashboardAccess: SessionBusinessOrganization | undefined
    let dgAccess: SessionBusinessOrganization | undefined
    this.sessionOrganizations.forEach((o) => {
      if (!dashboardAccess && o.hasDashboardLegalEntity) dashboardAccess = o
      if (!dgAccess && o.legalEntities.length && doppelganger?.hasDashboardAccess) dgAccess = o
    })

    return dashboardAccess || dgAccess
  }

  /**
   * Find first affiliation organization with dashboard access. Prefer organizations with
   * dashboard access over organizations with pending legal entities but user has a DG token.
   *
   * @return  The found legal entity or undefined if not found
   */
  findFirstDashboardAccessAffiliationOrganization(): SessionBusinessOrganization | undefined {
    const { doppelganger } = this

    // Look up the current org id stored in user preferences. Performing this here since
    // we'll only want to use this as a fallback if we don't know where else to send the user.
    const currentOrgId = this.lastKnownOrganizationId

    // If we find a stored current organization, look for in the available organizations
    if (currentOrgId) {
      const dashboardOrg = this.findOrganizationById(currentOrgId)
      if (dashboardOrg?.hasDashboardAffiliations) return dashboardOrg
    }

    // Loop over session organizations and identify affiliation organizations with dashboard access
    // or any with at least 1 legal entity if the user is a DGer with dashboard access. Prefer
    // the former if found.
    //
    // NOTE: Using session organization intentionally as we don't want to consider shared organizations
    // for this purpose (fallback logic should only consider access present on the JWT)
    let dashboardAccess: SessionBusinessOrganization | undefined
    let dgAccess: SessionBusinessOrganization | undefined
    this.sessionOrganizations.forEach((o) => {
      if (!dashboardAccess && o.hasDashboardAffiliations) dashboardAccess = o
      if (!dgAccess && o.hasAffiliations && doppelganger?.hasDashboardAccess) dgAccess = o
    })

    return dashboardAccess || dgAccess
  }

  /*
    AFFILIATIONS
  */

  /**
   * Check if any of the organizations on the session have affiliated legal entities.
   * @return If any organizations have an `Active` Legal Entity
   */
  get hasAffiliations() {
    return this.organizations.some((organization) => organization.hasAffiliations)
  }

  /**
   * Check if the current session is an affiliated user checking an affiliated organization.
   * In this case, both {@link currentAffiliatedOrganization} and {@link currentOrganization} are set.
   * @return If current session is an affiliated session
   */
  get isAffiliatedSession() {
    return !!(this.currentAffiliatedOrganization && this.currentOrganization)
  }

  /**
   * ASPECTS
   */

  hasAccessToAspect(aspect?: AspectCode) {
    return this.currentLegalEntity?.hasAccessToAspect(aspect) || !!this.doppelganger?.hasFullAccess
  }

  /**
   * TERMS OF SERVICE ACCEPTANCE
   */

  /**
   * Check if the the current version of TOS has been accepted
   *
   * @return If the the current version of TOS has been accepted
   */
  get hasAcceptedLatestTOS() {
    // If we're currently a visitor in a sharing context, we're not a member of the
    // current legal entity. Since we can't accept their terms anyway, short-circuit
    // the check.
    return this.isSharingContextActive || hasAcceptedTOS(this.termsOfServiceVersion, this.user)
  }

  get termsOfServiceVersion() {
    return TOS_VERSION.trim()
  }

  /*
    ONBOARDING
  */

  /**
   * Check if the session is properly registered
   *
   * @return If the session is registered
   */
  get isRegistered() {
    return !!this.user?.id && !!this.user?.emailAddress
  }

  /**
   * Check if there are any legal entities on the session
   *
   * @return If the session has any legal entities
   */
  get hasLegalEntity() {
    return this.organizations.some((o) => !!o.legalEntities.length)
  }

  /**
   * Check if any of the organizations on the session has any Legal Entities
   * that are affiliated.
   *
   *
   * @return If any organizations have an `Approved` Legal Entity
   */
  get hasDashboardAffiliations() {
    return this.organizations.some((o) => o.hasDashboardAffiliations)
  }

  /**
   * Check if any of the organizations on the session has any Legal Entities
   * that are `Pending`.
   *
   * @return If any organizations have an `Active` Legal Entity
   */
  get hasPendingLegalEntities() {
    return (
      this.organizations.some((o) => o.hasPendingLegalEntity) ||
      this.organizations.some((o) => o.hasPendingAffiliations)
    )
  }

  /**
   * Check if any of the organizations on the session has any Legal Entities
   * that are `Active`.
   *
   * In order to see the dashboard, at least 1 Organization has to have an active
   * Legal Entity. Allow Doppelgangers to see customers dashboards regardless
   * of the Legal Entity's status, so we can preview what a customer would see.
   *
   * @return If any organizations have an `Active` Legal Entity
   */
  get hasActiveLegalEntity() {
    return this.organizations.some((o) => o.hasActiveLegalEntity)
  }

  /**
   * Check if any of the organizations on the session has any Legal Entities
   * that have dashboard access or is affiliated with a Legal Entities that has dashboard access.
   *
   * @return If any organizations have an `Active` Legal Entity
   */
  get hasDashboardLegalEntityOrAffiliation() {
    return (
      this.organizations.some(
        (o) =>
          o.hasDashboardLegalEntity ||
          (o.legalEntities.length && this.doppelganger?.hasDashboardAccess)
      ) ||
      this.organizations.some(
        (o) =>
          o.hasDashboardAffiliations ||
          (o.affiliatedLegalEntities.length && this.doppelganger?.hasDashboardAccess)
      )
    )
  }

  /**
   * Return primary experience for the organizational employment
   */
  get currentPrimaryExperience() {
    if (this.currentAffiliation) {
      return this.currentAffiliation.primaryExperience
    }

    return this.currentOrganization?.primaryExperience
  }

  /*
    INTERNAL
  */

  /**
   * Decodes the JWT into its stored data. This includes the user, when
   * the session expires, the organization, and it's legal entities.
   */
  protected decodeSession(employments?: SessionEmployment[] | null) {
    super.decodeSession()

    const jwtOrganizations =
      this.decodedUnsignedJWT?.user?.memberships?.reduce(this.decodeJWTEmployment.bind(this), []) ??
      []

    const sessionOrganizations =
      employments?.reduce(this.decodeSessionEmployment.bind(this), []) ?? []

    this.sessionOrganizations = this.mergeOrganizations(jwtOrganizations, ...sessionOrganizations)

    // reset cache to read the org and LE from the new list in case the org or LE are no longer available.
    const cachedOrgId = this.sessionOrganization?.id
    this.sessionOrganization = cachedOrgId ? this.findOrganizationById(cachedOrgId) : undefined

    const cachedLEId = this.sessionLegalEntity?.id
    this.sessionLegalEntity = cachedLEId ? this.findLegalEntityById(cachedLEId) : undefined

    const { lastKnownLegalEntityId, currentLegalEntityId } = this
    // persist the new current legal entity if there wasnt one in session

    if (
      !lastKnownLegalEntityId ||
      (currentLegalEntityId && currentLegalEntityId !== lastKnownLegalEntityId)
    ) {
      this.persistCurrentLegalEntityId(currentLegalEntityId)
    }
  }

  private mergeOrganizations(
    jwtOrganizations: SessionBusinessOrganization[],
    ...sessionOrganizations: SessionBusinessOrganization[]
  ) {
    const mergedOrgs = new Map<string, SessionBusinessOrganization>()

    jwtOrganizations.forEach((jwtOrg) => {
      const sessionOrg = sessionOrganizations.find((sOrg) => sOrg.id === jwtOrg.id)
      if (sessionOrg) {
        // same org exists in JWT and session, we need to merge its entities & affiliations
        jwtOrg.addLegalEntities(sessionOrg.legalEntities)
        if (sessionOrg.affiliations) {
          jwtOrg.addAffiliations(sessionOrg.affiliations)
        }
      }

      // JWT org is not in the session orgs, we dont have to merge it
      mergedOrgs.set(jwtOrg.id, jwtOrg)
    })

    sessionOrganizations.forEach((org) => {
      // if org has been merged above, skip it
      if (mergedOrgs.has(org.id)) return

      // session org is not in JWT, we dont have to merge it
      mergedOrgs.set(org.id, org)
    })

    return Array.from(mergedOrgs.values())
  }

  private decodeSessionEmployment(
    organizations: SessionBusinessOrganization[],
    employment: SessionEmployment
  ) {
    const { id: employmentId, organization, affiliations: rawAffiliations } = employment
    const { id, name, slug, iconUrl, legalEntities: entities } = organization

    const affiliations = rawAffiliations
      ? rawAffiliations.flatMap<SessionAffiliation>((af) =>
          af.organization.legalEntities.map((entity) => {
            const raw: JWTAffiliation = {
              id: af.id,
              org: { ...af.organization, icon: af.organization.iconUrl ?? undefined },
              entity,
              perms: {},
            }
            return this.decodeAffiliation(id, raw)
          })
        )
      : undefined

    const legalEntities = entities
      ? entities.map<SessionLegalEntity>((e) => this.decodeLegalEntity(id, e))
      : []

    // no permissions included in graphql employment (only in jwt)
    const permissions = new SessionPermissions()

    const orgAttributes: SessionBusinessOrganizationAttributes = {
      id,
      name,
      slug,
      iconUrl: iconUrl ? AssetsHelper.publicUrl(CDNAsset.OrganizationIcons, iconUrl) : undefined,
      legalEntities,
      affiliations,
      employmentId,
      permissions,
    }
    organizations.push(new SessionBusinessOrganization(orgAttributes))
    return organizations
  }

  /**
   * Used when parsing a JWT session. Decodes a JWTEmployment
   * into a SessionOrganization and stores it on a passed array of
   * SessionOrganizations.
   */
  private decodeJWTEmployment(
    organizations: SessionBusinessOrganization[],
    employment: JWTEmployment
  ) {
    const { id: employmentId, org, perms, a: employmentActivated, priexp } = employment

    const { id, name, slug, icon, entities, affiliations: rawAffiliations } = org
    const legalEntities = entities
      ? entities.map<SessionLegalEntity>((e) => this.decodeLegalEntity(id, e))
      : []

    const affiliations = rawAffiliations
      ? rawAffiliations.map<SessionAffiliation>((af) => this.decodeAffiliation(id, af))
      : undefined

    const permissions = new SessionPermissions(perms)
    const priExpKey = objectHelper.keysOf(Experience).find((k) => Experience[k] === priexp)
    const primaryExperience = priExpKey ? Experience[priExpKey] : undefined

    const organization: SessionBusinessOrganizationAttributes = {
      id,
      name,
      slug,
      iconUrl: AssetsHelper.publicUrl(CDNAsset.OrganizationIcons, icon),
      legalEntities,
      affiliations,
      permissions,
      employmentId,
      employmentActivated,
      primaryExperience,
    }
    organizations.push(new SessionBusinessOrganization(organization))

    return organizations
  }

  private decodeLegalEntity(
    organizationId: string,
    rawLegalEntity: JWTLegalEntity,
    affiliationId?: string
  ) {
    return new SessionLegalEntity(organizationId, rawLegalEntity, affiliationId)
  }

  private decodeAffiliation(organizationId: string, rawAffiliation: JWTAffiliation) {
    const {
      entity: rawEntity,
      org: rawOrganization,
      perms,
      priexp,
      ...rawAttributes
    } = rawAffiliation

    const entity = this.decodeLegalEntity(organizationId, rawEntity, rawAttributes.id)

    const organization = new SessionAffiliationOrganization({
      ...(rawOrganization || {}),
      iconUrl: rawOrganization.icon
        ? AssetsHelper.publicUrl(CDNAsset.OrganizationIcons, rawOrganization.icon)
        : undefined,
    })

    const permissions = new SessionPermissions<FrontendPermissionModule>(perms)

    const priExpKey = objectHelper.keysOf(Experience).find((k) => Experience[k] === priexp)
    const primaryExperience = priExpKey ? Experience[priExpKey] : undefined

    const attributes = {
      ...rawAttributes,
      entity,
      organization,
      permissions,
      primaryExperience,
    }
    return new SessionAffiliation(attributes)
  }

  /**
   * Persists the current organization ID to session and global storage,
   * syncing it with the current legal entity if provided.
   */
  private persistCurrentOrganizationId(organizationId: string | undefined) {
    if (!organizationId) {
      this.clearStoredOrganizationAndLegalEntity()
      return
    }

    this.setGlobalPreference(FrontendSession.CURRENT_ORG_ID_STORAGE_NAMESPACE, organizationId)
    this.storage.session.setItem(FrontendSession.CURRENT_ORG_ID_STORAGE_NAMESPACE, organizationId)

    // Clear current legal entity to avoid conflicts, forcing re-selection if needed
    this.persistCurrentLegalEntityId(undefined)
  }

  /**
   * Sets the current legal entity ID in session and global storage, and synchronizes
   * the related organization ID to ensure consistency.
   */
  private persistCurrentLegalEntityId(legalEntityId: string | undefined) {
    if (!legalEntityId) {
      this.clearStoredOrganizationAndLegalEntity()
      return
    }

    const leOrg = this.findOrganizationByLegalEntityId(legalEntityId)
    if (!leOrg) {
      console.error(
        "Current LE ID does not match any known organization",
        legalEntityId,
        this.organizations
      )
      this.clearStoredOrganizationAndLegalEntity()
      return
    }

    this.setGlobalPreference(FrontendSession.CURRENT_LE_ID_STORAGE_NAMESPACE, legalEntityId)
    this.storage.session.setItem(FrontendSession.CURRENT_LE_ID_STORAGE_NAMESPACE, legalEntityId)

    this.setGlobalPreference(FrontendSession.CURRENT_ORG_ID_STORAGE_NAMESPACE, leOrg.id)
    this.storage.session.setItem(FrontendSession.CURRENT_ORG_ID_STORAGE_NAMESPACE, leOrg.id)
  }
  /**
   * Clears stored organization and legal entity IDs from session and global storage.
   */
  private clearStoredOrganizationAndLegalEntity() {
    this.deleteGlobalPreference(FrontendSession.CURRENT_ORG_ID_STORAGE_NAMESPACE)
    this.deleteGlobalPreference(FrontendSession.CURRENT_LE_ID_STORAGE_NAMESPACE)
    this.storage.session.removeItem(FrontendSession.CURRENT_ORG_ID_STORAGE_NAMESPACE)
    this.storage.session.removeItem(FrontendSession.CURRENT_LE_ID_STORAGE_NAMESPACE)
  }
}

// Memoized to avoid re-checking dates multiple times per render
const hasAcceptedTOS = memoizeOne(
  (termsOfServiceVersion: string, tosSource: SessionUser | SessionLegalEntity | undefined) => {
    if (!tosSource) return true

    const { termsAcceptedAt } = tosSource
    if (!termsAcceptedAt) return false

    const tosAcceptance = dayjs(termsAcceptedAt)
    const version = dayjs(termsOfServiceVersion, "YYYY-MM-DD")
    if (!tosAcceptance.isValid() || !version.isValid()) {
      TrackJS?.console.error("invalid dates for TOS check", tosAcceptance, version)
      return false
    }

    return tosAcceptance.isAfter(version) || tosAcceptance.isSame(version)
  }
)
