import * as React from "react"
import {
  AccountNumbersDisplay,
  Interval,
  type ReportDocumentOptions,
  ReportNumberPrecision,
  ReportOptionComparison,
} from "@digits-graphql/frontend/graphql-bearer"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import { type CurrencyFormatOptions, CurrencyStyle } from "@digits-shared/helpers/numberHelper"
import objectHelper from "@digits-shared/helpers/objectHelper"
import dayjs from "@digits-shared/initializers/dayjs/dayjs"
import { ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { isGenerated } from "src/frontend/components/Shared/Reports/Packages/shared"
import useReportPackageContext from "src/frontend/components/Shared/Reports/Packages/Viewer/ReportPackageContext"
import {
  type ColumnOptionKey,
  isBreakdownColumn,
  isDeltaColumn,
  type StatementColumns,
} from "src/frontend/components/Shared/Reports/Report/Components/Statements/columnTypes"
import { StatementSize } from "src/frontend/components/Shared/Reports/Report/Components/Statements/shared"
import { useStatementSize } from "src/frontend/components/Shared/Reports/Report/Components/Statements/StatementContext"
import { useReportComponentIntervalOrigin } from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/hooks/useReportComponentIntervalOrigin"
import { useComponentContext } from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/LayoutViewer/ComponentContext"
import useReportContext from "src/frontend/components/Shared/Reports/Report/Viewer/ReportContext"
import { useFirstTransactionTimestamp } from "src/shared/hooks/useFirstTransactionTimestamp"

export interface ReportColumns extends ReportDocumentOptions {
  columnKeys: ColumnOptionKey[]
  columns: StatementColumns
  deltaMonthOverMonthPeriods: number
  hideAccountNumbers: boolean
}

export function useReportDocumentOptions(): ReportColumns {
  const { reportPackage } = useReportPackageContext()
  const { report } = useReportContext()
  const { component, componentSize } = useComponentContext()
  const isSupportedOption = useIsSupportedReportOptionFn()
  const maximumBreakdownCount = useMaxBreakdownsCount()
  const hideAccountNumbers =
    reportPackage?.packageOptions?.accountNumbersDisplay === AccountNumbersDisplay.Hidden

  return React.useMemo(() => {
    const document = reportPackage?.documents.find(
      (d) => d.metadata.documentObjectId === report?.id
    )

    const options = isGenerated(document?.document)
      ? document?.document.options
      : component?.config.statement?.options

    if (!options)
      return {
        columnKeys: [],
        columns: {},
        deltaMonthOverMonthPeriods: 0,
        hideAccountNumbers,
      }

    const columns: StatementColumns = {}

    // Do not create columns if component is not large
    if (component?.componentId && componentSize !== ComponentSize.Large) {
      return {
        columnKeys: [],
        columns,
        ...options,
        deltaMonthOverMonthPeriods: 0,
        hideAccountNumbers,
      }
    }

    if (options.deltaMonthOverMonth) columns.deltaMonthOverMonth = options.deltaMonthOverMonth
    if (options.comparedToIncome) columns.comparedToIncome = options.comparedToIncome
    if (options.comparedToTotal) columns.comparedToTotal = options.comparedToTotal
    if (options.deltaYearOverYear) columns.deltaYearOverYear = options.deltaYearOverYear
    if (options.yearToDate) columns.yearToDate = options.yearToDate
    if (options.deltaYearToDate) columns.deltaYearToDate = options.deltaYearToDate

    for (const key of objectHelper.keysOf(columns)) {
      if (!isSupportedOption(key as ColumnOptionKey)) {
        delete columns[key]
      }
    }

    let deltaMonthOverMonthPeriods = maximumBreakdownCount(options.deltaMonthOverMonthPeriods ?? 0)
    if (!isSupportedOption("deltaMonthOverMonthPeriods")) {
      deltaMonthOverMonthPeriods = 0
    }

    const columnKeys = objectHelper.keysOf(columns)

    return {
      columnKeys,
      columns,
      ...options,
      deltaMonthOverMonthPeriods,
      hideAccountNumbers,
    }
  }, [
    component?.componentId,
    component?.config.statement?.options,
    componentSize,
    hideAccountNumbers,
    isSupportedOption,
    maximumBreakdownCount,
    report?.id,
    reportPackage?.documents,
  ])
}

export function useReportCurrencyOptions(): CurrencyFormatOptions {
  const size = useStatementSize()
  return reportCurrencyOptions(useReportDocumentOptions(), size)
}

export function reportCurrencyOptions(
  { amountPrecision }: ReportColumns,
  size: StatementSize | undefined = undefined
): CurrencyFormatOptions {
  const style = CurrencyStyle.Detail

  switch (size === StatementSize.Full ? amountPrecision : null) {
    case ReportNumberPrecision.Decimal:
      return { style, minimumFractionDigits: 2, maximumFractionDigits: 2 }

    case ReportNumberPrecision.Rounded:
    case ReportNumberPrecision.InvalidNumberPrecision:
    case undefined:
    case null:
      return { style, minimumFractionDigits: 0, maximumFractionDigits: 0 }
  }
}

function useIsSupportedReportOptionFn() {
  const origin = useReportComponentIntervalOrigin()
  const intervalCount = origin?.intervalCount ?? 1
  const multipleIntervals = intervalCount > 1
  return React.useCallback(
    (optionKey: ColumnOptionKey) => {
      switch (optionKey) {
        case "deltaMonthOverMonth":
        case "deltaMonthOverMonthPeriods":
          return !multipleIntervals

        case "deltaYearOverYear":
          return !multipleIntervals && origin.interval !== Interval.Year

        case "yearToDate":
        case "deltaYearToDate": {
          const supportedInterval = [Interval.Month, Interval.Quarter].includes(origin.interval)
          return supportedInterval && origin.index !== 1
        }

        default:
          return true
      }
    },
    [multipleIntervals, origin.index, origin.interval]
  )
}

export function useIsSupportedReportOption(optionKey: ColumnOptionKey) {
  return useIsSupportedReportOptionFn()(optionKey)
}

export function useStatementBreakdownColumnsCount() {
  const reportOptions = useReportDocumentOptions()
  const columnKeys = reportOptions?.columnKeys || []
  if (
    columnKeys.includes("deltaMonthOverMonth") &&
    isBreakdownColumn("deltaMonthOverMonth", reportOptions.columns)
  ) {
    if (reportOptions?.deltaMonthOverMonthPeriods) {
      return Number(reportOptions.deltaMonthOverMonthPeriods)
    }
    return 1
  }
  return 0
}

export function useReportExtraColumnsCount() {
  const reportOptions = useReportDocumentOptions()
  const columnKeys = reportOptions?.columnKeys || []
  let deltaColumns = columnKeys.length
  if (columnKeys.includes("deltaMonthOverMonth")) {
    if (isDeltaColumn("deltaMonthOverMonth", reportOptions.columns)) {
      // we show "total" & "∆ variance" columns
      deltaColumns = deltaColumns + 1
    } else {
      deltaColumns = deltaColumns - 1
    }
  }

  if (columnKeys.includes("deltaYearToDate")) {
    if (
      reportOptions.deltaYearToDate === ReportOptionComparison.Amount ||
      reportOptions.deltaYearToDate === ReportOptionComparison.Percent
    ) {
      deltaColumns = deltaColumns + 1
    }
  }
  if (columnKeys.includes("deltaYearOverYear")) {
    if (
      reportOptions.deltaYearOverYear === ReportOptionComparison.Amount ||
      reportOptions.deltaYearOverYear === ReportOptionComparison.Percent
    ) {
      deltaColumns = deltaColumns + 1
    }
  }
  return deltaColumns
}

function useMaxBreakdownsCount() {
  const origin = useReportComponentIntervalOrigin()
  const firstTransactionTimestamp = useFirstTransactionTimestamp()

  return React.useCallback(
    (deltaPeriods: number) => {
      if (!origin || !deltaPeriods) return deltaPeriods

      const breakdownMoment = dateTimeHelper.addIntervalOriginToDayjs(origin, -deltaPeriods)
      const firstTransactionMoment = dayjs.unix(firstTransactionTimestamp)

      if (breakdownMoment.isBefore(firstTransactionMoment, "year")) {
        return Math.max(
          0,
          deltaPeriods +
            1 -
            dateTimeHelper.intervalCount(
              breakdownMoment.unix(),
              firstTransactionTimestamp,
              origin.interval
            )
        )
      }

      return deltaPeriods
    },
    [firstTransactionTimestamp, origin]
  )
}
