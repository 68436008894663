import * as React from "react"

export const InvertValuesContext = React.createContext<boolean>(false)

/** Hook to retrieve the current context in functional components. */
export function useInvertValues() {
  return React.useContext(InvertValuesContext)
}

export function useIsInboundAmount(amount: number) {
  return amount >= 0
}
