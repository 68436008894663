import { Permission } from "@digits-graphql/frontend/graphql-bearer"
import { useHasPermissionFromObjectSharing } from "src/frontend/hooks/useHasPermissionFromObjectSharing"
import { useJwtPermissions } from "src/frontend/hooks/useJwtPermissions"
import {
  FrontendPermissionModule,
  FrontendPermissionSource,
} from "src/frontend/session/permissionModule"

/**
 * Determines whether the current user has permissions to load files based on either:
 *   - permissions granted by object sharing, or
 *   - permissions from the current affiliation or organization
 */
export function useCanListVault() {
  const permissions = useJwtPermissions(FrontendPermissionSource.LegalEntity)
  const module = FrontendPermissionModule.SecureFiles

  // Check if the permission granted by object sharing
  const hasPermissionFromObjectSharing = useHasPermissionFromObjectSharing(
    module,
    Permission.CommentRead,
    Permission.CommentShare,
    Permission.FullAccess,
    Permission.SensitiveFullAccess
  )

  if (hasPermissionFromObjectSharing) return true

  // Otherwise, we need the "Read" permission from the session's JWT
  // on the SecureFiles module.
  return !!permissions?.hasReadPermission(module)
}
