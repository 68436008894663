import * as React from "react"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import { SidebarContentOffsetVar } from "src/frontend/components/Shared/NavSidebar/sidebarConstants"

const FullScreenContainer = styled.div`
  width: 100vw;
  z-index: 1;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
`

const Header = styled.div`
  width: 100%;
  height: var(--header-height);
`

const HeaderGap = styled.div`
  width: 100%;
  height: var(--header-gap);
  background: ${colors.translucentPrimary50};
`

const ContentCentering = styled.div`
  ${SidebarContentOffsetVar};
  position: absolute;
  width: calc(100vw - var(--sidebar-content-offset));
  height: 100vh;
  top: 0;
  left: var(--sidebar-content-offset);
  bottom: 0;
  right: 0;
`

const ContentGrid = styled.div`
  width: var(--application-pane-full-width);
  height: 100%;
  display: grid;
  grid-template-columns: repeat(12, var(--grid-column-width));
  grid-column-gap: 24px;
  margin: 0 auto;
`

const GridColumn = styled.div`
  background: ${colors.translucentWhite30};
`

export const ApplicationGrid: React.FC = () => (
  <FullScreenContainer>
    <Header />
    <HeaderGap />
    <ContentCentering>
      <ContentGrid>
        {Array(12)
          .fill(0)
          .map((_, index) => (
            <GridColumn key={index} />
          ))}
      </ContentGrid>
    </ContentCentering>
  </FullScreenContainer>
)
