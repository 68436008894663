import { keyframes } from "styled-components"

const FADE_BIG_PIXELS = "1250px"

export default {
  /*
    ANIMATE IN
  */

  fadeIn: keyframes`
    from { opacity: 0; }

    to { opacity: 1; }
  `,

  fadeInTop: keyframes`
    from { opacity: 0; transform: translate3d(0,-100%,0); }

    to { opacity: 1; transform: translate3d(0,0,0); }
  `,

  fadeInBottom: keyframes`
    from { opacity: 0; transform: translate3d(0, 100%, 0); }

    to { opacity: 1; transform: translate3d(0,0,0); }
  `,

  fadeInBottomSmall: keyframes`
    from { opacity: 0; transform: translate3d(0, 200px, 0); }

    to { opacity: 1; transform: translate3d(0,0,0); }
  `,

  fadeInLeft: keyframes`
    from { opacity: 0; transform: translate3d(-100%, 0, 0); }

    to { opacity: 1; transform: translate3d(0,0,0); }
  `,

  fadeInRight: keyframes`
    from { opacity: 0; transform: translate3d(100%,0,0); }

    to { opacity: 1; transform: translate3d(0,0,0); }
  `,

  slideInBottom: keyframes`
    from { transform: translate3d(0, 100%, 0); }

    to { transform: translate3d(0,0,0); }
  `,

  slideOutBottom: keyframes`
    from { transform: translate3d(0, 0, 0); }

    to { transform: translate3d(0, 100%, 0); }
  `,

  /*
    Big
  */

  fadeInTopBig: keyframes`
    from { opacity: 0; transform: translate3d(0, -${FADE_BIG_PIXELS}, 0); }

    to { opacity: 1; transform: translate3d(0, 0, 0); }
  `,

  fadeInBottomBig: keyframes`
    from { opacity: 0; transform: translate3d(0, ${FADE_BIG_PIXELS}, 0); }

    to { opacity: 1; transform: translate3d(0, 0, 0); }
  `,

  fadeInLeftBig: keyframes`
    from { opacity: 0; transform: translate3d(-${FADE_BIG_PIXELS}, 0, 0); }

    to { opacity: 1; transform: translate3d(0, 0, 0); }
  `,

  fadeInRightBig: keyframes`
    from { opacity: 0; transform: translate3d(${FADE_BIG_PIXELS}, 0, 0); }

    to { opacity: 1; transform: translate3d(0, 0, 0); }
  `,

  /*
    Small
  */

  fadeInSmall: keyframes`
    from { opacity: 0; transform: translate3d(0, 50%, 0); }

    to { opacity: 1; transform: translate3d(0, 0, 0); }
  `,

  flipInY: keyframes`
    0% {
      opacity: 0;
      transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    }

    40% {
      transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    }

    60% {
      opacity: 1;
      transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    }

    80% {
      opacity: 1;
      transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    }

    100% {
      opacity: 1;
      transform: perspective(400px);
    }
  `,

  flipInYLongEnd: keyframes`
    0% {
      opacity: 0;
      transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    }

    15% {
      transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    }

    25% {
      opacity: 1;
      transform: perspective(400px) rotate3d(0, 1, 0, 5deg);
    }

    35% {
      opacity: 1;
      transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    }

    45% {
      opacity: 1;
      transform: perspective(400px);
    }

    100% {
      opacity: 1;
      transform: perspective(400px);
    }
  `,

  /*
    ANIMATE OUT
  */

  fadeOut: keyframes`
    from { opacity: 1; }

    to { opacity: 0; }
  `,

  fadeOutTop: keyframes`
    from { opacity: 1; transform: translateY(0); }

    to { opacity: 0; transform: translateY(-100%); }
  `,

  fadeOutBottom: keyframes`
    from { opacity: 1; transform: translateY(0); }

    to { opacity: 0; transform: translateY(100%); }
  `,

  fadeOutBottomSmall: keyframes`
    from { opacity: 1; transform: translateY(0); }

    to { opacity: 0; transform: translateY(200px); }
  `,

  fadeOutLeft: keyframes`
    from { opacity: 1; transform: translateX(0); }

    to { opacity: 0; transform: translateX(-100%); }
  `,

  fadeOutRight: keyframes`
    from { opacity: 1; transform: translateX(0); }

    to { opacity: 0; transform: translateX(100%); }
  `,

  /*
    Big
  */

  fadeOutTopBig: keyframes`
    from { opacity: 1; transform: translateY(0); }

    to { opacity: 0; transform: translateY(-${FADE_BIG_PIXELS}); }
  `,

  fadeOutBottomBig: keyframes`
    from { opacity: 1; transform: translateY(0); }

    to { opacity: 0; transform: translateY(${FADE_BIG_PIXELS}); }
  `,

  fadeOutLeftBig: keyframes`
    from { opacity: 1; transform: translateX(0); }

    to { opacity: 0; transform: translateX(-${FADE_BIG_PIXELS}); }
  `,

  fadeOutRightBig: keyframes`
    from { opacity: 1; transform: translateX(0); }

    to { opacity: 0; transform: translateX(${FADE_BIG_PIXELS}); }
  `,

  fadeOutAndInTop: keyframes`
    0% { opacity: 1; transform: translateY(0); }

    33% { opacity: 0; transform: translateY(-100%); }

    75% { opacity: 0; transform: translateY(-100%); }

    100% { opacity: 1; transform: translateY(0); }
  `,

  flipOutY: keyframes`
    0% {
      opacity: 1;
      transform: perspective(400px);
    }

    30% {
      opacity: 1;
      transform: perspective(400px) rotate3d(0, 1, 0, 15deg);
    }

    100% {
      opacity: 0;
      transform: perspective(400px) rotate3d(0, 1, 0, -90deg);
    }
  `,

  /*
    ANIMATE IN THEN OUT
  */

  fadeInAndOut: keyframes`
    0% { opacity: 0.3; }

    50% { opacity: 1; }

    100% { opacity: 0.3; }
  `,

  /*
    SPECIAL
  */

  spin: keyframes`
    to { opacity: 1; transform:rotate(360deg); }
  `,

  spin3D: keyframes`
    0% {
      transform: perspective(1000px) rotateY(0deg);
    }

    100% {
      transform: perspective(1000px) rotateY(360deg);
    }
  `,

  growWidth: keyframes`
    to { width: 100%; }
  `,

  scaleHeight: keyframes`
    from { transform: scaleY(0); }
    to { transform: scaleY(1); }
  `,

  grow: keyframes`
    to { transform: scale(1); }
  `,

  shrink: keyframes`
    to { transform: scale(.6); }
  `,

  shine: keyframes`
    0%, 15% {
      transform: translateY(300%) rotate(-40deg);
    }

    50% {
      transform: translateY(-100%) rotate(-40deg);
    }
  `,

  wiggle: keyframes`
    0% { transform: rotate(-10deg); }
    20% { transform: rotate(8deg); }
    40% { transform: rotate(-6deg); }
    60% { transform: rotate(5deg); }
    80% { transform: rotate(-3deg); }
    90% { transform: rotate(2deg); }
    100% { transform: rotate(0deg); }
  `,

  twinkle: keyframes`
    0% { opacity: .6; }

    10% { opacity: 1; }

    80% { opacity: 1; }

    100% { opacity: .6; }
  `,

  bounce: keyframes`
    0%, 50%, 100% {
      transform: translateY(0);
    }
    25%, 75% {
      transform: translateY(-25px);
    }
  `,

  enlarge: keyframes`
    50% {
      transform: translateZ(0) scale(1.2);
    }

    0%, 100% {
      transform: translateZ(0) scale(1);
    }
  `,

  enlargeSmall: keyframes`
    50% {
      transform: translateZ(0) scale(1.1);
    }

    100% {
      transform: translateZ(0) scale(1);
    }
  `,

  blink: keyframes`
    0% {
      opacity: 0.1;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.1;
    }
  `,
}
