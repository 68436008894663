import * as React from "react"
import { ObjectKind, useReadShareQuery } from "@digits-graphql/frontend/graphql-bearer"
import useSession from "@digits-shared/hooks/useSession"
import { useSharedObjectInfo } from "src/frontend/hooks/useSharedObjectInfo"
import { useSharingIntentId } from "src/frontend/hooks/useSharingIntentId"
import type FrontendSession from "src/frontend/session"

/** Determines shared object permission info from either a share intent, or a link share */
export function useSharedObjectPermission() {
  const session = useSession<FrontendSession>()
  const sharedObjectInfo = useSharedObjectInfo()
  const sharingIntentId = useSharingIntentId()

  const { hasUserData, jwtOrganizations } = session

  // We need to check on object sharing status for this user if:
  //   1) The user is logged in
  //   2) This route can be shared
  //   3) The LE represented in the current URL path is not found in the session
  const needsCheck =
    hasUserData &&
    !!sharedObjectInfo &&
    // NOTE: These last 2 checks prevent a user who is a member of an organization, but only has partial permissions
    // from accepting a sharing invite to an object they do not have permissions to access. There is no way to
    // create such users currently (everyone is an admin in the org or does not belong to it). If that changes, this
    // will need to be updated.
    !!sharedObjectInfo?.legalEntitySlug &&
    !session.findLegalEntityBySlugIn(jwtOrganizations, sharedObjectInfo.legalEntitySlug)

  const { loading, error, data, startPolling, stopPolling } = useReadShareQuery({
    skip: !needsCheck,
    variables: {
      // The fallback values here won't be used, but are required to keep the compiler happy.
      // We know that sharedObjectInfo will be defined if we make a call here, (based on
      // needsCheck) but we can't introduce a conditional before this hook to prove it
      // without upsetting the hook's linter.
      kind: sharedObjectInfo?.kind || ObjectKind.User,
      id: sharedObjectInfo?.id || "",
      intentId: sharingIntentId,
    },
  })

  return React.useMemo(
    () => ({
      needsCheck,
      data: data?.readShare,
      loading,
      error,
      startPolling,
      stopPolling,
    }),
    [needsCheck, data?.readShare, loading, error, startPolling, stopPolling]
  )
}
