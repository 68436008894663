import { JWTPermissionFlag } from "@digits-shared/session/jwt/jwtPermissions"
import type Permissions from "@digits-shared/session/Permissions"
import { useJwtPermissions } from "src/frontend/hooks/useJwtPermissions"
import {
  type FrontendPermissionModule,
  type FrontendPermissionSource,
} from "src/frontend/session/permissionModule"

export interface PermissionCheck {
  flag: JWTPermissionFlag
  module: FrontendPermissionModule
}

/**
 * Returns true if the current context for the source (e.g. Organization or Legal Entity)
 * has all the specified permissions; false otherwise.
 *
 * @param source {FrontendPermissionSource} The source from context whose permissions to check
 * @param checks {PermissionCheck[]} Permission flag and module to check
 */
export function useHasJwtPermissions(
  source: FrontendPermissionSource,
  ...checks: PermissionCheck[]
) {
  const permissions = useJwtPermissions(source)

  return hasJwtPermissions(permissions, ...checks)
}

export function hasJwtPermissions(
  permissions: Permissions<FrontendPermissionModule> | undefined,
  ...checks: PermissionCheck[]
): boolean {
  if (!permissions) return false

  return checks.every((check) => {
    switch (check.flag) {
      case JWTPermissionFlag.Create:
        return permissions.hasCreatePermission(check.module)

      case JWTPermissionFlag.Read:
        return permissions.hasReadPermission(check.module)

      case JWTPermissionFlag.Update:
        return permissions.hasUpdatePermission(check.module)

      case JWTPermissionFlag.Delete:
        return permissions.hasDeletePermission(check.module)

      case JWTPermissionFlag.Sensitive:
        return permissions.hasSensitivePermission(check.module)

      case JWTPermissionFlag.Comment:
        return permissions.hasCommentPermission(check.module)

      case JWTPermissionFlag.Grant:
        return permissions.hasGrantPermission(check.module)

      default:
        throw new Error(`Unhandled JWTPermissionFlag: ${check.flag}`)
    }
  })
}

/** Helper for creating a Create permission check */
export function canCreate(module: FrontendPermissionModule): PermissionCheck {
  return {
    flag: JWTPermissionFlag.Create,
    module,
  }
}

/** Helper for creating a Read permission check */
export function canRead(module: FrontendPermissionModule): PermissionCheck {
  return {
    flag: JWTPermissionFlag.Read,
    module,
  }
}

/** Helper for creating a Update permission check */
export function canUpdate(module: FrontendPermissionModule): PermissionCheck {
  return {
    flag: JWTPermissionFlag.Update,
    module,
  }
}

/** Helper for creating a Delete permission check */
export function canDelete(module: FrontendPermissionModule): PermissionCheck {
  return {
    flag: JWTPermissionFlag.Delete,
    module,
  }
}

/** Helper for creating a Sensitive permission check */
export function canSensitive(module: FrontendPermissionModule): PermissionCheck {
  return {
    flag: JWTPermissionFlag.Sensitive,
    module,
  }
}

/** Helper for creating a Comment permission check */
export function canComment(module: FrontendPermissionModule): PermissionCheck {
  return {
    flag: JWTPermissionFlag.Comment,
    module,
  }
}

/** Helper for creating a Grant permission check */
export function canGrant(module: FrontendPermissionModule): PermissionCheck {
  return {
    flag: JWTPermissionFlag.Grant,
    module,
  }
}
