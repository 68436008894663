import caretHelper from "@digits-shared/helpers/caretHelper"
import envHelper from "@digits-shared/helpers/envHelper"
import escapeHtml from "escape-html"
import {
  DATA_DECORATOR,
  DATA_ENTITY_KIND,
  DATA_ID,
  Decorator,
  DECORATOR_CLASS,
  EntityTagToken,
  NodeValues,
  TagNames,
} from "src/shared/components/EditableContent/editableContentConstants"

/* These must match the backend class names. */
/* taken from `const ENTITY_INSIGHT_STYLES` */
const INSIGHT_STYLE_CLASSES = ["dollar-value", "percentage", "outbound", "inbound"]

export const ContentDecorator = {
  decorate: (content: HTMLElement, decorator?: Decorator) => {
    const currentWord = caretHelper.getTextAtCaretPosition()
    const currentElement = caretHelper.getChildElementAtCaretPosition(content)

    const attributeDecorator = currentElement?.getAttribute(DATA_DECORATOR)
    const currentWords =
      attributeDecorator && typeof currentElement?.innerText === "string"
        ? currentElement.innerText
        : currentWord
    if (!currentWords?.length || !decorator) return closeDecorator(content)

    const firstChar = currentWords.charAt(0)
    switch (true) {
      case (attributeDecorator === Decorator.User || firstChar === EntityTagToken.Mention) &&
        decorator === Decorator.User:
        return decorateEntity(content, currentWords, decorator)

      case (attributeDecorator === Decorator.Party || firstChar === EntityTagToken.Mention) &&
        decorator === Decorator.Party:
        return decorateEntity(content, currentWords, decorator)

      case (attributeDecorator === Decorator.Category || firstChar === EntityTagToken.Mention) &&
        decorator === Decorator.Category:
        return decorateEntity(content, currentWords, decorator)
      default:
        return removeDecorator(content)
    }
  },

  encode: (content: HTMLElement) => {
    const nodes: Node[] = Array.from(content.childNodes)
    const trimmed = trimTrailingNewlines(nodes)
    const text = trimmed.map<string>(encodeNode)
    return text.join("").trim()
  },

  createDecoratedTag,
}

const trimTrailingNewlines = (nodes: Node[]) => {
  let count = nodes.length - 1
  let lastNode = nodes[count]

  while (count > 0) {
    const elem = lastNode as HTMLElement

    // exit early because we are no longer finding empty lines
    if (
      (lastNode?.nodeType === Node.TEXT_NODE && lastNode?.nodeValue?.trim()) ||
      elem?.innerText?.trim()
    ) {
      break
    }

    // keep looking for trailing empty lines
    count -= 1
    lastNode = nodes[count]
  }

  return nodes.slice(0, count + 1)
}

const encodeNode = (node: Node): string => {
  if (node.nodeType !== Node.ELEMENT_NODE) {
    return escapeHtml(node.nodeValue || "")
  }

  const element = node as HTMLElement

  const dataId = element.getAttribute(DATA_ID)
  const decorator = element.getAttribute(DATA_DECORATOR)

  // Element is decorated (Entity), return <Entity|ID|Title> special tag
  if (decorator && Decorator[decorator as Decorator]) {
    const entityKind = element.getAttribute(DATA_ENTITY_KIND) || decorator
    return `<${entityKind}|${dataId}|${element.innerText.replace(EntityTagToken.Mention, "")}>`
  }

  const childNodes: Node[] = Array.from(element.childNodes)
  const childrenHTML = childNodes.map(encodeNode).join("")
  // self-enclosing tags like <BR/>
  if (!childrenHTML) return `<${element.tagName} />`

  const inlineStyle = element.style.cssText ? `style="${element.style.cssText}"` : ""

  // generate list of valid insight-formatting classes to retain colors; ignore unknown classes
  const className = element.classList.length
    ? `class="${Array.from(element.classList.values())
        .filter((c) => INSIGHT_STYLE_CLASSES.includes(c))
        .join(" ")}"`
    : ""

  // Special case for anchors, preserve style if there is but otherwise just return the text without the anchor tag.
  if (element.tagName === TagNames.Anchor) {
    const href = element.getAttribute("href")
    const rel = element.getAttribute("rel")
    return `<${element.tagName} href="${href}" rel="${rel}" ${inlineStyle} ${className}>${childrenHTML}</${element.tagName}>`
  }

  return `<${element.tagName} ${className} ${inlineStyle}>${childrenHTML}</${element.tagName}>`
}

const replaceTextWithNewElement = (
  currentElement: HTMLElement,
  currentWord: string,
  newElement: HTMLElement
) => {
  const caretPosition =
    caretHelper.getCaretPosition(currentElement)?.end ?? currentElement.innerHTML.length
  const spaceAfterCaretPosition = currentElement.innerHTML.indexOf(" ", caretPosition)
  const sentenceEnd =
    spaceAfterCaretPosition > 0 ? spaceAfterCaretPosition : currentElement.innerHTML.length
  const currentSentence = currentElement.innerHTML.substring(0, sentenceEnd)

  const lastIndex = currentSentence.lastIndexOf(currentWord)
  if (lastIndex === -1) {
    if (!envHelper.isProduction()) {
      console.error("word '%s' not found in current element:", currentWord, currentElement)
    }
    return
  }

  const currentHTML = currentElement.innerHTML
  currentElement.innerHTML = currentHTML.substring(0, lastIndex)

  currentElement.appendChild(newElement)

  // After resetting innerHTML property, browser parses the set contents and creates new elements.
  // So in order to keep a "live" reference to newElement (and that its parent points at currentElement)
  // the rest of the html needs to be append it, instead of using currentElement.innerHTML
  const restChildren = document.createElement("template")
  restChildren.innerHTML = currentHTML.substring(lastIndex + currentWord.length, currentHTML.length)
  currentElement.appendChild(restChildren.content)

  caretHelper.placeAtEnd(newElement)
}

const closeDecorator = (content: HTMLElement) => {
  if (
    !content.innerHTML.length ||
    (content.childElementCount === 1 && content.firstElementChild?.tagName === TagNames.Break)
  ) {
    caretHelper.clearSelection()
    return null
  }

  const currentElement = caretHelper.getChildElementAtCaretPosition(content)
  if (!currentElement) return null

  const hasOpenDecorator = currentElement.getAttribute(DATA_DECORATOR)
  if (!hasOpenDecorator) return null

  if (!currentElement.hasAttribute(DATA_ID)) {
    currentElement.insertAdjacentText("beforebegin", currentElement.innerText)
    currentElement.remove()
    return content
  }

  const newSpan = document.createElement(TagNames.Span)
  newSpan.innerHTML = NodeValues.WhiteSpace
  currentElement.insertAdjacentElement("afterend", newSpan)

  newSpan.focus()

  return content
}

const removeDecorator = (content: HTMLElement) => {
  const currentWord = caretHelper.getTextAtCaretPosition()
  const currentElement = caretHelper.getChildElementAtCaretPosition(content)
  if (!currentElement || currentWord?.length !== 1) return null

  const hasOpenDecorator = currentElement.getAttribute(DATA_DECORATOR)
  if (!hasOpenDecorator) return null

  currentElement.insertAdjacentText("beforebegin", currentWord)
  currentElement.remove()

  content.focus()

  return content
}

const decorateEntity = (content: HTMLElement, currentWord: string, decorator: Decorator) => {
  const currentElement = caretHelper.getChildElementAtCaretPosition(content)
  if (!currentElement) return null

  if (currentElement.classList.contains(DECORATOR_CLASS)) {
    return null
  }

  const tag = createDecoratedTag(content, currentWord, decorator)
  replaceTextWithNewElement(currentElement, currentWord, tag)

  return content
}

function createDecoratedTag(content: HTMLElement, currentWord: string, decorator: Decorator) {
  const tag = document.createElement(TagNames.Span)
  tag.classList.add(decorator, DECORATOR_CLASS)
  tag.setAttribute(DATA_DECORATOR, decorator)
  tag.innerHTML = currentWord
  return tag
}
