import * as React from "react"
import { ReportOptionsContext } from "src/frontend/components/Shared/Layout/Components/Statements/ReportOptionsContext"

export const ComponentOptionsContextProvider: React.FC<{
  children?: React.ReactNode
}> = ({ children }) => {
  const { collapsedSections, setCollapsedSections, toggleCollapsedSection } =
    useCollapsingSections()

  const value = React.useMemo(
    () => ({
      loading: false,
      collapsedSections,
      setCollapsedSections,
      toggleCollapsedSection,
    }),
    [collapsedSections, setCollapsedSections, toggleCollapsedSection]
  )

  return <ReportOptionsContext.Provider value={value}>{children}</ReportOptionsContext.Provider>
}

function useCollapsingSections() {
  // Holds a local copy of the collapsed sections
  const [collapsedSections, setCollapsedSections] = React.useState<string[]>([])
  const toggleCollapsedSection = React.useCallback((...rowIds: string[]) => {
    setCollapsedSections((prevState) => {
      let newState = prevState.slice()
      rowIds.forEach((rowId) => {
        const rowIndex = newState.indexOf(rowId)
        if (rowIndex === -1) {
          newState = newState.concat(rowId)
        } else {
          newState.splice(rowIndex, 1)
        }
      })
      return newState
    })
  }, [])

  return React.useMemo(
    () => ({
      collapsedSections: { sections: collapsedSections },
      setCollapsedSections: (...rowIds: string[]) => setCollapsedSections(rowIds),
      toggleCollapsedSection,
    }),
    [collapsedSections, toggleCollapsedSection]
  )
}
