import { themedValue } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"

/*
 STYLES
*/

export const TRANSACTION_LIST_GRID_STYLES = css`
  display: grid;
  grid-template-columns: 55px minmax(0, 1fr) minmax(50px, 100px);
  grid-column-gap: 5px;
  align-items: center;
  padding: 5px 15px;

  width: 100%;
  min-width: 340px;
  font-weight: ${fonts.weight.normal};
`

const headerColor = themedValue({
  light: colors.subtitleGray,
  dark: colors.translucentWhite50,
})

export const TransactionListHeader = styled.div`
  ${TRANSACTION_LIST_GRID_STYLES};
  text-transform: uppercase;
  font-size: 10px;
  color: ${headerColor};

  & > :last-child {
    text-align: right;
  }
`
