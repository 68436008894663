import * as React from "react"
import { type ReactNode } from "react"
import type { ObjectEntities } from "@digits-graphql/frontend/graphql-bearer"
import type { EntityPopOverComponent } from "src/shared/components/ObjectEntities/entityPopOverTypes"
import { NodesToDOMParser } from "src/shared/components/ObjectEntities/NodesToDOMParser"
import { NodesToReactParser } from "src/shared/components/ObjectEntities/NodesToReactParser"

/*
 INTERFACE
*/

interface EditableEntitiesProps {
  editMode: boolean
  text: string
  entities?: ObjectEntities | null
  entityPopOver?: EntityPopOverComponent
  disableHovers?: boolean
  formatChildNode?: (el: HTMLElement) => ReactNode | undefined
}

/*
 FUNCTION
*/

/**
 * Parses text containing entity tags and either:
 * - Returns a DocumentFragment for edit mode
 * - Returns a React component with optional hover functionality for display mode
 */
export const editableEntitiesParser = ({
  text,
  entities,
  editMode,
  entityPopOver,
  disableHovers,
  formatChildNode,
}: EditableEntitiesProps) => {
  if (editMode) {
    return NodesToDOMParser({
      text,
      entities,
      formatChildNode,
    })
  }

  return (
    <NodesToReactParser
      text={text}
      entities={entities}
      entityPopOver={entityPopOver}
      disableHovers={disableHovers}
      formatChildNode={formatChildNode}
    />
  )
}
