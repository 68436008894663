import * as React from "react"
import ScrollMemory from "src/shared/components/ScrollMemory/ScrollMemory"

export interface ChromeScrollMemoryContextProps {
  scrollElement?: React.RefObject<HTMLElement | null>
  setScrollElement: React.SetState<HTMLElement | null>
}

export const ChromeScrollMemoryContext = React.createContext<ChromeScrollMemoryContextProps>({
  setScrollElement: () => undefined,
})

export default function useChromeScrollMemoryContext() {
  const context = React.useContext(ChromeScrollMemoryContext)

  // Memoize so that a new object is only returned if something changes
  return React.useMemo(() => context, [context])
}

export const ChromeScrollMemory: React.FC<React.PropsWithChildren> = ({ children }) => {
  const scrollElement = React.useRef<HTMLElement | null>(null)
  const setElementReady = React.useRef<() => void>()

  const setScrollElement = React.useCallback(
    (element: HTMLElement | null) => {
      scrollElement.current = element
      if (element) {
        setElementReady.current?.()
      }
    },
    [scrollElement]
  )

  const elementReady = new Promise<void>((resolve) => {
    setElementReady.current = resolve
  })

  const context: ChromeScrollMemoryContextProps = React.useMemo(
    () => ({
      scrollElement,
      setScrollElement,
    }),
    [setScrollElement]
  )

  return (
    <>
      <ChromeScrollMemoryContext.Provider value={context}>
        {children}
      </ChromeScrollMemoryContext.Provider>
      <ScrollMemory scrollElement={scrollElement} ready={elementReady} behavior="auto" />
    </>
  )
}
