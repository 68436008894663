import * as React from "react"
import { chevronStyles, PointingDirection } from "@digits-shared/components/UI/Elements/Chevron"
import {
  InputBlock,
  type InputProps,
  type InputVariant,
} from "@digits-shared/components/UI/Elements/Form/Input"
import {
  INPUT_BORDER_FOCUS_STYLES,
  INPUT_BORDER_STYLES,
  INPUT_INVALID_CLASS_NAME,
} from "@digits-shared/components/UI/Elements/Form/InputStyles"
import { InputLabel, InputSaveStatus } from "@digits-shared/components/UI/Elements/Form/Label"
import { defined } from "@digits-shared/helpers/filters"
import { themedValue } from "@digits-shared/themes"
import borders from "@digits-shared/themes/borders"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"

/*
 BASIC DROPDOWN
*/

type WrapperStyles = {
  size?: number
  disabled?: boolean
  isSet?: boolean
}

const TRANSPARENT_WRAPPER_STYLES = css<WrapperStyles & { $variant?: InputVariant }>`
  background: ${colors.transparent};
  box-shadow: none;
  ${INPUT_BORDER_STYLES};

  transition:
    color 250ms ease-out,
    border-color 250ms ease-out,
    background 250ms ease-out,
    box-shadow 250ms ease-out;

  &:not(:disabled) {
    color: ${(props) => (props.isSet !== false ? colors.white : colors.translucentWhite20)};
  }

  &::after {
    ${(props) =>
      chevronStyles(
        PointingDirection.Down,
        `${props.size || 4}px`,
        `${props.disabled ? colors.lightGray : colors.secondary}`
      )}
  }

  ${({ disabled }) => css`
    ${!disabled &&
    css`
      &:hover,
      &:focus,
      &:focus-within {
        ${INPUT_BORDER_FOCUS_STYLES};
      }
    `}

    ${disabled &&
    css`
      color: ${colors.lightGray};
      background: ${colors.translucentWhite20};
      border: 1px solid ${colors.theme.dark.border};
      opacity: 0.7;
      pointer-events: none;
    `}
  `};
`

export const DropdownWrapper = styled.div<WrapperStyles>`
  border-radius: ${borders.radius.default}px;
  display: inline-block;
  position: relative;
  width: 100%;

  &::after {
    position: absolute;
    right: 9px;
    top: 50%;
    margin-top: -2px;
    pointer-events: none;
    transition: border-color 250ms ease-out;
  }

  ${TRANSPARENT_WRAPPER_STYLES};
`

export const DeprecatedDropdown = styled.select`
  padding: 10px 30px 10px 2px;
  font-weight: ${fonts.weight.medium};
  font-size: 13px;
  height: 40px;

  width: 100%;
  cursor: pointer;
  border: none;
  appearance: none;
  outline: none;
  background: ${colors.transparent};
  color: ${themedValue({ light: colors.secondary, dark: colors.white })};

  &:disabled {
    pointer-events: none;
    color: ${colors.translucentSecondary30};
  }
`

/*
 DROPDOWN WITH LABEL
*/

export const DropdownWithLabelWrapper = styled(DropdownWrapper)<{ $variant?: InputVariant }>`
  width: 100%;
  border-color: transparent;

  ${(props) => props.disabled && `background-color: ${colors.lightGray};`}
  select {
    padding: 10px 30px 10px 2px;
    ${INPUT_BORDER_STYLES};
  }
`

type DropdownElementProps = InputProps & { className?: string } & React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > & {
    forwardRef?: React.RefObject<HTMLSelectElement>
    isSet?: boolean
  }

export const DropdownWithLabel: React.FC<DropdownElementProps> = (props) => {
  const {
    ref,
    forwardRef,
    isInvalid,
    disabled,
    saveStatus,
    onAnimationEnd,
    label,
    inputWidth,
    className,
    isSet,
    ...rest
  } = props
  const pending = saveStatus === InputSaveStatus.Pending

  const classNames = [className, isInvalid ? INPUT_INVALID_CLASS_NAME : undefined]
    .filter(defined)
    .join(" ")

  return (
    <InputBlock className={classNames} inputWidth={inputWidth}>
      {label && (
        <InputLabel label={label} saveStatus={saveStatus} onAnimationEnd={onAnimationEnd} />
      )}
      <DropdownWithLabelWrapper isSet={isSet}>
        <DeprecatedDropdown
          disabled={disabled || pending}
          ref={forwardRef}
          autoComplete="off"
          {...rest}
        />
      </DropdownWithLabelWrapper>
    </InputBlock>
  )
}
