import * as React from "react"
import { PopoverMutexContextProvider } from "@digits-shared/components/UI/Elements/PopUp/PopOverMutexContext"
import { OSContent } from "src/frontend/components/OS/OSContent"
import { OSDetails } from "src/frontend/components/OS/OSDetails"
import { TermsOfServiceCheck } from "src/frontend/components/OS/Shared/TermsOfService/TermsOfServiceCheck"
import { ChromeRenderer } from "src/frontend/components/Shared/Chrome/ChromeRenderer"
import { ViewTypeContextProvider } from "src/frontend/components/Shared/Contexts/ViewTypeContext"
import { ViewVersionContextProvider } from "src/frontend/components/Shared/Contexts/ViewVersionContextProvider"
import { useBlockMobile } from "src/frontend/components/Shared/Responsive/BlockMobile"

/*
  COMPONENTS
*/

export const OSChrome: React.FC = () => {
  const blockedContent = useBlockMobile()
  if (blockedContent) return blockedContent

  return (
    <OSChromeGlobalContexts>
      <ChromeRenderer ContentView={OSContent} DetailsView={OSDetails} />
    </OSChromeGlobalContexts>
  )
}

const OSChromeGlobalContexts: React.FC<React.PropsWithChildren> = ({ children }) => (
  <ViewTypeContextProvider>
    <ViewVersionContextProvider>
      <TermsOfServiceCheck />
      <PopoverMutexContextProvider>{children}</PopoverMutexContextProvider>
    </ViewVersionContextProvider>
  </ViewTypeContextProvider>
)
