import * as React from "react"
import { useInvertValues } from "@digits-shared/components/Contexts/InvertValuesContext"
import {
  Delta,
  type DeltaProps,
  type DirectionForValueProps,
} from "@digits-shared/components/UI/Elements/Delta"
import { Pill } from "@digits-shared/components/UI/Elements/Pill"
import deltaHelper from "@digits-shared/helpers/deltaHelper"
import styled from "styled-components"

/*
  STYLES
*/

const DeltaWithChevronStyled = styled(Pill)<StyledProps & DirectionForValueProps>`
  background-color: ${(props) =>
    props.noBackground ? "unset" : deltaHelper.deltaValueColor(props)};
  ${({ size }) => size && `font-size: ${size};`}
  ${({ noBackground }) => noBackground && "padding: 0;"}
`

const DeltaStyled = styled(Delta)`
  justify-content: flex-end;
`

/*
  INTERFACE
*/

interface StyledProps {
  noBackground?: boolean
  size?: string
}

interface DeltaWithChevronProps {
  noBackground?: boolean
}

/*
  COMPONENT
*/

export const DeltaWithChevron: React.FC<DeltaWithChevronProps & DeltaProps> = (props) => {
  const { noBackground, children, className, ...deltaProps } = props
  const { amount, delta, size, isNew, hasChevron }: DeltaProps = deltaProps
  const invertValues = useInvertValues()

  return (
    <DeltaWithChevronStyled
      className={className}
      noBackground={noBackground}
      size={size}
      amount={amount}
      invertValues={invertValues}
      delta={delta}
    >
      <DeltaStyled
        amount={amount}
        delta={delta}
        size={size}
        isNew={isNew}
        colorInChevron={!!noBackground}
        hasChevron={hasChevron}
      />
    </DeltaWithChevronStyled>
  )
}
