import * as React from "react"
import {
  type EntityLegalEntity,
  type EntityTransaction,
} from "@digits-graphql/frontend/graphql-bearer"
import { InvertValuesContext } from "@digits-shared/components/Contexts/InvertValuesContext"
import borders from "@digits-shared/themes/borders"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import { SharedTransactionRow } from "src/frontend/components/OS/Applications/SharedWithMe/Entities/EntityRows/SharedTransactionRow"
import {
  LegalEntitiesContainer,
  LegalEntityRows,
  useFilterByLegalEntity,
} from "src/frontend/components/OS/Applications/SharedWithMe/Entities/Shared"
import { type LegalEntitySharedEntities } from "src/frontend/components/OS/Applications/SharedWithMe/Shared"
import { invertValuesForCategory } from "src/shared/helpers/categoryHelper"

/*
  STYLES
*/

const TransactionsContainer = styled.div`
  width: 100%;
  margin-top: 8px;
  background: linear-gradient(180deg, rgba(29, 87, 119, 0.03) 0%, rgba(2, 79, 103, 0.0231) 100%);
  box-shadow: 0 0 10px ${colors.translucentBlack20};
  backdrop-filter: blur(40px);
  border-radius: ${borders.radius.default}px;
`

/*
  COMPONENTS
*/
export const SharedTransactions: React.FC<{
  transactionEntities: LegalEntitySharedEntities<EntityTransaction>[]
  selectedLegalEntity?: EntityLegalEntity
}> = ({ transactionEntities, selectedLegalEntity }) => {
  const filterTransactions = useFilterByLegalEntity(transactionEntities, selectedLegalEntity)

  return (
    <LegalEntitiesContainer>
      {filterTransactions.map(({ legalEntity, entities }) => (
        <LegalEntityRows key={legalEntity.id} legalEntity={legalEntity}>
          <TransactionsContainer>
            {entities.map((sharedTransaction, idx) => (
              <InvertValuesContext.Provider
                key={idx}
                value={invertValuesForCategory(sharedTransaction.entity.displayCategory)}
              >
                <SharedTransactionRow
                  key={idx}
                  transaction={sharedTransaction.entity}
                  legalEntity={legalEntity}
                  viewType={sharedTransaction.viewType}
                />
              </InvertValuesContext.Provider>
            ))}
          </TransactionsContainer>
        </LegalEntityRows>
      ))}
    </LegalEntitiesContainer>
  )
}
