import * as React from "react"
import { usePrevious } from "react-use"
import hoistStatics from "hoist-non-react-statics"

export interface PageScrollProps {
  pageScroll: PageScrollContextProps
}

export interface PageScrollContextProps {
  // Use getter so who ever needs to reference the scroll element can request it ad hoc
  getScrollElement: () => HTMLElement | null
}

export const PageScrollContext = React.createContext<PageScrollContextProps>({
  getScrollElement: () => null,
})

export function useBuildPageScrollContext() {
  const [_, setLastContextUpdate] = React.useState(Date.now())
  // Provide a scroll context so infinite scrolling works.
  const browserScrollContainer = React.useRef<HTMLDivElement | null>(null)
  const previousBrowserScrollContainer = usePrevious(browserScrollContainer.current)
  const pageScrollContext = React.useMemo<PageScrollContextProps>(
    () => ({
      getScrollElement: () => browserScrollContainer.current || null,
    }),
    []
  )

  // To avoid a race condition between the in memory react structures and the dom use useEffect
  // instead of useEffectOnce. This will ensure that we are able to reference the correct ref
  // in getScrollElement. Checking if browserScrollContainer.current has changed protects
  // against infinite updates.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => {
    if (browserScrollContainer.current !== previousBrowserScrollContainer) {
      setLastContextUpdate(Date.now())
    }
  })

  return { browserScrollContainer, pageScrollContext }
}

/**
 * Wrap a React Component Type (Class or Function) in higher order component that will
 * automatically pull the Page Scroll from the context and pass as an additional
 * prop to provided Component Type.
 *
 * TODO: NOTE: Currently will throw TS compile error if React Class Component uses default props.
 * @param {React.ComponentType<P>} Component to be wrapped
 */
export function withPageScrollContext<P>(Component: React.ComponentType<P & PageScrollProps>) {
  const BoundComponent = React.forwardRef<{}, React.PropsWithChildren<P>>((props, ref) => (
    <PageScrollContext.Consumer>
      {(value) => <Component ref={ref} {...(props as P)} pageScroll={value} />}
    </PageScrollContext.Consumer>
  ))

  BoundComponent.displayName = `withPageScrollContext(${Component.displayName || Component.name})`

  return hoistStatics(BoundComponent, Component)
}
