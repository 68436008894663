import * as React from "react"
import { useReadLegalEntityMetadataQuery } from "@digits-graphql/frontend/graphql-bearer"
import useSession from "@digits-shared/hooks/useSession"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/useViewVersion"
import type FrontendSession from "src/frontend/session"

export function useFirstTransactionTimestamp() {
  const viewKey = useViewVersion()
  const { isSharingContextActive } = useSession<FrontendSession>()
  const { data } = useReadLegalEntityMetadataQuery({
    variables: {
      viewKey,
    },
    skip: !viewKey || isSharingContextActive,
  })

  return React.useMemo(
    () => data?.readLegalEntityMetadata?.firstTransactionTimestamp || 0,
    [data?.readLegalEntityMetadata?.firstTransactionTimestamp]
  )
}
