import * as React from "react"
import { type ApolloClient, type NormalizedCacheObject, useApolloClient } from "@apollo/client"
import { createScope, molecule, use } from "bunshi"
import { ScopeProvider } from "bunshi/react"
import { atom } from "jotai"

export const ApolloClientScope = createScope(
  undefined as unknown as ApolloClient<NormalizedCacheObject>
)

export const ApolloClientMolecule = molecule(() => ({
  apolloClientAtom: atom<ApolloClient<NormalizedCacheObject>>(use(ApolloClientScope)),
}))

export const ApolloClientScopeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const client = useApolloClient()

  return (
    <ScopeProvider scope={ApolloClientScope} value={client}>
      {children}
    </ScopeProvider>
  )
}
