import * as React from "react"
import { type EntityLegalEntity } from "@digits-graphql/frontend/graphql-bearer"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import {
  ALL_BUSINESSES,
  LEGAL_ENTITY_CONTAINER_HORIZONTAL_PADDING,
  type LegalEntitySharedEntities,
} from "src/frontend/components/OS/Applications/SharedWithMe/Shared"
import { WIDE_WIDGET_PADDING } from "src/frontend/components/OS/Shared/Widgets/WidgetContainer"
import { EntityIcon } from "src/shared/components/ObjectEntities/EntityIcon"

export const PAGE_HEADER_NAV_HEIGHT = 50

/*
  STYLES
*/

export const LegalEntitiesContainer = styled.div`
  overflow-y: auto;
  padding: ${WIDE_WIDGET_PADDING}px ${WIDE_WIDGET_PADDING}px 20px ${WIDE_WIDGET_PADDING}px;
  min-height: 400px;
  max-height: calc(100vh - 134px);
`

const LegalEntityContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
`

const LegalEntityHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.05);
  padding: ${LEGAL_ENTITY_CONTAINER_HORIZONTAL_PADDING}px;
  font-size: 14px;
  font-weight: ${fonts.weight.heavy};
`

const LegalEntityLabel = styled.div`
  font-size: 14px;
  font-weight: ${fonts.weight.heavy};
`

/*
  COMPONENTS
*/

export const LegalEntityRows: React.FC<{
  legalEntity: EntityLegalEntity
  children?: React.ReactNode
}> = ({ legalEntity, children }) => (
  <LegalEntityContainer>
    <LegalEntityHeader>
      {/* TODO: Thread through organization to access icon url here */}
      <EntityIcon imageUrl="" fontSize={12} size={28} css="margin: 0 10px 0 0;">
        {legalEntity.name}
      </EntityIcon>
      <LegalEntityLabel>{legalEntity?.name}</LegalEntityLabel>
    </LegalEntityHeader>
    {children}
  </LegalEntityContainer>
)

/*
  FUNCTIONS
*/

export function useFilterByLegalEntity<E>(
  objectsWithLegalEntity: LegalEntitySharedEntities<E>[],
  selectedLegalEntity?: EntityLegalEntity
) {
  return React.useMemo(
    () =>
      selectedLegalEntity && selectedLegalEntity.id !== ALL_BUSINESSES.id
        ? objectsWithLegalEntity.filter((o) => o.legalEntity?.id === selectedLegalEntity.id)
        : objectsWithLegalEntity,
    [objectsWithLegalEntity, selectedLegalEntity]
  )
}
