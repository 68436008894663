import React from "react"
import { isNetworkRequestInFlight, type NetworkStatus } from "@apollo/client/core/networkStatus"
import {
  type Category,
  type CategoryType,
  type EntityCategory,
  ObjectKind,
  useListCategoriesQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import { defined } from "@digits-shared/helpers/filters"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/useViewVersion"
import { type CategoryTypeaheadResult } from "src/shared/components/Typeahead/sharedTypeahead"
import { sortCategoriesByDisplayNumber } from "src/shared/helpers/hierarchicalCategoryHelper"
import {
  convertHierarchicalDimensionTreeToFlatList,
  convertToHierarchicalDimensionTree,
  getHierarchicalDimensionAncestors,
  type HierarchicalDimensionNode,
} from "src/shared/helpers/hierarchicalDimensionHelper"

export function useTypeaheadCategories(
  text: string,
  kind: ObjectKind,
  includedTypes: CategoryType[] | undefined
) {
  const { result, networkStatus } = useCategoriesList()

  const categories = React.useMemo(
    () => buildCategoryResults(result, text, includedTypes),
    [includedTypes, result, text]
  )

  return React.useMemo(
    () => ({
      loading: isNetworkRequestInFlight(networkStatus),
      results: categories,
    }),
    [networkStatus, categories]
  )
}

function useCategoriesList(): {
  result: Category[]
  networkStatus: NetworkStatus
} {
  const viewKey = useViewVersion()
  const response = useListCategoriesQuery({
    variables: { viewKey },
  })

  const { networkStatus } = response
  const result = response.loading ? response.previousData : response.data

  return {
    result: result?.listCategories || [],
    networkStatus,
  }
}

function convertToEntityCategories(categories: Category[]): EntityCategory[] {
  return categories.toSorted(sortCategoriesByDisplayNumber).map<EntityCategory>((c) => ({
    ...c,
    chartOfAccountsDisplayNumber: c.displayNumber,
    __typename: "EntityCategory",
  }))
}

function buildAncestorMaps(flatTreeList: HierarchicalDimensionNode<Category>[]) {
  const categoryAncestors = new Map<string, EntityCategory[]>()
  const categoryIdToHierarchyText = new Map<string, string>()

  flatTreeList.forEach((node) => {
    const ancestorCategories = getHierarchicalDimensionAncestors(node).map((a) => a.dimension)
    const categoryId = node.dimension.id

    categoryAncestors.set(categoryId, ancestorCategories)
    categoryIdToHierarchyText.set(categoryId, ancestorCategories.map((anc) => anc.name).join(" ▸ "))
  })

  return { categoryAncestors, categoryIdToHierarchyText }
}

function categoryToTypeaheadResult(
  category: Category,
  hierarchyText: string | undefined
): CategoryTypeaheadResult {
  return {
    id: category.id,
    title: category.name,
    depth: category.ancestors?.length ?? 0,
    subtitle: [category.displayNumber, hierarchyText].filter(defined).join(" - "),
    searchValues: [category.name, category.displayNumber].filter(defined),
    kind: ObjectKind.Category,
    entity: category,
    isSelectable: category.synthesized !== true,
  }
}

function buildCategoryResults(
  categories: Category[],
  query: string,
  includedTypes: CategoryType[] | undefined
) {
  const entityCategories = convertToEntityCategories(categories)

  const categoryTree = convertToHierarchicalDimensionTree(entityCategories, 0)
  const flatTreeList = convertHierarchicalDimensionTreeToFlatList<Category>(categoryTree)

  const { categoryIdToHierarchyText } = buildAncestorMaps(flatTreeList)

  return flatTreeList
    .map((node) => node.dimension)
    .filter((category) => category.active)
    .filter((category) => !includedTypes || includedTypes.includes(category.type))
    .map((category) =>
      categoryToTypeaheadResult(category, categoryIdToHierarchyText.get(category.id))
    )
}
