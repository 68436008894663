import * as React from "react"
import { Link } from "react-router-dom"
import { SvgDigitsLogo } from "@digits-shared/components/SVGIcons/digits/DigitsLogo.svg"
import { CenteredDialogContainer } from "@digits-shared/components/UI/Elements/Container"
import { POP_UP_LIGHT_BACKGROUND_STYLES } from "@digits-shared/components/UI/Elements/PopUp/PopUp"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import routes from "src/frontend/routes"

/*
  STYLES
*/

const NotFoundStyled = styled.div`
  padding-top: 100px;
`

const NotFoundContainer = styled(CenteredDialogContainer)`
  ${POP_UP_LIGHT_BACKGROUND_STYLES};
  box-shadow: 0 0 20px ${colors.translucentSecondary20};
  margin: 0 auto;
`

const Logo = styled(SvgDigitsLogo)`
  width: 150px;
  height: 150px;
  fill: ${colors.secondary};
`

const Message = styled.div`
  font-weight: ${fonts.weight.normal};
  height: 45px;
  font-size: 60px;
  color: ${colors.secondary};

  a:hover {
    text-decoration: underline;
  }
`

/*
  COMPONENTS
*/

const NotFound: React.FC = () => (
  <NotFoundStyled>
    <NotFoundContainer>
      <Link to={routes.root.generate()}>
        <Logo />
      </Link>
      <Message>404</Message>
    </NotFoundContainer>
  </NotFoundStyled>
)

export default NotFound
