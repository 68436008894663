import * as React from "react"
import { ColorIcon } from "@digits-shared/components/UI/Elements/ColorIcon"
import useSession from "@digits-shared/hooks/useSession"
import styled from "styled-components"
import { useShouldBlockBranding } from "src/frontend/components/OS/Applications/AccountantSettings/Shared"
import { useCurrentAffiliatedOrganizationIcon } from "src/frontend/components/Shared/Portals/hooks/useCurrentAffiliatedOrganizationIcon"
import type FrontendSession from "src/frontend/session"

interface LegalEntityLogoProps {
  imageUrl?: string
  fallbackText?: string
  size: number
  fontSize?: number
  className?: string
}

type CurrentLegalEntityLogoProps = Omit<LegalEntityLogoProps, "imageUrl" | "fallbackText">

const Logo = styled(ColorIcon)`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CurrentLegalEntityLogo: React.FC<CurrentLegalEntityLogoProps> = ({
  size,
  fontSize,
  className,
}) => {
  const { currentLegalEntity, isAffiliatedSession, currentOrganization } =
    useSession<FrontendSession>()
  const affiliatedOrganizationIcon = useCurrentAffiliatedOrganizationIcon()
  const shouldBlockBranding = useShouldBlockBranding()

  if (isAffiliatedSession) {
    return (
      <Logo
        className={className}
        imageUrl={shouldBlockBranding ? undefined : affiliatedOrganizationIcon}
        fallbackText={currentLegalEntity?.name}
        size={size}
        fontSize={fontSize}
      />
    )
  }

  return (
    <Logo
      className={className}
      imageUrl={shouldBlockBranding ? undefined : currentOrganization?.iconUrl}
      fallbackText={currentLegalEntity?.name}
      size={size}
      fontSize={fontSize}
    />
  )
}

export const LegalEntityLogo: React.FC<LegalEntityLogoProps> = ({
  imageUrl,
  fallbackText,
  size,
  fontSize,
  className,
}) => (
  <Logo
    className={className}
    imageUrl={imageUrl}
    fallbackText={fallbackText}
    size={size}
    fontSize={fontSize}
  />
)
