import * as React from "react"
import { TransactionRecordType } from "@digits-graphql/frontend/graphql-bearer"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgFile02 } from "@digits-shared/components/SVGIcons/line/File02.svg"
import { type IconSize, IconSVGContainer } from "@digits-shared/components/UI/Icons/Icon"
import colors from "@digits-shared/themes/colors"
import styled, { css } from "styled-components"
import { SVGIconComponent } from "src/shared/components/Icons/SVGIcon"
import { DEFAULT_PARTY_ICON_SIZE } from "src/shared/components/PartyHover"

/*
  STYLES
*/

const SVG_STYLES = css<{ size?: number }>`
  width: ${({ size }) => (size || 0) * 0.6}px;
  height: ${({ size }) => (size || 0) * 0.6}px;
  fill: ${colors.white};

  *[stroke-miterlimit] {
    stroke: ${colors.white};
  }
`

export const CategoryIconContainer = styled.div<{ size?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;

  svg {
    ${SVG_STYLES};
  }
`

export const CategoryJournalEntryIcon = styled(SvgFile02)<{ size?: number }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  margin-left: 2px;
  padding: 0 2px;
  ${svgPathStyles(colors.rowDescription, 1.5)};
`

/*
  INTERFACE
*/

interface CategoryIconDisplayFields {
  name?: string
  displayKey?: string
}

interface Props {
  category: CategoryIconDisplayFields | undefined | null
  recordType?: TransactionRecordType
  iconSize?: IconSize
  className?: string
}

/*
  COMPONENT
*/

export const TransactionCategoryIcon: React.FC<Props> = ({
  category,
  recordType,
  iconSize = DEFAULT_PARTY_ICON_SIZE,
  className,
}) => {
  const size = iconSize.pixels

  if (recordType === TransactionRecordType.Journal) {
    return <CategoryJournalEntryIcon size={size} />
  }

  return (
    <CategoryIconContainer className={className} size={size}>
      <IconSVGContainer>
        <SVGIconComponent subjectDisplayKey={category?.displayKey || category?.name || ""} />
      </IconSVGContainer>
    </CategoryIconContainer>
  )
}
