import * as React from "react"
import { Anchor } from "@digits-shared/DesignSystem/Anchor"
import { themedValue } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-family: ${fonts.family.avenir};
  color: ${themedValue({ light: colors.secondary60, dark: colors.translucentWhite50 })};
  gap: 4px;
  padding: 25px 0px 15px;
`

const Links = styled.div`
  display: flex;
  gap: 8px;
`

const termsOfService = "/legal/terms-of-service"
const privacyPolicy = "/legal/privacy-policy"

export const CopyrightFooter: React.FC<{ className?: string }> = ({ className }) => (
  <Footer className={className}>
    <div>© {new Date().getFullYear()} Digits Financial, Inc.</div>
    <div>Digits® is a registered trademark of Digits Financial, Inc.</div>
    <Links>
      <Anchor popup href={termsOfService}>
        Terms of Service
      </Anchor>
      <Anchor popup href={privacyPolicy}>
        Privacy Policy
      </Anchor>
    </Links>
  </Footer>
)
