import * as React from "react"

type Target = Element | Document
type Ref<E extends Target> = React.RefObject<E | undefined | null>
type Args<E extends Target> = Parameters<E["addEventListener"]>

// Similar to use react-use/useEvent but the target is a RefObject
export function useRefEvent<E extends Target>(
  ref: Ref<E> | undefined,
  ...[type, listener, options]: Args<E>
) {
  React.useEffect(() => {
    const target = ref?.current
    if (!target) return

    target.addEventListener(type, listener, options)
    return () => {
      target.removeEventListener(type, listener, options)
    }
  })
}
