import * as React from "react"
import { useComponentIntervalOrigin } from "src/frontend/components/Shared/Layout/hooks/useComponentIntervalOrigin"
import { useReportIntervalOrigin } from "src/frontend/components/Shared/Reports/Report/hooks/useReportIntervalOrigin"

export function useReportComponentIntervalOrigin(intervalCount?: number) {
  const reportOrigin = useReportIntervalOrigin(intervalCount)
  const componentOrigin = useComponentIntervalOrigin(intervalCount)

  return React.useMemo(() => componentOrigin ?? reportOrigin, [componentOrigin, reportOrigin])
}
