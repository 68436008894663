export const URL_REGEX =
  /((?:https?\\?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b[-a-zA-Z0-9()@:%_+.~#?&//=]*)/i

export default {
  fullDashboardURL(pathname: string) {
    const protocol = process.env.PROTOCOL
    const host = process.env.HOST
    const port = process.env.PORT === "80" ? "" : `:${process.env.PORT}`
    return new URL(pathname, `${protocol}://${host}${port}`)
  },

  fullDashboardURLString(pathname: string) {
    return this.fullDashboardURL(pathname).toString()
  },

  isValidHttpUrl(text: string) {
    let url

    try {
      url = new URL(text)
    } catch {
      return false
    }

    return url.protocol === "http:" || url.protocol === "https:"
  },

  addSearchParams(pathname: string, params: Record<string, string | number>) {
    const url = this.fullDashboardURL(pathname)
    if (!url) return undefined
    for (const p in params) {
      url.searchParams.set(p, params[p]?.toString() ?? "")
    }
    return url.pathname + url.search
  },
}
