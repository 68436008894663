import colors from "@digits-shared/themes/colors"
import { curveStepAfter } from "@visx/curve"
import { type CurveFactory } from "d3-shape"

export interface TimeseriesAxisStyle {
  tickFill: string
  axisFill: string
  gridStroke: string
  yAxisStroke: string
}

export interface TimeseriesLineChartStyle extends TimeseriesAxisStyle {
  lineStroke: string
  lineGradientStart: string
  lineGradientEnd: string
  lineNegativeGradientStart: string
  lineNegativeGradientEnd: string
  lineGradientStops?: string[]
  curve?: CurveFactory
}

export interface TimeseriesBarChartStyle extends TimeseriesAxisStyle {
  barFillColor: string
  barFillBottomColor: string
  barFillNegativeColor: string
  barFillNegativeBottomColor: string

  barFillHighlightedColor: string
  barFillHighlightedBottomColor: string

  barFillSelectedColor: string
  barFillSelectedBottomColor: string
  barFillSelectedNegativeColor: string
  barFillSelectedNegativeBottomColor: string

  barFillInactiveColor: string
  barFillInactiveBottomColor: string
}

export const SharedBarChartStyles: TimeseriesBarChartStyle = {
  tickFill: colors.secondary,
  axisFill: colors.secondary20,
  gridStroke: colors.secondary20,
  yAxisStroke: colors.secondary20,

  barFillColor: "#89E3E9",
  barFillBottomColor: "#AEF2D6",
  barFillNegativeColor: "#F6DFAC",
  barFillNegativeBottomColor: "#FDB9AB",

  barFillHighlightedColor: "#6FE7E9",
  barFillHighlightedBottomColor: "#ACF2DC",

  barFillSelectedColor: "#33E4C5",
  barFillSelectedBottomColor: "#71F5BB",
  barFillSelectedNegativeColor: "#FCE6B6",
  barFillSelectedNegativeBottomColor: "#FF997A",

  barFillInactiveColor: colors.secondary20,
  barFillInactiveBottomColor: colors.secondary20,
}

export const SharedLineChartStyles: TimeseriesLineChartStyle = {
  tickFill: colors.secondary,
  axisFill: colors.secondary20,
  gridStroke: colors.secondary20,
  yAxisStroke: colors.secondary20,

  lineStroke: colors.secondary50,
  lineGradientStart: "#22D6E4",
  lineGradientEnd: "#71F5BB",
  lineNegativeGradientStart: "#FDB9AB",
  lineNegativeGradientEnd: "#F6DFAC",
}

export const RunwayLineChartStyles: TimeseriesLineChartStyle = {
  tickFill: colors.secondary,
  axisFill: colors.secondary20,
  gridStroke: colors.secondary20,
  yAxisStroke: colors.secondary20,

  lineStroke: colors.secondary50,
  lineGradientStart: "black",
  lineGradientEnd: "black",
  lineNegativeGradientStart: "black",
  lineNegativeGradientEnd: "black",
  curve: curveStepAfter,
}
