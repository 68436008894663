import { PartyDesignation } from "@digits-graphql/frontend/graphql-bearer"

export enum PartyDesignationFilter {
  All = "All Types",
  Businesses = "Businesses",
  Individuals = "Individuals",
}

export const PARTY_DESIGNATION_FILTER_LOOKUP = new Map<
  PartyDesignationFilter,
  Map<PartyDesignation, boolean>
>()
  .set(
    PartyDesignationFilter.All,
    new Map<PartyDesignation, boolean>()
      .set(PartyDesignation.BusinessDesignation, true)
      .set(PartyDesignation.SMBMealsDesignation, true)
      .set(PartyDesignation.SMBOtherDesignation, true)
      .set(PartyDesignation.PeopleDesignation, true)
      .set(PartyDesignation.IgnoredDesignation, true)
      .set(PartyDesignation.InvalidDesignation, true)
      .set(PartyDesignation.UnknownDesignation, true)
  )
  .set(
    PartyDesignationFilter.Businesses,
    new Map<PartyDesignation, boolean>()
      .set(PartyDesignation.BusinessDesignation, true)
      .set(PartyDesignation.SMBMealsDesignation, true)
      .set(PartyDesignation.SMBOtherDesignation, true)
  )
  .set(
    PartyDesignationFilter.Individuals,
    new Map<PartyDesignation, boolean>().set(PartyDesignation.PeopleDesignation, true)
  )

export const DEFAULT_PARTY_DESIGNATION_FILTER = PartyDesignationFilter.All
