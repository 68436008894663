import * as React from "react"
import * as ReactDOM from "react-dom"
import { type Employee, type Role } from "@digits-graphql/frontend/graphql-bearer"
import { LoadingBlock, LoadingCircle } from "@digits-shared/components/Loaders"
import { svgIconStyles, svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgEdit02 } from "@digits-shared/components/SVGIcons/line/Edit02.svg"
import { SvgDotsVerticalSolid } from "@digits-shared/components/SVGIcons/solid/DotsVerticalSolid.svg"
import { SvgLock01Solid } from "@digits-shared/components/SVGIcons/solid/Lock01Solid.svg"
import { SvgLock02Solid } from "@digits-shared/components/SVGIcons/solid/Lock02Solid.svg"
import { ColorIcon } from "@digits-shared/components/UI/Elements/ColorIcon"
import { H3 } from "@digits-shared/components/UI/Elements/Header"
import { Modal, ModalAvatar } from "@digits-shared/components/UI/Elements/Modals/Modal"
import {
  PageScrollContext,
  useBuildPageScrollContext,
} from "@digits-shared/components/UI/Elements/PageScrollContext"
import { ButtonRow, DigitsButton } from "@digits-shared/DesignSystem/Button"
import { DeferredPromise } from "@digits-shared/helpers/promises/DeferredPromise"
import stringHelper from "@digits-shared/helpers/stringHelper"
import userHelper, { UserRoleFullyQualified } from "@digits-shared/helpers/userHelper"
import { useModalRoot } from "@digits-shared/hooks/useModalRoot"
import useSession from "@digits-shared/hooks/useSession"
import useStateBoolean from "@digits-shared/hooks/useStateBoolean"
import { AspectCode } from "@digits-shared/session/SessionTypes"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import { v4 as generateUUID } from "uuid"
import { type AffiliationEntity } from "src/frontend/components/OS/Applications/AccountantSettings/Affiliations/Shared"
import { useDeleteAffiliations } from "src/frontend/components/OS/Applications/AccountantSettings/useModifyEmployeeAffiliations"
import { useChromeAlertContext } from "src/frontend/components/Shared/Alerts/ChromeAlertsContext"
import { type UserRoleOption, useUserRole } from "src/frontend/hooks/useUserRole"
import type FrontendSession from "src/frontend/session"
import { RoleBubblePicker } from "src/shared/components/Role/RoleBubblePicker"

export const ROLE_DROPDOWN_WIDTH = 200

/*
  STYLES
*/

export const AffiliationPage = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`

export const AffiliationMainContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const AffiliationListStyled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: 0 20px;
`

export const AffiliationNoMatch = styled.div`
  display: flex;
  justify-content: center;
  font-size: 16px;
  margin-top: 40px;
  margin-bottom: 40px;
`

export const AffiliationListHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 19px;
  font-weight: ${fonts.weight.medium};
  padding: 0 20px;
`

export const AffiliationRow = styled.div`
  position: relative;
  display: flex;
  height: 100px;
  background: rgba(255, 255, 255, 0.22);
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 15px;
`

export const AffiliationHeaderRow = styled(AffiliationRow)`
  position: relative;
  margin: 0 20px 15px 20px;
`

export const AffiliationRowLeftSide = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`

export const AffiliationRowInfo = styled.div`
  width: 360px;
  padding-left: 15px;
`

export const AffiliationsRowRightSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const AffiliationRowLabel = styled.div`
  padding: 0 0 5px 5px;
  font-size: 10px;
  color: ${colors.translucentSecondary70};
`

export const AffiliationRowTitle = styled.div`
  font-size: 16px;
  color: ${colors.secondary};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-bottom: 5px;
`

export const AffiliationRowSubtitle = styled.div`
  font-size: 10px;
  color: ${colors.translucentSecondary70};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const OrgEditContainer = styled.div`
  position: relative;
  background: rgba(255, 255, 255, 0.01);
  border: 1px solid ${colors.translucentSecondary50};
  box-sizing: border-box;
  border-radius: 8px;
  height: 86px;
  width: 86px;
  margin: 16px 10px;
`

export const EditIcon = styled(SvgEdit02)`
  position: absolute;
  height: 10px;
  width: 10px;
  right: 5px;
  top: 5px;
  ${svgPathStyles(colors.translucentSecondary50, 1.5)};
  cursor: pointer;

  &:hover {
    ${svgPathStyles(colors.secondary, 1.5)};
  }
`

export const LockedIcon = styled(SvgLock02Solid)`
  position: absolute;
  height: 10px;
  width: 10px;
  right: 5px;
  top: 5px;
  ${svgIconStyles(colors.translucentSecondary60)};
  cursor: pointer;
`

export const OrgIcon = styled(ColorIcon)`
  position: absolute;
  left: 17px;
  top: 17px;
`

export const EmployeeActionsSVG = styled(SvgDotsVerticalSolid)`
  position: absolute;
  right: 8px;
  top: 12px;
  width: 17px;
  height: 17px;
  transition: opacity 250ms ease;
  opacity: 0.6;
  ${svgIconStyles(colors.secondary)};
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`

export const AffiliationModal = styled(Modal)`
  width: 450px;
  text-align: center;
  padding: 60px 40px 40px 40px;
`

export const AffiliationModalAvatar = styled(ModalAvatar)`
  top: -95.5px;
`

export const AffiliationModalSubtitle = styled.div`
  font-size: 13px;
  margin-top: 15px;
  color: ${colors.regentGray};
`

/*
  COMPONENTS
*/

export const AffiliationList: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { browserScrollContainer, pageScrollContext } = useBuildPageScrollContext()

  return (
    <PageScrollContext.Provider value={pageScrollContext}>
      <AffiliationListStyled ref={browserScrollContainer}>{children}</AffiliationListStyled>
    </PageScrollContext.Provider>
  )
}

export const AffiliationsRole: React.FC<{
  employee: Employee
  affiliationEntity: AffiliationEntity
  role?: Role
  setRole: (role: Role) => Promise<void>
  isDisabled: boolean
  isLocked: boolean
  isLoading: boolean
}> = ({ employee, affiliationEntity, role, setRole, isDisabled, isLocked, isLoading }) => {
  const { value: isModalActive, setTrue: showModal, setFalse: hideModal } = useStateBoolean()

  const removePromiseRef = React.useRef<DeferredPromise>()
  const showRemoveModal = React.useCallback(() => {
    const deferred = new DeferredPromise<void>()
    removePromiseRef.current = deferred
    showModal()
    return deferred.promise
  }, [showModal])

  const onRolePick = React.useCallback(
    (newRole: Role) =>
      newRole.id === UserRoleFullyQualified.NoAccess ? showRemoveModal() : setRole(newRole),
    [setRole, showRemoveModal]
  )

  const onCancel = React.useCallback(() => {
    removePromiseRef.current?.reject()
    hideModal()
  }, [hideModal])

  const onComplete = React.useCallback(() => {
    setRole({ id: UserRoleFullyQualified.NoAccess }).then(() =>
      removePromiseRef.current?.resolve(undefined)
    )
    hideModal()
  }, [hideModal, setRole])

  return (
    <AffiliationsRowRightSide>
      <RoleBubblePicker
        width={ROLE_DROPDOWN_WIDTH}
        onRolePick={onRolePick}
        currentRole={AVAILABLE_AFFILIATE_USER_ROLE_OPTIONS_LOOKUP.get(role?.id)}
        roles={AVAILABLE_AFFILIATE_USER_ROLE_OPTIONS}
        isDisabled={isDisabled || isLocked}
        isLocked={isLocked}
        isLoading={isLoading}
        preventClose={isModalActive}
      />
      {isModalActive && (
        <RemoveModal
          employee={employee}
          affiliationEntity={affiliationEntity}
          onComplete={onComplete}
          onCancel={onCancel}
        />
      )}
    </AffiliationsRowRightSide>
  )
}

export const LoadingRow: React.FC<{ isHeader?: boolean }> = ({ isHeader }) => {
  const Container = isHeader ? AffiliationHeaderRow : AffiliationRow
  return (
    <Container>
      <AffiliationRowLeftSide>
        <LoadingCircle height="52px" width="52px" />
        <AffiliationRowInfo>
          <LoadingBlock display="block" height="20px" width="140px" />
          <LoadingBlock display="block" height="20px" width="100px" margin="10px 0 0 0" />
        </AffiliationRowInfo>
      </AffiliationRowLeftSide>
      <AffiliationsRowRightSide>
        <LoadingBlock display="block" height="15px" width="80px" />
        <LoadingBlock display="block" height="20px" width="140px" margin="10px 0 0 0" />
      </AffiliationsRowRightSide>
    </Container>
  )
}

export const AffiliationsUserInfo: React.FC<{
  employee: Employee
}> = ({ employee }) => (
  <AffiliationRowInfo>
    <AffiliationRowTitle>{userHelper.displayName(employee)}</AffiliationRowTitle>
    <AffiliationRowSubtitle>{employee.emailAddress}</AffiliationRowSubtitle>
  </AffiliationRowInfo>
)

const AVAILABLE_EMPLOYEE_USER_ROLE_OPTIONS: UserRoleOption[] = [
  {
    id: UserRoleFullyQualified.Admin,
    description:
      "Can manage firm settings, billing, users, and clients. Default admin access to all clients.",
  },
  {
    id: UserRoleFullyQualified.AccountManager,
    description:
      "Can view clients they’ve been given access to, can add new clients, can give users access to a client.",
  },
  {
    id: UserRoleFullyQualified.Limited,
    description: "Can view clients they’ve been given access to. Cannot add new users or clients.",
  },
]

const AVAILABLE_EMPLOYEE_USER_ROLE_OPTIONS_LOOKUP = AVAILABLE_EMPLOYEE_USER_ROLE_OPTIONS.reduce(
  (agg, o) => agg.set(o.id, o),
  new Map<string | undefined, UserRoleOption>()
)

export const AVAILABLE_AFFILIATE_USER_ROLE_OPTIONS: UserRoleOption[] = [
  {
    id: UserRoleFullyQualified.AffiliationAdmin,
    description: "Access to all tools. Can comment, tag, and share.",
  },
  {
    id: UserRoleFullyQualified.AffiliationAssociate,
    description: "Access to all tools. Cannot comment, tag, or share.",
  },
  {
    id: UserRoleFullyQualified.NoAccess,
    description: "Removes all access to the client.",
  },
]

export const AVAILABLE_AFFILIATE_USER_ROLE_OPTIONS_LOOKUP =
  AVAILABLE_AFFILIATE_USER_ROLE_OPTIONS.reduce(
    (agg, o) => agg.set(o.id, o),
    new Map<string | undefined, UserRoleOption>()
  )

export const AVAILABLE_OPERATOR_TEAMMATE_ROLE_OPTIONS: UserRoleOption[] = [
  {
    id: UserRoleFullyQualified.Admin,
    description: "Access to all tools. Can manage business settings, users, and integrations.",
  },
  // {
  //   id: UserRoleFullyQualified.Limited,
  //   description:
  //     "Can view reports, transactions or categories they’ve been given access to. Cannot access any tool.",
  // },
  {
    id: UserRoleFullyQualified.NoAccess,
    description: "Removes all access to the tools.",
  },
]

export const OPERATOR_TEAMMATE_ROLE_OPTIONS_LOOKUP =
  AVAILABLE_OPERATOR_TEAMMATE_ROLE_OPTIONS.reduce(
    (agg, o) => agg.set(o.id, o),
    new Map<string | undefined, UserRoleOption>()
  )

export function useAvailableEmployeeUserRoles() {
  const { currentOrganizationId } = useSession<FrontendSession>()
  const currentUserRole = useUserRole({ organizationId: currentOrganizationId })

  return React.useMemo(
    () => ({
      // Can lookup to display any role
      roleLookup: AVAILABLE_EMPLOYEE_USER_ROLE_OPTIONS_LOOKUP,
      // Can only select roles that are effectively their level or lower
      availableRoles: AVAILABLE_EMPLOYEE_USER_ROLE_OPTIONS.filter((o) => {
        switch (currentUserRole) {
          case UserRoleFullyQualified.Admin:
            return true
          case UserRoleFullyQualified.AccountManager:
            return o.id !== UserRoleFullyQualified.Admin
          case UserRoleFullyQualified.Limited:
          default:
            return false
        }
      }),
    }),
    [currentUserRole]
  )
}

export function useModifiedEmployeeRoleNotification(employee: Employee) {
  const { currentOrganization } = useSession<FrontendSession>()
  const { addAlert } = useChromeAlertContext()
  const name = userHelper.displayName(employee)

  return React.useCallback(
    (action: "create" | "update" | "delete", role?: UserRoleOption) => {
      addAlert({
        id: `employee-${action}-${
          employee.emailAddress
        }-${currentOrganization?.slug}-${generateUUID()}`,
        message:
          action === "delete"
            ? `${stringHelper.apostrophize(name)} access was revoked from ${currentOrganization?.name}`
            : `${name} was given ${userHelper.roleDisplayName(role)} access to ${currentOrganization?.name}`,
        SVGComponent: SvgLock01Solid,
        type: "info",
        autoDismiss: true,
      })
    },
    [addAlert, employee.emailAddress, currentOrganization, name]
  )
}

export function useModifiedAffiliationNotification(
  employee: Employee,
  affiliationEntity: AffiliationEntity
) {
  const { addAlert } = useChromeAlertContext()
  const name = userHelper.displayName(employee)

  return React.useCallback(
    (action: "create" | "update" | "delete", role?: Role) => {
      addAlert({
        id: `employee-affiliation-${action}-${employee.emailAddress}-${
          affiliationEntity.legalEntity.slug
        }-${generateUUID()}`,
        message:
          action === "delete"
            ? `${stringHelper.apostrophize(name)} access was removed from ${
                affiliationEntity.legalEntity.name
              }`
            : `${name} was given ${userHelper.roleDisplayName(role)} access to ${
                affiliationEntity.legalEntity.name
              }`,
        SVGComponent: SvgLock01Solid,
        type: "info",
        autoDismiss: true,
      })
    },
    [
      addAlert,
      employee.emailAddress,
      affiliationEntity.legalEntity.slug,
      affiliationEntity.legalEntity.name,
      name,
    ]
  )
}

export const useShouldBlockBranding = () => {
  const { currentLegalEntity } = useSession<FrontendSession>()
  const brandingEnabled = currentLegalEntity?.hasAccessToAspect(AspectCode.Branding)

  return React.useMemo(() => !brandingEnabled, [brandingEnabled])
}

const RemoveModal: React.FC<{
  employee: Employee
  affiliationEntity: AffiliationEntity
  onComplete: () => void
  onCancel: () => void
}> = ({ employee, affiliationEntity, onComplete, onCancel }) => {
  const { currentOrganization, user } = useSession<FrontendSession>()
  const isCurrentUser = employee.user?.id === user.id

  const { deleteAffiliations, loading: deleteLoading } = useDeleteAffiliations({
    employee,
    affiliationEntity,
  })

  const deactivateUser = React.useCallback(() => {
    deleteAffiliations()
      .then((response) => {
        // TODO: Display error?
        if (response.errors?.length) {
          console.error(response.errors)
          return
        }

        TrackJS?.console.log("modified employee affiliations successfully")
        onCancel()
      })
      // TODO: Display error?
      .catch((reason: string) => console.error(reason))
  }, [deleteAffiliations, onCancel])

  const modalRoot = useModalRoot()

  return ReactDOM.createPortal(
    <AffiliationModal preventEasyClose onCancel={onCancel}>
      <AffiliationModalAvatar imageUrl={employee.avatarUrl} fallbackUser={employee} size={71} />
      <H3 transform="none">
        Are you sure you’d like to remove&nbsp;
        <b>{isCurrentUser ? "your access" : userHelper.displayName(employee)}</b> to{" "}
        <b>{currentOrganization?.name}</b>?
      </H3>
      {isCurrentUser && (
        <AffiliationModalSubtitle>
          This will remove them from your clients list.
        </AffiliationModalSubtitle>
      )}
      <ButtonRow css="margin-top: 30px;">
        <DigitsButton onClick={onCancel} $variant="ghost-dark" disabled={deleteLoading}>
          Cancel
        </DigitsButton>
        <DigitsButton onClick={deactivateUser} disabled={deleteLoading}>
          {deleteLoading ? "Removing…" : "Remove"}
        </DigitsButton>
      </ButtonRow>
    </AffiliationModal>,
    modalRoot
  )
}
