import * as React from "react"
import SearchBox from "@digits-shared/components/UI/Elements/SearchBox"
import {
  PICKER_CONTAINER_PADDING,
  PickerPosition,
} from "@digits-shared/components/UI/Picker/constants"
import { usePickerKeyboardNavigations } from "@digits-shared/components/UI/Picker/usePickerKeyboardNavigation"
import useStateBoolean from "@digits-shared/hooks/useStateBoolean"
import { themedStyles, themedValue } from "@digits-shared/themes"
import borders from "@digits-shared/themes/borders"
import colors from "@digits-shared/themes/colors"
import styled, { css } from "styled-components"

/*
  STYLES
*/

export const PickerContainer = styled.div<{ width: number; isLoading?: boolean }>`
  position: relative;
  width: ${({ width }) => width}px;
  pointer-events: ${({ isLoading }) => (isLoading ? "none" : "unset")};
  border-radius: 50vh;
`

export const PickerListContainer = styled.div<{
  pickerPosition: PickerPosition
}>`
  display: flex;
  flex-direction: ${({ pickerPosition }) =>
    pickerPosition === PickerPosition.AboveTarget ? "column-reverse" : "column"};
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
`

export const PickerListRowBackground = styled.div<{
  isFinalSelection?: boolean
  isHovered?: boolean
  isSelected?: boolean
  preventMouseHover?: boolean
  pickerPosition: PickerPosition
}>`
  cursor: pointer;
  transition: background-color 250ms ease;

  ${({ pickerPosition }) =>
    pickerPosition === PickerPosition.AboveTarget
      ? css`
          &:first-child {
            border-radius: 0 0 ${borders.theme.dark.radius.modal}px
              ${borders.theme.dark.radius.modal}px;
          }

          &:last-child {
            border-radius: ${borders.theme.dark.radius.modal}px ${borders.theme.dark.radius.modal}px
              0 0;
          }

          &:not(:first-child) {
            ${themedStyles({
              light: css`
                border-bottom: 1px solid ${colors.translucentSecondary10};
              `,
              dark: borders.theme.dark.divider.bottom,
            })};
          }
        `
      : css`
          &:first-child {
            border-radius: ${borders.theme.dark.radius.modal}px ${borders.theme.dark.radius.modal}px
              0 0;
          }

          &:last-child {
            border-radius: 0 0 ${borders.theme.dark.radius.modal}px
              ${borders.theme.dark.radius.modal}px;
          }

          &:not(:last-child) {
            ${themedStyles({
              light: css`
                border-bottom: 1px solid ${colors.translucentSecondary10};
              `,
              dark: borders.theme.dark.divider.bottom,
            })};
          }
        `};

  ${({ isFinalSelection, isHovered, preventMouseHover, isSelected }) =>
    isFinalSelection || isHovered
      ? css`
          background-color: ${themedValue({ light: colors.white, dark: colors.neonGreen })};
        `
      : !preventMouseHover &&
        css`
          &:hover {
            background-color: ${themedValue({ light: colors.white, dark: colors.neonGreen })};
          }
        `}
`

export const PickerRowContainer = styled.div`
  display: flex;
  height: 55px;
  padding: 0 ${PICKER_CONTAINER_PADDING}px;
  align-items: center;
`

const PickerLoadingRowBackground = styled(PickerListRowBackground)`
  pointer-events: none;
`

export const PickerSearchContainer = styled.div<{ pickerPosition: PickerPosition }>`
  ${({ pickerPosition }) =>
    pickerPosition === PickerPosition.AboveTarget
      ? css`
          ${borders.theme.dark.divider.top};
          padding: ${PICKER_CONTAINER_PADDING}px ${PICKER_CONTAINER_PADDING}px
            ${PICKER_CONTAINER_PADDING + 5}px ${PICKER_CONTAINER_PADDING}px;
        `
      : css`
          ${borders.theme.dark.divider.bottom};
          padding: ${PICKER_CONTAINER_PADDING + 5}px ${PICKER_CONTAINER_PADDING}px
            ${PICKER_CONTAINER_PADDING}px ${PICKER_CONTAINER_PADDING}px;
        `}
`

const PickerNoResultContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px 0 30px 0;
`

/*
  INTERFACES
*/

export type PickerRowContentProps<E, P = {}> = PickerMouseInteraction & {
  element: E
  pickerPosition: PickerPosition
  isSelected?: boolean | undefined
  additionalProps?: P
}

type PickerRowContent<E, P> = React.ComponentType<PickerRowContentProps<E, P>>

export interface PickerStaticContentProps {
  pickerPosition: PickerPosition
  onClick?: () => void
  searchTerm: string
  searchResultsLength: number
}

export interface PickerNoResultRowContentProps {
  pickerPosition: PickerPosition
  searchTerm: string
}

export enum StaticContentPosition {
  BeforeSearch = "BeforeSearch",
  First = "First",
  Last = "Last",
}

export interface Props<E, P = {}> {
  maxHeight?: number
  elements: E[]
  // Element to show if there are no other elements present
  emptyElement?: E
  isCurrentSelection?: (element: E) => boolean
  // If the picker should be position above or below the content its anchored to
  pickerPosition: PickerPosition
  // auto-selects first item as soon as picker is shown (e.g. autocomplete on Enter)
  autoSelect?: boolean
  onPickElement: (element: E) => void
  RowContent: PickerRowContent<E, P>
  rowContentProps?: P
  elementSearchText?: (element: E) => string
  initialSearchTerm?: string
  searchPlaceholder?: string
  onSearch?: (searchTerm: string) => void
  // Don't show any options until there is a search term entered
  searchOnly?: boolean
  isLoading?: boolean
  LoadingRowContent?: React.ComponentType
  NoResultRowContent?: React.ComponentType<PickerNoResultRowContentProps>
  // Will show at all times if present
  StaticContent?: React.ComponentType<PickerStaticContentProps>
  onStaticContentClick?: (e?: React.MouseEvent) => void
  staticContentPosition?: StaticContentPosition
  loadingRowCount?: number
}

interface RowProps<E, P> {
  element: E
  isCurrentSelection?: (element: E) => boolean
  isKeyboardHighlighted: boolean
  usingKeyboard: boolean
  pickerPosition: PickerPosition
  RowContent: PickerRowContent<E, P>
  rowContentProps?: P
  onClick: (e: E) => void
}

export interface PickerMouseInteraction {
  isHovered?: boolean
  isSelected?: boolean
}

/*
  COMPONENTS
*/

export function Picker<E>({
  elements,
  emptyElement,
  pickerPosition,
  isCurrentSelection,
  autoSelect,
  onPickElement,
  RowContent,
  rowContentProps,
  elementSearchText,
  initialSearchTerm,
  searchPlaceholder,
  onSearch,
  searchOnly,
  isLoading,
  LoadingRowContent,
  NoResultRowContent,
  StaticContent,
  onStaticContentClick,
  staticContentPosition = StaticContentPosition.Last,
  loadingRowCount = 5,
}: React.PropsWithChildren<Props<E>>) {
  const withSearch = !!onSearch || !!elementSearchText

  const [searchTerm, setSearchTerm] = React.useState(initialSearchTerm || "")
  const onNewSearch = React.useCallback(
    (newSearchTerm: string) => {
      onSearch?.(newSearchTerm)
      setSearchTerm(newSearchTerm)
    },
    [onSearch]
  )

  const sortedElements = React.useMemo(
    () => (elementSearchText ? sortElements(elements.slice(), elementSearchText) : elements),
    [elements, elementSearchText]
  )

  const filteredElements = React.useMemo(
    () =>
      elementSearchText && !onSearch
        ? sortedElements.filter(
            (e) =>
              searchTerm.length === 0 ||
              elementSearchText(e).toLowerCase().includes(searchTerm.toLowerCase())
          )
        : sortedElements,
    [sortedElements, elementSearchText, onSearch, searchTerm]
  )

  const displayElements =
    !filteredElements.length && emptyElement ? [emptyElement] : filteredElements

  const keyboardNavIndex = usePickerKeyboardNavigations({
    pickerPosition,
    elements: displayElements,
    onEnter: onPickElement,
    isLoading: !!isLoading,
    maxElements: !filteredElements.length && NoResultRowContent ? 1 : undefined,
    autoSelect,
  })

  const rows = (
    isLoading && LoadingRowContent ? Array.from({ length: loadingRowCount }) : displayElements
  ).map((e, n) =>
    isLoading && LoadingRowContent ? (
      <LoadingRow key={n} pickerPosition={pickerPosition} LoadingRowContent={LoadingRowContent} />
    ) : (
      <PickerListRow
        key={n}
        element={e}
        isKeyboardHighlighted={keyboardNavIndex === n}
        usingKeyboard={keyboardNavIndex !== ZERO_STATE_PICKER_KEYBOARD_INDEX}
        pickerPosition={pickerPosition}
        isCurrentSelection={isCurrentSelection}
        RowContent={RowContent}
        rowContentProps={rowContentProps}
        onClick={onPickElement}
      />
    )
  )

  let content
  if (rows.length) content = rows
  if (!content || (searchOnly && !searchTerm)) {
    content = NoResultRowContent ? (
      <NoResultRowContent pickerPosition={pickerPosition} searchTerm={searchTerm} />
    ) : (
      <PickerNoResultContainer>No matches.</PickerNoResultContainer>
    )
  }

  return (
    <PickerListContainer pickerPosition={pickerPosition}>
      {StaticContent && staticContentPosition === StaticContentPosition.BeforeSearch && (
        <StaticContent
          pickerPosition={pickerPosition}
          onClick={onStaticContentClick}
          searchTerm={searchTerm}
          searchResultsLength={rows.length}
        />
      )}
      {withSearch && (
        <PickerSearchRow
          placeholder={searchPlaceholder}
          pickerPosition={pickerPosition}
          searchTerm={searchTerm}
          onChange={onNewSearch}
        />
      )}
      {StaticContent && staticContentPosition === StaticContentPosition.First && (
        <StaticContent
          pickerPosition={pickerPosition}
          onClick={onStaticContentClick}
          searchTerm={searchTerm}
          searchResultsLength={rows.length}
        />
      )}
      {content}
      {StaticContent && staticContentPosition === StaticContentPosition.Last && (
        <StaticContent
          pickerPosition={pickerPosition}
          onClick={onStaticContentClick}
          searchTerm={searchTerm}
          searchResultsLength={rows.length}
        />
      )}
    </PickerListContainer>
  )
}

export const PickerSearchRow: React.FC<{
  pickerPosition: PickerPosition
  placeholder?: string
  searchTerm: string
  onChange: (term: string) => void
}> = ({ pickerPosition, placeholder, searchTerm, onChange }) => (
  <PickerSearchContainer pickerPosition={pickerPosition}>
    <SearchBox initialValue={searchTerm} placeholder={placeholder} onChange={onChange} autoFocus />
  </PickerSearchContainer>
)

function PickerListRow<E, P>({
  element,
  isKeyboardHighlighted,
  usingKeyboard,
  pickerPosition,
  isCurrentSelection,
  RowContent,
  rowContentProps,
  onClick,
}: React.PropsWithChildren<RowProps<E, P>>) {
  const { value: isHovered, setTrue: setHovered, setFalse: setUnHovered } = useStateBoolean(false)
  const { value: isFinalSelection, setTrue: setFinalSelection } = useStateBoolean(false)
  const onRowClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      setFinalSelection()
      onClick(element)
    },
    [onClick, element, setFinalSelection]
  )

  const isSelected = isCurrentSelection?.(element)
  const showHovered = !isSelected && (isKeyboardHighlighted || (!usingKeyboard && isHovered))

  return (
    <PickerListRowBackground
      pickerPosition={pickerPosition}
      onMouseOver={setHovered}
      onMouseOut={setUnHovered}
      onClick={onRowClick}
      isFinalSelection={isFinalSelection}
      isHovered={!isSelected && isKeyboardHighlighted}
      isSelected={isSelected}
      preventMouseHover={usingKeyboard}
    >
      <RowContent
        element={element}
        pickerPosition={pickerPosition}
        isHovered={showHovered || (!isSelected && isFinalSelection)}
        isSelected={isSelected || (!isSelected && isFinalSelection)}
        additionalProps={rowContentProps}
      />
    </PickerListRowBackground>
  )
}

const LoadingRow: React.FC<{
  pickerPosition: PickerPosition
  LoadingRowContent: React.ComponentType
}> = ({ pickerPosition, LoadingRowContent }) => (
  <PickerLoadingRowBackground pickerPosition={pickerPosition}>
    <LoadingRowContent />
  </PickerLoadingRowBackground>
)

// Sorts the provided list by the values generated from the transformFn
const sortElements: <E>(elements: E[], transformFn: (el: E) => string) => E[] = (
  elements,
  transformFn
) =>
  elements.sort((a, b) => {
    const aText = transformFn(a)
    const bText = transformFn(b)
    if (aText < bText) return -1
    if (aText > bText) return 1
    return 0
  })

export const ZERO_STATE_PICKER_KEYBOARD_INDEX = -1
