import { isArray } from "@apollo/client/utilities"
import {
  type MoneyFlow,
  type Period,
  type StatementDeltaValue,
  type TransactionSummary,
} from "@digits-graphql/frontend/graphql-bearer"
import moneyFlowHelper from "@digits-shared/helpers/moneyFlowHelper"
import { toStatementDeltaValue } from "src/frontend/components/Shared/Reports/Report/Components/Statements/toStatementDeltas"

export interface Timeseries {
  label: string
  values: TimeseriesValues
}

export function toTimeseries(label: string, summaries: Summaries): Timeseries {
  return {
    label,
    values: toSortedTimeseriesValues(summaries),
  }
}

type NestedSummary = { summary: TransactionSummary }
export type Summaries = (TransactionSummary | TransactionSummary[]) | NestedSummary[]

function isNestSummary(summary: TransactionSummary | NestedSummary): summary is NestedSummary {
  return !!(summary as NestedSummary)?.summary
}

function isNestSummaries(summaries: Summaries): summaries is NestedSummary[] {
  if (!isArray(summaries)) return false
  return !!summaries?.[0] && !!isNestSummary(summaries[0])
}

export interface TimeseriesValue {
  period: Period
  moneyFlow: MoneyFlow
  deltaPrevious: StatementDeltaValue | undefined
  deltaYearAgo: StatementDeltaValue | undefined
}
export type TimeseriesValues = TimeseriesValue[]

export function toTimeseriesValue(summary: TransactionSummary | NestedSummary): TimeseriesValue {
  const {
    period,
    total: { moneyFlow, deltaPrevious, deltaYearAgo },
  } = isNestSummary(summary) ? summary.summary : summary

  return {
    period,
    moneyFlow: moneyFlow ?? moneyFlowHelper.buildZeroMoneyFlow(),
    deltaPrevious: toStatementDeltaValue(moneyFlow, deltaPrevious),
    deltaYearAgo: toStatementDeltaValue(moneyFlow, deltaYearAgo),
  }
}

export function toTimeseriesValues(summaries: Summaries | undefined): TimeseriesValue[] {
  if (!summaries) return []

  let arr = Array.isArray(summaries) ? summaries : [summaries]
  if (isNestSummaries(arr)) {
    arr = arr.map((s) => s.summary)
  }
  return arr.map((s) => toTimeseriesValue(s))
}

export function toSortedTimeseriesValues(summaries: Summaries | undefined): TimeseriesValue[] {
  return toTimeseriesValues(summaries).sort(sortTimeseriesValues)
}

export const sortTimeseriesValues = (a: TimeseriesValue, b: TimeseriesValue) =>
  a.period.startedAt - b.period.startedAt
