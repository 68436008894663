import { DisplayEntry, TransactionRecordType } from "@digits-graphql/frontend/graphql-bearer"

interface TransactionDisplayFields {
  displayEntry: DisplayEntry
}

export const SYNTHESIZED_RECORD_TYPES = [
  TransactionRecordType.ExpectedTransaction,
  TransactionRecordType.MissedTransaction,
]

const FULL_DATA_TRANSACTION_RECORD_TYPE = SYNTHESIZED_RECORD_TYPES.concat([
  TransactionRecordType.Transaction,
])

export default {
  isTransfer(transaction?: TransactionDisplayFields) {
    if (!transaction) return false

    return (
      transaction.displayEntry === DisplayEntry.TransferCredit ||
      transaction.displayEntry === DisplayEntry.TransferDebit
    )
  },

  isCredit(transaction?: TransactionDisplayFields) {
    if (!transaction) return false
    return (
      transaction.displayEntry === DisplayEntry.TransferCredit ||
      transaction.displayEntry === DisplayEntry.Credit
    )
  },

  isDebit(transaction?: TransactionDisplayFields) {
    return !this.isCredit(transaction)
  },

  fullDataRecordType(recordType: TransactionRecordType) {
    return FULL_DATA_TRANSACTION_RECORD_TYPE.includes(recordType)
  },

  isSynthesizedRecordType(recordType: TransactionRecordType) {
    return SYNTHESIZED_RECORD_TYPES.includes(recordType)
  },
}
