import * as React from "react"
import {
  type ReportPackageDocumentMetadata,
  type SharedReportDocumentMetadata,
} from "@digits-graphql/frontend/graphql-bearer"
import { VaultDownload } from "@digits-shared/components/Vault/VaultDownload"
import useSession from "@digits-shared/hooks/useSession"
import useDownloadUrl from "src/frontend/components/Shared/Reports/Report/Downloader/useDownloadUrl"
import type FrontendSession from "src/frontend/session"

/*
  COMPONENT
*/

interface DownloaderProps {
  document: ReportPackageDocumentMetadata | SharedReportDocumentMetadata
  onDownloadStart?: (document: ReportPackageDocumentMetadata | SharedReportDocumentMetadata) => void
  onDownloadCompleted?: (
    document: ReportPackageDocumentMetadata | SharedReportDocumentMetadata
  ) => void
  onDownloadError?: (
    document: ReportPackageDocumentMetadata | SharedReportDocumentMetadata,
    error: Error
  ) => void
  className?: string
  children: (props: ChildProps) => React.ReactNode
  reportPackageId: string
  legalEntityId?: string
}

interface ChildProps {
  isDownloading: boolean
}

export const ReportPackageDocumentDownloader: React.FC<DownloaderProps> = ({
  document,
  onDownloadStart,
  onDownloadCompleted,
  onDownloadError,
  className,
  children,
  reportPackageId,
  legalEntityId,
}) => {
  const { currentLegalEntityId: sessionLegalEntityId } = useSession<FrontendSession>()
  const [isDownloading, setDownloading] = React.useState(false)
  const fetchLink = useDownloadUrl(
    legalEntityId ?? sessionLegalEntityId,
    document.documentObjectId,
    document.documentObjectVersionId,
    isReportPackageDocumentMetadata(document)
      ? document.reportFile?.fileName || document.documentObjectId
      : document.documentObjectId,
    reportPackageId
  )

  const onStart = React.useCallback(() => {
    setDownloading(true)
    onDownloadStart?.(document)
  }, [onDownloadStart, document])

  const onDone = React.useCallback(() => {
    setDownloading(false)
    onDownloadCompleted?.(document)
  }, [onDownloadCompleted, document])

  const onError = React.useCallback(
    (err: Error) => {
      setDownloading(false)
      onDownloadError?.(document, err)
    },
    [onDownloadError, document]
  )

  return (
    <div className={className}>
      <VaultDownload
        fetchDownloadLink={fetchLink}
        onStart={onStart}
        onDone={onDone}
        onError={onError}
      >
        {children({ isDownloading })}
      </VaultDownload>
    </div>
  )
}

function isReportPackageDocumentMetadata(
  document: ReportPackageDocumentMetadata | SharedReportDocumentMetadata
): document is ReportPackageDocumentMetadata {
  return (document as ReportPackageDocumentMetadata).reportFile !== undefined
}
