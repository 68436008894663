import * as React from "react"

export default class ElementArrayHelper extends Array<React.ReactNode> {
  constructor(...items: React.ReactNode[]) {
    super(...items.flat())
    Object.setPrototypeOf(this, Object.create(ElementArrayHelper.prototype))
  }

  joinElements(separator?: React.ReactNode) {
    return (
      <>
        {this.reduce<React.ReactNode[]>((arr, item, index) => {
          if (!item) return arr

          if (arr.length && separator) {
            arr.push(<React.Fragment key={`separator-${index}`}>{separator}</React.Fragment>)
          }
          arr.push(item)

          return arr
        }, [])}
      </>
    )
  }
}
