import {
  type WorkItem,
  WorkItemState,
  type WorkItemType,
} from "@digits-graphql/frontend/graphql-bearer"

export enum WorkItemQueryParam {
  TaskId = "taskId",
  State = "state",
  Type = "type",
}

export enum TransactionReviewTab {
  TransactionReview = "To Review",
  PendingClientResponse = "Waiting on Client",
  ClientResponded = "Replies",
}

export enum SelectedWorkItemState {
  Open = "Open",
  Closed = "Closed",
}

export interface WorkItemGroup {
  groupType: WorkItemType
  name: string
  items: WorkItem[]
}

export interface WorkItemTypeSummary {
  itemType?: WorkItemType
  name: string
  count: number
}

export const OPEN_STATES = [WorkItemState.Active]
export const CLOSED_STATES = [WorkItemState.Accepted, WorkItemState.Completed]
export const TR_CLOSED_STATES = [...CLOSED_STATES, WorkItemState.Invalidated]
