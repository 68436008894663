import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"

export const TileCardHeading = styled.div`
  font-size: 24px;
  font-weight: ${fonts.weight.heavy};
  color: ${colors.secondary};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`

export const TileCard = styled.div<{ height?: number; skipAnimations?: boolean }>`
  ${({ height }) => height && `height: ${height}px;`}

  display: grid;
  grid-template-rows: 25px [header-start] minmax(20px, auto) [header-end] 1fr [graphic-start] auto [graphic-end];
  grid-template-areas:
    "space1"
    "header"
    "space2"
    "graphic";
  justify-items: start;

  > :first-child {
    grid-area: header;
  }
  > :last-child {
    grid-area: graphic;
  }
`
// TODO: Enable once component-level support for hovers is added. */
// ${({ skipAnimations }) =>
//   skipAnimations &&
//   css`
//     svg {
//       /* Mostly-transparent and bluish. */
//       filter: sepia(90%) hue-rotate(180deg) grayscale(60%);
//     }
//     &:hover {
//       svg {
//         filter: none;
//       }
//     }
//   `})}
