import { DocumentStatus } from "@digits-graphql/frontend/graphql-bearer"
import fileHelper, { FileType } from "@digits-shared/helpers/fileHelper"
import { type VaultFile } from "src/frontend/components/OS/Applications/Vault/types"

export function fileTypeFor(file: VaultFile) {
  if (file.mimeType) {
    const type = fileHelper.fileTypeForMimeType(file.mimeType)
    if (type) return type
  }

  if (file.status === DocumentStatus.New) return FileType.DIGITS

  if (file.status === DocumentStatus.Processing && !file.thumbnail) return FileType.DIGITS

  return FileType.Unknown
}
