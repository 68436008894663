import borders from "@digits-shared/themes/borders"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"

/*
  STYLES
*/

export const TableContainer = styled.div`
  padding: 20px 0 0 0;
  ${borders.theme.dark.divider.top};
  background-size: 100% 150px;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.1);
  background-image: radial-gradient(
    ellipse farthest-corner at 50% -50%,
    rgba(37, 109, 136, 0.65) 0%,
    rgba(37, 109, 136, 0.15) 30%,
    rgba(37, 109, 136, 0) 65%,
    rgba(6, 13, 24, 0) 75%
  );
`

export const TableHeaderContainer = styled.div`
  padding: 0 20px;
`

export const TableTitle = styled.div`
  font-size: 16px;
  margin-bottom: 15px;
  font-weight: ${fonts.weight.heavy};
`

export const TableSubtitle = styled.div`
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: ${fonts.weight.heavy};
`

export const TableDescription = styled.div`
  color: ${colors.translucentWhite50};
  font-size: 13px;
  margin-bottom: 15px;
  margin-top: -10px;
`

export const TableInnerContainer = styled.div`
  background-color: ${colors.white};
  border-radius: ${borders.radius.default}px;
  width: 100%;
  border: 1px solid ${colors.lightGray};
  padding: 20px 20px 0 20px;
`
