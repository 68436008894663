import React from "react"
import { SvgMessagePlusSquare } from "@digits-shared/components/SVGIcons/line/MessagePlusSquare.svg"
import { DigitsButton } from "@digits-shared/DesignSystem/Button"
import { useIsPrintTheme } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import styled, { css } from "styled-components"
import { useCanCommentReports } from "src/frontend/components/Shared/Reports/Packages/Viewer/hooks/useCanCommentReports"
import { useContextPath } from "src/frontend/components/Shared/Reports/Report/Viewer/UseContextPath"
import { useReportCommentsDispatch } from "src/frontend/components/Shared/Reports/ReportComments/ReportCommentsContext"
import { useActiveCommentEntry } from "src/frontend/components/Shared/Reports/ReportComments/selectors"

const SELECTED_FOR_THREAD = "selected-for-thread"

const CommentsWrapper = styled.div<WrapperProps>`
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: -4px;
    left: -4px;
    right: -4px;
    bottom: -4px;
    background: ${colors.translucentSecondary05};
    border-radius: 6px;
    transition: opacity 500ms;
    display: none;
    opacity: 0;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      pointer-events: none;

      &:before {
        display: block;
        opacity: 1;
      }
    `}
`

const CommentButton = styled(DigitsButton)`
  position: absolute;
  right: -14px;
  top: 20px;
  height: 28px;
  width: 28px;

  &:before {
    content: "";
    z-index: -1;
    border-radius: inherit;
    background: white;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  background: ${colors.white};
  display: none;

  .${SELECTED_FOR_THREAD} > & {
    display: inline-flex;
  }

  transition:
    transform 400ms ease-in-out,
    opacity 300ms;
  transform-origin: center;

  &:hover:not([disabled]) {
    transform: scale(1.04);

    &:before {
      background: #e0e3ed;
    }
  }
`

const CommentIcon = styled(SvgMessagePlusSquare)`
  stroke: ${colors.secondary};
  overflow: visible;
  padding: 3px 3px 2px 3px;
`

interface Props {
  contextId?: string
  disabled?: boolean
  className?: string
  selectionBackground?: boolean
  children?: React.ReactNode
}

interface WrapperProps {
  isActive?: boolean
  selectionBackground?: boolean
}

const WithComments: React.FC<Props> = ({
  contextId,
  disabled,
  className,
  children,
  selectionBackground,
}) => {
  const commentsDispatch = useReportCommentsDispatch()
  const activeEntry = useActiveCommentEntry()
  const contextPath = useContextPath(contextId)
  const canComment = useCanCommentReports()
  const isPrint = useIsPrintTheme()
  const isActive = !isPrint && activeEntry?.context === contextPath

  const enableListeners = canComment && !disabled && !isPrint ? true : undefined

  const onMouseOver = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.currentTarget.classList.add(SELECTED_FOR_THREAD)
  }, [])

  const onMouseOut = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.currentTarget.classList.remove(SELECTED_FOR_THREAD)
  }, [])

  const onClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      commentsDispatch({ type: "ADD_COMMENT", context: contextPath })
    },
    [commentsDispatch, contextPath]
  )

  return (
    <CommentsWrapper
      className={className}
      isActive={isActive}
      selectionBackground={selectionBackground}
      onMouseEnter={enableListeners && onMouseOver}
      onMouseLeave={enableListeners && onMouseOut}
    >
      {children}
      {!isPrint && !disabled && (
        <CommentButton $circle size="medium" $variant="secondary-dark" onClick={onClick}>
          <CommentIcon />
        </CommentButton>
      )}
    </CommentsWrapper>
  )
}
export default WithComments
