import * as React from "react"
import { RingLoader } from "@digits-shared/components/Loaders/Ring"
import { svgIconStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgDigitsLogoNoBackground } from "@digits-shared/components/SVGIcons/digits/DigitsLogoNoBackground.svg"
import colors from "@digits-shared/themes/colors"
import styled, { css, keyframes } from "styled-components"

const LOADER_DIAMETER = 140

export enum Position {
  Unset = "Unset",
  RelativeCenter = "RelativeCenter",
  AbsoluteCenter = "AbsoluteCenter",
}

export enum Delay {
  None = "None",
  /**
   * Adds an initial delay before the fade-in so that quick page loads
   * don't show a loader
   */
  PageLoad = "PageLoad",
}

interface RingLogoLoaderProps {
  position?: Position
  delay?: Delay
  isComplete?: boolean
  className?: string
  color?: string
}

interface LoaderProps {
  position: Position
  delay: Delay
}

function positionStyles(props: LoaderProps) {
  switch (props.position) {
    case Position.Unset:
      return undefined
    case Position.RelativeCenter:
      return css`
        position: relative;
        margin: calc(50vh - ${LOADER_DIAMETER / 2}px) auto 0;
      `
    case Position.AbsoluteCenter:
      return css`
        position: absolute;
        top: calc(50vh - ${LOADER_DIAMETER / 2}px);
        left: calc(50vw - ${LOADER_DIAMETER / 2}px);
      `
  }
}

const FADE_IN = keyframes`
  from { opacity: 0; }

  to { opacity: 1; }
`

function delayStyles(props: LoaderProps) {
  switch (props.delay) {
    case Delay.PageLoad:
      return css`
        opacity: 0;
        animation: ${FADE_IN} 300ms 250ms ease-in-out forwards;
      `
    case Delay.None:
      return undefined
  }
}

const Loader = styled.div<LoaderProps>`
  width: ${LOADER_DIAMETER}px;
  height: ${LOADER_DIAMETER}px;
  ${positionStyles}
  ${delayStyles}
`

const Ring = styled(RingLoader)`
  margin: 0;
`

const Logo = styled(SvgDigitsLogoNoBackground)<{ color: string }>`
  width: ${LOADER_DIAMETER}px;
  height: ${LOADER_DIAMETER}px;
  position: absolute;
  padding: ${LOADER_DIAMETER / 4}px;
  top: calc(50% - ${LOADER_DIAMETER / 2}px);
  left: calc(50% - ${LOADER_DIAMETER / 2}px);
  ${({ color }) => svgIconStyles(color)};
`

export const RingLogoLoader = React.forwardRef<HTMLDivElement, RingLogoLoaderProps>(
  (
    {
      position = Position.RelativeCenter,
      delay = Delay.None,
      isComplete,
      className,
      color = colors.logoGreen,
    },
    ref
  ) => (
    <Loader ref={ref} position={position} delay={delay} className={className}>
      <Ring diameter={LOADER_DIAMETER} color={color} isComplete={isComplete} />
      <Logo color={color} />
    </Loader>
  )
)
