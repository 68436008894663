import * as React from "react"
import { type IntervalOrigin, LayoutComponentType } from "@digits-graphql/frontend/graphql-bearer"
import urlHelper from "@digits-shared/helpers/urlHelper"
import useRouter from "@digits-shared/hooks/useRouter"
import styled from "styled-components"
import { useHighlight } from "src/frontend/components/OS/Details/Shared/hooks/useHighlight"
import { CashBadge } from "src/frontend/components/Shared/Layout/CashBadge"
import { type TimeseriesValue } from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"
import { ComponentExpandLink } from "src/frontend/components/Shared/Layout/Components/Headers/ComponentExpandIcon"
import {
  ComponentDateSummary,
  ComponentSummary,
} from "src/frontend/components/Shared/Layout/Components/Headers/ComponentSummary"
import { RunwayDeltaSubHeader } from "src/frontend/components/Shared/Layout/Components/Runway/RunwayDeltaSubHeader"
import { RunwayLineChartComponent } from "src/frontend/components/Shared/Layout/Components/Runway/RunwayLineChart"
import { RunwayTile } from "src/frontend/components/Shared/Layout/Components/Runway/RunwayTile"
import {
  useCashOutTimeLeft,
  useRunwayTimeseries,
} from "src/frontend/components/Shared/Layout/Components/Runway/shared"
import { ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { useComponentDetailsLinkPath } from "src/frontend/components/Shared/Layout/hooks/useEntityDetailsViewPaths"
import {
  type MatchedComponent,
  type SizingProps,
} from "src/frontend/components/Shared/Layout/types"
import { type Runway } from "src/frontend/types"

/*
  STYLES
*/

const TileComponent = styled(RunwayTile)<{ height: number }>`
  max-width: 250px;
`

/*
  INTERFACES
*/

interface RunwayChartComponentProps extends SizingProps {
  origin: IntervalOrigin
  component: MatchedComponent<"runwayChart">
  componentSize: ComponentSize
  skipAnimations: boolean
  runway?: Runway
  loading: boolean
}

/*
  COMPONENTS
*/

export const RunwayChartComponent: React.FC<RunwayChartComponentProps> = ({
  runway,
  loading,
  origin,
  component,
  componentSize,
  height,
  skipAnimations,
}) => {
  const { currentTimeseries, fullTimeseries } = useRunwayTimeseries(runway)
  const { title: cashOutTitle, deltaMonths } = useCashOutTimeLeft(runway)
  const { history } = useRouter()
  const runwayPath = useComponentDetailsLinkPath(LayoutComponentType.RunwayChart, origin)

  const highlight = useHighlight()
  const [selectedIndex, setSelectedIndex] = React.useState(highlight ?? undefined)

  const onMouseOver = React.useCallback((value: TimeseriesValue, index: number) => {
    setSelectedIndex(index)
  }, [])

  const onMouseOut = React.useCallback(
    (value?: TimeseriesValue) => {
      setSelectedIndex(highlight ?? undefined)
    },
    [highlight]
  )

  const onBarClick = React.useCallback(
    (_: TimeseriesValue, index: number) => {
      setSelectedIndex(index)
      if (!runwayPath) return
      const path = urlHelper.addSearchParams(runwayPath, { highlight: index })
      if (!path) return
      history.push(path)
    },
    [history, runwayPath]
  )

  const title = React.useMemo(
    () => (
      <ComponentExpandLink to={runwayPath} linkBadge={<CashBadge />} componentSize={componentSize}>
        Runway
      </ComponentExpandLink>
    ),
    [runwayPath, componentSize]
  )

  const subHeader = <RunwayDeltaSubHeader deltaMonths={deltaMonths} />

  if (componentSize === ComponentSize.Small || componentSize === ComponentSize.PageSmall) {
    return (
      <>
        <ComponentDateSummary
          componentSize={componentSize}
          title={title}
          timeseries={currentTimeseries}
          selectedIndex={selectedIndex}
        />
        <TileComponent
          loading={loading}
          height={height}
          runway={runway}
          skipAnimations={skipAnimations}
        />
      </>
    )
  }

  return (
    <>
      <ComponentSummary
        componentSize={componentSize}
        title={title}
        timeseries={fullTimeseries}
        defaultValueNode={cashOutTitle}
        defaultTimeseriesIndex={currentTimeseries.length - 1}
        selectedIndex={selectedIndex}
        subHeaderCustomNode={subHeader}
        isEstimate={!!selectedIndex && selectedIndex > currentTimeseries.length - 1}
      />
      <RunwayLineChartComponent
        runway={runway}
        skipAnimations={skipAnimations}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        onClick={onBarClick}
        height={height}
      />
    </>
  )
}
