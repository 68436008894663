import React from "react"
import { v4 as generateUUID } from "uuid"

export default function useForceUpdate(): [string, () => void] {
  const [key, setKey] = React.useState(generateUUID())
  const update = React.useCallback(() => {
    setKey(generateUUID())
  }, [])
  return [key, update]
}
