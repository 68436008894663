import * as React from "react"

export interface PropagateOptions {
  /** If true, preventDefault() will additionally be called on the event. */
  preventDefault?: boolean
}

export function useStopClickPropagation(options?: PropagateOptions) {
  return React.useCallback(
    (event: React.MouseEvent) => {
      if (options?.preventDefault) event.preventDefault()
      event.stopPropagation()
      event.nativeEvent.stopImmediatePropagation()
    },
    [options?.preventDefault]
  )
}
