export class DigitsPackageIconSize {
  static readonly Large = new DigitsPackageIconSize({
    containerWidth: 355,
    containerHeight: 260,
    scale: 2,
  })

  static readonly Medium = new DigitsPackageIconSize({
    containerWidth: 195,
    containerHeight: 155,
    scale: 1.6,
  })

  static readonly Small = new DigitsPackageIconSize({
    containerWidth: 190,
    containerHeight: 130,
    scale: 1,
  })

  readonly containerWidth: number
  readonly containerHeight: number
  readonly scale: number

  constructor({
    containerWidth,
    containerHeight,
    scale,
  }: {
    containerWidth: number
    containerHeight: number
    scale: number
  }) {
    this.containerWidth = containerWidth
    this.containerHeight = containerHeight
    this.scale = scale
  }
}
