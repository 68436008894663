import React from "react"
import { useInternalUserSettings } from "src/frontend/hooks/useInternalUserSettings"

export type GroupView = "Assigned" | "Watched" | "Open" | "Resolved"

/**
 * Initialize GroupView state and return group view setters for ActionItemSidebar.
 */
export function useGroupView() {
  const showAssignments = useInternalUserSettings().actionItemAssignments
  const [groupView, setGroupView] = React.useState<GroupView>(showAssignments ? "Assigned" : "Open")

  return { groupView, setGroupView }
}
