import * as React from "react"
import { useCurrentRoute } from "@digits-shared/hooks/useCurrentRoute"
import useRouter from "@digits-shared/hooks/useRouter"
import { usePortalStore } from "src/frontend/components/Shared/Portals/State/portalStore"
import { portalModeSelector } from "src/frontend/components/Shared/Portals/State/selectors"
import { PortalMode } from "src/frontend/components/Shared/Portals/State/types"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import routes from "src/frontend/routes"

export function useCanEditClientPortalLayout() {
  const portalMode = usePortalStore(portalModeSelector)
  return portalMode === PortalMode.Ready
}

export function useIsEditLayoutActive() {
  const layout = usePortalStore((state) => state.layout)
  const canEditLayout = useCanEditClientPortalLayout()
  const currentRoute = useCurrentRoute(routes)

  switch (true) {
    case routes.legalEntityHomeEdit.isRouteOrChildOfRoute(currentRoute):
      return canEditLayout
    case routes.reportPackageEditVersion.isRouteOrChildOfRoute(currentRoute):
      return !!layout.sections
    default:
      return false
  }
}

export function useCanActivateEditLayout() {
  const layout = usePortalStore((state) => state.layout)
  const currentRoute = useCurrentRoute(routes)

  switch (true) {
    case routes.legalEntityHome.isRoute(currentRoute):
      return true
    case routes.reportPackageEditVersion.isRouteOrChildOfRoute(currentRoute):
      return !!layout.sections
    default:
      return false
  }
}

export function useActivateEditLayout() {
  const { history, location } = useRouter()
  const generatePath = useFrontendPathGenerator()
  const canActivate = useCanActivateEditLayout()
  const currentRoute = routes[location.name]

  return React.useCallback(() => {
    if (!canActivate) return
    if (routes.legalEntityHome.isRoute(currentRoute)) {
      history.push(generatePath(routes.legalEntityHomeEdit))
    }
  }, [canActivate, currentRoute, generatePath, history])
}
