import * as React from "react"
import { MetricChartComponent } from "src/frontend/components/Shared/Layout/Components/Charts/MetricChartComponent"
import { type ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { useConfigLookbackOriginOverride } from "src/frontend/components/Shared/Layout/hooks/useConfigOriginOverride"
import {
  type MatchedComponent,
  type SizingProps,
} from "src/frontend/components/Shared/Layout/types"
import { useMetricByTimeLiveData } from "src/frontend/components/Shared/Portals/hooks/useMetricByTimeLiveData"

/*
  INTERFACES
*/

interface MetricChartComponentProps extends SizingProps {
  component: MatchedComponent<"metricChart">
  componentSize: ComponentSize
  skipAnimations: boolean
}

/*
  COMPONENTS
*/

export const MetricPortalComponent: React.FC<MetricChartComponentProps> = ({
  component,
  componentSize,
  height,
  skipAnimations,
}) => {
  const config = useConfigLookbackOriginOverride(component.config.metricChart)

  const { dimensionalSummary } = useMetricByTimeLiveData(config, component.config.type)

  return (
    <MetricChartComponent
      summaries={dimensionalSummary?.time}
      origin={config.origin}
      component={component}
      componentSize={componentSize}
      height={height}
      skipAnimations={skipAnimations}
    />
  )
}
