/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react"
import fonts from "@digits-shared/themes/typography"
import { styled } from "styled-components"

const A = styled.a`
  font-weight: ${fonts.weight.heavy};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    text-underline-offset: 3px;
  }
`

export const Anchor: React.FC<
  React.AnchorHTMLAttributes<HTMLAnchorElement> & { className?: string; popup?: boolean }
> = ({ className, popup, children, ...rest }) => {
  if (popup) {
    return (
      <A className={className} target="_blank" rel="noopener noreferrer" {...rest}>
        {children}
      </A>
    )
  }

  return <A {...rest}>{children}</A>
}
