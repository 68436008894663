import * as React from "react"
import { CategoryType } from "@digits-graphql/frontend/graphql-bearer"
import { InvertValuesContext } from "@digits-shared/components/Contexts/InvertValuesContext"
import { svgIconStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { DeltaChevron, DeltaContainer } from "@digits-shared/components/UI/Elements/Delta"
import { Amount } from "@digits-shared/components/UI/Table/Content"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import { useConfigOriginOverride } from "src/frontend/components/Shared/Layout/hooks/useConfigOriginOverride"
import {
  Title,
  type TopEntitiesProps,
} from "src/frontend/components/Shared/Portals/Components/Lists/Shared"
import { useCategoriesListCountDisplay } from "src/frontend/components/Shared/Portals/hooks/useCategoriesListCountDisplay"
import { useTopCategoriesLiveData } from "src/frontend/components/Shared/Portals/hooks/useTopCategoriesLiveData"
import {
  CategoryIconStyled,
  TopCategoriesList,
} from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/Shared/TopCategoriesList"
import { type CategoryTransactionSummary } from "src/frontend/types"
import {
  adjectiveForCategoryType,
  invertValuesForCategoryType,
} from "src/shared/helpers/categoryHelper"

const StyledTopCategoriesList = styled(TopCategoriesList)`
  ${CategoryIconStyled} {
    ${svgIconStyles(colors.secondary50)}
  }

  ${Amount}, ${DeltaContainer} {
    color: ${colors.secondary50};

    ${DeltaChevron} {
      border-bottom-color: ${colors.secondary50};
      border-top-color: ${colors.secondary50};
    }
  }
`

/*
  COMPONENTS
*/

export const TopCategories: React.FC<TopEntitiesProps> = ({ component, componentSize }) => {
  const config = useConfigOriginOverride(component.config.topEntities, 1)

  const categoryTypeName = React.useMemo(
    () => adjectiveForCategoryType(config.categoryType),
    [config.categoryType]
  )

  const { data, loading } = useTopCategoriesLiveData(config, component.config.type)

  const invertValues = invertValuesForCategoryType(config.categoryType ?? CategoryType.UnknownType)

  const categorySummaries: CategoryTransactionSummary[] | undefined = React.useMemo(
    () =>
      data?.entities.category.map((c) => ({
        category: c.categoryObject,
        summary: c.summary,
      })),
    [data]
  )

  const countDisplay = useCategoriesListCountDisplay(
    categorySummaries,
    categoryTypeName,
    config.sort,
    invertValues
  )

  if (!countDisplay) return null

  return (
    <InvertValuesContext.Provider value={invertValues}>
      <Title componentSize={componentSize}>Top {countDisplay} </Title>
      <StyledTopCategoriesList categorySummaries={categorySummaries} loading={loading} />
    </InvertValuesContext.Provider>
  )
}
