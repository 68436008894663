import * as React from "react"
import { type StatementRow } from "@digits-graphql/frontend/graphql-bearer"
import { SvgChevronDown } from "@digits-shared/components/SVGIcons/line/ChevronDown.svg"
import { themedValue } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import { themedSVGPathStyles } from "@digits-shared/themes/svgIconStyles"
import styled, { css } from "styled-components"
import { useReportOptionsContext } from "src/frontend/components/Shared/Layout/Components/Statements/ReportOptionsContext"
import { INDENT_SIZE } from "src/frontend/components/Shared/Reports/Report/Components/Statements/shared"
import {
  useCanToggleCollapse,
  useCorrespondingTitleRowId,
  useIsSectionCollapsed,
} from "src/frontend/components/Shared/Reports/Report/Options/CollapsingSections"
import zIndexes from "src/shared/config/zIndexes"

/*
  STYLES
*/

const CHEVRON_SIZE = 18

const chevronColor = themedSVGPathStyles(
  {
    light: colors.translucentSecondary60,
    dark: colors.translucentWhite80,
  },
  1.5
)

const hoverChevron = themedValue({
  light: colors.translucentSecondary10,
  dark: colors.translucentWhite30,
})

const Chevron = styled(SvgChevronDown)<{ $collapsed: boolean }>`
  width: ${CHEVRON_SIZE}px;
  height: ${CHEVRON_SIZE}px;
  padding: 2px;
  ${chevronColor};

  transform: rotate(${({ $collapsed }) => ($collapsed ? -90 : 0)}deg);
  transition: transform 200ms ease;
  border-radius: 100%;
  border-width: 0;

  &:hover {
    background-color: ${hoverChevron};
  }
`

const CollapseToggleButton = styled.button<{
  depth: number
  collapsed: boolean
  expandedOffset?: number
  collapsedOffset?: number
}>`
  margin-left: -${CHEVRON_SIZE}px;
  z-index: ${zIndexes.tooltip};
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: center;
  top: ${({ collapsed, collapsedOffset = 0, expandedOffset = 0 }) =>
    collapsed ? collapsedOffset : expandedOffset}px;
  left: calc(${({ depth }) => depth * INDENT_SIZE}px);
  opacity: 0;
  padding: 0;
  transition: opacity 150ms ease;
  pointer-events: none;

  background: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:active {
    outline: none;
  }
`

const CollapsibleContainer = styled.div<{ collapsed: boolean }>`
  position: relative;
  min-width: fit-content;
  display: flex;
  & > :last-child {
    flex: 1;
  }

  &::before {
    position: absolute;
    left: -50px;
    width: 70px;
    height: 30px;
    content: "";
  }

  ${({ collapsed }) =>
    collapsed &&
    css`
      ${CollapseToggleButton} {
        opacity: 1;
        pointer-events: auto;
      }
    `}
  &:hover {
    cursor: default;

    ${CollapseToggleButton} {
      opacity: 1;
      pointer-events: auto;
    }
  }
`

const CollapsedRowPlaceholder = styled.div``

/*
  COMPONENTS
*/

export const CollapsedRow: React.FC<{ row: StatementRow }> = ({ row: { rowId } }) => (
  <CollapsedRowPlaceholder data-context-id={rowId} />
)

export const CollapsibleRow: React.FC<
  React.PropsWithChildren<{
    rowId: string
    depth: number
    collapsedButtonOffset?: number
    expandedButtonOffset?: number
  }>
> = ({ rowId, depth, collapsedButtonOffset, expandedButtonOffset, children }) => {
  const collapsingId = useCorrespondingTitleRowId(rowId)
  const { toggleCollapsedSection } = useReportOptionsContext()
  const isSectionCollapsed = useIsSectionCollapsed(collapsingId)
  const canToggleCollapse = useCanToggleCollapse(rowId)

  const onClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      toggleCollapsedSection(collapsingId)
    },
    [collapsingId, toggleCollapsedSection]
  )

  return (
    <CollapsibleContainer
      collapsed={isSectionCollapsed}
      onClick={isSectionCollapsed ? onClick : undefined}
    >
      {canToggleCollapse && (
        <CollapseToggleButton
          onClick={onClick}
          type="button"
          depth={depth}
          collapsed={isSectionCollapsed}
          expandedOffset={expandedButtonOffset}
          collapsedOffset={collapsedButtonOffset}
        >
          <Chevron $collapsed={isSectionCollapsed} />
        </CollapseToggleButton>
      )}
      {children}
    </CollapsibleContainer>
  )
}
