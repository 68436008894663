import * as React from "react"
import { ColorIcon } from "@digits-shared/components/UI/Elements/ColorIcon"

/*
  INTERFACE
*/

interface EntityIconProps {
  imageUrl: string | undefined | null
  size?: number
  fontSize?: number
  className?: string
  children?: string
  onClick?: () => void
}

/*
  COMPONENT
*/

export const EntityIcon: React.FC<EntityIconProps> = ({
  children,
  className,
  imageUrl,
  onClick,
  size = 27,
  fontSize = 12,
}) => (
  <ColorIcon
    margin="0 5px 0 0"
    fallbackText={children}
    fontSize={fontSize}
    size={size}
    className={className}
    imageUrl={imageUrl}
    onClick={onClick}
  />
)

export const LargeEntityIcon = React.memo<EntityIconProps>(
  ({ imageUrl, size = 44, className, onClick, children }) => {
    const name = children?.[0]
    return (
      <ColorIcon
        className={className}
        fallbackText={name}
        size={size}
        imageUrl={imageUrl}
        onClick={onClick}
      />
    )
  }
)
