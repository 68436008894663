import * as React from "react"
import styled from "styled-components"

/*
  STYLES
*/

const VaultTarget = styled.div`
  display: contents;
`

/*
  INTERFACES
*/

export interface VaultDownloadLink {
  url: string
  fileName: string
}

interface VaultProps {
  children?: React.ReactNode
  fetchDownloadLink: () => Promise<VaultDownloadLink>
  holdFileDownload?: boolean
  onStart?: () => void
  onLinkReady?: () => void
  onError?: (error: Error) => void
  onDone?: () => void
  className?: string
}

/*
  COMPONENTS
*/

export const VaultDownload: React.FC<VaultProps> = ({
  fetchDownloadLink,
  holdFileDownload,
  children,
  className,
  onStart,
  onLinkReady,
  onError,
  onDone,
}) => {
  const [link, setLink] = React.useState<VaultDownloadLink>()

  React.useEffect(() => {
    if (holdFileDownload) return

    const timer = setTimeout(() => {
      if (!link) return

      const fakeAnchor = document.createElement("a")
      fakeAnchor.href = link.url
      fakeAnchor.download = link.fileName
      document.body.appendChild(fakeAnchor)

      fakeAnchor.click()
      onDone?.()
    }, 100)
    return () => timer && clearTimeout(timer)
  }, [link, onDone, holdFileDownload])

  const handleClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      onStart?.()
      fetchDownloadLink().then(
        (dLink) => {
          setLink(dLink)
          onLinkReady?.()
        },
        (error) => {
          // ignore
          if (error?.isCanceled) {
            return
          }
          onError ? onError(error) : console.error(error)
        }
      )
    },
    [onStart, fetchDownloadLink, onLinkReady, onError]
  )

  return (
    <VaultTarget className={className} onClick={handleClick}>
      {children}
    </VaultTarget>
  )
}
