import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgChevronDown } from "@digits-shared/components/SVGIcons/line/ChevronDown.svg"
import {
  EXPANDED_CLASS_NAME,
  HOVERABLE_CLASS_NAME,
  SELECTED_CLASS_NAME,
} from "@digits-shared/components/UI/Table"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"

export const CHEVRON_DISCLOSURE_FILL = colors.secondary80

export const ChevronDisclosure = styled(SvgChevronDown)`
  width: 10px;
  height: 10px;
  ${svgPathStyles(CHEVRON_DISCLOSURE_FILL, 1.5)};
  transform: rotate(-90deg);
  cursor: pointer;

  .${SELECTED_CLASS_NAME} &,
  ${"." + HOVERABLE_CLASS_NAME}:not(.${EXPANDED_CLASS_NAME}):hover & {
    ${svgPathStyles(colors.secondary, 1.5)};
  }
`

export const ChevronDisclosureForRow = styled(ChevronDisclosure)`
  align-self: center;
  transform: rotate(-90deg) translateY(10px);
`
