import React from "react"
import envHelper from "@digits-shared/helpers/envHelper"
import useRouter from "@digits-shared/hooks/useRouter"
import useSession from "@digits-shared/hooks/useSession"
import useStateBoolean from "@digits-shared/hooks/useStateBoolean"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import routes from "src/frontend/routes"
import type FrontendSession from "src/frontend/session"

export function useAddClientModal() {
  const { currentOrganization, isDigitsEmployee } = useSession<FrontendSession>()
  const pathGenerator = useFrontendPathGenerator()
  const { history } = useRouter()
  const { value: isModalActive, setTrue: showModal, setFalse: hideModal } = useStateBoolean()

  const onAddClient = React.useCallback(() => {
    if (isDigitsEmployee && envHelper.isDevelopment()) {
      // If we're in development, show the new invite client modal
      showModal()
    } else {
      history.push(pathGenerator(routes.welcome, { org: currentOrganization?.slug }))
    }
  }, [currentOrganization?.slug, history, isDigitsEmployee, pathGenerator, showModal])

  return React.useMemo(
    () => ({
      onAddClient,
      isModalActive,
      hideModal,
      showModal,
    }),
    [hideModal, isModalActive, onAddClient, showModal]
  )
}
