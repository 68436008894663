import * as React from "react"
import { svgIconStyles, svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgCornerDownLeft } from "@digits-shared/components/SVGIcons/line/CornerDownLeft.svg"
import { SvgPlusSquareSolid } from "@digits-shared/components/SVGIcons/solid/PlusSquareSolid.svg"
import colors from "@digits-shared/themes/colors"
import fonts, { BodyText, LabelText } from "@digits-shared/themes/typography"
import styled from "styled-components"
import {
  newPartyEntityResultWithId,
  type OnSelectedCallback,
} from "src/shared/components/Typeahead/sharedTypeahead"

/*
 STYLES
*/

const AddNewContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 7px 10px;
  border-top: 1px solid ${colors.translucentBlack10};

  &:hover {
    cursor: pointer;
    background: ${colors.white};
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Term = styled(BodyText)`
  b {
    font-weight: ${fonts.weight.heavy};
  }
`

const AddHelp = styled(LabelText)`
  color: ${colors.translucentSecondary80};
  display: flex;
  align-items: center;
`

const Enter = styled.span`
  color: ${colors.secondary};
  font-weight: ${fonts.weight.heavy};
  border: 1px solid ${colors.translucentSecondary40};
  border-radius: 2px;
  padding: 1px 4px;
  display: inline-flex;
  align-items: center;
  margin: 2px 4px 0;
  gap: 4px;
`

const AddIcon = styled(SvgPlusSquareSolid)`
  ${svgIconStyles(colors.primary)};
  width: 32px;
  height: 32px;
`

const EnterIcon = styled(SvgCornerDownLeft)`
  ${svgPathStyles(colors.secondary)};
  height: 10px;
  width: 10px;
`

/*
 COMPONENTS
*/

export const AddNewResult: React.FC<{
  searchTerm: string
  onResultSelected: OnSelectedCallback
}> = ({ searchTerm, onResultSelected }) => {
  const addNewResult = React.useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation()
      onResultSelected(newPartyEntityResultWithId(searchTerm.trim()))
    },
    [searchTerm, onResultSelected]
  )

  return (
    <AddNewContainer onMouseDown={addNewResult}>
      <AddIcon />
      <TextContainer>
        <Term>
          Add <b>{`"${searchTerm}"`}</b>
        </Term>

        <AddHelp>
          Shift +
          <Enter>
            <EnterIcon />
          </Enter>
        </AddHelp>
      </TextContainer>
    </AddNewContainer>
  )
}
