import * as React from "react"
import styled from "styled-components"
import { LoadingContent, PublicContent } from "src/frontend/components/Public/Content"
import { useBlockMobile } from "src/frontend/components/Shared/Responsive/BlockMobile"

const ChromeStyled = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`

const ContentViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

/**
 * Provides simplified application chrome for situations where a user is not logged in,
 * (publicly viewable) and general actions (org selector, logout) and navigation (left nav)
 * are not available.
 *
 * We don't reuse the shared Chrome because:
 *
 * 1) It provides a bunch of capabilities around contextual view handling that we don't need
 * 2) The contextual view handling actually hinders our ability to present a simple content view for
 *    a route which would normally be displayed as a contextual view.
 */
export const PublicChrome: React.FC<{ loading: boolean }> = ({ loading }) => {
  const blockedContent = useBlockMobile()
  if (blockedContent) return blockedContent

  const ContentView = loading ? LoadingContent : PublicContent

  return (
    <ChromeStyled>
      <ContentViewContainer>
        <ContentView />
      </ContentViewContainer>
    </ChromeStyled>
  )
}
