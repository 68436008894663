import * as React from "react"
import useLazyInterval from "@digits-shared/hooks/useLazyInterval"

type IntervalCallback = () => void

/**
 * This hook sets up an interval and clears it after unmounting.
 * It’s a combo of setInterval and clearInterval tied to the component lifecycle.
 *
 * Can only be used in React Functional Components since it uses `React.useEffect`
 * hook to tap into the components lifecycle.
 */
export default function useInterval(callback: IntervalCallback, delay: number) {
  const [setInterval, timerRef] = useLazyInterval(callback, delay)

  // Set up the timeout.
  React.useEffect(() => {
    setInterval()
  }, [setInterval, delay])

  return timerRef
}
