import * as React from "react"
import {
  type IntervalOrigin,
  type Period,
  type PeriodMonetaryValue,
} from "@digits-graphql/frontend/graphql-bearer"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import { type ScaleLinear } from "d3-scale"
import { useStackableBarChartContext } from "src/frontend/components/OS/Shared/Charts/StackableBarChart/StackableBarChartContext"

export const STACKABLE_BAR_CHART_X_INNER_PADDING = 0.4
export const STACKABLE_BAR_CHART_X_AXIS_PADDING = 5
export const STACKABLE_BAR_CHART_X_AXIS_OFFSET = 15
export const STACKABLE_BAR_CHART_MIN_HEIGHT = 2
export const STACKABLE_BAR_CHART_SINGLE_LABEL_HEIGHT = 50
export const STACKABLE_BAR_CHART_ALL_BAR_LABEL_HEIGHT = 30
export const STACKABLE_BAR_CHART_SINGLE_LABEL_OFFSET = STACKABLE_BAR_CHART_SINGLE_LABEL_HEIGHT + 5
export const STACKABLE_BAR_CHART_LEGEND_SPACING = 35

/*
 INTERFACES
*/

export interface StackableBarData {
  dataId: string
  stackIndex: number
  period: Period
  total: PeriodMonetaryValue
  legend: string
  barColorIds: StackableBarColorsIds
}

export interface StackableBarColorsIds {
  inactivePeriodFillId: string
  inactivePeriodHoverFillId: string
  activePeriodFillId: string
  activePeriodHoverFillId: string
}

export type ChartPeriodsMap = { [key: number]: StackableBarData[] }

export interface StackableBarDimensions {
  height: number
  width: number
  x: number
  y: number
}

/*
 HOOKS
*/

export function useIsCurrentPeriod(startedAt: number) {
  const { intervalOrigin } = useStackableBarChartContext()
  return isTimestampCurrentPeriod(startedAt, intervalOrigin)
}

export function useStackableBarChartShowTooltipGenerator() {
  const { showTooltip } = useStackableBarChartContext()

  return React.useCallback(
    (barData: StackableBarData) => (event: React.MouseEvent) => {
      event.stopPropagation()

      showTooltip({
        tooltipData: barData,
      })
    },
    [showTooltip]
  )
}

export function useStackableBarChartClickGenerator() {
  const { periodData, onBarClick } = useStackableBarChartContext()

  return React.useCallback(
    (barData: StackableBarData) => (event: React.MouseEvent) => {
      const periodBarData = periodData[barData.period.startedAt] || []
      const periodBarIndex = Object.values(periodData).indexOf(periodBarData)
      return onBarClick?.(barData, periodBarIndex, event)
    },
    [onBarClick, periodData]
  )
}

/*
 HELPERS
*/

export function isTimestampCurrentPeriod(startedAt: number, intervalOrigin: IntervalOrigin) {
  const period = { startedAt, interval: intervalOrigin.interval }

  return dateTimeHelper.isPeriodEqualToIntervalOrigin(period, intervalOrigin)
}

export function stackableBarChartXData(barData: StackableBarData): number {
  return barData.period.startedAt
}

export function stackableBarChartYData(invertValues: boolean, barData: StackableBarData): number {
  return barData.total.value.amount === 0
    ? 0
    : invertValues
      ? barData.total.value.amount * -1
      : barData.total.value.amount
}

export function stackableBarChartYMaxData(invertValues: boolean, periods: ChartPeriodsMap) {
  const maxNegative = Object.values(periods)
    .map((chartData) =>
      chartData
        .map((d) => stackableBarChartYData(invertValues, d))
        .filter((v) => v < 0)
        .map((v) => Math.abs(v))
        .reduce((max, v) => max + v, 0)
    )
    .reduce((max, v) => (v > max ? v : max), 0)

  const maxPositive = Object.values(periods)
    .map((chartData) =>
      chartData
        .map((d) => stackableBarChartYData(invertValues, d))
        .filter((v) => v > 0)
        .map((v) => Math.abs(v))
        .reduce((max, v) => max + v, 0)
    )
    .reduce((max, v) => (v > max ? v : max), 0)

  return maxNegative + maxPositive
}

export function stackableBarChartLargestValue(
  invertValues: boolean,
  periodMap: ChartPeriodsMap,
  yScale: ScaleLinear<number, number>,
  yMax: number
) {
  return Object.values(periodMap)
    .map((chartData) =>
      chartData
        .map((d) =>
          stackableBarChartBarHeight(stackableBarChartYData(invertValues, d), yScale, yMax)
        )
        .reduce((acc, h) => acc + h, 0)
    )
    .reduce((max, h) => (h > max ? h : max), 0)
}

export function stackableBarChartBarHeight(
  yData: number,
  yScale: ScaleLinear<number, number>,
  yMax: number
) {
  const scaledYValue = yScale(Math.abs(yData)) ?? 0

  return Math.max(yMax - scaledYValue, Math.abs(yData) ? STACKABLE_BAR_CHART_MIN_HEIGHT : 0)
}

export function stackableBarChartGroupByPeriods(data?: StackableBarData[]): ChartPeriodsMap {
  if (!data) return {}

  // sort data first by period and then by stack index
  data.sort((d1, d2) => {
    if (d1.period.startedAt !== d2.period.startedAt) {
      return d1.period.startedAt > d2.period.startedAt ? 1 : -1
    }
    return d1.stackIndex > d2.stackIndex ? -1 : 1
  })

  return data.reduce<ChartPeriodsMap>((periods, barData) => {
    const period = periods[barData.period.startedAt] || []
    period.push(barData)
    periods[barData.period.startedAt] = period
    return periods
  }, {})
}
