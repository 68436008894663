import { Component, type RefObject } from "react"
import { withRouter } from "react-router-dom"
import { type DigitsRouteProps } from "@digits-shared/components/Router/DigitsRouter"
import { type Action, type Location, type UnregisterCallback } from "history"
import { elementByIdScrollTop, elementScrollTop, getElementScrollTop } from "./Utils"

interface ScrollProps {
  scrollElement?: RefObject<Element | null>
  elementID?: string
  ready?: Promise<void>
  behavior?: ScrollBehavior
}

type Props = ScrollProps & DigitsRouteProps

interface ScrollData {
  scrollHeight: number
  scrollTop: number
}

class ScrollMemory extends Component<Props> {
  url: Map<string, ScrollData> = new Map()

  historyUnregisterCallback?: UnregisterCallback

  componentDidMount() {
    this.historyUnregisterCallback = this.props.history.listen(this.handleHistoryEvent)
  }

  shouldComponentUpdate(nextProps: Props) {
    const { location, history } = this.props

    const { scrollElement, elementID, behavior } = this.props

    if (scrollElement === undefined && !elementID) {
      throw new Error("must pass a scrollElement or elementID")
    }

    // location before change url
    const actual = location
    // location after change url
    const next = nextProps.location
    // the first page has not key, set "enter" for key
    const key = actual.key || "enter"

    // if hash => let the normal operation of the browser
    const locationChanged =
      (next.pathname !== actual.pathname || next.search !== actual.search) && next.hash === ""

    // get scroll of the page or the element before change location
    const element = elementID ? document.getElementById(elementID) : scrollElement?.current
    const scrollTop = element ? getElementScrollTop(element) : 0
    const scrollHeight = element?.scrollHeight ?? 0

    if (locationChanged) {
      // pass page or element scroll to top if this is a PUSH action
      if (history.action === "PUSH") {
        if (elementID) {
          elementByIdScrollTop(0, elementID, behavior)
        } else if (scrollElement?.current) {
          elementScrollTop(0, scrollElement.current, behavior)
        }
      }

      // save scroll with key location
      this.url.set(key, { scrollTop, scrollHeight })
    }
    // never render
    return false
  }

  componentWillUnmount() {
    this.historyUnregisterCallback?.()
  }

  render() {
    return null
  }

  handleHistoryEvent = (location: Location, action: Action) => {
    switch (action) {
      case "POP":
        return this.handlePopAction(location)
      case "PUSH":
      case "REPLACE":
      default:
      // NotImplemented
    }
  }

  handlePopAction(location: Location) {
    const { ready, behavior } = this.props

    // key or enter page
    const key = location.key || "enter"
    // get the next for scroll position
    const data = this.url.get(key)

    // if find in url map => scroll to position
    if (data !== undefined) {
      this.scrollWhenContentReady(data, behavior)
      if (ready) {
        ready.then(() => this.scrollWhenContentReady(data, behavior))
      }
    }
  }

  scrollWhenContentReady = (
    data: ScrollData,
    behavior?: ScrollBehavior,
    timestamp = Date.now()
  ) => {
    const { elementID, scrollElement } = this.props
    const element = elementID ? document.getElementById(elementID) : scrollElement?.current
    if (!element) return
    ;(element as HTMLElement).style.filter = "opacity(0)"
    const { scrollHeight, scrollTop } = data
    if (element.scrollHeight < scrollHeight && timestamp + 3000 > Date.now()) {
      window.requestAnimationFrame(() => this.scrollWhenContentReady(data, behavior, timestamp))
      return
    }
    ;(element as HTMLElement).style.removeProperty("filter")
    elementScrollTop(scrollTop, element, behavior)
  }
}

export default withRouter(ScrollMemory)
