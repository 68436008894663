import * as React from "react"
import useLazyTimeout from "./useLazyTimeout"

type TimerCallback = () => void

/**
 * This hook sets up a timer and clears it after unmounting.
 * It’s a combo of setTimeout and clearTimeout tied to the component lifecycle.
 *
 * Can only be used in React Functional Components since it uses `React.useEffect`
 * hook to tap into the components lifecycle.
 */
export default function useTimeout(callback: TimerCallback, delay: number) {
  const [setTimeout, timerRef] = useLazyTimeout(callback, delay)

  // Set up the timeout.
  React.useEffect(() => {
    setTimeout()
  }, [setTimeout, delay])

  return timerRef
}
