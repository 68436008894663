import * as React from "react"
import { type LayoutComponent } from "@digits-graphql/frontend/graphql-bearer"
import { useDndContext } from "@dnd-kit/core"
import { type ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import {
  CHART_COMPONENT_HEIGHT,
  ComponentWrapper,
} from "src/frontend/components/Shared/Layout/Shared"
import { MatchComponent } from "src/frontend/components/Shared/Portals/Components/MatchComponent"
import { useDropInfo } from "src/frontend/components/Shared/Portals/DragAndDrop/useDropInfo"
import {
  usePortalDispatch,
  usePortalStore,
} from "src/frontend/components/Shared/Portals/State/portalStore"
import { activeComponentSelector } from "src/frontend/components/Shared/Portals/State/selectors"

/**
 * Hook that updates the component drag overlay for a particular section and row.
 */
export function useUpdateComponentDragOverlay(
  sectionId: string,
  rowId: string | null,
  isOver: boolean
) {
  const activeComponent = usePortalStore(activeComponentSelector)

  const { componentSize, componentWidth, dropAllowed } = useDropInfo({
    sectionId,
    rowId,
    additionToRow: true,
  })
  const setComponentDragOverlay = useSetComponentDragOverlay()

  React.useEffect(() => {
    if (dropAllowed && activeComponent && isOver) {
      setComponentDragOverlay(activeComponent, componentSize, componentWidth)
    }
  }, [activeComponent, componentSize, componentWidth, dropAllowed, isOver, setComponentDragOverlay])
}

/**
 * Helper hook which returns a function that can be used to set the drag overlay for a component.
 */
export function useSetComponentDragOverlay() {
  const builderDispatch = usePortalDispatch()
  const dndContext = useDndContext()

  const onTransitionEnd = React.useCallback(() => {
    builderDispatch({ type: "componentSlideEnded" })
  }, [builderDispatch])

  return React.useCallback(
    (component: LayoutComponent, componentSize: ComponentSize, componentWidth: number) => {
      builderDispatch({
        type: "setDragOverlayContent",
        content: (
          <ComponentWrapper
            ref={dndContext.dragOverlay.setRef}
            isOverlay
            width={componentWidth}
            onTransitionEnd={onTransitionEnd}
          >
            <MatchComponent
              component={component}
              componentSize={componentSize}
              height={CHART_COMPONENT_HEIGHT}
              skipAnimations
              isOverlay
            />
          </ComponentWrapper>
        ),
      })
    },
    [builderDispatch, dndContext.dragOverlay.setRef, onTransitionEnd]
  )
}
