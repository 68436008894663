import * as React from "react"
import { type PropsWithChildren, type ReactElement } from "react"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import { WIDGET_PADDING } from "src/frontend/components/OS/Shared/Widgets/WidgetContainer"

const SELECTED_CLASS_NAME = "selected-item"

/*
  STYLES
*/

const List = styled.div<{ padding: number }>`
  margin: 0 -${({ padding }) => padding}px;
`

export const ListItem = styled.div<{
  padding: number
}>`
  display: grid;
  ${({ padding }) => `grid-template-columns: ${padding - 10}px 25px 1fr auto ${padding - 10}px;`}
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  align-items: center;
  justify-content: space-between;
  padding: 3px 0;

  border-style: solid;
  border-color: transparent transparent ${colors.translucentPrimary10};
  border-width: 1px 0 1px 0;
  &:last-of-type {
    border-bottom-color: transparent;
  }

  &:hover,
  &.${SELECTED_CLASS_NAME} {
    &:first-of-type {
      border-top-color: ${colors.translucentPrimary10};
    }

    &:last-of-type {
      border-bottom-color: ${colors.translucentPrimary10};
    }
    background: ${colors.translucentSecondary05};
  }

  cursor: ${({ onClick }) => (onClick ? "pointer" : "auto")};
`

export const TopList = styled(WidgetSummaryList)`
  margin: 0 -10px;

  ${ListItem} {
    height: 44px;
  }
`

const Padding = styled.div``

const ZeroState = styled.div`
  font-size: 12px;
  font-weight: ${fonts.weight.normal};
  text-align: center;
  margin: 25px 0 16px;
`

/*
  INTERFACES
*/

export interface SummaryListData<D> {
  data?: D
  loading: boolean
}

interface WidgetSummaryListProps<D> {
  measureRef?: React.Ref<HTMLDivElement>
  className?: string
  padding?: number
  data?: D[]
  loading: boolean
  activeIndex: number | undefined
  IconComponent: React.ComponentType<SummaryListData<D>>
  LabelComponent: React.ComponentType<SummaryListData<D>>
  SummaryComponent: React.ComponentType<SummaryListData<D>>

  onClick?: (data: D, e: React.MouseEvent) => void
  onMouseEnter?: (data: D) => void
  onMouseLeave?: () => void
}

/*
  COMPONENTS
*/

export function WidgetSummaryList<D>({
  measureRef,
  className,
  padding = WIDGET_PADDING,
  data,
  loading,
  IconComponent,
  LabelComponent,
  SummaryComponent,
  activeIndex,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: PropsWithChildren<WidgetSummaryListProps<D>>): ReactElement | null {
  if (loading || !data) {
    const loaders = Array.from({ length: 5 }).map((_, idx) => (
      <ListItem key={`loader_${idx}`} padding={padding}>
        <Padding />
        <IconComponent loading />
        <LabelComponent loading />
        <SummaryComponent loading />
        <Padding />
      </ListItem>
    ))

    return <List padding={padding}>{loaders}</List>
  }

  return (
    <List ref={measureRef} className={className} padding={padding}>
      {data.map((summary, idx) => (
        <ListItem
          key={`summary_${idx}`}
          padding={padding}
          className={activeIndex === idx ? SELECTED_CLASS_NAME : ""}
          onClick={onClick?.bind(undefined, summary)}
          onMouseEnter={onMouseEnter?.bind(undefined, summary)}
          onMouseLeave={onMouseLeave}
        >
          <Padding />
          <IconComponent data={summary} loading={false} />
          <LabelComponent data={summary} loading={false} />
          <SummaryComponent data={summary} loading={false} />
          <Padding />
        </ListItem>
      ))}
      {!loading && !data.length && <ZeroState>None Found</ZeroState>}
    </List>
  )
}
