import * as React from "react"
import { useGraphQLTracer } from "@digits-shared/components/Contexts/GraphQLTracerContext"
import type Session from "@digits-shared/session/Session"

const LazyGraphQLTraceDebugger = React.lazy(
  () => import("src/shared/components/Support/LazyGraphQLTraceDebugger")
)

/*
  COMPONENTS
*/

export const GraphQLTraceDebugger: React.FC<{
  session: Session
}> = ({ session }) => {
  const graphQLTracer = useGraphQLTracer()

  if (!graphQLTracer.state.debuggerEnabled) {
    return null
  }

  return (
    <React.Suspense>
      <LazyGraphQLTraceDebugger session={session} />
    </React.Suspense>
  )
}
