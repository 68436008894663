import * as React from "react"
import {
  type Period,
  type PeriodMonetaryValue,
  type TransactionSummary,
} from "@digits-graphql/frontend/graphql-bearer"
import numberHelper from "@digits-shared/helpers/numberHelper"
import objectHelper from "@digits-shared/helpers/objectHelper"
import { PaletteBarColors } from "src/frontend/components/OS/Shared/Charts/StackableBarChart/GradientStackableBarChart"
import { type StackableBarData } from "src/frontend/components/OS/Shared/Charts/StackableBarChart/Shared"

export interface BasicDimension {
  id: string
  name: string
}

export interface PeriodDimensionSummary<D extends BasicDimension> {
  period: Period
  dimensions: DimensionSummary<D>[]
}

export interface DimensionSummary<D extends BasicDimension> {
  dimension: D
  summary: TransactionSummary
}

export interface DimensionSummaryHistory {
  dimensionId: string
  summaries: TransactionSummary[]
}

interface TopDimensionMap<D extends BasicDimension> {
  [key: string]: {
    dimension: DimensionSummary<D>
    position: number
  }
}

export function useDimensionSummaryStackableData<D extends BasicDimension>(
  topDimensions: DimensionSummary<D>[] = [],
  summaries: PeriodDimensionSummary<D>[] = [],
  histories: DimensionSummaryHistory[] = [],
  otherDimensionName: string | undefined = undefined
): StackableBarData[] {
  return React.useMemo(() => {
    const topDimensionMap = mapDimensions(otherDimensionName, topDimensions)

    const highestStackIndex =
      Object.values(topDimensionMap)
        .map((d) => d.position)
        .reduce((max, i) => Math.max(i, max), 0) ?? -1

    const topHistories =
      topDimensions
        .map((summary) => histories?.find((h) => h.dimensionId === summary.dimension.id))
        .filter((h): h is DimensionSummaryHistory => h !== undefined) ?? []

    const expectedLength =
      topDimensions.length - (topDimensionMap[otherDimensionName || ""] ? 1 : 0)

    if (topHistories.length !== expectedLength) {
      console.error("Missing history for top dimensions")
      return []
    }

    return summaries
      .flatMap<StackableBarData>((periodSummary) =>
        summarizeDimensionPeriods(
          otherDimensionName,
          topDimensionMap,
          highestStackIndex,
          topDimensions,
          topHistories,
          periodSummary
        )
      )
      .reverse()
  }, [histories, otherDimensionName, summaries, topDimensions])
}

function summarizeDimensionPeriods<D extends BasicDimension>(
  otherDimensionName: string | undefined,
  topDimensionMap: TopDimensionMap<D>,
  highestStackIndex: number,
  topDimensions: DimensionSummary<D>[],
  topHistories: DimensionSummaryHistory[],
  periodSummary: PeriodDimensionSummary<D>
) {
  const { period } = periodSummary
  let otherDimensionSummary: TransactionSummary | undefined

  // Map those dimensions in this period that are also top dimensions in the current period
  const currentTopDimensions = periodSummary.dimensions
    .map<StackableBarData | undefined>((dimensionSummary) => {
      const { dimension, summary } = dimensionSummary

      if (dimension.name === otherDimensionName) {
        otherDimensionSummary = summary
        return
      }

      // if "dimension" is not a top dimension for the current period, do not add it as a
      // stack, it will be accounted for in "Others"
      const topDimension = topDimensionMap[dimension.id]

      if (!topDimension) return

      const dataId = dimension.id
      const legend = dimension.name
      const stackIndex = topDimension.position

      return buildStackableBarData(
        period,
        dimensionSummary.summary.total,
        dataId,
        stackIndex,
        legend
      )
    })
    .filter((data): data is StackableBarData => data !== undefined)

  const missingTopDimensions = topDimensions
    .map<StackableBarData | undefined>((dimensionSummary) => {
      const { dimension } = dimensionSummary
      if (dimension.name === otherDimensionName) return

      // if the current period "top dimension" is not found in this period's top dimensions,
      // use the historic data to show in the graph
      const periodTopDimension = periodSummary.dimensions.find(
        (cat) => cat.dimension.id === dimension.id
      )
      if (periodTopDimension) return

      const topDimension = topDimensionMap[dimension.id] as (typeof topDimensionMap)[number]

      const history = topHistories.find((h) => h.dimensionId === dimension.id)
      const historySummary =
        history &&
        history.summaries.find((summary) => summary.period.startedAt === period.startedAt)
      const total = historySummary ? historySummary.total : buildMonetaryValue()

      const dataId = dimension.id
      const legend = dimension.name
      const stackIndex = topDimension.position

      return buildStackableBarData(period, total, dataId, stackIndex, legend)
    })
    .filter((data): data is StackableBarData => data !== undefined)

  const otherDimensions = otherDimensionName
    ? buildOtherDimensions(
        otherDimensionName,
        otherDimensionSummary,
        highestStackIndex,
        periodSummary,
        topDimensionMap,
        topDimensions,
        topHistories
      )
    : []

  return currentTopDimensions.concat(missingTopDimensions, otherDimensions)
}

function buildOtherDimensions<D extends BasicDimension>(
  otherDimensionName: string,
  otherDimensionSummary: TransactionSummary | undefined,
  highestStackIndex: number,
  periodSummary: PeriodDimensionSummary<D>,
  topDimensionMap: TopDimensionMap<D>,
  topDimensions: DimensionSummary<D>[],
  topHistories: DimensionSummaryHistory[]
) {
  const otherTotal = buildMonetaryValue(otherDimensionSummary?.total)

  // calculate missing other amounts, these are the top dimensions for that period that
  // are not a top dimension in the current period. We need to add to "Other" theses amounts
  // given that we are not going to show these top dimensions, we only show the same top
  // dimensions for the current period in all periods
  periodSummary.dimensions.forEach((s) => {
    // if "Other Dimension" or "dimension" is a top dimension do not add it up (it will show in the stack)
    if (s.dimension.name === otherDimensionName || topDimensionMap[s.dimension.id]) return

    // This "dimension" does not repeat in the Top Dimensions, we need to add it to Other
    otherTotal.value.amount += s.summary.total.value.amount
    otherTotal.transactionsCount += s.summary.total.transactionsCount
  })

  // Now subtract from Other any dimension in this period that is not a top dimension on that
  // period (it would be a stack but also included in Other, so double-counted)
  topDimensions.forEach((topDimension) => {
    if (topDimension.dimension.name === otherDimensionName) return

    const dimension = periodSummary.dimensions.find(
      (s) => s.dimension.id === topDimension.dimension.id
    )

    if (dimension) return

    const history = topHistories.find((h) => h.dimensionId === topDimension.dimension.id)
    const historySummary =
      history &&
      history.summaries.find((s) => s.period.startedAt === periodSummary.period.startedAt)

    if (!historySummary) {
      return
    }

    // This "dimension" repeats in the Top C, we need to subtract it from Other
    otherTotal.value.amount -= historySummary.total.value.amount
    otherTotal.transactionsCount -= historySummary.total.transactionsCount
  })

  return buildStackableBarData(
    periodSummary.period,
    otherTotal,
    otherDimensionName,
    highestStackIndex,
    otherDimensionName
  )
}

function buildStackableBarData(
  period: Period,
  total: PeriodMonetaryValue,
  dataId: string,
  stackIndex: number,
  legend: string
) {
  const barColors = { ...PaletteBarColors }
  objectHelper
    .keysOf(barColors)
    .forEach((color) => (barColors[color] = `${barColors[color]}-${stackIndex}`))

  return {
    dataId,
    stackIndex,
    period,
    barColorIds: barColors,
    total,
    legend,
  }
}

function buildMonetaryValue(total?: PeriodMonetaryValue): PeriodMonetaryValue {
  if (total) return JSON.parse(JSON.stringify(total))

  return {
    value: numberHelper.buildMonetaryAmount(),
    transactionsCount: 0,
  }
}

function mapDimensions<D extends BasicDimension>(
  otherDimensionName: string | undefined,
  topDimensions?: DimensionSummary<D>[]
) {
  return (
    topDimensions?.reduce<TopDimensionMap<D>>((map, dimensionSummary, idx) => {
      const { dimension } = dimensionSummary
      const catId = dimension.name === otherDimensionName ? dimension.name : dimension.id
      const position = dimension.name === otherDimensionName ? topDimensions.length - 1 : idx

      map[catId] = {
        dimension: dimensionSummary,
        position,
      }

      return map
    }, {}) ?? {}
  )
}
