import {
  type SessionOrganization,
  type SessionOrganizationAttributes,
} from "src/frontend/session/Organization"

/**
 * Simple wrapper for affiliation organizations stored on JWT.
 *
 * Provides convenience methods, better naming than what is found on the intentionally
 * abbreviated JWT, and clean types.
 */
export default class SessionAffiliationOrganization implements SessionOrganization {
  private readonly attributes: SessionOrganizationAttributes

  constructor(rawAffiliatedOrganization: SessionOrganizationAttributes) {
    this.attributes = {
      ...rawAffiliatedOrganization,
    }
  }

  /*
    ACCESSORS
  */

  get id() {
    return this.attributes.id
  }

  get slug() {
    return this.attributes.slug
  }

  get name() {
    return this.attributes.name
  }

  get iconUrl() {
    return this.attributes.iconUrl
  }
}
