import * as React from "react"
import { type IntervalOrigin } from "@digits-graphql/frontend/graphql-bearer"
import { type DigitsLocation } from "@digits-shared/components/Router/DigitsLocation"
import {
  type DigitsRoute,
  type IncludedQueryParams,
  type RouteParams,
  type StaticRoutes,
} from "@digits-shared/components/Router/DigitsRoute"
import envHelper from "@digits-shared/helpers/envHelper"
import useRouter from "@digits-shared/hooks/useRouter"

export type PathGenerator = (
  to: DigitsRoute,
  parameters?: RouteParams,
  includedQueryParams?: IncludedQueryParams,
  excludedQueryParams?: string[]
) => string

/**
 * Returns a function which accepts a route and parameter config info, and produces a path.
 *
 * If the provided parameters are incomplete for the needed path parameters, param values
 * from the current route/path will be used to fill in missing values (by name). After that,
 * if there are still missing parameters, a warning will be printed to the console.
 */
export function usePathGenerator(
  routes: StaticRoutes,
  contextOrigin?: IntervalOrigin
): PathGenerator {
  const { location } = useRouter()

  return React.useCallback(
    (
      to: DigitsRoute,
      parameters: Record<string, string | number | boolean | undefined | null> = {},
      includedQueryParams?: IncludedQueryParams,
      excludedQueryParams?: string[]
    ) => {
      const generatePath = createPathGenerator(routes, location, contextOrigin)
      return generatePath(to, parameters, includedQueryParams, excludedQueryParams)
    },
    [location, contextOrigin, routes]
  )
}

export function createPathGenerator(
  routes: StaticRoutes,
  location: DigitsLocation,
  contextOrigin?: IntervalOrigin
) {
  return (
    to: DigitsRoute,
    parameters: Record<string, string | number | boolean | undefined | null> = {},
    includedQueryParams?: IncludedQueryParams,
    excludedQueryParams?: string[]
  ) => {
    const routeConfigs = Object.values(routes)
    const currentRoute =
      routeConfigs.find((config) => config.name === location.name) || routeConfigs[0]

    if (!to) {
      console.error("No route passed to usePathGenerator")
      if (envHelper.isDevelopment()) {
        throw new Error(
          "No route passed to usePathGenerator. Needs to be fixed to avoid crashes or errors"
        )
      }
      return "/"
    }

    return to.generatePathFromCurrentRoute({
      location,
      currentRoute,
      parameters: normalizeParams(parameters),
      includedQueryParams,
      excludedQueryParams,
      contextOrigin,
    })
  }
}

/** Helper to filter out undefined values and convert all values to strings */
function normalizeParams(
  parameters: Record<string, string | number | boolean | undefined | null>
): Record<string, string> {
  return Object.fromEntries(
    Object.entries(parameters)
      .filter(definedValue)
      .map(([key, value]) => [key, value.toString()])
  )
}

/** Predicate to filter and remove the undefined type from an entry value */
function definedValue(
  entry: [string, string | number | boolean | undefined | null]
): entry is [string, string | number | boolean] {
  return entry[1] !== undefined && entry[1] !== null
}
