import {
  type CategoryType,
  type EntityCategory,
  type EntityParty,
  type EntityUser,
  type ObjectIdentifier,
  ObjectKind,
} from "@digits-graphql/frontend/graphql-bearer"
import { v4 as generateUUID } from "uuid"

export const NEW_ENTITY_ID = "new-entity-id"

/*
 INTERFACES
*/

export type CategoryTypeaheadProps = {
  onResultSelected: OnSelectedCallback<CategoryTypeaheadResult>
  kind: ObjectKind.Category
  includedTypes?: CategoryType[]
}

export type PartyTypeaheadProps = {
  kind: ObjectKind.Party
  onResultSelected: OnSelectedCallback<PartyTypeaheadResult>
}

export type NewPartyTypeaheadProps = {
  kind: ObjectKind.Party
  allowAddNew: true
  newEntities?: NewPartyEntity[]
  onResultSelected: OnSelectedCallback<PartyWithNewEntitiesTypeaheadResult>
}

export type UserTypeaheadProps = {
  onResultSelected: OnSelectedCallback<UserTypeaheadResult>
  kind: ObjectKind.User
  objectIdentifier: ObjectIdentifier
}

export type TypeaheadKinds =
  | CategoryTypeaheadProps
  | PartyTypeaheadProps
  | NewPartyTypeaheadProps
  | UserTypeaheadProps

export function isCategoryTypeAhead(t: TypeaheadKinds): t is CategoryTypeaheadProps {
  return t.kind === ObjectKind.Category
}

export function isPartyTypeAhead(t: TypeaheadKinds): t is PartyTypeaheadProps {
  return t.kind === ObjectKind.Party
}

export function isPartyWithNewTypeAhead(t: TypeaheadKinds): t is NewPartyTypeaheadProps {
  return isPartyTypeAhead(t) && (t as NewPartyTypeaheadProps).allowAddNew
}

export function isUserTypeAhead(t: TypeaheadKinds): t is UserTypeaheadProps {
  return t.kind === ObjectKind.User
}

type TypeaheadBaseResult = {
  id: string
  depth?: number
  title: string
  subtitle?: string | null
  searchValues: string[]
  isSelectable?: boolean
}

export type CategoryTypeaheadResult = TypeaheadBaseResult & {
  kind: ObjectKind.Category
  entity: EntityCategory
}

export type PartyTypeaheadResult = TypeaheadBaseResult & {
  kind: ObjectKind.Party
  entity: EntityParty
}

export type PartyWithNewEntitiesTypeaheadResult = Omit<PartyTypeaheadResult, "entity"> & {
  entity: EntityParty | NewPartyEntity
}

export type UserTypeaheadResult = TypeaheadBaseResult & {
  kind: ObjectKind.User
  entity: EntityUser
}

export type TypeaheadResult =
  | CategoryTypeaheadResult
  | PartyTypeaheadResult
  | PartyWithNewEntitiesTypeaheadResult
  | UserTypeaheadResult

export type OnSelectedCallback<T extends TypeaheadResult = TypeaheadResult> = (data: T) => void

export type NewPartyEntity = Pick<EntityParty, "id" | "name" | "iconUrl">
export type TypeaheadEntity = EntityCategory | EntityParty | NewPartyEntity | EntityUser

export function isTypeaheadParty(entity: TypeaheadEntity): entity is EntityParty {
  return !!(entity as EntityParty).designation
}

export function isTypeaheadNewParty(entity: TypeaheadEntity): entity is NewPartyEntity {
  return !isTypeaheadParty(entity) && !isTypeaheadCategory(entity) && !isTypeaheadUser(entity)
}

export function isTypeaheadCategory(entity: TypeaheadEntity): entity is EntityCategory {
  return !!(entity as EntityCategory).type
}

export function isTypeaheadUser(entity: TypeaheadEntity): entity is EntityUser {
  return !!(entity as EntityUser).emailAddress
}

export const newPartyEntityResultWithId = (name: string): PartyWithNewEntitiesTypeaheadResult => ({
  // Intentionally an invalid UUID, as this should be cleaned up before being sent to the BE
  id: NEW_ENTITY_ID + "-" + generateUUID(),
  title: name,
  searchValues: [name],

  kind: ObjectKind.Party,
  entity: {
    // Intentionally an invalid UUID, as this should be cleaned up before being sent to the BE
    id: NEW_ENTITY_ID + "-" + generateUUID(),
    name,
    iconUrl: null,
  },
})

export const isNewPartyId = (partyId?: string) => partyId?.startsWith(NEW_ENTITY_ID)
