import {
  type DigitsLocation,
  mutateLocationWithDigitsProperties,
} from "@digits-shared/components/Router/DigitsLocation"
import { type StaticRoutes } from "@digits-shared/components/Router/DigitsRoute"
import { DigitsRouter, type DigitsRouterProps } from "@digits-shared/components/Router/DigitsRouter"
import {
  type DigitsHistory,
  HistoryAction,
  HistoryType,
} from "@digits-shared/components/Router/History"
import { createMemoryHistory } from "history"
import {
  SwitchableHistory,
  type SwitchableHistoryDelegate,
} from "src/shared/components/Router/SwitchableHistory"

export interface SwitchableRouterProps<SRC extends StaticRoutes> extends DigitsRouterProps<SRC> {
  browserHistory: DigitsHistory
  initialEntries?: string[]
}

export abstract class SwitchableRouter<SRC extends StaticRoutes, P = {}> extends DigitsRouter<
  SRC,
  SwitchableRouterProps<SRC> & P
> {
  abstract historyDelegate: SwitchableHistoryDelegate

  declare history: SwitchableHistory

  protected constructor(props: SwitchableRouterProps<SRC> & P) {
    const { browserHistory } = props
    super(props, browserHistory)
  }

  configureHistory() {
    const { browserHistory, initialEntries, routes } = this.props

    const memoryHistory = createMemoryHistory({
      initialEntries,
    })
    mutateLocationWithDigitsProperties(memoryHistory.location, routes)

    this.history = new SwitchableHistory(
      memoryHistory as unknown as DigitsHistory,
      browserHistory,
      this.historyDelegate,
      this.historyType(browserHistory.location.name)
    )

    this.unregisterHistoryListener = this.history.listen(this.onHistoryLocationChange)
    this.onHistoryLocationChange(this.history.location as DigitsLocation)
  }

  componentDidMount() {
    super.componentDidMount()

    const { browserHistory } = this.props

    // Because it changes router state, it's important that this happens before,
    // and outside of, a render() call. Otherwise it will cause React errors
    // about updating during a state transition.
    this.history.switchTo(this.historyType(browserHistory.location.name), HistoryAction.None)
  }

  shouldComponentUpdate() {
    const { browserHistory } = this.props

    // Determine what the history type should be for this location
    const historyType = this.historyType(browserHistory.location.name)

    // If we are attempting to switch to browser history, dont perform a history action
    const historyAction =
      historyType === HistoryType.Browser ? HistoryAction.None : HistoryAction.Replace

    // Because it changes router state, it's important that this happens before,
    // and outside of, a render() call. Otherwise it will cause React errors
    // about updating during a state transition.
    this.history.switchTo(historyType, historyAction)

    return true
  }

  abstract historyType(locationName: string): HistoryType

  switchTo(historyType: HistoryType, action?: HistoryAction) {
    this.history.switchTo(historyType, action)
  }

  inNonContentView(locationName: string) {
    const { routes } = this.props
    return routes.pathIsDetailsView(locationName)
  }
}
