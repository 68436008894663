import * as React from "react"
import {
  type Contact,
  type Employee,
  useCancelInvitationMutation,
  useResendManagedOperatorInvitationMutation,
} from "@digits-graphql/frontend/graphql-bearer"
import { LoadingBlock } from "@digits-shared/components/Loaders"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgCheck } from "@digits-shared/components/SVGIcons/line/Check.svg"
import { SvgPlus } from "@digits-shared/components/SVGIcons/line/Plus.svg"
import { ColorIcon } from "@digits-shared/components/UI/Elements/ColorIcon"
import { DigitsButton } from "@digits-shared/DesignSystem/Button"
import { EllipsisMenuTrigger, Menu, MenuContent, MenuItem } from "@digits-shared/DesignSystem/Menu"
import { AlertModal } from "@digits-shared/DesignSystem/Modals/AlertModal"
import useSession from "@digits-shared/hooks/useSession"
import useStateBoolean from "@digits-shared/hooks/useStateBoolean"
import { JWTPermissionFlag } from "@digits-shared/session/jwt/jwtPermissions"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import { LogoRow } from "src/frontend/components/OS/Applications/ClientPortal/InviteFlow/Content/LogoRow"
import {
  MODAL_RADIUS,
  useDefaultInviteMessage,
} from "src/frontend/components/OS/Applications/ClientPortal/InviteFlow/shared"
import { type InviteClientAction } from "src/frontend/components/OS/Applications/ClientPortal/InviteFlow/State/actions"
import { useHasPermission } from "src/frontend/components/Shared/Permissions/Requires"
import { InviteClientsModalState } from "src/frontend/components/Shared/Portals/State/types"
import type FrontendSession from "src/frontend/session"
import {
  FrontendPermissionModule,
  FrontendPermissionSource,
} from "src/frontend/session/permissionModule"

const CLIENT_ICON_SIZE = 28

interface ClientsWithAccessProps {
  className?: string
  modalState: InviteClientsModalState
  activatedClients: (Employee | Contact)[] | undefined
  invitedClients: Employee[] | undefined
  loading: boolean
}

interface CallbackProps {
  onClose: () => void
  dispatch: React.Dispatch<InviteClientAction>
}

const AddUserIcon = styled(SvgPlus)`
  width: 16px;
  height: 16px;
  ${svgPathStyles(colors.white, 2)};
`

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
`

export const CloseButton = styled.div`
  position: absolute;
  right: 24px;
  top: 24px;

  width: 30px;
  height: 30px;

  cursor: pointer;
  line-height: 26px;

  &::before {
    content: "✕";
    color: ${colors.translucentWhite60};
    font-size: 18px;
    margin-left: 10px;
    transition: color 200ms;
  }

  &:hover {
    &::before {
      color: ${colors.translucentWhite80};
      font-weight: ${fonts.weight.heavy};
    }
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 32px 40px;
  background-color: white;
  border-radius: ${MODAL_RADIUS}px ${MODAL_RADIUS}px 0 0;
`

const LeftHeaderColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1; /* long titles don't squish the plus button */
`

const HeaderTitle = styled.div`
  font-size: 18px;
  font-weight: ${fonts.weight.heavy};
  color: ${colors.secondary};
`

const ClientList = styled.ul`
  flex: 1;
  overflow-y: scroll;
  padding: 0 42px;
`

const ClientInfo = styled.li`
  padding: 28px 0 28px 0;
  display: flex;
  gap: 12px;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 0.5px solid ${colors.secondary40};
  }
`

const NameEmailWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const InvitedWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-left: auto;
`

const Name = styled.div`
  font-size: 14px;
  font-weight: ${fonts.weight.medium};
  color: ${colors.secondary};
`

const Email = styled.div`
  font-size: 12px;
  color: ${colors.secondary60};
`

const Status = styled.div`
  font-size: 11.5px;
  font-weight: ${fonts.weight.heavy};
  font-style: italic;
  opacity: 0.8;
  color: ${colors.secondary};
`

const CheckmarkIcon = styled(SvgCheck)`
  ${svgPathStyles(colors.neonGreen, 2)};
  position: relative;
  left: 6px;
  height: 16px;
  width: 16px;
  pointer-events: none;
  opacity: 1;
`

const ResentWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`

export const MyClients: React.FC<ClientsWithAccessProps & CallbackProps> = ({
  className,
  modalState,
  activatedClients,
  invitedClients,
  loading,
  onClose,
  dispatch,
}) => {
  const { currentLegalEntity } = useSession<FrontendSession>()

  const onInviteClick = React.useCallback(() => {
    dispatch({ type: "setModalState", modalState: InviteClientsModalState.InviteClients })
  }, [dispatch])

  return (
    <Wrapper className={className}>
      <Header>
        <LeftHeaderColumn>
          <LogoRow size={36} />
          <HeaderTitle>{currentLegalEntity.name} Users</HeaderTitle>
        </LeftHeaderColumn>
        <DigitsButton onClick={onInviteClick} $circle size="small">
          <AddUserIcon />
        </DigitsButton>
      </Header>
      <Clients
        modalState={modalState}
        activatedClients={activatedClients}
        invitedClients={invitedClients}
        loading={loading}
      />
    </Wrapper>
  )
}

const Clients: React.FC<ClientsWithAccessProps> = ({
  activatedClients,
  invitedClients,
  loading,
}) => {
  if (loading) return <LoadingList />

  return (
    <ClientList>
      {invitedClients?.map((client) => <InvitedClient key={client.emailAddress} client={client} />)}
      {activatedClients?.map((client) => (
        <ActivatedClient key={client.emailAddress} client={client} />
      ))}
    </ClientList>
  )
}

const ActivatedClient: React.FC<{ client: Employee | Contact }> = ({ client }) => (
  <ClientInfo key={client.emailAddress}>
    <ColorIcon fallbackUser={client} size={CLIENT_ICON_SIZE} />
    <NameEmailWrapper>
      <Name>{`${client.givenName} ${client.familyName}`}</Name>
      <Email>{client.emailAddress}</Email>
    </NameEmailWrapper>
  </ClientInfo>
)

const InvitedClient: React.FC<{ client: Employee }> = ({ client }) => {
  const { currentLegalEntityId: legalEntityId } = useSession<FrontendSession>()
  const { value: isResent, setTrue: resent } = useStateBoolean(false)
  const { value: showConfirm, toggle: toggleConfirm } = useStateBoolean(false)

  const [resendManagedOperatorInvitation] = useResendManagedOperatorInvitationMutation()
  const [cancelInvitation] = useCancelInvitationMutation()

  const hasResendPermission = useHasPermission({
    source: FrontendPermissionSource.LegalEntity,
    module: FrontendPermissionModule.Invitations,
    flag: JWTPermissionFlag.Create,
  })

  const hasRevokePermission = useHasPermission({
    source: FrontendPermissionSource.LegalEntity,
    module: FrontendPermissionModule.Invitations,
    flag: JWTPermissionFlag.Delete,
  })

  const hasModifyPermission = hasResendPermission || hasRevokePermission

  const defaultMessage = useDefaultInviteMessage()
  const onResendClick = React.useCallback(
    () =>
      resendManagedOperatorInvitation({
        variables: {
          legalEntityId,
          token: client.invitation?.id ?? "",
          emailAddress: client.emailAddress ?? "",
          message: defaultMessage,
        },
      }).then(resent),
    [
      client.emailAddress,
      client.invitation?.id,
      defaultMessage,
      legalEntityId,
      resendManagedOperatorInvitation,
      resent,
    ]
  )

  const onRevokeClick = React.useCallback(() => {
    toggleConfirm()
  }, [toggleConfirm])

  const onRevokeConfirm = React.useCallback(() => {
    cancelInvitation({
      variables: {
        token: client.invitation?.id ?? "",
      },
      refetchQueries: ["ListEmployees"],
    }).finally(toggleConfirm)
  }, [cancelInvitation, client.invitation?.id, toggleConfirm])

  if (!hasModifyPermission || !client.invitation || !client.emailAddress) return null

  return (
    <ClientInfo key={client.emailAddress}>
      <ColorIcon fallbackUser={client} size={CLIENT_ICON_SIZE} />
      <NameEmailWrapper>
        <Name>{`${client.givenName} ${client.familyName}`}</Name>
        <Email>{client.emailAddress}</Email>
      </NameEmailWrapper>

      {showConfirm && (
        <AlertModal
          headerLabel="Revoke Invitation"
          primaryButtonLabel="Revoke"
          primaryButtonClick={onRevokeConfirm}
          secondaryButtonLabel="Cancel"
          secondaryButtonClick={toggleConfirm}
        >
          Are you sure you want to revoke this invitation?
        </AlertModal>
      )}

      {isResent ? (
        <ResentWrapper>
          <Status>Invitation Resent</Status>
          <CheckmarkIcon />
        </ResentWrapper>
      ) : (
        <InvitedWrapper>
          <Status>Invite Sent...</Status>
          {(hasResendPermission || hasRevokePermission) && (
            <Menu>
              <EllipsisMenuTrigger />
              <MenuContent>
                {hasResendPermission && (
                  <MenuItem label="Resend Invitation" onSelect={onResendClick} />
                )}
                {hasRevokePermission && (
                  <MenuItem label="Revoke Invitation" onSelect={onRevokeClick} />
                )}
              </MenuContent>
            </Menu>
          )}
        </InvitedWrapper>
      )}
    </ClientInfo>
  )
}

const LoadingList: React.FC = () => (
  <ClientList>
    {Array.from({ length: 3 }).map((_, i) => (
      <ClientInfo key={i}>
        <LoadingBlock width="35px" height="35px" />
        <NameEmailWrapper>
          <Name>
            <LoadingBlock width="150px" height="17px" />
          </Name>
          <Email>
            <LoadingBlock width="180px" height="14px" />
          </Email>
        </NameEmailWrapper>
      </ClientInfo>
    ))}
  </ClientList>
)
