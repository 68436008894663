import * as React from "react"
import {
  type Layout,
  type LayoutComponent,
  LayoutComponentType,
} from "@digits-graphql/frontend/graphql-bearer"
import { themedStyles } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import styled, { css } from "styled-components"
import { type ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { DeleteComponentStep, matchComponent } from "src/frontend/components/Shared/Layout/types"
import {
  COMPONENT_TRANSITION_DURATION,
  DELETE_COMPONENT_SHRINK_KEYFRAMES,
} from "src/frontend/components/Shared/Portals/Components/DeleteAnimation"
import { ENTITY_INSIGHT_STYLES } from "src/shared/components/Insights/Item"

export const GUTTER_ID_PREFIX = "gutter"
export const ROW_GUTTER_ID_PREFIX = `${GUTTER_ID_PREFIX}_row`
export const COMPONENT_GUTTER_ID_PREFIX = `${GUTTER_ID_PREFIX}_component`

export const COMPONENT_MIN_HEIGHT = 300
export const COMPONENT_RADIUS = 16
export const COMPONENT_PADDING = 20
export const CHART_COMPONENT_HEIGHT = 200

export const COMPONENT_BLUR = 8

export interface ComponentStateProps {
  isDraggable?: boolean
  isDragging?: boolean
  isOverlay?: boolean
}
/*
  STYLES
*/

export const COMPONENT_STATE_STYLES = css<ComponentStateProps>`
  ${({ isDragging }) =>
    // Hide component contents while a drag is active, just showing an outline
    isDragging &&
    css`
      border: 1px dashed ${colors.primary};
      background: unset;

      & * {
        opacity: 0;
      }
    `}
`

export const ComponentStyleContainer = styled.div<ComponentStateProps>`
  position: relative;
  padding: 10px;
  color: ${colors.secondary};

  &:hover {
    box-shadow: 0 0 20px 0 ${colors.secondary20};
  }

  ${({ isDraggable }) =>
    isDraggable &&
    themedStyles({
      light: css`
        &:hover {
          border: 0.5px solid #71f5bb;
          box-shadow: 0 0 0px 2px ${colors.neonGreen};
        }
      `,
      dark: css`
        &:hover {
          border: 0.5px solid #71f5bb;
          box-shadow: 0 0 0px 2px ${colors.translucentNeonGreen30};
        }
      `,
    })}
`

export const ConfigComponentContainer = styled(ComponentStyleContainer)`
  border-radius: ${COMPONENT_RADIUS}px;
  padding: ${COMPONENT_PADDING}px;
  border: 1px solid ${colors.transparent};
  background: ${colors.white90};

  ${COMPONENT_STATE_STYLES};

  ${({ isOverlay }) =>
    isOverlay &&
    css`
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.54) 12.73%,
        rgba(255, 255, 255, 0.33) 90.37%
      );
      backdrop-filter: blur(${COMPONENT_BLUR}px);
    `}
`

export const ConfigComponentPlaceholder = styled(ConfigComponentContainer)`
  width: 315px;
  height: 220px;
  margin: 10px 0;
`

export const ComponentConfigWrapper = styled.div<{
  isOverlay?: boolean
  isDisabled?: boolean
}>`
  padding: 10px 0;

  ${({ isDisabled, isOverlay }) => css`
    cursor: ${isDisabled ? "auto" : "grab"};

    ${isOverlay &&
    css`
      transform: rotate(2deg);

      ${ConfigComponentContainer} {
        box-shadow: 0 0 20px ${colors.translucentBlack40};
      }
    `}
  `}
`

export const ComponentContainer = styled(ConfigComponentContainer)`
  min-height: ${COMPONENT_MIN_HEIGHT}px;

  ${themedStyles({
    light: undefined,
    dark: css`
      height: 290px;
      backdrop-filter: blur(40px);
      border-radius: 30px;
      box-shadow: 0px 0px 20px ${colors.translucentBlack10};
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.74) 12.73%,
        rgba(255, 255, 255, 0.43) 90.37%
      );
    `,
  })}

  ${({ isOverlay }) =>
    isOverlay &&
    css`
      backdrop-filter: blur(${COMPONENT_BLUR}px);
    `}

  ${COMPONENT_STATE_STYLES};
`

export const StatementComponentContainer = styled(ConfigComponentContainer)<{
  componentSize: ComponentSize
}>`
  min-height: ${COMPONENT_MIN_HEIGHT}px;
`

export const TextComponentContainer = styled(ComponentStyleContainer)<{
  editing: boolean
  empty: boolean
}>`
  font-size: 10px;
  border-radius: ${COMPONENT_RADIUS}px;
  border: 0.5px solid ${colors.transparent};

  ${ENTITY_INSIGHT_STYLES};

  ${({ editing, empty }) =>
    editing
      ? css`
          ${empty &&
          css`
            background: linear-gradient(
              180deg,
              rgba(255, 255, 255, 0.54) 12.73%,
              rgba(255, 255, 255, 0.33) 90.37%
            );
          `}
          &:focus-within {
            background: ${colors.translucentSecondary05};
            border: 0.5px solid #71f5bb;
          }
        `
      : css`
          padding-top: 0;
          padding-bottom: 0;
        `};

  ${COMPONENT_STATE_STYLES};

  ${({ isOverlay }) =>
    isOverlay &&
    css`
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.54) 12.73%,
        rgba(255, 255, 255, 0.33) 90.37%
      );
      backdrop-filter: blur(${COMPONENT_BLUR}px);
    `}
`

/*
  FUNCTIONS
*/

/**
 * Finds the index of the row containing the specified component ID within the Layout
 */
export function findPositionIn(
  layout: Layout,
  componentId: string | undefined
): { sectionId: string | undefined; rowId: string | undefined; rowIndex: number } {
  let info = {
    rowIndex: -1,
    sectionId: undefined as string | undefined,
    rowId: undefined as string | undefined,
  }

  if (!componentId) return info

  const { sections } = layout

  sections?.forEach((s) => {
    s.rows?.forEach((r, i) => {
      if (r.components?.some((c) => c.componentId === componentId)) {
        info = {
          rowIndex: i,
          sectionId: s.sectionId,
          rowId: r.rowId,
        }
      }
    })
  })

  return info
}

export function findComponentsIn(
  layout: Layout,
  sectionId: string,
  rowId: string
): LayoutComponent[] {
  const { sections } = layout
  if (!sections) return []

  const section = sections.find((s) => s.sectionId === sectionId)
  if (!section) return []

  const { rows } = section
  if (!rows) return []

  const row = rows.find((s) => s.rowId === rowId)
  if (!row) return []

  return row.components ?? []
}

export function componentContentWidth(width: number) {
  return width - COMPONENT_PADDING * 2
}

export function useLazyComponentSize(component: LayoutComponent, width: number) {
  return React.useMemo(() => {
    let height = COMPONENT_MIN_HEIGHT
    if (matchComponent(component, "statement", LayoutComponentType.Statement)) {
      height = 750
    }
    return css`
      min-height: ${height}px;
      width: ${width}px;
      border-radius: ${COMPONENT_RADIUS}px;
    `
  }, [component, width])
}

export const ComponentWrapper = styled.div<{
  isDragging?: boolean
  isOverlay?: boolean
  isDisabled?: boolean
  width: number
  deleteAnimationStep?: DeleteComponentStep
  hovered?: boolean
}>`
  position: relative;
  ${({ isDisabled, isOverlay }) => css`
    cursor: ${isDisabled ? "auto" : "grab"};

    ${isOverlay &&
    css`
      transform: rotate(2deg);

      ${ConfigComponentContainer} {
        box-shadow: 0 0 20px ${colors.translucentBlack40};
      }
    `}
  `}

  padding: 0;
  width: ${({ width }) => width}px;

  ${({ isOverlay }) => isOverlay && "pointer-events: none;"}

  ${({ isDragging, deleteAnimationStep }) =>
    !isDragging &&
    deleteAnimationStep === DeleteComponentStep.Shrink &&
    css`
      animation: ${DELETE_COMPONENT_SHRINK_KEYFRAMES} ${COMPONENT_TRANSITION_DURATION}ms both;
    `}

  ${({ hovered }) =>
    hovered
      ? css`
          &::before {
            position: absolute;
            top: -35px;
            right: 0;
            height: 35px;
            width: 200px;
            content: "";
          }
        `
      : css``};
`
