import * as React from "react"
import { type ActionItemKind } from "@digits-graphql/frontend/graphql-bearer"
import { svgIconStyles, svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgDigitsLogoNoBackground } from "@digits-shared/components/SVGIcons/digits/DigitsLogoNoBackground.svg"
import { SvgCreditCardAngle } from "@digits-shared/components/SVGIcons/line/CreditCardAngle.svg"
import { SvgMessageCheckSquare } from "@digits-shared/components/SVGIcons/line/MessageCheckSquare.svg"
import { ColorIcon } from "@digits-shared/components/UI/Elements/ColorIcon"
import { IconSVGContainer } from "@digits-shared/components/UI/Icons/Icon"
import colors from "@digits-shared/themes/colors"
import styled, { css } from "styled-components"
import { SVGIconComponent } from "src/shared/components/Icons/SVGIcon"
import { type PartyAttributesForIcon, PartyIcon } from "src/shared/components/PartyHover/PartyIcon"
import { CategoryIconContainer } from "src/shared/components/Transactions/TransactionRow/TransactionCategoryIcon"

/* 
  STYLES
*/

const IconContainer = styled.div<{ color?: string }>`
  width: 32px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ color }) => color || colors.secondary10};
`

const StyledPartyIcon = styled(PartyIcon)`
  border: none;
  border-radius: 4px;
  img {
    border-radius: 4px;
  }
`

const DigitsIcon = styled(SvgDigitsLogoNoBackground)`
  height: 20px;
  width: 20px;
  ${svgIconStyles(colors.white)};
`

const iconStyles = css`
  width: 16px;
  height: 16px;
  ${svgPathStyles(colors.secondary, 1.5)};
`

const WorkItemIcon = styled(SvgMessageCheckSquare)`
  ${iconStyles};
`

const BillIcon = styled(SvgCreditCardAngle)`
  ${iconStyles};
`

/* 
  COMPONENTS
*/

export const IconByPrecedence: React.FC<{
  party?: PartyAttributesForIcon | null
  iconUrl?: string | null
  iconColor?: string
  categoryName?: string | null
  actionItemKind: ActionItemKind
}> = ({ party, iconUrl, iconColor, categoryName, actionItemKind }) => {
  if (categoryName)
    return (
      <CategoryIconContainer size={30}>
        <IconSVGContainer>
          <SVGIconComponent subjectDisplayKey={categoryName} />
        </IconSVGContainer>
      </CategoryIconContainer>
    )
  if (party) return <StyledPartyIcon party={party} />
  if (iconUrl) return <ColorIcon imageUrl={iconUrl} />
  return (
    <IconContainer color={iconColor}>
      <ActionItemIcon actionItemKind={actionItemKind} />
    </IconContainer>
  )
}

const ActionItemIcon: React.FC<{ actionItemKind: ActionItemKind }> = ({ actionItemKind }) => {
  switch (actionItemKind) {
    case "Onboarding":
      return <DigitsIcon />
    case "WorkItem":
    case "TransactionReview":
      return <WorkItemIcon />
    case "Bill":
      return <BillIcon />
    default:
      return null
  }
}
