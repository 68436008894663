import * as React from "react"
import { hasExactlyOne } from "@digits-shared/helpers/arrayHelper"
import moneyFlowHelper from "@digits-shared/helpers/moneyFlowHelper"
import objectHelper from "@digits-shared/helpers/objectHelper"
import { type Timeseries } from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"

export function useCombinedDepartmentsSeries(timeseries: Timeseries[]) {
  return React.useMemo(() => {
    const first = timeseries?.[0]?.values
    if (!first || hasExactlyOne(timeseries)) return first

    let series = objectHelper.cloneDeep(first)
    for (let breakdownIdx = 1; breakdownIdx < timeseries.length; breakdownIdx++) {
      const breakdown = timeseries[breakdownIdx]?.values
      series = series.map((total, idx) => {
        total.moneyFlow = moneyFlowHelper.add(total.moneyFlow, breakdown?.[idx]?.moneyFlow)
        return total
      })
    }
    return series
  }, [timeseries])
}
