import * as React from "react"
import { themedStyles, useThemeMode } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import styled, { css } from "styled-components"

const WIDTH = 45
const HEIGHT = 24
const SLIDER_DIAMETER = 16

/*
 STYLES
*/

interface SwitchDimensions {
  width: number
  height: number
  diameter: number
}

export const SwitchStyled = styled.label<SwitchDimensions & { disabled?: boolean }>`
  position: relative;
  display: inline-block;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.25;
    `};
`

export const HiddenInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`

export const Slider = styled.span<SwitchDimensions>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.4s;
  border-radius: ${({ width }) => width / 2}px;
  background-color: ${colors.transparent};

  ${themedStyles({
    light: css`
      border: 1px solid ${colors.translucentPrimary70};

      &.light::before {
        background-color: ${colors.translucentPrimary70};
      }

      ${HiddenInput} + &.light {
        opacity: 0.8;
      }

      ${HiddenInput} + &.light:hover {
        opacity: 1;
      }

      ${HiddenInput}:checked + &.light {
        opacity: 1;
        background: ${colors.primary};
        border-color: transparent;

        &.light::before {
          background-color: ${colors.white};
        }
      }
    `,
    dark: css`
      border: 1px solid ${colors.translucentWhite50};

      &.dark::before {
        background-color: ${colors.translucentWhite50};
      }

      ${HiddenInput} + &.dark {
        opacity: 0.4;
      }

      ${HiddenInput} + &.dark:hover {
        opacity: 0.7;
      }

      ${HiddenInput}:checked + &.dark {
        opacity: 1;
        background-color: #112746;
        border: 1px solid ${colors.neonGreen};

        &.dark::before {
          background-color: ${colors.neonGreen};
        }
      }
    `,
  })}
  &:before {
    position: absolute;
    content: "";
    height: ${({ diameter }) => diameter}px;
    width: ${({ diameter }) => diameter}px;
    top: 3px;
    left: 3px;
    bottom: 4px;
    border-radius: 50%;
    transition: 0.4s;
  }

  ${HiddenInput}:focus + & {
    outline: none;
  }

  ${HiddenInput}:checked + &:before {
    transform: translateX(${({ width, diameter }) => width - diameter - diameter / 2}px);
  }
`

/*
 INTERFACE
*/

interface Props {
  on?: boolean
  onChange?: (on: boolean) => void
  className?: string
  disabled?: boolean
  size?: SwitchDimensions
}

/*
 COMPONENT
*/

export const Switch: React.FC<Props> = ({
  on,
  onChange,
  className,
  disabled,
  size = { width: WIDTH, height: HEIGHT, diameter: SLIDER_DIAMETER },
}) => {
  const mode = useThemeMode()
  const { width, height, diameter } = size
  const onClick = React.useCallback((e: React.MouseEvent) => {
    e.stopPropagation()
  }, [])

  const onValueChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(e.target.checked)
    },
    [onChange]
  )

  return (
    <SwitchStyled
      width={width}
      height={height}
      diameter={diameter}
      onClick={onClick}
      className={className}
      disabled={disabled}
    >
      <HiddenInput type="checkbox" checked={on} onChange={onValueChange} />
      <Slider className={mode} width={width} height={height} diameter={diameter} />
    </SwitchStyled>
  )
}
