import * as React from "react"
import { type EntityLegalEntity } from "@digits-graphql/frontend/graphql-bearer"
import { PointingDirection } from "@digits-shared/components/UI/Elements/Chevron"
import { type PopUpCoordinates } from "@digits-shared/components/UI/Elements/PopUp/PopUp"
import { type PickerPosition } from "@digits-shared/components/UI/Picker/constants"
import {
  Picker,
  type PickerMouseInteraction,
  PickerRowContainer,
  type PickerRowContentProps,
} from "@digits-shared/components/UI/Picker/Picker"
import {
  DEFAULT_PICKER_CHEVRON_HEIGHT,
  PICKER_MAX_HEIGHT,
  PickerPopUp,
} from "@digits-shared/components/UI/Picker/PickerPopUp"
import { defined } from "@digits-shared/helpers/filters"
import useStateBoolean from "@digits-shared/hooks/useStateBoolean"
import colors from "@digits-shared/themes/colors"
import styled, { css } from "styled-components"

/*
  STYLES
*/
const LegalEntityName = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${colors.theme.dark.text};
  font-size: 13px;
  transition: color 250ms ease;
`

const LegalEntityPickerRowContainer = styled(PickerRowContainer)<PickerMouseInteraction>`
  ${({ isHovered }) =>
    isHovered &&
    css`
      ${LegalEntityName} {
        color: ${colors.theme.dark.invertedText};
      }
    `}
`

/*
  INTERFACES
*/
interface LegalEntityPickerPopUpProps {
  className?: string
  width: number
  maxHeight?: number
  chevronDirection?: PointingDirection
  pickerPosition: PickerPosition
  coordinates?: PopUpCoordinates
  legalEntities: EntityLegalEntity[]
  useUnassignedIcon?: boolean
  isLoading?: boolean
  onLegalEntityPick: (legalEntity: EntityLegalEntity) => void
  onClose?: () => void
  startCloseAnimation?: boolean
  onCloseAnimationEnd?: () => void
}

/*
  COMPONENTS
*/
export const LegalEntityPickerPopUp: React.FC<LegalEntityPickerPopUpProps> = ({
  className,
  width,
  maxHeight = PICKER_MAX_HEIGHT,
  chevronDirection = PointingDirection.None,
  pickerPosition,
  coordinates,
  legalEntities,
  useUnassignedIcon,
  isLoading,
  onLegalEntityPick,
  onClose,
  startCloseAnimation,
  onCloseAnimationEnd,
}) => {
  const { value: isDisabled, setTrue: disable } = useStateBoolean(false)
  const disableAndPick = React.useCallback(
    (legalEntity: EntityLegalEntity) => {
      disable()
      onLegalEntityPick(legalEntity)
    },
    [disable, onLegalEntityPick]
  )

  return (
    <PickerPopUp
      className={className}
      maxHeight={maxHeight}
      width={width}
      chevron={{ size: DEFAULT_PICKER_CHEVRON_HEIGHT, direction: chevronDirection }}
      pickerPosition={pickerPosition}
      onClose={onClose}
      startCloseAnimation={startCloseAnimation}
      coordinates={coordinates}
      isDisabled={isDisabled}
      onCloseAnimationEnd={onCloseAnimationEnd}
    >
      <Picker
        elements={legalEntities}
        emptyElement={legalEntities[0]}
        pickerPosition={pickerPosition}
        onPickElement={disableAndPick}
        RowContent={LegalEntityRowContent}
        rowContentProps={{ useUnassignedIcon }}
        isLoading={isLoading}
        elementSearchText={searchTermFromLegalEntity}
        searchPlaceholder="Search Businesses"
      />
    </PickerPopUp>
  )
}

const LegalEntityRowContent: React.FC<PickerRowContentProps<EntityLegalEntity>> = ({
  element,
  isHovered,
}) => (
  <LegalEntityPickerRowContainer isHovered={isHovered}>
    <LegalEntityName>{element.name}</LegalEntityName>
  </LegalEntityPickerRowContainer>
)

const searchTermFromLegalEntity = (legalEntity: EntityLegalEntity) =>
  [legalEntity.name].filter(defined).join(" ")
