import * as React from "react"
import { type ApolloError } from "@apollo/client"
import {
  type ReadShareAdjacencyQuery,
  type ReadShareQuery,
  ShareAction,
} from "@digits-graphql/frontend/graphql-bearer"

export enum ObjectSharingStatus {
  // Object sharing status has not yet been determined. We're probably still loading.
  Unknown = "Unknown",

  // Object is not shared, or this user has no access
  NoAccess = "NoAccess",

  // An intent was created, but it was revoked before it could be used
  IntentRevoked = "IntentRevoked",

  // This shared object has a public link that requires a request for access
  AccessRequestRequired = "AccessRequestRequired",

  // User has a pending access request
  AccessRequestPending = "AccessRequestPending",

  // Access is possible, create a grant for the user
  GrantRequired = "GrantRequired",

  // Proceed through to viewing the shared object
  AccessGranted = "AccessGranted",

  // User does not have a grant directly to the object but does have access via an adjacent object
  // (e.g. Grant to category and is now trying to view a party associated with a transaction for that category)
  AccessGrantedViaAdjacency = "AccessGrantedViaAdjacency",
}

export function useObjectSharingStatus({
  previewError,
  permissionData,
  permissionError,
  adjacencyPermissionData,
  adjacencyPermissionError,
}: {
  previewError?: ApolloError
  permissionData?: ReadShareQuery["readShare"]
  permissionError?: ApolloError
  adjacencyPermissionData?: ReadShareAdjacencyQuery["readShareAdjacency"]
  adjacencyPermissionError?: ApolloError
}) {
  return React.useMemo<ObjectSharingStatus>(() => {
    if (previewError && previewError.message === "you no longer have access to this object") {
      return ObjectSharingStatus.IntentRevoked
    }

    if (previewError && permissionError && adjacencyPermissionError) {
      return ObjectSharingStatus.NoAccess
    }

    if (permissionData) {
      return toObjectSharingStatus(permissionData.action)
    }

    if (adjacencyPermissionData) {
      return ObjectSharingStatus.AccessGrantedViaAdjacency
    }

    return ObjectSharingStatus.Unknown
  }, [
    adjacencyPermissionData,
    adjacencyPermissionError,
    permissionData,
    permissionError,
    previewError,
  ])
}

function toObjectSharingStatus(action: ShareAction): ObjectSharingStatus {
  switch (action) {
    case ShareAction.IntentRevoked:
      return ObjectSharingStatus.IntentRevoked
    case ShareAction.RequestAccess:
      return ObjectSharingStatus.AccessRequestRequired
    case ShareAction.RequestPending:
      return ObjectSharingStatus.AccessRequestPending
    case ShareAction.CreateGrant:
      return ObjectSharingStatus.GrantRequired
    case ShareAction.Proceed:
      return ObjectSharingStatus.AccessGranted
    default:
      return ObjectSharingStatus.Unknown
  }
}
