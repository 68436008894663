import * as React from "react"
import {
  Interval,
  type Period,
  type StatementDeltas,
  type StatementDeltaValue,
} from "@digits-graphql/frontend/graphql-bearer"
import colorHelper from "@digits-shared/helpers/colorHelper"
import dateTimeHelper, { DateFormat } from "@digits-shared/helpers/dateTimeHelper"
import moneyFlowHelper from "@digits-shared/helpers/moneyFlowHelper"
import numberHelper, { CurrencyStyle } from "@digits-shared/helpers/numberHelper"
import { themedValue } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import { useThemedConstant } from "@digits-shared/themes/themedFunctions"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import dayjs from "@digits-shared/initializers/dayjs/dayjs"
import { type TimeseriesValue } from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"

/*
  STYLES
*/

const ChartPeriodStyled = styled.div`
  position: absolute;
  top: 14px;
  left: 10px;
  right: 15px;
  display: flex;
  font-size: 18px;
`

const dateColor = themedValue({
  light: colors.translucentSecondary80,
  dark: colors.translucentWhite80,
})

const Date = styled.span`
  color: ${dateColor};
`

const Amounts = styled.div`
  text-align: right;
  flex: 1;
`

const amountColor = themedValue({
  light: colors.secondary,
  dark: colors.white,
})

const Amount = styled.div`
  color: ${amountColor};
  font-weight: ${fonts.weight.heavy};
`

const Delta = styled.div<{ color: string }>`
  color: ${({ color }) => color};
  font-size: 12px;
`

const DeltaAmount = styled.span`
  font-weight: ${fonts.weight.medium};
  margin-bottom: 2px;
`

const DeltaPercent = styled.span``

const DeltaDate = styled.span`
  color: ${dateColor};
  display: inline-block;
  text-align: left;
  white-space: nowrap;
  overflow: visible;
`

/*
  INTERFACES
*/

interface ChartPeriodPros {
  current: TimeseriesValue
  deltas?: StatementDeltas
  previous?: TimeseriesValue
}

interface PeriodDelta {
  amount: string | string[]
  percent: string
  color: string
}

interface PeriodDeltaProps {
  period: Period
  previousPeriod?: StatementDeltaValue | null
  previousYear?: StatementDeltaValue | null
}

/*
  COMPONENTS
*/

export const ChartPeriod: React.FC<ChartPeriodPros> = ({ current, previous, deltas }) => {
  const { period: currentPeriod, moneyFlow: currentMoneyFlow } = current
  const period = previous?.period || currentPeriod
  const value = previous?.moneyFlow || currentMoneyFlow

  const previousPeriod = previous?.period
    ? previous?.deltaPrevious
    : current.deltaPrevious
      ? current.deltaPrevious
      : deltas?.previousPeriod
  const previousYear = previous?.period
    ? previous?.deltaYearAgo
    : current.deltaYearAgo
      ? current.deltaYearAgo
      : deltas?.previousYear

  const date = dateTimeHelper.isPeriodYearToDate(period)
    ? `${dateTimeHelper.displayNameFromUnixTimestamp(period.startedAt, Interval.Year)} YTD`
    : dateTimeHelper.displayNameFromRange(period)

  return (
    <ChartPeriodStyled>
      <Date>{date}</Date>
      <Amounts>
        <Amount>{moneyFlowHelper.currency(value, { style: CurrencyStyle.Detail })}</Amount>
        <PeriodDeltas period={period} previousPeriod={previousPeriod} previousYear={previousYear} />
      </Amounts>
    </ChartPeriodStyled>
  )
}

const PeriodDeltas: React.FC<PeriodDeltaProps> = ({ period, previousPeriod, previousYear }) => {
  const green = useThemedConstant({
    dark: colors.translucentNeonGreen80,
    light: colors.translucentPrimary80,
  })
  const orange = colorHelper.hexToRgba(colors.orange, 0.8)

  const prevPeriod = dayjs
    .unix(period.startedAt)
    .utc()
    .subtract(1, dateTimeHelper.unitOfTimeForInterval(period.interval))

  const prevYear = dayjs
    .unix(period.startedAt)
    .utc()
    .subtract(1, dateTimeHelper.unitOfTimeForInterval(Interval.Year))

  const createPeriodDelta = React.useCallback(
    ({ percentageOfAmount, moneyFlow }: StatementDeltaValue) => {
      const percent = numberHelper
        .numberFormatter({
          signDisplay: "always",
          style: "percent",
        })
        .format(percentageOfAmount / 100)

      const amount = moneyFlowHelper.currency(moneyFlow, {
        signDisplay: "always",
        style: CurrencyStyle.Detail,
      })

      const isPeriodPositive = moneyFlow.value.amount >= 0
      const color = isPeriodPositive ? green : orange
      return {
        amount,
        percent,
        color,
      }
    },
    [green, orange]
  )

  const prevDelta: PeriodDelta | undefined = React.useMemo(() => {
    if (!previousPeriod) return undefined
    return createPeriodDelta(previousPeriod)
  }, [createPeriodDelta, previousPeriod])

  const yearDelta: PeriodDelta | undefined = React.useMemo(() => {
    if (!previousYear) return undefined
    return createPeriodDelta(previousYear)
  }, [createPeriodDelta, previousYear])

  let { interval } = period
  const isYTD = dateTimeHelper.isPeriodYearToDate(period)
  if (isYTD) {
    interval = Interval.Year
  }
  const showPrevDelta = prevDelta && (interval !== Interval.Year || isYTD)
  return (
    <>
      {showPrevDelta && (
        <Delta color={prevDelta.color}>
          <DeltaAmount>{prevDelta.amount}</DeltaAmount>&nbsp;
          <DeltaPercent>({prevDelta.percent})</DeltaPercent>&nbsp;
          <DeltaDate>
            vs {dateTimeHelper.displayNameFromDayjs(prevPeriod, interval, DateFormat.Tiny)}
          </DeltaDate>
        </Delta>
      )}
      {yearDelta && (
        <Delta color={yearDelta.color}>
          <DeltaAmount>{yearDelta.amount}</DeltaAmount>&nbsp;
          <DeltaPercent>({yearDelta.percent})</DeltaPercent>&nbsp;
          <DeltaDate>vs {prevYear.format("YYYY")}</DeltaDate>
        </Delta>
      )}
    </>
  )
}
