import * as React from "react"
import { Theme, useThemeMode } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import Vibrant from "node-vibrant"
import useStateObject from "src/shared/hooks/useStateObject"

const DARK_THEME_BACKGROUND_IMAGE = `radial-gradient(
  circle at 55% 25%, 
  rgba(26, 134, 146, 0.3) 0%, 
  #161d29 100%
)`

export type VibrantBackgroundImageURL = string | undefined | null

export interface VibrantBackgroundValues {
  backgroundColor?: string
  secondaryBackgroundColor?: string
  backgroundImage?: string
  textColor?: string
  isLoading: boolean
}

export type VibrantBackgroundColors = Omit<VibrantBackgroundValues, "isLoading">

export function useVibrantBackgroundDefaults(overrides?: VibrantBackgroundColors) {
  const isDark = useThemeMode() === Theme.Dark
  return React.useMemo(
    () => ({
      backgroundImage: isDark ? DARK_THEME_BACKGROUND_IMAGE : overrides?.backgroundImage,
      backgroundColor: isDark ? "#161d29" : overrides?.backgroundColor,
      secondaryBackgroundColor: overrides?.secondaryBackgroundColor,
      textColor: overrides?.textColor,
    }),
    [
      isDark,
      overrides?.backgroundImage,
      overrides?.backgroundColor,
      overrides?.secondaryBackgroundColor,
      overrides?.textColor,
    ]
  )
}

export function useVibrantBackground(
  imageUrl: VibrantBackgroundImageURL,
  overrides?: Partial<VibrantBackgroundColors>
) {
  const componentUnmounted = React.useRef(false)
  React.useEffect(() => {
    componentUnmounted.current = false
    return () => {
      componentUnmounted.current = true
    }
  }, [])

  const defaults = useVibrantBackgroundDefaults(overrides)

  const [vibrantBackground, setVibrantBackground] = useStateObject<VibrantBackgroundValues>({
    isLoading: false,
    ...defaults,
  })

  React.useEffect(() => {
    if (!imageUrl) {
      setVibrantBackground({
        backgroundColor: defaults.backgroundColor,
        backgroundImage: defaults.backgroundImage,
        isLoading: false,
      })

      return
    }

    setVibrantBackground({
      backgroundColor: defaults.backgroundColor,
      backgroundImage: defaults.backgroundImage,
      isLoading: true,
    })

    Vibrant.from(imageUrl).getPalette((err, palette) => {
      if (componentUnmounted.current) {
        setVibrantBackground({ isLoading: false })
        return
      }
      if (err) {
        setVibrantBackground({ isLoading: false })
        return console.error(err)
      }

      let backgroundImage = defaults?.backgroundImage
      let backgroundColor = defaults?.backgroundColor
      let secondaryBackgroundColor: string | undefined
      let textColor = defaults?.textColor

      const darkVibrant = palette && palette.DarkVibrant ? palette.DarkVibrant.getHex() : ""
      const vibrant = palette && palette.Vibrant ? palette.Vibrant.getHex() : ""
      const lightVibrant =
        palette && palette.LightVibrant ? palette.LightVibrant.getHex() : colors.white

      const darkMuted = palette && palette.DarkMuted ? palette.DarkMuted.getHex() : ""
      const muted = palette && palette.Muted ? palette.Muted.getHex() : ""
      const lightMuted = palette && palette.LightMuted ? palette.LightMuted.getHex() : ""

      if ((vibrant && lightVibrant) || !muted) {
        if (palette && palette.Vibrant) textColor = palette.Vibrant.getTitleTextColor()
        backgroundColor = vibrant || lightVibrant || darkVibrant
        secondaryBackgroundColor = lightVibrant || darkVibrant
        backgroundImage = `
          linear-gradient(-237deg, ${vibrant}, ${colors.transparent} 70%),
          linear-gradient(-127deg, ${vibrant}, ${colors.transparent} 70%),
          linear-gradient(-21deg, ${lightVibrant}, ${colors.transparent} 70%);
        `
      } else if (muted) {
        if (palette && palette.Muted) textColor = palette.Muted.getTitleTextColor()
        backgroundColor = darkMuted
        secondaryBackgroundColor = lightMuted
        backgroundImage = `
            linear-gradient(-21deg, ${muted}, ${colors.transparent} 70%),
            linear-gradient(-127deg, ${lightMuted}, ${colors.transparent} 70%),
            linear-gradient(-237deg, ${darkMuted}, ${colors.transparent} 70%);
        `
      }

      setVibrantBackground({
        backgroundColor,
        secondaryBackgroundColor,
        backgroundImage,
        textColor,
        isLoading: false,
      })
    })
  }, [imageUrl, defaults, setVibrantBackground])

  // do not {...vibrantBackground} because it busts dependency lists
  return vibrantBackground
}
