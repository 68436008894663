import React, { useMemo } from "react"
import {
  type BillingPrice,
  type BillingProduct,
  BillingProductName,
  Interval,
} from "@digits-graphql/frontend/graphql-bearer"
import numberHelper, { CurrencyStyle } from "@digits-shared/helpers/numberHelper"
import useSession from "@digits-shared/hooks/useSession"
import { useBillingOrganizationPlan } from "src/frontend/components/OS/Applications/Settings/hooks/useBillingOrganizationPlan"
import { useBillingPlans } from "src/frontend/components/OS/Applications/Settings/hooks/useBillingPlans"
import type FrontendSession from "src/frontend/session"

interface OrganizationBillingPlanValue {
  planName: BillingProductName
  planDetails: string
  planInterval: Interval | undefined
  planIntervalText: string
  clientCount: number
  planMaxLimit: number
  planExceeded: boolean
  planMaxed: boolean
  loading: boolean
  growthMonthlyCost: string
  premiumMonthlyCost: string
}

interface BillingPlan {
  product: BillingProduct
  price: BillingPrice
}

const defaultValue = {
  planName: BillingProductName.Free,
  planDetails: "",
  planInterval: undefined,
  planIntervalText: "",
  clientCount: 0,
  planMaxLimit: 0,
  planExceeded: false,
  planMaxed: false,
  loading: false,
  growthMonthlyCost: "$99",
  premiumMonthlyCost: "$199",
}

const OrganizationBillingPlan = React.createContext<OrganizationBillingPlanValue>(defaultValue)

export const OrganizationBillingPlanProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const session = useSession<FrontendSession>()
  const { currentOrganization } = session

  const {
    loading: orgPlanLoading,
    planName,
    planMaxLimit,
    planDetails,
    planInterval,
    planIntervalText,
    planExceeded,
    planMaxed,
    clientCount,
  } = useBillingOrganizationPlan(currentOrganization?.id)

  const {
    loading: planCostLoading,
    growthMonthlyCost,
    premiumMonthlyCost,
  } = useBillingPlanCost(currentOrganization?.id)

  const value = useMemo(
    () => ({
      planName,
      planDetails,
      planInterval,
      planIntervalText,
      clientCount,
      planMaxLimit,
      planExceeded,
      planMaxed,
      loading: planCostLoading || orgPlanLoading,
      growthMonthlyCost,
      premiumMonthlyCost,
    }),
    [
      planName,
      planDetails,
      planInterval,
      planIntervalText,
      clientCount,
      planMaxLimit,
      planExceeded,
      planMaxed,
      planCostLoading,
      orgPlanLoading,
      growthMonthlyCost,
      premiumMonthlyCost,
    ]
  )

  return (
    <OrganizationBillingPlan.Provider value={value}>{children}</OrganizationBillingPlan.Provider>
  )
}

const useBillingPlanCost = (organizationId?: string) => {
  const { plans, loading } = useBillingPlans(organizationId)

  if (!plans) {
    return {
      growthMonthlyCost: defaultValue.growthMonthlyCost,
      premiumMonthlyCost: defaultValue.premiumMonthlyCost,
    }
  }

  const growthMonthlyBillingPlan = getMonthlyBillingPlan(plans, BillingProductName.Growth)
  const premiumMonthlyBillingPlan = getMonthlyBillingPlan(plans, BillingProductName.Premium)

  return {
    loading,
    growthMonthlyCost: growthMonthlyBillingPlan
      ? (numberHelper.currency(growthMonthlyBillingPlan.price.amount, {
          style: CurrencyStyle.Detail,
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        }) as string)
      : defaultValue.growthMonthlyCost,
    premiumMonthlyCost: premiumMonthlyBillingPlan
      ? (numberHelper.currency(premiumMonthlyBillingPlan.price.amount, {
          style: CurrencyStyle.Detail,
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        }) as string)
      : defaultValue.premiumMonthlyCost,
  }
}

const getMonthlyBillingPlan = (
  billingPlans: BillingPlan[],
  productName: BillingProductName
): BillingPlan | undefined => {
  const foundBillingPlan = billingPlans.filter(
    (billingPlan) =>
      billingPlan.price.interval === Interval.Month && billingPlan.product.name === productName
  )

  if (foundBillingPlan.length > 0) return foundBillingPlan[0]
  return undefined
}

export function useOrganizationBillingPlan() {
  return React.useContext(OrganizationBillingPlan)
}
