import * as React from "react"
import { SvgArrowRight } from "@digits-shared/components/SVGIcons/line/ArrowRight.svg"
import { SvgXClose } from "@digits-shared/components/SVGIcons/line/XClose.svg"
import { DigitsButton } from "@digits-shared/DesignSystem/Button"
import useInterval from "@digits-shared/hooks/useInterval"
import useRouter from "@digits-shared/hooks/useRouter"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"
import dayjs from "@digits-shared/initializers/dayjs/dayjs"
import { SatelliteSprite } from "src/frontend/components/OS/Applications/Shared/Sprites/SatelliteSprite"
import { type SystemWebNotificationType } from "src/frontend/types/SystemWebNotification"
import { DrawerItemIcon } from "src/shared/components/Drawer/DrawerItemIcon"

/*
 STYLES
*/

const EmptyItem = styled.div`
  font-size: 20px;
  text-align: center;
  color: ${colors.translucentSecondary50};
`

const Item = styled.div<{ hasToLink: boolean; isRead?: boolean; type?: SystemWebNotificationType }>`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 12px 16px;
  border-radius: 8px;
  color: ${colors.white};

  ${({ type }) => {
    switch (type) {
      case "ai":
        return css`
          background: linear-gradient(84.38deg, #4894c6 -4.07%, #6f78ce 95.1%);
        `
      default:
        return css`
          background: ${colors.black};
        `
    }
  }};

  ${({ hasToLink }) =>
    hasToLink &&
    css`
      cursor: pointer;
    `};
`

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  gap: 12px;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  width: 18px;
  height: 18px;
  color: ${colors.white};
`

const TitleContent = styled.div`
  vertical-align: middle;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  max-height: 128px; /* Fallback for non-webkit */
  flex: 1;

  overflow: hidden;
`

export const ActivityTitle = styled(TitleContent)<{ $hasSubtitle?: boolean }>`
  font-size: 14px;
  font-weight: ${({ $hasSubtitle }) => ($hasSubtitle ? fonts.weight.heavy : fonts.weight.roman)};
`

const Subtitle = styled(TitleContent)`
  font-size: 12px;
`

const Time = styled(Subtitle)`
  margin-top: 3px;
  padding-top: 0;
  font-size: 8px;
  text-transform: uppercase;
  color: ${colors.translucentWhite80};
`

/*
 INTERFACE
*/

interface ActivityRowProps {
  icon: JSX.Element | ((props: React.SVGProps<SVGSVGElement>) => JSX.Element)
  iconSize?: string
  title: React.ReactNode
  subtitle?: React.ReactNode
  occurredAt?: number
  isRead?: boolean
  toLink?: string
  onClick?: () => void
  onCloseClick?: () => void
  buttonText?: string
  showCloseButton?: boolean
  className?: string
  type?: SystemWebNotificationType
}

interface ActivityRowActionProps {
  toLink?: string
  onCloseClick?: () => void
  buttonText?: string
  showCloseButton?: boolean
}

const ActivityItem: React.FC<ActivityRowProps> = ({
  toLink,
  title,
  icon,
  iconSize,
  subtitle,
  occurredAt,
  isRead,
  onClick,
  onCloseClick,
  className,
  showCloseButton,
  buttonText,
  type,
}) => {
  const { history } = useRouter()
  const onClickIntercept = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      onCloseClick?.()

      const { target } = e
      // target has a no referrer rel (like a mailto link) let it do its thing
      if (target instanceof HTMLAnchorElement && target.relList?.contains("noreferrer")) {
        return e.stopPropagation()
      }

      // a toLink was provided, navigate to that
      if (toLink) {
        return history.push(toLink)
      }

      // call back
      onClick?.()
    },
    [onClick, history, toLink, onCloseClick]
  )

  return (
    <div onClick={onClickIntercept}>
      <ActivityItemDetails
        className={className}
        onCloseClick={onCloseClick}
        toLink={toLink}
        isRead={isRead}
        title={title}
        icon={icon}
        iconSize={iconSize}
        subtitle={subtitle}
        occurredAt={occurredAt}
        showCloseButton={showCloseButton}
        buttonText={buttonText}
        type={type}
      />
    </div>
  )
}

export default ActivityItem

const ActivityItemDetails: React.FC<ActivityRowProps> = ({
  toLink,
  title,
  icon,
  iconSize,
  subtitle,
  occurredAt,
  isRead,
  onCloseClick,
  className,
  showCloseButton,
  buttonText,
  type,
}) => (
  <Item hasToLink={!!toLink} isRead={isRead} className={className} type={type}>
    <Title>
      <IconContainer>
        {typeof icon === "function" ? <DrawerItemIcon icon={icon} iconSize={iconSize} /> : icon}
      </IconContainer>
      <TitleContent>
        <ActivityTitle $hasSubtitle={!!subtitle}>{title}</ActivityTitle>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        {occurredAt && <ActivityTimestamp occurredAt={occurredAt} />}
      </TitleContent>
    </Title>

    <ActivityItemAction
      toLink={toLink}
      showCloseButton={showCloseButton}
      buttonText={buttonText}
      onCloseClick={onCloseClick}
    />
  </Item>
)

const ActivityTimestamp: React.FC<{ occurredAt: number }> = ({ occurredAt }) => {
  const date = dayjs.unix(occurredAt)

  // update timestamp every minute
  const [timeDelta, setTimeDelta] = React.useState(date.fromNow())
  useInterval(() => {
    setTimeDelta(date.fromNow())
  }, 60 * 1000)

  return <Time>{timeDelta}</Time>
}

const ActivityItemAction: React.FC<ActivityRowActionProps> = ({
  toLink,
  showCloseButton,
  buttonText,
  onCloseClick,
}) => {
  const onItemCloseClick = React.useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()
      onCloseClick?.()
    },
    [onCloseClick]
  )

  if (showCloseButton) {
    return (
      <>
        {buttonText && (
          <DigitsButton
            css={{ marginLeft: "16px", marginRight: "16px" }}
            $variant="secondary-white"
            size="medium"
          >
            {buttonText}
          </DigitsButton>
        )}
        <DigitsButton
          css={{ margin: "-8px -8px -8px 8px" }}
          $circle
          $variant="ghost-light"
          onClick={onItemCloseClick}
        >
          <SvgXClose />
        </DigitsButton>
      </>
    )
  }

  if (toLink) {
    return (
      <DigitsButton css={{ margin: "-8px -8px -8px 8px" }} $circle $variant="ghost-light">
        <SvgArrowRight />
      </DigitsButton>
    )
  }

  if (buttonText) {
    return (
      <DigitsButton css={{ marginLeft: "16px" }} $variant="secondary-white" size="medium">
        {buttonText}
      </DigitsButton>
    )
  }

  return null
}

export const EmptyActivityItem: React.FC = () => (
  <EmptyItem>
    <SatelliteSprite />
    All caught up!
  </EmptyItem>
)
