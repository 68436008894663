import * as React from "react"
import { WorkItemState } from "@digits-graphql/frontend/graphql-bearer"
import useRouter from "@digits-shared/hooks/useRouter"

export enum ClientTransactionReviewTab {
  Open = "open",
  Resolved = "resolved",
}

export function useClientTransactionReviewTab() {
  const { search } = useRouter()
  const showResolved = search.get("tab") === ClientTransactionReviewTab.Resolved

  return React.useMemo(() => ({ open: !showResolved, resolved: showResolved }), [showResolved])
}

export const getClientTransactionReviewTabArgs = (workItemState: WorkItemState) =>
  workItemState === WorkItemState.Active ? {} : { tab: ClientTransactionReviewTab.Resolved }
