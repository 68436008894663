export default {
  dndOverlay: 14,
  modalOverlay: 10,
  tooltip: 6,
  leftSidebarMax: 5,
  topNavDropdown: 5,
  pageHeaderDropdown: 4,
  stickyNavMax: 4,
  leftSidebarContent: 3,
  stickHeaderContent: 2,
  mainContent: 1,
}
