import * as React from "react"
import { type SortDirection } from "@digits-graphql/frontend/graphql-bearer"
import {
  buildDisplayOptionAccessor,
  buildDisplayOptionDefaultAccessor,
  type DisplayOptionAccessor,
  type DisplayOptionGroupContextProps,
  useBuildDisplayOptionGroup,
} from "src/frontend/components/OS/Shared/DisplayOptions/DisplayOption"
import {
  DIMENSION_ACTIVITY_OPTION,
  DIMENSION_SEARCH_TERM_OPTION,
  DIMENSION_SORT_BY_OPTION,
  DIMENSION_SORT_DIRECTION_OPTION,
} from "src/frontend/components/OS/Shared/DisplayOptions/DisplayOptionsAccessors"
import { type DimensionActivity, type DimensionSortBy } from "src/frontend/types/DimensionFilter"

/*
  CORE DISPLAY OPTION GROUP
*/

// Sort Option Group
type DimensionDisplayOptionGroupSort = {
  sortBy: DisplayOptionAccessor<DimensionSortBy>
  sortDirection: DisplayOptionAccessor<SortDirection>
}

// Filter Option Group
type DimensionDisplayOptionGroupFilter = {
  dimensionActivity: DisplayOptionAccessor<DimensionActivity>
  searchTerm: DisplayOptionAccessor<string>
}

type DimensionDisplayOptionGroup = DimensionDisplayOptionGroupSort &
  DimensionDisplayOptionGroupFilter

type DimensionDisplayOptionGroupContextProps =
  DisplayOptionGroupContextProps<DimensionDisplayOptionGroup> & {
    clearOnlyFilters: () => void
    currentFilterURLKeysCount: number
  }

/*
  DEFAULT DISPLAY OPTION GROUP
*/

export const DEFAULT_DIMENSION_DISPLAY_OPTION_GROUP_SORT: DimensionDisplayOptionGroupSort = {
  sortBy: buildDisplayOptionDefaultAccessor(DIMENSION_SORT_BY_OPTION),
  sortDirection: buildDisplayOptionDefaultAccessor(DIMENSION_SORT_DIRECTION_OPTION),
}

const DEFAULT_DIMENSION_DISPLAY_OPTION_GROUP_FILTER: DimensionDisplayOptionGroupFilter = {
  dimensionActivity: buildDisplayOptionDefaultAccessor(DIMENSION_ACTIVITY_OPTION),
  searchTerm: buildDisplayOptionDefaultAccessor(DIMENSION_SEARCH_TERM_OPTION),
}

const DEFAULT_DIMENSION_DISPLAY_OPTION_GROUP: DimensionDisplayOptionGroup = {
  ...DEFAULT_DIMENSION_DISPLAY_OPTION_GROUP_SORT,
  ...DEFAULT_DIMENSION_DISPLAY_OPTION_GROUP_FILTER,
}

/*
  CONTEXT
*/

export const DimensionDisplayOptionGroupContext =
  React.createContext<DimensionDisplayOptionGroupContextProps>({
    clearOptions: () => {},
    clearOnlyFilters: () => {},
    currentURLKeys: [],
    currentFilterURLKeysCount: 0,
    ...DEFAULT_DIMENSION_DISPLAY_OPTION_GROUP,
  })

/*
  HOOKS
*/

export function useDimensionDisplayOptionGroup() {
  return React.useContext(DimensionDisplayOptionGroupContext)
}

export const DimensionDisplayGroupProvider: React.FC<{
  isActive: boolean
  children?: React.ReactNode
}> = ({ isActive, children }) => {
  const { getOption, setOption, clearOptions, currentURLKeys } = useBuildDisplayOptionGroup(
    DEFAULT_DIMENSION_DISPLAY_OPTION_GROUP,
    isActive
  )

  const context = React.useMemo(
    (): DimensionDisplayOptionGroupContextProps => ({
      clearOptions,
      clearOnlyFilters: () =>
        clearOptions(Object.keys(DEFAULT_DIMENSION_DISPLAY_OPTION_GROUP_FILTER)),
      currentURLKeys,
      currentFilterURLKeysCount: currentURLKeys.reduce(
        (acc, urlKey: keyof DimensionDisplayOptionGroupFilter) =>
          acc + (DEFAULT_DIMENSION_DISPLAY_OPTION_GROUP_FILTER[urlKey] ? 1 : 0),
        0
      ),
      sortBy: buildDisplayOptionAccessor(
        DIMENSION_SORT_BY_OPTION,
        getOption,
        setOption,
        clearOptions
      ),
      sortDirection: buildDisplayOptionAccessor(
        DIMENSION_SORT_DIRECTION_OPTION,
        getOption,
        setOption,
        clearOptions
      ),
      dimensionActivity: buildDisplayOptionAccessor(
        DIMENSION_ACTIVITY_OPTION,
        getOption,
        setOption,
        clearOptions
      ),
      searchTerm: buildDisplayOptionAccessor(
        DIMENSION_SEARCH_TERM_OPTION,
        getOption,
        setOption,
        clearOptions
      ),
    }),
    [clearOptions, currentURLKeys, getOption, setOption]
  )

  return (
    <DimensionDisplayOptionGroupContext.Provider value={context}>
      {children}
    </DimensionDisplayOptionGroupContext.Provider>
  )
}
