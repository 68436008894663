import * as React from "react"
import useRouter from "@digits-shared/hooks/useRouter"
import useSession from "@digits-shared/hooks/useSession"
import { TermsOfServiceModal } from "src/frontend/components/OS/Shared/TermsOfService/TermsOfServiceModal"
import routes from "src/frontend/routes"
import type FrontendSession from "src/frontend/session"

export const TermsOfServiceCheck: React.FC = () => {
  const { hasAcceptedLatestTOS, hasUserData, doppelganger } = useSession<FrontendSession>()

  const { location } = useRouter()
  const isSupportPage = location.name === routes.internalFeatures.name

  if (isSupportPage || doppelganger || hasAcceptedLatestTOS || !hasUserData) {
    return null
  }

  return <TermsOfServiceModal />
}
