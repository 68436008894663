import * as React from "react"
import { type TypedDocumentNode, useApolloClient, useMutation } from "@apollo/client"
import useRedirectToApp from "@digits-shared/hooks/useRedirectToApp"
import useSession from "@digits-shared/hooks/useSession"

type LogOutDoppelgangerResponse = { logOutSupport: { bearer: string } }

interface LogOutOptions {
  rootPath: string
  LogOut: TypedDocumentNode
  LogOutDoppelganger: TypedDocumentNode<LogOutDoppelgangerResponse>
}

export default function useSessionLogOut({ rootPath, LogOut, LogOutDoppelganger }: LogOutOptions) {
  const session = useSession()
  const apolloClient = useApolloClient()
  const redirectToApp = useRedirectToApp(rootPath)

  const logBackIn = React.useCallback(
    async (data: LogOutDoppelgangerResponse) => {
      // Clear apollo client store
      // Use clearStore to avoid refetching queries
      // https://www.apollographql.com/docs/react/caching/advanced-topics/#resetting-the-cache
      await apolloClient.clearStore()

      await session.onBearerChange(data.logOutSupport).then(() => redirectToApp())
    },
    [apolloClient, redirectToApp, session]
  )

  const [logOutDoppelgangerMutation] = useMutation(LogOutDoppelganger, {
    onError: console.error,
    onCompleted: logBackIn,
    context: {
      publicAPI: true,
    },
    variables: {
      id: session.doppelgangerPermit || "",
    },
  })

  const [logOutMutation] = useMutation(LogOut, {
    onError: console.error,
    // Must be a wrapped function to avoid context error on callback
    onCompleted: () => {
      session.clear().then(() => redirectToApp())
    },
    context: {
      publicAPI: true,
    },
  })

  return React.useCallback(() => {
    // If is a doppelganger session and someone clicks logout, fire off a logout
    // event so we can track when that session was ended.
    // Must call logout first so we can make the request with the jwt
    if (session.doppelganger) {
      session.logoutDoppelganger()
      return logOutDoppelgangerMutation()
    }
    return logOutMutation()
  }, [logOutMutation, logOutDoppelgangerMutation, session])
}
