import * as React from "react"
import useStateBoolean from "@digits-shared/hooks/useStateBoolean"

interface EnterpriseReachOutModalContextValue {
  show: boolean
  openModal: () => void
  closeModal: () => void
}

const defaultValue = {
  show: true,
  openModal: () => undefined,
  closeModal: () => undefined,
}

const EnterpriseReachOutModalContext =
  React.createContext<EnterpriseReachOutModalContextValue>(defaultValue)

export const EnterpriseReachOutModalContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { value: show, setTrue: openModal, setFalse: closeModal } = useStateBoolean(false)

  const value = React.useMemo<EnterpriseReachOutModalContextValue>(
    () => ({
      show,
      openModal,
      closeModal,
    }),
    [show, openModal, closeModal]
  )

  return (
    <EnterpriseReachOutModalContext.Provider value={value}>
      {children}
    </EnterpriseReachOutModalContext.Provider>
  )
}

export function useEnterpriseReachOutModalContext() {
  return React.useContext(EnterpriseReachOutModalContext)
}
