import * as React from "react"
import moneyFlowHelper from "@digits-shared/helpers/moneyFlowHelper"
import { CurrencyStyle } from "@digits-shared/helpers/numberHelper"
import { type TimeseriesValue } from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"
import { TileCardHeading } from "src/frontend/components/Shared/Layout/Components/Tiles/TileCard"
import { TileDelta } from "src/frontend/components/Shared/Layout/Components/Tiles/TileDelta"

interface HeadingProps {
  className?: string
  timeseries?: TimeseriesValue[]
  selectedIndex?: number
}

export const TimeseriesCardHeading: React.FC<HeadingProps> = ({
  className,
  timeseries,
  selectedIndex,
}) => {
  const timeseriesToDisplay = timeseries && timeseries[selectedIndex ?? timeseries.length - 1]
  const period = timeseriesToDisplay?.period
  return (
    <TileCardHeading className={className}>
      {timeseriesToDisplay &&
        moneyFlowHelper.currency(timeseriesToDisplay.moneyFlow, {
          style: CurrencyStyle.Summary,
        })}
      <TileDelta delta={timeseriesToDisplay?.deltaPrevious} interval={period?.interval} />
    </TileCardHeading>
  )
}
