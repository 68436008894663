import * as React from "react"
import { addToRange, DayPicker, type Modifiers } from "react-day-picker"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import dayjs from "@digits-shared/initializers/dayjs/dayjs"
import { useDateRangeContext } from "src/shared/components/DateRangeSelector/DateRangeContext"
import "react-day-picker/dist/style.css"

const DayPickerContainer = styled.div`
  .rdp-root {
    --rdp-day-height: 30px;
    --rdp-day-width: 30px;
    --rdp-weekday-padding: 0;
    --rdp-font-family: ${fonts.family.avenir};
    --rdp-selected-font: bold 14px ${fonts.family.avenir};
    --rdp-range_middle-font: normal 14px ${fonts.family.avenir};
    --rdp-caption-font-size: 16px;
    --rdp-outline: none;
    --rdp-outline-selected: none;
    --rdp-accent-color: ${colors.secondary};

    .rdp-months {
      flex-wrap: nowrap;
    }

    .rdp-day.rdp-today:not(.rdp-day_outside) {
      font-weight: 700;
    }
  }
`

interface DayRangePickerProps {
  className?: string
}

interface DayRange {
  from: Date | undefined
  to: Date | undefined
}

export const DayRangePicker: React.FC<DayRangePickerProps> = ({ className }) => {
  const {
    dateRangeState: { timeRange },
    dateRangeDispatch,
  } = useDateRangeContext()
  const currentOrigin = dateTimeHelper.intervalOriginFromPeriodRange(timeRange)

  const now = dayjs().utc()
  const [dates, setDates] = React.useState<DayRange>(() => {
    const fromMmt = dateTimeHelper.dayjsFromTimestamp(timeRange.startedAt)
    const from = dateTimeHelper.dayjsToLocalDate(fromMmt)
    const toMmt = dateTimeHelper.dayjsFromTimestamp(timeRange.endedAt)
    const to = dateTimeHelper.dayjsToLocalDate(toMmt)

    return {
      from,
      to,
    }
  })

  React.useEffect(() => {
    if (!dates.to || !dates.from) return
    const start = dayjs(dates.from).utc()
    const end = dayjs(dates.to).utc()
    const d = dayjs.duration(end.diff(start))

    if (d.asMonths() > 2) {
      dateRangeDispatch({ type: "SET_START", value: end.unix() })
      dateRangeDispatch({ type: "SET_END", value: end.unix() })
      setDates({ from: end.toDate(), to: end.toDate() })
    }
  }, [dateRangeDispatch, dates])

  const disabledDays: { after: Date; before?: Date } = { after: now.toDate(), before: undefined }

  // Allow only ranges up to 60 days (2 months up or down)
  if (timeRange.startedAt) {
    disabledDays.after = dateTimeHelper.dayjsToLocalDate(
      dayjs.unix(timeRange.startedAt).add(1, "month").endOf("month")
    )
  }
  if (timeRange.endedAt) {
    disabledDays.before = dateTimeHelper.dayjsToLocalDate(
      dayjs.unix(timeRange.endedAt).subtract(1, "month").startOf("month")
    )
  }

  const defaultMonth = dateTimeHelper.dayjsFromIntervalOrigin(currentOrigin).toDate()

  const onDayClick = React.useCallback(
    (day: Date, dayModifiers: Modifiers) => {
      if (dayModifiers.disabled) {
        return
      }
      const range = addToRange(day, dates)
      const from = range?.from ?? day
      const to = range?.to ?? day

      dateRangeDispatch({ type: "SET_START", value: dayjs(from).utc().unix() })
      dateRangeDispatch({ type: "SET_END", value: dayjs(to).utc().unix() })
      setDates({
        from,
        to,
      })
    },
    [dateRangeDispatch, dates]
  )

  return (
    <DayPickerContainer className={className}>
      <DayPicker
        mode="range"
        toMonth={now.toDate()}
        disabled={disabledDays}
        selected={dates}
        numberOfMonths={2}
        onDayClick={onDayClick}
        defaultMonth={defaultMonth}
      />
    </DayPickerContainer>
  )
}
