import * as React from "react"
import { MetricSummaryItem } from "@digits-graphql/frontend/graphql-bearer"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgInfoCircle } from "@digits-shared/components/SVGIcons/line/InfoCircle.svg"
import { DigitsTooltip } from "@digits-shared/DesignSystem/Tooltip"
import colorHelper from "@digits-shared/helpers/colorHelper"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"

export const CASH_METRIC_TYPES = [
  MetricSummaryItem.GrossIncome,
  MetricSummaryItem.GrossBurn,
  MetricSummaryItem.NetBurn,
]

/*
  STYLES
*/

const InfoIcon = styled(SvgInfoCircle)`
  ${svgPathStyles(colors.secondary50, 2.5)};
  width: 10px;
  height: 10px;
`

const CashBadgePill = styled.div`
  font-size: 12px;
  padding: 2px 4px;
  background-color: ${colorHelper.hexToRgba(colors.subtleNeonGreen, 0.4)};
  border-radius: 4px;
  color: ${colors.logoGreen};

  &::after {
    content: "Cash";
    display: inline-block;
    text-transform: uppercase;
  }
`

const EllipsisText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

/*
  INTERFACES
*/

interface CashBadgeProps {
  compact?: boolean
}

/*
  COMPONENTS
*/

export const CashBadge: React.FC<CashBadgeProps> = ({ compact }) => (
  <DigitsTooltip
    maxWidth={195}
    content="Cash-based metrics reflect actual and projected money-movement while excluding unrealized transactions
     and other accrual-based accounting adjustments."
  >
    {compact ? <InfoIcon /> : <CashBadgePill />}
  </DigitsTooltip>
)

export const CashBadgedTitle: React.FC<{ title: string; compact?: boolean }> = ({
  title,
  compact,
}) => (
  <span css="display: flex; align-items: center; gap: 4px;">
    <EllipsisText>{title}</EllipsisText>
    <CashBadge compact={compact} />
  </span>
)
