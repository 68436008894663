import * as React from "react"
import { type EntityLegalEntity } from "@digits-graphql/frontend/graphql-bearer"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import { EntityIcon } from "src/shared/components/ObjectEntities/EntityIcon"

/*
  STYLES
 */

const LegalEntityTitleContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  margin: 10px 0;

  &:first-child {
    margin-top: 0;
  }
`

const LegalEntityName = styled.div`
  color: ${colors.white};
`

/*
  COMPONENTS
 */

export const LegalEntityTitle: React.FC<{ legalEntity: EntityLegalEntity }> = ({ legalEntity }) => (
  <LegalEntityTitleContainer>
    {/* TODO: Thread through organization to access icon url here */}
    <EntityIcon imageUrl="" fontSize={12} size={24} css="margin: 0 10px 0 0;">
      {legalEntity.name}
    </EntityIcon>
    <LegalEntityName>{legalEntity.name}</LegalEntityName>
  </LegalEntityTitleContainer>
)
