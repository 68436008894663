import * as React from "react"

interface NavCarouselItemContextValue {
  makeItemVisible: () => void
}

const DEFAULT: NavCarouselItemContextValue = {
  makeItemVisible: () => {},
}

export const NavCarouselItemContext = React.createContext<NavCarouselItemContextValue>(DEFAULT)

/*
  HOOKS
*/

export function useNavCarouselItemContext() {
  return React.useContext(NavCarouselItemContext)
}
