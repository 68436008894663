import { responsiveStyles } from "@digits-shared/components/Responsive/responsiveStyles"
import styled, { css } from "styled-components"
import { AssistantDisplay } from "src/frontend/components/OS/Home/useAssistantDisplay"
import { SidebarContentOffsetVar } from "src/frontend/components/Shared/NavSidebar/sidebarConstants"

// TODO - delete these consts when RealTimeEngineSettings are removed
export const APPLICATION_PANE_FULL_WIDTH = 1128
export const GRID_COLUMN_WIDTH = 72

export const APPLICATION_PANE_WIDTH = 744
export const HEADER_HEIGHT = 70
export const HEADER_GAP = 40
export const EXPANDED_CLASS_NAME = "expanded"

const applicationResponsiveStyles = responsiveStyles<{ assistantDisplay?: AssistantDisplay }>({
  desktop: ({ assistantDisplay }) => css`
    ${SidebarContentOffsetVar};
    ${assistantDisplay !== AssistantDisplay.FullWidth &&
    css`
      margin-left: var(--sidebar-content-offset);
      width: calc(100vw - var(--sidebar-content-offset));
    `}
  `,
  mobile: css`
    min-height: var(--viewport-height);
    width: var(--viewport-width);
  `,
})

export const ApplicationPositioning = styled.div<{ assistantDisplay?: AssistantDisplay }>`
  --application-pane-width: ${APPLICATION_PANE_WIDTH}px;
  --application-pane-full-width: ${APPLICATION_PANE_FULL_WIDTH}px;
  --grid-column-width: ${GRID_COLUMN_WIDTH}px;
  --header-height: ${HEADER_HEIGHT}px;
  --header-gap: ${HEADER_GAP}px;

  ${({ assistantDisplay }) => applicationResponsiveStyles({ assistantDisplay })};

  position: relative;

  height: 100%;
`

export const ApplicationPane = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: var(--application-pane-width);
`

export const ApplicationFullWidthPane = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: var(--application-pane-full-width);
  margin: 0 auto;
`

export const ApplicationExpandablePane = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: var(--application-pane-full-width);
  margin: 0 auto;

  &.${EXPANDED_CLASS_NAME} {
    width: 100%;
  }
`
