import * as React from "react"
import { type IntervalOrigin } from "@digits-graphql/frontend/graphql-bearer"
import { unique } from "@digits-shared/helpers/filters"
import objectHelper from "@digits-shared/helpers/objectHelper"
import useConstant from "@digits-shared/hooks/useConstant"
import colors from "@digits-shared/themes/colors"
import Color from "color"
import { v4 as UUID } from "uuid"
import {
  type ColoringProps,
  InterpolateColor,
  VerticalAngledGradient,
} from "src/frontend/components/OS/Shared/Charts/Coloring"
import {
  type StackableBarColorsIds,
  type StackableBarData,
} from "src/frontend/components/OS/Shared/Charts/StackableBarChart/Shared"
import { StackableBarChart } from "src/frontend/components/OS/Shared/Charts/StackableBarChart/StackableBarChart"
import {
  StackableBarChartContextProvider,
  type StackableBarChartLabelType,
  type StackableBarChartLegendType,
} from "src/frontend/components/OS/Shared/Charts/StackableBarChart/StackableBarChartContext"
import useIntervalOrigin from "src/shared/hooks/useIntervalOrigin"

export const PaletteBarColors: StackableBarColorsIds = {
  inactivePeriodFillId: "bar-fill",
  inactivePeriodHoverFillId: "bar-fill-hover",
  activePeriodFillId: "current-bar-fill",
  activePeriodHoverFillId: "current-bar-fill-hover",
}

const POSITIVE_COLOR_RANGE = [
  { color: colors.theme.dark.graph.value1 },
  { color: colors.theme.dark.graph.value2 },
  { color: colors.theme.dark.graph.value3 },
  { color: colors.theme.dark.graph.value4 },
  { color: colors.theme.dark.graph.value5 },
  { color: colors.theme.dark.graph.value6 },
]

/*
  INTERFACES
*/

interface Props {
  data?: StackableBarData[]
  isLoading?: boolean
  onBarClick?: (
    barData: StackableBarData | undefined,
    periodIndex: number,
    event: React.MouseEvent
  ) => void
  onMouseOver?: (barData: StackableBarData | undefined, periodIndex: number) => void
  onMouseOut?: (barData?: StackableBarData) => void
  otherDimensionName?: string
  label: StackableBarChartLabelType
  legend: StackableBarChartLegendType
  alwaysShowAxisTicks?: boolean
  highlightAllPeriodsOnHover?: boolean
  emptyNode?: React.ReactNode
  intervalOrigin?: IntervalOrigin
  highContrast?: boolean
  positiveColors?: GradientColorProps
  negativeColors?: GradientColorProps
  barColorIds?: StackableBarColorsIds
}

interface DarkPeriodColorProps extends ColoringProps {
  dataIds: string[]
  colorRange: {
    color: string
    bottomColor?: string
  }[]
}

export interface GradientColorProps {
  start: string
  end: string
  colorRange?: {
    color: string
    bottomColor?: string
  }[]
}

/*
  COMPONENTS
*/

export const GradientStackableBarChart: React.FC<Props> = ({
  data = [],
  isLoading,
  intervalOrigin,
  onBarClick,
  onMouseOver,
  onMouseOut,
  otherDimensionName,
  label,
  legend,
  alwaysShowAxisTicks,
  highlightAllPeriodsOnHover,
  positiveColors,
  negativeColors,
  barColorIds,
  emptyNode,
}) => {
  const uniqueChartId = useConstant(UUID())
  const defaultIntervalOrigin = useIntervalOrigin()

  const fillIds = barColorIds || PaletteBarColors
  const negativeFillIds = React.useMemo(() => {
    const nFills = { ...fillIds }
    objectHelper.keysOf(fillIds).forEach((color) => (nFills[color] = `neg-${nFills[color]}`))
    return nFills
  }, [fillIds])

  const dataIds = React.useMemo(() => data?.map((d) => d.dataId).filter(unique), [data])
  const step = 1 / dataIds.length

  const negativeColorRange = React.useMemo(() => {
    const negativeStartColor = negativeColors?.start || "#ffb466"
    const negativeEndColor = negativeColors?.end || colors.orange
    const negativeC1 = Color(negativeStartColor)
    const negativeC2 = Color(negativeEndColor || negativeStartColor)
    return dataIds.map((_, i) => {
      if (i === 0) return { color: negativeC1.hex() }
      if (i === dataIds.length - 1) return { color: negativeC2.hex() }
      return { color: InterpolateColor(negativeC1, negativeC2, i * step).hex() }
    })
  }, [dataIds, negativeColors?.end, negativeColors?.start, step])

  return (
    <StackableBarChartContextProvider
      uniqueChartId={uniqueChartId}
      label={label}
      legend={legend}
      otherDimensionName={otherDimensionName}
      alwaysShowAxisTicks={alwaysShowAxisTicks}
      highlightAllPeriodsOnHover={highlightAllPeriodsOnHover}
      emptyNode={emptyNode}
      onBarClick={onBarClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      intervalOrigin={intervalOrigin ?? defaultIntervalOrigin}
      loading={isLoading}
      chartData={data}
    >
      <StackableBarChart>
        <defs>
          <PeriodColors
            dataIds={dataIds}
            barColorIds={fillIds}
            colorRange={positiveColors?.colorRange || POSITIVE_COLOR_RANGE}
          />
          <PeriodColors
            dataIds={dataIds}
            barColorIds={negativeFillIds}
            colorRange={negativeColorRange}
          />
        </defs>
      </StackableBarChart>
    </StackableBarChartContextProvider>
  )
}

const PeriodColors: React.FC<DarkPeriodColorProps> = ({ dataIds, colorRange, barColorIds }) => (
  <>
    {dataIds.map((_, i) => {
      const { color, bottomColor } = colorRange[i % colorRange.length] ?? { color: colors.black }
      return (
        <React.Fragment key={i}>
          <VerticalAngledGradient
            id={`${barColorIds.activePeriodHoverFillId}-${i}`}
            activePeriod
            hovered
            color={color}
            bottomColor={bottomColor}
            withOpacity
          />
          <VerticalAngledGradient
            id={`${barColorIds.activePeriodFillId}-${i}`}
            activePeriod
            color={color}
            bottomColor={bottomColor}
            withOpacity
          />
          <VerticalAngledGradient
            id={`${barColorIds.inactivePeriodFillId}-${i}`}
            color={color}
            bottomColor={bottomColor}
            withOpacity
          />

          <VerticalAngledGradient
            id={`${barColorIds.inactivePeriodHoverFillId}-${i}`}
            hovered
            color={color}
            bottomColor={bottomColor}
          />
        </React.Fragment>
      )
    })}
  </>
)
