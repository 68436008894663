export const DEFAULT_COVER_URL = "<default>"

export const ABSTRACT_IMAGES = new Map<string, ImageSet>([
  [
    DEFAULT_COVER_URL,
    {
      full: "",
      thumb: require("static/images/reports/covers/default.png"),
    },
  ],
  [
    "Abstract/01abstract",
    {
      full: require("static/images/reports/covers/Abstract/01abstract.jpg"),
      thumb: require("static/images/reports/covers/Abstract/01abstract-thumb.jpg"),
    },
  ],
  [
    "Abstract/02abstract",
    {
      full: require("static/images/reports/covers/Abstract/02abstract.jpg"),
      thumb: require("static/images/reports/covers/Abstract/02abstract-thumb.jpg"),
    },
  ],
  [
    "Abstract/03abstract",
    {
      full: require("static/images/reports/covers/Abstract/03abstract.jpg"),
      thumb: require("static/images/reports/covers/Abstract/03abstract-thumb.jpg"),
    },
  ],
  [
    "Abstract/04abstract",
    {
      full: require("static/images/reports/covers/Abstract/04abstract.jpg"),
      thumb: require("static/images/reports/covers/Abstract/04abstract-thumb.jpg"),
    },
  ],
  [
    "Abstract/05abstract",
    {
      full: require("static/images/reports/covers/Abstract/05abstract.jpg"),
      thumb: require("static/images/reports/covers/Abstract/05abstract-thumb.jpg"),
    },
  ],
  [
    "Abstract/06abstract",
    {
      full: require("static/images/reports/covers/Abstract/06abstract.jpg"),
      thumb: require("static/images/reports/covers/Abstract/06abstract-thumb.jpg"),
    },
  ],
  // [
  //   "Abstract/07abstract",
  //   {
  //     full: require("static/images/reports/covers/Abstract/07abstract.jpg"),
  //     thumb: require("static/images/reports/covers/Abstract/07abstract-thumb.jpg"),
  //   },
  // ],
  [
    "Abstract/08abstract",
    {
      full: require("static/images/reports/covers/Abstract/08abstract.jpg"),
      thumb: require("static/images/reports/covers/Abstract/08abstract-thumb.jpg"),
    },
  ],
  [
    "Abstract/09abstract",
    {
      full: require("static/images/reports/covers/Abstract/09abstract.jpg"),
      thumb: require("static/images/reports/covers/Abstract/09abstract-thumb.jpg"),
    },
  ],
  [
    "Abstract/10abstract",
    {
      full: require("static/images/reports/covers/Abstract/10abstract.jpg"),
      thumb: require("static/images/reports/covers/Abstract/10abstract-thumb.jpg"),
    },
  ],
  // [
  //   "Abstract/11abstract",
  //   {
  //     full: require("static/images/reports/covers/Abstract/11abstract.jpg"),
  //     thumb: require("static/images/reports/covers/Abstract/11abstract-thumb.jpg"),
  //   },
  // ],
  // [
  //   "Abstract/12abstract",
  //   {
  //     full: require("static/images/reports/covers/Abstract/12abstract.jpg"),
  //     thumb: require("static/images/reports/covers/Abstract/12abstract-thumb.jpg"),
  //   },
  // ],
  [
    "Abstract/13abstract",
    {
      full: require("static/images/reports/covers/Abstract/13abstract.jpg"),
      thumb: require("static/images/reports/covers/Abstract/13abstract-thumb.jpg"),
    },
  ],
  [
    "Abstract/14abstract",
    {
      full: require("static/images/reports/covers/Abstract/14abstract.jpg"),
      thumb: require("static/images/reports/covers/Abstract/14abstract-thumb.jpg"),
    },
  ],
  [
    "Abstract/15abstract",
    {
      full: require("static/images/reports/covers/Abstract/15abstract.jpg"),
      thumb: require("static/images/reports/covers/Abstract/15abstract-thumb.jpg"),
    },
  ],
  [
    "Abstract/16abstract",
    {
      full: require("static/images/reports/covers/Abstract/16abstract.jpg"),
      thumb: require("static/images/reports/covers/Abstract/16abstract-thumb.jpg"),
    },
  ],
  [
    "Abstract/17abstract",
    {
      full: require("static/images/reports/covers/Abstract/17abstract.jpg"),
      thumb: require("static/images/reports/covers/Abstract/17abstract-thumb.jpg"),
    },
  ],
  [
    "Abstract/18abstract",
    {
      full: require("static/images/reports/covers/Abstract/18abstract.jpg"),
      thumb: require("static/images/reports/covers/Abstract/18abstract-thumb.jpg"),
    },
  ],
  [
    "Abstract/19abstract",
    {
      full: require("static/images/reports/covers/Abstract/19abstract.jpg"),
      thumb: require("static/images/reports/covers/Abstract/19abstract-thumb.jpg"),
    },
  ],
  [
    "Abstract/20abstract",
    {
      full: require("static/images/reports/covers/Abstract/20abstract.jpg"),
      thumb: require("static/images/reports/covers/Abstract/20abstract-thumb.jpg"),
    },
  ],
  [
    "Abstract/21abstract",
    {
      full: require("static/images/reports/covers/Abstract/21abstract.jpg"),
      thumb: require("static/images/reports/covers/Abstract/21abstract-thumb.jpg"),
    },
  ],
  [
    "Abstract/22abstract",
    {
      full: require("static/images/reports/covers/Abstract/22abstract.jpg"),
      thumb: require("static/images/reports/covers/Abstract/22abstract-thumb.jpg"),
    },
  ],
  [
    "Abstract/23abstract",
    {
      full: require("static/images/reports/covers/Abstract/23abstract.jpg"),
      thumb: require("static/images/reports/covers/Abstract/23abstract-thumb.jpg"),
    },
  ],
  [
    "Abstract/24abstract",
    {
      full: require("static/images/reports/covers/Abstract/24abstract.jpg"),
      thumb: require("static/images/reports/covers/Abstract/24abstract-thumb.jpg"),
    },
  ],
  [
    "Abstract/25abstract",
    {
      full: require("static/images/reports/covers/Abstract/25abstract.jpg"),
      thumb: require("static/images/reports/covers/Abstract/25abstract-thumb.jpg"),
    },
  ],
])

export const INDUSTRY_IMAGES = new Map<string, ImageSet>([
  [
    "Industry/01clothing",
    {
      full: require("static/images/reports/covers/Industry/01clothing.jpg"),
      thumb: require("static/images/reports/covers/Industry/01clothing-thumb.jpg"),
    },
  ],
  [
    "Industry/02Groceries",
    {
      full: require("static/images/reports/covers/Industry/02Groceries.jpg"),
      thumb: require("static/images/reports/covers/Industry/02Groceries-thumb.jpg"),
    },
  ],
  [
    "Industry/03transportation2",
    {
      full: require("static/images/reports/covers/Industry/03transportation2.jpg"),
      thumb: require("static/images/reports/covers/Industry/03transportation2-thumb.jpg"),
    },
  ],
  [
    "Industry/04cafe",
    {
      full: require("static/images/reports/covers/Industry/04cafe.jpg"),
      thumb: require("static/images/reports/covers/Industry/04cafe-thumb.jpg"),
    },
  ],
  [
    "Industry/05construction",
    {
      full: require("static/images/reports/covers/Industry/05construction.jpg"),
      thumb: require("static/images/reports/covers/Industry/05construction-thumb.jpg"),
    },
  ],
  [
    "Industry/06music",
    {
      full: require("static/images/reports/covers/Industry/06music.jpg"),
      thumb: require("static/images/reports/covers/Industry/06music-thumb.jpg"),
    },
  ],
  [
    "Industry/07retail",
    {
      full: require("static/images/reports/covers/Industry/07retail.jpg"),
      thumb: require("static/images/reports/covers/Industry/07retail-thumb.jpg"),
    },
  ],
  [
    "Industry/08nonprofit",
    {
      full: require("static/images/reports/covers/Industry/08nonprofit.jpg"),
      thumb: require("static/images/reports/covers/Industry/08nonprofit-thumb.jpg"),
    },
  ],
  [
    "Industry/09hospitality",
    {
      full: require("static/images/reports/covers/Industry/09hospitality.jpg"),
      thumb: require("static/images/reports/covers/Industry/09hospitality-thumb.jpg"),
    },
  ],
  [
    "Industry/10realestate",
    {
      full: require("static/images/reports/covers/Industry/10realestate.jpg"),
      thumb: require("static/images/reports/covers/Industry/10realestate-thumb.jpg"),
    },
  ],
  [
    "Industry/11solar",
    {
      full: require("static/images/reports/covers/Industry/11solar.jpg"),
      thumb: require("static/images/reports/covers/Industry/11solar-thumb.jpg"),
    },
  ],
  [
    "Industry/12restaurant",
    {
      full: require("static/images/reports/covers/Industry/12restaurant.jpg"),
      thumb: require("static/images/reports/covers/Industry/12restaurant-thumb.jpg"),
    },
  ],
  [
    "Industry/13hospitality",
    {
      full: require("static/images/reports/covers/Industry/13hospitality.jpg"),
      thumb: require("static/images/reports/covers/Industry/13hospitality-thumb.jpg"),
    },
  ],
  [
    "Industry/14veterinarian",
    {
      full: require("static/images/reports/covers/Industry/14veterinarian.jpg"),
      thumb: require("static/images/reports/covers/Industry/14veterinarian-thumb.jpg"),
    },
  ],
  [
    "Industry/15warehousing",
    {
      full: require("static/images/reports/covers/Industry/15warehousing.jpg"),
      thumb: require("static/images/reports/covers/Industry/15warehousing-thumb.jpg"),
    },
  ],
  [
    "Industry/16transportation1",
    {
      full: require("static/images/reports/covers/Industry/16transportation1.jpg"),
      thumb: require("static/images/reports/covers/Industry/16transportation1-thumb.jpg"),
    },
  ],
  [
    "Industry/17manufacturing1",
    {
      full: require("static/images/reports/covers/Industry/17manufacturing1.jpg"),
      thumb: require("static/images/reports/covers/Industry/17manufacturing1-thumb.jpg"),
    },
  ],
  [
    "Industry/18restaurant",
    {
      full: require("static/images/reports/covers/Industry/18restaurant.jpg"),
      thumb: require("static/images/reports/covers/Industry/18restaurant-thumb.jpg"),
    },
  ],
  [
    "Industry/19wind",
    {
      full: require("static/images/reports/covers/Industry/19wind.jpg"),
      thumb: require("static/images/reports/covers/Industry/19wind-thumb.jpg"),
    },
  ],
  [
    "Industry/20brewing",
    {
      full: require("static/images/reports/covers/Industry/20brewing.jpg"),
      thumb: require("static/images/reports/covers/Industry/20brewing-thumb.jpg"),
    },
  ],
  [
    "Industry/21healthcare",
    {
      full: require("static/images/reports/covers/Industry/21healthcare.jpg"),
      thumb: require("static/images/reports/covers/Industry/21healthcare-thumb.jpg"),
    },
  ],
  [
    "Industry/22mining",
    {
      full: require("static/images/reports/covers/Industry/22mining.jpg"),
      thumb: require("static/images/reports/covers/Industry/22mining-thumb.jpg"),
    },
  ],
  [
    "Industry/23finance",
    {
      full: require("static/images/reports/covers/Industry/23finance.jpg"),
      thumb: require("static/images/reports/covers/Industry/23finance-thumb.jpg"),
    },
  ],
  [
    "Industry/24agriculture",
    {
      full: require("static/images/reports/covers/Industry/24agriculture.jpg"),
      thumb: require("static/images/reports/covers/Industry/24agriculture-thumb.jpg"),
    },
  ],
])

export const SCENIC_IMAGES = new Map<string, ImageSet>([
  [
    "Scenic/01scenic",
    {
      full: require("static/images/reports/covers/Scenic/01scenic.jpg"),
      thumb: require("static/images/reports/covers/Scenic/01scenic-thumb.jpg"),
    },
  ],
  [
    "Scenic/02scenic",
    {
      full: require("static/images/reports/covers/Scenic/02scenic.jpg"),
      thumb: require("static/images/reports/covers/Scenic/02scenic-thumb.jpg"),
    },
  ],
  [
    "Scenic/03scenic",
    {
      full: require("static/images/reports/covers/Scenic/03scenic.jpg"),
      thumb: require("static/images/reports/covers/Scenic/03scenic-thumb.jpg"),
    },
  ],
  [
    "Scenic/04scenic",
    {
      full: require("static/images/reports/covers/Scenic/04scenic.jpg"),
      thumb: require("static/images/reports/covers/Scenic/04scenic-thumb.jpg"),
    },
  ],
  [
    "Scenic/05scenic",
    {
      full: require("static/images/reports/covers/Scenic/05scenic.jpg"),
      thumb: require("static/images/reports/covers/Scenic/05scenic-thumb.jpg"),
    },
  ],
  [
    "Scenic/06scenic",
    {
      full: require("static/images/reports/covers/Scenic/06scenic.jpg"),
      thumb: require("static/images/reports/covers/Scenic/06scenic-thumb.jpg"),
    },
  ],
  [
    "Scenic/07scenic",
    {
      full: require("static/images/reports/covers/Scenic/07scenic.jpg"),
      thumb: require("static/images/reports/covers/Scenic/07scenic-thumb.jpg"),
    },
  ],
  [
    "Scenic/08scenic",
    {
      full: require("static/images/reports/covers/Scenic/08scenic.jpg"),
      thumb: require("static/images/reports/covers/Scenic/08scenic-thumb.jpg"),
    },
  ],
  [
    "Scenic/09scenic",
    {
      full: require("static/images/reports/covers/Scenic/09scenic.jpg"),
      thumb: require("static/images/reports/covers/Scenic/09scenic-thumb.jpg"),
    },
  ],
  [
    "Scenic/10scenic",
    {
      full: require("static/images/reports/covers/Scenic/10scenic.jpg"),
      thumb: require("static/images/reports/covers/Scenic/10scenic-thumb.jpg"),
    },
  ],
  [
    "Scenic/11scenic",
    {
      full: require("static/images/reports/covers/Scenic/11scenic.jpg"),
      thumb: require("static/images/reports/covers/Scenic/11scenic-thumb.jpg"),
    },
  ],
])

export const PINK_IMAGES = new Map<string, ImageSet>([
  [
    "Pink/pink01",
    {
      full: require("static/images/reports/covers/Pink/pink01.jpg"),
      thumb: require("static/images/reports/covers/Pink/pink01-thumb.jpg"),
    },
  ],
  [
    "Pink/pink02",
    {
      full: require("static/images/reports/covers/Pink/pink02.jpg"),
      thumb: require("static/images/reports/covers/Pink/pink02-thumb.jpg"),
    },
  ],
  [
    "Pink/pink03",
    {
      full: require("static/images/reports/covers/Pink/pink03.jpg"),
      thumb: require("static/images/reports/covers/Pink/pink03-thumb.jpg"),
    },
  ],
  [
    "Pink/pink04",
    {
      full: require("static/images/reports/covers/Pink/pink04.jpg"),
      thumb: require("static/images/reports/covers/Pink/pink04-thumb.jpg"),
    },
  ],
  [
    "Pink/pink05",
    {
      full: require("static/images/reports/covers/Pink/pink05.jpg"),
      thumb: require("static/images/reports/covers/Pink/pink05-thumb.jpg"),
    },
  ],
  [
    "Pink/pink06",
    {
      full: require("static/images/reports/covers/Pink/pink06.jpg"),
      thumb: require("static/images/reports/covers/Pink/pink06-thumb.jpg"),
    },
  ],
  [
    "Pink/pink07",
    {
      full: require("static/images/reports/covers/Pink/pink07.jpg"),
      thumb: require("static/images/reports/covers/Pink/pink07-thumb.jpg"),
    },
  ],
])

export const CITYSCAPE_IMAGES = new Map<string, ImageSet>([
  [
    "Cityscape/cityscape01",
    {
      full: require("static/images/reports/covers/Cityscape/cityscape01.jpg"),
      thumb: require("static/images/reports/covers/Cityscape/cityscape01-thumb.jpg"),
    },
  ],
  [
    "Cityscape/cityscape02",
    {
      full: require("static/images/reports/covers/Cityscape/cityscape02.jpg"),
      thumb: require("static/images/reports/covers/Cityscape/cityscape02-thumb.jpg"),
    },
  ],
  [
    "Cityscape/cityscape03",
    {
      full: require("static/images/reports/covers/Cityscape/cityscape03.jpg"),
      thumb: require("static/images/reports/covers/Cityscape/cityscape03-thumb.jpg"),
    },
  ],
  [
    "Cityscape/cityscape04",
    {
      full: require("static/images/reports/covers/Cityscape/cityscape04.jpg"),
      thumb: require("static/images/reports/covers/Cityscape/cityscape04-thumb.jpg"),
    },
  ],
  [
    "Cityscape/cityscape05",
    {
      full: require("static/images/reports/covers/Cityscape/cityscape05.jpg"),
      thumb: require("static/images/reports/covers/Cityscape/cityscape05-thumb.jpg"),
    },
  ],
  // [
  //   "Cityscape/cityscape06",
  //   {
  //     full: require("static/images/reports/covers/Cityscape/cityscape06.jpg"),
  //     thumb: require("static/images/reports/covers/Cityscape/cityscape06-thumb.jpg"),
  //   },
  // ],
  // [
  //   "Cityscape/cityscape07",
  //   {
  //     full: require("static/images/reports/covers/Cityscape/cityscape07.jpg"),
  //     thumb: require("static/images/reports/covers/Cityscape/cityscape07-thumb.jpg"),
  //   },
  // ],
  [
    "Cityscape/cityscape08",
    {
      full: require("static/images/reports/covers/Cityscape/cityscape08.jpg"),
      thumb: require("static/images/reports/covers/Cityscape/cityscape08-thumb.jpg"),
    },
  ],
  [
    "Cityscape/cityscape09",
    {
      full: require("static/images/reports/covers/Cityscape/cityscape09.jpg"),
      thumb: require("static/images/reports/covers/Cityscape/cityscape09-thumb.jpg"),
    },
  ],
  [
    "Cityscape/cityscape10",
    {
      full: require("static/images/reports/covers/Cityscape/cityscape10.jpg"),
      thumb: require("static/images/reports/covers/Cityscape/cityscape10-thumb.jpg"),
    },
  ],
  [
    "Cityscape/cityscape11",
    {
      full: require("static/images/reports/covers/Cityscape/cityscape11.jpg"),
      thumb: require("static/images/reports/covers/Cityscape/cityscape11-thumb.jpg"),
    },
  ],
  [
    "Cityscape/cityscape12",
    {
      full: require("static/images/reports/covers/Cityscape/cityscape12.jpg"),
      thumb: require("static/images/reports/covers/Cityscape/cityscape12-thumb.jpg"),
    },
  ],
  [
    "Cityscape/cityscape13",
    {
      full: require("static/images/reports/covers/Cityscape/cityscape13.jpg"),
      thumb: require("static/images/reports/covers/Cityscape/cityscape13-thumb.jpg"),
    },
  ],
  [
    "Cityscape/cityscape14",
    {
      full: require("static/images/reports/covers/Cityscape/cityscape14.jpg"),
      thumb: require("static/images/reports/covers/Cityscape/cityscape14-thumb.jpg"),
    },
  ],
  [
    "Cityscape/cityscape15",
    {
      full: require("static/images/reports/covers/Cityscape/cityscape15.jpg"),
      thumb: require("static/images/reports/covers/Cityscape/cityscape15-thumb.jpg"),
    },
  ],
  [
    "Cityscape/cityscape16",
    {
      full: require("static/images/reports/covers/Cityscape/cityscape16.jpg"),
      thumb: require("static/images/reports/covers/Cityscape/cityscape16-thumb.jpg"),
    },
  ],
  [
    "Cityscape/cityscape17",
    {
      full: require("static/images/reports/covers/Cityscape/cityscape17.jpg"),
      thumb: require("static/images/reports/covers/Cityscape/cityscape17-thumb.jpg"),
    },
  ],
  [
    "Cityscape/cityscape18",
    {
      full: require("static/images/reports/covers/Cityscape/cityscape18.jpg"),
      thumb: require("static/images/reports/covers/Cityscape/cityscape18-thumb.jpg"),
    },
  ],
  [
    "Cityscape/cityscape19",
    {
      full: require("static/images/reports/covers/Cityscape/cityscape19.jpg"),
      thumb: require("static/images/reports/covers/Cityscape/cityscape19-thumb.jpg"),
    },
  ],
  [
    "Cityscape/cityscape20",
    {
      full: require("static/images/reports/covers/Cityscape/cityscape20.jpg"),
      thumb: require("static/images/reports/covers/Cityscape/cityscape20-thumb.jpg"),
    },
  ],
])

export const COVER_IMAGES = new Map<string, ImageSet>(
  Array.from(ABSTRACT_IMAGES.entries())
    .concat(Array.from(INDUSTRY_IMAGES.entries()))
    .concat(Array.from(SCENIC_IMAGES.entries()))
    .concat(Array.from(CITYSCAPE_IMAGES.entries()))
    .concat(Array.from(PINK_IMAGES.entries()))
)
