import * as React from "react"
import useRouter from "@digits-shared/hooks/useRouter"
import { usePortalDispatch } from "src/frontend/components/Shared/Portals/State/portalStore"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"

/** Looks for location state that indicates we're trying to deep link into the invite clients modal */
export function useInviteClientsModalDeepLinking() {
  const { location, history } = useRouter()
  const portalDispatch = usePortalDispatch()
  const generatePath = useFrontendPathGenerator()

  React.useEffect(() => {
    const initialModalState = location.state?.showInviteClientsModal

    if (initialModalState) {
      portalDispatch({
        type: "setShowInviteClientsModal",
        show: true,
        initialModalState,
      })
      // Redirect to the current location to clear off the state
      history.replace(location.fullPathname)
    }
  }, [generatePath, history, location, location.state?.showInviteClientsModal, portalDispatch])
}
