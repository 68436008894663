import * as React from "react"
import { type ReactNode } from "react"
import { type ObjectEntities } from "@digits-graphql/frontend/graphql-bearer"
import htmlEntities from "he"
import { type EntityPopOverComponent } from "src/shared/components/ObjectEntities/entityPopOverTypes"
import { NodesToReactParser } from "src/shared/components/ObjectEntities/NodesToReactParser"

/*
 INTERFACE
*/

interface EntityTagsProps {
  text: string
  decode?: boolean
  entities?: ObjectEntities | null
  entityPopOver?: EntityPopOverComponent
  disableHovers?: boolean
  formatChildNode?: (el: HTMLElement) => ReactNode | undefined
}

/*
 COMPONENT
*/

/**
 * Component that parses text containing entity tags and renders them with optional hover functionality
 */
const EntitiesParser = ({
  text,
  entities,
  decode,
  entityPopOver,
  disableHovers,
  formatChildNode,
}: EntityTagsProps) => {
  const decodedText = React.useMemo(
    () => (decode ? htmlEntities.decode(text) : text),
    [decode, text]
  )

  return (
    <NodesToReactParser
      text={decodedText}
      entities={entities}
      entityPopOver={entityPopOver}
      disableHovers={disableHovers}
      formatChildNode={formatChildNode}
    />
  )
}

export default EntitiesParser
