import * as React from "react"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import useReportPackageContext from "src/frontend/components/Shared/Reports/Packages/Viewer/ReportPackageContext"
import useReportContext from "src/frontend/components/Shared/Reports/Report/Viewer/ReportContext"
import useIntervalOrigin from "src/shared/hooks/useIntervalOrigin"

export function useReportIntervalOrigin(intervalCount?: number) {
  const { reportPackage } = useReportPackageContext()
  const { report } = useReportContext()
  const origin = useIntervalOrigin()

  return React.useMemo(() => {
    if (reportPackage) {
      return dateTimeHelper.intervalOriginFromRange(reportPackage, intervalCount)
    }

    if (report?.reportFor) {
      return dateTimeHelper.intervalOriginFromDate(report.reportFor, report.interval, intervalCount)
    }

    return origin
  }, [intervalCount, origin, report?.interval, report?.reportFor, reportPackage])
}
