import * as React from "react"
import { type Contact } from "@digits-graphql/frontend/graphql-public"
import { InputBlock } from "@digits-shared/components/UI/Elements/Form/Input"
import { Textarea, TextareaStyled } from "@digits-shared/components/UI/Elements/Form/TextArea"
import { DigitsButton } from "@digits-shared/DesignSystem/Button"
import stringHelper from "@digits-shared/helpers/stringHelper"
import useSession from "@digits-shared/hooks/useSession"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import { AddClientForm } from "src/frontend/components/OS/Applications/ClientPortal/InviteFlow/Content/InviteClients/AddClientForm"
import { ClientRow } from "src/frontend/components/OS/Applications/ClientPortal/InviteFlow/Content/InviteClients/ClientRow"
import { useSendInvites } from "src/frontend/components/OS/Applications/ClientPortal/InviteFlow/Content/InviteClients/useSendInvites"
import {
  MODAL_RADIUS,
  useDefaultInviteMessage,
} from "src/frontend/components/OS/Applications/ClientPortal/InviteFlow/shared"
import { type InviteClientAction } from "src/frontend/components/OS/Applications/ClientPortal/InviteFlow/State/actions"
import {
  ClientStatus,
  type InviteClientState,
} from "src/frontend/components/OS/Applications/ClientPortal/InviteFlow/State/types"
import { CONTAINER_STYLES } from "src/frontend/components/OS/Shared/ClientPortal/ClientPortalShared"
import { InviteClientsModalState } from "src/frontend/components/Shared/Portals/State/types"
import type FrontendSession from "src/frontend/session"

/*
  STYLES
*/

const Wrapper = styled.div`
  border-radius: ${MODAL_RADIUS}px;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 42px 42px 12px;
`

const Label = styled.div`
  color: ${colors.secondary};
  font-size: 14px;
  font-weight: ${fonts.weight.heavy};
  margin-bottom: 6px;
  margin-left: 12px;

  &:last-of-type {
    margin-top: 18px;
  }
`

const TextFieldWrapper = styled.div<{ readOnly: boolean }>`
  ${CONTAINER_STYLES};
  padding: 18px;
  flex: 1;
  min-height: 200px;
  margin-bottom: 0;

  ${InputBlock} {
    margin-bottom: 0;
    height: 100%;
    color: ${colors.secondary};
  }

  ${TextareaStyled} {
    font-family: ${fonts.family.avenir};
    font-weight: ${fonts.weight.normal};
    font-stretch: revert;
    height: 100%;
    padding: 0;
    color: ${colors.secondary};

    ::placeholder {
      color: ${colors.secondary70};
    }

    &,
    &:active,
    &:hover {
      border: 0.5px solid transparent !important;
      border-image-source: revert !important;
    }

    &:read-only {
      color: ${colors.secondary70};
    }
  }

  transition: color 200ms ease-in-out;
`

const BottomWrapper = styled.div`
  margin-top: auto;
  padding: 8px 4px 0;
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`

const ScrollableArea = styled.div`
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  overscroll-behavior: none;
  display: flex;
  flex-direction: column;
  padding: 0 4px;
`

/*
  COMPONENTS
*/

export const InviteClients: React.FC<{
  className?: string
  dispatch: React.Dispatch<InviteClientAction>
  state: InviteClientState
  onClose?: () => void
}> = ({ className, state, dispatch }) => {
  const {
    invitees,
    sendingInvitations,
    validClientInForm,
    inviteFormValid,
    userNoun,
    userPluralNoun,
  } = state
  const { user } = useSession<FrontendSession>()

  const { clientMessage, setClientMessage, sendInvites } = useSendInvites(state, dispatch)

  const addClient = React.useCallback(
    (client: Contact) => {
      dispatch({ type: "addInvitee", client })
    },
    [dispatch]
  )

  const deleteClient = React.useCallback(
    (client: Contact) => {
      dispatch({ type: "deleteInvitee", client })
    },
    [dispatch]
  )

  const backToClientList = React.useCallback(() => {
    dispatch({ type: "setModalState", modalState: InviteClientsModalState.MyClients })
  }, [dispatch])

  // set a default invitation message
  const defaultMessage = useDefaultInviteMessage()
  React.useEffect(() => {
    const signature = user?.attributes?.givenName
      ? `\r\n\nBest,\r\n${user.attributes.givenName}`
      : ""

    setClientMessage(defaultMessage + signature)
  }, [defaultMessage, setClientMessage, user.attributes.givenName])

  const updateMessage = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      event.preventDefault()
      setClientMessage(event.target.value)
    },
    [setClientMessage]
  )

  const contacts = React.useMemo(() => {
    const pendingContacts = invitees.filter((i) => i.status === ClientStatus.Pending)
    const sentContacts = invitees.filter((i) => i.status === ClientStatus.Sent)
    return sentContacts.concat(pendingContacts)
  }, [invitees])

  const sendInvitesDisabled =
    (invitees.length === 0 && !validClientInForm) ||
    !inviteFormValid ||
    !clientMessage ||
    sendingInvitations

  const inviteNoun = stringHelper.pluralize(invitees.length, "Invite", "Invites")

  return (
    <Wrapper className={className}>
      <ScrollableArea>
        <Label>Add {stringHelper.titleCase(userNoun)}</Label>
        {contacts.map((client) => (
          <ClientRow
            key={client.emailAddress}
            client={client}
            showSentStatus={client.status === ClientStatus.Sent}
            readOnly={sendingInvitations}
            deleteClient={deleteClient}
          />
        ))}

        <AddClientForm state={state} dispatch={dispatch} addClient={addClient} />

        <Label>Message</Label>
        <TextFieldWrapper readOnly={sendingInvitations}>
          <Textarea
            placeholder="Add a message"
            value={clientMessage}
            onChange={updateMessage}
            readOnly={sendingInvitations}
          />
        </TextFieldWrapper>
      </ScrollableArea>

      <BottomWrapper>
        <DigitsButton disabled={sendInvitesDisabled} onClick={sendInvites} $fullWidth>
          {sendingInvitations ? `Sending ${inviteNoun}` : `Send ${inviteNoun}`}
        </DigitsButton>

        {!sendingInvitations && state.showViewUsers && (
          <DigitsButton onClick={backToClientList} $variant="ghost-dark" $fullWidth>
            View All {stringHelper.titleCase(userPluralNoun)}
          </DigitsButton>
        )}
      </BottomWrapper>
    </Wrapper>
  )
}
