import * as React from "react"
import Dropzone, { type DropzoneState, type FileRejection } from "react-dropzone"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgAccountantFirm } from "@digits-shared/components/SVGIcons/custom/AccountantFirm.svg"
import { SvgPlusCircleFilled } from "@digits-shared/components/SVGIcons/custom/PlusCircleFilled.svg"
import { SvgAlertTriangle } from "@digits-shared/components/SVGIcons/line/AlertTriangle.svg"
import { SvgBuilding02 } from "@digits-shared/components/SVGIcons/line/Building02.svg"
import { SvgPassport } from "@digits-shared/components/SVGIcons/line/Passport.svg"
import { SvgXCircleSolid } from "@digits-shared/components/SVGIcons/solid/XCircleSolid.svg"
import { URL_PATTERN_REGEX } from "@digits-shared/components/UI/Elements/Form/Form"
import { Input } from "@digits-shared/components/UI/Elements/Form/Input"
import { hasFirstElement } from "@digits-shared/helpers/arrayHelper"
import { EMAIL_ADDRESS_PATTERN_REGEX } from "@digits-shared/helpers/emailAddressPattern"
import objectHelper from "@digits-shared/helpers/objectHelper"
import { type UseFormTrackingVal } from "@digits-shared/hooks/useFormTracking"
import useSession from "@digits-shared/hooks/useSession"
import borders from "@digits-shared/themes/borders"
import colors from "@digits-shared/themes/colors"
import fonts, { H2Text } from "@digits-shared/themes/typography"
import styled from "styled-components"
import { type AddClientAction } from "src/frontend/components/OS/Header/AddClient/actions"
import { type AddClientState } from "src/frontend/components/OS/Header/AddClient/types"
import type FrontendSession from "src/frontend/session"

// Same kb value as API, if changed you need to update `IconMaxSize`

const BADGE_MAX_SIZE = 1 << 22

const BADGE_ACCEPTED_TYPES = {
  "image/png": [".png"],
  "image/jpeg": [".jpeg", ".jpg"],
  "image/gif": [".gif"],
}

export enum ClientDetailsField {
  companyName = "companyName",
  websiteUrl = "websiteUrl",
  givenName = "givenName",
  familyName = "familyName",
  emailAddress = "emailAddress",
}

interface CompanyLogoProps {
  iconBlob?: string
  iconUrl?: string
}

/*
  STYLES
*/

const FormsWrapper = styled.div``

const StyledForm = styled.form<{ disabled: boolean }>`
  background: ${colors.white};
  border-radius: 8px;
  border: 0.5px solid ${colors.white};
  display: flex;
  flex-direction: column;

  transition: background-color 200ms ease-in-out;
  padding: 10px 18px 0;
  margin-bottom: 12px;
`

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
`

const Left = styled.div`
  display: flex;
  flex: 0 0 70%;
  flex-direction: column;
`

const Right = styled.div`
  flex: 0 0 25%;
  text-align: right;
  align-items: center;
`

const TitleRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 24px 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 2px;
  margin-bottom: 16px;
`

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
`

const ClientDetailsIcon = styled(SvgBuilding02)`
  height: 16px;
  width: 16px;
  ${svgPathStyles(colors.white, 2)};
`

const UserDetailsIcon = styled(SvgPassport)`
  height: 16px;
  width: 16px;
  ${svgPathStyles(colors.white, 2)};
`

const ModuleIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  align-self: center;
  background: ${colors.accentBlue};
`

const TitleText = styled(H2Text)`
  font-weight: ${fonts.weight.normal};
`

const StyledWarningIcon = styled(SvgAlertTriangle)`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  ${svgPathStyles(colors.orange, 1.5)};
`

const Decoration = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;
  font-size: 11px;
  font-weight: ${fonts.weight.medium};
  width: 100%;
  line-height: 120%;
`

const ErrorMessage: React.FC<React.PropsWithChildren<{ className?: string; show?: boolean }>> = ({
  className,
  show,
  children,
}) => (
  <>
    {show && (
      <Decoration>
        <StyledWarningIcon />
        {children}
      </Decoration>
    )}
  </>
)

const DropzoneContainer = styled.div<{ isDragActive: boolean }>`
  padding: 5px 10px 0;
  flex: 1;
  align-items: center;
  border: 2px dashed
    ${({ isDragActive }) => (isDragActive ? colors.translucentSecondary40 : colors.transparent)};
`

const LogoLabel = styled.div`
  font-size: 12px;
  font-weight: ${fonts.weight.medium};
  color: ${colors.secondary70};
  text-align: center;
`

const IconContainer = styled.div`
  position: relative;
  background-color: ${colors.white};
  border-radius: ${borders.radius.card}px;
  cursor: pointer;
`

const RemoveIcon = styled(SvgXCircleSolid)`
  transition: opacity 250ms;
  position: absolute;
  width: 24px;
  height: 24px;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
  ${svgPathStyles(colors.secondary70, 1.5)};
`

const PlusIcon = styled(SvgPlusCircleFilled)`
  position: absolute;
  width: 36px;
  height: 36px;
  top: 45px;
  left: calc(50% + 7px);
`

const Logo = styled.img`
  transition: opacity 250ms;
  min-width: 36px;
  min-height: 36px;
  max-width: 72px;
  max-height: 72px;
  border-radius: 4px;
`

const LogoPlaceholder = styled(SvgAccountantFirm)`
  width: 72px;
  height: 72px;
`

const LogoWrapper = styled.div`
  &:hover {
    ${Logo} {
      opacity: 0.3;
    }

    ${RemoveIcon} {
      opacity: 1;
    }
  }

  &:not(:hover) {
    ${Logo} {
      opacity: 1;
    }

    ${RemoveIcon} {
      opacity: 0;
    }
  }
`

/*
  COMPONENTS
*/

export const ClientDetailsForm: React.FC<{
  state: AddClientState
  dispatch: React.Dispatch<AddClientAction>
  formTracking: UseFormTrackingVal<ClientDetailsField>
}> = ({ state, dispatch, formTracking }) => {
  const { user } = useSession<FrontendSession>()
  const {
    onFieldBlurred,
    onFieldChanged,
    isFieldInvalid,
    fieldHasValidValue,
    getFieldValue,
    hasFieldBlurred,
  } = formTracking

  const companyDetailsFormRef = React.useRef<HTMLFormElement>(null)
  const userDetailsFormRef = React.useRef<HTMLFormElement>(null)
  const companyNameRef = React.useRef<HTMLInputElement>(null)

  const emailIsSelf = React.useMemo(
    () => getFieldValue(ClientDetailsField.emailAddress) === user.emailAddress,
    [getFieldValue, user.emailAddress]
  )

  React.useEffect(() => {
    if (emailIsSelf) {
      dispatch({
        type: "setErrorMessage",
        message: (
          <>
            The email address entered is already associated with an existing account. Please invite
            a user with a new email address.
          </>
        ),
      })
    } else {
      dispatch({
        type: "setErrorMessage",
        message: undefined,
      })
    }
  }, [dispatch, emailIsSelf])

  const invalid = objectHelper
    .keysOf(ClientDetailsField)
    .some((f) => !fieldHasValidValue(ClientDetailsField[f]) || emailIsSelf)

  React.useEffect(() => {
    dispatch({ type: "setAddClientDetailsFormValid", valid: !invalid })
  }, [dispatch, invalid])

  const onSelected = React.useCallback(
    (icon: string) => {
      dispatch({ type: "setClientIcon", icon })
    },
    [dispatch]
  )

  const onFileDropped = React.useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      const reader = new FileReader()
      reader.onabort = () => console.error("file reading was aborted")
      reader.onerror = () => console.error("file reading has failed")
      reader.onload = () => {
        const blob = reader.result
        if (typeof blob !== "string") return
        onSelected(blob)
      }
      if (hasFirstElement(acceptedFiles)) {
        reader.readAsDataURL(acceptedFiles[0])
      } else {
        dispatch({
          type: "setErrorMessage",
          message: fileRejections[0]?.errors[0]?.message || "File cannot be read",
        })
      }
    },
    [dispatch, onSelected]
  )

  const onRemoveIcon = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      dispatch({ type: "removeClientIcon" })
    },
    [dispatch]
  )

  return (
    <FormsWrapper>
      <StyledForm ref={companyDetailsFormRef} disabled={false}>
        <TitleRow>
          <ModuleIconContainer>
            <ClientDetailsIcon />
          </ModuleIconContainer>
          <TitleText>Company Details</TitleText>
        </TitleRow>
        <DetailsWrapper>
          <Left>
            <Row>
              <Input
                forwardRef={companyNameRef}
                placeholder="Company Name"
                name={ClientDetailsField.companyName}
                defaultValue={getFieldValue(ClientDetailsField.companyName) ?? undefined}
                autoFocus
                autoComplete="off"
                type="text"
                onChange={onFieldChanged}
                onBlur={onFieldBlurred}
                isInvalid={isFieldInvalid(ClientDetailsField.companyName)}
                $variant="bordered"
                required
              />
            </Row>
            <Row>
              <InputWrapper>
                <Input
                  placeholder="Company Website"
                  name={ClientDetailsField.websiteUrl}
                  defaultValue={getFieldValue(ClientDetailsField.websiteUrl) ?? undefined}
                  autoComplete="off"
                  pattern={URL_PATTERN_REGEX}
                  type="text"
                  onChange={onFieldChanged}
                  onBlur={onFieldBlurred}
                  isInvalid={isFieldInvalid(ClientDetailsField.websiteUrl)}
                  $variant="bordered"
                  inputMarginBottom="0"
                />
                <ErrorMessage
                  show={
                    hasFieldBlurred(ClientDetailsField.websiteUrl) &&
                    isFieldInvalid(ClientDetailsField.websiteUrl)
                  }
                >
                  Company Website must be a valid URL
                </ErrorMessage>
              </InputWrapper>
            </Row>
          </Left>
          <Right>
            <LogoLabel>Company Logo</LogoLabel>
            <IconContainer>
              <Dropzone
                disabled={!!state.icon}
                multiple={false}
                onDrop={onFileDropped}
                maxSize={BADGE_MAX_SIZE}
                accept={BADGE_ACCEPTED_TYPES}
              >
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <IconDropZone
                    iconBlob={state.icon}
                    getRootProps={getRootProps}
                    getInputProps={getInputProps}
                    isDragActive={isDragActive}
                    onRemove={onRemoveIcon}
                  />
                )}
              </Dropzone>
            </IconContainer>
          </Right>
        </DetailsWrapper>
      </StyledForm>
      <StyledForm ref={userDetailsFormRef} disabled={false}>
        <TitleRow>
          <ModuleIconContainer>
            <UserDetailsIcon />
          </ModuleIconContainer>
          <TitleText>User Details</TitleText>
        </TitleRow>
        <Row>
          <Input
            placeholder="First Name"
            name={ClientDetailsField.givenName}
            defaultValue={getFieldValue(ClientDetailsField.givenName) ?? undefined}
            autoComplete="off"
            type="text"
            onChange={onFieldChanged}
            onBlur={onFieldBlurred}
            isInvalid={isFieldInvalid(ClientDetailsField.givenName)}
            $variant="bordered"
            required
          />
          <Input
            placeholder="Last Name"
            name={ClientDetailsField.familyName}
            defaultValue={getFieldValue(ClientDetailsField.familyName) ?? undefined}
            autoComplete="off"
            type="text"
            onChange={onFieldChanged}
            onBlur={onFieldBlurred}
            isInvalid={isFieldInvalid(ClientDetailsField.familyName)}
            $variant="bordered"
            required
          />
        </Row>
        <Row>
          <InputWrapper>
            <Input
              placeholder="Email"
              name={ClientDetailsField.emailAddress}
              defaultValue={getFieldValue(ClientDetailsField.emailAddress) ?? undefined}
              autoComplete="off"
              pattern={EMAIL_ADDRESS_PATTERN_REGEX}
              type="text"
              onChange={onFieldChanged}
              onBlur={onFieldBlurred}
              isInvalid={isFieldInvalid(ClientDetailsField.emailAddress)}
              $variant="bordered"
              inputMarginBottom="0"
              required
            />
            <ErrorMessage
              show={
                hasFieldBlurred(ClientDetailsField.emailAddress) &&
                isFieldInvalid(ClientDetailsField.emailAddress)
              }
            >
              Email must be a valid email address
            </ErrorMessage>
          </InputWrapper>
        </Row>
      </StyledForm>
    </FormsWrapper>
  )
}

type IconDropZoneProps = Pick<DropzoneState, "getInputProps" | "getRootProps" | "isDragActive">
const IconDropZone: React.FC<
  CompanyLogoProps & IconDropZoneProps & { onRemove: (e: React.MouseEvent) => void }
> = ({ iconBlob, getRootProps, getInputProps, isDragActive, onRemove }) => (
  <div {...getRootProps()}>
    <input {...getInputProps()} />

    <IconContainer>
      <DropzoneContainer isDragActive={isDragActive}>
        <IconPreview iconBlob={iconBlob} onRemove={onRemove} />
      </DropzoneContainer>
    </IconContainer>
  </div>
)

const IconPreview: React.FC<CompanyLogoProps & { onRemove: (e: React.MouseEvent) => void }> = ({
  iconBlob,
  onRemove,
}) => {
  if (iconBlob) {
    return (
      <LogoWrapper onClick={onRemove}>
        <Logo src={iconBlob} />
        <RemoveIcon />
      </LogoWrapper>
    )
  }

  return (
    <>
      <LogoPlaceholder />
      <PlusIcon />
    </>
  )
}
