import * as React from "react"
import { DimensionSortMode } from "@digits-graphql/frontend/graphql-bearer"
import stringHelper from "@digits-shared/helpers/stringHelper"
import { type CategoryTransactionSummary } from "src/frontend/types"

export function useCategoriesListCountDisplay(
  categorySummaries: CategoryTransactionSummary[] | undefined,
  categoryTypeName: string,
  sortMode: DimensionSortMode,
  invertValues: boolean
) {
  return React.useMemo(() => {
    const suffix = stringHelper.pluralize(categorySummaries?.length || 0, "Category", "Categories")

    let sortModeName
    switch (sortMode) {
      case DimensionSortMode.Total:
        sortModeName = ""
        break
      case DimensionSortMode.Growing:
        sortModeName = invertValues ? "Shrinking" : "Growing"
        break
      case DimensionSortMode.Shrinking:
        sortModeName = invertValues ? "Growing" : "Shrinking"
        break
    }
    return [sortModeName, categoryTypeName, suffix].filter((n) => n).join(" ")
  }, [categorySummaries, sortMode, categoryTypeName, invertValues])
}
