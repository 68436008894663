import * as React from "react"
import { PartyRole, type StatementDeltas } from "@digits-graphql/frontend/graphql-bearer"
import { hasFirstElement } from "@digits-shared/helpers/arrayHelper"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import styled, { css } from "styled-components"
import { TopPartiesList } from "src/frontend/components/Shared/Reports/Report/Components/Parties/PartiesList"
import { CategoryInsight } from "src/frontend/components/Shared/Reports/Report/Components/Shared/DetailsPopOver/CategoryInsight"
import { DetailsPopUp } from "src/frontend/components/Shared/Reports/Report/Components/Shared/DetailsPopOver/DetailsPopUp"
import { OverTimeChart } from "src/frontend/components/Shared/Reports/Report/Components/Shared/DetailsPopOver/OverTimeChart"
import {
  PopOverTab,
  PopOverTabs,
} from "src/frontend/components/Shared/Reports/Report/Components/Shared/DetailsPopOver/PopOverTabs"
import { TopTransactions } from "src/frontend/components/Shared/Reports/Report/Components/Shared/DetailsPopOver/TopTransactions"
import { useBalanceDetails } from "src/frontend/components/Shared/Reports/Report/Components/Shared/DetailsPopOver/useBalanceDetails"
import {
  PopOverPosition,
  type PopOverPositioning,
} from "src/frontend/components/Shared/Reports/Report/Components/Shared/DetailsPopOver/usePopOverPositioning"
import useRowSelectionContext from "src/frontend/components/Shared/Reports/Report/Components/Statements/RowSelectionContext"
import {
  type CategoryRowDetails,
  type StatementCategoryDetails,
  useToTimeSeries,
} from "src/frontend/components/Shared/Reports/Report/Components/Statements/toDetailsData"
import { CategoryTitle } from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/Components/Text/PopOver/EntityPopOverTitle"
import { FrontendPartyRole } from "src/frontend/types/FrontendPartyRole"

/*
  STYLES
*/

const TopParties = styled(TopPartiesList)<{ minHeight?: number }>`
  padding: 15px 20px;
  ${({ minHeight }) =>
    minHeight &&
    css`
      min-height: ${minHeight}px;
    `}
`

/*
  INTERFACES
*/

interface PopOverProps {
  rowId: string
  details: CategoryRowDetails
  deltas?: StatementDeltas
}

/*
  COMPONENTS
*/

export const CategoryDetailsPopOver: React.FC<PopOverProps & PopOverPositioning> = ({
  rowId,
  details,
  deltas,
  positionRef,
  measureRef,
  measureRect,
  position,
}) => {
  const {
    periodDetails: {
      hover: { context, entity: category },
      insight,
    },
  } = details
  const { selectedValue } = useRowSelectionContext()
  const skipAnimations = React.useRef(false)

  // if the popover position is above the line, set the height of the charts to be a minimum height
  // this prevents the popover from shrinking and losing focus
  const minHeight = React.useMemo(() => {
    if (position === PopOverPosition.Below) return undefined
    return insight ? 331 : 230
  }, [insight, position])

  const { topParties, partiesTitle } = React.useMemo(() => {
    const topParties =
      context?.party?.map(({ summary, partyObject: party }) => ({
        party,
        summary,
      })) || []

    const partiesRole = topParties
      ?.find((topParty) => !!topParty.party.roles?.find((r) => r !== PartyRole.UnknownRole))
      ?.party.roles?.find((r) => r !== PartyRole.UnknownRole)

    const partiesTitle = `Top ${
      partiesRole ? FrontendPartyRole.displayStringForRole(partiesRole, true) : "Journal Entries"
    }`

    return { topParties, partiesTitle }
  }, [context?.party])

  const tabs = React.useMemo(() => {
    const skip = skipAnimations.current
    skipAnimations.current = true

    const { periodDetails, ytdDetails } = details
    const {
      hover: { topTransactions },
    } = periodDetails

    const data: React.ReactElement[] = []
    data.push(
      <PopOverTab key="overview" title="Overview">
        <Chart details={periodDetails} deltas={deltas} skipAnimations={skip} />
        <CategoryInsight category={category} insight={insight} />
      </PopOverTab>
    )

    // Disable YTD if the ytdDetails do not have "valid" YTD periods,
    // e.g. Jan or Q1
    if (
      hasFirstElement(ytdDetails?.hover?.history.time) &&
      dateTimeHelper.isPeriodYearToDate(ytdDetails.hover.history.time[0].summary.period)
    ) {
      const selectedYTD =
        selectedValue && dateTimeHelper.isPeriodYearToDate(selectedValue.value.period)

      data.push(
        <PopOverTab key="ytd" title="YTD" active={selectedYTD}>
          <Chart details={ytdDetails} deltas={deltas} skipAnimations={skip} />
          <CategoryInsight category={category} insight={ytdDetails.insight} isYTDInsight />
        </PopOverTab>
      )
    }

    // top parties chart is optional
    if (topParties.length) {
      data.push(
        <PopOverTab key="top_parties" title={partiesTitle}>
          <TopParties minHeight={minHeight} partySummaries={topParties} category={category} />
        </PopOverTab>
      )
    }

    // top transactions list is optional
    if (topTransactions?.length) {
      data.push(
        <PopOverTab key="top_txn" title="Top Transactions">
          <TopTransactions minHeight={minHeight} transactions={topTransactions} />
        </PopOverTab>
      )
    }
    return data
  }, [category, deltas, details, insight, minHeight, partiesTitle, selectedValue, topParties])

  return (
    <DetailsPopUp
      ref={measureRef}
      rowId={rowId}
      details={details}
      positionRef={positionRef}
      measureRect={measureRect}
      width={420}
      maxHeight="auto"
    >
      <CategoryTitle category={category} />
      <PopOverTabs>{tabs}</PopOverTabs>
    </DetailsPopUp>
  )
}

const Chart: React.FC<{
  details: StatementCategoryDetails
  deltas?: StatementDeltas
  skipAnimations?: boolean
}> = ({ details, deltas, skipAnimations }) => {
  const isBalance = useBalanceDetails(details)
  const amountOverTime = useToTimeSeries(details)
  return (
    <OverTimeChart
      amountOverTime={amountOverTime}
      deltas={deltas}
      isBalance={isBalance}
      skipAnimations={skipAnimations}
    />
  )
}
