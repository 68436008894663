import * as React from "react"
import { type UseMeasureRect } from "react-use/lib/useMeasure"
import { useMeasure } from "@digits-shared/hooks/useMeasure"

export enum PopOverPosition {
  Above = "Above",
  Below = "Below",
}

export interface PopOverPositioning {
  position: PopOverPosition

  positionRef: React.RefObject<HTMLElement>
  measureRef: React.Ref<HTMLDivElement>
  measureRect: UseMeasureRect
}

export function usePopOverPositioning() {
  const containerRef = React.useRef<HTMLDivElement>(null)
  const [popOverMeasureRef, popOverMeasureRect] = useMeasure<HTMLDivElement>()

  return React.useMemo(() => {
    const { height: popOverHeight } = popOverMeasureRect
    const viewportHeight = window.innerHeight

    const containerY = containerRef.current?.getBoundingClientRect().y

    let popOverPosition = PopOverPosition.Below

    if (containerY !== undefined && popOverHeight !== 0) {
      const positionAbove = containerY + popOverHeight > viewportHeight
      popOverPosition = positionAbove ? PopOverPosition.Above : PopOverPosition.Below
    }

    return {
      popOverPosition,
      containerRef,
      popOverMeasureRef,
      popOverMeasureRect,
    }
  }, [popOverMeasureRect, popOverMeasureRef])
}
