export default {
  onboardMainScreens: {
    duration: "400ms",
    rawDuration: 400,
  },

  onboardFadeIn: {
    duration: "500ms",
    rawDuration: 500,
  },

  onboardModal: {
    duration: "500ms",
    rawDuration: 500,
  },

  defaultFadeIn: {
    duration: "250ms",
  },

  defaultElementHover: {
    duration: "250ms",
    rawDuration: 250,
  },
}
