import {
  Flow,
  type MoneyFlow,
  type StatementDeltas,
  type StatementDeltaValue,
} from "@digits-graphql/frontend/graphql-bearer"
import deltaHelper from "@digits-shared/helpers/deltaHelper"

export function toStatementDeltaValue(
  currentValue: MoneyFlow | undefined | null,
  previous: number | undefined | null
): StatementDeltaValue | undefined {
  if (previous === undefined || previous === null || !currentValue) return undefined

  const percentageOfAmount = deltaHelper.deltaPercentageForMoneyFlow(currentValue, previous)
  if (!percentageOfAmount) {
    return undefined
  }

  const isNormal =
    currentValue.businessFlow === Flow.Outbound && percentageOfAmount < 0
      ? false
      : currentValue.isNormal

  return {
    percentageOfAmount,
    moneyFlow: {
      ...currentValue,
      isNormal,
      value: {
        ...currentValue.value,
        amount: previous,
      },
    },
  }
}

export function toStatementDeltas(args: {
  moneyFlow: MoneyFlow | undefined | null
  deltaPrevious: number | undefined | null
  deltaYearAgo: number | undefined | null
}): StatementDeltas | undefined {
  const { moneyFlow, deltaPrevious, deltaYearAgo } = args
  const previousPeriod =
    deltaPrevious === undefined ? deltaPrevious : toStatementDeltaValue(moneyFlow, deltaPrevious)
  const previousYear =
    deltaYearAgo === undefined ? deltaYearAgo : toStatementDeltaValue(moneyFlow, deltaYearAgo)

  return {
    previousPeriod,
    previousYear,
  }
}
