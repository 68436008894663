import * as React from "react"
import {
  type CollapsedSections,
  type ReportDocumentOptions,
} from "@digits-graphql/frontend/graphql-bearer"

type ReportOptionsContextValue = {
  options?: ReportDocumentOptions | null
  mutation?: (newOptions: ReportDocumentOptions) => void
  loading: boolean
  collapsedSections: CollapsedSections
  setCollapsedSections: (...ids: string[]) => void
  toggleCollapsedSection: (...ids: string[]) => void
}

export const ReportOptionsContext = React.createContext<ReportOptionsContextValue>({
  loading: false,
  collapsedSections: {
    sections: [],
  },
  setCollapsedSections: () => {},
  toggleCollapsedSection: () => {},
})

export function useReportOptionsContext() {
  return React.useContext(ReportOptionsContext)
}
