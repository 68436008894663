import * as React from "react"
import { PickerPosition } from "@digits-shared/components/UI/Picker/constants"
import { useEscapeKeyCapture } from "@digits-shared/hooks/useEscapeKeyCapture"
import { useOnBodyClick } from "@digits-shared/hooks/useOnBodyClick"
import { usePopOverState } from "@digits-shared/hooks/usePopOverState"
import useScrollContext from "@digits-shared/hooks/useScrollContext"

interface PickerState {
  isVisible: boolean
  setVisible: () => void
  setHidden: () => void
  onMouseEnter: (e: React.MouseEvent<HTMLElement>) => void
  onMouseLeave: (e: React.MouseEvent<HTMLElement>) => void
  pickerPosition: PickerPosition
  containerRef: React.MutableRefObject<HTMLDivElement | null>
}

type PickerOptions = boolean | { disabled?: boolean; ignoreBodyClick?: boolean }

function isOptionsBoolean(o?: PickerOptions): o is boolean {
  return typeof o === "boolean"
}

function ensureOptions(o?: PickerOptions) {
  if (isOptionsBoolean(o)) {
    return { disabled: o }
  }

  return o ?? {}
}

// Manages the state of a picker dialog in a way that is aware of its position in a scrolling container.
export function usePickerState(
  estimatedPickerHeight: number,
  options?: PickerOptions
): PickerState {
  const containerRef = React.useRef<HTMLDivElement | null>(null)
  const { disabled, ignoreBodyClick } = ensureOptions(options)

  const {
    isPopOverOpen: isVisible,
    showPopOver,
    hidePopOver: setHidden,
    onMouseEnter,
    onMouseLeave,
  } = usePopOverState({ disabled })
  useOnBodyClick(containerRef, setHidden, !isVisible || ignoreBodyClick)

  useEscapeKeyCapture(setHidden)

  const [pickerPosition, setPickerPosition] = React.useState(PickerPosition.BelowTarget)

  const { getScrollElement } = useScrollContext()

  const setVisible = React.useCallback(() => {
    const scrollClientRect = getScrollElement()?.getBoundingClientRect()
    const selfClientRect = containerRef.current?.getBoundingClientRect()

    if (!scrollClientRect || !selfClientRect) return showPopOver()

    const isPickerOverflowingBottom =
      // If the picker is less than its height away from the scroll container's bottom, it will be cut off.
      scrollClientRect.bottom - selfClientRect.top < estimatedPickerHeight &&
      // There must also be enough room to show the picker if its is above the target, so make sure
      // it won't be cut off at the top of the scroll container
      selfClientRect.top - scrollClientRect.top >= estimatedPickerHeight

    setPickerPosition(
      isPickerOverflowingBottom ? PickerPosition.AboveTarget : PickerPosition.BelowTarget
    )
    showPopOver()
  }, [estimatedPickerHeight, getScrollElement, showPopOver])

  return {
    containerRef,
    isVisible,
    setVisible,
    setHidden,
    onMouseEnter,
    onMouseLeave,
    pickerPosition,
  }
}
