import * as React from "react"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgCheck } from "@digits-shared/components/SVGIcons/line/Check.svg"
import { BasicCheckbox } from "@digits-shared/components/UI/Elements/Checkbox/BasicCheckbox"
import { isSafari } from "@digits-shared/helpers/devicesHelper"
import colors from "@digits-shared/themes/colors"
import styled, { css } from "styled-components"

/*
 STYLES
*/

const OnboardCheckboxContainer = styled.div`
  position: relative;
  display: flex;
`

const OnboardCheckmarkHider = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 21px;
  height: 21px;
  pointer-events: none;
`

export const OnboardCheckmarkIcon = styled(SvgCheck)<{ color?: string }>`
  position: absolute;
  display: none;
  top: 7px;
  left: ${isSafari ? "6.5" : "8.5"}px;
  ${({ color }) => svgPathStyles(color ?? colors.neonGreen, 3)};
  height: 12px;
  width: 12px;
  pointer-events: none;
`

const OnboardCheckmarkIconWhite = styled(OnboardCheckmarkIcon)<{ color?: string }>`
  ${({ color }) => svgPathStyles(color ?? colors.neonGreen, 3)};
  z-index: -1;
`

const OnboardCheckboxStyled = styled.input<{ color?: string; disabled?: boolean }>`
  display: inline-block;
  background-color: ${colors.transparent};
  border: 1px solid ${({ color }) => color ?? colors.neonGreen};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  padding: 9px;
  border-radius: 4px;
  appearance: none;
  overflow: hidden;
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}

  &:checked {
    color: ${({ color }) => color ?? colors.neonGreen};

    & + ${OnboardCheckmarkHider} > ${OnboardCheckmarkIcon}, & ~ ${OnboardCheckmarkIconWhite} {
      display: block;
    }
  }
`

/*
 COMPONENTS
*/

export class OnboardCheckmark<T extends string | string[] | number> extends BasicCheckbox<T> {
  render() {
    return (
      <OnboardCheckboxContainer>
        <OnboardCheckboxStyled
          id={this.props.id}
          type="checkbox"
          value={this.props.value}
          checked={this.state.checked}
          onChange={this.onCheckboxChange}
          className={this.props.className}
          color={this.props.color}
          disabled={this.props.disabled}
        />
        <OnboardCheckmarkHider>
          <OnboardCheckmarkIcon color={this.props.color} />
        </OnboardCheckmarkHider>
        <OnboardCheckmarkIconWhite color={this.props.color} />
      </OnboardCheckboxContainer>
    )
  }
}
