import * as React from "react"
import { svgIconStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgDigitsLogoNoBackground } from "@digits-shared/components/SVGIcons/digits/DigitsLogoNoBackground.svg"
import { SvgDigitsWordmark } from "@digits-shared/components/SVGIcons/digits/DigitsWordmark.svg"
import colors from "@digits-shared/themes/colors"
import { styled } from "styled-components"
import { SIDEBAR_SVG_STYLES } from "src/frontend/components/Shared/NavSidebar/sidebarConstants"
import { SidebarRow } from "src/frontend/components/Shared/NavSidebar/SidebarExpando"
import routes from "src/frontend/routes"

const DigitsLogoSidebarRow = styled(SidebarRow)`
  padding: 0 0 0 12px;
  gap: 8px;
  margin-top: 30px;
  height: 44px;
`

const DigitsLogo = styled(SvgDigitsLogoNoBackground)`
  ${SIDEBAR_SVG_STYLES};
`

const DigitsWordmark = styled(SvgDigitsWordmark)`
  transition: opacity 300ms 200ms;
  min-height: 30px;
  max-height: 30px;
  width: 80px;
  margin-left: -12px;
  padding-top: 5px;
  ${svgIconStyles(colors.secondary)};
`

/**
 * Digits Logo and Wordmark
 * Acts as a button if onClick is provided
 * Links home if onClick is omitted
 */
export const LogoFooter: React.FC<{ onClick?: () => void; className?: string }> = ({
  onClick,
  className,
}) => {
  if (onClick) {
    return (
      <DigitsLogoSidebarRow
        className={className}
        Icon={DigitsLogo}
        rowType="button"
        onClick={onClick}
      >
        <DigitsWordmark className="digits-wordmark" />
      </DigitsLogoSidebarRow>
    )
  }

  return (
    <DigitsLogoSidebarRow className={className} Icon={DigitsLogo} route={routes.root} isExact>
      <DigitsWordmark className="digits-wordmark" />
    </DigitsLogoSidebarRow>
  )
}
