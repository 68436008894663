import * as React from "react"
import { Link } from "react-router-dom"
import { RowContentDescription } from "@digits-shared/components/UI/Table/Content"
import borders from "@digits-shared/themes/borders"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import { SvgLocationIcon } from "src/frontend/components/Shared/Layout/Components/Locations/SvgLocationIcon"
import routes from "src/frontend/routes"
import { type TransactionProps } from "src/shared/components/Transactions/TransactionRow/types"
import useIntervalOrigin from "src/shared/hooks/useIntervalOrigin"
import { useStopClickPropagation } from "src/shared/hooks/useStopClickPropagation"

const LocationName = styled(RowContentDescription)`
  display: inline-block;
  border-radius: ${borders.radius.default}px;
  color: ${colors.rowDescription};
`

const LocationLink = styled(Link)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  gap: 4px;

  svg {
    width: 12px;
    height: 12px;
  }

  &:hover ${LocationName} {
    text-decoration: underline;
  }
`

/*
  COMPONENTS
*/

export const TransactionLocation: React.FC<TransactionProps> = ({ transaction }) => {
  const origin = useIntervalOrigin()
  const stopClickPropagation = useStopClickPropagation()

  const { location } = transaction
  if (!location) return <LocationName />

  return (
    <LocationLink
      to={routes.locationDetails.generateFromCurrentPath({
        ...origin,
        locationId: location.id,
      })}
      onClick={stopClickPropagation}
    >
      <SvgLocationIcon />
      <LocationName>{location.name}</LocationName>
    </LocationLink>
  )
}
