// Kinds of things that can have things dropped on them
export enum TargetKind {
  Drawer = "drawer",
  Row = "row",
  RowGutter = "rowGutter",
  ComponentGutter = "componentGutter",
  Component = "component",
  Config = "config",
}

// Types of things that can be dropped
export enum DropType {
  Component = "component",
  Config = "config",
}
