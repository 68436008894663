import * as React from "react"
import { type QueryResult } from "@apollo/client"

type UsePollingOptions<TData> = {
  query: QueryResult<TData>
  startCondition?: boolean
  startInterval?: number
  maxInterval?: number
  backoffMultiplier?: number
}

export function usePollingWithBackoff<TData>({
  query,
  startCondition = true,
  startInterval = 2e4, // 20 seconds
  maxInterval = 6e5, // 10 minutes
  backoffMultiplier = 1.5, // 20s, 30s, 45s...
}: UsePollingOptions<TData>) {
  const [pollInterval, setPollInterval] = React.useState<number>(startInterval)
  const intervalId = React.useRef<NodeJS.Timeout>()

  const stopPollingInterval = React.useCallback(() => {
    if (intervalId.current) {
      clearInterval(intervalId.current)
    }
    query.stopPolling()
  }, [query])

  React.useEffect(() => {
    if (!startCondition) {
      // reset conditions
      setPollInterval(startInterval)
      stopPollingInterval()
      return
    }

    // Start polling with the initial interval
    query.startPolling(pollInterval)

    intervalId.current = setInterval(() => {
      setPollInterval((prevInterval) => {
        const nextInterval = Math.min(prevInterval * backoffMultiplier, maxInterval)
        query.stopPolling()
        query.startPolling(nextInterval)
        return nextInterval
      })
    }, pollInterval)

    // Cleanup on unmount
    return () => {
      stopPollingInterval()
    }
  }, [
    query,
    pollInterval,
    maxInterval,
    startCondition,
    stopPollingInterval,
    startInterval,
    backoffMultiplier,
  ])

  return {
    pollInterval,
  }
}
