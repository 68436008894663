import * as React from "react"
import useSession from "@digits-shared/hooks/useSession"
import { useAvailableAffiliationEntities } from "src/frontend/components/OS/Applications/AccountantSettings/useAvailableAffiliationEntities"
import type FrontendSession from "src/frontend/session"

export function useCurrentAffiliatedOrganizationIcon() {
  const session = useSession<FrontendSession>()
  const legalEntity = session.currentLegalEntity

  const { data: availableAffiliationEntities } = useAvailableAffiliationEntities()

  return React.useMemo((): string | undefined | null => {
    const found = availableAffiliationEntities.find((a) => a.legalEntity.slug === legalEntity.slug)
    if (!found) return

    return found.organization?.iconUrl
  }, [legalEntity, availableAffiliationEntities])
}
