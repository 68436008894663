/** Returns two arrays given the input array and predicate.
 *
 * The first returned array contains all items for which the predicate returned true.
 *
 * The second contains the rest of the items. */
export function partition<T>(items: T[], predicate: (item: T) => boolean): [T[], T[]] {
  return items.reduce(
    (partitions, item) => {
      const part = predicate(item) ? partitions[0] : partitions[1]
      part.push(item)
      return partitions
    },
    [[] as T[], [] as T[]]
  )
}

/** Returns a first-element indexable array type when the array has at least one element
 *
 * Note: Technically this type could be incorrect if the array is sparse
 */
export function hasFirstElement<T>(elements?: T[] | null): elements is [T] {
  return Boolean(elements && elements.length > 0)
}

/** Returns a first-element indexable array type when the array has exactly one element */
export function hasExactlyOne<T>(elements?: T[]): elements is [T] {
  return Boolean(elements && elements.length === 1)
}

/** Returns a random element from the array */
export function getRandomElement<T>(elements: T[]): T {
  return elements[Math.floor(Math.random() * elements.length)] as T
}

/** Shuffles an array using the Fisher-Yates (Knuth) shuffle algorithm.
 *
 * Returns a shallow copy of the array.
 */
export function shuffle<T>(elements: T[]): T[] {
  const shuffled = [...elements]
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[shuffled[i], shuffled[j]] = [shuffled[j] as T, shuffled[i] as T]
  }
  return shuffled
}

export function areEqual<T>(arr1: T[], arr2: T[]): boolean {
  // Check if arrays have the same length
  if (arr1.length !== arr2.length) {
    return false
  }

  // Compare each element
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false
    }
  }

  return true
}

/** Ensures that the provided argument is an array, wra
 */
export function ensureArray<T>(itemOrItems: T | T[]): T[] {
  if (Array.isArray(itemOrItems)) return itemOrItems
  return [itemOrItems]
}
