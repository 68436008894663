import * as React from "react"
import { Interval } from "@digits-graphql/frontend/graphql-bearer"
import { DigitsButton } from "@digits-shared/DesignSystem/Button"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import { useDateRangeContext } from "src/shared/components/DateRangeSelector/DateRangeContext"

const PresetsContainer = styled.div`
  padding: 23px;
  background: #e3e9f5;
  border-radius: 24px 0 0 24px;
`

const PresetsTitle = styled.div`
  font-size: 22px;
  font-weight: ${fonts.weight.heavy};
  margin-bottom: 25px;
  white-space: nowrap;
`

const Presets = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const PresetsHeader = styled.div`
  font-size: 16px;
  font-weight: ${fonts.weight.heavy};
  text-transform: capitalize;
  margin-bottom: 6px;
`

export const DateRangePresets: React.FC = () => {
  const todayOrigin = dateTimeHelper.todayIntervalOrigin(Interval.Month)
  const quarterOrigin = dateTimeHelper.todayIntervalOrigin(Interval.Quarter)
  const yearOrigin = dateTimeHelper.todayIntervalOrigin(Interval.Year)

  const {
    dateRangeState: { timeRange },
    dateRangeDispatch,
    singleInterval,
  } = useDateRangeContext()

  const { lastMonth, thisMonth, last3, last12, thisQuarter, thisYear, lastYear, last10Years } =
    React.useMemo(
      () => ({
        lastMonth: dateTimeHelper.addIntervalToOrigin(todayOrigin, -1),
        thisMonth: { ...todayOrigin, intervalCount: 1 },
        last3: { ...todayOrigin, intervalCount: 3 },
        last12: { ...todayOrigin, intervalCount: 12 },
        thisQuarter: { ...quarterOrigin, intervalCount: 1 },
        thisYear: yearOrigin,
        lastYear: {
          ...yearOrigin,
          index: yearOrigin.year - 1,
          year: yearOrigin.year - 1,
        },
        last10Years: { ...yearOrigin, intervalCount: 10 },
      }),
      [quarterOrigin, todayOrigin, yearOrigin]
    )

  const {
    isLastMonth,
    isThisMonth,
    isLast3,
    isLast12,
    isThisQuarter,
    isThisYear,
    isLastYear,
    isLast10Years,
  } = React.useMemo(() => {
    const currentOrigin = dateTimeHelper.intervalOriginFromPeriodRange(timeRange)
    return {
      isLastMonth: dateTimeHelper.areIntervalOriginsEqual(lastMonth, currentOrigin),
      isThisMonth: dateTimeHelper.areIntervalOriginsEqual(thisMonth, currentOrigin),
      isLast3: dateTimeHelper.areIntervalOriginsEqual(last3, currentOrigin),
      isLast12: dateTimeHelper.areIntervalOriginsEqual(last12, currentOrigin),
      isThisQuarter: dateTimeHelper.areIntervalOriginsEqual(thisQuarter, currentOrigin),
      isThisYear: dateTimeHelper.areIntervalOriginsEqual(thisYear, currentOrigin),
      isLastYear: dateTimeHelper.areIntervalOriginsEqual(lastYear, currentOrigin),
      isLast10Years: dateTimeHelper.areIntervalOriginsEqual(last10Years, currentOrigin),
    }
  }, [last10Years, last12, last3, lastMonth, lastYear, thisMonth, thisQuarter, thisYear, timeRange])

  const onLastMonth = React.useCallback(() => {
    dateRangeDispatch({ type: "SET_INTERVAL_ORIGIN", origin: lastMonth })
  }, [dateRangeDispatch, lastMonth])

  const onThisMonth = React.useCallback(() => {
    dateRangeDispatch({ type: "SET_INTERVAL_ORIGIN", origin: thisMonth })
  }, [dateRangeDispatch, thisMonth])

  const onLast3 = React.useCallback(() => {
    dateRangeDispatch({ type: "SET_INTERVAL_ORIGIN", origin: last3 })
  }, [dateRangeDispatch, last3])

  const onLast12 = React.useCallback(() => {
    dateRangeDispatch({ type: "SET_INTERVAL_ORIGIN", origin: last12 })
  }, [dateRangeDispatch, last12])

  const onThisQuarter = React.useCallback(() => {
    dateRangeDispatch({ type: "SET_INTERVAL_ORIGIN", origin: thisQuarter })
  }, [dateRangeDispatch, thisQuarter])

  const onThisYear = React.useCallback(() => {
    dateRangeDispatch({ type: "SET_INTERVAL_ORIGIN", origin: thisYear })
  }, [dateRangeDispatch, thisYear])

  const onLastYear = React.useCallback(() => {
    dateRangeDispatch({ type: "SET_INTERVAL_ORIGIN", origin: lastYear })
  }, [dateRangeDispatch, lastYear])

  const onLast10Years = React.useCallback(() => {
    dateRangeDispatch({ type: "SET_INTERVAL_ORIGIN", origin: last10Years })
  }, [dateRangeDispatch, last10Years])

  return (
    <PresetsContainer>
      <PresetsTitle>Time Period</PresetsTitle>
      <Presets>
        <PresetsHeader>Presets</PresetsHeader>
        <Preset $active={isThisMonth} onClick={onThisMonth}>
          Today
        </Preset>
        <Preset $active={isLastMonth} onClick={onLastMonth}>
          Last Month
        </Preset>
        {!singleInterval && (
          <>
            <Preset $active={isLast3} onClick={onLast3}>
              Last 3 Months
            </Preset>
            <Preset $active={isLast12} onClick={onLast12}>
              Last 12 Months
            </Preset>
          </>
        )}
        <Preset $active={isThisQuarter} onClick={onThisQuarter}>
          This Quarter
        </Preset>
        <Preset $active={isThisYear} onClick={onThisYear}>
          This Year
        </Preset>
        <Preset $active={isLastYear} onClick={onLastYear}>
          Last Year
        </Preset>
        {!singleInterval && (
          <Preset $active={isLast10Years} onClick={onLast10Years}>
            Last 10 years
          </Preset>
        )}
      </Presets>
    </PresetsContainer>
  )
}

const Preset: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement> & { $active?: boolean }> = ({
  $active,
  onClick,
  children,
}) => (
  <DigitsButton
    $variant="ghost-dark"
    size="small"
    $active={$active}
    onClick={onClick}
    css={`
      margin-left: -12px;
    `}
  >
    {children}
  </DigitsButton>
)
