import * as React from "react"
import { PartyChartComponent } from "src/frontend/components/Shared/Layout/Components/Charts/PartyChartComponent"
import { type ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { useConfigLookbackOriginOverride } from "src/frontend/components/Shared/Layout/hooks/useConfigOriginOverride"
import {
  type ChartType,
  type MatchedComponent,
  type SizingProps,
} from "src/frontend/components/Shared/Layout/types"
import { usePartySummaryByTimeLiveData } from "src/frontend/components/Shared/Portals/hooks/usePartySummaryByTimeLiveData"

/*
  INTERFACES
*/

interface PartyChartComponentProps extends SizingProps {
  component: MatchedComponent<"partyChart">
  componentSize: ComponentSize
  chartType: ChartType
  skipAnimations: boolean
}

/*
  COMPONENTS
*/

export const PartyPortalComponent: React.FC<PartyChartComponentProps> = ({
  component,
  componentSize,
  chartType,
  height,
  skipAnimations,
}) => {
  const config = useConfigLookbackOriginOverride(component.config.partyChart)

  const { party, dimensionalSummary } = usePartySummaryByTimeLiveData(config, component.config.type)

  return (
    <PartyChartComponent
      party={party}
      summaries={dimensionalSummary?.time}
      origin={config.origin}
      component={component}
      chartType={chartType}
      componentSize={componentSize}
      height={height}
      skipAnimations={skipAnimations}
    />
  )
}
