import * as React from "react"
import { type Statement } from "@digits-graphql/frontend/graphql-bearer"
import { SvgExpand01 } from "@digits-shared/components/SVGIcons/line/Expand01.svg"
import { SvgMinimize01 } from "@digits-shared/components/SVGIcons/line/Minimize01.svg"
import { unique } from "@digits-shared/helpers/filters"
import { useReportOptionsContext } from "src/frontend/components/Shared/Layout/Components/Statements/ReportOptionsContext"
import { useStopClickPropagation } from "src/shared/hooks/useStopClickPropagation"

export function useSectionCollapseToggles(statement: Statement | undefined) {
  const { collapsedSections, setCollapsedSections } = useReportOptionsContext()
  const stopPropagation = useStopClickPropagation()

  const sectionIds = React.useMemo(
    () => new Set(statement?.rows.filter((r) => !!r.sectionTitle).map((r) => r.rowId) ?? []),
    [statement?.rows]
  )
  const areSectionsCollapsed = React.useMemo(
    () =>
      sectionIds.size &&
      Array.from(sectionIds.values()).every((s) => collapsedSections.sections.includes(s)),
    [collapsedSections.sections, sectionIds]
  )

  const parentIds = React.useMemo(
    () => new Set(statement?.rows.filter((r) => !!r.parentCategoryTitle).map((r) => r.rowId) ?? []),
    [statement?.rows]
  )
  const areParentsCollapsed = React.useMemo(
    () =>
      !areSectionsCollapsed &&
      parentIds.size &&
      Array.from(parentIds.values()).every((s) => collapsedSections.sections.includes(s)),
    [areSectionsCollapsed, collapsedSections.sections, parentIds]
  )
  const areAllExpanded = collapsedSections.sections.length === 0

  const onCollapseSections = React.useCallback(
    (e: React.MouseEvent) => {
      stopPropagation(e)
      if (!statement || areSectionsCollapsed) return
      const rowIds = Array.from(sectionIds.values()).filter(unique)
      setCollapsedSections(...rowIds)
    },
    [areSectionsCollapsed, sectionIds, setCollapsedSections, statement, stopPropagation]
  )

  const onCollapseParents = React.useCallback(
    (e: React.MouseEvent) => {
      stopPropagation(e)
      if (!statement) return
      const rowIds = Array.from(parentIds.values()).filter(unique)
      setCollapsedSections(...rowIds)
    },
    [parentIds, setCollapsedSections, statement, stopPropagation]
  )

  const onExpandAll = React.useCallback(
    (e: React.MouseEvent) => {
      stopPropagation(e)
      if (!statement || areAllExpanded) return
      setCollapsedSections()
    },
    [areAllExpanded, setCollapsedSections, statement, stopPropagation]
  )

  return {
    areSectionsCollapsed,
    areAllExpanded,
    areParentsCollapsed,
    onCollapseSections,
    onCollapseParents,
    onExpandAll,
  }
}

export function useCollapseAllSections(statement: Statement | undefined) {
  const { collapsedSections, toggleCollapsedSection } = useReportOptionsContext()

  const sectionIds = React.useMemo(
    () => statement?.rows.filter((r) => !!r.sectionTitle).map((r) => r.rowId) ?? [],
    [statement?.rows]
  )

  const collapseAll = React.useMemo(
    () => !sectionIds?.every((s) => collapsedSections.sections.includes(s)),
    [collapsedSections.sections, sectionIds]
  )

  const label = collapseAll ? "Collapse View" : "Expand View"
  const icon = collapseAll ? SvgMinimize01 : SvgExpand01

  const onClick = React.useCallback(() => {
    if (!statement) return

    const rowIds = statement.rows
      .filter(
        (r) => !!r.sectionTitle && collapseAll !== collapsedSections.sections.includes(r.rowId)
      )
      .map((r) => r.rowId)
    toggleCollapsedSection(...rowIds)
  }, [collapsedSections.sections, collapseAll, statement, toggleCollapsedSection])

  return { label, onClick, icon }
}
