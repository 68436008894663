import * as React from "react"
import { CategoryType, PartyRole, ProductArea } from "@digits-graphql/frontend/graphql-bearer"
import hoistStatics from "hoist-non-react-statics"

export const ProductAreaContext = React.createContext<ProductArea>(ProductArea.None)

/**
 * Hook to retrieve the current context in functional components. If a PartyRole is specified
 * that is not unknown, we will want the product area to be All. This is needed so we can properly
 * aggregate based on the party role. ProductArea.All informs the backend to use the role instead
 * of the product area for summarizing.
 * */
export function useProductArea(partyRole?: PartyRole) {
  const productArea = React.useContext(ProductAreaContext)
  if (!partyRole || partyRole === PartyRole.UnknownRole) return productArea

  return ProductArea.All
}

export function useProductAreaCategoryTypes() {
  const productArea = useProductArea()

  return React.useMemo(() => {
    const map = new Map<CategoryType, boolean>()
    switch (productArea) {
      case ProductArea.Expenses:
        map.set(CategoryType.Expenses, true)
        map.set(CategoryType.OtherExpenses, true)
        map.set(CategoryType.CostOfGoodsSold, true)
        break
      case ProductArea.Revenue:
        map.set(CategoryType.Income, true)
        map.set(CategoryType.OtherIncome, true)
        map.set(CategoryType.CostOfGoodsSold, true)
        break
      case ProductArea.Cash:
        map.set(CategoryType.Assets, true)
        map.set(CategoryType.Liabilities, true)
        break
    }

    return map
  }, [productArea])
}

export interface ProductAreaProps {
  productArea: ProductArea
}

/** Wrap a component to inject the context value as a prop. */
export function withProductAreaContext<P>(Component: React.ComponentType<P & ProductAreaProps>) {
  const BoundComponent = React.forwardRef<{}, React.PropsWithChildren<P>>((props, ref) => (
    <ProductAreaContext.Consumer>
      {(value: ProductArea) => <Component {...(props as P)} ref={ref} productArea={value} />}
    </ProductAreaContext.Consumer>
  ))

  BoundComponent.displayName = `withProductAreaContext(${Component.displayName || Component.name})`

  return hoistStatics(BoundComponent, Component)
}
