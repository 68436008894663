import { themedStyles, themedValue } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"

const neonAmountStyles = themedStyles({
  light: css`
    color: #5cbca1;
  `,
  dark: css`
    color: ${colors.white};
  `,
})

export const NeonAmount = styled.div`
  ${neonAmountStyles};
  display: inline-block;
  font-weight: ${fonts.weight.medium};
  font-size: 13px;
`

export const RightNeonAmount = styled(NeonAmount)`
  text-align: right;
`

const countColor = themedValue({
  light: colors.gray,
  dark: colors.translucentWhite70,
})

export const Count = styled.div`
  color: ${countColor};
  font-size: 11px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`
