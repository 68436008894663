import * as React from "react"
import {
  ReportOptionComparison,
  type Statement,
  type StatementRow,
} from "@digits-graphql/frontend/graphql-bearer"
import { useReportOptionsContext } from "src/frontend/components/Shared/Layout/Components/Statements/ReportOptionsContext"
import { type RowDetails } from "src/frontend/components/Shared/Reports/Report/Components/Statements/toDetailsData"
import { statementRowFiltering } from "src/frontend/components/Shared/Reports/Report/Components/Statements/zeroLineItems"
import { useReportDocumentOptions } from "src/frontend/components/Shared/Reports/Report/hooks/useReportDocumentOptions"

interface CollapsedRows {
  rows: StatementRow[]
  collapsedRowIds: Set<string>
  rowDetails: Map<string, RowDetails>
}

export function useCollapsedRowFiltering(statement?: Statement): CollapsedRows {
  const { collapsedSections } = useReportOptionsContext()
  const options = useReportDocumentOptions()

  const yearOverYearColumns =
    options.deltaYearOverYear &&
    options.deltaYearOverYear !== ReportOptionComparison.InvalidComparison
      ? 1
      : 0

  const monthOverMonthColumns =
    options.deltaMonthOverMonth &&
    options.deltaMonthOverMonth !== ReportOptionComparison.InvalidComparison
      ? Number(options.deltaMonthOverMonthPeriods)
      : 0

  const ytdColumns =
    options.yearToDate && options.yearToDate !== ReportOptionComparison.InvalidComparison
      ? options.deltaYearToDate
        ? 2
        : 1
      : 0

  return React.useMemo(() => {
    const statementRows = statement?.rows ?? []
    const collapsedRowIds = new Set<string>()
    const rowDetails = toRowDetailsMap(statementRows)

    const filteringOptions = { monthOverMonthColumns, yearOverYearColumns, ytdColumns }
    const noEmptyRows = statementRowFiltering(statementRows, filteringOptions)

    const rows = noEmptyRows.map(
      createCollapsedRowsFilter(collapsedSections.sections, collapsedRowIds)
    )

    return { rows, rowDetails, collapsedRowIds }
  }, [
    collapsedSections.sections,
    monthOverMonthColumns,
    statement?.rows,
    yearOverYearColumns,
    ytdColumns,
  ])
}

// Creates a stateful filter function that can exclude collapsed rows and their sub-rows
function createCollapsedRowsFilter(
  collapsedSections: string[],
  collapsedRowIds: Set<string>
): (row: StatementRow) => StatementRow {
  const collapsedIds = new Set(collapsedSections)
  let targetDepth = -1

  return function filterCollapsedSections(row: StatementRow) {
    // Skip all rows we encounter until we return back to our target depth
    if (targetDepth >= 0 && row.depth > targetDepth) {
      collapsedRowIds.add(row.rowId)
      return row
    }

    // When we find our target depth again, that's the row we want to show
    // for the collapsed section.
    if (row.depth === targetDepth) {
      targetDepth = -1
      return row
    }

    // When we see a row that has been marked as collapsed, start skipping rows
    // (including this one), until we hit the target depth again.
    if (collapsedIds.has(row.rowId)) {
      targetDepth = row.depth
      collapsedRowIds.add(row.rowId)
      return row
    }

    return row
  }
}

export const normalizeRowId = (row: StatementRow) =>
  row.rowId?.replace(/Title$/, "").replace(/Summary$/, "")

type RowDetailsMap = Map<string, RowDetails>

function toRowDetailsMap(rows: StatementRow[]) {
  return rows.reduce<RowDetailsMap>((m, r) => {
    const rowId = normalizeRowId(r)

    m.set(rowId, {
      periodDetails: r.details,
      ytdDetails: r.ytdDetails,
      deltas: r.deltas,
    })

    return m
  }, new Map())
}
