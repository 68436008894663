import * as React from "react"
import { ViewType } from "@digits-graphql/frontend/graphql-bearer"
import useSession from "@digits-shared/hooks/useSession"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/useViewVersion"
import { usePortalDispatch } from "src/frontend/components/Shared/Portals/State/portalStore"
import type FrontendSession from "src/frontend/session"

export function useResetPortalPerLE() {
  const { currentLegalEntityId } = useSession<FrontendSession>()
  const portalDispatch = usePortalDispatch()
  const viewVersion = useViewVersion()

  React.useLayoutEffect(
    () => {
      portalDispatch({ type: "reset", viewVersion })
      return () => {
        // Reset on unmount, e.g. log out, exit doppelganger.
        portalDispatch({
          type: "reset",
          viewVersion: {
            legalEntityId: "",
            viewType: ViewType.Ledger,
            viewVersion: "",
          },
        })
      }
    },
    // Don't want to reset every time the view version changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentLegalEntityId, portalDispatch]
  )
}
