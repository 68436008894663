import * as React from "react"
import { svgIconStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgLock01Solid } from "@digits-shared/components/SVGIcons/solid/Lock01Solid.svg"
import { SvgShieldTickSolid } from "@digits-shared/components/SVGIcons/solid/ShieldTickSolid.svg"
import { SvgUsers01Solid } from "@digits-shared/components/SVGIcons/solid/Users01Solid.svg"
import { SvgUserSquareSolid } from "@digits-shared/components/SVGIcons/solid/UserSquareSolid.svg"
import { SvgXCircleSolid } from "@digits-shared/components/SVGIcons/solid/XCircleSolid.svg"
import { PointingDirection } from "@digits-shared/components/UI/Elements/Chevron"
import { type PopUpCoordinates } from "@digits-shared/components/UI/Elements/PopUp/PopUp"
import {
  type PickerBubbleIconProps,
  type PickerBubbleLabelProps,
} from "@digits-shared/components/UI/Picker/Bubble"
import {
  PICKER_INFO_MARGIN_LEFT,
  type PickerPosition,
} from "@digits-shared/components/UI/Picker/constants"
import {
  Picker,
  type PickerMouseInteraction,
  PickerRowContainer,
  type PickerRowContentProps,
} from "@digits-shared/components/UI/Picker/Picker"
import {
  DEFAULT_PICKER_CHEVRON_HEIGHT,
  PICKER_MAX_HEIGHT,
  PickerPopUp,
} from "@digits-shared/components/UI/Picker/PickerPopUp"
import userHelper, { UserRoleFullyQualified } from "@digits-shared/helpers/userHelper"
import useStateBoolean from "@digits-shared/hooks/useStateBoolean"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"
import { type UserRoleOption } from "src/frontend/hooks/useUserRole"

/*
  STYLES
*/

const ROLE_SVG_STYLES = css`
  display: flex;
  flex: 0.2;
  margin: 0 0 0 3px;
  width: 24px;
  height: 24px;
  ${svgIconStyles(colors.secondary)};
`

const AdminIcon = styled(SvgShieldTickSolid)`
  ${ROLE_SVG_STYLES};
`

const ManagerIcon = styled(SvgUsers01Solid)`
  ${ROLE_SVG_STYLES};
`

const AssociateIcon = styled(SvgUserSquareSolid)`
  ${ROLE_SVG_STYLES};
`

const NoAccessIcon = styled(SvgXCircleSolid)`
  ${ROLE_SVG_STYLES};
`

const PadLockSVG = styled(SvgLock01Solid)`
  position: absolute;
  right: 9px;
  width: 15px;
  height: 15px;
  ${svgIconStyles(colors.translucentSecondary70)};
  transform: translate(-0.5px, -0.5px);
`

const RoleName = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${colors.secondary};
  font-weight: ${fonts.weight.medium};
  font-size: 14px;
  transition: color 250ms ease;
`

const RoleDescription = styled.div`
  color: ${colors.secondary};
  font-size: 13px;
  transition: color 250ms ease;
  margin-top: 3px;
`

const LoadingName = styled(RoleName)`
  margin-left: ${PICKER_INFO_MARGIN_LEFT}px;
`

const RoleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  margin-left: ${PICKER_INFO_MARGIN_LEFT}px;
  text-align: left;
`

const RolePickerRowContainer = styled(PickerRowContainer)<PickerMouseInteraction>`
  height: 75px;

  &::before {
    content: "";
    height: 52px;
    width: 4px;
    margin-left: -14px;
    background: ${({ isSelected }) => (isSelected ? colors.primary : colors.transparent)};
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.translucentSecondary10};
  }
`

interface RolePickerPopUpProps {
  className?: string
  width: number
  maxHeight?: number
  chevronDirection?: PointingDirection
  pickerPosition: PickerPosition
  coordinates?: PopUpCoordinates
  roles: UserRoleOption[]
  currentRole?: UserRoleOption
  emptyRole?: UserRoleOption
  isLoading?: boolean
  onRolePick: (role: UserRoleOption) => void
  onClose?: () => void
  // Required to animate the closing of the pop up
  startCloseAnimation?: boolean
  // Fired when the animation of the closing is finished
  onCloseAnimationEnd?: () => void
}

export const RolePickerPopUp: React.FC<RolePickerPopUpProps> = ({
  className,
  width,
  maxHeight = PICKER_MAX_HEIGHT,
  chevronDirection = PointingDirection.None,
  pickerPosition,
  coordinates,
  roles,
  currentRole,
  emptyRole,
  isLoading,
  onRolePick,
  onClose,
  startCloseAnimation,
  onCloseAnimationEnd,
}) => {
  const { value: isDisabled, setTrue: disable } = useStateBoolean(false)
  const disableAndPick = React.useCallback(
    (role: UserRoleOption) => {
      disable()
      onRolePick(role)
    },
    [disable, onRolePick]
  )

  const isCurrentSelection = React.useCallback(
    (role: UserRoleOption) =>
      currentRole?.id === role.id || (!currentRole && role.id === UserRoleFullyQualified.NoAccess),
    [currentRole]
  )

  return (
    <PickerPopUp
      className={className}
      maxHeight={maxHeight}
      width={width}
      chevron={{ size: DEFAULT_PICKER_CHEVRON_HEIGHT, direction: chevronDirection }}
      pickerPosition={pickerPosition}
      onClose={onClose}
      startCloseAnimation={startCloseAnimation}
      coordinates={coordinates}
      isDisabled={isDisabled}
      onCloseAnimationEnd={onCloseAnimationEnd}
    >
      <Picker
        elements={roles}
        emptyElement={emptyRole}
        isCurrentSelection={isCurrentSelection}
        pickerPosition={pickerPosition}
        onPickElement={disableAndPick}
        RowContent={RoleRowContent}
        isLoading={isLoading}
      />
    </PickerPopUp>
  )
}

const RoleRowContent: React.FC<PickerRowContentProps<UserRoleOption>> = ({
  element: role,
  isHovered,
  isSelected,
}) => (
  <RolePickerRowContainer isHovered={isHovered} isSelected={isSelected}>
    <RoleIcon roleId={role.id} />
    <RoleContainer>
      <RoleName>{userHelper.roleDisplayName(role?.id)}</RoleName>
      <RoleDescription>{role?.description}</RoleDescription>
    </RoleContainer>
  </RolePickerRowContainer>
)

export const RoleRowLabel: React.FC<
  PickerBubbleLabelProps<UserRoleOption, { isLocked: boolean }>
> = ({ element: role, isLoading, additionalProps }) => {
  if (isLoading) return <LoadingName>Loading…</LoadingName>

  const isLocked = additionalProps?.isLocked
  return (
    <RoleContainer>
      {isLocked && <PadLockSVG />}
      <RoleName>{userHelper.roleDisplayName(role?.id)}</RoleName>
    </RoleContainer>
  )
}

export const RoleIcon: React.FC<{ roleId?: string }> = ({ roleId }) => {
  switch (roleId) {
    case UserRoleFullyQualified.Admin:
    case UserRoleFullyQualified.AffiliationAdmin:
      return <AdminIcon />
    case UserRoleFullyQualified.AccountManager:
      return <ManagerIcon />
    case UserRoleFullyQualified.Limited:
    case UserRoleFullyQualified.AffiliationAssociate:
      return <AssociateIcon />
    default:
      return <NoAccessIcon />
  }
}

export const PickerBubbleRoleIcon: React.FC<PickerBubbleIconProps<UserRoleOption>> = ({
  element,
}) => <RoleIcon roleId={element?.id} />
