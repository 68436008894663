import React from "react"
import { Keys } from "@digits-shared/components/UI/Elements/Keys"
import useStateBoolean from "@digits-shared/hooks/useStateBoolean"

export function useTypeaheadState(disabled: boolean, onDelete?: () => void) {
  const ref = React.useRef<HTMLInputElement | null>(null)
  const {
    value: isTypeaheadShown,
    toggle: toggleShowTypeahead,
    setTrue: showTypeahead,
    setFalse: hideTypeahead,
  } = useStateBoolean()

  const onClick = React.useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation()
      if (!disabled) {
        toggleShowTypeahead()
      }
    },
    [disabled, toggleShowTypeahead]
  )

  const onKeyDown = React.useCallback(
    (event: React.KeyboardEvent) => {
      event.stopPropagation()
      const { code } = event
      if (!disabled) {
        switch (code) {
          case Keys.Enter:
          case Keys.ArrowDown: {
            toggleShowTypeahead()
            break
          }

          case Keys.Delete: {
            hideTypeahead()
            onDelete?.()
            break
          }
          default: {
            break
          }
        }
      }
    },
    [disabled, toggleShowTypeahead, onDelete, hideTypeahead]
  )

  return React.useMemo(
    () => ({ isTypeaheadShown, showTypeahead, hideTypeahead, onClick, onKeyDown, ref }),
    [onClick, showTypeahead, hideTypeahead, isTypeaheadShown, onKeyDown]
  )
}
