import * as React from "react"
import {
  type ColumnStructure,
  defaultColumnRenderer,
  renderDefaultPaddingCell,
  renderEmptySideColumnExpandedCell,
  TableCell,
} from "@digits-shared/components/UI/Table/Column"
import {
  type HeaderComponent,
  type HeaderProps,
  type HeaderRender,
} from "@digits-shared/components/UI/Table/Header"
import { TableRow } from "@digits-shared/components/UI/Table/Row"
import numberHelper from "@digits-shared/helpers/numberHelper"
import colors from "@digits-shared/themes/colors"
import styled, { css } from "styled-components"

/*
  STYLES
*/

export const TableFooter = styled.tfoot<FooterProps>`
  ${(props) =>
    props.isLoading &&
    css`
      pointer-events: none;
      opacity: 0.3;
    `}
`

export const FooterCell = styled(TableCell)`
  border-top: 1px solid ${colors.theme.dark.border};
  padding: 12px;
  vertical-align: middle;
  text-align: left;
`

/*
  INTERFACES
*/

export type FooterRender = HeaderRender
export type FooterComponent<V> = HeaderComponent<V>
export type FooterRenderer<V> = FooterRender | FooterComponent<V>

interface FooterProps {
  isLoading?: boolean
  addPaddingCells?: boolean | number
  isLeftSideExpandable?: boolean
  isRightSideExpandable?: boolean
  children?: React.ReactNode
}

/*
  COMPONENT
*/

function renderFooter<V>(
  columnName: string,
  footerRenderer: FooterRenderer<V>,
  key?: string | number
) {
  const usingComponent = footerRenderer as FooterComponent<V>
  if (usingComponent.component) {
    const props = usingComponent.props || ({} as V)
    const Footer = usingComponent.component as React.FC<HeaderProps & V>
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Footer columnName={columnName} key={key} {...props} />
  }

  const usingRender = footerRenderer as FooterRender
  return usingRender({ columnName, key })
}

export function defaultFooterRenderer<V>(
  columnName: string,
  key?: string | number,
  footerRenderer?: FooterRenderer<V>,
  colSize?: ColumnStructure
) {
  if (!footerRenderer) {
    return defaultColumnRenderer(columnName, key, colSize)
  }

  return renderFooter(columnName, footerRenderer, key)
}

export const Footer: React.FC<FooterProps> = (props) => {
  const { isLeftSideExpandable, isRightSideExpandable, addPaddingCells, children } = props

  const paddingCellWidth = numberHelper.isNumber(addPaddingCells) ? addPaddingCells : undefined
  return (
    <TableFooter {...props}>
      <TableRow>
        {isLeftSideExpandable && renderEmptySideColumnExpandedCell()}
        {addPaddingCells && renderDefaultPaddingCell("padding-footer1", paddingCellWidth)}
        {children}
        {addPaddingCells && renderDefaultPaddingCell("padding-footer2", paddingCellWidth)}
        {isRightSideExpandable && renderEmptySideColumnExpandedCell()}
      </TableRow>
    </TableFooter>
  )
}
