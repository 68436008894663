import * as React from "react"
import {
  ReadShareDocument,
  type ReadShareQuery,
  ReadUserAclDocument,
  useGrantFromShareMutation,
} from "@digits-graphql/frontend/graphql-bearer"
import { useAccessConstraint } from "src/frontend/hooks/useAccessConstraint"
import { useSharedObjectInfo } from "src/frontend/hooks/useSharedObjectInfo"
import { useSharingIntentId } from "src/frontend/hooks/useSharingIntentId"
import { ObjectSharingStatus } from "src/frontend/session/ObjectSharingStatus"

// Used for creating grants from intents or links.
export function useShareGrantMutation(
  objectSharingStatus: ObjectSharingStatus,
  permissionData?: ReadShareQuery["readShare"]
) {
  const sharedObjectInfo = useSharedObjectInfo()
  const sharingIntentId = useSharingIntentId()
  const constraint = useAccessConstraint()

  const [grantFromShareMutation, { loading, error }] = useGrantFromShareMutation()

  if (
    sharedObjectInfo &&
    permissionData &&
    !loading &&
    !error &&
    objectSharingStatus === ObjectSharingStatus.GrantRequired
  ) {
    grantFromShareMutation({
      variables: {
        object: {
          kind: sharedObjectInfo.kind,
          id: sharedObjectInfo.id,
          legalEntityId: permissionData.legalEntity.id,
        },
        intentId: sharingIntentId,
        constraint,
      },
      awaitRefetchQueries: true,
      refetchQueries: [ReadShareDocument, ReadUserAclDocument],
    }).catch((e) => {
      TrackJS?.console.error(
        "Failed to create grant for: ",
        {
          ...sharedObjectInfo,
          legalEntityId: permissionData.legalEntity.id,
          intentId: sharingIntentId,
        },
        e
      )
    })
  }

  return React.useMemo(() => ({ loading, error }), [loading, error])
}
