import { themedStyles } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import styled, { css } from "styled-components"
import {
  SPRINGBOARD_DETAILS_MAIN_SECTION_WIDTH,
  SPRINGBOARD_DETAILS_NAV_FULL_MARGIN_BOTTOM,
  SPRINGBOARD_DETAILS_NAV_HEIGHT,
  SPRINGBOARD_DETAILS_RIGHT_SIDEBAR_MARGIN_LEFT,
  SPRINGBOARD_DETAILS_RIGHT_SIDEBAR_WIDTH,
} from "src/frontend/components/OS/Details/Shared/Styles/shared"

export const SpringboardDetailsChrome = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

export const SpringboardDetailsCentering = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${SPRINGBOARD_DETAILS_MAIN_SECTION_WIDTH +
  SPRINGBOARD_DETAILS_RIGHT_SIDEBAR_MARGIN_LEFT +
  SPRINGBOARD_DETAILS_RIGHT_SIDEBAR_WIDTH}px;
  height: 100%;
  margin: 0 auto;
  font-size: 13px;
`

export const SpringboardDetailsSectionAndSidebarContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: calc(
    100vh - ${SPRINGBOARD_DETAILS_NAV_HEIGHT + SPRINGBOARD_DETAILS_NAV_FULL_MARGIN_BOTTOM}px
  );
`

const mainSectionStyles = themedStyles({
  dark: undefined,
  light: undefined,
  print: css`
    transition: unset;
    transform: unset;
    height: auto;
    width: 8.5in;
    padding: 0 25px;

    &:after {
      content: unset;
    }
  `,
})

export const SpringboardDetailsMainSection = styled.section`
  position: relative;
  height: 100%;
  min-height: 100%;
  display: flex;
  gap: 15px;
  width: ${SPRINGBOARD_DETAILS_MAIN_SECTION_WIDTH}px;
  color: ${colors.white};

  ${mainSectionStyles};
`

export const SIDEBAR_CLASS_NAME = "sidebar"

export const SIDEBAR_ENTER_DURATION = 700
export const SIDEBAR_EXIT_DURATION = 200

export const SpringboardDetailsRightSidebarContainer = styled.aside`
  position: absolute;
  top: 0;
  right: -150px;
  width: ${SPRINGBOARD_DETAILS_RIGHT_SIDEBAR_WIDTH}px;
  height: 100%;
  overflow: auto;
  color: ${colors.white};

  ${themedStyles({
    dark: undefined,
    light: undefined,
    print: css`
      display: none;
    `,
  })};

  &.${SIDEBAR_CLASS_NAME}-enter {
    opacity: 0;
    transform: translateY(50px);
  }

  &.${SIDEBAR_CLASS_NAME}-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition:
      opacity 200ms ease-out 500ms,
      transform 200ms ease-out 500ms;
  }

  &.${SIDEBAR_CLASS_NAME}-exit {
    opacity: 1;
    transform: translateY(0);
  }

  &.${SIDEBAR_CLASS_NAME}-exit-active {
    opacity: 0;
    transform: translateY(50px);
    transition:
      opacity 200ms ease-in,
      transform 200ms ease-in;
  }
`
