import * as React from "react"
import { LoadingBlock } from "@digits-shared/components/Loaders"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import { DragAndDropOverlay } from "src/frontend/components/Shared/Portals/DragAndDrop/DragAndDropOverlay"
import { usePortalStateInvariants } from "src/frontend/components/Shared/Portals/hooks/usePortalStateInvariants"
import { Section } from "src/frontend/components/Shared/Portals/Layout/Section"
import { usePortalStore } from "src/frontend/components/Shared/Portals/State/portalStore"
import { layoutSectionsSelector } from "src/frontend/components/Shared/Portals/State/selectors"
import { useAdvanceComponentDeleteAnimation } from "src/frontend/components/Shared/Portals/State/useAdvanceComponentDeleteAnimation"

const LayoutWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 1;
`

const LoadingWrapper = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  margin-top: 20px;
`

export const Layout: React.FC = () => {
  const layoutSections = usePortalStore(layoutSectionsSelector)
  const dragOverlayContent = usePortalStore((state) => state.dragOverlayContent)
  const layoutLoading = usePortalStore((state) => state.layoutLoading)

  useAdvanceComponentDeleteAnimation()
  usePortalStateInvariants()

  if (layoutLoading) {
    return (
      <LoadingWrapper>
        {Array.from({ length: 3 }).map((_, idx) => (
          <LoadingBlock
            key={idx}
            display="block"
            width="363px"
            height="296px"
            margin="0 0 20px 0"
            backgroundColor={colors.translucentWhite20}
            $shineColor={colors.translucentWhite40}
          />
        ))}
      </LoadingWrapper>
    )
  }

  if (!layoutSections) return null

  return (
    <>
      <LayoutWrapper>
        {layoutSections.map((section) => (
          <Section key={section.sectionId} section={section} />
        ))}
      </LayoutWrapper>
      <DragAndDropOverlay>{dragOverlayContent}</DragAndDropOverlay>
    </>
  )
}
