import * as React from "react"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgInfoCircle } from "@digits-shared/components/SVGIcons/line/InfoCircle.svg"
import { DigitsTooltip } from "@digits-shared/DesignSystem/Tooltip"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"

/*
  STYLES
*/

const InfoIcon = styled(SvgInfoCircle)`
  ${svgPathStyles(colors.secondary50, 2.5)};
  width: 10px;
  height: 10px;
`

/*
  COMPONENTS
*/

export const DisclaimerTooltip: React.FC = () => (
  <DigitsTooltip
    maxWidth={195}
    content="Balances may have variations since fees, dividends, and interest are not accessible through some bank APIs."
  >
    <InfoIcon />
  </DigitsTooltip>
)
