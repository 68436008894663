import * as React from "react"
import { LocationChartConfig } from "src/frontend/components/Shared/Layout/Components/Locations/LocationChartConfig"
import { type MatchedConfig, type SizingProps } from "src/frontend/components/Shared/Layout/types"
import { useRemoveConfig } from "src/frontend/components/Shared/Portals/ConfigsSidebar/ComponentConfigContext"
import { usePortalStore } from "src/frontend/components/Shared/Portals/State/portalStore"
import { type LocationChartData } from "src/frontend/types"

/*
  INTERFACES
*/

interface ChartConfigProps extends SizingProps {
  config: MatchedConfig<"locationChart">
  skipAnimations: boolean
}

/*
  COMPONENTS
*/
export const LocationPortalChartConfig: React.FC<ChartConfigProps> = ({
  config,
  height,
  skipAnimations,
}) => {
  const removeConfig = useRemoveConfig()
  const layoutId = usePortalStore((state) => state.layout.layoutId)

  const onSummaries = React.useCallback(
    (summaries?: LocationChartData) => {
      if (!summaries?.total?.length) {
        removeConfig()
      }
    },
    [removeConfig]
  )

  return (
    <LocationChartConfig
      config={config}
      height={height}
      skipAnimations={skipAnimations}
      layoutId={layoutId}
      onSummaries={onSummaries}
    />
  )
}
