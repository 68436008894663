import * as React from "react"
import {
  SummaryLineItem,
  type SummaryLineItemConfig,
} from "@digits-graphql/frontend/graphql-bearer"
import urlHelper from "@digits-shared/helpers/urlHelper"
import useRouter from "@digits-shared/hooks/useRouter"
import { useHighlight } from "src/frontend/components/OS/Details/Shared/hooks/useHighlight"
import { BarChartComponent } from "src/frontend/components/Shared/Layout/Components/Charts/BarChartComponent"
import { LineChartComponent } from "src/frontend/components/Shared/Layout/Components/Charts/LineChartComponent"
import { StackedAreaChartComponent } from "src/frontend/components/Shared/Layout/Components/Charts/StackedAreaChartComponent"
import {
  type TimeseriesValue,
  toSortedTimeseriesValues,
} from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"
import { SummaryLineItemComponentTitle } from "src/frontend/components/Shared/Layout/Components/Headers/ComponentEntityIcons"
import { type ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { useConfigLookbackOriginOverride } from "src/frontend/components/Shared/Layout/hooks/useConfigOriginOverride"
import { useComponentDetailsLinkPath } from "src/frontend/components/Shared/Layout/hooks/useEntityDetailsViewPaths"
import {
  type MatchedComponent,
  type SizingProps,
} from "src/frontend/components/Shared/Layout/types"
import { useSummaryLineItemByTimeCategoryLiveData } from "src/frontend/components/Shared/Portals/hooks/useSummaryLineItemByTimeCategoryLiveData"
import { useSummaryLineItemByTimeLiveData } from "src/frontend/components/Shared/Portals/hooks/useSummaryLineItemByTimeLiveData"

/*
  INTERFACES
*/

interface SummaryLineItemChartComponentProps extends SizingProps {
  component: MatchedComponent<"summaryLineItem">
  componentSize: ComponentSize
  skipAnimations: boolean
}

interface SummaryChartComponentProps extends SizingProps {
  component: MatchedComponent<"summaryLineItem">
  config: SummaryLineItemConfig
  componentSize: ComponentSize
  title: React.ReactNode | undefined
  skipAnimations: boolean
  onMouseOver: (value: TimeseriesValue, index: number) => void
  onMouseOut: (value?: TimeseriesValue) => void
  onClick: (value: TimeseriesValue, index: number) => void
  selectedIndex?: number
}

/*
  COMPONENTS
*/

export const SummaryLineItemChartComponent: React.FC<SummaryLineItemChartComponentProps> = ({
  component,
  componentSize,
  height,
  skipAnimations,
}) => {
  const config = useConfigLookbackOriginOverride(component.config.summaryLineItem)
  const { history } = useRouter()
  const metricPath = useComponentDetailsLinkPath(config.item, config.origin)
  const highlight = useHighlight()
  const [selectedIndex, setSelectedIndex] = React.useState(highlight ?? undefined)

  const onMouseOver = React.useCallback((value: TimeseriesValue, index: number) => {
    setSelectedIndex(index)
  }, [])

  const onMouseOut = React.useCallback(
    (value?: TimeseriesValue) => {
      setSelectedIndex(highlight ?? undefined)
    },
    [highlight]
  )

  const onChartClick = React.useCallback(
    (_: TimeseriesValue, index: number) => {
      setSelectedIndex(index)

      if (!metricPath) return
      const path = urlHelper.addSearchParams(metricPath, { highlight: index })
      if (!path) return
      history.push(path)
    },
    [history, metricPath]
  )

  if (!config) {
    return null
  }

  const title = (
    <SummaryLineItemComponentTitle
      item={config.item}
      componentSize={componentSize}
      intervalOrigin={config.origin}
    />
  )

  if (config.item === SummaryLineItem.TotalCash) {
    return (
      <SummaryAreaChartComponent
        component={component}
        config={config}
        componentSize={componentSize}
        title={title}
        height={height}
        skipAnimations={skipAnimations}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        onClick={onChartClick}
        selectedIndex={selectedIndex}
      />
    )
  }

  return (
    <SummaryBarChartComponent
      component={component}
      config={config}
      componentSize={componentSize}
      title={title}
      height={height}
      skipAnimations={skipAnimations}
      onClick={onChartClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      selectedIndex={selectedIndex}
    />
  )
}

const SummaryBarChartComponent: React.FC<SummaryChartComponentProps> = ({
  component,
  config,
  componentSize,
  title,
  height,
  skipAnimations,
  onClick,
  onMouseOver,
  onMouseOut,
  selectedIndex,
}) => {
  const { dimensionalSummary } = useSummaryLineItemByTimeLiveData(config, component.config.type)
  const timeseries = React.useMemo(
    () => toSortedTimeseriesValues(dimensionalSummary?.time),
    [dimensionalSummary?.time]
  )

  if (config.item === SummaryLineItem.TotalCash) {
    return (
      <LineChartComponent
        componentSize={componentSize}
        title={title}
        icon={undefined}
        timeseries={timeseries}
        height={height}
        skipAnimations={skipAnimations}
        onClick={onClick}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        selectedIndex={selectedIndex}
      />
    )
  }

  return (
    <BarChartComponent
      componentSize={componentSize}
      title={title}
      icon={undefined}
      timeseries={timeseries}
      height={height}
      skipAnimations={skipAnimations}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      selectedIndex={selectedIndex}
    />
  )
}

const SummaryAreaChartComponent: React.FC<SummaryChartComponentProps> = ({
  component,
  config,
  componentSize,
  title,
  height,
  skipAnimations,
  onMouseOver,
  onMouseOut,
  onClick,
  selectedIndex,
}) => {
  const { timeseries, breakdownTimeseries } = useSummaryLineItemByTimeCategoryLiveData(
    config,
    component.config.type
  )

  return (
    <StackedAreaChartComponent
      componentSize={componentSize}
      title={title}
      timeseries={timeseries}
      breakdownTimeseries={breakdownTimeseries}
      height={height}
      skipAnimations={skipAnimations}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      selectedIndex={selectedIndex}
    />
  )
}
