import * as React from "react"
import styled from "styled-components"

/*
 STYLES
*/

const CheckboxInput = styled.input`
  cursor: pointer;
`

/*
 COMPONENTS
*/

interface CheckboxProps<T> {
  id?: string
  checked?: boolean
  value: T
  name?: string
  type?: "checkbox" | "radio"
  onCheckboxChange?: (checked: boolean, event: React.ChangeEvent<HTMLInputElement>) => void
  className?: string
  color?: string
  disabled?: boolean
}

interface CheckboxState {
  checked: boolean
}

export class BasicCheckbox<T extends string | string[] | number> extends React.Component<
  CheckboxProps<T>,
  CheckboxState
> {
  private checkboxRef = React.createRef<HTMLInputElement>()

  state = { checked: this.props.checked || false }

  componentDidUpdate(prevProps: CheckboxProps<T>) {
    this.shouldBeChecked(prevProps)
  }

  render() {
    return (
      <CheckboxInput
        ref={this.checkboxRef}
        name={this.props.name}
        type={this.props.type || "checkbox"}
        value={this.props.value}
        checked={this.state.checked}
        onChange={this.onCheckboxChange}
        className={this.props.className}
      />
    )
  }

  shouldBeChecked(prevProps: CheckboxProps<T>) {
    if (this.props.checked !== prevProps.checked) {
      this.setState({ checked: this.props.checked || false })
    }
  }

  get checked() {
    return this.state.checked
  }

  set checked(checked: boolean) {
    this.setState({ checked })
  }

  get value() {
    return this.checked ? this.props.value : null
  }

  simulateClick() {
    this.checkboxRef.current?.click()
  }

  onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ checked: event.currentTarget.checked })

    if (!this.props.onCheckboxChange) return
    this.props.onCheckboxChange(event.currentTarget.checked, event)
  }
}
