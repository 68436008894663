import * as React from "react"
import { ChartContainer } from "src/frontend/components/Shared/Layout/Components/Charts/shared"
import { ParentSizedTimeseriesBarChart } from "src/frontend/components/Shared/Layout/Components/Charts/TimeseriesBarChart"
import { type TimeseriesValues } from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"
import { ComponentSummary } from "src/frontend/components/Shared/Layout/Components/Headers/ComponentSummary"
import { ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { type SizingProps } from "src/frontend/components/Shared/Layout/types"

/*
  INTERFACES
*/

interface BarChartConfigProps extends SizingProps {
  name: React.ReactNode | undefined
  timeseries: TimeseriesValues
  skipAnimations: boolean
  icon?: React.ReactNode
}
/*
  STYLES
*/

/*
  COMPONENTS
*/

export const BarChartConfig = React.memo<BarChartConfigProps>(
  ({ name, timeseries, height, skipAnimations, icon }) => (
    <>
      <ComponentSummary
        componentSize={ComponentSize.Small}
        title={name}
        timeseries={timeseries}
        icon={icon}
      />
      <ChartContainer height={height} width="auto">
        <ParentSizedTimeseriesBarChart
          timeseries={timeseries}
          skipAnimations={skipAnimations}
          hideGrid
          hideAxis
        />
      </ChartContainer>
    </>
  )
)
