import colors from "@digits-shared/themes/colors"
import { css } from "styled-components"

export const TRANSACTION_REVIEW_MESSAGE =
  "I chose Digits for us because it is simple, easy, & painless. Here's how it works.\r\n" +
  "Click on the button below.\r\n" +
  "Answer a few questions.\r\n" +
  "Get back to your day!"

export const CONTAINER_STYLES = css`
  background: ${colors.translucentWhite40};
  border-radius: 10px;
  border: 0.5px solid ${colors.translucentWhite04};
  margin-bottom: 18px;
  display: flex;
  flex-direction: column;

  transition: background-color 200ms ease-in-out;
`
