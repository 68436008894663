import { notNull } from "@digits-shared/helpers/filters"
import stringHelper from "@digits-shared/helpers/stringHelper"

const DRIVERS_MARKERS = [" Notable changes include ", "driven by "]
const DRIVERS_REGEX = /(.+?[^<]+?<[^|>]+\|[^>]+>)/g

export function insightDriversParser(insightText: string, legalEntityNameReplacement?: string) {
  let sentence = insightText
  if (legalEntityNameReplacement) {
    sentence = sentence.replace(/^you /i, `<b>${legalEntityNameReplacement}</b> `)
  }

  const driversMarker = DRIVERS_MARKERS.find((marker) => sentence.includes(marker))
  if (!driversMarker) {
    return sentence
  }

  const sentenceWithParts = sentence.split(driversMarker)
  const driversSentences = sentenceWithParts[1]?.match(DRIVERS_REGEX)?.filter(notNull)
  if (!driversSentences?.length) {
    return sentenceWithParts.join(driversMarker)
  }

  const drivers = `<ol>${driversSentences
    .map((part) => part.replace(/^, /, ""))
    .map((part) => part.replace(/^ and /, " "))
    .map((part) => `<li>${part.trim()}</li>`)
    .join("")}</ol>`

  return [sentenceWithParts[0]?.replace(/ , $/, ":") || "", drivers]
    .join(`<br/>${stringHelper.capitalize(driversMarker.trim())}:<br/>`)
    .replace(/ , /g, ", ")
}
