import * as React from "react"
import { SvgXClose } from "@digits-shared/components/SVGIcons/line/XClose.svg"
import { type ButtonSize, DigitsButton } from "@digits-shared/DesignSystem/Button"
import colors from "@digits-shared/themes/colors"
import * as PopoverPrimitive from "@radix-ui/react-popover"
import styled from "styled-components"

const StyledPopoverContent = styled(PopoverPrimitive.Content)`
  position: relative;
  background: ${colors.white};
  padding: 8px;
  border-radius: 8px;
  z-index: 10;
  box-shadow: 0 0 12px 0 ${colors.translucentSecondary20};
`

const CloseButton = styled(PopoverPrimitive.Close)`
  position: absolute;
  top: 4px;
  right: 4px;
`

export interface PopoverContentProps extends PopoverPrimitive.PopoverContentProps {
  showCloseButton?: boolean
  closeButtonSize?: ButtonSize
  className?: string
}

/**
 * EXAMPLE USAGE:
 * <Popover>
 *   <PopoverTrigger asChild>
 *     -- place trigger component here--
 *   </PopoverTrigger>
 *   <PopoverContent>
 *     -- place content here--
 *   </PopoverContent>
 * </Popover>
 */

export const Popover = PopoverPrimitive.Root
export const PopoverTrigger = PopoverPrimitive.Trigger
export const PopoverAnchor = PopoverPrimitive.Anchor
export const PopoverContent = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<PopoverContentProps>
>(
  (
    {
      children,
      className,
      showCloseButton = true,
      closeButtonSize = "small",
      sideOffset = 4,
      ...rest
    },
    forwardedRef
  ) => (
    <PopoverPrimitive.Portal>
      <StyledPopoverContent
        className={className}
        sideOffset={sideOffset}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        ref={forwardedRef}
      >
        {showCloseButton && (
          <CloseButton asChild>
            <DigitsButton $circle size={closeButtonSize} $variant="ghost-dark">
              <SvgXClose />
            </DigitsButton>
          </CloseButton>
        )}
        {children}
      </StyledPopoverContent>
    </PopoverPrimitive.Portal>
  )
)
