import { AnimatedIcon } from "@digits-shared/components/UI/Icons/AnimatedIcon"
import styled, { keyframes } from "styled-components"

/*
 STYLES
*/

export const COMPONENT_TRANSITION_DURATION = 250
export const COMPONENT_POOF_DURATION = 1000

export const DELETE_COMPONENT_SHRINK_KEYFRAMES = keyframes`
  0% {
    opacity: 1;
    transform: scale(1);
  }
  
  100% {
    opacity: 0;
    transform: scale(0);
  }
`

export const POOF_ANIMATION_KEYFRAMES = keyframes`
  0% {
    background-position: 0 top;
    opacity: 0;
  }
  1% {
    opacity: 1;
  }
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    background-position: 100% top;
  }
`

export const AnimatedPoof = styled(AnimatedIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 150px;
  animation: ${POOF_ANIMATION_KEYFRAMES} ${COMPONENT_POOF_DURATION}ms linear both;
  animation-timing-function: steps(21);
  animation-iteration-count: 1;
  flex-shrink: 0;
`

export const poofSpriteURL = require("static/images/shared/poofSprite.png")

export const TRASH_CAN_RATTLE_KEYFRAMES = keyframes`
  0% {
   transform: translate3d(0, 0, 0); 
  }
 
 25% {
   transform: translate3d(-3px, 1px, 0) rotate(-1deg);
 }
 
 50% {
   transform: translate3d(0, 0, 0);
 }
 
 75% {
  transform: translate3d(2px, 1px, 0) rotate(1deg);
 }
 
 100% {
  transform: translate3d(0, 0, 0); 
 }
`
