import * as React from "react"

export const TransactionFeedContext = React.createContext<boolean | undefined>(undefined)

/** Hook to retrieve the current context in functional components. Currently used to only display certain things in
 * the transaction feed which is an internal tool to see a realtime, raw feed of a customer's transactions. We may roll
 * this into the TransactionListContext if we want to expose this feed to customers in the future. */
export default function useTransactionFeed() {
  return React.useContext(TransactionFeedContext)
}
