import { type DocumentType } from "@digits-graphql/frontend/graphql-bearer"
import { FILTERABLE_DOCUMENT_TYPES } from "src/frontend/components/OS/Applications/Vault/VaultBrowser/useVaultFilters"

function isDocumentType(documentType: string): documentType is DocumentType {
  return (FILTERABLE_DOCUMENT_TYPES as string[]).includes(documentType)
}

export function makeValidDocumentTypes(documentType: string | null) {
  if (documentType && isDocumentType(documentType)) {
    return [documentType]
  }
  return undefined
}
