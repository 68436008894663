import * as React from "react"
import { useHasPortalQuery } from "@digits-graphql/frontend/graphql-bearer"
import useSession from "@digits-shared/hooks/useSession"
import { JWTPermissionFlag } from "@digits-shared/session/jwt/jwtPermissions"
import { useHasPermission } from "src/frontend/components/Shared/Permissions/Requires"
import type FrontendSession from "src/frontend/session"
import {
  FrontendPermissionModule,
  FrontendPermissionSource,
} from "src/frontend/session/permissionModule"

/**
 * Returns whether the current legal entity has a client portal.
 *
 * Note: Will return undefined while a query is in-progress, or if the current user does not
 *       have permission to read portal information.
 */
export function useHasPortal() {
  const { currentLegalEntityId: legalEntityId, currentLegalEntity: legalEntity } =
    useSession<FrontendSession>()

  const canReadPortal = useHasPermission({
    source: FrontendPermissionSource.LegalEntity,
    module: FrontendPermissionModule.Portals,
    flag: JWTPermissionFlag.Read,
  })

  const { data, loading } = useHasPortalQuery({
    variables: {
      legalEntityId,
    },
    // do not batch with other slow queries, this speeds up home loading times
    context: { noBatch: true },
    skip: !canReadPortal || !legalEntity.hasDashboardAccess(),
  })

  return React.useMemo(
    () => ({
      hasPortal: data?.hasPortal,
      canReadPortal,
      loading,
    }),
    [canReadPortal, data?.hasPortal, loading]
  )
}
