import * as React from "react"
import useSession from "@digits-shared/hooks/useSession"
import type FrontendSession from "src/frontend/session"
import type SessionAffiliationOrganization from "src/frontend/session/AffiliationOrganization"
import type SessionLegalEntity from "src/frontend/session/LegalEntity"

export interface AffiliationEntity {
  organization: SessionAffiliationOrganization
  legalEntity: SessionLegalEntity
}

export function useAffiliationEntities(orgId?: string) {
  const session = useSession<FrontendSession>()

  const affiliations = orgId
    ? session.findOrganizationById(orgId)?.affiliations
    : session.affiliations

  return React.useMemo(() => {
    const affiliationEntities: AffiliationEntity[] =
      affiliations?.map(({ entity, organization }) => ({
        organization,
        legalEntity: entity,
      })) ?? []

    return {
      affiliationEntities,
    }
  }, [affiliations])
}
