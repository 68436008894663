import * as React from "react"
import { MODULE_FROM_ROUTE_REG_EXP } from "@digits-shared/components/Router/DigitsRoute"
import useRouter from "@digits-shared/hooks/useRouter"
import {
  ModuleNameContext,
  UNKNOWN_MODULE_NAME,
} from "src/frontend/components/Shared/Contexts/ModuleNameContext"

export const ModuleNameProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { location } = useRouter()
  const moduleNameContext = React.useMemo(
    () => moduleFromPathname(location.pathname),
    [location.pathname]
  )

  return (
    <ModuleNameContext.Provider value={moduleNameContext}>{children}</ModuleNameContext.Provider>
  )
}

// A module is considered a product area + the section of that area being view (e.g. expenses)
function moduleFromPathname(pathname: string): string {
  const currentModuleMatcher = pathname.match(MODULE_FROM_ROUTE_REG_EXP)

  return currentModuleMatcher
    ? `${currentModuleMatcher[1] || UNKNOWN_MODULE_NAME}`
    : UNKNOWN_MODULE_NAME
}
