import * as React from "react"
import { AnimatedIcon } from "@digits-shared/components/UI/Icons/AnimatedIcon"
import styled from "styled-components"

export const AnimatedSatellite = styled(AnimatedIcon)`
  width: 350px;
  height: 350px;
  animation-duration: 5s;
  animation-timing-function: steps(119);
  flex-shrink: 0;
  margin: 0 auto;
`

export const SatelliteSprite: React.FC<{ className?: string }> = ({ className }) => (
  <AnimatedSatellite spriteURL={require("static/images/shared/satellite_sprite.png")} />
)
