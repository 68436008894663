import * as React from "react"
import { type ApolloError } from "@apollo/client"
import { type ApolloCache } from "@apollo/client/cache"
import {
  type EntityLegalEntity,
  type UpdateNotificationStatusMutation,
  useUpdateNotificationStatusMutation,
  type WebNotification,
  WebNotificationFieldsFragmentDoc,
  WebNotificationStatus,
} from "@digits-graphql/frontend/graphql-bearer"
import { svgIconStyles } from "@digits-shared/components/SVG/svgIconStyles"
import colors from "@digits-shared/themes/colors"
import styled, { css } from "styled-components"
import { LegalEntityTitle } from "src/frontend/components/Shared/Alerts/LegalEntityTitle"
import ActivityItem, {
  EmptyActivityItem,
} from "src/frontend/components/Shared/Alerts/Notifications/ActivityItem"
import { useNotifications } from "src/frontend/components/Shared/Alerts/Notifications/useNotifications"
import { useAccessibleLegalEntitiesById } from "src/frontend/components/Shared/Alerts/useAccessibleLegalEntitiesById"
import {
  type SystemWebNotification,
  type SystemWebNotificationType,
} from "src/frontend/types/SystemWebNotification"
import { DrawerSection, SidebarSectionLoading } from "src/shared/components/Drawer"
import { SVGIconComponent } from "src/shared/components/Icons/SVGIcon"

/*
  STYLES
*/

const NotificationIcon = styled.img`
  display: inline;
  width: 32px;
  height: 32px;
  border-radius: 50%;
`

const WebSystemActivityIconStyled = styled.img`
  border-radius: 50%;
  width: 32px;
  height: 32px;
  padding: 0;
`

const SystemActivityMessage = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 14px;
`

const SVG_STYLES = css<{ type?: SystemWebNotificationType }>`
  width: 18px;
  height: 18px;

  ${({ type }) => {
    switch (type) {
      case "warning":
        return css`
          ${svgIconStyles(colors.error)};
        `
      case "success":
        return css`
          ${svgIconStyles(colors.accentGreen)};
        `
      case "info":
      case "ai":
      default:
        return css`
          ${svgIconStyles(colors.white)};
        `
    }
  }};
`

const NotificationSVGIcon = styled(SVGIconComponent)<{ type?: SystemWebNotificationType }>`
  ${SVG_STYLES};
`

/*
  COMPONENTS
*/

export const WebNotifications: React.FC = () => {
  const { notificationResult, notificationEntries } = useNotifications()
  const accessibleLegalEntities = useAccessibleLegalEntitiesById(true)

  const noTitle = accessibleLegalEntities.size === 1

  return (
    <DrawerSection>
      {notificationResult?.loading || notificationResult?.error || !notificationEntries.length ? (
        <WebNotificationsList
          loading={notificationResult?.loading}
          noTitle={noTitle}
          error={notificationResult?.error}
        />
      ) : (
        notificationEntries.map((entry) => (
          <WebNotificationsList
            key={entry[0]}
            loading={false}
            notifications={entry[1]}
            legalEntity={accessibleLegalEntities.get(entry[0])}
            noTitle={noTitle}
            error={notificationResult?.error}
          />
        ))
      )}
    </DrawerSection>
  )
}

const WebNotificationsList: React.FC<{
  notifications?: WebNotification[]
  loading?: boolean
  error?: ApolloError
  noTitle?: boolean
  legalEntity?: EntityLegalEntity
}> = ({ notifications, loading, error, noTitle, legalEntity }) => {
  const [dismiss] = useUpdateNotificationStatusMutation()

  const generateOnCloseClick = React.useCallback(
    (notification: WebNotification) => () =>
      dismiss({
        variables: {
          id: notification.id,
          status: WebNotificationStatus.Dismissed,
        },
        update: updateStatusInCache.bind(null, notification, WebNotificationStatus.Dismissed),
      }),
    [dismiss]
  )

  if (loading) return <SidebarSectionLoading widths={[55, 85, 65, 77]} />
  if (error || !notifications?.length) return <EmptyActivityItem />

  return (
    <>
      {!noTitle && legalEntity && <LegalEntityTitle legalEntity={legalEntity} />}
      {notifications.map((notification) => (
        <WebNotificationItem
          key={notification.id}
          notification={notification}
          onCloseClick={generateOnCloseClick(notification)}
        />
      ))}
    </>
  )
}

export const WebNotificationItem: React.FC<{
  notification: WebNotification | SystemWebNotification
  onClick?: () => void
  onCloseClick: () => void
  className?: string
}> = ({ notification, onClick, onCloseClick, className }) =>
  isWebNotification(notification) ? (
    <WebActivity
      notification={notification}
      onClick={onClick}
      className={className}
      onCloseClick={onCloseClick}
    />
  ) : (
    <WebSystemActivity
      notification={notification}
      onClick={onClick}
      className={className}
      onCloseClick={onCloseClick}
    />
  )

export function isWebNotification(
  notification: WebNotification | SystemWebNotification
): notification is WebNotification {
  return (notification as WebNotification).legalEntityId !== undefined
}

const WebActivity: React.FC<{
  notification: WebNotification
  onClick?: () => void
  onCloseClick: () => void
  className?: string
}> = ({ notification, onClick, onCloseClick, className }) => {
  const [markRead] = useUpdateNotificationStatusMutation()

  const onClickCallback = React.useCallback(() => {
    markRead({
      variables: {
        id: notification.id,
        status: WebNotificationStatus.Read,
      },
      update: updateStatusInCache.bind(null, notification, WebNotificationStatus.Read),
    })
    onClick?.()
  }, [markRead, notification, onClick])

  return (
    <ActivityItem
      className={className}
      title={notification.message}
      icon={<WebActivityIcon notification={notification} />}
      onClick={onClickCallback}
      onCloseClick={onCloseClick}
      toLink={notification.targetAction}
      occurredAt={notification.createdAt}
      isRead={notification.status === WebNotificationStatus.Read}
    />
  )
}

const WebSystemActivity: React.FC<{
  notification: SystemWebNotification
  onClick?: () => void
  onCloseClick: () => void
  className?: string
}> = ({ notification, onClick, onCloseClick, className }) => {
  const onClickCallback = React.useCallback(() => {
    notification.onClick?.()
    onClick?.()
  }, [notification, onClick])
  const onCloseClickCallback = React.useCallback(() => {
    notification?.onCloseClick?.()
    onCloseClick()
  }, [notification, onCloseClick])
  const { assetLink, SVGComponent, springboardApplication } = notification

  return (
    <ActivityItem
      className={className}
      title={<SystemActivityMessage>{notification.message}</SystemActivityMessage>}
      subtitle={notification.description}
      icon={
        (assetLink && <WebSystemActivityIconStyled src={assetLink} />) ||
        (SVGComponent && (
          <SVGComponentStyled
            css={SVG_STYLES}
            type={notification.type}
            SVGComponent={SVGComponent}
          />
        )) ||
        (springboardApplication && springboardApplication.iconURL && (
          <WebSystemActivityIconStyled src={springboardApplication.iconURL} />
        )) || <NotificationSVGIcon subjectDisplayKey={notification.id} type={notification.type} />
      }
      onClick={onClickCallback}
      onCloseClick={onCloseClickCallback}
      toLink={notification.targetAction}
      showCloseButton={!notification.autoDismiss}
      buttonText={notification.buttonText}
      type={notification.type}
    />
  )
}

const WebActivityIcon: React.FC<{ notification: WebNotification }> = ({ notification }) => {
  const { assetLink, displayKey } = notification

  return assetLink ? (
    <NotificationIcon src={assetLink} />
  ) : (
    <NotificationSVGIcon subjectDisplayKey={displayKey} />
  )
}

const SVGComponentStyled: React.FC<{
  SVGComponent: React.ComponentType<React.SVGProps<SVGSVGElement>>
  className?: string
  type?: SystemWebNotificationType
}> = ({ SVGComponent, className, type }) => <SVGComponent type={type} className={className} />

const updateStatusInCache = (
  notification: WebNotification,
  newStatus: WebNotificationStatus,
  store: ApolloCache<UpdateNotificationStatusMutation>
) => {
  const cachedNotification = store.readFragment<WebNotification>({
    id: store.identify(notification),
    fragment: WebNotificationFieldsFragmentDoc,
    fragmentName: "WebNotificationFields",
  })

  if (!cachedNotification) return

  store.writeFragment({
    id: store.identify(notification),
    fragment: WebNotificationFieldsFragmentDoc,
    fragmentName: "WebNotificationFields",
    data: { ...cachedNotification, status: newStatus },
  })
}
