import * as React from "react"
import {
  LayoutComponentType,
  type RunwayChartConfig,
  useReadRunwayLiveDataQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/useViewVersion"
import { type Runway } from "src/frontend/types"

interface RunwayResponse {
  runway?: Runway
  loading: boolean
}

export function useRunwayLiveData(config: RunwayChartConfig | undefined): RunwayResponse {
  const viewId = useViewVersion()

  const { data, loading } = useReadRunwayLiveDataQuery({
    variables: {
      viewId,
      config: {
        type: LayoutComponentType.RunwayChart,
        runwayChart: config,
      },
    },
    skip: !viewId?.viewVersion || !config,
  })

  return React.useMemo(() => {
    const liveData = data?.liveComponentData

    // Resolves the component data union types to the portions we care about
    if (liveData?.__typename === "Runway") {
      const { current, future, cashOutDate, previousCashOutDate } = liveData
      const runway = {
        current: current.time?.map((t) => t.summary),
        future: future.time?.map((t) => t.summary),
        cashOutDate,
        previousCashOutDate,
      }
      return { runway, loading }
    }
    return { loading }
  }, [data?.liveComponentData, loading])
}
