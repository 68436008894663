import { IconShape } from "@digits-shared/components/UI/Icons/Icon"
import styled from "styled-components"

interface VibrantIconValues {
  textColor?: string
  isLoading?: boolean
  iconShape?: IconShape
}

/*
  STYLES
*/

const iconPropsToStyle = (props: VibrantIconValues) => {
  const border = props.isLoading ? undefined : `1px solid ${props.textColor}`
  /* fix for the transparent icons */
  const backgroundColor = props.isLoading ? undefined : props.textColor
  const borderRadius = props.iconShape === IconShape.Square ? "3px" : "50%"

  return {
    style: {
      border,
      backgroundColor,
      borderRadius,
    },
  }
}

export const VibrantIcon = styled.img.attrs<VibrantIconValues>(iconPropsToStyle)`
  height: inherit;
  width: inherit;
`
