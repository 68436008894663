import * as React from "react"
import { ArrivalContext } from "@digits-graphql/frontend/graphql-bearer"
import { DigitsButton } from "@digits-shared/DesignSystem/Button"
import stringHelper from "@digits-shared/helpers/stringHelper"
import useSession from "@digits-shared/hooks/useSession"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import { LogoRow } from "src/frontend/components/OS/Applications/ClientPortal/InviteFlow/Content/LogoRow"
import { MODAL_RADIUS } from "src/frontend/components/OS/Applications/ClientPortal/InviteFlow/shared"
import {
  ClientStatus,
  type InviteClientState,
} from "src/frontend/components/OS/Applications/ClientPortal/InviteFlow/State/types"
import type FrontendSession from "src/frontend/session"

/*
  STYLES
 */
const Wrapper = styled.div`
  padding: 80px 52px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: ${MODAL_RADIUS}px;
  height: 100%;
  background: linear-gradient(177.24deg, #6b92d5 0.5%, #6cb1cb 100%);

  & img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 75%;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  gap: 8px;
  color: ${colors.white};
`

export const LegalEntityName = styled.div`
  color: ${colors.white};
  font-weight: ${fonts.weight.heavy};
  font-size: 14px;
  margin-bottom: 12px;
`

export const Headline = styled.h2`
  font-size: 24px;
  font-weight: ${fonts.weight.heavy};
  color: ${colors.white};
`

const Body = styled.p`
  font-size: 14px;
`

/*
  COMPONENTS
 */

export const Done: React.FC<{
  className?: string
  state: InviteClientState
  onDone: () => void
}> = ({ className, state, onDone }) => {
  const { currentLegalEntity } = useSession<FrontendSession>()
  const { userNoun, userPluralNoun } = state
  const sentCount = React.useMemo(
    () => state.invitees.filter((i) => i.status === ClientStatus.Sent).length,
    [state.invitees]
  )

  const context = React.useMemo(() => {
    switch (state.arrivalContext) {
      case ArrivalContext.ReportsBuilder:
        return {
          headline: "Report shared!",
          button: "Back to Report",
          imageSrc: require("static/images/clientportal/client-portal-report-stylized.png"),
        }
      default:
        return {
          headline: `${stringHelper.pluralize(sentCount, "Invite", "Invites")} sent!`,
          button: "Back to Dashboard",
          imageSrc: require("static/images/clientportal/client-portal-stylized.png"),
        }
    }
  }, [sentCount, state.arrivalContext])

  return (
    <Wrapper className={className}>
      <img alt="line art of a dashboard with charts" src={context.imageSrc} />

      <TextWrapper>
        <LogoRow size={36} />
        <LegalEntityName>{currentLegalEntity.name}</LegalEntityName>
        <Headline>{context.headline}</Headline>
        <Body>
          Your{" "}
          {stringHelper.pluralize(
            sentCount,
            `${userNoun} was sent a secure link `,
            `${userPluralNoun} were sent secure links `
          )}
          with full access to Digits.
        </Body>
      </TextWrapper>

      <DigitsButton $fullWidth onClick={onDone}>
        {context.button}
      </DigitsButton>
    </Wrapper>
  )
}
