import * as React from "react"
import { LoadingBlock } from "@digits-shared/components/Loaders/Blocks"
import colors from "@digits-shared/themes/colors"
import styled, { css } from "styled-components"

/*
  STYLES
*/
export const PaddingCellDefaultWidth = 25

export const PaddingCell = styled.td<{ width?: number }>`
  padding: 5px;
  width: ${({ width }) => width || PaddingCellDefaultWidth}px;
`

export const SideExpandedCell = styled.td`
  position: relative;
  z-index: 1;
  opacity: 0;
  width: 0;
  padding: 0;
`

const SIDE_EXPANDED_CELL_STYLES = css`
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: ${colors.white};
`

export const LeftSideExpandedCell = styled.div`
  ${SIDE_EXPANDED_CELL_STYLES};
  right: -1px;
`

export const RightSideExpandedCell = styled.div`
  ${SIDE_EXPANDED_CELL_STYLES};
  left: -1px;
`

// Needed for changing on hover. `SIDE_EXPANDED_CELL_STYLES` needs to be a constant white
// background, otherwise changing it with the table hover background which has an alpha makes
// it see through.
export const SideExpandedCellBackground = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${colors.white};
`

export const ExpandedRowCell = styled.td`
  padding: 10px 20px;
`

export const TableCell = styled.td<{ size?: ColumnStructure }>`
  padding: 12px 0; /* NO PADDING ON THE SIDES */
  vertical-align: middle;
  text-align: ${(props) => props.size?.textAlign || "left"};
`

export const LoadingCell = styled(LoadingBlock)`
  vertical-align: top;
`

/*
  TYPES
*/

export interface ColumnStructure {
  width: number | string
  height: number | string
  textAlign?: "left" | "right" | "center"
}

export type ColumnRenderer<T> = (data: T, rowIndex: number, colIndex: number) => React.ReactNode

export type ColumnComponentProps<T, OptProps extends object = {}> = OptProps & {
  data: T | undefined
  list: T[]
  loading: boolean

  // (Optional props)
  rowIndex?: number
  colIndex?: number
}

export type ColumnComponent<T, U> = React.FC<ColumnComponentProps<T> & U>

export interface ColumnUsingRender<T> {
  // Function which returns contents of that column cell
  render: ColumnRenderer<T>

  // (Optional) Function which returns loading contents of that column cell
  loadingRender?: ColumnRenderer<ColumnStructure>
}

export interface ColumnUsingComponent<T, U> {
  // Functional Component which returns contents of that column cell
  component: ColumnComponent<T, U>
  props?: U
}

export type ColumnDefinition<T, U> = (ColumnUsingRender<T> | ColumnUsingComponent<T, U>) & {
  // (Optional) Fixed size for this column. If `loadingSize` not specified the loading cell will
  // be of this same size
  size?: ColumnStructure

  // (Optional) Sizes for the loading columns
  loadingSize?: ColumnStructure
}

export interface Columns<T, U = {}> {
  // Key - [Header Name]
  // Value - Column Definition
  [key: string]: ColumnDefinition<T, U>
}

export interface LoadingColumn<T, U> {
  component?: React.FC<ColumnComponentProps<T> & U>
  render?: ColumnRenderer<unknown>
  size: ColumnStructure | undefined
  loadingColumn: boolean
}

export interface LoadingColumnRenders<T, U = {}> {
  [key: string]: LoadingColumn<T, U>
}

export function defaultColumnRenderer<T extends React.ReactNode>(
  data: T,
  key?: string | number,
  colSize?: ColumnStructure
) {
  return (
    <Column size={colSize} key={key}>
      {data}
    </Column>
  )
}

export function defaultLoadingRenderer<T, U>(
  columns: Columns<T, U>,
  rowIndex: number,
  colIndex: number
) {
  const colName = Object.keys(columns)[colIndex] || ""
  const colSize = columns[colName]?.size

  if (colSize) {
    return renderDefaultLoadingCell(colSize, `${rowIndex}-${colIndex}`)
  }
  return null
}

export const renderDefaultPaddingCell = (
  key: string | number,
  width: number = PaddingCellDefaultWidth
) => <PaddingCell key={key} width={width} />

export const renderDefaultLoadingCell = (size: ColumnStructure, key: string | number) => {
  const width = isNaN(Number(size.width.toString())) ? size.width.toString() : `${size.width}px`
  const height = isNaN(Number(size.height.toString())) ? size.height.toString() : `${size.height}px`
  return <LoadingCell margin="5px 0" key={key} width={width} height={height} />
}

export function renderLeftSideExpandedCell<T>(
  renderLeftSideExpanded: (data: T, index: number) => React.ReactNode,
  data: T,
  index: number
) {
  return (
    <SideExpandedCell>
      <LeftSideExpandedCell>
        <SideExpandedCellBackground>
          {renderLeftSideExpanded(data, index)}
        </SideExpandedCellBackground>
      </LeftSideExpandedCell>
    </SideExpandedCell>
  )
}

export function renderRightSideExpandedCell<T>(
  renderRightSideExpanded: (data: T, index: number) => React.ReactNode,
  data: T,
  index: number
) {
  return (
    <SideExpandedCell>
      <RightSideExpandedCell>
        <SideExpandedCellBackground>
          {renderRightSideExpanded(data, index)}
        </SideExpandedCellBackground>
      </RightSideExpandedCell>
    </SideExpandedCell>
  )
}

export function renderEmptySideColumnExpandedCell() {
  return <SideExpandedCell />
}

export const Column: React.FC<{ size?: ColumnStructure; children?: React.ReactNode }> = ({
  size,
  children,
}) => <TableCell size={size}>{children}</TableCell>
