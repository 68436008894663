import * as React from "react"
import {
  type EntityDepartment,
  type TransactionSummary,
} from "@digits-graphql/frontend/graphql-bearer"
import moneyFlowHelper from "@digits-shared/helpers/moneyFlowHelper"
import {
  type DimensionSummary,
  type DimensionSummaryHistory,
} from "src/frontend/components/OS/Shared/Charts/StackableBarChart/useDimensionSummaryStackableData"
import { type StackableBarChartComponentData } from "src/frontend/components/Shared/Layout/Components/Charts/StackedBarChartComponent"
import { type DepartmentSummaries } from "src/frontend/components/Shared/Layout/Components/Departments/filterDepartmentAncestors"

export function useTopDepartmentsSeries(
  dimensionalSummary: DepartmentSummaries[] | undefined
): StackableBarChartComponentData<EntityDepartment> {
  return React.useMemo(() => {
    const topDimensions: DimensionSummary<EntityDepartment>[] = []
    const histories: DimensionSummaryHistory[] = []
    const byPeriod = new Map<number, DimensionSummary<EntityDepartment>[]>()

    if (!dimensionalSummary) return { topDimensions, histories, summaries: [] }

    dimensionalSummary
      .toSorted((a, b) => {
        const meanA = calculateMean(a.summaries)
        const meanB = calculateMean(b.summaries)
        return meanB - meanA
      })
      .forEach(({ department: deptEntity, summaries }) => {
        // Add each top department to our list
        topDimensions.push({
          dimension: deptEntity,
          summary: summaries[0] as TransactionSummary,
        })

        // Prepare our dimension summary history for this department
        const history: DimensionSummaryHistory = { dimensionId: deptEntity.id, summaries: [] }

        // Loop over the histories for this department
        summaries.forEach((summary) => {
          // Add the summary to histories
          history.summaries.push(summary)

          // Find or start the tracked summaries for this period (across all departments)
          const periodSummaries = byPeriod.get(summary.period.startedAt) || []

          // Add this departments support for the current period
          periodSummaries.push({ dimension: deptEntity, summary: summary })

          // Push the new array of summaries back on the map to track by period
          byPeriod.set(summary.period.startedAt, periodSummaries)
        })

        histories.push(history)
      })

    // Get the first department, so we can build a period dimension summary
    // for each period we want to show
    const summaries =
      dimensionalSummary[0]?.summaries.map((summary) => {
        // Grab the period summaries if available
        const dimensions = byPeriod.get(summary.period.startedAt) || []

        // Return all the departments in this period in the correct structure
        return { period: summary.period, dimensions }
      }) ?? []

    return { topDimensions, histories, summaries }
  }, [dimensionalSummary])
}

function calculateMean(summaries: TransactionSummary[]) {
  const moneyFlows = summaries.flatMap(
    ({ total: { moneyFlow } }) => moneyFlow ?? moneyFlowHelper.buildZeroMoneyFlow()
  )
  return moneyFlowHelper.calculateMean(...moneyFlows).value.amount
}
