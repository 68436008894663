import * as React from "react"
import { type ReactNode } from "react"
import { type ObjectEntities, ObjectKind } from "@digits-graphql/frontend/graphql-bearer"
import stringHelper from "@digits-shared/helpers/stringHelper"
import urlHelper, { URL_REGEX } from "@digits-shared/helpers/urlHelper"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import {
  DATA_DECORATOR,
  DATA_ID,
} from "src/shared/components/EditableContent/editableContentConstants"
import DOMSanitize from "src/shared/components/ObjectEntities/DOMSanitize"
import {
  CategoryTag,
  DepartmentTag,
  LocationTag,
  PartyTag,
  UserTag,
} from "src/shared/components/ObjectEntities/Entities"
import {
  convertToDigitsEntityTags,
  getObjectKindFromString,
} from "src/shared/components/ObjectEntities/entitiesParserShared"
import type { EntityPopOverComponent } from "src/shared/components/ObjectEntities/entityPopOverTypes"

/*
 STYLES
*/

const FallbackName = styled.span`
  margin-left: 19px;
  color: ${colors.secondary};
  font-weight: ${fonts.weight.heavy};
`

/*
 INTERFACES
*/

interface NodesParser {
  text: string
  entities?: ObjectEntities | null
  entityPopOver?: EntityPopOverComponent
  disableHovers?: boolean
  formatChildNode?: (el: HTMLElement) => ReactNode | undefined
}

interface EntityTagProps {
  index: number
  entityId: string
  title?: string
  entities: ObjectEntities
  entityPopOver?: EntityPopOverComponent
  disableHover?: boolean
  formatChildNode?: (el: HTMLElement) => ReactNode | undefined
}

interface HTMLParserProps {
  index: number
  node: ChildNode
  entities?: ObjectEntities | null
  entityPopOver?: EntityPopOverComponent
  disableHovers?: boolean
  formatChildNode?: (el: HTMLElement) => ReactNode | undefined
}

interface HtmlEntityProps {
  index: number
  entityName: string
  entityId: string
  title: string
  entities?: ObjectEntities | null
  entityPopOver?: EntityPopOverComponent
  disableHover?: boolean
}

/*
 COMPONENTS
*/

/**
 * NodesToReactParser converts a DocumentFragment directly into React elements.
 * Key behaviors:
 * - Creates pure React elements without intermediate DOM manipulation
 * - Maintains React's virtual DOM
 * - Better for interactive components that need React's state management
 *
 * Use this parser when you need:
 * - React event handling
 * - Component re-rendering
 */
export const NodesToReactParser: React.FC<NodesParser> = ({
  text,
  entities,
  entityPopOver,
  disableHovers,
  formatChildNode: formatChildNode,
}) => {
  const fragment = React.useMemo(() => {
    const entitiesText = convertToDigitsEntityTags(text)
    return DOMSanitize.sanitize(entitiesText)
  }, [text])

  const tags = Array.from(fragment.childNodes).map((node, idx) => (
    <HTMLParser
      key={idx}
      index={idx}
      node={node}
      entities={entities}
      entityPopOver={entityPopOver}
      disableHovers={disableHovers}
      formatChildNode={formatChildNode}
    />
  ))

  return <>{tags}</>
}

const HTMLParser: React.FC<HTMLParserProps> = ({
  index,
  node,
  entities,
  entityPopOver,
  disableHovers,
  formatChildNode,
}) => {
  const element = node as HTMLElement
  if (!element.tagName) {
    return <UrlTags key={`text_${index}`} text={element.nodeValue || ""} />
  }

  if (element.tagName === "DIGITS-ENTITY") {
    const entityId = element.getAttribute(DATA_ID) || ""
    const entityName = element.getAttribute(DATA_DECORATOR) || ""
    const title = element.innerText || element.getAttribute("title") || ""

    return (
      <HtmlEntity
        key={`entity_${index}_${entityId}`}
        index={index}
        entityId={entityId}
        entityName={entityName}
        title={title}
        entities={entities}
        entityPopOver={entityPopOver}
        disableHover={disableHovers}
      />
    )
  }

  let children: ReactNode[] = []
  if (element.childNodes?.length) {
    children = Array.from(element.childNodes).map(
      (elChild, idx) => (
        <HTMLParser
          key={`html_${index}_${idx}`}
          index={idx}
          node={elChild}
          entities={entities}
          entityPopOver={entityPopOver}
          disableHovers={disableHovers}
        />
      ),
      []
    )
  }

  // Support re-writing node contents based on data attributes.
  if (element.childNodes?.length === 1) {
    const newChild = formatChildNode?.(element)
    children = newChild ? [newChild] : children
  }

  const style: Record<string, unknown> = {}
  if (element?.style) {
    for (let i = 0; i < element.style.length; i += 1) {
      const styleName = element.style[i] as keyof CSSStyleDeclaration
      const styleCameName = stringHelper.camelCase(styleName.toString())
      style[styleCameName] = element.style[styleName]
    }
  }
  const props: Record<string, unknown> = {
    key: `element_${index}`,
    style,
  }

  Object.entries({ href: "href", rel: "rel", class: "className" }).forEach(
    ([attrName, reactName]) => {
      const attr = element?.getAttribute(attrName)
      if (attr) {
        props[reactName] = attr
        props.target = "_blank"
      }
    }
  )

  return React.createElement(element.tagName.toLowerCase(), props, ...children)
}

const HtmlEntity: React.FC<HtmlEntityProps> = ({
  index,
  entityName,
  entityId,
  title,
  entities,
  entityPopOver,
  disableHover,
}) => {
  const key = `${entityName}_${index}_${entityId}`
  if (!entities) {
    return <React.Fragment key={key}>{title || entityName}</React.Fragment>
  }

  const { kind } = getObjectKindFromString(entityName)

  switch (kind) {
    case ObjectKind.User:
      return (
        <UserEntity key={key} index={index} entityId={entityId} title={title} entities={entities} />
      )

    case ObjectKind.Category:
      return (
        <CategoryEntity
          key={key}
          index={index}
          entityId={entityId}
          title={title}
          entities={entities}
          entityPopOver={entityPopOver}
          disableHover={disableHover}
        />
      )

    case ObjectKind.Party:
      return (
        <PartyEntity
          key={key}
          index={index}
          entityId={entityId}
          title={title}
          entities={entities}
          entityPopOver={entityPopOver}
          disableHover={disableHover}
        />
      )

    case ObjectKind.Department:
      return (
        <DepartmentEntity
          key={key}
          index={index}
          entityId={entityId}
          title={title}
          entities={entities}
          entityPopOver={entityPopOver}
          disableHover={disableHover}
        />
      )

    case ObjectKind.Location:
      return (
        <LocationEntity
          key={key}
          index={index}
          entityId={entityId}
          title={title}
          entities={entities}
          entityPopOver={entityPopOver}
          disableHover={disableHover}
        />
      )

    default:
      console.warn("Unsupported entity", entityName, entityId, title)
      return <>{title || entityName}</>
  }
}

const UrlTags: React.FC<{ text: string }> = ({ text }) => (
  <React.Fragment key={text}>
    {text.split(URL_REGEX).flatMap((str, idx) => {
      if (!str || str.search(URL_REGEX) === -1) return str

      if (!urlHelper.isValidHttpUrl(str)) return str

      return (
        <a key={`${str}_${idx}`} href={str} target="_blank" rel="noreferrer">
          {str}
        </a>
      )
    })}
  </React.Fragment>
)

const UserEntity: React.FC<EntityTagProps> = ({ index, entityId, title, entities }) => {
  const user = entities.users?.find((u) => u.id === entityId)
  if (!user) {
    if (title) return <FallbackName>{title}</FallbackName>

    console.warn("User entity not found for id:", entityId)
    return <span key={`missing_user_${index}_${entityId}`}>User</span>
  }
  return <UserTag user={user} />
}

const CategoryEntity: React.FC<EntityTagProps> = ({
  index,
  entityId,
  title,
  entities,
  entityPopOver,
  disableHover,
}) => {
  const category = entities.categories?.find((c) => c.id === entityId)
  if (!category) {
    if (title) return <FallbackName>{title}</FallbackName>

    console.warn("Category entity not found for id:", entityId)
    return <span key={`missing_category_${index}_${entityId}`}>Category</span>
  }
  return <CategoryTag entity={category} entityPopOver={entityPopOver} disableHover={disableHover} />
}

const PartyEntity: React.FC<EntityTagProps> = ({
  index,
  entityId,
  title,
  entities,
  entityPopOver,
  disableHover,
}) => {
  const party = entities.parties?.find((p) => p.id === entityId)
  if (!party) {
    if (title) return <FallbackName>{title}</FallbackName>

    console.warn("Party entity not found for id:", entityId)
    return <span key={`missing_party_${index}_${entityId}`}>Party</span>
  }
  return <PartyTag entity={party} entityPopOver={entityPopOver} disableHover={disableHover} />
}

const DepartmentEntity: React.FC<EntityTagProps> = ({
  index,
  entityId,
  title,
  entities,
  entityPopOver,
  disableHover,
}) => {
  const department = entities.departments?.find((d) => d.id === entityId)
  if (!department) {
    if (title) return <FallbackName>{title}</FallbackName>

    console.warn("Department entity not found for id:", entityId)
    return <span key={`missing_department_${index}_${entityId}`}>Department</span>
  }

  return (
    <DepartmentTag entity={department} entityPopOver={entityPopOver} disableHover={disableHover} />
  )
}

const LocationEntity: React.FC<EntityTagProps> = ({
  index,
  entityId,
  title,
  entities,
  entityPopOver,
  disableHover,
}) => {
  const location = entities.locations?.find((l) => l.id === entityId)
  if (!location) {
    if (title) return <FallbackName>{title}</FallbackName>

    console.warn("Location entity not found for id:", entityId)
    return <span key={`missing_location_${index}_${entityId}`}>Location</span>
  }

  return <LocationTag entity={location} entityPopOver={entityPopOver} disableHover={disableHover} />
}
