import * as React from "react"

export const ComponentHoveredContext = React.createContext<ComponentHoveredContextVal>({
  hovered: false,
})

export interface ComponentHoveredContextVal {
  hovered: boolean
}

export function useHovered() {
  return React.useContext(ComponentHoveredContext).hovered
}
