import colors from "@digits-shared/themes/colors"
import styled from "styled-components"

// reference: https://urlregex.com/ Swift version
// with two tweaks:
// 1) was to allow a domain extension (\\w{2,}) of more than 3 characters
// 2) allow subdomain (?:[\w\d\-_]+\.){1,2}
export const URL_PATTERN_REGEX =
  "^((?:http|https)://)?(?:www\\.)?(?:[\\w\\d\\-_]+\\.){1,2}\\w{2,}(\\.\\w{2})?(/(?:[\\w\\d\\-.\\/_]+)?)?$"

export const ROUTING_NUMBER_PATTERN_REGEX = "[0-9]{9}"

export const NUMBERS_ONLY_PATTERN_REGEX = "[0-9]+"

export const Form = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
`
export default Form

export const FormDivider = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  font-size: 11px;
  color: ${colors.translucentWhite50};
  width: 100%;
  text-transform: uppercase;

  &::before,
  &::after {
    position: absolute;
    height: 1px;
    width: 45%;
    background-image: linear-gradient(
      to left,
      ${colors.translucentWhite30} 0,
      rgba(255, 255, 255, 0) 90%
    );
    content: "";
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
    transform: rotate(180deg);
  }
`
