import * as React from "react"
import { type Statement as StatementGQL } from "@digits-graphql/frontend/graphql-bearer"
import { LoadingCell } from "@digits-shared/components/UI/Table"
import colorHelper from "@digits-shared/helpers/colorHelper"
import useForwardedRef from "@digits-shared/hooks/useForwardedRef"
import { useMeasure } from "@digits-shared/hooks/useMeasure"
import { themedStyles } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import styled, { css } from "styled-components"
import {
  RowStyled,
  STATEMENT_COLUMN_WIDTH,
  STATEMENT_ROWS_CLASSNAME,
  STATEMENT_WIDE_CONTAINER_SIZE,
  StatementSize,
} from "src/frontend/components/Shared/Reports/Report/Components/Statements/shared"
import { StatementColumnHighlights } from "src/frontend/components/Shared/Reports/Report/Components/Statements/StatementColumnHighlights"
import { StatementContext } from "src/frontend/components/Shared/Reports/Report/Components/Statements/StatementContext"
import { StatementHeader } from "src/frontend/components/Shared/Reports/Report/Components/Statements/StatementHeader"
import { StatementRowComponent } from "src/frontend/components/Shared/Reports/Report/Components/Statements/StatementRow"
import {
  normalizeRowId,
  useCollapsedRowFiltering,
} from "src/frontend/components/Shared/Reports/Report/Components/Statements/useCollapsedRowFiltering"
import { useRowCellSizes } from "src/frontend/components/Shared/Reports/Report/Components/Statements/useRowCellSizes"
import {
  useReportDocumentOptions,
  useReportExtraColumnsCount,
  useStatementBreakdownColumnsCount,
} from "src/frontend/components/Shared/Reports/Report/hooks/useReportDocumentOptions"
import { useStopClickPropagation } from "src/shared/hooks/useStopClickPropagation"

/*
  STYLES
*/

const Container = styled.div`
  width: 100%;
  font-size: 12px;
  display: flex;
  justify-content: center;
  container-type: inline-size;
  position: relative;
`
const StatementRows = styled.div<{
  $headerHeight: number
  $trendlineWidth: string | number
  $maxTitleCellWidth: string | number
  $maxTotalCellWidth: number
  $totalColumns: number
  $rowsHeight: number | undefined
}>`
  position: relative;
  min-width: min(400px, 100%);
  padding-right: 20px;

  ${themedStyles({
    dark: undefined,
    light: css`
      max-height: 70vh;
      overflow-y: auto;
    `,
    print: css``,
  })}

  --statement-header-height: ${(props) => props.$headerHeight}px;
  --statement-cell-width: ${(props) => props.$maxTotalCellWidth}px;
  --statement-title-width: 1fr;

  @container (min-width: ${STATEMENT_WIDE_CONTAINER_SIZE}px) {
    & {
      [orientation="portrait"] & {
        --statement-title-width: minmax(220px, ${(props) => props.$maxTitleCellWidth}px);
      }

      [orientation="landscape"] & {
        --statement-title-width: ${(props) =>
          typeof props.$maxTitleCellWidth === "string"
            ? props.$maxTitleCellWidth
            : `minmax(${props.$maxTitleCellWidth}px, 1fr)`};
      }

      overflow-x: scroll;

      --statement-scroll-shadow-left: ${colorHelper.hexToRgba(colors.primary, 0)};
      --statement-scroll-shadow-top: ${colorHelper.hexToRgba(colors.primary, 0)};
      --statement-scroll-shadow-right: ${colorHelper.hexToRgba(colors.primary, 0)};
      --statement-scroll-shadow-bottom: ${colorHelper.hexToRgba(colors.primary, 0)};
      background:
          /* Shadow LEFT */
        radial-gradient(
            farthest-side at 0 50%,
            var(--statement-scroll-shadow-left),
            ${colorHelper.hexToRgba(colors.primary, 0)}
          )
          center left no-repeat scroll,
        /* Shadow RIGHT */
          radial-gradient(
            farthest-side at 100% 50%,
            var(--statement-scroll-shadow-right),
            ${colorHelper.hexToRgba(colors.primary, 0)}
          )
          center right no-repeat scroll,
        /* Shadow TOP */
          radial-gradient(
            farthest-side at 50% 0,
            var(--statement-scroll-shadow-top),
            ${colorHelper.hexToRgba(colors.primary, 0)}
          )
          0 25px no-repeat scroll,
        /* Shadow BOTTOM */
          radial-gradient(
            farthest-side at 50% 100%,
            var(--statement-scroll-shadow-bottom),
            ${colorHelper.hexToRgba(colors.primary, 0)}
          )
          0 100% no-repeat scroll;

      background-size:
        14px 100%,
        14px 100%,
        100% 14px,
        100% 14px;

      &.scroll-left {
        --statement-scroll-shadow-left: ${colorHelper.hexToRgba(colors.primary, 0.2)};
      }
      &.scroll-top {
        --statement-scroll-shadow-top: ${colorHelper.hexToRgba(colors.primary, 0.2)};
      }
      &.scroll-right {
        --statement-scroll-shadow-right: ${colorHelper.hexToRgba(colors.primary, 0.2)};
      }
      &.scroll-bottom {
        --statement-scroll-shadow-bottom: ${colorHelper.hexToRgba(colors.primary, 0.2)};
      }
    }
  }

  ${themedStyles({
    light: undefined,
    dark: undefined,
    print: css`
      --statement-scroll-shadow-left: transparent;
      --statement-scroll-shadow-top: transparent;
      --statement-scroll-shadow-right: transparent;
      --statement-scroll-shadow-bottom: transparent;
    `,
  })};

  --statement-rows-height: ${(props) => props.$rowsHeight || 0}px;
  --statement-total-columns: ${(props) => props.$totalColumns};
  --statement-grid-template: var(--statement-title-width)
    ${(props) =>
      typeof props.$trendlineWidth === "string"
        ? props.$trendlineWidth
        : `${props.$trendlineWidth}px`}
    repeat(var(--statement-total-columns), var(--statement-cell-width));
`

export const StatementLoadingRows = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`

/*
  INTERFACE
*/

interface StatementProps {
  statement?: StatementGQL
  loading: boolean
  size?: StatementSize
}

/*
  COMPONENTS
*/

export const Statement: React.FC<StatementProps> = ({ statement, size, loading }) => {
  const statementContext = React.useMemo(
    () => ({
      kind: statement?.kind,
      size: size,
    }),
    [size, statement?.kind]
  )

  return (
    <StatementContext.Provider value={statementContext}>
      <StatementWithContext statement={statement} loading={loading} size={size} />
    </StatementContext.Provider>
  )
}

const StatementWithContext: React.FC<StatementProps> = ({ size, statement, loading }) => {
  const { rows, rowDetails, collapsedRowIds } = useCollapsedRowFiltering(statement)
  const stopPropagation = useStopClickPropagation()

  const breakdownColumns = useStatementBreakdownColumnsCount()
  const extraColumns = useReportExtraColumnsCount()
  const totalColumns = breakdownColumns + extraColumns + 1

  const { rowsRef, onScroll } = useRowScrollShadows()
  const { templateRef, headerHeight, trendlineWidth, maxTitleCellWidth, maxTotalCellWidth } =
    useRowCellSizes(rowsRef, rows, size)

  return (
    <Container>
      <StatementRows
        ref={rowsRef}
        className={STATEMENT_ROWS_CLASSNAME}
        $headerHeight={headerHeight}
        $trendlineWidth={trendlineWidth}
        $maxTitleCellWidth={maxTitleCellWidth}
        $maxTotalCellWidth={maxTotalCellWidth}
        $totalColumns={totalColumns}
        $rowsHeight={rowsRef.current?.scrollHeight}
        onScroll={onScroll}
        onClick={stopPropagation}
      >
        <StatementColumnHighlights
          key={`${rows.length}_${collapsedRowIds.size}`}
          totalRows={rows.length}
          totalColumns={totalColumns}
        />
        <StatementHeader size={size} />
        {loading && <LoadingRows size={size} />}
        {React.useMemo(
          () =>
            rows.map((row, i) => (
              <StatementRowComponent
                key={`${row.rowId || i}_${collapsedRowIds.has(row.rowId)}`}
                row={row}
                collapsed={collapsedRowIds.has(row.rowId)}
                size={size}
                details={rowDetails.get(normalizeRowId(row))}
              />
            )),
          [collapsedRowIds, rowDetails, rows, size]
        )}
      </StatementRows>
      <div ref={templateRef} css="position: fixed; z-index: -999; opacity: 0; height: 0" />
    </Container>
  )
}

function useRowScrollShadows() {
  const [measureRef, rect] = useMeasure<HTMLDivElement>()
  const rowsRef = useForwardedRef(measureRef)
  const options = useReportDocumentOptions()

  const toggleShadows = React.useCallback((currentTarget?: HTMLDivElement) => {
    if (!currentTarget) return

    const {
      scrollTop,
      scrollLeft,
      offsetWidth,
      scrollWidth,
      offsetHeight,
      scrollHeight,
      classList,
    } = currentTarget

    const hasTopScroll = scrollTop > 5
    const hasLeftScroll = scrollLeft > 0
    const hasRightScroll =
      Math.ceil(offsetWidth + scrollLeft) < scrollWidth && scrollWidth > offsetWidth + 10
    const hasBottomScroll =
      Math.ceil(offsetHeight + scrollTop) < scrollHeight && scrollHeight > offsetHeight + 10

    classList.toggle("scroll-top", hasTopScroll)
    classList.toggle("scroll-left", hasLeftScroll)
    classList.toggle("scroll-right", hasRightScroll)
    classList.toggle("scroll-bottom", hasBottomScroll)
  }, [])

  React.useEffect(() => {
    const currentTarget = rowsRef.current
    toggleShadows(currentTarget)
  }, [rect, options, rowsRef, toggleShadows])

  const onScroll = React.useCallback(
    ({ currentTarget }: React.MouseEvent<HTMLDivElement>) => {
      toggleShadows(currentTarget)
    },
    [toggleShadows]
  )

  return { rowsRef, onScroll }
}

const LoadingRows: React.FC<{ size?: StatementSize }> = ({ size }) => {
  const isCondensed = size === StatementSize.Condensed
  const breakdownColumns = useStatementBreakdownColumnsCount()
  const extraCount = useReportExtraColumnsCount()
  const extraColumns = isCondensed ? 0 : extraCount

  const columns = isCondensed ? 1 : 1 + breakdownColumns + extraColumns
  const width = isCondensed ? "120px" : "200px"
  const rows = isCondensed ? 5 : 15

  return (
    <StatementLoadingRows>
      {Array.from({ length: rows }).map((_, idx) => (
        <RowStyled key={idx} depth={0}>
          <LoadingCell height={16} width={width} />
          <div />
          {Array.from({ length: columns }).map((__, col) => (
            <LoadingCell key={col} height={16} width={STATEMENT_COLUMN_WIDTH} />
          ))}
        </RowStyled>
      ))}
    </StatementLoadingRows>
  )
}
