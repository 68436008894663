import stringHelper from "@digits-shared/helpers/stringHelper"

export interface CaretPosition {
  start: number
  end: number
}

function isInput(el: HTMLElement | HTMLInputElement): el is HTMLInputElement {
  return (el as HTMLInputElement).value !== undefined
}

const caretHelper = {
  isElementFocused: (el: HTMLElement) => {
    let parent: HTMLElement | null = el
    while (parent) {
      if (document.activeElement === parent) return true
      parent = parent.parentElement
    }
    return false
  },

  getCaretPosition: (el: HTMLElement | HTMLInputElement): CaretPosition | undefined => {
    if (isInput(el) && el.selectionStart !== null && el.selectionEnd !== null) {
      return {
        start: el.selectionStart,
        end: el.selectionEnd,
      }
    }

    const selection = window.getSelection()
    if (!selection?.focusNode) return

    const range = selection.getRangeAt(0)
    const preCaretRange = range?.cloneRange()
    const tmp = document.createElement("div")
    if (!range || !preCaretRange) return

    preCaretRange.selectNodeContents(el)
    preCaretRange.setEnd(range.endContainer, range.endOffset)
    tmp.appendChild(preCaretRange.cloneContents())
    const caretPosition = tmp.innerHTML.length
    return { start: caretPosition, end: caretPosition }
  },

  getTextAtCaretPosition: () => {
    const selection = window.getSelection()
    if (!selection?.focusNode || !selection?.modify) return

    const range = selection.getRangeAt(0)

    selection.collapseToStart()
    selection.modify("move", "backward", "word")
    selection.modify("extend", "forward", "word")

    const text = selection.toString().trim()

    // selection.modify "text" does not select special characters like "@"
    const regexObj = new RegExp(`[@#]?${stringHelper.escapeRegExp(text || "")}`)
    const selectionText = selection.focusNode.nodeValue?.trim()
    const prefixedText = selectionText?.match(regexObj)

    // Restore selection
    selection.removeAllRanges()
    selection.addRange(range)

    // if text has a trailing "@" don't use the matched selection because `selection.modify(..., word)`
    // will break words by the "@"; removing the leading characters and triggering `@user` autocomplete
    if (selectionText?.match(/\w+@/)) return selectionText

    return prefixedText?.[0] || text
  },

  getChildElementAtCaretPosition: (el: HTMLElement) => {
    const selection = window.getSelection?.()
    if (el && selection?.rangeCount) {
      let childElement = selection.focusNode
      if (childElement?.nodeType === Node.TEXT_NODE && childElement.nodeValue === "") {
        childElement = childElement.previousSibling
      }

      while (childElement && childElement !== el && childElement.nodeType !== Node.ELEMENT_NODE) {
        childElement = childElement.parentElement
      }
      return childElement as HTMLElement
    }
    return undefined
  },

  moveCaret: (el: HTMLElement, position: CaretPosition) => {
    if (position.start < 0 || position.end < 0) return
    const asInput = el as HTMLInputElement
    if (asInput?.selectionStart !== null && asInput?.selectionEnd !== null) {
      asInput.selectionStart = position.start
      asInput.selectionEnd = position.end
    }
  },

  selectElement: (el: HTMLElement) => {
    const isTargetFocused = caretHelper.isElementFocused(el)
    if (isTargetFocused) {
      const range = document.createRange()
      range.selectNodeContents(el) // Select the entire contents of the element with the range
      const selection = window.getSelection()
      selection?.removeAllRanges()
      selection?.addRange(range) // make the range just created the visible selection
    }
  },

  placeAtEnd: (el: HTMLElement) => {
    const isTargetFocused = caretHelper.isElementFocused(el)
    if (isTargetFocused) {
      const range = document.createRange()
      range.selectNodeContents(el) // Select the entire contents of the element with the range
      range.collapse(false)
      const selection = window.getSelection()
      selection?.removeAllRanges()
      selection?.addRange(range) // make the range just created the visible selection
    }
  },

  clearSelection: () => {
    const selection = window.getSelection()
    if (selection && selection.rangeCount) {
      const range = selection.getRangeAt(0)
      selection.removeAllRanges()
      selection.addRange(range)
    }
  },

  insertAtCaret: (el: HTMLElement, textOrElement: string | Node) => {
    const isTargetFocused = caretHelper.isElementFocused(el)
    const selection = window.getSelection()
    if (isTargetFocused && selection?.rangeCount) {
      const range = selection.getRangeAt(0)
      range.deleteContents()

      const node =
        typeof textOrElement === "string" ? document.createTextNode(textOrElement) : textOrElement
      range.insertNode(node)

      range.collapse(false) // collapse the range to the end point
      selection?.removeAllRanges()
      selection?.addRange(range)
    }
  },
}

export default caretHelper
