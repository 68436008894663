import * as React from "react"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/useViewVersion"
import { useCashSummaryChartLiveData } from "src/frontend/components/Shared/Layout/Components/CashSummary/useCashSummaryChartLiveData"
import { CashflowChartComponent } from "src/frontend/components/Shared/Layout/Components/Charts/CashflowChartComponent"
import { type ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { useConfigLookbackOriginOverride } from "src/frontend/components/Shared/Layout/hooks/useConfigOriginOverride"
import {
  type MatchedComponent,
  type SizingProps,
} from "src/frontend/components/Shared/Layout/types"

/*
  INTERFACES
*/

interface CashSummaryChartComponentProps extends SizingProps {
  component: MatchedComponent<"cashSummaryChart">
  componentSize: ComponentSize
  skipAnimations: boolean
}

/*
  COMPONENTS
*/

export const CashSummaryChartComponent: React.FC<CashSummaryChartComponentProps> = ({
  component,
  componentSize,
  height,
  skipAnimations,
}) => {
  // TODO: Re-enable step-down logic once there is a monthly/daily toggle on the dashboard.
  // const config = useConfigOriginStepDown(component.config.cashSummaryChart)
  const config = useConfigLookbackOriginOverride(component.config.cashSummaryChart)

  const { data } = useCashSummaryChartLiveData(config, useViewVersion())

  return (
    <CashflowChartComponent
      origin={config.origin}
      breakdownSummaries={data?.breakdownSummaries}
      totalCash={data?.totalCash}
      componentSize={componentSize}
      height={height}
      skipAnimations={skipAnimations}
    />
  )
}
