import {
  EXPANDED_CLASS_NAME,
  HOVERABLE_CLASS_NAME,
  TableRow,
} from "@digits-shared/components/UI/Table"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"

const CARET_STYLE = css`
  display: block;
  width: 100%;
  position: absolute;
  left: 0;
  transition: transform 300ms;
  transform: scaleX(1.95);
`

export const EXPANDED_GROUP_LINE_STYLE = css`
  content: "";
  display: block;
  position: absolute;
  top: -25px;
  left: 0;
  bottom: -26px;
  height: auto;
  width: 20px;
  z-index: 1;
  border-left: 1px solid ${colors.translucentSecondary40};

  ${"." + HOVERABLE_CLASS_NAME}:not(.${EXPANDED_CLASS_NAME}):hover & {
    border-color: ${colors.translucentSecondary60};
  }
`

const GROUP_ACTION_STYLE = css`
  position: relative;
  font-size: 13px;
  font-weight: ${fonts.weight.heavy};
  width: 15px;
  color: ${colors.translucentSecondary40};

  ${"." + HOVERABLE_CLASS_NAME}:not(.${EXPANDED_CLASS_NAME}):hover & {
    color: ${colors.translucentSecondary60};
  }
`

export const CollapsedIcon = styled.div`
  ${GROUP_ACTION_STYLE};

  height: 25px;

  &::before {
    ${CARET_STYLE};

    content: "∧";
    top: -3px;
  }

  &::after {
    ${CARET_STYLE};

    content: "∨";
    top: 8px;
  }

  ${TableRow}:hover & {
    &::before {
      transform: scaleX(1.95) translateY(-3px);
    }

    &::after {
      transform: scaleX(1.95) translateY(3px);
    }
  }
`

export const ExpandedIcon = styled.div`
  ${GROUP_ACTION_STYLE};

  height: 0;
  cursor: zoom-out;

  &::before {
    ${EXPANDED_GROUP_LINE_STYLE}
  }

  ${TableRow}.first-transaction & {
    &::before {
      top: 0;
    }

    &::after {
      ${CARET_STYLE};

      content: "∨";
      top: -13px;
    }
  }

  ${TableRow}.last-transaction & {
    &::before {
      bottom: 0;
    }

    &::after {
      ${CARET_STYLE};

      content: "∧";
      left: -0.5px;
      top: -7.5px;
    }
  }
`
