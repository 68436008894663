import * as React from "react"
import {
  type EntityUser,
  type ObjectIdentifier,
  ObjectKind,
} from "@digits-graphql/frontend/graphql-bearer"
import { ColorIcon } from "@digits-shared/components/UI/Elements/ColorIcon"
import envHelper from "@digits-shared/helpers/envHelper"
import { useOnBodyClick } from "@digits-shared/hooks/useOnBodyClick"
import useSession from "@digits-shared/hooks/useSession"
import { useListSuggestedUsers } from "src/frontend/hooks/useListSuggestedUsers"
import type FrontendSession from "src/frontend/session"
import {
  isTypeaheadUser,
  type TypeaheadResult,
} from "src/shared/components/Typeahead/sharedTypeahead"
import { Typeahead } from "src/shared/components/Typeahead/Typeahead"
import { useTypeaheadState } from "src/shared/components/Typeahead/useTypeaheadState"

interface DebuggingProps {
  assignedToUserId?: string
  setAssigneeUserId?: (id?: string) => void
  assignedToOrganizationId?: string
  setAssigneeOrganizationId?: (id?: string) => void
}

const DebuggingContext = React.createContext<DebuggingProps>({})

export const DebuggingContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { isDoppelganger } = useSession<FrontendSession>()
  const [assignedToUserId, setAssigneeUserId] = React.useState<string>()
  const [assignedToOrganizationId, setAssigneeOrganizationId] = React.useState<string>()

  const context = React.useMemo(
    () => ({
      assignedToUserId,
      setAssigneeUserId,
      assignedToOrganizationId,
      setAssigneeOrganizationId,
    }),
    [assignedToUserId, assignedToOrganizationId]
  )

  if (!envHelper.isDevelopment() && !isDoppelganger) return <>{children}</>

  return <DebuggingContext.Provider value={context}>{children}</DebuggingContext.Provider>
}

export function useDebuggingContext() {
  return React.useContext(DebuggingContext)
}

export const DebuggingUserPicker: React.FC = () => {
  const {
    currentLegalEntityId: legalEntityId,
    currentOrganizationId,
    user,
    isDoppelganger,
  } = useSession<FrontendSession>()
  const showPicker = envHelper.isDevelopment() || isDoppelganger
  const { setAssigneeUserId, setAssigneeOrganizationId } = useDebuggingContext()
  const { isTypeaheadShown, onClick, hideTypeahead, ref } = useTypeaheadState(false)
  useOnBodyClick(ref, hideTypeahead)

  const targetObject = React.useMemo(
    () => ({ kind: ObjectKind.Comment, legalEntityId, id: "" }),
    [legalEntityId]
  )

  const userIDToOrgId = useUserToOrganizationId(targetObject, currentOrganizationId, !showPicker)

  const [overrideUser, setOverride] = React.useState<EntityUser>()
  const onResultSelected = React.useCallback(
    (result: TypeaheadResult) => {
      hideTypeahead()
      if (!isTypeaheadUser(result.entity)) return

      setAssigneeUserId?.(result.entity.id)
      setAssigneeOrganizationId?.(userIDToOrgId?.get(result.entity.id))
      setOverride(result.entity)
    },
    [hideTypeahead, setAssigneeOrganizationId, setAssigneeUserId, userIDToOrgId]
  )

  if (!showPicker) return null

  return (
    <div css="position: relative">
      <ColorIcon css="cursor: pointer" fallbackUser={overrideUser ?? user} onClick={onClick} />

      {isTypeaheadShown && (
        <Typeahead
          ref={ref}
          placeholder="Select assignee…"
          kind={ObjectKind.User}
          objectIdentifier={targetObject}
          onResultSelected={onResultSelected}
          close={hideTypeahead}
        />
      )}
    </div>
  )
}

// Return a map of user to the organization ID that grants
// them access to the current legal entity.
function useUserToOrganizationId(
  targetObject: ObjectIdentifier,
  organizationId?: string,
  skip?: boolean
) {
  const { users } = useListSuggestedUsers(targetObject, organizationId, skip)
  return React.useMemo(
    () =>
      users?.reduce(
        (m, u) => m.set(u.id, u.employments?.[0]?.organization.id),
        new Map<string, string | undefined>()
      ),
    [users]
  )
}
