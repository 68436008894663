import * as React from "react"
import styled, { css, keyframes } from "styled-components"

/*
  STYLES
*/

const RING_ANIMATION = keyframes`
  0% {
    transform: rotate(40deg);
  }
  100% {
    transform: rotate(400deg);
  }
`

const FIRST_RING_COMPLETE_ANIMATION = keyframes`
  to {
    transform: rotate(40deg);
  }
`

const FirstSection = styled.div<Props>`
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: ${(props) => props.diameter}px;
  height: ${(props) => props.diameter}px;
  border: ${(props) => props.borderWidth}px solid ${(props) => props.color};
  border-radius: 50%;
  animation: ${RING_ANIMATION} 800ms cubic-bezier(0.5, 0, 0.2, 1) infinite;
  border-color: ${({ color }) => color} rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
`

const SECOND_RING_COMPLETE_ANIMATION = keyframes`
  to {
    transform: rotate(130deg);
  }
`

const SecondSection = styled(FirstSection)`
  animation-delay: 100ms;
`

const THIRD_RING_COMPLETE_ANIMATION = keyframes`
  to {
    transform: rotate(220deg);
  }
`

const ThirdSection = styled(FirstSection)`
  animation-delay: 50ms;
`

const FOURTH_RING_COMPLETE_ANIMATION = keyframes`
  to {
    transform: rotate(310deg);
  }
`

const FourthSection = styled(FirstSection)`
  animation-delay: 50ms;
`

const Ring = styled.div<Props>`
  display: inline-block;
  position: relative;
  width: ${({ diameter }) => diameter}px;
  height: ${({ diameter }) => diameter}px;
  margin: 3px 0 0 10px;
  ${({ isComplete }) =>
    isComplete &&
    css`
      ${FirstSection} {
        animation: ${FIRST_RING_COMPLETE_ANIMATION} 300ms ease-in-out forwards;
      }

      ${SecondSection} {
        animation: ${SECOND_RING_COMPLETE_ANIMATION} 300ms ease-in-out forwards;
      }

      ${ThirdSection} {
        animation: ${THIRD_RING_COMPLETE_ANIMATION} 300ms ease-in-out forwards;
      }

      ${FourthSection} {
        animation: ${FOURTH_RING_COMPLETE_ANIMATION} 300ms ease-in-out forwards;
      }
    `}
`

/*
  INTERFACE
*/

interface Props {
  diameter: number
  color: string
  borderWidth?: number
  isComplete?: boolean
  className?: string
}

/*
  COMPONENT
*/

export const RingLoader: React.FC<Props> = ({
  diameter,
  color,
  isComplete,
  borderWidth = 2,
  className,
}) => (
  <Ring className={className} diameter={diameter} color={color} isComplete={isComplete}>
    <FirstSection diameter={diameter} color={color} borderWidth={borderWidth} />
    <SecondSection diameter={diameter} color={color} borderWidth={borderWidth} />
    <ThirdSection diameter={diameter} color={color} borderWidth={borderWidth} />
    <FourthSection diameter={diameter} color={color} borderWidth={borderWidth} />
  </Ring>
)
