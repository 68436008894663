import * as React from "react"
import { useRouteMatch } from "react-router-dom"
import { LoggedRedirect } from "@digits-shared/components/Router/LoggedRedirect"
import { SvgDigitsLogoGreen } from "@digits-shared/components/SVGIcons/digits/DigitsLogoGreen.svg"
import { isMobile } from "@digits-shared/helpers/devicesHelper"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { createGlobalStyle } from "styled-components"
import {
  useIsMobileLocation,
  useMobileRoutes,
} from "src/frontend/components/Shared/Responsive/mobileRoutes"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import routes from "src/frontend/routes"

/*
  STYLES
*/

// Note: main#main has increased specificity to take precedence over
// app-level global styles
const BodyStyles = createGlobalStyle`
  html, body, main#main {
    max-width: 100vw;
    min-width: 100vw;
    width: 100vw;
    max-height: -webkit-fill-available;
    min-height: -webkit-fill-available;
    height: -webkit-fill-available;
    overflow: hidden;
  }
`

const MobileStyled = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 20px;
  background: ${colors.secondary} url(${require("static/images/onboard/background.png")}) no-repeat
    center;
  background-size: cover;
  color: ${colors.white};
`

export const BlockMobileHeadline = styled.div`
  text-align: center;
  font-size: 26px;
  line-height: 34px;
  margin-bottom: 30px;
  font-weight: ${fonts.weight.book};

  strong {
    font-weight: ${fonts.weight.medium};
  }
`

export const BlockMobileIconContainer = styled.div`
  position: relative;
  margin: 0 auto 40px;
  width: 100%;
  height: 110px;
`

const Message = styled.div`
  font-size: 16px;
  text-align: center;
  line-height: 19px;
  max-width: 250px;
  margin: 0 auto;
`

/*
  COMPONENTS
*/

export function useBlockMobile() {
  const generatePath = useFrontendPathGenerator()
  const isMobileLocation = useIsMobileLocation()
  const isLegalEntityRoot = useRouteMatch(routes.legalEntity.parameterizedPath)
  const isLegalEntityHome = useRouteMatch(routes.legalEntityHome.parameterizedPath)
  const mobileRoutes = useMobileRoutes()

  if (!isMobile || isMobileLocation) return null

  if (isLegalEntityRoot?.isExact || isLegalEntityHome?.isExact) {
    return <LoggedRedirect name="useBlockMobile-mobileHome" to={generatePath(mobileRoutes.home)} />
  }

  return <MobilePage />
}

const MobilePage: React.FC = () => (
  <MobileStyled>
    <BodyStyles />
    <BlockMobileIconContainer>
      <SvgDigitsLogoGreen />
    </BlockMobileIconContainer>
    <BlockMobileHeadline>
      Digits is optimized for <strong>desktop visualization.</strong>
    </BlockMobileHeadline>
    <Message>Please visit us from your desktop to see this content.</Message>
  </MobileStyled>
)
