import * as React from "react"
import { type ApolloError, type NetworkStatus } from "@apollo/client"
import {
  type ObjectIdentifier,
  useListAccountantSuggestedUsersQuery,
  useListLimitedSuggestedUsersQuery,
  useListSuggestedUsersQuery,
  type User,
} from "@digits-graphql/frontend/graphql-bearer"
import useSession from "@digits-shared/hooks/useSession"
import { useCanComment } from "src/frontend/hooks/useCanComment"
import { useJwtPermissions } from "src/frontend/hooks/useJwtPermissions"
import type FrontendSession from "src/frontend/session"
import {
  FrontendPermissionModule,
  FrontendPermissionSource,
  objectKindToModule,
} from "src/frontend/session/permissionModule"

interface SuggestedUsers {
  loading: boolean
  error?: ApolloError
  users?: User[]
  networkStatus: NetworkStatus
}

function useHasCommentingPermissions() {
  const permissions = useJwtPermissions(FrontendPermissionSource.LegalEntity)
  return React.useMemo(
    () => !!permissions?.hasCommentPermission(FrontendPermissionModule.Reports),
    [permissions]
  )
}

export function useListSuggestedUsers(
  objectIdentifier: ObjectIdentifier,
  organizationId?: string,
  skip = false
): SuggestedUsers {
  const hasCommentingPermissions = useHasCommentingPermissions()
  const { isAffiliatedSession } = useSession<FrontendSession>()

  const operatorResponse = useOperatorEndpoint(objectIdentifier, organizationId, skip)
  const accountantResponse = useAccountantEndpoint(objectIdentifier, organizationId, skip)
  const limitedUserResponse = useLimitedUserEndpoint(objectIdentifier, skip)

  return hasCommentingPermissions
    ? isAffiliatedSession
      ? accountantResponse
      : operatorResponse
    : limitedUserResponse
}

function useOperatorEndpoint(identifier: ObjectIdentifier, organizationId?: string, skip = false) {
  const hasCommentingPermissions = useHasCommentingPermissions()
  const { isAffiliatedSession } = useSession<FrontendSession>()

  const { data, loading, previousData, error, networkStatus } = useListSuggestedUsersQuery({
    variables: {
      organizationId: organizationId || "",
      identifier,
    },
    notifyOnNetworkStatusChange: true,
    skip: skip || isAffiliatedSession || !hasCommentingPermissions || !organizationId,
  })

  const users = loading ? previousData?.listSuggestedUsers : data?.listSuggestedUsers
  return React.useMemo(
    () => ({
      loading,
      users,
      error,
      networkStatus,
    }),
    [loading, users, error, networkStatus]
  )
}

function useAccountantEndpoint(
  identifier: ObjectIdentifier,
  organizationId?: string,
  skip = false
) {
  const hasCommentingPermissions = useHasCommentingPermissions()
  const { isAffiliatedSession } = useSession<FrontendSession>()

  const { data, loading, previousData, error, networkStatus } =
    useListAccountantSuggestedUsersQuery({
      variables: {
        organizationId: organizationId || "",
        identifier,
      },
      notifyOnNetworkStatusChange: true,
      skip: skip || !isAffiliatedSession || !hasCommentingPermissions || !organizationId,
    })

  const users = loading
    ? previousData?.listAccountantSuggestedUsers
    : data?.listAccountantSuggestedUsers
  return React.useMemo(
    () => ({
      loading,
      users,
      error,
      networkStatus,
    }),
    [loading, users, error, networkStatus]
  )
}

function useLimitedUserEndpoint(identifier: ObjectIdentifier, skip = false) {
  const hasCommentingPermissions = useHasCommentingPermissions()
  const hasCommentingGrant = useCanComment(objectKindToModule(identifier.kind))

  const { data, loading, previousData, error, networkStatus } = useListLimitedSuggestedUsersQuery({
    variables: {
      identifier,
    },
    notifyOnNetworkStatusChange: true,
    // skip if user is not limited (hasCommentingPermissions) or has no permission from object sharing
    skip: skip || hasCommentingPermissions || !hasCommentingGrant,
  })

  const users = loading ? previousData?.listLimitedSuggestedUsers : data?.listLimitedSuggestedUsers
  return React.useMemo(
    () => ({
      loading,
      users,
      error,
      networkStatus,
    }),
    [loading, users, error, networkStatus]
  )
}
