import * as React from "react"
import {
  LayoutComponentType,
  type LocationChartConfig,
  useReadLocationChartLiveDataQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/useViewVersion"
import { useIsShareUser } from "src/frontend/hooks/useObjectSharingState"
import { type LocationChartResponse } from "src/frontend/types"

export function useLocationChartLiveData(
  locationChart: LocationChartConfig | undefined | null,
  skip?: boolean
): LocationChartResponse {
  const viewId = useViewVersion()
  const isShareUser = useIsShareUser()

  const { data, loading } = useReadLocationChartLiveDataQuery({
    variables: {
      viewId,
      config: {
        type: LayoutComponentType.LocationChart,
        locationChart,
      },
    },
    skip: skip || (!isShareUser && !viewId?.viewVersion),
  })

  return React.useMemo(() => {
    const liveData = data?.liveComponentData
    // Resolves the component data union types to the portions we care about
    if (liveData?.__typename === "LocationSummary" && liveData.total?.time && liveData.location) {
      const {
        total,
        expensesChange,
        otherExpensesChange,
        incomeChange,
        otherIncomeChange,
        cogsChange,
        location,
      } = liveData
      return {
        data: {
          total: total.time.map(({ summary }) => summary),
          expensesChange: expensesChange.time.map(({ summary }) => summary),
          otherExpensesChange: otherExpensesChange.time.map(({ summary }) => summary),
          incomeChange: incomeChange.time.map(({ summary }) => summary),
          otherIncomeChange: otherIncomeChange.time.map(({ summary }) => summary),
          cogsChange: cogsChange.time.map(({ summary }) => summary),
          location,
        },
        loading,
      }
    }

    return { data: undefined, loading }
  }, [data, loading])
}
