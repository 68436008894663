import * as React from "react"
import {
  type EntityDepartment,
  type EntityLegalEntity,
} from "@digits-graphql/frontend/graphql-bearer"
import { SharedDepartmentRow } from "src/frontend/components/OS/Applications/SharedWithMe/Entities/EntityRows/SharedDepartmentRow"
import {
  LegalEntitiesContainer,
  LegalEntityRows,
  useFilterByLegalEntity,
} from "src/frontend/components/OS/Applications/SharedWithMe/Entities/Shared"
import { type LegalEntitySharedEntities } from "src/frontend/components/OS/Applications/SharedWithMe/Shared"

/*
  COMPONENTS
*/
export const SharedDepartments: React.FC<{
  departmentEntities: LegalEntitySharedEntities<EntityDepartment>[]
  selectedLegalEntity?: EntityLegalEntity
}> = ({ departmentEntities, selectedLegalEntity }) => {
  const filtered = useFilterByLegalEntity(departmentEntities, selectedLegalEntity)
  return (
    <LegalEntitiesContainer>
      {filtered.map(({ legalEntity, entities }) => (
        <LegalEntityRows key={legalEntity.id} legalEntity={legalEntity}>
          {entities.map((department, idx) => (
            <SharedDepartmentRow
              key={idx}
              department={department.entity}
              legalEntity={legalEntity}
              viewType={department.viewType}
            />
          ))}
        </LegalEntityRows>
      ))}
    </LegalEntitiesContainer>
  )
}
