import * as React from "react"
import { ColorIcon } from "@digits-shared/components/UI/Elements/ColorIcon"
import { isNavigationEscape, Keys } from "@digits-shared/components/UI/Elements/Keys"
import {
  PageScrollContext,
  type PageScrollContextProps,
} from "@digits-shared/components/UI/Elements/PageScrollContext"
import { ButtonRow } from "@digits-shared/DesignSystem/Button"
import { isSafari } from "@digits-shared/helpers/devicesHelper"
import useDetailsViewContext from "@digits-shared/hooks/useDetailsViewContext"
import useForwardedRef from "@digits-shared/hooks/useForwardedRef"
import { useKeyUpEvent } from "@digits-shared/hooks/useKeysEvent"
import { useOnBodyClick } from "@digits-shared/hooks/useOnBodyClick"
import { themedStyles } from "@digits-shared/themes"
import borders from "@digits-shared/themes/borders"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css, keyframes, type RuleSet } from "styled-components"

/*
  STYLES
*/

const FADE_IN = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const MODAL_OVERLAY_FADE_IN = keyframes`
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
`

const MODAL_OVERLAY_FADE_OUT = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`

const MODAL_ANIMATION_DURATION = 300

interface AnimateModal {
  animateIn?: boolean
  animateOut?: boolean
}

export const ModalOverlay = styled.div<AnimateModal>`
  position: fixed;
  background-image: radial-gradient(
    172% 378% at -9.5% -24%,
    rgba(0, 92, 140, 0.1) 0%,
    rgba(0, 10, 15, 0) 100%
  );
  backdrop-filter: blur(30px);
  opacity: 0.5;
  animation: ${({ animateIn = true, animateOut }) =>
    animateOut
      ? css`
          ${MODAL_OVERLAY_FADE_OUT} ${MODAL_ANIMATION_DURATION}ms ease-in-out forwards
        `
      : animateIn &&
        css`
          ${MODAL_OVERLAY_FADE_IN} ${MODAL_ANIMATION_DURATION}ms ease-in-out forwards
        `};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  overflow: auto;
`

const MODAL_CONTENT_FADE_IN = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -50px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`

const MODAL_CONTENT_FADE_OUT = keyframes`
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -50px, 0);
  }
`

export const ModalContent = styled.div<AnimateModal>`
  animation: ${({ animateIn = true, animateOut }) =>
    animateOut
      ? css`
          ${MODAL_CONTENT_FADE_OUT} ${MODAL_ANIMATION_DURATION}ms ease-in-out forwards
        `
      : animateIn &&
        css`
          ${MODAL_CONTENT_FADE_IN} ${MODAL_ANIMATION_DURATION}ms ${MODAL_ANIMATION_DURATION}ms ease-in-out forwards
        `};
`

export const ModalAvatar = styled(ColorIcon)`
  position: absolute;
  top: ${({ size }) => (typeof size === "number" ? `-${50 + size / 2}px` : "-50%")};
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 0px 30px ${colors.translucentWhite50};
`

export const ModalAnchor = styled.div`
  position: relative;
  height: 100%;
`

export const ModalCenterPositioning = styled.div`
  margin: 0 auto;
  padding: 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100%;
`

const modalStyles = themedStyles({
  light: css`
    color: ${colors.secondary};
    background: rgba(255, 255, 255, 0.22);
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  `,
  dark: css`
    color: ${colors.white};
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      linear-gradient(to bottom, rgba(36, 70, 109, 0.6) -2.34%, rgba(32, 31, 106, 0.2) 102.16%),
      linear-gradient(
        to bottom,
        rgba(143, 248, 255, 0.1625) 7.11%,
        rgba(70, 159, 184, 0.1365) 53.53%,
        rgba(0, 75, 118, 0.3445) 97.18%
      ),
      #131923;

    ${!isSafari && // back to IE6
    css`
      border: 0.5px solid ${colors.transparent};
    `}
    border-image: radial-gradient(${colors.transparent}, #06bde2, ${colors.transparent});
    border-image-slice: 1;
    border-image-width: 1px;
    border-radius: ${borders.radius.card}px;
  `,
})

export const ModalContainer = styled.div`
  ${modalStyles};
  animation: ${FADE_IN} 300ms ease-in-out;
  width: 600px;
  text-align: left;
  white-space: pre-wrap;

  position: relative;

  backdrop-filter: blur(34px);
  padding: 50px 40px;

  ${ButtonRow} {
    margin-top: 40px;
  }
`

export const ModalTitle = styled.div`
  color: ${colors.secondary};
  font-size: 18px;
  font-weight: ${fonts.weight.heavy};
  text-align: left;
  width: 100%;
  margin-bottom: 15px;
  text-transform: initial;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

export const ModalSubtitle = styled.div`
  font-size: 16px;
  color: ${colors.regentGray};
  text-align: left;
  width: 100%;
  margin-top: -10px;
  margin-bottom: 15px;
`

export const ModalDescription = styled.div`
  font-weight: ${fonts.weight.normal};
  font-size: 13px;
  text-transform: initial;
`

export const ModalErrorMessage = styled.div`
  color: ${colors.orange};
  min-height: 30px;
  margin: 10px 0 20px 0;
  text-transform: initial;
`

export const ModalErrorMessageStatic = styled(ModalErrorMessage)`
  margin-top: -20px;
  height: 30px;
`

export const ModalButtonRow = styled(ButtonRow)`
  justify-content: flex-end;
`

/*
  INTERFACES
*/

export interface ModalProps {
  onCancel?: () => void
  id?: string
  className?: string
  children?: React.ReactNode

  /**
   * If true, prevents using the default modal container.
   * Most modals should not need a custom container.
   */
  useCustomModalContainer?: boolean
  preventEasyClose?: boolean
  preventModalClickPropagation?: boolean
  animateOut?: boolean
  onAnimationEnd?: (event: React.AnimationEvent) => void
  overlayStyles?: RuleSet
}

/*
  COMPONENTS
*/

export const Modal = React.forwardRef<HTMLDivElement, ModalProps>((props, ref) => {
  const {
    id,
    className,
    useCustomModalContainer,
    animateOut,
    onAnimationEnd,
    children,
    preventModalClickPropagation = true,
    overlayStyles,
  } = props

  const modalContainer = useForwardedRef(ref)
  const { showCloseButton, hideCloseButton } = useDetailsViewContext()

  const handleModalContainerClick = React.useCallback(
    (event: React.MouseEvent) => {
      // Prevent bubbling to so we can detect clicks outside of modal container
      if (preventModalClickPropagation) {
        event.nativeEvent.stopImmediatePropagation()
      }
    },
    [preventModalClickPropagation]
  )
  useModalEvents(modalContainer, props)

  // Side-effect to manage the body overflow. Done separately since this behavior has no state
  // dependencies, and we don't want to perform it multiple times if state changes
  React.useEffect(() => {
    document.body.style.overflow = "hidden"
    return () => {
      document.body.style.overflow = ""
    }
  }, [])

  // Side-effect to manage the document listeners. Depends on props/context, so could tear-down
  // and set back up as state changes.
  React.useEffect(() => {
    hideCloseButton()
    return () => {
      showCloseButton()
    }
  }, [hideCloseButton, showCloseButton])

  const pageScrollContext = React.useMemo<PageScrollContextProps>(
    () => ({
      getScrollElement: () => modalContainer.current || null,
    }),
    [modalContainer]
  )

  return useCustomModalContainer ? (
    <ModalOverlay
      id={id}
      className={className}
      ref={modalContainer}
      onClick={handleModalContainerClick}
      animateOut={animateOut}
      onAnimationEnd={onAnimationEnd}
    >
      <PageScrollContext.Provider value={pageScrollContext}>{children}</PageScrollContext.Provider>
    </ModalOverlay>
  ) : (
    <ModalOverlay css={overlayStyles}>
      <ModalCenterPositioning>
        <ModalContainer
          id={id}
          className={className}
          ref={modalContainer}
          onClick={handleModalContainerClick}
          role="dialog"
          aria-modal
        >
          <ModalAnchor>
            <PageScrollContext.Provider value={pageScrollContext}>
              {children}
            </PageScrollContext.Provider>
          </ModalAnchor>
        </ModalContainer>
      </ModalCenterPositioning>
    </ModalOverlay>
  )
})

function useModalEvents(modalContainer: React.RefObject<HTMLDivElement | null>, props: ModalProps) {
  const { onCancel, preventEasyClose } = props
  const { showCloseButton } = useDetailsViewContext()

  const closeModal = React.useCallback(() => {
    showCloseButton()
    onCancel?.()
  }, [showCloseButton, onCancel])

  const handleKeyUp = React.useCallback(
    (event: KeyboardEvent) => {
      // close modal on Escape as long as it is not a text field
      if (isNavigationEscape(event) && !preventEasyClose) closeModal()
    },
    [preventEasyClose, closeModal]
  )

  const handleDocumentClick = React.useCallback(() => {
    if (preventEasyClose) return
    closeModal()
  }, [preventEasyClose, closeModal])

  // Side-effect to manage the document listeners. Depends on props/context, so could tear-down
  // and set back up as state changes.
  useKeyUpEvent(Keys.Escape, handleKeyUp)
  useOnBodyClick(modalContainer, handleDocumentClick)
}
