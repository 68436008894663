// Using class names instead of props so other elements that don't have access to these props
// directly can switch functionality based on their presence without having to update state
// outside of this Row component or use callbacks.
import { themedValue } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"

export type Identifier = string | number

export type DataIdentifier<T> = (data: T) => Identifier

export type RowBooleanValue<T> = boolean | ((data: T, index: number) => boolean)

export const EXPANDABLE_CLASS_NAME = "expandable"
export const LEFT_SIDE_EXPANDABLE_CLASS_NAME = "left-side-expandable"
export const RIGHT_SIDE_EXPANDABLE_CLASS_NAME = "right-side-expandable"
export const HOVERABLE_CLASS_NAME = "hoverable"

export const EXPANDED_CLASS_NAME = "expanded"
export const SELECTED_CLASS_NAME = "selected"

export const TABLE_BORDER_COLOR = themedValue({
  light: colors.translucentWhite40,
  dark: "rgba(37, 109, 136, 0.12)",
})

export interface IdentifiableData {
  id: Identifier
}

export function getRowIdentifier<P extends { getDataIdentifier?: DataIdentifier<T> }, T>(
  props: P,
  data: T,
  rowIndex: number
): Identifier {
  const identifiableData = data as unknown as IdentifiableData
  const passedDataIdentifier = data && props.getDataIdentifier?.(data)

  return (
    passedDataIdentifier ||
    (identifiableData && identifiableData.id !== undefined ? identifiableData.id : rowIndex)
  )
}
