import * as React from "react"
import {
  INITIAL_STATE,
  type OnboardingAction,
  type OnboardingField,
  type OnboardingState,
  OnboardingStep,
  reducer,
  RESET_FORM_ACTION_TYPES,
} from "@digits-shared/components/Onboarding/reducer"
import { useEffectOnce } from "@digits-shared/hooks/useEffectOnce"
import { useFormTracking, type UseFormTrackingVal } from "@digits-shared/hooks/useFormTracking"
import useRouter from "@digits-shared/hooks/useRouter"

export enum SignUpBehavior {
  MarketingSite = "MarketingSite",
  DispatchState = "DispatchState",
}

export interface OnboardingContextVal extends UseFormTrackingVal<OnboardingField> {
  state: OnboardingState
  dispatch: React.Dispatch<OnboardingAction>
  signUpBehavior: SignUpBehavior
}

/*
  CONTEXT
*/

/** Context object for directly consuming OnboardingContext */
export const OnboardingContext = React.createContext<OnboardingContextVal>({
  state: INITIAL_STATE,
  signUpBehavior: SignUpBehavior.MarketingSite,
  dispatch: () => {},
  resetFields: () => {},
  isFieldInvalid: () => false,
  getFieldValue: () => undefined,
  fieldHasValidValue: () => false,
  onFieldBlurred: () => {},
  onFieldChanged: () => {},
  updateFieldState: () => {},
  hasFieldBlurred: () => false,
})

/*
  PROVIDER
*/

export interface OnboardingProps {
  initialState?:
    | OnboardingStep.SignIn
    | OnboardingStep.SignUp
    | OnboardingStep.CreatePassword
    | OnboardingStep.MagicLinkLoading
    | OnboardingStep.MagicLinkExpired
    | OnboardingStep.SingleSignOnExpired
    | OnboardingStep.PasswordlessInviteAccepted
    | OnboardingStep.ResetPassword
  emailAddress?: string
  invitationToken?: string
  redirectPath?: string
  children?: React.ReactNode
  signUpBehavior?: SignUpBehavior
}

/** Component encapsulating onboarding state within the public chrome, providing state and dispatch */
export const OnboardingStateProvider: React.FC<OnboardingProps> = ({
  initialState,
  emailAddress,
  invitationToken,
  redirectPath,
  signUpBehavior = SignUpBehavior.MarketingSite,
  children,
}) => {
  const {
    location: { queryParams },
  } = useRouter()
  const formTracking = useFormTracking<OnboardingField>()
  const [state, dispatch] = React.useReducer(reducer, INITIAL_STATE)

  useEffectOnce(() => {
    if (initialState && state.step === OnboardingStep.None) {
      switch (initialState) {
        case OnboardingStep.SignIn:
          return dispatch({ type: "SHOW_SIGN_IN" })
        case OnboardingStep.SignUp:
          return dispatch({ type: "SHOW_SIGN_UP" })
        case OnboardingStep.CreatePassword:
          return dispatch({ type: "SHOW_CREATE_PASSWORD" })
        case OnboardingStep.ResetPassword:
          return dispatch({ type: "SHOW_RESET_PASSWORD" })
        case OnboardingStep.MagicLinkLoading:
          return dispatch({ type: "SHOW_MAGIC_LINK_LOADING" })
        case OnboardingStep.MagicLinkExpired:
          if (emailAddress && redirectPath) {
            return dispatch({ type: "SHOW_MAGIC_LINK_EXPIRED", emailAddress, redirectPath })
          }
          return dispatch({ type: "SHOW_SIGN_IN" })
        case OnboardingStep.PasswordlessInviteAccepted:
          if (invitationToken) {
            return dispatch({
              type: "SHOW_PASSWORDLESS_INVITE_ACCEPTED",
              invitationToken,
              redirectPath,
            })
          }
          return dispatch({ type: "SHOW_SIGN_IN" })
        case OnboardingStep.SingleSignOnExpired:
          if (emailAddress) {
            return dispatch({ type: "SHOW_SINGLE_SIGN_ON_EXPIRED", emailAddress })
          }
      }
    }
  })

  // support an immediate error message if found within the errorMsg query param
  useEffectOnce(() => {
    if (queryParams.errorMsg) {
      dispatch({ type: "SET_ERROR_TEXT", text: queryParams.errorMsg })
    }
  })

  // Dispatch is wrapped here so that we have the opportunity to react to actions as they
  // are sent. This allows us to reset the form tracking when certain navigations occur.
  const dispatchWithFormHandling = React.useCallback(
    (action: OnboardingAction) => {
      dispatch(action)

      if (RESET_FORM_ACTION_TYPES.has(action.type)) {
        formTracking.resetFields()
      }
    },
    // The linter wants to depend on `formTracking`, but that changes value too often,
    // causing infinite rerenders.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const value: OnboardingContextVal = React.useMemo(
    () => ({
      state,
      signUpBehavior,
      dispatch: dispatchWithFormHandling,
      ...formTracking,
    }),
    [state, signUpBehavior, dispatchWithFormHandling, formTracking]
  )

  return <OnboardingContext.Provider value={value}>{children}</OnboardingContext.Provider>
}

/*
  HOOK
 */
export function useOnboardingContext() {
  return React.useContext(OnboardingContext)
}
