import * as React from "react"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import useRouter from "@digits-shared/hooks/useRouter"
import useIntervalOrigin from "src/shared/hooks/useIntervalOrigin"

export function useHighlight() {
  const {
    location: { queryParams },
  } = useRouter()

  return React.useMemo(() => {
    const highlight = parseInt(queryParams.highlight || "", 10)
    return isNaN(highlight) ? undefined : highlight
  }, [queryParams.highlight])
}

export function useIntervalOriginHighlightOffset(intervalCount: number = 1) {
  const intervalOrigin = useIntervalOrigin()
  const highlight = useHighlight()

  return React.useMemo(() => {
    if (
      intervalOrigin.intervalCount === undefined ||
      intervalOrigin.intervalCount === null ||
      highlight === undefined
    ) {
      // can't offset
      return { ...intervalOrigin, intervalCount }
    }

    const offset = intervalOrigin.intervalCount - (highlight + 1)
    return {
      ...dateTimeHelper.addIntervalToOrigin(intervalOrigin, -offset),
      intervalCount,
    }
  }, [intervalOrigin, highlight, intervalCount])
}
