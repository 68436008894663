import { Permission } from "@digits-graphql/frontend/graphql-bearer"
import { useHasPermissionFromObjectSharing } from "src/frontend/hooks/useHasPermissionFromObjectSharing"
import { useJwtPermissions } from "src/frontend/hooks/useJwtPermissions"
import {
  FrontendPermissionModule,
  FrontendPermissionSource,
} from "src/frontend/session/permissionModule"

/**
 * Determines whether the current user has permissions to comment based on either:
 *   - permissions granted by object sharing, or
 *   - permissions from the current affiliation or organization
 */
export function useCanCommentReports() {
  const permissions = useJwtPermissions(FrontendPermissionSource.LegalEntity)
  const module = FrontendPermissionModule.Reports

  // Check if the permission granted by object sharing allow for commenting
  // for the current object sharing context.
  const hasPermissionFromObjectSharing = useHasPermissionFromObjectSharing(
    module,
    Permission.CommentRead,
    Permission.CommentShare,
    Permission.FullAccess,
    Permission.SensitiveFullAccess
  )

  if (hasPermissionFromObjectSharing) return true

  // Otherwise, we need the "Comment" permission from the session's JWT
  // on the reports module.
  return !!permissions?.hasCommentPermission(module)
}
