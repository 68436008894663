import * as React from "react"
import { svgIconStyles } from "@digits-shared/components/SVG/svgIconStyles"
import {
  IconShape,
  IconSVGContainerNoBackgroundColor,
} from "@digits-shared/components/UI/Icons/Icon"
import userHelper, { type UserDisplayInfo } from "@digits-shared/helpers/userHelper"
import { themedStyles } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"

/*
  STYLES
*/

export const Icon = styled.img<ColorIconProps>`
  // Default to circular icon shape
  border-radius: ${({ iconShape }) => (iconShape === "square" ? "4px" : "50%")};
  height: ${(props) => props.size || 35}px;
  width: ${(props) => props.size || 35}px;
`

const iconStyles = themedStyles({
  light: css`
    background: ${colors.theme.light.genericIcon};

    div svg {
      ${svgIconStyles(colors.white)};
    }
  `,
  dark: css`
    background: ${colors.theme.dark.genericIcon};

    div svg {
      ${svgIconStyles(colors.translucentWhite80)};
    }
  `,
})

export const ColorIconStyled = styled.div<ColorIconProps & IconColor>`
  ${iconStyles};
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => (isNumber(props.size) ? `${props.size}px` : props.size || "35px")};
  width: ${(props) => (isNumber(props.size) ? `${props.size}px` : props.size || "35px")};
  flex: 0 0 ${(props) => (isNumber(props.size) ? `${props.size}px` : props.size || "35px")};
  border-radius: ${({ iconShape }) => (iconShape === "square" ? "3px" : "50%")};
  background: ${({ background }) => background};

  ${(props) => {
    const fontSize =
      props.fontSize ??
      Math.max(
        11,
        ((isNumber(props.size) ? props.size : 35) -
          (props.displayFallbackText || props.fallbackUser ? 4 : 0)) /
          2
      )
    const paddingLeft = fontSize >= 12 ? 0 : 1
    return css`
      padding-left: ${paddingLeft}px;
      font-size: ${fontSize}px;
    `
  }};

  font-weight: ${fonts.weight.medium};
  text-transform: uppercase;

  ${(props) =>
    props.imageUrl &&
    css`
      background: none;
    `};

  color: ${(props) => props.color};

  ${(props) =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
`

/*
  INTERFACES
*/

export interface IconColor {
  background: string
  color: string
}

export interface ColorIconProps {
  imageUrl?: string | null
  fallbackUser?: UserDisplayInfo | null
  fallbackText?: string | null
  displayFallbackText?: boolean
  hideInitialText?: boolean
  borderColor?: string
  margin?: string
  size?: number | string
  fontSize?: number
  className?: string
  children?: React.ReactNode
  onClick?: (event: React.MouseEvent) => void
  iconShape?: IconShape
}

/*
  COMPONENTS
*/

export const ColorIcon = React.forwardRef<HTMLDivElement, ColorIconProps>((props, ref) => {
  const { imageUrl, displayFallbackText, fallbackUser, fallbackText, hideInitialText } = props
  const { initial, initialsColors } = getInitialAndColors(
    fallbackUser,
    fallbackText,
    displayFallbackText
  )

  return (
    <ColorIconStyled ref={ref} {...initialsColors} {...props}>
      {!imageUrl && ((displayFallbackText && fallbackText) || (!hideInitialText && initial))}
      {renderIcon(props)}
    </ColorIconStyled>
  )
})

const renderIcon = (props: ColorIconProps) => {
  const { imageUrl, children, className, iconShape = IconShape.Circle, ...rest } = props

  if (imageUrl) {
    return (
      <Icon
        src={userHelper.largeAvatarUrl({ avatarUrl: imageUrl })}
        iconShape={iconShape}
        {...rest}
      />
    )
  }

  if (children) {
    return (
      <IconSVGContainerNoBackgroundColor iconShape={iconShape}>
        {children}
      </IconSVGContainerNoBackgroundColor>
    )
  }

  return null
}

const getInitialAndColors = (
  user?: UserDisplayInfo | null,
  fallbackText?: string | null,
  displayFallbackText?: boolean
) => {
  const textForColorCode =
    displayFallbackText || !user
      ? fallbackText
      : user.primaryEmailAddress || user.emailAddress || userHelper.displayName(user)
  const initialsColors = getIconColor(textForColorCode)

  const initial =
    user && (user.givenName || user.familyName)
      ? `${user.givenName?.trim()[0] || ""}`
      : fallbackText?.trim()[0]

  return { initial, initialsColors }
}

export const getIconColor = (displayText?: string | null): IconColor => {
  if (!displayText) {
    return { ...BG_COLORS[0], color: FONT_COLOR }
  }

  // Subtracting 13 shifts the sequence so that A lands on the first color, while
  // not shifting other plausible characters (like numbers) into negative indexes
  const reducer = (acc: number, l: string | undefined) =>
    acc + (l ? l.toLocaleUpperCase().charCodeAt(0) - 13 : 0)
  const code = displayText.split("").reduce(reducer, 0)

  return {
    ...(BG_COLORS[code % BG_COLORS.length] as (typeof BG_COLORS)[number]),
    color: FONT_COLOR,
  }
}

const FONT_COLOR = "#ffffff"

export const BG_COLORS = [
  { background: "#7597E8" },
  { background: "#FF9F5A" },
  { background: "#26CD73" },
  { background: "#FF4FA4" },
  { background: "#31D0E6" },
  { background: "#EC7474" },
  { background: "#5868F5" },
  { background: "#649863" },
  { background: "#BA5AB6" },
  { background: "#3C96FF" },
  { background: "#9F8050" },
  { background: "#594398" },
  { background: "#B53E3E" },
  { background: "#317533" },
  { background: "#C87343" },
  { background: "#3159A6" },
  { background: "#CE4C8A" },
  { background: "#79B355" },
  { background: "#845FB3" },
  { background: "#223F6A" },
  { background: "#E76548" },
  { background: "#11A242" },
  { background: "#AF5400" },
  { background: "#1018D6" },
  { background: "#655047" },
  { background: "#184B00" },
] as const satisfies Array<{ background: string }>

function isNumber(size?: number | string): size is number {
  return typeof size === "number"
}
