import * as React from "react"
import { SvgChevronDown } from "@digits-shared/components/SVGIcons/line/ChevronDown.svg"
import { Menu, MenuContent, MenuItem, MenuTrigger } from "@digits-shared/DesignSystem/Menu"
import colors from "@digits-shared/themes/colors"
import { BodyText } from "@digits-shared/themes/typography"
import styled from "styled-components"
import { useDimensionDisplayOptionGroup } from "src/frontend/components/OS/Shared/DisplayOptions/DimensionDisplayOptionGroupContext"
import { DimensionActivity } from "src/frontend/types/DimensionFilter"

const SelectedFilterContainer = styled(BodyText)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  border-radius: 6px;
  cursor: pointer;
  width: 100%;
  border: 1px solid ${colors.secondary05};

  &:hover {
    border-color: ${colors.accentBlue};
  }
`

const Chevron = styled(SvgChevronDown)`
  width: 16px;
  height: 16px;
`

/*
  COMPONENTS
*/

export const DimensionActivityFilterDropdown: React.FC = () => {
  const { dimensionActivity } = useDimensionDisplayOptionGroup()

  const onCategoryActivityClick = React.useCallback(
    (activity: DimensionActivity) => () => dimensionActivity.set(activity),
    [dimensionActivity]
  )

  const dropDownValues = React.useMemo(() => Object.values(DimensionActivity), [])

  return (
    <Menu>
      <MenuTrigger asChild>
        <SelectedFilterContainer>
          {dimensionActivity.get()}
          <Chevron />
        </SelectedFilterContainer>
      </MenuTrigger>

      <MenuContent small>
        {dropDownValues.map((activity, i) => (
          <MenuItem
            key={activity}
            active={activity === dimensionActivity.get()}
            onSelect={onCategoryActivityClick(activity)}
            label={activity}
          />
        ))}
      </MenuContent>
    </Menu>
  )
}
