import { Flow, type MonetaryValue, type MoneyFlow } from "@digits-graphql/frontend/graphql-bearer"
import { PointingDirection } from "@digits-shared/components/UI/Elements/Chevron"
import { Theme } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"

export interface DeltaDisplayProps {
  amount: number
  invertValues: boolean
  // Note : this should be the delta percentage calculated with deltaPercentageForAmount, not the raw amount delta!
  delta: number | null | undefined
  isNew?: boolean | null
  themeMode?: Theme
}

export default {
  deltaDirectionForValue(props: DeltaDisplayProps) {
    const { isNew, invertValues, amount, delta } = props

    if (isNew || delta === null) {
      if (invertValues) {
        switch (true) {
          case amount > 0:
            return PointingDirection.Down
          case amount < 0:
            return PointingDirection.Up
          default:
            return PointingDirection.None
        }
      }
      switch (true) {
        case amount < 0:
          return PointingDirection.Down
        case amount > 0:
          return PointingDirection.Up
        default:
          return PointingDirection.None
      }
    }

    if (delta) {
      switch (true) {
        case delta < 0:
          return PointingDirection.Down
        case delta > 0:
          return PointingDirection.Up
        default:
          return PointingDirection.None
      }
    }

    return PointingDirection.None
  },

  deltaValueColor(props: DeltaDisplayProps) {
    const colorDown = props.themeMode === Theme.Light ? colors.secondary70 : colors.orange
    const colorUp = props.themeMode === Theme.Light ? colors.secondary70 : colors.primary
    const colorDefault =
      props.themeMode === Theme.Light ? colors.secondary70 : colors.translucentSecondary80

    switch (this.deltaDirectionForValue(props)) {
      case PointingDirection.Up:
        return props.invertValues ? colorDown : colorUp
      case PointingDirection.Down:
        return props.invertValues ? colorUp : colorDown
      case PointingDirection.None:
      default:
        return colorDefault
    }
  },

  /**
   * Given a MonetaryValue calculate the delta of the current value
   * against the value provided in the deltaPrevious property.
   *
   * @param amount The amount to calculate the delta for
   * @param deltaPrevious The delta to the value being compared against
   * @param invertValue Whether or not to invert the sign to denote flow of money
   */
  deltaPercentageForAmount(
    amount: number,
    deltaPrevious: number | null | undefined,
    invertValue = false
  ) {
    const currentTotal = amount * -1
    const prevMonthTotal = currentTotal + (deltaPrevious || 0)
    const signedDelta = (prevMonthTotal >= 0 ? -1 : 1) * (invertValue ? -1 : 1)

    const deltaQuotient = (currentTotal - prevMonthTotal) / (prevMonthTotal * signedDelta)
    const delta = deltaQuotient * 100
    let truncatedDelta = this.truncateDelta(delta)

    if (!isFinite(truncatedDelta)) return null

    if (isNaN(truncatedDelta)) truncatedDelta = 0

    return truncatedDelta
  },

  truncateDelta(delta: number) {
    return Math.abs(delta) <= 10 ? parseFloat(delta.toFixed(2)) : Math.trunc(delta)
  },

  /**
   * Given a MonetaryValue calculate the delta of the current value
   * against the value provided in the deltaPrevious property.
   *
   * @param value The MonetaryValue to calculate the delta for
   * @param deltaPrevious The delta to the value being compared against
   * @param invertValue Whether or not to invert the sign to denote flow of money
   */
  deltaPercentageForMonetaryValue(
    value: MonetaryValue,
    deltaPrevious: number | null | undefined,
    invertValue = false
  ) {
    return this.deltaPercentageForAmount(value.amount, deltaPrevious, invertValue)
  },

  /**
   * Given a MoneyFlow calculate the delta of the current value
   * against the value provided in the deltaPrevious property.
   *
   * @param value The MoneyFlow to calculate the delta for
   * @param deltaPrevious The delta to the value being compared against
   */
  deltaPercentageForMoneyFlow(value: MoneyFlow, deltaPrevious: number | null | undefined) {
    return this.deltaPercentageForAmount(
      value.value.amount,
      deltaPrevious,
      value.businessFlow === Flow.Outbound && value.isNormal
    )
  },
}
