import React from "react"
import { useListDataSourcesBadgeCountsQuery } from "@digits-graphql/frontend/graphql-bearer"

export function useDataSourcesBadgeCountsQuery(legalEntityIds: string[], skip?: boolean) {
  const result = useListDataSourcesBadgeCountsQuery({
    variables: {
      legalEntityIds,
    },
    skip: skip,
  })

  return React.useMemo(
    () => ({ counts: result.data?.listDataSourcesBadgeCounts, loading: result.loading }),
    [result]
  )
}
