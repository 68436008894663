import * as React from "react"
import { SvgEmployeeRelated } from "@digits-shared/components/SVGIcons/category/EmployeeRelated.svg"
import { SvgCreditCardShield } from "@digits-shared/components/SVGIcons/line/CreditCardShield.svg"
import { SvgElectrical01 } from "@digits-shared/components/SVGIcons/line/Electrical01.svg"
import { SvgFileShield02 } from "@digits-shared/components/SVGIcons/line/FileShield02.svg"
import { SvgMessageQuestionCircle } from "@digits-shared/components/SVGIcons/line/MessageQuestionCircle.svg"
import { SvgPower01 } from "@digits-shared/components/SVGIcons/line/Power01.svg"
import { SvgSettings01 } from "@digits-shared/components/SVGIcons/line/Settings01.svg"
import { SvgSettings04 } from "@digits-shared/components/SVGIcons/line/Settings04.svg"
import { chevronStyles, PointingDirection } from "@digits-shared/components/UI/Elements/Chevron"
import { ColorIcon } from "@digits-shared/components/UI/Elements/ColorIcon"
import envHelper from "@digits-shared/helpers/envHelper"
import userHelper from "@digits-shared/helpers/userHelper"
import { useEscapeKeyCapture } from "@digits-shared/hooks/useEscapeKeyCapture"
import { useMeasure } from "@digits-shared/hooks/useMeasure"
import useSession from "@digits-shared/hooks/useSession"
import { AspectCode } from "@digits-shared/session/SessionTypes"
import colors from "@digits-shared/themes/colors"
import styled, { css } from "styled-components"
import { Application } from "src/frontend/components/OS/Applications/Application"
import { useBillingOrganizationPlan } from "src/frontend/components/OS/Applications/Settings/hooks/useBillingOrganizationPlan"
import { useLogOut } from "src/frontend/components/Session/useLogOut"
import { useBadgeContext } from "src/frontend/components/Shared/Contexts/BadgeContext"
import { useNavSidebarContext } from "src/frontend/components/Shared/Contexts/useNavSidebarContext"
import {
  SIDEBAR_PATH_SVG_STYLES,
  SIDEBAR_SVG_STYLES,
} from "src/frontend/components/Shared/NavSidebar/sidebarConstants"
import { SidebarRow } from "src/frontend/components/Shared/NavSidebar/SidebarExpando"
import { SidebarViewType } from "src/frontend/components/Shared/NavSidebar/SidebarViewType"
import { RequiresUpdate } from "src/frontend/components/Shared/Permissions/Requires"
import { RequiresAspect } from "src/frontend/components/Shared/Permissions/RequiresAspect"
import routes from "src/frontend/routes"
import type FrontendSession from "src/frontend/session"
import {
  FrontendPermissionModule,
  FrontendPermissionSource,
} from "src/frontend/session/permissionModule"
import { Experience } from "src/frontend/session/personas"
/*
  STYLES
*/

const Footer = styled.div<{ isMenuOpen: boolean }>`
  ${({ isMenuOpen }) =>
    isMenuOpen &&
    css`
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 60px;
        width: 100%;
        backdrop-filter: blur(13px);
      }
    `}

  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`

const AccountantSettingsIcon = styled(SvgSettings04)`
  ${SIDEBAR_PATH_SVG_STYLES};
`

const SettingsIcon = styled(SvgSettings01)`
  ${SIDEBAR_PATH_SVG_STYLES};
`

const ConnectionsIcon = styled(SvgElectrical01)`
  ${SIDEBAR_PATH_SVG_STYLES};
`

const BillingIcon = styled(SvgCreditCardShield)`
  ${SIDEBAR_PATH_SVG_STYLES};
`

const SupportIcon = styled(SvgMessageQuestionCircle)`
  ${SIDEBAR_PATH_SVG_STYLES};
`

const DigitsEmployeeIcon = styled(SvgEmployeeRelated)`
  ${SIDEBAR_SVG_STYLES};
`

const SharedWithMeIcon = styled(SvgFileShield02)`
  ${SIDEBAR_PATH_SVG_STYLES};
`

const SignOutIcon = styled(SvgPower01)`
  ${SIDEBAR_PATH_SVG_STYLES};
`

const UserMenuRow = styled(SidebarRow)<{ isMenuOpen: boolean }>`
  ${({ isMenuOpen }) =>
    isMenuOpen &&
    css`
      background: ${colors.secondary05};
      margin-top: 5px;
    `};

  &:before {
    content: unset;
  }
`

const Menu = styled.div<{ height: number }>`
  display: flex;
  align-items: center;
  flex-direction: column;

  background: ${colors.secondary05};
  backdrop-filter: blur(20px);
  overflow: hidden;
  z-index: 1;

  position: absolute;
  bottom: 48px;
  width: 100%;

  height: 0;
  transition: height 300ms;

  &.menu-expand {
    height: ${({ height }) => height}px;
  }
`

const MenuItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`

const Chevron = styled.div<{ expanded: boolean }>`
  ${({ expanded }) =>
    chevronStyles(
      expanded ? PointingDirection.Down : PointingDirection.Up,
      "5px",
      colors.secondary
    )};
`

const MenuTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;

  div:first-child {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

/*
  COMPONENT
*/

export const SidebarFooter: React.FC = () => {
  const session = useSession<FrontendSession>()
  const accountantFeatures = session.currentPrimaryExperience === Experience.AccountantPortal
  const { isFooterMenuOpen } = useNavSidebarContext()

  return (
    <Footer isMenuOpen={isFooterMenuOpen}>
      <RequiresAspect aspect={AspectCode.Connections}>
        <SidebarConnections />
      </RequiresAspect>

      <SidebarEntitySettings />
      {accountantFeatures && <SidebarOrganizationSettings />}

      <SidebarDigitsOnly />

      <UserMenu>
        <SidebarViewType />
        {accountantFeatures && <SidebarBillings />}

        <SidebarSharedWithMe />
        <SidebarCustomerSupport />
      </UserMenu>
    </Footer>
  )
}

export const SharedUserFooter: React.FC = () => {
  const { isFooterMenuOpen } = useNavSidebarContext()
  return (
    <Footer isMenuOpen={isFooterMenuOpen}>
      <SidebarDigitsOnly />
      <UserMenu>
        <SidebarCustomerSupport />
      </UserMenu>
    </Footer>
  )
}

const UserMenu: React.FC<React.PropsWithChildren> = ({ children }) => {
  const session = useSession<FrontendSession>()
  const logOut = useLogOut()
  const [ref, { height }] = useMeasure<HTMLDivElement>()
  const { isFooterMenuOpen, onMouseLeaveFooterMenu, toggleFooterMenu, hideFooterMenu } =
    useNavSidebarContext()
  useEscapeKeyCapture(
    hideFooterMenu,
    // needs to be nested within document to capture the escape event before DetailsView
    { target: document?.body }
  )

  return (
    <div onMouseLeave={onMouseLeaveFooterMenu}>
      <Menu
        height={height + 6}
        className={isFooterMenuOpen ? "menu-expand" : ""}
        inert={isFooterMenuOpen ? undefined : ""}
      >
        <MenuItemContainer ref={ref}>
          {isFooterMenuOpen && children}
          <SidebarRow Icon={SignOutIcon} onClick={logOut}>
            Log out
          </SidebarRow>
        </MenuItemContainer>
      </Menu>
      <UserMenuRow Icon={Avatar} isMenuOpen={isFooterMenuOpen} onClick={toggleFooterMenu}>
        <MenuTitle>
          <div css="flex: 1">{userHelper.displayName(session.user, "abbreviateLast")}</div>
          <Chevron expanded={isFooterMenuOpen} />
        </MenuTitle>
      </UserMenuRow>
    </div>
  )
}

const Avatar: React.FC = () => {
  const session = useSession<FrontendSession>()
  return (
    <ColorIcon
      css={{ marginLeft: "-1px" }}
      imageUrl={session.user.avatarUrl}
      fallbackUser={session.user}
      size={27}
      fontSize={12}
    />
  )
}

const SidebarCustomerSupport: React.FC = () => {
  const onClick = React.useCallback(() => {
    window.open("mailto:support@digits.com", "_blank")
  }, [])

  return (
    <SidebarRow Icon={SupportIcon} onClick={onClick}>
      Support
    </SidebarRow>
  )
}

const SidebarBillings: React.FC = () => {
  const { currentOrganization } = useSession<FrontendSession>()
  const orgSlug = currentOrganization?.slug ?? ""
  const { planExceeded } = useBillingOrganizationPlan(currentOrganization?.id)

  return (
    <RequiresUpdate
      source={FrontendPermissionSource.Organization}
      module={FrontendPermissionModule.Billing}
    >
      <SidebarRow
        Icon={BillingIcon}
        route={routes.organizationBilling}
        params={{ orgSlug }}
        badge={Boolean(planExceeded)}
      >
        Billing
      </SidebarRow>
    </RequiresUpdate>
  )
}

const SidebarDigitsOnly: React.FC = () => {
  const { isDigitsEmployee } = useSession<FrontendSession>()
  // DO NOT remove the isProduction check
  const displayDigitsSupport = !envHelper.isProduction() || isDigitsEmployee

  if (!displayDigitsSupport) {
    return null
  }

  return (
    <SidebarRow Icon={DigitsEmployeeIcon} route={routes.internalFeatures} isSolidIcon>
      Employee Features
    </SidebarRow>
  )
}

const SidebarSharedWithMe: React.FC = () => (
  <SidebarRow Icon={SharedWithMeIcon} route={routes.sharedWithMe}>
    Shared with me
  </SidebarRow>
)

const SidebarOrganizationSettings: React.FC = () => {
  const { currentOrganization, currentLegalEntity } = useSession<FrontendSession>()

  return (
    <RequiresUpdate
      source={FrontendPermissionSource.Organization}
      module={FrontendPermissionModule.Employees}
    >
      <SidebarRow
        Icon={AccountantSettingsIcon}
        route={routes.organizationSettingsClient}
        params={{ orgSlug: currentOrganization?.slug ?? "", leSlug: currentLegalEntity.slug }}
      >
        Manage Clients
      </SidebarRow>
    </RequiresUpdate>
  )
}

const SidebarConnections: React.FC = () => {
  const { currentLegalEntity } = useSession<FrontendSession>()
  const { badgeCount } = useBadgeContext()

  const count = badgeCount(currentLegalEntity.id, Application.Connections.name)

  return (
    <RequiresUpdate
      source={FrontendPermissionSource.Organization}
      module={FrontendPermissionModule.Employees}
    >
      <SidebarRow
        Icon={ConnectionsIcon}
        route={routes.connections}
        params={{ leSlug: currentLegalEntity.slug }}
        badge={Boolean(count)}
        badgeType="error"
      >
        Connections
      </SidebarRow>
    </RequiresUpdate>
  )
}

const SidebarEntitySettings: React.FC = () => {
  const { currentLegalEntity } = useSession<FrontendSession>()
  const { badgeCount } = useBadgeContext()

  if (!currentLegalEntity?.id) return null

  const params = { leSlug: currentLegalEntity.slug }
  const count = badgeCount(currentLegalEntity.id, Application.DataSources.name)

  // navigate directly to the Accounting sidebar if badge count is greater than 0
  const route = count ? routes.accountingSettings : routes.legalEntitySettings

  return (
    <RequiresUpdate
      source={FrontendPermissionSource.LegalEntity}
      module={FrontendPermissionModule.Organizations}
    >
      <SidebarRow
        Icon={SettingsIcon}
        route={route}
        params={params}
        badge={Boolean(count)}
        badgeType="error"
      >
        Settings
      </SidebarRow>
    </RequiresUpdate>
  )
}
