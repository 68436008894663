import React from "react"
import { EmployeeStatus } from "@digits-graphql/frontend/graphql-bearer"
import { usePortalViewers } from "src/frontend/components/Shared/Portals/hooks/usePortalViewers"
import { usePortalDispatch } from "src/frontend/components/Shared/Portals/State/portalStore"

/** Syncs the list of employees with access to the client portal into the reducer state */
export function useEmployeesWithAccess() {
  const portalDispatch = usePortalDispatch()

  const { loading, portalViewers: employees } = usePortalViewers({
    limit: 500,
    includeInvited: true,
  })

  React.useEffect(() => {
    if (!loading && employees) {
      const active = employees.filter((e) => e.status === EmployeeStatus.Activated)
      const invited = employees.filter((e) => e.status === EmployeeStatus.Initialized)
      portalDispatch({ type: "setEmployees", employees: active, invitedEmployees: invited })
    }
  }, [employees, loading, portalDispatch])
}
