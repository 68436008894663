import React from "react"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgSearchSm } from "@digits-shared/components/SVGIcons/line/SearchSm.svg"
import useForwardedRef from "@digits-shared/hooks/useForwardedRef"
import { themedStyles } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"
import { useStopClickPropagation } from "src/shared/hooks/useStopClickPropagation"

const HORIZONTAL_MARGIN = 10
const HORIZONTAL_PADDING = 10
const SVG_SIZE = 22
const SVG_MARGIN = 6

/*
 STYLES
*/

const SvgSearchStyled = styled(SvgSearchSm)`
  height: ${SVG_SIZE}px;
  width: ${SVG_SIZE}px;
  align-self: center;
  margin: 0 6px 0 0;
`

const inputContainerStyles = themedStyles({
  light: css`
    border: 1px solid ${colors.translucentBlack10};
    background: ${colors.white};

    ${SvgSearchStyled} {
      ${svgPathStyles(colors.secondary70, 1)};
    }
  `,
  dark: css`
    border: 1px solid #8ff8ff;

    ${SvgSearchStyled} {
      ${svgPathStyles("#8ff8ff", 1)};
    }
  `,
})

export const InputContainer = styled.div<{ $width?: number }>`
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;
  border-radius: 8px;
  padding: 8px ${HORIZONTAL_PADDING}px;
  margin: ${HORIZONTAL_MARGIN}px;
  ${({ $width }) =>
    $width &&
    css`
      ${$width}px
    `};

  ${inputContainerStyles};
`

const inputStyles = themedStyles({
  light: css`
    color: ${colors.secondary};

    &::selection {
      background: ${colors.translucentSecondary10};
    }

    &::placeholder {
      color: ${colors.translucentSecondary80};
    }
  `,
  dark: css`
    color: ${colors.white};

    &::selection {
      background: #4e999a;
    }

    &::placeholder {
      color: ${colors.translucentWhite80};
    }
  `,
})

const InputBox = styled.input<{ $maxWidth?: number }>`
  ${({ $maxWidth }) =>
    $maxWidth &&
    `max-width: ${
      $maxWidth - HORIZONTAL_PADDING * 2 - SVG_MARGIN - SVG_SIZE - HORIZONTAL_MARGIN * 2
    }px`};
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-shadow: none;
  border-radius: 0;
  border: none;
  background: transparent;
  ${fonts.scale.body};

  &::selection {
    border-radius: 5px;
    padding: 2px 3px;
  }

  &::placeholder {
    font-weight: ${fonts.weight.book};
  }

  ${inputStyles};
`

/*
 INTERFACES
*/

interface WorkItemTypeaheadInputProps {
  autoFocus?: boolean
  disabled?: boolean
  placeholder?: string
  defaultValue?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  width?: number
}

/*
 COMPONENTS
*/

export const TypeaheadInput = React.forwardRef<HTMLInputElement, WorkItemTypeaheadInputProps>(
  (props, ref) => {
    const { disabled, placeholder, onChange, autoFocus, width } = props
    const forwardedRef = useForwardedRef(ref)

    // Prevents the typeahead from being closed when the user clicks on the search input
    const stopPropagation = useStopClickPropagation()

    React.useEffect(() => {
      let timeout: NodeJS.Timeout
      if (forwardedRef?.current && autoFocus) {
        // TODO: Broken in Typeahead Portal component for Chrome and Firefox
        forwardedRef.current.focus()
        forwardedRef.current.select()

        // Hack to fix the focus issue in Chrome and Firefox
        timeout = setTimeout(() => {
          forwardedRef.current.focus()
        }, 100)
      }

      return () => {
        clearTimeout(timeout)
      }
    }, [autoFocus, forwardedRef])

    return (
      <InputContainer $width={width}>
        <SvgSearchStyled />
        <InputBox
          ref={forwardedRef}
          autoFocus={autoFocus}
          disabled={disabled}
          placeholder={placeholder}
          onChange={onChange}
          onClick={stopPropagation}
          $maxWidth={width}
        />
      </InputContainer>
    )
  }
)
