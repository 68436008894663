import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgMarkerPin02 } from "@digits-shared/components/SVGIcons/line/MarkerPin02.svg"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"

export const SvgLocationIcon = styled(SvgMarkerPin02)`
  &&& g {
    fill: none;
  }
  ${svgPathStyles(colors.secondary)}
`
