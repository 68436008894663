import borders from "@digits-shared/themes/borders"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"

interface PillProps {
  pillStyle?: "default" | "outline"
}

export const Pill = styled.div<PillProps>`
  display: inline-block;
  border-radius: ${borders.radius.pill}px;
  font-size: 10px;
  font-weight: ${fonts.weight.medium};
  padding: 2px 8px 1px;

  ${({ pillStyle }) => {
    const color = pillStyle === "outline" ? colors.neonGreen : colors.theme.dark.invertedText
    const border = pillStyle === "outline" ? `1px solid ${colors.neonGreen}` : "none"
    const backgroundColor = pillStyle === "outline" ? colors.transparent : colors.neonGreen

    return css`
      color: ${color};
      background-color: ${backgroundColor};
      border: ${border};
    `
  }}
`
